import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import LoadFileToS3 from "../../../../../utils/components/fileUpload/LoadFileToS3";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import { arraySortNum } from "../../../../../utils/sortArrayOfObjectsNum";

import Prioritize from "./Prioritize";

import "./Editor.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtToday = "/routes/interact/saveUpdtToday";

const imageFileTypes = ["jpeg", "jpg", "bmp", "tiff", "png", "gif", "ebp"];
const videoFileTypes = ["mp4", "webm"];

const maxImageSize = 5000000; // 500KB
const maxVedioSize = 100000000; // 100mb  -- about 10 minutes

const readMeCode = "1143100000";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      imageFlag: false,
      videoFlag: false,
      previousFlag: false,
      nextFlag: false,
      addFlag: false,
      priorityFlag: false,
      showPriorityFlag: false,

      getNewsErr: "",

      today: [],

      newsTitle: "",
      news: "",
      seqNo: 0,
      presentSeqNo: 0,
      deleteFlag: false,

      showDeleteFlag: false,
      showUndoFlag: false,

      newsImage: "",
      newsVideo: "",
      justSaved: false,

      // saveFlag: false,
      newFlag: true,
      editFlag: false,

      opsMode: "edit",

      showPrevButton: true,
      showNextButton: false,

      activeCommunityId: 0,

      showVideoUploadPanel: false,
      showImageUploadPanel: false,
      lastDASize: 0,

      showEditorPanel: true,
      showButtonPanel: true,
      s3SubBucket: "",

      s3ImageFileData: null,
      s3VideoFileData: null,
      imgRequestorMsg: "",
      videoRequestorMsg: "",

      errMsg: "",
      errFlag: false,
      publishThisNews: false,

      openTo: "public",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let today = [];
    if (this.props.today) today = this.props.today.news;

    let s3SubBucket = "";
    if (this.props.today) s3SubBucket = this.props.today.subBucket;
    if (!s3SubBucket || s3SubBucket === "")
      s3SubBucket = await this.createSubBucket();

    let showPrevButton = false;
    if (today.length > 0) showPrevButton = true;

    let opsMode = "edit";
    if (today.length === 0) {
      opsMode = "new";
    }

    this.setState({
      deviceSize,
      activeCommunityId: this.props.communityId,
      presentSeqNo: today.length + 1,
      showPrevButton,
      opsMode,
      today,
      s3SubBucket,
    });

    if (this.props.today) await this.loadToday();
  };

  createSubBucket = async () => {
    return "cid-" + this.props.communityId + "-news-digital-assets-bucket";
  };

  loadToday = async () => {
    let today = [];
    let newToday = {};
    if (this.props.today.lenght > 1) {
      this.props.today.forEach((obj) => {
        newToday = {
          newsTitle: obj.newsTitle,
          news: obj.news,
          postDate: obj.postDate,
          seqNo: obj.seqNo,
          fileUploads: obj.fileUploads,
          deleteFlag: false,
        };
        today.push(newToday);
      });
      await this.setState({
        today,
        seqNo: this.props.today.length,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
    });
  };

  handleSelect = async (sel) => {
    // console.log("Editor sel:", sel);
    this.setState({
      showVideoUploadPanel: false,
      showImageUploadPanel: false,
      imageFlag: false,
      videoFlag: false,
      previousFlag: false,
      nextFlag: false,
      addFlag: false,
      priorityFlag: false,
      newFlag: false,
      justSaved: false,
      showDeleteFlag: false,
      editFlag: true,
    });

    if (sel === "publish") {
      let pub = await this.handlePublish();
      //   console.log("pub:", pub);
      if (pub.status === "success") {
        this.setState({
          justSaved: true,
          showButtonPanel: false,
        });
        // this.props.returnToCaller();
      }
    }
    if (sel === "cancel") {
      // Check to see if this.state.today has any new uploads. If yes ...
      // remove those from the database before exiting.
      this.props.returnToCaller();
    }
    if (sel === "image") {
      let lastDASize = 0;
      if (this.state.s3ImageFileData) {
        lastDASize = this.state.s3ImageFileData.size;
      }
      this.setState({
        showEditorPanel: false,
        showImageUploadPanel: true,
        lastDASize,
      });
    }
    if (sel === "video") {
      let lastDASize = 0;
      if (this.state.s3VideoFileData) {
        lastDASize = this.state.s3VideoFileData.size;
      }
      this.setState({
        showEditorPanel: false,
        showVideoUploadPanel: true,
        lastDASize,
      });
    }
    if (sel === "prev") {
      await this.handlePrevious();
      this.setState({
        previousFlag: true,
        editFlag: true,
      });
    }
    if (sel === "next") {
      await this.handleNext();
      await this.setState({
        nextFlag: true,
      });
    }
    if (sel === "new") {
      await this.handleNew();
      await this.setState({
        newFlag: true,
        showDeleteFlag: false,
        showUndoFlag: false,
      });
    }
    if (sel === "add") {
      await this.setState({
        addFlag: true,
      });
    }
    if (sel === "delete") {
      await this.handleDelete();
    }
    if (sel === "undo") {
      await this.markAsUnDeleted();
    }
    if (sel === "position") {
      await this.handlePrioritize();
      await this.setState({
        priorityFlag: true,
      });
    }
    if (sel === "save") {
      await this.handleSave();
      this.setState({
        // saveFlag: true,
        newFlag: false,
        editFlag: true,
      });
    }
  };

  handleDelete = async () => {
    let news = [];
    let size = 0;
    this.state.today.forEach((obj) => {
      if (obj.seqNo !== this.state.presentSeqNo) {
        news.push(obj);
      } else {
        size += obj.fileUploads.size;
      }
    });

    let seqNo = 1;
    this.state.today.forEach((obj) => {
      obj.seqNo = seqNo;
      seqNo++;
    });

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      news,
      sizeAdj: size * -1,
    };
    let url = baandaServer + saveUpdtToday;
    try {
      let delret = await axios.post(url, input);
      if (delret.data.status === "success") {
        this.setState({
          errMsg: "Successfully deleted.",
          errFlag: false,
          today: delret.data.Msg.news,
        });
        this.handleSelect("new");
      } else {
        this.setState({
          errMsg: delret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {}
  };

  markAsUnDeleted = async () => {
    let ptd = [];
    this.state.today.forEach((obj) => {
      if (obj.seqNo === this.state.presentSeqNo) {
        obj.isDeleted = false;
        ptd.push(obj);
      } else {
        ptd.push(obj);
      }
    });
    this.setState({
      today: ptd,
      showUndoFlag: false,
      showDeleteFlag: true,
    });
  };

  validatePost = async () => {
    let msg = "";
    let isValid = "success";
    if (this.state.today && this.state.today.length === 0) {
      msg = "There are no news to publish. Please  cancel to exit.";
      isValid = "error";
    }

    if (this.state.today.length > 30) {
      msg =
        "Max thirty postings are allowed at a time. Please delete old posts to publish a new. Check info for more.";
      isValid = "error";
    }

    return { status: isValid, Msg: msg };
  };

  packageDBInput = async () => {
    // console.log("packageDBInput");

    let data = [];

    let sizeAdj = 0;
    // let deletedFlag = false;
    this.state.today.forEach((obj) => {
      // console.log("obj:", obj);
      if (!obj.isDeleted) {
        if (obj.seqNo === this.state.presentSeqNo) {
          obj.isPublished = true;
        }
        data.push(obj);
      }
    });

    let property = "seqNo";

    let sortednews = arraySortNum(data, property, "dsc");

    // console.log("sortnews:", sortednews);
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      commName: this.props.commName,
      news: sortednews,
      sizeAdj,
      opsMode: this.state.opsMode,
      baandaId: this.props.baandaId,
      toPublish: true,
    };

    return input;
  };

  handlePublish = async () => {
    // console.log("handle Publish...");
    try {
      let url = baandaServer + saveUpdtToday;
      let isValid = await this.validatePost();
      //   console.log("isValid:", isValid);
      if (isValid.status === "success") {
        let input = await this.packageDBInput();
        // console.log("url:", url);
        // console.log("input:", input);
        try {
          await axios.post(url, input);
          //   console.log("*********** ret: ", ret);
          return { status: "success", Msg: "" };
        } catch (err) {
          this.setState({
            errMsg: err.message,
            errFlag: true,
          });
        }
      } else {
        this.setState({
          errMsg: isValid.Msg,
          justSaved: true,
        });
        return { status: "error", Msg: isValid.Msg };
      }
    } catch (err) {
      console.log("handlePublish err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleNew = async () => {
    let seqno = this.state.today.length + 1;
    let showPrevButton = false;
    if (parseFloat(seqno) > 0) showPrevButton = true;

    await this.setState({
      newsTitle: "",
      news: "",
      presentSeqNo: seqno,
      showPrevButton,
      showNextButton: false,
      s3ImageFileData: null,
    });
  };

  handlePrevious = async () => {
    let pseqn = this.state.presentSeqNo;

    let showPrevButton = true;
    if (parseFloat(pseqn) - 1 === 1) {
      showPrevButton = false;
    }
    let showNextButton = false;
    if (parseFloat(pseqn) <= this.state.today.length) showNextButton = true;

    this.state.today.forEach(async (obj) => {
      //   console.log("prev obj:", obj);
      if (obj.seqNo === parseFloat(pseqn) - 1) {
        let imagefu = null,
          videofu = null;
        if (obj.fileUploads.length) {
          obj.fileUploads.forEach((elm) => {
            if (elm.type === "image") {
              imagefu = elm;
            }
            if (elm.type === "video") {
              videofu = elm;
            }
          });
        }

        // console.log("obj.isDeleted:", obj.isDeleted);
        let showDeleteFlag = false;
        let showUndoFlag = false;
        if (obj.isDeleted) showUndoFlag = true;
        if (!obj.isDeleted) showDeleteFlag = true;

        await this.setState({
          newsTitle: obj.newsTitle,
          news: obj.news,
          postDate: obj.postDate,
          seqNo: obj.seqNo,
          fileUploads: obj.fileUploads,
          openTo: obj.openTo,
          presentSeqNo: pseqn - 1,
          publishThisNews: obj.isPublished,
          s3ImageFileData: imagefu,
          s3VideoFileData: videofu,
          showUndoFlag,
          showDeleteFlag,
        });

        // console.log(
        //   "this.state.showDeleteFlag:",
        //   this.state.showDeleteFlag,
        //   " this.state.showUndoFlag:",
        //   this.state.showUndoFlag
        // );
      }
    });
    await this.setState({
      showNextButton: showNextButton,
      showPrevButton,
      errMsg: "",
      errFlag: false,
    });
  };

  handleNext = async () => {
    let pseqn = this.state.presentSeqNo;
    let showNextButton = false;
    if (parseFloat(pseqn) + 1 < this.state.today.length) {
      showNextButton = true;
    }

    this.state.today.forEach(async (obj) => {
      if (obj.seqNo === parseFloat(pseqn) + 1) {
        let imagefu = null,
          videofu = null;
        if (obj.fileUploads.length) {
          obj.fileUploads.forEach((elm) => {
            if (elm.type === "image") {
              imagefu = elm;
            }
            if (elm.type === "video") {
              videofu = elm;
            }
          });
        }

        let showDeleteFlag = false;
        let showUndoFlag = false;
        if (obj.isDeleted) showUndoFlag = true;
        if (!obj.isDeleted) showDeleteFlag = true;

        this.setState({
          newsTitle: obj.newsTitle,
          news: obj.news,
          postDate: obj.postDate,
          seqNo: obj.seqNo,
          fileUploads: obj.fileUploads,
          publishThisNews: obj.isPublished,
          openTo: obj.openTo,
          presentSeqNo: pseqn + 1,
          s3ImageFileData: imagefu,
          s3VideoFileData: videofu,
          showUndoFlag,
          showDeleteFlag,
        });
      }
    });
    this.setState({
      showNextButton,
      showPrevButton: true,
    });
  };

  checkIfDARemoved = () => {
    let canDelete = "success";
    let msg = "";
    this.state.today.forEach((obj) => {
      if (obj.seqNo === this.state.presentSeqNo) {
        if (obj.fileUploads.length > 0) {
          canDelete = "error";
          msg = "Must delete this image / video first to delete the news.";
        }
      }
    });

    return { status: canDelete, Msg: msg };
  };

  handleRemove = async () => {
    let canDel = this.checkIfDARemoved();
    if (canDel.status === "success") {
      this.setState((prevstate) => ({
        deleteFlag: !prevstate.deleteFlag,
      }));
    } else {
      this.setState({
        errMsg: canDel.Msg,
        errFlag: true,
      });
    }
  };

  handleOKToPublish = async () => {
    this.setState((prevstate) => ({
      publishThisNews: !prevstate.publishThisNews,
    }));
  };

  callerOfImageUpload = async (fileData) => {
    let delflg = false;

    if (fileData.ops === "done") {
      if (fileData.ifDeletedFlag) {
        delflg = true;
        await this.setState({
          s3ImageFileData: null,
        });
      } else {
        await this.setState({
          s3ImageFileData: fileData.s3FileData,
        });
      }

      let inputToUpdateDB = await this.packageForDAMgmtInDB(delflg);

      let coopStoreId = 0;
      let displayStoreName = "";
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        displayStoreName = this.props.store.displayStoreName;
      }

      // Execute Save ...
      let input = {
        communityId: this.props.communityId,
        coopStoreId,
        displayStoreName,
        commName: this.props.commName,
        news: inputToUpdateDB.news,
        sizeAdj: inputToUpdateDB.toAdjustSize,
        opsMode: this.state.opsMode,
        s3SubBucket: this.state.s3SubBucket,
        baandaId: this.props.auth.user.baandaId,
        updateDAFlag: true,
      };
      await this.handleSaveDAinDB(input);

      await this.setState({
        // showVideoUploadPanel: false,
        showImageUploadPanel: false,
        showEditorPanel: true,
        imageFlag: true,
        videoFlag: false,
        previousFlag: false,
        nextFlag: false,
        addFlag: false,
        priorityFlag: false,
        // saveFlag: false,
      });
    }
    if (fileData.ops === "cancel") {
      this.setState({
        showImageUploadPanel: false,
        showEditorPanel: true,
      });
    }
  };

  callerOfVedioUpload = async (fileData) => {
    // let newflg = false;
    let delflg = false;

    if (fileData.ops === "done") {
      if (fileData.ifDeletedFlag) {
        delflg = true;
        await this.setState({
          s3VideoFileData: null,
        });
      } else {
        await this.setState({
          s3VideoFileData: fileData.s3FileData,
        });
      }

      let inputToUpdateDB = await this.packageForDAMgmtInDB(delflg);
      // console.log("inputToUpdateDB:", inputToUpdateDB);

      let coopStoreId = 0;
      let displayStoreName = "";
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        displayStoreName = this.props.store.displayStoreName;
      }

      // Execute Save ...
      let input = {
        communityId: this.props.communityId,
        coopStoreId,
        displayStoreName,
        commName: this.props.commName,
        news: inputToUpdateDB.news,
        sizeAdj: inputToUpdateDB.toAdjustSize,
        opsMode: this.state.opsMode,
        baandaId: this.props.auth.user.baandaId,
        updateDAFlag: true,
      };
      await this.handleSaveDAinDB(input);

      this.setState({
        showVideoUploadPanel: false,
        showEditorPanel: true,
        imageFlag: false,
        videoFlag: true,
        previousFlag: false,
        nextFlag: false,
        addFlag: false,
        priorityFlag: false,
        // saveFlag: false,
      });
    }
    if (fileData.ops === "cancel") {
      this.setState({
        showVideoUploadPanel: false,
        showEditorPanel: true,
      });
    }
  };

  handleSaveDAinDB = async (input) => {
    let url = baandaServer + saveUpdtToday;
    // console.log("handleSaveDAinDB url:", url, " input:", input);
    try {
      let daret = await axios.post(url, input);
      // console.log("daret.data:", daret.data);
      if (daret.data.status === "success") {
        // console.log("daret.data.Msg:", daret.data.Msg);
        await this.manageNewDBUpdate(daret.data.Msg);
        this.setState({
          errMsg: "Successfuly Updated.",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: daret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  // After saving data in DB, re-initiate the state values with the latest values from the DB.
  manageNewDBUpdate = async (newLoad) => {
    // console.log("newLoad:", newLoad);
    let today = [];
    let news = "";
    let newsTitle = "";
    let s3ImageFileData = null;
    let s3VideoFileData = null;
    let publishThisNews = false;

    newLoad.news.forEach((obj) => {
      let newToday = {
        newsTitle: obj.newsTitle,
        news: obj.news,
        postDate: obj.postDate,
        seqNo: obj.seqNo,
        fileUploads: obj.fileUploads,
        isPublished: obj.isPublished,
        openTo: obj.openTo,
      };
      today.push(newToday);

      if (this.state.presentSeqNo === obj.seqNo) {
        news = obj.news;
        newsTitle = obj.newsTitle;
        obj.fileUploads.forEach((da) => {
          if (da.type === "image") s3ImageFileData = da;
          if (da.type === "video") s3VideoFileData = da;
        });
        publishThisNews = obj.isPublished;
      }
    });

    await this.setState({
      today,
      news,
      newsTitle,
      s3VideoFileData,
      s3ImageFileData,
      publishThisNews,
      opsMode: "edit",
    });
  };

  validateSave = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.newsTitle === "") {
      isValid = "error";
      msg = "News must have a Head Line. ";
    }
    if (this.state.news === "") {
      isValid = "error";
      msg += "News must have a body, or content. ";
    }

    if (this.state.deleteFlag) {
      if (
        this.state.today[this.state.presentSeqNo - 1].fileUploads.length > 0
      ) {
        isValid = "error";
        msg +=
          "All digital content (image/video) must be deleted prior to deleting the news.";
      }
    }

    return { status: isValid, Msg: msg };
  };

  retPositioningSave = async (news) => {
    // console.log('in retPositioningSave news:', news);
    let coopStoreId = 0;
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
    }
    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      news,
      updateDAFlag: false,
    };
    // console.log('input:', input)
    await this.handleSaveDAinDB(input);

    return true;
  };

  handleSave = async () => {
    let valid = await this.validateSave();

    let coopStoreId = 0;
    let displayStoreName = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      displayStoreName = this.props.store.displayStoreName;
    }
    if (valid.status === "success") {
      if (this.state.today.length > 0) {
        let news = [];
        if (this.state.presentSeqNo > this.state.today.length) {
          let fileUploads = [];
          if (this.state.s3ImageFileData)
            fileUploads.push(this.state.s3ImageFileData);
          if (this.state.s3VideoFileData)
            fileUploads.push(this.state.s3VideoFileData);
          news = [...this.state.today];
          let newsObj = {
            fileUploads,
            news: this.state.news,
            newsTitle: this.state.newsTitle,
            journalistsName: this.props.auth.user.name,
            isPublished: this.state.publishThisNews,
            openTo: this.state.openTo,
            seqNo: this.state.presentSeqNo,
            showJournalist: true,
          };
          news.push(newsObj);
        } else {
          this.state.today.forEach((obj) => {
            if (this.state.presentSeqNo === obj.seqNo) {
              let newsObj = {
                fileUploads: obj.fileUploads,
                news: this.state.news,
                newsTitle: this.state.newsTitle,
                journalistsName: this.props.auth.user.name,
                isPublished: this.state.publishThisNews,
                openTo: this.state.openTo,
                seqNo: this.state.presentSeqNo,
                showJournalist: true,
                isDeleted: obj.isDeleted,
              };
              news.push(newsObj);
            } else {
              news.push(obj);
            }
          });
        }

        let input = {
          communityId: this.props.communityId,
          commName: this.props.commName,
          coopStoreId,
          displayStoreName,
          news: news,
          sizeAdj: 0, // Size has been considered when the DA was loaded
          opsMode: this.state.opsMode,
          updateDAFlag: true,
          s3SubBucket: this.state.s3SubBucket,
          toPublish: false,
        };

        await this.handleSaveDAinDB(input);
      } else {
        let fileUploads = [];
        if (this.state.s3ImageFileData)
          fileUploads.push(this.state.s3ImageFileData);
        if (this.state.s3VideoFileData)
          fileUploads.push(this.state.s3VideoFileData);
        let news = [];
        let newsObj = {
          fileUploads,
          news: this.state.news,
          newsTitle: this.state.newsTitle,
          journalistsName: this.props.auth.user.name,
          isPublished: this.state.publishThisNews,
          seqNo: this.state.presentSeqNo,
          showJournalist: true,
        };
        news.push(newsObj);
        let input = {
          communityId: this.props.communityId,
          commName: this.props.commName,
          coopStoreId,
          displayStoreName,
          news: news,
          sizeAdj: 0, // Size has been considered when the DA was loaded
          opsMode: this.state.opsMode,
          updateDAFlag: true,
          toPublish: false,
        };

        await this.handleSaveDAinDB(input);
      }
    } else {
      this.setState({
        errMsg: valid.Msg,
        errFlag: true,
      });
    }
  };

  // Package so the database is updated with latest image / video state
  packageForDAMgmtInDB = async (delflg) => {
    // let delSize = 0;
    let addedSize = 0;
    let fileUploads = [];
    let newslist = [];
    let lastDASize = this.state.lastDASize;

    let newTypeAdded = "";
    if (this.state.showImageUploadPanel) {
      if (this.state.s3ImageFileData)
        addedSize = this.state.s3ImageFileData.size;

      newTypeAdded = "image";
    }
    if (this.state.showVideoUploadPanel) {
      if (this.state.s3VideoFileData)
        addedSize = this.state.s3VideoFileData.size;
      newTypeAdded = "video";
    }

    if (this.state.presentSeqNo > this.state.today.length) {
      newslist = [...this.state.today];

      if (this.state.showImageUploadPanel) {
        fileUploads.push(this.state.s3ImageFileData);
      }
      if (this.state.showVideoUploadPanel) {
        fileUploads.push(this.state.s3VideoFileData);
      }

      let news = "To be filled in ...";
      let newsTitle = "Empty content - to be filled in";
      if (this.state.news !== "") news = this.state.news;
      if (this.state.newsTitle !== "") newsTitle = this.state.newsTitle;

      let newObj = {
        news,
        newsTitle,
        position: this.state.presentSeqNo,
        seqNo: this.state.presentSeqNo,
        journalistsName: this.props.auth.user.name,
        isPublished: this.state.publishThisNews,
        openTo: this.state.openTo,
        deleted: false,
        fileUploads,
      };

      newslist.push(newObj);
    } else {
      this.state.today.forEach((da) => {
        if (da.seqNo !== this.state.presentSeqNo) {
          newslist.push(da);
        } else {
          let fload = [];

          da.fileUploads.forEach((ful) => {
            if (delflg) {
              if (newTypeAdded !== ful.type) {
                fload.push(ful);
              } else {
                console.log("noting pushed ...");
              }
            } else {
              if (newTypeAdded !== ful.type) {
                fload.push(ful);
              }
            }
          });

          if (newTypeAdded === "image" && this.state.s3ImageFileData) {
            fload.push(this.state.s3ImageFileData);
          }
          if (newTypeAdded === "video" && this.state.s3VideoFileData) {
            fload.push(this.state.s3VideoFileData);
          }

          let newObj = {
            news: da.news,
            newsTitle: da.newsTitle,
            position: this.state.presentSeqNo,
            seqNo: this.state.presentSeqNo,
            journalistsName: this.props.auth.user.name,
            isPublished: this.state.publishThisNews,
            openTo: this.state.openTo,
            fileUploads: fload,
          };

          newslist.push(newObj);
        }
      });
    }

    let toAdjustSize = addedSize - lastDASize;
    return { news: newslist, toAdjustSize };
  };

  handlePrioritize = async () => {
    await this.setState({
      showPriorityFlag: true,
      showButtonPanel: false,
      showEditorPanel: false,
    });
  };

  returnFromPrioritize = async (input, ops) => {
    if (ops === "modified") {
      await this.retPositioningSave(input);
    }

    await this.setState({
      showPriorityFlag: false,
      showButtonPanel: true,
      showEditorPanel: true,
      justSaved: true,
      today: input,
      errMsg: "",
      news: "",
      newsTitle: "",
      presentSeqNo: input.length + 1,
      s3ImageFileData: null,
      s3VideoFileData: null,
      openTo: "public",
      publishThisNews: false,
    });
  };

  handleExposureType = async (e) => {
    await this.setState({
      openTo: e.target.value,
    });
  };

  firstLetterUpperCase = (string) => {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    console.log("Editor...");

    let openToPanel;
    openToPanel = (
      <div className="row news-expose-row">
        <div className="col text-center radio-font-style">
          <strong>For&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="public"
                checked={this.state.openTo === "public"}
                onChange={this.handleExposureType}
              />{" "}
              Public
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="member"
                checked={this.state.openTo === "member"}
                onChange={this.handleExposureType}
              />{" "}
              Members
            </label>
          </div>
        </div>
      </div>
    );

    let aPostButtons = (
      <div className="row">
        <div className="col-3">{openToPanel}</div>
        {this.state.newsTitle.length > 0 && this.state.news.length > 0 ? (
          <div className="col-6 text-center">
            <button
              className={`${
                !this.state.imageFlag ? "btn_reg_60" : "btn_reg_60_active"
              }`}
              onClick={() => this.handleSelect("image")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Image
            </button>
            <button
              className={`${
                !this.state.videoFlag ? "btn_reg_60" : "btn_reg_60_active"
              }`}
              onClick={() => this.handleSelect("video")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Video
            </button>
            <button
              className="btn_reg_60"
              onClick={() => this.handleSelect("save")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Save
            </button>
            <button
              className="btn_reg_70"
              onClick={() => this.handleSelect("publish")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Publish
            </button>
            {this.state.deleteFlag ? (
              <button
                className="btn_reg_70"
                onClick={() => this.handleSelect("delete")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Delete
              </button>
            ) : null}
            {this.state.showUndoFlag ? (
              <button
                className="btn_reg_70"
                onClick={() => this.handleSelect("undo")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Undo <i className="fa fa-undo" />
              </button>
            ) : null}
          </div>
        ) : (
          <div className="col-6 text-center new-start-editor-msg">
            Start New Bulletin - News Editorial
          </div>
        )}
      </div>
    );

    let buttonPanel;
    buttonPanel = (
      <div>
        {this.state.showPrevButton ? (
          <button
            className={`${
              !this.state.previousFlag ? "btn_reg_70" : "btn_reg_70_active"
            }`}
            onClick={() => this.handleSelect("prev")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Previous
          </button>
        ) : null}
        {this.state.showNextButton ? (
          <button
            className={`${
              !this.state.nextFlag ? "btn_reg_60" : "btn_reg_60_active"
            }`}
            onClick={() => this.handleSelect("next")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Next
          </button>
        ) : null}

        <button
          className={`${
            !this.state.newFlag ? "btn_reg_65" : "btn_reg_65_active"
          }`}
          onClick={() => this.handleSelect("new")}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          New
        </button>
        {this.state.today.length > 1 ? (
          <button
            className={`${
              !this.state.priorityFlag ? "btn_reg_70" : "btn_reg_70_active"
            }`}
            onClick={() => this.handleSelect("position")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Position
          </button>
        ) : null}
      </div>
    );

    let inputPanel;
    inputPanel = (
      <div>
        <div className="row editor-row">
          <div className="col-2 text-right head-line-lbl">News Headline</div>
          <div className="col-10 text-left">
            <input
              name="newsTitle"
              type="text"
              value={this.state.newsTitle}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="news-caption"
              placeholder="Enter a headline in 50 chars or less"
            />
          </div>
        </div>
        <div className="row editor-row">
          <div className="col">
            <textarea
              name="news"
              maxLength="2000"
              placeholder="Describe the news or post."
              rows="10"
              wrap="hard"
              spellCheck="true"
              className="news-description"
              onChange={this.onChange}
              value={this.state.news}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-9 text-center news-description-msg">
            Write this post in 2000 characters or less.
          </div>
          <div className="col-3 text-left news-description-msg">
            Chars used: {this.state.news.length}
          </div>
        </div>
        <div>
          <div className="row">
            {this.state.presentSeqNo <= this.state.today.length ? (
              <div className="col-6 remove-seq-editor">
                Remove this post:{" "}
                <Checkbox
                  checked={this.state.deleteFlag}
                  onChange={this.handleRemove}
                />
              </div>
            ) : (
              <div className="col-6 remove-seq-editor">&nbsp;</div>
            )}

            <div className="col-6 remove-seq-editor">
              Message Seq # {this.state.presentSeqNo}
            </div>
          </div>
        </div>
      </div>
    );

    let modalButton = (
      <button
        onClick={this.openAlertModal}
        className="btn_info_main"
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        onClick={() => this.handleSelect("cancel")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let imgRequestorMsg, videoRequestorMsg;
    if (this.state.deviceSize === "small") {
      imgRequestorMsg = "Image - MaxSize: " + maxImageSize / 1000000 + " MB";
      videoRequestorMsg = "Vedio - MaxSize: " + maxVedioSize / 1000000 + " MB";
    } else {
      imgRequestorMsg =
        "Image (png, jpg, bmp, gif, tiff, webp) MaxSize: " +
        maxImageSize / 1000000 +
        "MB";
      videoRequestorMsg =
        "Vedio (mp4, webm)- MaxSize: " + maxVedioSize / 1000000 + " MB";
    }

    let loadImagePanel;
    if (this.state.showImageUploadPanel) {
      loadImagePanel = (
        <div className="text-center">
          <LoadFileToS3
            returnToCaller={this.callerOfImageUpload}
            communityId={this.state.activeCommunityId}
            fileType={imageFileTypes}
            maxSize={maxImageSize}
            s3subdir={this.state.s3SubBucket}
            requestor={imgRequestorMsg}
            s3FileData={this.state.s3ImageFileData}
            type="image"
          />
        </div>
      );
    }

    let loadVedioPanel;
    if (this.state.showVideoUploadPanel) {
      loadVedioPanel = (
        <div className="text-center">
          <LoadFileToS3
            returnToCaller={this.callerOfVedioUpload}
            communityId={this.state.activeCommunityId}
            fileType={videoFileTypes}
            maxSize={maxVedioSize}
            s3subdir={this.state.s3SubBucket}
            requestor={videoRequestorMsg}
            s3FileData={this.state.s3VideoFileData}
            type="video"
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.showEditorPanel) {
      outputPanel = (
        <div className="billboard-editor-box">
          {this.props.store ? (
            <div className="row">
              <div className="col-10 text-center">
                <h3>
                  {this.firstLetterUpperCase(this.props.store.displayStoreName)}
                  &nbsp;Editor's Office
                </h3>
              </div>
              <div className="col-2 text-right">
                {modalButton}
                {backButton}{" "}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-10 text-center">
                <h3>
                  {this.firstLetterUpperCase(this.props.commName)}&nbsp;Editor's
                  Office
                </h3>
              </div>
              <div className="col-2 text-center">
                {modalButton}
                {backButton}{" "}
              </div>
            </div>
          )}

          {this.state.newFlag || this.state.editFlag ? inputPanel : null}
          <div className="upper-button-pos">{aPostButtons}</div>
          <hr />
          {this.state.showButtonPanel ? (
            <div className="text-center lower-button-pos">{buttonPanel}</div>
          ) : null}

          <div
            className={
              this.state.errFlag
                ? "text-center editor-err-msg"
                : "text-center editor-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    let priorityPanel;
    if (this.state.showPriorityFlag) {
      priorityPanel = (
        <div>
          <Prioritize
            today={this.state.today}
            returnToCallerx={this.returnFromPrioritize}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        {priorityPanel}
        {loadVedioPanel}
        {loadImagePanel}
        <ModalContainer />
      </div>
    );
  }
}

Editor.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Editor));
