import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import BasicIntro from "../templates/utils/CommonIntro";
// import TagAnItem from "../templates/utils/TagAnItem";
import FrameCompose from "../templates/utils/FrameCompose";
import SliderReview from "./review/SliderReview";
 

import "./SliderCompose.css";

import sliderComp from "../../../../../../../images/sliderComp.jpg";

import SliderTestSend from "./review/SliderTestSend";
import UploadCompositionDA from "../daloads/UploadCompositionDA";
 

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";
const getABroadcast = "/routes/interact/getABroadcast?";
 
// const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1144210000";

class SliderCompose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      frameButtonFlag: false,
      introBtnFlag: false,
      tagItemBtnFlag: false,
      reviewBtnFlag: false,
      testSendBtnFlag: false,

      showTemplateOnPopup: false,

      theBroadcast: null,
      broadcastId: 0,
      compositionComponent: "",
 
      openIntroFlag: false,
      openFrameFlag: false,
      // openTagItemFlag: false,
      openReviewFlag: false,
      openTestSendFlag: false,

      imageType: "",
      daType: "image",

      errMsg: "",
      errFlag: false,
      deleteMsg: "To delete a frame, click edit (pencil button), click image then delete it."
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getTheBroadcast();

    this.setState({
      deviceSize,
      broadcastId: this.props.theBroadcast.broadcastId,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTheBroadcast = async () => {
    // console.log("in getTheBroadcast");
    let params = "broadcastId=" + this.props.theBroadcast.broadcastId;

    let url = baandaServer + getABroadcast + params;
    // console.log("getTheBroadcast url:", url);
    try {
      let getbrd = await axios.get(url);
      // console.log("getbrd:", getbrd);
      if (getbrd.data.status === "success") {
        this.setState({
          theBroadcast: getbrd.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  testComposition = async () => {
    let url = baandaServer + saveBroadcastWip;
    let input = await this.packageForDb();
    // console.log("testComposition input:", input);
    try {
      let compret = await axios.post(url, input);
      // console.log("compret:", compret);
      if (compret.data.status === "success") {
        this.setState({
          theBroadcast: compret.data.Msg,
          errFlag: false,
          errMsg: "Successfully Updated",
        });
      } else {
        this.setState({
          errFlag: false,
          errMsg: compret.data.Msg,
        });
      }
    } catch (err) {
      this.setState({
        errFlag: false,
        errMsg: err.message,
      });
    }
  };

  showTheTemplate = async () => {
    // console.log("showTheTemplate");
    this.setState({
      showTemplateOnPopup: true,
    });
  };

  exitFromPopup = async () => {
    this.setState({
      showTemplateOnPopup: false,
    });
  };

  returnToBrCompose = async () => {
    // console.log("this.state.theBroadcast:", this.state.theBroadcast);
    this.props.returnToCaller(this.state.theBroadcast);
  };

  handleSliderInput = async (type) => {
    // console.log("handle Banner Input type: ", type);
    this.setState({
      daType: "",
      introBtnFlag: false,
   
      openIntroFlag: false,
    
      frameButtonFlag: false,

      tagItemBtnFlag: false,
      // openTagItemFlag: false,
      openFrameFlag: false,
      bannerBtnFlag: false,

      openReviewFlag: false,
      showTemplateOnPopup: false,
      tvBtnFlag: false,
      testSendBtnFlag: false,
      openTestSendFlag: false,
    });
    
    // console.log("here in handleBanner ... this.state:", this.state);

    switch (type) {

      case "banner":
        this.setState({
          daType: "image",
          compositionComponent: "banner",
          openDAUploadFlag: true,
          bannerBtnFlag: true,
        });
        break;

      case "intro":
        this.setState({
          introBtnFlag: true,
          openIntroFlag: true,
          compositionComponent: "intro",
        });
        break;

      case "frames":
        this.setState({
          daType: "image",
          frameButtonFlag: true,
          openFrameFlag: true,
          compositionComponent: "frames",
        });
        break;

      // case "tagitem":
      //   this.setState({
      //     tagItemBtnFlag: true,
      //     openTagItemFlag: true,
      //     compositionComponent: "tagitem",
      //   });
      //   break;

      case "test":
        this.setState({
          testSendBtnFlag: true,
          openTestSendFlag: true,
          compositionComponent: "test",
        });
        break;

      case "review":
        await this.getTheBroadcast();
        this.setState({
          reviewBtnFlag: true,
          openReviewFlag: true,
          compositionComponent: "review",
        });
        break;

      case "tv":
        this.setState({
          tvBtnFlag: true,
          showTemplateOnPopup: true,
          compositionComponent: "tv",
        });
        break;

      default:
        console.log("xxx");
    }

    await this.getTheBroadcast();
    // console.log('>> this.state: ', this.state);
  };

  returnFromUploadCompositionDA = async () => {
    // console.log("returnFromUploadCompositionDA ...");
    await this.getTheBroadcast();

    this.setState({
      compositionComponent: this.state.compositionComponent,
      daType: this.state.daType,
      //   openDAUploadFlag: false,
      //   openBodyFlag: false,
      reviewBtnFlag: false,
      openReviewFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("SliderCompose...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToBrCompose}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let backFromTemplatePop = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let showTemplatePanel;
    if (this.state.showTemplateOnPopup) {
      showTemplatePanel = (
        <div className="basic-comp-show-template-box">
          <div className="template-pop-header text-center">
            Basic Email Teamplate View &nbsp;{backFromTemplatePop}
          </div>
          <div className="text-center pop-basic-image-placement">
            <img
              src={sliderComp}
              alt="Template 1"
              hight="530"
              width="320"
            />
          </div>
        </div>
      );
    }

    let sideButtonPanel = (
      <div>
        <div className="row">
          <div className="col">
            <button
              className={
                this.state.bannerBtnFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("banner")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Banner
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className={
                this.state.introBtnFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("intro")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Intro
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className={
                this.state.frameButtonFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("frames")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Frames
            </button>
          </div>
        </div>
        {/* <div className="row">
          <div className="col">
            <button
              className={
                this.state.tagItemBtnFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("tagitem")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              TagItem
            </button>
          </div>
        </div> */}

        <div className="row">
          <div className="col text-center basic-ops-left">Ops</div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className={
                this.state.testSendBtnFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("test")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Test
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <button
              className={
                this.state.cvBtnFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("review")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Review
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className={
                this.state.tvBtnFlag ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleSliderInput("tv")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              -- TV --
            </button>
          </div>
        </div>
      </div>
    );

    let activePanel;

    if (this.state.openDAUploadFlag) {
      console.log('>>> this.state.compositionComponent:', this.state.compositionComponent);
      activePanel = (
        <div>
          <UploadCompositionDA
            theBroadcast={this.state.theBroadcast}
            communityId={this.props.communityId}
            broadcastId={this.state.broadcastId}
            selectedTemplate={this.props.selectedTemplate}
            compositionComponent={this.state.compositionComponent}
            returnToCaller={this.returnFromUploadCompositionDA}
            daType={this.state.daType}
          />
        </div>
      );
    }

    if (this.state.openIntroFlag) {
      activePanel = (
        <div>
          <BasicIntro
            theBroadcast={this.state.theBroadcast}
            communityId={this.props.communityId}
            broadcastId={this.state.broadcastId}
            selectedTemplate={this.props.selectedTemplate}
            compositionComponent={this.state.compositionComponent}
            returnToCaller={this.returnFromUploadCompositionDA}
          />
        </div>
      );
    }

    if (this.state.openFrameFlag) {
      activePanel = (
        <div>
          <FrameCompose
            theBroadcast={this.state.theBroadcast}
            communityId={this.props.communityId}
            broadcastId={this.state.broadcastId}
            selectedTemplate={this.props.selectedTemplate}
            compositionComponent={this.state.compositionComponent}
            returnToCaller={this.returnFromUploadCompositionDA}
          />
        </div>
      );
    }

    // if (this.state.openTagItemFlag) {
    //   activePanel = (
    //     <div>
    //       <TagAnItem
    //         theBroadcast={this.state.theBroadcast}
    //         communityId={this.props.communityId}
    //         broadcastId={this.state.broadcastId}
    //         selectedTemplate={this.props.selectedTemplate}
    //         compositionComponent={this.state.compositionComponent}
    //         returnToCaller={this.returnFromUploadCompositionDA}
    //       />
    //     </div>
    //   );
    // }

    if (this.state.openReviewFlag) {
      activePanel = (
        <div>
          <SliderReview
            theBroadcast={this.state.theBroadcast}
            communityId={this.props.communityId}
            broadcastId={this.state.broadcastId}
            selectedTemplate={this.props.selectedTemplate}
            compositionComponent={this.state.compositionComponent}
            yourName={this.props.auth.user.name}
            returnToCaller={this.returnFromUploadCompositionDA}
          />
        </div>
      );
    }

    if (this.state.openTestSendFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      activePanel = (
        <div>
          <SliderTestSend
            theBroadcast={this.state.theBroadcast}
            communityId={this.props.communityId}
            broadcastId={this.state.broadcastId}
            selectedTemplate={this.props.selectedTemplate}
            compositionComponent={this.state.compositionComponent}
            yourName={this.props.auth.user.name}
            returnToCaller={this.returnFromUploadCompositionDA}
            store={store}
          />
        </div>
      );
    }

    let outputPanel = (
      <div>
        <div className="row text-center ">
          <div className="col-6 basic-compose-header text-center">
            Slider Composition &nbsp;&nbsp;{infoModalButton}&nbsp;
            {backButton}
          </div>
          <div className="col-6 basic-compose-header-name text-center">
            Name: {this.props.theBroadcast.broadcastName}
          </div>
        </div>
        {showTemplatePanel}
        <div className="row">
          <div className="col-1 text-center">{sideButtonPanel}</div>
          <div className="col-11 text-left">
            <div className="basic-function-box">{activePanel}</div>
            <div
              className={
                this.state.errFlag
                  ? "text-center camp-comp-msg-err"
                  : "text-center camp-comp-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
        {this.state.openFrameFlag ? <div className="slider-delete-msg">{this.state.deleteMsg}</div> : null}
        
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SliderCompose.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SliderCompose));
