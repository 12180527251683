import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
// import moment from "moment";

import ReactLoading from "react-loading";

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import UpdateCompany from "./UpdateCompany";
import UpdateExternaAccount from "./UpdateExternalAccount";
import UpdatePerson from "./UpdatePerson";
import UpdateIdentity from "./UpdateIdentity";
import UpdateBizFinalStep from "./UpdateBizFinalStep";

import "./UpdateSetup.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
// const checkAddressValidity = "/routes/shared/checkAddressValidity?";
const getAccountFull = "/routes/stripe/account/getAccountFull?";

const readMeCode = "1151110000";

class UpdateSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      stripeAccId: "",
      businessType: "", // may be, remain in props
      updateStartFlag: true,

      flowIndex: 0,
      nextFlag: false,
      backFlag: false,

      bpmcc: "7399",
      bpname: "",
      bpproduct_description: "",
      bpurl: "",

      business_profile: {},
      company: {},

      person: {},

      numberOfPerson: 0,

      external_account: {},

      verification: {},

      id_files: {},

      individual: {
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
          country: "US",
        },
        // dob: moment(),
        dob: new Date(),
        email: "", // to be loaded from props.auth.user.email
        first_name: "",
        last_name: "",
        gender: "", // loaded from user.profile
        phone: "",
        ssn_last_4: "",
        id_number: "", // SSN for USA,
        verification: {
          additional_document: {
            back: "", // looking for a blob here. So, a reference would require user to load a jog, png, pdf less than 10MB,
            front: "",
          },
          document: {
            purpose: "identity_document",
            back: "", // looking for a blob here. So, a reference would require user to load a jog, png, pdf less than 10MB,
            front: "",
          },
        },
        // loadingFlag: false,
        stripe: {},
      },

      requested_capabilities: ["card_payments", "transfers"],
      loadingFlag: false,
      businessProfileSkip: false,
      companySkip: false,
      externalAccountSkip: false,
      personSkip: false,
      IdpictureSkip: false,
      noUpdateFlag: false,
      ccdata: {},
      deviceSize: "",
      communityId: 0,
      commonButtonsFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let companySkip = false;
    let personSkip = false;
    let businessProfileSkip = false;
    let externalAccountSkip = false;
    if (this.props.pendingReqLength === 0) {
      companySkip = true;
      personSkip = true;
      businessProfileSkip = true;
      externalAccountSkip = true;
    }

    await this.setState({
      deviceSize,
      loadingFlag: true,
      companySkip,
      personSkip,
      businessProfileSkip,
      externalAccountSkip,
    });

    await this.loadDataFromStripe();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  loadDataFromStripe = async () => {
    try {
      let param =
        "accId=" +
        this.props.stripeAccId +
        "&communityId=" +
        this.props.communityId;
      let url = baandaServer + getAccountFull + param;

      let acc = await axios.get(url);
      // console.log(">>>>>>>>> acc:", acc.data.msg);
      let dacc = acc.data.msg.account;
      // console.log("============>>> dacc:", dacc);
      let person = acc.data.msg.person;
      let bstripe = acc.data.msg.communityStripe;
      // console.log('===>> bstripe:', bstripe);
      let comp = null,
        extac = null;
      if (dacc.company) {
        comp = dacc.company;
      }
      if (dacc.external_accounts) extac = dacc.external_accounts;
      // console.log("dacc.external_accounts:", extac);
      // console.log("here 111");
      await this.loadCompanyDataFromStripe(comp, bstripe.stripe);
      // console.log("here 222");
      await this.loadExternalAccDataFromStripe(extac, bstripe.stripe);
      // console.log("here 333");
      await this.loadPersonDataFromStripe(dacc.id, person, bstripe.stripe);
      // console.log("here 444");
      await this.initiateIdImages(dacc.id, bstripe.stripe); // Research if Stripe does respond to with the orignal file. Will see, if they do when retrieve a person.
      // console.log("here 555");

      // console.log(">>>> Account dacc:", dacc);
      let bpmcc, bpname, bpproduct_description, bpurl;
      if (dacc.business_profile.mcc) {
        bpmcc = dacc.business_profile.mcc;
      } else bpmcc = "7399";
      if (dacc.business_profile.name) {
        bpname = dacc.business_profile.name;
      } else bpname = "";
      if (dacc.business_profile.product_description) {
        bpproduct_description = dacc.business_profile.product_description;
      } else {
        bpproduct_description = "";
      }

      if (dacc.business_profile.url) {
        bpurl = dacc.business_profile.url;
      } else bpurl = "";
      // console.log('--->> this.props.stripeAccId:', this.props.stripeAccId);

      let bprof = {
        mcc: bpmcc,
        business_name: bpname,
        product_description: bpproduct_description,
        url: bpurl,
      };

      await this.setState({
        communityId: this.props.communityId,
        bpmcc,
        bpname,
        bpproduct_description,
        bpurl,
        stripeAccId: this.props.stripeAccId,
        businessType: this.props.businessType,
        loadingFlag: false,
        stripe: bstripe,
        commonButtonsFlag: true,
        business_profile: bprof,
      });

      // console.log('---->> this.state.stripeAccId;', this.state.stripeAccId);
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  loadCompanyDataFromStripe = async (comp, stripe) => {
    // console.log("loadCompanyDataFromStripe comp:", comp);
    let einno = "";
    // console.log('loadCompanyDataFromStripe stripe:', stripe);
    // console.log('loadCompanyDataFromStripe stripe.tax_id:', stripe.tax_id);

    if (stripe.tax_id) {
      einno = stripe.tax_id;
    }
    // console.log('>>>>>> einno:', einno);
    let company;
    if (!comp.name) {
      company = {
        address: {
          city: "",
          country: "US",
          line1: "",
          line2: "",
          state: "",
          postal_code: "",
        },
        name: "",
        phone: "",
        tax_id: "",
      };
    } else {
      company = {
        address: {
          city: comp.address.city,
          country: "US",
          line1: comp.address.line1,
          line2: comp.address.line2,
          state: comp.address.state,
          postal_code: comp.address.postal_code,
        },
        name: comp.name,
        phone: comp.phone,
        tax_id: einno,
      };
    }
    // console.log("============company:", company);

    await this.setState({
      company: company,
    });

    return true;
  };

  loadExternalAccDataFromStripe = async (extacc, stripe) => {
    // console.log("loadExternalAccDataFromStripe extacc:", extacc, '  stripe:', stripe);
    let dlen = extacc.data.length;
    let extdata, exta; // local external account
    if (dlen > 0) {
      extdata = extacc.data[0]; // for now, we will only condier record 1 of the array of external_account
    }
    // console.log('dlen:', dlen);
    let baccno = "";
    if (stripe && stripe.bank_account_no) baccno = stripe.bank_account_no;
    // console.log('baccno:', baccno);
    if (dlen === 0) {
      exta = {
        object: "bank_account",
        account_holder_name: "",
        account_holder_type: "company",
        bank_name: "",
        country: "US",
        currency: "usd",
        last4: "",
        routing_number: "",
        account_number: "",
      };
    } else {
      exta = {
        object: "bank_account",
        account_holder_name: extdata.account_holder_name,
        account_holder_type: extdata.account_holder_type,
        bank_name: extdata.bank_name,
        country: extdata.country,
        currency: extdata.currency,
        last4: "",
        routing_number: extdata.routing_number,
        account_number: baccno,
      };
    }
    // console.log('exta:', exta);
    await this.setState({
      external_account: exta,
    });

    return true;
  };

  loadPersonDataFromStripe = async (accId, person, stripe) => {
    // console.log("%%%%%%%%%%%%%%%%%  person:", person, ' stripe:', stripe);

    let per = null,
      perx,
      id = 0;
    let numberOfPerson = 0;
    // let pdata = person.data;
    // console.log('---- pdata:', pdata, ' person.data.length:', person.data.length);

    if (person.data && person.data.length > 0) {
      id = 0; // First element of the array
      numberOfPerson = person.data.length;
    }

    let ssn4 = "";
    if (stripe.person_last4ssn) ssn4 = stripe.person_last4ssn;

    if (person.data.length === 0) {
      perx = {
        object: "person",
        account: accId,
        personId: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        dob: new Date(),
        relationship: {
          director: false, // true or false for the company
          executive: false, // true or false for the company
          owner: false, // true or false for the company
          percent_ownership: null, // number betwee 0 to 100
          representative: false, // true or false for the company
          title: null, // CEO, CIO, COO, President, etc.
        },
        address: {
          city: "",
          country: "US",
          line1: "",
          line2: "",
          postal_code: "",
          state: "",
        },
        ssn_last_4: ssn4,
        verification: {},
      };
    } else {
      // console.log('got here ... why?');
      per = person.data[id];
      // console.log("per:", per);
      // console.log("per.dob:", per.dob);
      // let pdob = moment([per.dob.year, per.dob.month, per.dob.day]);
      let datestr =
        per.dob.day < 10
          ? "0" + per.dob.day.toString()
          : per.dob.day.toString();
      let monthstr =
        per.dob.month < 10
          ? "0" + per.dob.month.toString()
          : per.dob.month.toString();
      let yearstr = per.dob.year.toString();
      // let dateString = datestr+"/"+monthstr+"/"+yearstr;
      let dateString = monthstr + "/" + datestr + "/" + yearstr;
      // console.log('dateString:', dateString);
      let pdob = new Date(dateString);
      // console.log('**********************************');
      // console.log('pdob:', pdob);
      // console.log('**********************************');
      perx = {
        object: "person",
        account: accId,
        personId: stripe.personId,
        first_name: per.first_name,
        last_name: per.last_name,
        email: per.email,
        phone: per.phone,
        dob: pdob,
        relationship: per.relationship,
        address: per.address,
        ssn_last_4: ssn4,
        verification: {},
      };
    }
    await this.setState({
      person: perx,
      numberOfPerson,
    });

    return true;
  };

  // Stripe do not send back ID files
  initiateIdImages = async (accId, stripe) => {
    // console.log("initiateIdImages accId:", accId, "  stripe:", stripe);
    let personId = "";
    let perVerificaionFieldUpId = "",
      perVerificationFieldDownId = "";
    let comVerificaionFieldUpId = "",
      comVerificationFieldDownId = "";

    if (stripe.personId) personId = stripe.personId;
    if (stripe.personVerificaionFileUpId)
      perVerificaionFieldUpId = stripe.personVerificaionFileUpId;
    if (stripe.personVerificaionFiledownId)
      perVerificationFieldDownId = stripe.personVerificaionFiledownId;

    if (stripe.companyVerificaionFileUpId)
      comVerificaionFieldUpId = stripe.companyVerificaionFileUpId;
    if (stripe.companyVerficationFildownId)
      comVerificationFieldDownId = stripe.companyVerficationFildownId;

    let id_files = {
      accId: accId,
      person: {
        personId: personId,
        faceup: {
          verificationFieldId: perVerificaionFieldUpId,
          file: null,
          fileblob: null,
        },
        facedown: {
          verificationFieldId: perVerificationFieldDownId,
          file: null,
          fileblob: null,
        },
      },
      company: {
        faceup: {
          verificationFieldId: comVerificaionFieldUpId,
          file: null,
          fileblob: null,
        },
        facedown: {
          verificationFieldId: comVerificationFieldDownId,
          file: null,
          fileblob: null,
        },
      },
    };

    // console.log("id_files:", id_files);

    await this.setState({
      id_files,
    });

    return true;
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
    // console.log("onChange caphone:", this.state.caphone);
  };

  handleBackBegin = async (data) => {
    this.props.handleBackBegin();
  };

  handleBackFromCompany = async (data, skip) => {
    // console.log("handleBackFromCompany data:", data);
    this.setState({
      flowIndex: 0,
      company: data,
      companySkip: skip,
    });
  };

  handleBackFromExtAcc = async (data, skip) => {
    // console.log("handleBackFromCompany data:", data, ' skip:', skip);

    this.setState({
      flowIndex: 1,
      external_account: data,
      externalAccountSkip: skip,
    });
  };

  handleBackFromPerson = async (data, skip) => {
    // console.log("handleBackFromPerson data:", data);
    this.setState({
      flowIndex: 2,
      person: data,
      personSkip: skip,
    });
  };

  handleBackFromIdentity = async (data, skip) => {
    // console.log("handleBackFromIdentity data:", data);
    this.setState({
      flowIndex: 3,
      id_files: data,
      IdpictureSkip: skip,
    });
  };

  handleBackFromBizFinal = async (data) => {
    // console.log("handleBackFromIdentity data:", data);
    let flowIndex = 4;
    if (this.props.pendingReqLength !== 0) flowIndex = 3;

    this.setState({
      flowIndex,
    });
  };

  handleNext = async () => {
    let bprof = {
      mcc: this.state.bpmcc,
      business_name: this.state.bpname,
      product_description: this.state.bpproduct_description,
      url: this.state.bpurl,
    };
    this.setState({
      flowIndex: 1,
      business_profile: bprof,
    });
  };

  handleNextToCompany = async (data, skip) => {
    this.setState({
      flowIndex: 2,
      company: data, // Load  this to UpdateSetup state.
      companySkip: skip,
    });
  };

  handleNextToExtAcc = async (data, skip) => {
    // console.log('handleNextToExtAcc data:', data, ' skip:', skip);
    await this.setState({
      flowIndex: 3,
      external_account: data,
      externalAccountSkip: skip,
    });
  };

  handleNextToPerson = async (data, skip) => {
    // console.log("handleNextToPerson data:", data, " skip:", skip);

    let flowIndex = 4;

    await this.setState({
      person: data,
      personSkip: skip,
    });

    if (this.props.pendingReqLength !== 0) {
      flowIndex = 5;
      await this.handleNextToIdentity(data, true);
    }

    await this.setState({
      flowIndex,
      // person: data,
      // personSkip: skip,
    });
  };

  handleNextToIdentity = async (data, skip) => {
    await this.setState({
      id_files: data,
      IdpictureSkip: skip,
    });
    // console.log("handleNextToIdentity data:", data, " skip:", skip);
    let noUpdateFlag = false;
    if (this.props.pendingReqLength === 0) {
      if (
        this.state.businessProfileSkip &&
        this.state.companySkip &&
        this.state.externalAccountSkip &&
        this.state.personSkip &&
        this.state.IdpictureSkip
      )
        noUpdateFlag = true;
    } else {
      if (
        this.state.businessProfileSkip &&
        this.state.companySkip &&
        this.state.externalAccountSkip &&
        this.state.personSkip
      )
        noUpdateFlag = true;
    }

    // console.log(">>>>>>>>>>>>>>>>> noUpdateFlag:", noUpdateFlag);

    let business_profile = {
      mcc: this.state.bpmcc,
      name: this.state.bpname,
      product_description: this.state.bpproduct_description,
      url: this.state.bpurl,
    };

    let personId = {
      object: "person",
      personId: this.state.person.personId,
    };

    let ccdata = {
      stripeAccId: this.state.stripeAccId,
      businessType: this.state.businessType,
      business_profile,
      company: this.state.company,
      external_account: this.state.external_account,
      person: this.state.person,
      id_files: this.state.id_files,
      businessProfileSkip: this.state.businessProfileSkip,
      companySkip: this.state.companySkip,
      externalAccountSkip: this.state.externalAccountSkip,
      personSkip: this.state.personSkip,
      IdpictureSkip: this.state.IdpictureSkip,
      numberOfPerson: this.state.numberOfPerson,
      personId: personId,
      communityId: this.state.communityId,
    };
    this.setState({
      ccdata,
      flowIndex: 5,
      noUpdateFlag,
    });
  };

  handleSkip = async () => {
    this.setState((prevstate) => ({
      businessProfileSkip: !prevstate.businessProfileSkip,
    }));
  };

  render() {
    // console.log("updateSetup this.state:", this.state);
    // console.log("updateSetup this.props:", this.props);
    console.log("UpdateSetup...");

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    let commonButtons;
    if (this.state.commonButtonsFlag) {
      commonButtons = (
        <div>
          <button
            onClick={this.handleBackBegin}
            className="btn_reg_60"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Back
          </button>
          &nbsp;
          <button
            onClick={this.handleNext}
            className="btn_reg_60"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Next
          </button>
          &nbsp;
          <button
            className="btn_info_main"
            type="button"
            onClick={this.openAlertModal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-info-circle" />
          </button>
          &nbsp;
        </div>
      );
    }

    let headerPanel;
    if (this.state.flowIndex === 0) {
      headerPanel = (
        <div className="row">
          <div className="col-5 header_text">
            Business Profile&nbsp;(Business)
          </div>
          <div className="col-7 ">{commonButtons}</div>
        </div>
      );
    }

    let bottomMsgPanel = (
      <div className="row">
        <div className="col message_text">
          &nbsp;<font color="red">*</font>&nbsp; are mandatory fields. Please
          click 'Next' to continue. Click ‘Back’ to review and fix data in any
          section.
        </div>
      </div>
    );

    let bpmcc, bpname, bpdesc, bpurl, bpskip;
    if (this.state.deviceSize === "small") {
      bpmcc = "MCC";
      bpname = "Biz-Name";
      bpdesc = "Description";
      bpurl = "Biz Url";
      bpskip = "Skip biz-profile";
    } else {
      bpmcc = "Merchant Category Code";
      bpname = "Business Name";
      bpdesc = "Short Description";
      bpurl = "Business Url";
      bpskip = "Skip business profile for now";
    }

    let businessProfilePanel;
    businessProfilePanel = (
      <div>
        <div className="input-up-height" />
        <div className="row">
          <div className="col-4 bprofile_label text-right">
            <font color="red">*</font>&nbsp;{bpmcc}
          </div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpmcc"
              type="number"
              value={this.state.bpmcc}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="input_mcc"
              pattern="[0-9]"
              min="1000"
              max="9999"
              placeholder="mcc"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 bprofile_label text-right">
            <font color="red">*</font>&nbsp;{bpname}
          </div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpname"
              type="text"
              value={this.state.bpname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_bpname"
              placeholder="business name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 bprofile_label text-right">{bpdesc}</div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpproduct_description"
              type="text"
              value={this.state.bpproduct_description}
              onChange={this.onChange}
              size="150"
              maxLength="150"
              className="input_bpname"
              placeholder="Business Description"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4 bprofile_label text-right">
            <font color="red">*</font>
            {bpurl}
          </div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpurl"
              type="text"
              value={this.state.bpurl}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="input_bpname"
              placeholder="Business URL"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-center biz_updt_confirm_biz">
            <Checkbox
              checked={this.state.businessProfileSkip}
              onChange={this.handleSkip}
            />
            &nbsp;{bpskip}
          </div>
          <div className="col-4 text-center">{uploadingSpin}</div>
        </div>
        <div>{bottomMsgPanel}</div>
      </div>
    );

    let outputPanel;
    if (this.state.flowIndex === 0) {
      outputPanel = (
        <div>
          {headerPanel}
          <div className="profile_input_box fixedsize_ccsetup">
            {businessProfilePanel}
          </div>
        </div>
      );
    }

    if (this.state.flowIndex === 1) {
      outputPanel = (
        <div>
          <UpdateCompany
            handleBack={this.handleBackFromCompany}
            handleNext={this.handleNextToCompany}
            companyData={this.state.company}
            companySkip={this.state.companySkip}
          />
        </div>
      );
    }

    if (this.state.flowIndex === 2) {
      outputPanel = (
        <div>
          <UpdateExternaAccount
            handleBack={this.handleBackFromExtAcc}
            handleNext={this.handleNextToExtAcc}
            externalAccountData={this.state.external_account}
            externalAccountSkip={this.state.externalAccountSkip}
          />
        </div>
      );
    }

    if (this.state.flowIndex === 3) {
      outputPanel = (
        <div>
          <UpdatePerson
            handleBack={this.handleBackFromPerson}
            handleNext={this.handleNextToPerson}
            personData={this.state.person}
            personSkip={this.state.personSkip}
          />
        </div>
      );
    }

    if (this.state.flowIndex === 4 && this.props.pendingReqLength === 0) {
      outputPanel = (
        <div>
          <UpdateIdentity
            handleBack={this.handleBackFromIdentity}
            handleNext={this.handleNextToIdentity}
            IdpictureSkip={this.state.IdpictureSkip}
            fileData={this.state.id_files}
            stripe={this.state.stripe}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    if (this.state.flowIndex === 5) {
      outputPanel = (
        <div>
          <UpdateBizFinalStep
            handleBack={this.handleBackFromBizFinal}
            handleCancel={this.handleBackBegin}
            ccdata={this.state.ccdata}
            noUpdateFlag={this.state.noUpdateFlag}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}

        <ModalContainer />
      </div>
    );
  }
}

UpdateSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateSetup));
