import React, { Component } from "react";

import axios from "axios";

import "./ItemFilter.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCatagories = "/routes/dashboard/getCatagories?";

class ItemFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      filterType: "all",
      catagories: [],
      selheight: 1,

      categorySelected: "",
      itemName: "",

      invClassification: "catalog",
      deviceSize: "",
      itemType: "goods",
    };
  } 

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });
  };

  returnToItemList = async () => {
    let data = {
      ops: "cancel",
    };
    this.props.returnToCaller(data);
  };

  handleFilter = () => {
    let retData = {
      ops: "fetch",
      invClassification: this.state.invClassification,
      filterType: this.state.filterType,
      itemType: this.state.itemType,
      category: this.state.categorySelected,
      searchItemName: this.state.itemName,
    };

    // console.log("Item Fliter retData:", retData);

    this.props.returnToCaller(retData);
  };

  handleFilterType = async (e) => {
    await this.setState({
      filterType: e.target.value,
    });
    if (this.state.filterType === "category") {
      // console.log('filter type is category...')
      await this.getCategories();
    }
  };

  getCategories = async () => {
    // console.log('in getCategories');
    let coopStoreId = 0;
    if ( this.props.store) coopStoreId = this.props.store.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId + "&coopStoreId=" + coopStoreId +
      "&invClassification=" +
      this.state.invClassification + 
      "&categoryOf=item&itemType=" + this.state.itemType  
      ; 
      // +
      // "&clientProgram=ItemFilter.js&clientFunction=getCategories";
    let url = baandaServer + getCatagories + params;
    // console.log("url:", url);
    try {
      let retcat = await axios.get(url);
      // console.log("retcat:", retcat);
      await this.setState({
        catagories: retcat.data.Msg,
      });
      // console.log("retcat:", retcat);
    } catch (err) {
      console.log("err:", err.message);
    }
    if (document.getElementById("mySelect"))
      document.getElementById("mySelect").value = "DEFAULT";
  };

  onfocus = async () => {
    let selht = 4;
    if (this.state.catagories.length < 4)
      selht = this.state.catagories.length + 1;
    await this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  handleCategorySelected = async () => {
    let value = document.getElementById("mySelect").value;
    await this.setState({
      categorySelected: value,
      selheight: 1,
    });
    // console.log("value >>>:", value);
  };

  onChangeClassification = async (e) => {
    await this.setState({
      invClassification: e.target.value,
    });

    if (this.state.filterType === "category") {
      await this.getCategories();
    }
  };

  onChangeItemType = async(e) => {
    await this.setState({
      itemType: e.target.value,
    });
  }

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ItemFilter...');

    let sellist = this.state.catagories.map((obj, i) => {
      return (
        <option key={i} value={obj.value}>
          {obj.label}&nbsp;
        </option>
      );
    });

    let categoryPanel;
    if (this.state.filterType === "category") {
      categoryPanel = (
        <div>
          <div>
            <div className="row select_panel_inventory">
              <div className="col text-center div_catitem_select">
                <select
                  size={this.state.selheight}
                  id="mySelect"
                  name="mySelect"
                  onChange={this.handleCategorySelected}
                  className="inv-item-select"
                  onFocus={this.onfocus}
                  onBlur={this.onblur}
                >
                  <option value="DEFAULT" disabled>
                    Select an Category
                  </option>
                  {sellist}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col text-center select_item_msg">
                Please select a category to filter.
              </div>
            </div>
          </div>
        </div>
      );
    }

    let namePanel;
    if (this.state.filterType === "name") {
      namePanel = (
        <div>
          <div className="row name-row">
            <div className="col-3 text-right inventory-label">Item Name</div>
            <div className="col-9 text-left input_placement">
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="input_text_catlog"
                placeholder="Enter item name to filter"
                spellCheck="false"
              />
            </div>
          </div>
          <div>
            <p className="text-cente name-msg">Part/full of item name.</p>
          </div>
        </div>
      );
    }

    let searchPanel;
    searchPanel = (
      <div>
        <div className="row">
          <div className="col text-center radio-fonts">
            <strong>Filter By: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="all"
                  checked={this.state.filterType === "all"}
                  onChange={this.handleFilterType}
                />{" "}
                All
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="name"
                  checked={this.state.filterType === "name"}
                  onChange={this.handleFilterType}
                />{" "}
                By Name
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="category"
                  checked={this.state.filterType === "category"}
                  onChange={this.handleFilterType}
                />{" "}
                By Category
              </label>
            </div>
            <button
              className="btn_reg_60"
              onClick={this.handleFilter}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Fetch
            </button>
            <div className="item-filter-space" />
            {categoryPanel}
            {namePanel}
            {/* <br /> */}
            &nbsp;
            {this.props.caller &&
            this.props.caller === "itemselection" ? null : (
              <button
                className="btn_back_main"
                onClick={this.returnToItemList}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-step-backward" />
              </button>
            )}
          </div>
        </div>
      </div>
    );

    let itemTypePanel;
    if (this.state.invClassification === "catalog") {
      itemTypePanel = (
        <div>
          <div className="row">
            <div className="col text-center radio-fonts">
              <strong>Item Type: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="goods"
                    checked={this.state.itemType === "goods"}
                    onChange={this.onChangeItemType}
                  />{" "}
                  Goods (materials)
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="service"
                    checked={this.state.itemType === "service"}
                    onChange={this.onChangeItemType}
                  />{" "}
                  Services
                </label>
              </div>
              {/* <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="education"
                    checked={this.state.itemType === "education"}
                    onChange={this.onChangeItemType}
                  />{" "}
                  Education
                </label>
              </div> */}
            </div>
          </div>
        </div>
      );
    } 

    let invtypePanel;
    invtypePanel = (
      <div>
        <div className="row">
          <div className="col text-center radio-fonts">
            <strong>Classification: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="asset"
                  checked={this.state.invClassification === "asset"}
                  onChange={this.onChangeClassification}
                />{" "}
                Assets
              </label>
            </div>
            {/* <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="consumeable"
                  checked={this.state.invClassification === "consumeable"}
                  onChange={this.onChangeClassification}
                />{" "}
                Consumeables
              </label>
            </div> */}
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="catalog"
                  checked={this.state.invClassification === "catalog"}
                  onChange={this.onChangeClassification}
                />{" "}
                Catalog
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="raw-item"
                  checked={this.state.invClassification === "raw-item"}
                  onChange={this.onChangeClassification}
                />{" "}
                Raw-Item (to manufacture)
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let outputPanel = (
      <div className="inv-search-new-box">
        {invtypePanel}
        {itemTypePanel}
        {searchPanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default ItemFilter;
