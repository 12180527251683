import React, { Component } from "react";
import axios from "axios";

import "./ViewCommList.css";
import ReactLoading from "react-loading";

import notAvailable from "../../../images/notavailable4.jpg";

import { sortArrayOfObjects } from "../../../utils/sortArrayOfObjects";

import "./ViewCommList.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBizSearch = "/routes/search/getBizSearch?";

class ViewCommList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      bizlist: [],

      // showOutputFlag: false,
      loading: true,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.loadListData();

    this.setState({
      deviceSize,
      loading: false,
    });
  };

  loadListData = async () => {
    let lat = 0;
    if (
      this.props.searchInput.geoLocation &&
      this.props.searchInput.geoLocation.lat
    )
      lat = this.props.searchInput.geoLocation.lat;
    let long = 0;
    if (
      this.props.searchInput.geoLocation &&
      this.props.searchInput.geoLocation.long
    )
      long = this.props.searchInput.geoLocation.long;
    let distance = 0;
    if (this.props.searchInput.distance)
      distance = this.props.searchInput.distance;
    let params =
      "type=" +
      this.props.searchInput.type +
      "&businessText=" +
      this.props.searchInput.businessText +
      "&distance=" +
      distance +
      "&fromLatitude=" +
      lat +
      "&fromLongitude=" +
      long;

    let url = baandaServer + getBizSearch + params;
    // console.log("loadListData url:", url);
    try {
      let bizret = await axios.get(url);
      // console.log("bizret.data:", bizret.data);
      if (bizret.data.status === "success") {
        let out;
        if (this.state.businessText === "") {
          out = await sortArrayOfObjects(
            bizret.data.Msg,
            "signBoardShort",
            "dsc"
          );
        } else {
          out = await sortArrayOfObjects(bizret.data.Msg, "score", "dsc");
        }

        // console.log("out:", out);
        this.setState({
          bizlist: out,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: bizret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleComm = (comm) => {
    // console.log("community:", comm);
    let retObj = {
      type: "community",
      commmunity: comm,
    };
    this.props.returnToCaller(retObj);
  };

  returnToSearch = () => {
    let retObj = {
      type: "search",
    };
    this.props.returnToCaller(retObj);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewCommList...');

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let summaryPanel;
    if (this.state.bizlist.length > 0) {
      summaryPanel = (
        <div className="login-search-res">
          Results&nbsp;
          <span className="boxes">{this.state.bizlist.length}</span>&nbsp;
        </div>
      );
    }

    let bizPanel;
    // console.log("this.state.bizlist:", this.state.bizlist);

    if (this.state.bizlist.length > 0) {
      let bool = true;
      bizPanel = (
        <div>
          <div className="row show-pickups-header">
            <div className="col text-center header-text-catalog">
              {summaryPanel}
            </div>
          </div>

          <div className="fixedsize_search-biz-view">
            {this.state.bizlist.map((post, i) => {
              let pic = "";
              if (post.location === "") pic = notAvailable;
              else pic = post.location;
              return (
                <div key={i}>
                  <div
                    className={`${
                      bool ? "row-search-dr" : "row-search-lt"
                    }`}
                  >
                    <div className="row">
                      <div className="col-2 text-center pic-placement">
                        {/* <img src={pic} alt="pic" className="pic-cat-browse" /> */}
                        <img src={pic} alt="pic" className="pic-cat-browse-view-comm" />
                      </div>
                      <div className="col-8 text-left login-search-biz-lines">
                        <div className="row ">
                          <div className="col text-left login-search-biz-lines-row1">
                            {post.signBoardShort}
                          </div>
                          
                          {post.score &&
                          this.props.searchInput.businessText !== "" ? (
                            <div className="col-2 text-left login-search-biz-lines-row2">
                              Score:{post.score.toFixed(1)}
                            </div>
                          ) : (
                            <div className="col-2 text-left login-search-biz-lines-row2">
                              &nbsp;
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-8 text-left login-search-biz-lines-row3">
                            {post.signBoardLong}
                          </div>
                          {post.distance ? (
                            <div className="col-4 text-left login-search-biz-lines-row2">
                              {post.distance} mi
                            </div>
                          ) : (
                            <div className="col-3 text-left login-search-biz-lines-row2">
                              &nbsp;
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col text-left search-comm-city">
                            {/* Location: {post.city},&nbsp;&nbsp;{post.zip}&nbsp;&nbsp;{post.state} */}
                            {post.center.postalAddress.city}, {post.center.postalAddress.state.toUpperCase()}
                          </div>
                          </div>
                      </div>
                      <div className="col-2 text-center item-open-button-pos">
                        <button
                          onClick={() => this.handleComm(post)}
                          className="btn_reg_50_open "
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>

        </div>
      );
    } else if (this.state.loading) {
      bizPanel = (
        <div className="text-center comm-biz-search-result-none">
          Searching --- Work in progress
          <div className="text-center react-load-login-pos">
            <ReactLoading
              type={"spokes"}
              color={"#dfe9f0"}
              height={50}
              width={50}
            />
          </div>
        </div>
      );
    } else {
      bizPanel = (
        <div className="text-center comm-biz-search-result-none">
          Nothing found for your search
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center comm-search-result-header">
          Communities&nbsp;&nbsp; {backButton}
          {bizPanel}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default ViewCommList;
