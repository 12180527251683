
const sortArrayOfObjects = async (data, property, ascDsc) => {
  // console.log('property:', property);
  // console.log('data: ', data);
  let sorted;
  if ( ascDsc === 'dsc') {
    sorted = data.sort((a,b) => (a[property] > b[property] ) ? 1 : -1 );
    // console.log(' sorted:', sorted);
  } else {
    sorted =  data.sort((a,b) => (a[property] > b[property] ) ? -1 : 1 );
    // console.log(' sorted:', sorted);
  }
  // sorted = data.sort(compare(property, ascDsc));

  return sorted;
};

export { sortArrayOfObjects } ;
