import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import EditTargetAddress from "./utils/EditTargetAddress";
import WipInvoiceList from "./utils/WipInvoiceList";

import "./NewInvoice.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getUserByEmailForInvoice = "/routes/users/getUserByEmailForInvoice?";
const getActiveTasks = "/routes/task/getActiveTasks?";
const getZiptaxSalesTax = "/routes/dashboard/getZiptaxSalesTax?";
const handleManualInvoice = "/routes/finance/handleManualInvoice";
const sendInviteToJoinBaanda = "/routes/finance/sendInviteToJoinBaanda";
// const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111210000";

class NewInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionType: "newinvoice",
      referenceType: "service",

      billToEmail: "",
      showBillToDetails: false,
      billToAddress: "",
      billToName: "",
      billToCell: "",
      shipToAddress: "",

      lineDescription: "",
      lineIsTaxable: false,
      linePrice: 0.0,
      lineQty: 1,
      lineUnit: "",
      lineNo: 1,
      lineTotal: 0.0,
      subId: 0,

      line1: "",
      city: "",
      state: "",
      postalCode: "",
      shipline1: "",
      shipcity: "",
      shipstate: "",
      shippostalCode: "",
      country: "USA",

      useBaandaShipping: false,

      subTotalTaxable: 0.0,
      subTotalNontaxable: 0.0,
      salesTaxAmt: 0.0,
      customerTaxAmt: 0.0,
      salesTaxPercent: 0.0,
      invoiceTotal: 0.0,
      shippingCost: 0.0,

      showBillToDetailFlag: false,
      sendEmailToRegister: false,
      invConfirm: false,
      taskStatus: false,

      sendInvoteToRegister: false,
      addedLineItems: [],

      errMsg: "",
      errFlag: false,

      showMainInvoiceCreation: true,
      showEditAddress: false,
      showWipInvoiceList: false,
      showTasksToInvoiceFlag: false,
      showFinalSubmit: false,

      subLineItemType: "milestone",
      referenceId: 0,
      tasks: [],
      selectedTask: null,
      showEmailInputFlag: false,
      payByDate: new Date(),
      byDate: "",
      showTermsFlag: false,
      invoiceTerms: "",
      taxPaidBy: "customer",
      fineApr: 0.0,
      invoiceId: 0,
      taxInfo: null,
      salesTaxObj: null,
      delayFineApr: 0.0,
      showTermDueDate: "",
      salesTaxAmount: 0,

      selectedInvoice: null,
      deliveryData: null,

      salute: "",
      inviteLetter: "",
      inviteeName: "",
      subject: "",
      respect: "",

      isCustomer: false,
      draftButtonFlag: true,

      totalProjAmt: 0,
      totalInvoiceAmount: 0,
      totalPaidAmount: 0,

      taskInvoiceFlag: false,
    };
  }

  componentDidMount = async () => {
    let payByDate = new Date(
      this.state.payByDate.setMonth(this.state.payByDate.getMonth() + 1)
    );

    let byDate = await this.formatDate(payByDate);
    let fineApr = 0;
    if (this.props.community.delayPaymentFineAPR !== 0)
      fineApr = this.props.community.delayPaymentFineAPR;

    this.setState({
      byDate,
      payByDate,
      fineApr,
      invoiceTerms: this.props.community.invoiceTerms,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      salesTaxAmt: 0,
      invoiceTotal: 0,
      showFinalSubmit: false,
    });
  };

  handleOpsType = async (e) => {
    let value = e.target.value;
    let showMainInvoiceCreation = true;
    let showEditAddress = false;
    let showWipInvoiceList = false;
    if (value === "wipinvoice") {
      showMainInvoiceCreation = false;
      showEditAddress = false;
      showWipInvoiceList = true;
    }
    this.setState({
      actionType: e.target.value,
      referenceId: 0,
      selectedTask: null,
      showBillToDetailFlag: false,

      showMainInvoiceCreation,
      showEditAddress,
      showWipInvoiceList,
    });
    await this.initializeInvoice();
  };

  returnFromWipInvoiceList = async (inv) => {
    // console.log("inv:", inv);
    if (inv) await this.initializeWithSelectedInvoice(inv);
    this.setState({
      showMainInvoiceCreation: true,
      showEditAddress: false,
      showWipInvoiceList: false,
      selectedInvoice: inv,
    });
  };

  initializeWithSelectedInvoice = async (inv) => {
    // console.log("inv.manualInvoiceParams:", inv);
    // console.log("inv.manualInvoiceParams:", inv.manualInvoiceParams);

    let stdd = new Date(inv.manualInvoiceParams.dueDate);
    // console.log("stdd:", stdd);

    let defaultStdd = await this.dateFormat(stdd);
    // console.log("defaultStdd:", defaultStdd);

    let showTermDueDate = "";
    if (inv.manualInvoiceParams.dueDate)
      showTermDueDate = inv.manualInvoiceParams.dueDate.substring(0, 10);
    else showTermDueDate = defaultStdd;

    // console.log("showTermDueDate:", showTermDueDate);

    let showBillToDetailFlag = true;
    let subTotalNontaxable = 0;
    let subTotalTaxable = 0;
    let subLineItemType = "milestone";
    inv.itemDetails.forEach((llm) => {
      if (llm.lineIsTaxable) subTotalTaxable += parseFloat(llm.price);
      else subTotalNontaxable += parseFloat(llm.price);
    });

    let salesTaxPercent = 0;
    let salesTaxObj = null;
    if (inv.taxInfo && inv.taxInfo.taxObj) {
      salesTaxPercent = inv.taxInfo.taxObj.taxBreakdown.taxSales * 100;
      salesTaxObj = inv.taxInfo.taxObj;
    }

    let billToAddress =
      inv.manualInvoiceParams.billToAddress.line1 +
      ", " +
      inv.manualInvoiceParams.billToAddress.city +
      " " +
      inv.manualInvoiceParams.billToAddress.state +
      " " +
      inv.manualInvoiceParams.billToAddress.postalCode;

    let showFinalSubmit = false;
    if (inv.financeBreakdown.totalInvoiceAmount > 0) showFinalSubmit = true;

    // console.log(
    //   "inv.manualInvoiceParams.dueDate:",
    //   inv.manualInvoiceParams.dueDate
    // );
    let payByDate = new Date();
    if (inv.manualInvoiceParams.dueDate) {
      payByDate = inv.manualInvoiceParams.dueDate;
    }
    // console.log("payByDate:", payByDate);

    this.setState({
      billToEmail: inv.customerEmail,
      // showBillToDetails: false,
      showEmailInputFlag: true,
      showBillToDetailFlag,
      billToAddress,
      billToName: inv.customerName,
      billToCell: "",

      payByDate,

      lineDescription: "",
      lineIsTaxable: false,
      linePrice: 0.0,
      lineQty: 1,
      lineUnit: "",
      lineNo: inv.itemDetails.length + 1,
      lineTotal: 0.0,
      subId: 0,

      line1: inv.manualInvoiceParams.billToAddress.line1,
      city: inv.manualInvoiceParams.billToAddress.city,
      state: inv.manualInvoiceParams.billToAddress.state,
      postalCode: inv.manualInvoiceParams.billToAddress.postalCode,
      shipline1: inv.manualInvoiceParams.shipToAddress.line1,
      shipcity: inv.manualInvoiceParams.shipToAddress.city,
      shipstate: inv.manualInvoiceParams.shipToAddress.state,
      shippostalCode: inv.manualInvoiceParams.shipToAddress.postalCode,
      useBaandaShipping: inv.manualInvoiceParams.useBaandaShipping,

      subTotalTaxable,
      subTotalNontaxable,
      salesTaxAmt: inv.financeBreakdown.salesTaxAmount,
      salesTaxPercent,
      invoiceTotal: inv.financeBreakdown.totalInvoiceAmount,
      shippingCost: inv.financeBreakdown.shippingAmt,

      sendEmailToRegister: false,
      invConfirm: false,

      sendInvoteToRegister: false,
      addedLineItems: inv.itemDetails,

      errMsg: "",
      errFlag: false,

      showMainInvoiceCreation: true,
      showEditAddress: false,
      showTasksToInvoiceFlag: false,
      showFinalSubmit,

      subLineItemType,
      referenceId: 0,
      tasks: [],
      selectedTask: null,
      // invoiceTerms: "",
      // fineApr: 0.0,
      taxPaidBy: "customer",

      invoiceId: inv.invoiceId,
      taxInfo: inv.taxInfo,
      salesTaxObj,
      delayFineApr: inv.manualInvoiceParams.fineApr,
      showTermDueDate,
      salesTaxAmount: 0,
    });
  };

  handleReferenceType = async (e) => {
    let value = e.target.value;
    // console.log("value:", value);
    let showEmailInputFlag = false;
    // showEmailInputFlag
    if (value === "other") showEmailInputFlag = true;
    // console.log("showEmailInputFlag:", showEmailInputFlag);
    this.setState({
      referenceType: e.target.value,
      showEmailInputFlag,
      draftButtonFlag: true,
      taskInvoiceFlag: false,
    });

    await this.initializeInvoice();
  };

  initializeInvoice = async () => {
    let newDate = new Date();
    let payByDate = new Date(newDate.setMonth(newDate.getMonth() + 1));

    this.setState({
      billToEmail: "",
      showBillToDetails: false,
      billToAddress: "",
      billToName: "",
      billToCell: "",

      lineDescription: "",
      lineIsTaxable: false,
      linePrice: 0.0,
      lineQty: 1,
      lineUnit: "",
      lineNo: 1,
      lineTotal: 0.0,
      subId: 0,

      line1: "",
      city: "",
      state: "",
      postalCode: "",
      shipline1: "",
      shipcity: "",
      shipstate: "",
      shippostalCode: "",

      useBaandaShipping: false,

      subTotalTaxable: 0.0,
      subTotalNontaxable: 0.0,
      salesTaxAmt: 0.0,
      salesTaxPercent: 0.0,
      invoiceTotal: 0.0,
      shippingCost: 0.0,

      showBillToDetailFlag: false,
      sendEmailToRegister: false,
      invConfirm: false,

      sendInvoteToRegister: false,
      addedLineItems: [],

      // errMsg: "",
      // errFlag: false,

      showMainInvoiceCreation: true,
      showEditAddress: false,
      showTasksToInvoiceFlag: false,
      showFinalSubmit: false,

      subLineItemType: "milestone",
      referenceId: 0,
      tasks: [],
      selectedTask: null,
      payByDate,
      // invoiceTerms: "",
      // fineApr: 0.0,
      taxPaidBy: "customer",

      invoiceId: 0,
      taxInfo: null,
      salesTaxObj: null,
      delayFineApr: 0.0,
      showTermDueDate: "",
      salesTaxAmount: 0,
      deliveryData: null,
    });
  };

  handleSubLineitemtype = async (e) => {
    this.setState({
      subLineItemType: e.target.value,
    });
  };

  handleTaxPaidBy = async (e) => {
    this.setState({
      taxPaidBy: e.target.value,
    });
  };

  onChangeInteger = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) === 0) {
      this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  onChangeCurrency = async (e) => {
    // console.log("[e.target.name]:", [e.target.name]);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  onChangeCurrencyShip = async (e) => {
    // console.log("[e.target.name]:", [e.target.name]);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
        errMsg: "",
        invConfirm: false,
        showFinalSubmit: false,
      });
    } else {
      this.setState({
        [e.target.name]: value,
        errMsg: "",
        invConfirm: false,
        showFinalSubmit: false,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  handlecheckIfBaanda = async () => {
    let param =
      "email=" +
      this.state.billToEmail +
      "&communityId=" +
      this.props.communityId;
    let url = baandaServer + getUserByEmailForInvoice + param;
    // console.log("url:", url);
    try {
      let baandaret = await axios.get(url);
      // console.log("baandaret.data:", baandaret.data);
      if (baandaret.data.status === "success") {
        let bdm = baandaret.data.Msg;
        if (bdm.baandaId === 0) {
          this.setState({
            isCustomer: bdm.isCustomer,
            customerBaandaId: bdm.baandaId,
            showBillToDetailFlag: true,
            billToAddress: "",
            billToName: "",
            billToCell: "",
            billToBaandaId: bdm.baandaId,
            errFlag: false,
            errMsg: "",
            sendInvoteToRegister: false,
          });
        } else {
          let bdmf = bdm.profileInfo;
          let bdma = bdm.profileInfo.address;
          this.setState({
            isCustomer: true, // If a valid baanda, they must be assumed to be a customer to raise an invoice.
            billToAddress:
              bdma.street +
              ", " +
              bdma.city +
              " " +
              bdma.state +
              " " +
              bdma.postalCode,
            shipToAddress:
              bdma.street +
              ", " +
              bdma.city +
              " " +
              bdma.state +
              " " +
              bdma.postalCode,
            billToName: bdmf.formalName,
            billToCell: bdmf.cell.number,
            showBillToDetailFlag: true,
            billToBaandaId: bdm.baandaId,
            line1: bdma.street,
            city: bdma.city,
            state: bdma.state,
            postalCode: bdma.postalCode,
            sendInvoteToRegister: false,
          });
        }
      } else {
        this.setState({
          errMsg: baandaret.data.Msg,
          errFlag: true,
          showBillToDetailFlag: false,
          sendInvoteToRegister: false,
        });
      }
    } catch (err) {
      console.log("handleCheckifBaanda err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
        showBillToDetailFlag: false,
        sendInvoteToRegister: false,
      });
    }
  };

  handleRefSearch = async () => {
    // console.log("in handleRefSearch ..");
    this.setState({
      showTermsFlag: false,
    });
    let params =
      "type=merchant&purpose=invoice&communityId=" + this.props.communityId;
    let url = baandaServer + getActiveTasks + params;
    // console.log("url:", url);
    try {
      let tskret = await axios.get(url);
      if (tskret.data.status === "success") {
        if (tskret.data.Msg.length > 0) {
          this.setState({
            tasks: tskret.data.Msg,
            showTasksToInvoiceFlag: true,
            errMsg: "",
            errFlag: false,
          });
        } else {
          this.setState({
            tasks: [],
            errMsg:
              "No tasks found that is oktoproceed, or active, for invoicing.",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          errMsg: tskret.data.Msg,
          reeFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        reeFlag: true,
      });
    }
  };

  exitTaskSselect = () => {
    this.setState({
      showTasksToInvoiceFlag: false,
    });
  };

  handleSendInvite = async () => {
    this.setState((prevstate) => ({
      sendInvoteToRegister: !prevstate.sendInvoteToRegister,
    }));
  };

  handleIsTaxable = async () => {
    this.setState((prevstate) => ({
      lineIsTaxable: !prevstate.lineIsTaxable,
    }));
  };

  handleConfirmToSubmit = async () => {
    this.setState((prevstate) => ({
      invConfirm: !prevstate.invConfirm,
    }));
  };

  handleTaskStatus = async () => {
    this.setState((prevstate) => ({
      taskStatus: !prevstate.taskStatus,
      errMsg:
        "Warning: You are setting this task as 'done' (finished) and this is the last invoice.",
      errFlag: true,
    }));
  };

  sendAnInvite = async () => {
    let invitees = [];
    let inviteedObj = {
      inviteeEmail: this.state.billToEmail,
      inviteeName: this.state.inviteeName,
    };
    invitees.push(inviteedObj);
    let input = {
      communityId: this.props.communityId,
      communityCaption: this.props.community.commCaption,
      inviteeType: "individual", // individual or group
      invitees,

      subject: this.state.subject,
      salute: this.state.salute,
      respect: this.state.respect,
      theletter: this.state.inviteLetter,
      invitorEmail: this.props.auth.user.email,
      invitorName: this.props.auth.user.name,
      baandaId: this.props.auth.user.baandaId,
      membershipFee: 0,
      offerValidTill: null,
      // offerValidTillxx: this.state.validTillDate,
      imageFile: null,
      clientProgram: "NewInvoice.js",
      clientFunction: "sendAnInvite",
    };

    // console.log("input:", input);

    try {
      let url = baandaServer + sendInviteToJoinBaanda;
      let sendret = await axios.post(url, input);
      if (sendret.data.status === "success") {
        this.setState({
          errMsg:
            "An invite will be sent to the email you provided. Please circle back once the recipiant registers.",
          errFlag: false,
        });
        return { status: "success", Msg: "" };
      } else {
        this.setState({
          errMsg: sendret.data.Msg + ". Failed to send message.",
          errFlag: true,
        });
        return { status: "error", Msg: "" };
      }
    } catch (err) {
      console.log("Err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  handleSendInviteToJoinBaanda = async () => {
    // console.log("in handleSendInvite ...");
    // let isValid = await this.validateEmail(this.state.billToEmail);
    // console.log("isvalid : , isValid");
    if (await this.validateEmail(this.state.billToEmail)) {
      // Please send an invite
      await this.sendAnInvite();
      this.setState({
        showBillToDetailFlag: false,
        sendInvoteToRegister: false,
      });
    } else {
      this.setState({
        errMsg: "Invalid email syntax. Please enter a valid email.",
        errFlag: false,
      });
    }
  };

  validateEmail = async (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true;
    else return false;
  };

  validateInvoiceLines = async () => {
    let isValid = "success",
      msg = "";
    if (parseFloat(this.state.linePrice) === 0) {
      isValid = "error";
      msg = "Price is required";
    }
    if (parseFloat(this.state.lineQty) === 0) {
      isValid = "error";
      msg = " | Quantity cannot be 0";
    }
    if (this.state.lineDescription === "") {
      isValid = "error";
      msg = " | Line must have a description";
    }

    this.state.addedLineItems.forEach((lit) => {
      if (lit.subLineItemType === "retainer") {
        isValid = "error";
        msg = "Retainer invoice can have only the retainer line item";
      }
    });

    return { status: isValid, Msg: msg };
  };

  addAnInvoiceLine = async () => {
    // console.log("in addAnInvoiceLine ...");
    let currLines = [...this.state.addedLineItems];
    let subTotalNontaxable = 0;
    let subTotalTaxable = 0;
    let isval = await this.validateInvoiceLines();
    if (isval.status === "success") {
      let newLineObj = {
        description: this.state.lineDescription,
        isTaxable: this.state.lineIsTaxable,
        taxPaidBy: this.state.taxPaidBy,
        price: parseFloat(this.state.linePrice),
        quantity: parseFloat(this.state.lineQty),
        unit: this.state.lineUnit,
        lineNo: this.state.lineNo,
        subLineItemType: this.state.subLineItemType,
        subId: parseFloat(this.state.subId),
      };
      currLines.push(newLineObj);

      currLines.forEach((elm) => {
        // console.log("elm:", elm);
        if (elm.isTaxable) subTotalTaxable += elm.price * elm.quantity;
        else subTotalNontaxable += elm.price * elm.quantity;
      });

      let newLineNo = parseFloat(this.state.lineNo) + 1;

      await this.setState({
        addedLineItems: currLines,
        lineNo: newLineNo,
        lineDescription: "",
        lineIsTaxable: false,
        linePrice: 0.0,
        lineQty: 1,
        lineUnit: "",
        subId: 0,
        subTotalTaxable,
        subTotalNontaxable,
        errMsg: "",
        errFlag: false,
        showFinalSubmit: false,
      });
    } else {
      this.setState({
        errMsg: isval.Msg,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  // SalesTax Calculation
  getSalesTaxBreakDown = async (deliverToAddr, totalOfTaxable) => {
    let salesTaxObj = {
      salesTax: 0,
      taxBreakdown: null,
      msg: "",
      status: "success",
    };

    try {
      let param = "address=" + deliverToAddr;
      let url = baandaServer + getZiptaxSalesTax + param;
      // console.log("url:", url);

      let resp = await axios.get(url);
      // console.log("resp.data: ", resp.data);

      if (resp.data.status === "success") {
        salesTaxObj.salesTax = parseFloat(
          (totalOfTaxable * resp.data.Msg.results[0].taxSales).toFixed(2)
        );
        salesTaxObj.taxBreakdown = resp.data.Msg.results[0];
        this.setState({
          salesTaxObj,
        });
      } else {
        salesTaxObj.status = "error";
        salesTaxObj.msg = resp.data.Msg;
      }
    } catch (err) {
      console.log("err:", err.message);
      salesTaxObj.status = "error";
      salesTaxObj.msg = err.message;
    }

    // console.log("1. >> salesTaxObj:", salesTaxObj);

    return salesTaxObj;
  };

  handleInvoiceTotalCalculation = async () => {
    if (this.state.addedLineItems.length === 0) {
      this.setState({
        errMsg: "At least one line item is requred to calculate.",
        errFlag: true,
      });
    } else {
      await this.invoiceTotalCalculation();
      this.setState({
        linePrice: 0,
        lineQty: 1,
        lineDescription: "",
        lineUnit: "",
      });
    }
  };

  invoiceTotalCalculation = async () => {
    let deliverToAddr = this.state.billToAddress + " USA";
    // console.log("deliverToAddr:", deliverToAddr);

    let calcTax = null;
    if (this.state.subTotalTaxable > 0) {
      calcTax = await this.getSalesTaxBreakDown(
        deliverToAddr,
        this.state.subTotalTaxable
      );
    } else {
      calcTax = {
        msg: "",
        salesTax: 0.0,
        taxBreakdown: null,
        status: "success",
      };
    }

    // console.log("calcTax:", calcTax);
    let customerTaxableAmt = 0;
    this.state.addedLineItems.forEach((obj) => {
      if (obj.isTaxable && obj.taxPaidBy === "customer") {
        customerTaxableAmt += obj.price * obj.quantity;
      }
    });

    let salesTaxAmt = 0;
    let customerTaxAmt = 0;
    if (this.state.subTotalTaxable > 0) {
      salesTaxAmt = calcTax.salesTax;
      customerTaxAmt = customerTaxableAmt * calcTax.taxBreakdown.taxSales;
    }

    let salesTaxPercent = 0.0;
    if (calcTax.taxBreakdown)
      salesTaxPercent = calcTax.taxBreakdown.taxSales * 100;
    let total =
      parseFloat(this.state.subTotalNontaxable) +
      parseFloat(this.state.subTotalTaxable) +
      parseFloat(customerTaxAmt) +
      parseFloat(this.state.shippingCost);
    // console.log("total:", total);

    this.setState({
      salesTaxAmt,
      customerTaxAmt,
      salesTaxPercent,
      invoiceTotal: total,
      showFinalSubmit: true,
      errMsg: "",
      errFlag: false,
    });
  };

  handleChangeTargetAddress = async () => {
    this.setState({
      showMainInvoiceCreation: false,
      showEditAddress: true,
    });
  };

  returnFromChangeAddress = async (obj) => {
    // console.log("returnFromChangeAddress obj:", obj);
    if (obj.ops === "cancel") {
      this.setState({
        showMainInvoiceCreation: true,
        showEditAddress: false,
      });
    } else {
      this.setState({
        showMainInvoiceCreation: true,
        showEditAddress: false,
        line1: obj.line1,
        city: obj.city,
        state: obj.state,
        postalCode: obj.postalCode,
        shipline1: obj.shipline1,
        shipcity: obj.shipcity,
        shipstate: obj.shipstate,
        shippostalCode: obj.shippostalCode,
        useBaandaShipping: obj.useBaandaShipping,
      });
    }
  };

  doneTaskSelection = async (tsk) => {
    // console.log("tsk:", tsk);
    let lineDescription = "";
    let liTotal = 0;
    let linePrice = 0;
    let lineUnit = "";
    let lineQty = 1;
    let subLineItemType = this.state.subLineItemType;
    let draftButtonFlag = true;
    if (tsk.clientDecision === "oktoproceed" && tsk.invoices.length === 0) {
      let qt = tsk.quotes[0];
      qt.lineItem.forEach((tot) => {
        liTotal += tot.cost;
      });
      linePrice = parseFloat((liTotal * (qt.retainerPercent / 100)).toFixed(2));
      lineDescription =
        "Retainer to activate the service for the item: " + tsk.itemName;
      lineUnit = "Service Retainer";
      subLineItemType = "retainer";
      draftButtonFlag = false;
    }

    let totalProjAmt = 0;
    tsk.changeOrder.forEach((cot) => {
      cot.lineItem.forEach((lit) => {
        totalProjAmt += lit.cost;
      });
      totalProjAmt += cot.estimatedTaxAmount;
    });

    tsk.quotes[0].lineItem.forEach((tql) => {
      totalProjAmt += tql.cost;
    });

    let totalInvoiceAmount = 0;
    let totalPaidAmount = 0;
    tsk.invoices.forEach((inv) => {
      totalInvoiceAmount += inv.invoicedAmount;
      // if (inv.status === "paid") totalPaidAmount += inv.invoicedAmount;
    });
    tsk.payments.forEach((pnt) => {
      totalPaidAmount += pnt.paidAmount;
    });

    await this.setState({
      selectedTask: tsk,
      showTasksToInvoiceFlag: false,
      referenceId: tsk.taskId,
      billToEmail: tsk.targetContact.email,
      linePrice,
      lineDescription,
      lineUnit,
      lineQty,
      subLineItemType,
      actionType: "newinvoice",
      draftButtonFlag,
      lineNo: 1,
      addedLineItems: [],
      subTotalNontaxable: 0,
      totalProjAmt,
      totalInvoiceAmount,
      taskInvoiceFlag: true,
      totalPaidAmount,
    });

    await this.handlecheckIfBaanda();
  };

  handlePayByDate = async (date) => {
    // console.log("1 date:", date);
    let byDate = await this.formatDate(date);
    await this.setState({
      payByDate: date,
      byDate,
    });
  };

  formatDate = async (date) => {
    let year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;

    return month + "/" + day + "/" + year;
  };

  handleCreateTerms = async () => {
    // let byDate = await this.formatDate(this.state.payByDate);

    let invoiceTerms;
    // let showTermDueDate = "Invoice to be paid by " + byDate + ".";
    if (this.state.invoiceTerms === "") {
      invoiceTerms = this.props.community.invoiceTerms;
    } else invoiceTerms = this.state.invoiceTerms;

    let delayFineApr = 0.0;
    if (this.state.delayFineApr > 0) delayFineApr = this.state.delayFineApr;
    else if (this.props.community.delayPaymentFineAPR > 0)
      delayFineApr = this.props.community.delayPaymentFineAPR;

    this.setState({
      showTermsFlag: true,
      invoiceTerms,
      delayFineApr,
    });
  };

  exitTermsPanel = async () => {
    this.setState({
      showTermsFlag: false,
    });
  };

  selectThisItemToDelete = async (obj) => {
    // console.log("obj:", obj);
    let subTotalNontaxable = 0;
    let subTotalTaxable = 0;
    let ln = 1;
    let adl = [];
    this.state.addedLineItems.forEach((elm) => {
      if (elm.lineNo !== obj.lineNo) {
        elm.lineNo = ln;
        adl.push(elm);
        ln++;
        if (elm.lineisTaxable) {
          subTotalTaxable += elm.linePrice * elm.lineQty;
        } else {
          subTotalNontaxable += elm.linePrice * elm.lineQty;
        }
      }
    });

    let lineNo = adl.length + 1;

    this.setState({
      addedLineItems: adl,
      subTotalNontaxable,
      subTotalTaxable,
      lineNo,
      errMsg: "",
      errFlag: false,
    });
  };

  dateFormat = async (date) => {
    // console.log("date:", date);

    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  packageDataForDB = async (ask) => {
    let paySchedulePolicy = {
      installmentType: "",
      nextSchedulePayday: this.state.payByDate,
      nextPayAmount: parseFloat(this.state.invoiceTotal), // This will be the total amount
      lastReminderSentDate: null,
      numberOfInstallment: 0,
      numberOfInstallmentPaid: 0,
      nextPayBy: this.state.payByDate,
      invoiceTerms: this.state.invoiceTerms,
    };

    // console.log("paySchedulePolicy:", paySchedulePolicy);

    let refType = "";
    let refId = 0;
    if (this.state.referenceType === "service") {
      refType = "service";
      refId = this.state.referenceId;
    } else if (this.state.referenceType === "project") {
      refType = "project";
      refId = this.state.projectId;
    } else refType = "other";

    // console.log("shipline1:", this.state.shipline1);
    let shipToAddress = null;
    let spType = "na";
    let spRegularExists = false;
    let spRegularStatus = "na";
    let totalDeliveryStatus = "na";
    let regularItemsobj = {
      name: "",
      itemId: 0,
    };
 
    let spInfo = null;
    if (
      parseFloat(this.state.shippingCost) > 0 &&
      this.state.useBaandaShipping
    ) {
      if (this.state.shipline1 !== "") {
        shipToAddress = {
          line1: this.state.shipline1,
          city: this.state.shipcity,
          state: this.state.shipstate,
          postalCode: this.state.shippostalCode,
          country: this.state.country,
        };
      } else {
        shipToAddress = {
          line1: this.state.line1,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
          country: this.state.country,
        };
      }
      spType = "ship";
      spRegularExists = true;
      spRegularStatus = "tobe";
      if (ask === "post") totalDeliveryStatus = "tobe";

      let regularItems = [];
      regularItemsobj = {
        itemid: 0,
        name:
          "Item refers to (task/project) id : " +
          refId +
          " of type " +
          refType +
          " raised manually",
      };
      regularItems.push(regularItemsobj);
      spInfo = {
        numberOfRegularPackages: 1,
        numberOfSpecialPackages: 0,
        regularItems,
        specialItems: [],
        regularPkg: [],
      };
    }

    let displayStoreName = "";
    let displayStoreKeeper = "";
    if (this.props.store) {
      displayStoreName = this.props.store.displayStoreName;
      displayStoreKeeper = this.props.store.displayStoreKeeper;
    }

    let dueDate = new Date();
    if (this.state.payByDate) dueDate = this.state.payByDate;
    let manualInvoiceParams = {
      refId, // Reference Id may be project, task, stand alone ... no refId
      refType,
      dueDate,
      invoiceTerms: this.state.invoiceTerms,
      fineApr: this.state.fineApr,
      billToAddress: {
        line1: this.state.line1,
        city: this.state.city,
        state: this.state.state,
        postalCode: this.state.postalCode,
        country: this.state.country,
      },
      shipToAddress,
      useBaandaShipping: this.state.useBaandaShipping,
      displayStoreName,
      displayStoreKeeper,
    };

    let billingAddress = this.state.billToAddress;
    let shippingAddress = "";
    if (this.state.shipline1 === "") shippingAddress = billingAddress;
    else {
      shippingAddress =
        this.state.shipline1 +
        ", " +
        this.state.shipcity +
        " " +
        this.state.shipstate +
        " " +
        this.state.shippostalCode;
    }

    let stMerchantPortionAmt = 0;
    let salesTaxCustomerAmt = 0;
    let stMerchantPortion = 0;
    let salesTaxCustomer = 0;
    this.state.addedLineItems.forEach((obj) => {
      if (obj.isTaxable) {
        if (obj.taxPaidBy === "customer")
          salesTaxCustomerAmt += parseFloat(obj.price);
        else stMerchantPortionAmt += parseFloat(obj.price);
      }
    });
 

    if (this.state.salesTaxObj) {
 
      stMerchantPortion =
        this.state.salesTaxObj.taxBreakdown.taxSales * stMerchantPortionAmt;
      salesTaxCustomer =
        this.state.salesTaxObj.taxBreakdown.taxSales * salesTaxCustomerAmt;
    }
   

    let taxInfo = {
      taxableAmount: this.state.salesTaxAmt,
      salesTaxPer: parseFloat(this.state.salesTaxPercent.toFixed(2)),
      stMerchantPortion,
      stMerchantTax: stMerchantPortionAmt,
      salesTaxCustomer,
      taxObj: this.state.salesTaxObj,
    };

    let financeBreakdown = {
      totalInvoiceAmount: this.state.invoiceTotal, // Should contain the invoice total
      amountPaid: 0,
      lastPaymentAmount: 0,
      lastPaymentDate: null,
      salesTaxAmount: salesTaxCustomer, // Put the tax amount
      writeOffAmt: 0,
      shippingAmt: parseFloat(this.state.shippingCost),
    };

    let invoiceType = "receivable";
    // if (this.state.subLineItemType === "retainer") invoiceType = "liability";

    let invoiceTerms =
      this.state.invoiceTerms +
      " Invoice to be paid by " +
      this.state.byDate +
      ". Late fee APR = " +
      this.state.delayFineApr +
      "%.";
    if (this.state.subLineItemType === "retainer")
      invoiceTerms = "The quation is valid till " + this.state.byDate + ".";

    let coopStoreId = 0;
    let coopStoreName = "";
    let coopStoreKeeper = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      coopStoreName = this.props.store.displayStoreName;
      coopStoreKeeper = this.props.store.displayStoreKeeper;
    }

    let taskId = 0;
    if (this.state.selectedTask) taskId = this.state.selectedTask.taskId;

    let input = {
      invoiceOfId: this.state.billToBaandaId, // This will be target's / customer's baandaId
      invoiceId: this.state.invoiceId,
      invoiceCreationType: "manual",
      invoiceOfType: "individual",
      customerEmail: this.state.billToEmail,
      customerName: this.state.billToName,
      customerCell: this.state.billToCell,
      communityId: this.props.communityId,
      coopStoreId,
      coopStoreName,
      coopStoreKeeper,
      commName: this.props.community.commName,
      invoiceDate: new Date(),
      invoiceType,
      financeBreakdown,
      paySchedule: true,
      paySchedulePolicy,
      manualEntryState: "", // To be filled in based if this was Draft (then wip) or Submit ( then posted)
      handlingFlag: "",
      created_at: new Date(),
      manualInvoiceParams,
      itemDetails: this.state.addedLineItems,
      taxInfo,
      invoiceCategory: this.state.referenceType,
      opsType: "",
      billingAddress,
      shippingAddress,
      invoiceTerms,

      shipToAddress,
      spType,
      spRegularExists,
      spRegularStatus,
      totalDeliveryStatus,
      spInfo,

      updated_by_bid: this.props.auth.user.baandaId,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      clientProgram: "NewInvoice.js",
      clientFunction: "packageDataForDB",
      isCustomer: this.state.isCustomer,
      taskId,
    };

    return input;
  };

  validateInvoiceData = async () => {
    let msg = "";
    let state = "success";
    if (this.state.addedLineItems.length === 0) {
      msg = "Must have at least on line item to save;";
      state = "error";
    }

    if (parseFloat(this.state.shippingCost) > 0) {
      if (this.state.shipToAddress === "") {
        msg += " Must have a shipping address for non-zero shipping cost;";
        state = "error";
      }
      if (
        this.state.shipline1 === "" ||
        this.state.shipcity === "" ||
        this.state.shippostalCode === "" ||
        this.state.shippostalCode === ""
      ) {
        msg +=
          " Must have a shipping address for non-zero shipping cost. Click Edit to specify;";
        state = "error";
      }
    }

    if (
      this.state.line1 === "" ||
      this.state.city === "" ||
      this.state.postalCode === "" ||
      this.state.city === ""
    ) {
      msg += " Must have billing address. Click Edit to specify;";
      state = "error";
    }

    if (this.state.billToAddress === "") {
      msg += " Must have a billing address;";
      state = "error";
    }

    return { status: state, Msg: msg };
  };

  handleUpdate = async (ask) => {
    // console.log("ask:", ask);
    let noOfLines = 0;
    let hasRetainer = false;
    let opsType = "active";
    this.state.addedLineItems.forEach((obj) => {
      if (obj.subLineItemType === "retainer") {
        hasRetainer = true;
        opsType = "retainer";
      }
      noOfLines++;
    });
    if (hasRetainer && noOfLines > 1) {
      this.setState({
        errMsg: "Invoice for a retainer can have only the reatiner line item.",
        errFlag: true,
      });
    } else {
      let valid = await this.validateInvoiceData();
      if (valid.status === "success") {
        let input = await this.packageDataForDB(ask);
        input.manualEntryState = ask;
        input.handlingFlag = ask;

        if (opsType !== "retainer") {
          if (this.state.taskStatus) opsType = "done";
        }
        input.opsType = opsType;

        let url = baandaServer + handleManualInvoice;
        // console.log("Input: ", input, " url:", url);
        try {
          let retinv = await axios.post(url, input);
          // console.log("retinv.data:", retinv.data);
          if (retinv.data.status === "success") {
            // In case of Draft
            if (ask === "wip") {
              this.setState({
                errMsg: "Drafted successfully.",
                errFlag: false,
                invoiceId: retinv.data.Msg,
              });
            } else {
              this.setState({
                errMsg: "Invoice has been raised successfully.",
                errFlag: false,
                totalInvoiceFlag: false,
                totalProjAmt: 0,
                totalPaidAmount: 0,
                totalInvoiceAmount: 0,
                taskInvoiceFlag: false,
              });
              await this.initializeInvoice();
            }
          } else {
            this.setState({
              errMsg: retinv.data.Msg,
              errFlag: true,
            });
          }
        } catch (err) {
          this.setState({
            errMsg: err.message,
            errFlag: true,
          });
        }
      } else {
        this.setState({
          errMsg: valid.Msg,
          errFlag: true,
        });
      }
    }
  };

  returnToPayPending = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("NewInvoice...");

    let exitRaiseInvoice = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToPayPending}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let helpModalButton = (
      <button
        className="btn_info_main man-inv-info-pos"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitTermsButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitTermsPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let addLineButton = (
      <button
        className="btn_reg_60 manual-inv-add-pos"
        type="button"
        onClick={this.addAnInvoiceLine}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add <i className="fa fa-plus" />
      </button>
    );

    let refSearchButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleRefSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        RefId <i className="fa fa-search" />
      </button>
    );

    let refSearchButtonPassive = (
      <button
        className="btn_reg_70_disabled btn_reg_70_disabled_pos"
        type="button"
        // onClick={this.handleRefSearch}
        // style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        RefId <i className="fa fa-search" />
      </button>
    );

    let checkIfBaandaButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handlecheckIfBaanda}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Fetch
      </button>
    );

    let sendInviteButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleSendInviteToJoinBaanda}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Invite
      </button>
    );

    let editAddresslButton = (
      <button
        className="btn_reg_70 manual-inv-edit-pos"
        type="button"
        onClick={this.handleChangeTargetAddress}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Edit <i className="fa fa-edit"></i>
        {/* <i className="fa fa-ship" />  */}
      </button>
    );

    let calculateButton = (
      <button
        className="btn_reg_100 btn-man-inv-submit-pos"
        type="button"
        onClick={this.handleInvoiceTotalCalculation}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Calculate <i className="fa fa-calculator" />
      </button>
    );

    let invoiceSubmitButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={() => this.handleUpdate("post")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Submit
      </button>
    );

    let invoiceDraftButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={() => this.handleUpdate("wip")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Draft
      </button>
    );

    let invoiceTermButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleCreateTerms}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Terms
      </button>
    );

    let opsTypePanel;
    opsTypePanel = (
      <div className="row rec-main-select-ops-row-x">
        <div className="col text-center radio-font-style">
          <strong>Act on&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="newinvoice"
                checked={this.state.actionType === "newinvoice"}
                onChange={this.handleOpsType}
              />{" "}
              New Invoice
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="wipinvoice"
                checked={this.state.actionType === "wipinvoice"}
                onChange={this.handleOpsType}
              />{" "}
              Work In progress
            </label>
          </div>
        </div>
      </div>
    );

    let invoiceRefTypePanel;
    invoiceRefTypePanel = (
      <div className="row rec-main-select-ops-row">
        <div className="col text-center radio-font-style">
          <strong>Reference Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="service"
                checked={this.state.referenceType === "service"}
                onChange={this.handleReferenceType}
              />{" "}
              Custom Service
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="projects"
                checked={this.state.referenceType === "projects"}
                onChange={this.handleReferenceType}
                disabled
              />{" "}
              Projects
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="other"
                checked={this.state.referenceType === "other"}
                onChange={this.handleReferenceType}
              />{" "}
              Other
            </label>
          </div>
        </div>
      </div>
    );

    let termsPanel;
    if (this.state.showTermsFlag) {
      termsPanel = (
        <div className="invoice-terms-box">
          <div className="text-center invoice-terms-edit-head">
            Edit Payment Terms&nbsp;{exitTermsButton}
          </div>
          <div className="row">
            <div className="col-2 text-right invoice-terms-lbl">Terms</div>
            <div className="col-10 text-right">
              <textarea
                name="invoiceTerms"
                maxLength="1000"
                placeholder=""
                rows="4"
                wrap="hard"
                spellCheck="true"
                className="edit-invoice-terms-textarea-x"
                onChange={this.onChange}
                value={this.state.invoiceTerms}
                required
              />
            </div>
          </div>
          {this.state.subLineItemType === "retainer" ? (
            <div className="row">
              <div className="col-2 text-right invoice-terms-lbl">&nbsp;</div>
              <div className="col-2 text-left">&nbsp;</div>
              <div className="col-8 text-center term-due-date">
                The quotation is valid till {this.state.byDate}.
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-2 text-right invoice-terms-lbl">Fine APR</div>
              <div className="col-2 text-left">
                <input
                  name="delayFineApr"
                  type="number"
                  value={this.state.delayFineApr}
                  onChange={this.onChangeCurrency}
                  className="manual-invoice-fine-apr"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                  spellCheck="false"
                />{" "}
                &nbsp;%
              </div>
              <div className="col-8 text-center term-due-date">
                Invoice to be paid by {this.state.byDate}.
              </div>
            </div>
          )}
        </div>
      );
    }

    let inviteSetupPanel;
    if (this.state.sendInvoteToRegister) {
      inviteSetupPanel = (
        <div className="">
          <div className="text-center new-inv-invite-head">
            Invite with a letter
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Subject</div>
            <div className="col-10 text-left">
              <input
                name="subject"
                type="text"
                value={this.state.subject}
                onChange={this.onChange}
                size="60"
                maxLength="60"
                className="inv-invite-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Salute</div>
            <div className="col-10 text-left">
              <input
                name="salute"
                type="text"
                value={this.state.salute}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="inv-invite-salute"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Name</div>
            <div className="col-10 text-left">
              <input
                name="inviteeName"
                type="text"
                value={this.state.inviteeName}
                onChange={this.onChange}
                size="60"
                maxLength="60"
                className="inv-invite-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Letter</div>
            <div className="col-10 text-left">
              <textarea
                name="inviteLetter"
                maxLength="1000"
                placeholder=""
                rows="4"
                wrap="hard"
                spellCheck="true"
                className="inv-invite-letter"
                onChange={this.onChange}
                value={this.state.inviteLetter}
                required
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Respect</div>
            <div className="col-10 text-left">
              <input
                name="respect"
                type="text"
                value={this.state.respect}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="inv-invite-salute"
                placeholder=""
              />
            </div>
          </div>
          {sendInviteButton}
        </div>
      );
    }

    let showTasksPanel;
    if (this.state.tasks.length > 0 && this.state.showTasksToInvoiceFlag) {
      let bool2 = true;
      showTasksPanel = (
        <div className="task-to-invoice-box">
          <div className="text-center task-pm-select-header">
            Select a Task to Invoice &nbsp;&nbsp;
            <button
              onClick={this.exitTaskSselect}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_back_main"
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row">
            <div className="col-10 text-left task-details-list-tbl-head">
              Id | Item Name | Task Name | Customer Name ( Email)
            </div>
            <div className="col-2 text-left task-details-list-tbl-head">
              Ops
            </div>
          </div>
          <div className="task-to-invoice-sel-box">
            {this.state.tasks.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool2
                        ? "row row-col-details-dr"
                        : "row row-col-details-lt"
                    }
                  >
                    <div className="col-10 text-left task-pm-select-lbl">
                      {obj.taskId} | {obj.itemName} | {obj.taskName} |{" "}
                      {obj.targetContact.name} ({obj.targetContact.email})
                    </div>
                    <div className="col-2 text-center">
                      <button
                        onClick={() => this.doneTaskSelection(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        className="btn_reg_40"
                      >
                        Sel
                      </button>
                    </div>
                  </div>
                  {(bool2 = !bool2)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let refIdPanel;
    if (this.state.referenceType === "service") {
      refIdPanel = (
        <div className="row ref-id-row">
          <div className="col-2 text-left inv-refid-lbl">Pay By</div>
          <div className="col-6 text-left inv-refid-lbl">
            <DatePicker
              selected={this.state.payByDate}
              onSelect={this.handlePayByDate}
              onChange={this.handlePayByDate}
              minDate={new Date()}
              name="payByDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-1 text-right inv-refid-btn-pos">
            {refSearchButton}
          </div>
          <div className="col-2 text-right inv-refid-lbl">TaskId</div>
          <div className="col-1 text-left inv-refid-lbl-taskid">
            {this.state.referenceId}
          </div>
        </div>
      );
    } else {
      refIdPanel = (
        <div className="row ref-id-row">
          <div className="col-2 text-left inv-refid-lbl">Pay By</div>
          <div className="col-6 text-left inv-refid-lbl">
            <DatePicker
              selected={this.state.payByDate}
              onSelect={this.handlePayByDate}
              onChange={this.handlePayByDate}
              minDate={new Date()}
              name="payByDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-1 text-right inv-refid-btn-pos">
            {refSearchButtonPassive}
          </div>
          <div className="col-1 text-right inv-refid-lbl">&nbsp;</div>
          <div className="col-2 text-left inv-refid-lbl">N/A</div>
        </div>
      );
    }

    let referencePanel;
    referencePanel = (
      <div className="row">
        <div className="col-6 text-center">{invoiceRefTypePanel}</div>
        <div className="col-6 text-center">{refIdPanel}</div>
      </div>
    );

    let detialLineType;
    if (this.state.subLineItemType === "retainer") {
      detialLineType = (
        <div>
          <div className="row rec-main-select-ops-row">
            <div className="col text-center radio-font-style">
              <strong>SubType&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="retainer"
                    checked={this.state.subLineItemType === "retainer"}
                    onChange={this.handleSubLineitemtype}
                  />{" "}
                  Retain
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="milestone"
                    checked={this.state.subLineItemType === "milestone"}
                    onChange={this.handleSubLineitemtype}
                    disabled
                  />{" "}
                  Milestone
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="other"
                    checked={this.state.subLineItemType === "other"}
                    onChange={this.handleSubLineitemtype}
                    disabled
                  />{" "}
                  Other
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      detialLineType = (
        <div>
          <div className="row rec-main-select-ops-row">
            <div className="col text-center radio-font-style">
              <strong>SubType&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="retainer"
                    checked={this.state.subLineItemType === "retainer"}
                    onChange={this.handleSubLineitemtype}
                    disabled
                  />{" "}
                  Retain
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="milestone"
                    checked={this.state.subLineItemType === "milestone"}
                    onChange={this.handleSubLineitemtype}
                  />{" "}
                  Milestone
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="other"
                    checked={this.state.subLineItemType === "other"}
                    onChange={this.handleSubLineitemtype}
                  />{" "}
                  Other
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let whoPayTaxPanel;
    whoPayTaxPanel = (
      <div className="row">
        <div className="col text-center man-inv-tax-by-font">
          <strong>Tax by&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="customer"
                checked={this.state.taxPaidBy === "customer"}
                onChange={this.handleTaxPaidBy}
              />{" "}
              Customer
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="business"
                checked={this.state.taxPaidBy === "business"}
                onChange={this.handleTaxPaidBy}
              />{" "}
              Business
            </label>
          </div>
        </div>
      </div>
    );

    let aLineItemPanel;
    if (this.state.showBillToDetailFlag) {
      aLineItemPanel = (
        <div className="invoice-lineitem-box">
          <div className="row inv-line-of-row1">
            <div className="col-2 text-right inv-sl-lbl">
              Line #:&nbsp;{this.state.lineNo}
            </div>
            <div className="col-5 text-left inv-refid-lbl">
              {detialLineType}
            </div>
            {this.state.referenceType !== "other" &&
            this.state.subLineItemType === "milestone" ? (
              <div className="col-1 text-right inv-sl-lbl">SubId</div>
            ) : (
              <div className="col-1 text-right inv-sl-lbl">&nbsp;</div>
            )}

            {this.state.referenceType !== "other" &&
            this.state.subLineItemType === "milestone" ? (
              <div className="col-2 text-left inv-refid-lbl">
                <input
                  name="subId"
                  type="number"
                  value={this.state.subId}
                  onChange={this.onChangeInteger}
                  className="inv-sub-id"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
              </div>
            ) : (
              <div className="col-2 text-left inv-refid-lbl">&nbsp;</div>
            )}

            <div className="col-2 text-left inv-refid-lbl">
              {/* {refSearchSubLevelButton} */}
              &nbsp;
            </div>
          </div>
          <div className="row inv-line-of-row">
            <div className="col-2 text-right inv-sl-lbl">Describe</div>
            <div className="col-9 text-right inv-sl-lbl">
              <input
                name="lineDescription"
                type="text"
                value={this.state.lineDescription}
                onChange={this.onChange}
                size="250"
                maxLength="250"
                className="inv-bill-to-email"
                placeholder=""
              />
            </div>
            <div className="col-1 text-right inv-sl-lbl">&nbsp;</div>
          </div>
          <div className="row inv-line-of-row">
            <div className="col-2 text-right inv-sl-lbl">Price</div>
            <div className="col-4 text-left inv-sl-lbl">
              <input
                name="linePrice"
                type="number"
                value={this.state.linePrice}
                onChange={this.onChangeCurrency}
                className="comm-membership-fee"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-2 text-right inv-sl-lbl">Qty</div>
            <div className="col-4 text-left inv-sl-lbl">
              <input
                name="lineQty"
                type="number"
                value={this.state.lineQty}
                onChange={this.onChangeCurrency}
                className="comm-membership-fee"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row inv-line-of-row">
            <div className="col-2 text-right inv-sl-lbl">Unit</div>
            <div className="col-2 text-left inv-sl-lbl">
              <input
                name="lineUnit"
                type="text"
                value={this.state.lineUnit}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="inv-line-unit"
                placeholder=""
              />
            </div>
            {this.state.subLineItemType === "retainer" ? (
              <div className="col-2 text-center inv-sl-lbl-tax">Retainer</div>
            ) : (
              <div className="col-2 text-center inv-sl-lbl-tax">
                <Checkbox
                  checked={this.state.lineIsTaxable}
                  onChange={this.handleIsTaxable}
                />{" "}
                Is taxable
              </div>
            )}

            <div className="col-4 text-center inv-sl-lbl-tax">
              {this.state.lineIsTaxable ? whoPayTaxPanel : null}
            </div>
            <div className="col-2 text-left inv-sl-lbl">{addLineButton}</div>
          </div>
        </div>
      );
    }

    let totalLinePanel;
    if (this.state.showBillToDetailFlag) {
      totalLinePanel = (
        <div className="row total-line-pos">
          <div className="col-2 text-center sub-total-inv">Totals</div>
          <div className="col-4 text-left sub-total-inv">
            Taxables = $
            {this.commaFormattedCurrency(this.state.subTotalTaxable)}
          </div>
          <div className="col-4 text-left sub-total-inv">
            Non Taxables = $
            {this.commaFormattedCurrency(this.state.subTotalNontaxable)}
          </div>
          <div className="col-2 text-right sub-total-inv">
            {this.commaFormattedCurrency(
              this.state.subTotalTaxable + this.state.subTotalNontaxable
            )}
          </div>
        </div>
      );
    }

    let shippingLinePanel;
    if (this.state.showBillToDetailFlag) {
      shippingLinePanel = (
        <div className="row ship-line-pos">
          {this.state.subLineItemType === "retainer" ? (
            <div className="col-1 ship-line-lbl text-right">&nbsp;</div>
          ) : (
            <div className="col-1 ship-line-lbl text-right">Shiping</div>
          )}
          {this.state.subLineItemType === "retainer" ? (
            <div className="col-3 text-left">&nbsp;</div>
          ) : (
            <div className="col-3 text-left">
              <input
                name="shippingCost"
                type="number"
                value={this.state.shippingCost}
                onChange={this.onChangeCurrencyShip}
                className="inv-ship-cost"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          )}

          <div className="col-1 ship-line-lbl text-right">
            {calculateButton}
          </div>
          {this.state.salesTaxAmt > 0 ? (
            <div className="col-4 text-left ship-line-lbl">
              Tax&nbsp;$
              {this.commaFormattedCurrency(this.state.salesTaxAmt)}
              &nbsp;&nbsp; @
              {this.commaFormattedCurrency(this.state.salesTaxPercent)}%
              &nbsp;Customer $
              {this.commaFormattedCurrency(this.state.customerTaxAmt)}
            </div>
          ) : (
            <div className="col-4 text-left">&nbsp;</div>
          )}
          {this.state.invoiceTotal > 0 ? (
            <div className="col-3 text-right ship-line-lbl">
              Total ${this.commaFormattedCurrency(this.state.invoiceTotal)}{" "}
            </div>
          ) : (
            <div className="col-3 text-right">&nbsp; </div>
          )}
        </div>
      );
    }

    let finalPanel;
    // if (this.state.showBillToDetailFlag) {
    finalPanel = (
      <div className="row inv-final-row-pos">
        {this.state.showFinalSubmit ? (
          <div className="col-4 text-right inv-final-lbl">
            <Checkbox
              checked={this.state.invConfirm}
              onChange={this.handleConfirmToSubmit}
            />{" "}
            Confirm to submit (final)
          </div>
        ) : (
          <div className="col-4 text-right inv-final-lbl">&nbsp;</div>
        )}
        {this.state.showFinalSubmit &&
        this.state.referenceType === "service" &&
        this.state.subLineItemType !== "retainer" ? (
          <div className="col-3 text-center inv-final-lbl">
            <Checkbox
              checked={this.state.taskStatus}
              onChange={this.handleTaskStatus}
            />{" "}
            Task Done
          </div>
        ) : (
          <div className="col-3 text-center inv-final-lbl">&nbsp;</div>
        )}
        {this.state.showFinalSubmit ? (
          <div className="col-1 text-left inv-final-lbl">
            {this.state.invConfirm ? invoiceSubmitButton : null}
          </div>
        ) : (
          <div className="col-1 text-left inv-final-lbl">&nbsp;</div>
        )}

        <div className="col-4 text-center inv-final-lbl">
          {invoiceTermButton}{" "}
          {this.state.draftButtonFlag ? invoiceDraftButton : null}
        </div>
      </div>
    );
    // }

    let invLineBody;
    if (this.state.showBillToDetailFlag) {
      let bool = true;
      invLineBody = (
        <div className="">
          <div className="row">
            <div className="col-1 text-center invoice-line-list-header">#</div>
            <div className="col-1 text-center invoice-line-list-header">
              Ops
            </div>
            <div className="col-8 text-left invoice-line-list-header">
              Description | Price | Qty | Unit | isTaxable
            </div>
            <div className="col-2 text-right invoice-line-list-header">
              Amount ${" "}
            </div>
          </div>
          <div className="invoice-lines-body-box">
            {this.state.addedLineItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-invline-dr" : "row row-col-invline-lt"
                    }
                  >
                    <div className="col-1 text-left invoice-line-item-no">
                      {obj.lineNo}.
                    </div>
                    <div className="col-1 text-center">
                      <button
                        className="btn_exit_del new-invoice-trash-btn-pos"
                        type="button"
                        onClick={() => this.selectThisItemToDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-8 text-left invoice-line-item">
                      {" "}
                      {obj.description} | $
                      {this.commaFormattedCurrency(obj.price)} |{" "}
                      {this.commaFormattedCurrency(obj.quantity)} | {obj.unit} |{" "}
                      {obj.isTaxable ? "Yes" : "No"}
                    </div>
                    <div className="col-2 text-right invoice-line-item">
                      {this.commaFormattedCurrency(obj.price * obj.quantity)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let billTodetailPanel;
    if (this.state.showBillToDetailFlag) {
      if (this.state.billToBaandaId === 0) {
        billTodetailPanel = (
          <div>
            <div className="text-center invoice-not-a-baanda-msg">
              Plaease enter a valid and Baanda email. Baanda registration is
              needed to process the invoice and the follow up activities. When
              your recipiant registers in Baanda, you will be able to raise the
              invoice. For more information, please click the help 'i' button.
            </div>
            {inviteSetupPanel}
            <div className="text-center invoice-send-invite">
              <Checkbox
                checked={this.state.sendInvoteToRegister}
                onChange={this.handleSendInvite}
              />{" "}
              Check to send an invite to join Baanda. &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        );
      } else {
        billTodetailPanel = (
          <div>
            <div className="row">
              <div className="col-2 text-right manual-invoice-lbl">Name</div>
              <div className="col-6 text-left manual-invoice-lbl">
                {this.state.billToName}
              </div>
              <div className="col-1 text-right manual-invoice-lbl">Cell</div>
              <div className="col-3 text-left manual-invoice-lbl">
                {this.formatPhoneNumber(this.state.billToCell)}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right manual-invoice-lbl">Address</div>
              <div className="col-10 text-left manual-invoice-lbl">
                {this.state.billToAddress} {editAddresslButton}
              </div>
            </div>
            {aLineItemPanel}
            {invLineBody}
            {totalLinePanel}
            {shippingLinePanel}
            {finalPanel}
          </div>
        );
      }
    }

    let billToPanel;
    if (this.state.showEmailInputFlag) {
      billToPanel = (
        <div>
          <div className="row">
            <div className="col-2 text-right manual-invoice-lbl">
              Bill To Email
            </div>
            <div className="col-8 text-left manual-invoice-lbl">
              <input
                name="billToEmail"
                type="text"
                value={this.state.billToEmail}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-bill-to-email"
                placeholder="email..."
              />
            </div>
            <div className="col-2 text-left manual-invoice-lbl">
              {checkIfBaandaButton}
            </div>
          </div>
          {billTodetailPanel}
        </div>
      );
    } else {
      billToPanel = (
        <div>
          <div className="row">
            <div className="col-2 text-right manual-invoice-lbl">
              Bill To Email
            </div>
            <div className="col-8 text-left manual-invoice-lbl">
              {this.state.billToEmail}
            </div>
            <div className="col-2 text-left manual-invoice-lbl">&nbsp;</div>
          </div>
          {billTodetailPanel}
        </div>
      );
    }

    let invCreatePanel;
    invCreatePanel = (
      <div className="fixedsize-invoice-workarea-box">{billToPanel}</div>
    );

    let outputPanel;
    if (this.state.showMainInvoiceCreation) {
      outputPanel = (
        <div className="manual-invoice-box">
          <div className="text-center ">
            <div className="row">
              <div className="col-4 text-right manual-inv-header">
                Manual Invoice Creation {helpModalButton}
              </div>
              {this.state.taskInvoiceFlag ? (
                <div className="col-6 text-center manual-task-amount-header">
                  Task : ${this.commaFormattedCurrency(this.state.totalProjAmt)}{" "}
                  &nbsp;|&nbsp; Invoiced: $
                  {this.commaFormattedCurrency(this.state.totalInvoiceAmount)}
                  &nbsp;|&nbsp; Paid: $
                  {this.commaFormattedCurrency(this.state.totalPaidAmount)}{" "}
                  &nbsp;{exitRaiseInvoice}
                </div>
              ) : (
                <div className="col-6"> {exitRaiseInvoice}</div>
              )}

              <div className="col-2 text-left manual-inv-header-no">
                Invoice Id: {this.state.invoiceId}
              </div>
            </div>

            {opsTypePanel}
            {referencePanel}
            {showTasksPanel}
            {termsPanel}
            {invCreatePanel}
          </div>

          <div
            className={
              this.state.errFlag
                ? "text-center invoice-manual-entry-msg-err"
                : "text-center invoice-manual-entry-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.showWipInvoiceList) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <WipInvoiceList
            communityId={this.props.communityId}
            store={store}
            returnToCaller={this.returnFromWipInvoiceList}
          />
        </div>
      );
    }

    if (this.state.showEditAddress) {
      outputPanel = (
        <div className="manual-invoice-box">
          <EditTargetAddress
            line1={this.state.line1}
            city={this.state.city}
            state={this.state.state}
            postalCode={this.state.postalCode}
            shipline1={this.state.shipline1}
            shipcity={this.state.shipcity}
            shipstate={this.state.shipstate}
            shippostalCode={this.state.shippostalCode}
            returnToCaller={this.returnFromChangeAddress}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

NewInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewInvoice)
);
