import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./SelectCoopStore.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
let getCustomerStore = "/routes/coop/getCustomerStore?";

const readMeCode = "1110311111";

class SelectCoopStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      selectedStore: null,

      stores: [],
      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.getListOfCustomerStore();

    this.setState({
      deviceSize,
    });
  };

  // This is to show the info panel
  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToCustMain = (store) => {
    // console.log("store:", store);
    this.setState({
      selectedStore: store,
    });
    this.props.returnToCaller(store);
  };

  cancelReturnToMain = () => {
    this.props.returnToCaller(null);
  };

  getListOfCustomerStore = async () => {
    let param =
      "communityId=" +
      this.props.communityId +
      "&customerBaandaId=" +
      this.props.auth.user.baandaId;
    let url = baandaServer + getCustomerStore + param;
    // console.log("url:", url);

    try {
      let str = await axios.get(url);
      if (str.data.status === "success") {
        this.setState({
          stores: str.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: str.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("SelectCoopStore...");

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    // let backButton = (
    //   <button
    //     className="btn_back_main"
    //     type="button"
    //     onClick={this.cancelReturnToMain}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     <i className="fas fa-step-backward" />
    //   </button>
    // );

    let storeListPanel;
    let bool = true;
    storeListPanel = (
      <div className="">
        <div className="coop-cust-stores-box">
          {this.state.stores.map((post, i) => {
            // console.log("post:", post);

            return (
              <div key={i}>
                <div
                  className={`${
                    bool ? "row-color-storelist-dr" : "row-color-storelist-lt"
                  }`}
                >
                  <div className="row">
                    {post.coopStoreId === 0 ? (
                      <div className="col-10 text-left coop-cust-store-list-lbl">
                        Community: {post.name} | Buys : {post.purchase.length} |
                        Tasks : {post.tasks.length}
                      </div>
                    ) : (
                      <div className="col-10 text-left coop-cust-store-list-lbl">
                        {post.name} | Store: {post.coopStoreName} | Keep:{" "}
                        {post.coopStoreKeeper} | Buys : {post.purchase.length} |
                        Tasks : {post.tasks.length}
                      </div>
                    )}
                    {this.state.selectedStore &&
                    parseFloat(this.state.selectedStore.communityId) ===
                      parseFloat(post.communityId) &&
                    parseFloat(this.state.selectedStore.coopStoreId) ===
                      parseFloat(post.coopStoreId) ? (
                      <div className="col-2 text-center ">
                        {/* <button
                        onClick={() => this.returnToCustMain(post)}
                        className={
                          this.state.deviceSize === "big"
                            ? "btn_reg_60"
                            : "cust-store-list-but-pos btn_reg_40"
                        }
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        { this.state.deviceSize === "big" ? "Open" : <i className="fas fa-check" />}
                      </button> */}
                        <i className="fas fa-check" />
                      </div>
                    ) : (
                      <div className="col-2 text-center ">
                        <button
                          onClick={() => this.returnToCustMain(post)}
                          className={
                            this.state.deviceSize === "big"
                              ? "btn_reg_60"
                              : "cust-store-list-but-pos btn_reg_40"
                          }
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          {this.state.deviceSize === "big" ? (
                            "Open"
                          ) : (
                            <i className="fas fa-check" />
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="store-select-box">
        <div className="text-center store-list-header">
          Select a Store&nbsp;&nbsp;{infoButton}
        </div>

        {storeListPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center sel-coop-store-msg-err"
              : "text-center sel-coop-store-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SelectCoopStore.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectCoopStore)
);
