import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ReactLoading from "react-loading";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./BudgetMain.css";

import { sortArrayOfObjects } from "../../../../../../utils/sortArrayOfObjects";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
// const getCategories = "/routes/dashboard/getCatagories?";
const getBroadCategories = "/routes/finance/getBroadCategories?";
const getExpBudgetData = "/routes/dashboard/getExpBudgetData?";
const saveCategoriesNBudget = "/routes/finance/saveCategoriesNBudget";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111110000";

class BudgetMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSize: "",

      mainPanelShow: false,
      categorySelectShow: true,

      finMMDD: "",
      finYYYY: "",
      classification: "expense",

      categoryOfType: "new",

      categoryOf: "",
      detailCategory: "",

      message: "",
      errFlag: false,

      categoryDefs: [],
      categoryLevel: "",
      selectedBroadCategory: null,
      returnToBroadCat: false,
      saveAllocationWork: false,
      selectedCategory: "",

      allocationAmt: 0,
      allocNote: "",
      allocBudgetId: 0,

      budgetAllocation: [],

      totalAlloc: 0,
      totalSpent: 0,

      editModeFlag: false,
      fromDb: false,
      loadingFlag: false,
    };
  }

  componentDidMount = async () => {
    await this.setState({
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
    });

    await this.getExpenseCategoryOfs();
    await this.getOriginalBudget();
    // console.log("CDM categoryDefs:", this.state.categoryDefs);
    // console.log("CDM budgetAllocation:", this.state.budgetAllocation);

    let totalAlloc = 0;
    let totalSpent = 0;
    this.state.budgetAllocation.forEach((obj) => {
      totalAlloc += obj.allocatedAmount;
      totalSpent += obj.spentAmount;
    });
    await this.setState({
      totalAlloc,
      totalSpent,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  getExpenseCategoryOfs = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&classification=expense";
    let url = baandaServer + getBroadCategories + params;
    try {
      let catret = await axios.get(url);
      // console.log("catret:", catret);
      if (catret.data.status === "success") {
        if (catret.data.Msg.length > 0) {
          catret.data.Msg.forEach((obj) => {
            obj.fromDb = true;
          });
          let newCatDef = [...catret.data.Msg];
          this.state.categoryDefs.forEach((elm) => {
            if (!elm.fromDb) {
              let catObj = {
                categories: elm.categories,
                categoryOf: elm.categoryOf,
                classification: elm.classification,
                communityId: elm.communityId,
                fromDb: elm.fromDb,
                insertProcess: elm.insertProcess,
                itemType: elm.itemType,
                updated_at: elm.updated_at,
                updated_by_bid: elm.updated_by_bid,
                active: elm.active,
              };
              newCatDef.push(catObj);
            }
          });
          this.setState({
            message: "",
            errFlag: false,
            categoryDefs: newCatDef,
          });
        } else {
          this.setState({
            message: "No broad expense categories has been defined yet.",
            errFlag: false,
            categoryDefs: [],
          });
        }
      } else {
        this.setState({
          message: catret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getExpenseCategoryOfs err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getOriginalBudget = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      parseFloat(this.props.communityId) +
      "&coopStoreId=" +
      coopStoreId +
      "&finMMDD=" +
      this.state.finMMDD +
      "&finYYYY=" +
      this.state.finYYYY +
      "&classification=expense";
    let url = baandaServer + getExpBudgetData + params;
    // console.log("url:", url);
    try {
      let bgtret = await axios.get(url);
      // console.log("bgtret.data:", bgtret.data.Msg);
      if (bgtret.data.status === "success") {
        await this.setState({
          budgetAllocation: bgtret.data.Msg,
          message: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: bgtret.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  handleCategoryOf = async (e) => {
    let value = e.target.value;
    let selectedBroadCategory = this.state.selectedBroadCategory;
    if (value === "select") selectedBroadCategory = null;
    let catdef = await sortArrayOfObjects(
      this.state.categoryDefs,
      "categoryOf",
      "dsc"
    );
    await this.setState({
      categoryOfType: e.target.value,
      categoryDefs: catdef,
      categoryLevel: "broad",
      message: "",
      errFlag: false,
      selectedBroadCategory,
    });
  };

  handleANewBroadCategory = async () => {
    let itExists = false;
    let message = "",
      errFlag = false;
    let newCatDef = [...this.state.categoryDefs];
    if (this.state.categoryOf !== "") {
      this.state.categoryDefs.forEach((obj) => {
        // console.log('210 obj:', obj.categoryOf, ' this.state.categoryOf:', this.state.categoryOf);
        if (obj.categoryOf === this.state.categoryOf) {
          itExists = true;
        }
      });
      if (itExists) {
        message = "Duplicate Broad category not allowed.";
        errFlag = true;
      } else {
        let catObj = {
          categories: [],
          categoryOf: this.state.categoryOf,
          classification: this.state.classification,
          communityId: this.props.communityId,
          fromDb: false,
          insertProcess: "regular",
          itemType: "",
          updated_at: new Date(),
          updated_by_bid: this.props.auth.user.baandaId,
          active: true,
        };
        newCatDef.push(catObj);
        message = "Successfully included. Click 'Select' to work on it.";
        errFlag = false;
      }
    } else {
      message = "Broad category cannot be blank.";
      errFlag = true;
    }

    await this.setState({
      categoryDefs: newCatDef,
      message,
      errFlag,
      categoryOf: "",
      detailCategory: "",
    });
  };

  allocateBudget = async () => {
    let sorted = await sortArrayOfObjects(
      this.state.budgetAllocation,
      "note",
      "dsc"
    );
    // console.log("276 sorted:", sorted);
    let selectedBroadCategory = this.state.selectedBroadCategory;
    let itExists = false;
    if (selectedBroadCategory) {
      this.state.budgetAllocation.forEach((obj) => {
        // console.log(
        //   "2222 obj:",
        //   obj,
        //   " this.state.selectedBroadCategory.categoryOf:",
        //   this.state.selectedBroadCategory.categoryOf
        // );
        if (obj.categoryOf === this.state.selectedBroadCategory.categoryOf)
          itExists = true;
      });
    }

    if (itExists) selectedBroadCategory = null;

    this.setState({
      budgetAllocation: sorted,
      mainPanelShow: true,
      categorySelectShow: false,
      selectedBroadCategory,
    });
  };

  handleCategorySelection = async () => {
    let catdef = await sortArrayOfObjects(
      this.state.categoryDefs,
      "categoryOf",
      "dsc"
    );
    this.setState({
      mainPanelShow: false,
      categorySelectShow: true,
      categoryLevel: "broad",
      message: "",
      errFlag: false,
      categoryDefs: catdef,
    });
  };

  selectThisAndShowCategories = async (obj) => {
    // console.log("obj:", obj);
    let modcat = [];
    obj.categories.forEach((elm) => {
      let fdb = elm.fromDb;
      if (elm.fromDb === undefined) fdb = true;
      let ncobj = {
        label: elm.label,
        value: elm.value,
        fromDb: fdb,
      };
      modcat.push(ncobj);
    });
    obj.categories = modcat;
    this.setState({
      selectedBroadCategory: obj,
      categoryLevel: "details",
      returnToBroadCat: false,
      message: "",
      errFlag: false,
    });
  };

  selectThisCategory = async (cat) => {
    // console.log("cat.value: ", cat.value);

    this.setState({
      selectedCategory: cat.value,
    });
    this.allocateBudget();
  };

  //   returnToBroadCat

  handlereturnToBroadCat = async () => {
    this.setState((prevstate) => ({
      returnToBroadCat: !prevstate.returnToBroadCat,
      categoryLevel: "broad",
      message: "",
      errFlag: false,
      detailCategory: "",
    }));
  };

  handleConfirmAllocSave = async () => {
    await this.setState((prevstate) => ({
      saveAllocationWork: !prevstate.saveAllocationWork,
    }));
  };

  onChangeOfAllocation = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        allocationAmt: val,
      });
    } else {
      await this.setState({
        allocationAmt: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  selectWIPCategory = async (cat) => {
    // console.log("cat:", cat);
    let newcat = [];
    this.state.categoryDefs.forEach((obj) => {
      if (obj.categoryOf !== cat.categoryOf) {
        newcat.push(obj);
      }
    });
    await this.setState({
      categoryDefs: newcat,
    });
  };

  addTheDetailCategory = async () => {
    let trimmed = this.state.detailCategory.replace(/\s+/g, " ").trim();
    // console.log("trimmed:", trimmed.toLowerCase());
    let message = "",
      errFlag = false;
    let newDetCats = [];
    let itExists = false;
    let detailCategory = this.state.detailCategory;
    try {
      // console.log("detailCategory:", detailCategory);
      this.state.selectedBroadCategory.categories.forEach((obj) => {
        // console.log("obj:", obj);
        if (obj.label.toLowerCase() === trimmed.toLowerCase()) {
          message = "Duplicate. Name comparison is case insensitive.";
          throw new Error(message);
        }
      });
      if (!itExists) {
        if (
          this.state.selectedBroadCategory.categoryOf.toLowerCase() ===
          "writeoff"
        ) {
          if (
            trimmed !== "inventory" ||
            trimmed !== "depreciate" ||
            trimmed !== "appreciate"
          )
            throw new Error(
              "For Writeoff, allowed detail categories are Inventory, Depreciate, & Appreciate."
            );
        }
        if (
          this.state.selectedBroadCategory.categoryOf.toLowerCase() === "event"
        ) {
          if (trimmed !== "tasks")
            throw new Error("For Event, allowed detail category is 'Tasks'.");
        }
        newDetCats = [...this.state.selectedBroadCategory.categories];
        // console.log('1 newDetCats:', newDetCats);
        let newdetcatobj = {
          label: trimmed,
          value: trimmed,
          fromDb: false,
        };
        newDetCats.push(newdetcatobj);
        // console.log('2. newDetCats:', newDetCats);
        let newCats = this.state.selectedBroadCategory;
        newCats.categories = newDetCats;
        this.setState({
          selectedBroadCategory: newCats,
        });
        // this.state.selectedBroadCategory.categories = newDetCats;
        this.state.categoryDefs.forEach((elm) => {
          if (elm.categoryOf === this.state.selectedBroadCategory.categoryOf) {
            elm.categories = newDetCats;
          }
        });
        detailCategory = "";
      }
      let newCategoryOfDefs = [...this.state.categoryDefs];
      // console.log("3. newCategoryOfDefs:", newCategoryOfDefs);
      this.setState({
        categoryDefs: newCategoryOfDefs,
        message,
        errFlag,
        detailCategory,
      });
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  handleAddAllocation = async () => {
    // console.log("In handleAddAllocation");
    let budgetAlloc;
    if (this.state.editModeFlag) {
      // console.log("In edit message field");
      budgetAlloc = [];
      this.state.budgetAllocation.forEach((elm) => {
        if (elm.budgetId === this.state.allocBudgetId) {
          let newAllocObj = elm;
          newAllocObj.allocatedAmount = parseFloat(this.state.allocationAmt);
          newAllocObj.note = this.state.allocNote;

          budgetAlloc.push(newAllocObj);
        } else {
          budgetAlloc.push(elm);
        }
      });
    } else {
      budgetAlloc = [...this.state.budgetAllocation];
      let budgetAllocObj = {
        allocatedAmount: parseFloat(this.state.allocationAmt),
        budgetId: 0,
        categoryOf: this.state.selectedBroadCategory.categoryOf,
        classification: "expense",
        communityId: this.props.communityId,
        finMMDD: this.state.finMMDD,
        finYYYY: this.state.finYYYY,
        spentAmount: 0,
        note: this.state.allocNote,
        updated_at: new Date(),
        updated_by_bid: this.props.auth.user.baandaId,
      };
      budgetAlloc.push(budgetAllocObj);
    }

    let totalAlloc = 0;
    let totalSpent = 0;
    budgetAlloc.forEach((obj) => {
      totalAlloc += obj.allocatedAmount;
      totalSpent += obj.spentAmount;
    });
    this.setState({
      budgetAllocation: budgetAlloc,
      allocationAmt: 0,
      allocNote: "",
      selectedCategory: "",
      selectedBroadCategory: null,
      totalAlloc,
      totalSpent,
      editModeFlag: false,
    });
  };

  activateOrDeactivate = async (obj) => {
    let curActiveFlg = obj.active;
    let tempCats = [...this.state.categoryDefs];
    tempCats.forEach((elm) => {
      if (elm.categoryOf === obj.categoryOf) {
        elm.active = !curActiveFlg;
      }
    });
    await this.setState({
      categoryDefs: tempCats,
    });
  };

  saveAllocationWorkFn = async () => {
    this.setState({
      loadingFlag: true,
    });
    let coopStoreId = 0;

    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let url = baandaServer + saveCategoriesNBudget;

    let input = {
      categoryDefs: this.state.categoryDefs,
      budgetAllocation: this.state.budgetAllocation,
      communityId: this.props.communityId,
      coopStoreId,
      baandaId: this.props.auth.user.baandaId,
      clientProgram: "BudgetMain",
      clientFunction: "saveAllocationWorkFn",
      classification: this.state.classification,
      finMMDD: this.state.finMMDD,
      finYYYY: this.state.finYYYY,
    };
    // console.log("url:", url, " input:", input);

    try {
      let retbgt = await axios.post(url, input);
      if (retbgt.data.status === "success") {
        await this.getExpenseCategoryOfs();
        await this.getOriginalBudget();
        this.setState({
          message: "Successfully updated",
          errFlag: false,
          loadingFlag: false,
          allocationAmt: 0,
          allocNote: "",
        });
      } else {
        this.setState({
          message: retbgt.data.Msg,
          errFlag: true,
          loadingFlag: false,
        });
      }
    } catch (err) {
      console.log("saveAllocationWorkFn err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
        loadingFlag: false,
      });
    }
  };

  editThisBudgetLine = async (obj) => {
    // console.log("editThisBudgetLine obj:", obj);
    // console.log("this.state.categoryDefs:", this.state.categoryDefs);
    let selectedBroadCategory;
    let selectedCategory = obj;
    let allocationAmt = obj.allocatedAmount;
    let allocNote = obj.note;
    let allocBudgetId = obj.budgetId;
    this.state.categoryDefs.forEach((elm) => {
      if (elm.categoryOf === obj.categoryOf) {
        selectedBroadCategory = elm;
      }
      // console.log("selectedBroadCategory:", selectedBroadCategory);
    });

    await this.setState({
      selectedBroadCategory,
      selectedCategory,
      allocationAmt,
      allocNote,
      allocBudgetId,
      editModeFlag: true,
    });
  };

  deleteThisDetailCategory = async (cat) => {
    // console.log('cat:', cat);
    // console.log('this.state.selectedBroadCategory:', this.state.selectedBroadCategory);
    let delcats = [];
    this.state.selectedBroadCategory.categories.forEach((obj) => {
      if (obj.value !== cat.value) delcats.push(obj);
    });
    let newDelCats = this.state.selectedBroadCategory;
    newDelCats.categories = delcats;
    this.setState({
      selectedBroadCategory: newDelCats,
    });
    // this.state.selectedBroadCategory.categories = delcats;
    let trns = this.state.selectedBroadCategory;
    // console.log('this.state.selectedBroadCategory:', this.state.selectedBroadCategory);
    await this.setState({
      selectedBroadCategory: trns,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("BudgetMain...");

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div className="subscription-spinner-pos text-center">
          <ReactLoading
            type={"spokes"}
            color={"#1f3d6b"}
            height={30}
            width={30}
          />
        </div>
      );
    }

    let helpButton;
    helpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addButton;
    addButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleAddAllocation}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-plus" />
        &nbsp;&nbsp;<i className="fa fa-crosshairs"></i>
      </button>
    );

    let categorySelButton;
    categorySelButton = (
      <button
        className="btn_reg_100"
        type="button"
        onClick={this.handleCategorySelection}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Category&nbsp;
        <i className="fa fa-cogs" />
      </button>
    );

    let budgetHeaderPanel;
    budgetHeaderPanel = (
      <div className="budget-header-pos">
        <div className="budget-header-text text-center">
          Expense Budget Mgmt.&nbsp;{helpButton} {categorySelButton}
        </div>
        <div className="text-center bgt-for-finyear">
          Budgeting For year {this.state.finYYYY} starting on{" "}
          {this.state.finMMDD} (mm-dd)
        </div>
      </div>
    );

    let categoryTypetHeaderPanel;
    categoryTypetHeaderPanel = (
      <div className="budget-header-pos">
        <div className="budget-header-text text-center">
          Category Selection (Expense)&nbsp;&nbsp;
          <button
            className="btn_reg_80"
            type="button"
            onClick={this.allocateBudget}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Allocate
          </button>
          &nbsp;
          {helpButton}
        </div>
      </div>
    );

    let selectCatOfPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Category Of&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.categoryOfType === "new"}
                onChange={this.handleCategoryOf}
              />{" "}
              New
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="select"
                checked={this.state.categoryOfType === "select"}
                onChange={this.handleCategoryOf}
              />{" "}
              Select
            </label>
          </div>
        </div>
      </div>
    );

    let categoryPanel;
    if (this.state.categorySelectShow && this.state.categoryOfType === "new") {
      categoryPanel = (
        <div>
          {selectCatOfPanel}
          <div className="row">
            <div className="col-4 text-right bgt-category-lbl">
              Broad Category
            </div>
            <div className="col-6 text-left ">
              <input
                name="categoryOf"
                type="text"
                value={this.state.categoryOf}
                onChange={this.onChange}
                size="40"
                maxLength="40"
                className="bgt-category-of"
                placeholder=""
              />
            </div>

            <div className="col-2 text-left bgt-category-lbl">
              <button
                className="btn_reg_50"
                type="button"
                onClick={this.handleANewBroadCategory}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      );
    }

    if (
      this.state.categorySelectShow &&
      this.state.categoryOfType === "select" &&
      this.state.categoryLevel === "broad"
    ) {
      let bool = true;
      if (this.state.categoryDefs.length > 0) {
        categoryPanel = (
          <div>
            {selectCatOfPanel}
            <div className="row">
              <div className="col-3 bgt-select-list-lbl text-center">Ops</div>
              <div className="col-9 bgt-select-list-lbl text-left">
                Broad Category Name
              </div>
            </div>
            <div className="fixedsize-bgt-category-list">
              {this.state.categoryDefs.map((obj, i) => {
 
                let activeMsg = "";
                if (obj.fromDb) {
                  if (obj.active) activeMsg = "In Db & Active";
                  else activeMsg = "In Db & Inactive";
                } else {
                  activeMsg = "New - WIP";
                }
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-3 text-center">
                        {obj.fromDb ? (
                          <button
                            className="btn-bgt-cat-del-disabled"
                            type="button"
                          >
                            <i className="fa fa-trash" />
                          </button>
                        ) : (
                          <button
                            className="btn-bgt-cat-del"
                            type="button"
                            onClick={() => this.selectWIPCategory(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        )}
                        {obj.fromDb ? (
                          <button
                            className="btn-bgt-cat-active-toggle"
                            type="button"
                            onClick={() => this.activateOrDeactivate(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-undo" />
                          </button>
                        ) : (
                          <button
                            className="btn-bgt-cat-active-toggle-disabled"
                            type="button"
                            // onClick={() => this.selectThisAndShowCategories(obj)}
                            // style={{
                            //   cursor: this.state.disabled ? "default" : "pointer",
                            // }}
                          >
                            <i className="fa fa-undo" />
                          </button>
                        )}

                        <button
                          className="btn_reg_40 fin_budget_check"
                          type="button"
                          onClick={() => this.selectThisAndShowCategories(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check"></i>
                        </button>
                      </div>
                      <div className="col-9 text-left item-category-label">
                        {obj.categoryOf}&nbsp;(
                        {activeMsg})
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        categoryPanel = (
          <div>
            {selectCatOfPanel}
            <div className="text-center no-broad-cat-defined">
              No expense broad category has been defined
            </div>
          </div>
        );
      }
    }

    let addDetailCategoryPanel = (
      <div>
        <div className="row detail-cat-add-row">
          <div className="col-4 text-right detail-cat-add-lbl">
            Detail Category Name
          </div>
          <div className="col-6 text-left detail-cat-add-lbl">
            <input
              name="detailCategory"
              type="text"
              value={this.state.detailCategory}
              onChange={this.onChange}
              size="40"
              maxLength="40"
              className="bgt-detail-category"
              placeholder=""
            />
          </div>
          <div className="col-2 text-left">
            <button
              className="btn_reg_40"
              type="button"
              onClick={this.addTheDetailCategory}
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </div>
    );

    if (
      this.state.selectedBroadCategory &&
      this.state.categorySelectShow &&
      this.state.categoryOfType === "select" &&
      this.state.categoryLevel === "details"
    ) {
      let bool1 = true;
      categoryPanel = (
        <div>
          <div className="row">
            <div className="col-3 bgt-select-list-lbl text-center">Ops</div>
            <div className="col-9 bgt-select-list-lbl text-left">
              Details Category of ({this.state.selectedBroadCategory.categoryOf}
              )
            </div>
          </div>
          <div className="fixedsize-bgt-category-list">
            {this.state.selectedBroadCategory.categories.map((obj, i) => {
 
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-3 text-center">
                      {obj.fromDb ? (
                        <button
                          className="btn-bgt-cat-del-disabled"
                          type="button"
                        >
                          <i className="fa fa-trash" />
                        </button>
                      ) : (
                        <button
                          className="btn-bgt-cat-del"
                          type="button"
                          onClick={() => this.deleteThisDetailCategory(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      )}
                      {/* <button
                        className="btn_reg_40 fin_budget_detail_check"
                        type="button"
                        onClick={() => this.selectThisCategory(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check"></i>
                      </button> */}
                    </div>
                    <div className="col-9 text-left item-category-label">
                      {obj.label}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
          {addDetailCategoryPanel}
          <div className="text-center bgt-reselect-broad">
            <Checkbox
              checked={this.state.returnToBroadCat}
              onChange={this.handlereturnToBroadCat}
            />{" "}
            Go to Broad Category
          </div>
        </div>
      );
    }

    let budgetAllocList;
    if (this.state.budgetAllocation.length > 0) {
      let bool2 = true;
      budgetAllocList = (
        <div>
          <div className="row">
            <div className="col-1 bgt-select-list-lbl text-center">Ops</div>
            <div className="col-1 bgt-select-list-lbl text-right">Alloc</div>
            <div className="col-1 bgt-select-list-lbl text-right">Spent</div>
            <div className="col-4 bgt-select-list-lbl text-right">
              Broad category
            </div>
            <div className="col-5 bgt-select-list-lbl text-left">Note</div>
          </div>
          <div className="fixedsize-bgt-allocation-list">
            {this.state.budgetAllocation.map((obj, i) => {
   
              return (
                <div key={i}>
                  <div
                    className={
                      bool2
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.editThisBudgetLine(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                    </div>
                    <div className="col-1 text-right bgt-alloc-lbl">
                      {this.commaFormattedCurrency(obj.allocatedAmount)}
                    </div>
                    <div className="col-1 text-right bgt-alloc-lbl">
                      {this.commaFormattedCurrency(obj.spentAmount)}
                    </div>
                    <div className="col-4 text-right bgt-alloc-lbl">
                      {obj.categoryOf}
                    </div>
                    <div className="col-5 text-left bgt-alloc-lbl">
                      {obj.note}
                    </div>
                  </div>
                  {(bool2 = !bool2)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      budgetAllocList = (
        <div className="fixedsize-bgt-alloc-list">
          No budget has been allocated yet. Click 'i' for budgeting assistance.
        </div>
      );
    }

    let budgetingPanel;
    if (this.state.selectedBroadCategory) {
 
      budgetingPanel = (
        <div className="">
          <div className="text-center bgt-selected-cats">
            Selected broad-caterogry{" "}
            <b>{this.state.selectedBroadCategory.categoryOf}</b>{" "}
          </div>
          <div>
            <div className="row alloc-row-pos">
              <div className="col-3 text-right bgt-text-lbl">
                Allocated Amount
              </div>
              <div className="col-9 text-left">
                $
                <input
                  name="allocationAmt"
                  type="number"
                  value={this.state.allocationAmt}
                  onChange={this.onChangeOfAllocation}
                  className="bgt-allocation-amt"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                  spellCheck="false"
                />{" "}
                &nbsp;{addButton}
              </div>
            </div>
            <div className="row alloc-row-pos">
              <div className="col-3 text-right bgt-text-lbl">A Note</div>
              <div className="col-9 text-left">
                <input
                  name="allocNote"
                  type="text"
                  value={this.state.allocNote}
                  onChange={this.onChange}
                  size="100"
                  maxLength="100"
                  className="bgt-note-text"
                  placeholder="In 100 characters or less"
                />
              </div>
            </div>
          </div>
          {/* {budgetAllocList} */}
        </div>
      );
    }

    let totalNsavePanel;
    totalNsavePanel = (
      <div>
        <div className="row total-row-pos">
          <div className="col-1 bgt-select-list-lbl text-center">Total</div>
          <div className="col-2 bgt-total-alloc text-center">
            Alloc:$ {this.commaFormattedCurrency(this.state.totalAlloc)}
          </div>
          <div className="col-2 bgt-total-spent text-center">
            Spent:$ {this.commaFormattedCurrency(this.state.totalSpent)}
          </div>
          <div className="col-7 bgt-select-list-lbl text-left">&nbsp;</div>
        </div>
        <div className="row save-button-row">
          <div className="col-7 text-left alloc-save-message">
            Confirm to save. Definitiona are final on completion.
          </div>
          <div className="col-3 text-left alloc-save-message">
            <Checkbox
              checked={this.state.saveAllocationWork}
              onChange={this.handleConfirmAllocSave}
            />{" "}
            Confirm to save
            {uploadingSpin}
          </div>
          <div className="col-2 text-left alloc-save-message">
            {this.state.saveAllocationWork ? (
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.saveAllocationWorkFn}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.categorySelectShow) {
      outputPanel = (
        <div className="catagory-type-panel-box">
          {categoryTypetHeaderPanel}
          {categoryPanel}
          <div
            className={
              this.state.errFlag
                ? " text-center bgt-msgs-err"
                : "text-center bgt-msgs"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    if (this.state.mainPanelShow) {
      outputPanel = (
        <div className="fixedsize_budget_box">
          {budgetHeaderPanel}
          <div className="budgeting-input-box">{budgetingPanel}</div>
          {budgetAllocList}
          {totalNsavePanel}
          <div
            className={
              this.state.errFlag
                ? " text-center bgt-msgs-err"
                : "text-center bgt-msgs"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

BudgetMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BudgetMain));
