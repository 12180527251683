import React, { useState } from "react";

import "./ImageSlider.css";

const slideStyles = {
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const rightArrowStyles = {
  position: "absolute",
  top: "90%",
  transform: "translate(0, -50%)",
  right: "-20px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const leftArrowStyles = {
  position: "absolute",
  top: "90%",
  transform: "translate(0, -50%)",
  left: "-20px",
  fontSize: "45px",
  color: "#fff",
  zIndex: 1,
  cursor: "pointer",
};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const sliderStylesSmall = {
  position: "relative",
  height: "100%",
  width: "140%",
  marginLeft: "-50px",
};

const ImageSlider = ({ slides, domain, size }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const disable = false;

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex) => {
    // console.log("slideIndex:", slideIndex);
    setCurrentIndex(slideIndex);
  };
  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[currentIndex].location})`,
  };

  let sb = slides[currentIndex].sliderBody;
  // console.log('domain:', domain);
  let slideTextHeight = "220px";
  let slidePaddingLeft = "30px";
  let slideTitlePaddingLeft = "30px";
  if (domain === "campaignshow") {
    if (size === "big") {
      slideTextHeight = "180px";
      slidePaddingLeft = "30px";
      slideTitlePaddingLeft = "30px";
    } else {
      slideTextHeight = "140px";
      slidePaddingLeft = "20px";
      slideTitlePaddingLeft = "15px";
    }
  }

  let frameTitlePanel;
  if (sb.title !== "") {
    // console.log('slideTitlePaddingLeft:', slideTitlePaddingLeft);
    frameTitlePanel = (
      <div
        style={{
          backgroundColor: sb.titleFormat.bgColor,
          width: "100%",
          fontSize: sb.titleFormat.fontSize,
          fontFamily: sb.titleFormat.fontFamily,
          color: sb.titleFormat.color,
          textAlign: sb.titleFormat.align,
          paddingLeft: slideTitlePaddingLeft,
          paddingTop: "10px",
        }}
      >
        {sb.titleFormat.isBold ? <b>{sb.title}</b> : sb.title}
      </div>
    );
  }

  let frameTextPanel;
  if (sb.writeup !== "") {
    // console.log('>> slideTextHeight:', slideTextHeight);
    // console.log('sb.writeup:', sb.writeup);
    frameTextPanel = (
      <div
        style={{
          backgroundColor: sb.writeupFormat.bgColor,
          width: "100%",
          height: slideTextHeight,
          paddingTop: "10px",
          fontSize: sb.writeupFormat.fontSize,
          fontFamily: sb.writeupFormat.fontFamily,
          color: sb.writeupFormat.color,
          textAlign: sb.writeupFormat.align,
          paddingLeft: slidePaddingLeft,
        }}
        className="frame-writeup-show"
      >
        {sb.writeupFormat.isBold ? <b>{sb.writeup}</b> : sb.writeup}
      </div>
    );
  }

  let frameWriteupPanel = (
    <div className="slider-text-box">
      {frameTitlePanel}
      {frameTextPanel}
    </div>
  );

  return (
    <div style={size === "big" ? sliderStyles : sliderStylesSmall}>
      <div>
        <div onClick={goToPrevious} style={leftArrowStyles}>
          ❰
        </div>
        <div onClick={goToNext} style={rightArrowStyles}>
          ❱
        </div>
      </div>
      <div style={slideStylesWidthBackground}></div>

      <div className="dots-container-styles">
        {slides.map((slide, slideIndex) => (
          <div
            className="dot-style-slider"
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            style={{ cursor: disable ? "default" : "pointer" }}
          >
            {slideIndex === currentIndex ? (
              <div>&#x25A3;</div>
            ) : (
              <div>&#x25A2;</div>
            )}
          </div>
        ))}
      </div>
      <div className="text-center">{frameWriteupPanel}</div>
    </div>
  );
};

export default ImageSlider;
