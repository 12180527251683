import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./IndUpdateIdentity.css";

// import cosmicDoorway from "./image/cosmicDoorway.jpg";
import cosmicDoorway from "../../../../../communityCreate/components/image/cosmicDoorway.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const idfilehandling = "/routes/stripe/idfilehandling";
const saveIndividualIdInCommunity =
  "/routes/stripe/saveIndividualIdInCommunity";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const picTypes = [
  "image/jpeg",
  // "image/bmp",
  // "image/tiff",
  "image/png",
  // "image/gif",
  // "image/webp",
  "application/pdf",
];
const maxIdPicSize = 5000000; // 5MB

const readMeCode = "0000000000";

class IndUpdateIdentity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      indFaceUpFile: null,
      faceUpImageBlob: null,
      indFaceUpFileNameToDisplay: "",
      fileNameToDisplay: "",
      indFaceDownFile: null,
      faceDownImageBlob: null,
      indFaceDownFileNameToDisplay: "",

      // indFaceDownFile: null,
      comFaceUpImagePreviewUrl: null,
      // indFaceDownFileNameToDisplay: "",
      comFaceDownFile: null,
      comFaceDownImagePreviewUrl: null,
      comFaceDownFileNameToDisplay: "",

      uploadFileType: "faceup",

      pictureErrFlag: false,
      pictureErrMsg: "",
      //   File-upload state variables -----------------------
      highlight: false,
      currFilename: "",
      disabled: false,
      isFaceUpFlag: false,
      isFaceDownFlag: false,
      faceUpActiveFlag: true,
      faceDownActiveFlag: false,
      faceUpStripeFileId: "",
      pFacedownStripeFileId: null,
      faceDownStripeFileId: "",
      cFacedownStripeFileId: null,
      viewActiveFlag: false,
      uploadMsg: "Click or Tap File Dropzone.",
      pictureMsg:
        "Credit card processors require you to upload your ID card, and both sides (Face-up and faced-wn)",
      uploadBtnClicked: true,
      idSide: "Upload Id Card's FACE Side",
      directionMsg:
        "Please read the Help (i) for details. Your request may be rejected if the documents are not uploaded correctly.",
      showmsg: "",
      IdpictureSkip: false,
      loadingFlag: false,
      showmsgErrFlag: false,
      documentOfType: "individual",

      errMsg: "",

      idVerificationDone: false
    };

    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    this.setState({
      deviceSize,
      IdpictureSkip: this.props.IdpictureSkip,
      showmsg: "",
    });
    if (this.props.fileData) await this.loadDataInStateIdentity();
  };

  loadDataInStateIdentity = async () => {
    let id = this.props.fileData;
    // console.log("id:", id);

    // individual variable
    let ifufile,
      pfuiurl,
      pfuverifyid = "",
      pfdfile,
      pfdiurl,
      pfdverifyid = "";

    if (id && id.individual && id.individual.faceup.file)
      ifufile = id.individual.faceup.file;

    // console.log("id.individual.faceup:", id.individual.faceup);
    // if (id.individual.faceup.file) {
    //   console.log(
    //     "id.individual.faceup.file name:",
    //     id.individual.faceup.file.name
    //   );
    // }
    // console.log("ifufile:", ifufile);

    if (id && id.individual && id.individual.faceup.fileblob)
      pfuiurl = id.individual.faceup.fileblob;

    if (id && id.individual && id.individual.faceup.verificationFieldId)
      pfuverifyid = id.individual.faceup.verificationFieldId;
    if (id && id.individual && id.individual.facedown.file)
      pfdfile = id.individual.facedown.file;
    if (id && id.individual && id.individual.facedown.fileblob)
      pfdiurl = id.individual.facedown.fileblob;
    if (id && id.individual && id.individual.facedown.verificationFieldId)
      pfdverifyid = id.individual.facedown.verificationFieldId;

    let ifufntodisp = "",
      pfdfntodisp = "";
    if (ifufile && ifufile.name) ifufntodisp = ifufile.name;
    if (pfdfile && pfdfile) pfdfntodisp = pfdfile.name;

    let msg = "";

    let faceupdone = false,
      facedowndone = false;

    // console.log("pfuverifyid:", pfuverifyid, " pfdverifyid:", pfdverifyid);
    if (pfuverifyid !== "") {
      faceupdone = true;
      msg += "Face Up File Id: " + pfuverifyid + " | ";
    }
    if (pfdverifyid !== "") {
      facedowndone = true;
      msg += "Face Down File Id: " + pfdverifyid + " | ";
    }

    if (!faceupdone) msg += "Please load faceup of Id; ";
    if (!facedowndone) msg += "Please load facedown of Id; ";

    let  idVerificationDone = false;
    if ( this.props.fileData && this.props.fileData.individual) {
      if ( (this.props.fileData.individual.facedown &&  this.props.fileData.individual.facedown.verificationFieldId !== '') && (this.props.fileData.individual.faceup &&  this.props.fileData.individual.faceup.verificationFieldId !== '')) idVerificationDone = true;
    } 

    this.setState({
      indFaceUpFile: ifufile,
      indFaceUpImagePreviewUrl: pfuiurl,
      faceUpImageBlob: pfuiurl,
      indFaceUpFileNameToDisplay: ifufntodisp,
      indFaceDownFile: pfdfile,
      indFaceDownImagePreviewUrl: pfdiurl,
      faceDownImageBlob: pfdiurl,
      indFaceDownFileNameToDisplay: pfdfntodisp,
      faceupStripeField: pfuverifyid,
      facedownStripeFileId: pfdverifyid,

      fileNameToDisplay: ifufntodisp,
      showmsg: msg,
      idVerificationDone
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  // ---------------- File Upload Functions ------------------------------------
  onDragOver = async (evt) => {
    evt.preventDefault();
    await this.setState({ highlight: true });
  };

  onDragLeave = async () => {
    await this.setState({ highlight: false });
  };

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  onDrop = async (event) => {
    event.preventDefault();
    // console.log("Inside onDrop : ", this.state.disabled);
    // // console.log("this.props:", this.props);
    // if (this.state.disabled) return; // investigate
    const files = event.dataTransfer.files;
    // console.log("files:", files);
    // console.log(">>>  onDrag files: ", files[0].name);
    // console.log("this.state.logoActiveFlag:", this.state.logoActiveFlag);
    if (this.state.idFaceUpFlag) {
      await this.setState({
        highlight: false,
        currFilename: files[0],
        fileNameToDisplay: files[0].name,
        indFaceUpFileNameToDisplay: files[0].name,
      });
    }
    if (this.state.idFaceDownFlag) {
      await this.setState({
        highlight: false,
        currFilename: files[0],
        fileNameToDisplay: files[0].name,
        indFaceDownFileNameToDisplay: files[0].name,
      });
    }
  };

  onFilesAdded = async (evt) => {
    // console.log("onFileAdded");
    let fullPath = evt.target.value;
    let filename;
    if (fullPath) {
      filename = fullPath.split(/(\\|\/)/g).pop();
    }

    const file = evt.target.files[0];
    // console.log("fileName:", filename);
    // console.log("===================================");
    // console.log("file:", file);
    // console.log("===================================");

    let isVerified = { status: true, msg: "" };
    // pic=0, video=1, & audio=2 -- only three kinds now & pic is active.
    isVerified = this.verifyFiles(file, this.state.uploadFileType);
    // console.log("isVerified:", isVerified);

    let smsg = "";
    if (this.state.faceUpStripeFileId)
      smsg = "Face-up file_id: " + this.state.faceUpStripeFileId + ". ";
    if (this.state.faceDownStripeFileId)
      smsg =
        smsg + "Face-down File Id: " + this.state.faceDownStripeFileId + ". ";
    // smsg = smsg + this.state.directionMsg;
    // const files = evt.target.files;
    if (isVerified.status) {
      // console.log("verified ... true ...");
      // console.log("this.state.uploadFileType:", this.state.uploadFileType);
      // let reader = new FileReader();
      if (this.state.uploadFileType === "faceup") {
        let reader = new FileReader();
        // console.log("faceup...reader.result:", reader.result);
        reader.onload = async (e) => {
          await this.setState({
            indFaceUpFile: file,
            faceUpImageBlob: reader.result,
            indFaceUpFileNameToDisplay: filename,
            fileNameToDisplay: filename,
            pictureErrFlag: false,
            pictureErrMsg: "",
            showmsgErrFlag: false,
            showmsg: smsg,
          });
        };
        // console.log("this.state.faceUpImageBlob:", this.state.faceUpImageBlob);
        reader.readAsDataURL(file);
      } else {
        let reader = new FileReader();
        // console.log("facedown reader.result:", reader.result);
        reader.onload = async (e) => {
          this.setState({
            indFaceDownFile: file,
            faceDownImageBlob: reader.result,
            indFaceDownFileNameToDisplay: filename,
            fileNameToDisplay: filename,
            pictureErrFlag: false,
            pictureErrMsg: "",
            showmsgErrFlag: false,
            showmsg: smsg,
          });
        };
        reader.readAsDataURL(file);
      }
    } else {
      this.setState({
        pictureErrFlag: true,
        publishDeactivate: true,
        pictureErrMsg: isVerified.msg,
      });
    }
  };

  verifyFiles = (file, selType) => {
    // console.log("verifyFiles file:", file, "  selType:", selType);
    let msg = "";
    let isValid = true;
    if (file && (selType === "faceup" || selType === "facedown")) {
      if (!picTypes.includes(file.type)) {
        isValid = false;
        msg = "Invalid file type (" + file.type + "). ";
      }
      if (file.size > maxIdPicSize) {
        isValid = false;
        msg =
          msg +
          "Your picture size (" +
          file.size / 1000 +
          "kb) is above the limit.";
      }
    }

    let retMsg;
    if (file) {
      retMsg = {
        status: isValid,
        msg: msg,
      };
    } else {
      retMsg = {
        status: false,
        msg: "File not selected.",
      };
    }

    return retMsg;
  };

  setUploadType = async (type) => {
    // console.log("upload type:", type);
    // console.log("$$$$ this.state:", this.state);
    let msg, filenametodisp;
    if (this.state.documentOfType === "person") {
      if (type === "faceup ")
        filenametodisp = this.state.indFaceUpFileNameToDisplay;
      else filenametodisp = this.state.indFaceDownFileNameToDisplay;
    } else {
      if (type === "faceup ")
        filenametodisp = this.state.indFaceDownFileNameToDisplay;
      else filenametodisp = this.state.comFaceDownFileNameToDisplay;
    }

    let smsg = "";
    if (this.state.faceUpStripeFileId)
      smsg = "Person file_id: " + this.state.faceUpStripeFileId + ". ";
    if (this.state.faceDownStripeFileId)
      smsg =
        smsg + "Company File Id: " + this.state.faceDownStripeFileId + ". ";
    // smsg = smsg + this.state.directionMsg;

    if (type === "faceUp") {
      msg = "Valid file types are jpeg, png of less than 5MB in size.";
      await this.setState({
        faceUpActiveFlag: true,
        faceDownActiveFlag: false,
        idSide: "Upload Id Doc FACE Side",
        fileNameToDisplay: filenametodisp,
        viewActiveFlag: false,
        showmsg: smsg,
        showmsgErrFlag: false,
      });
    }
    if (type === "faceDown") {
      msg = "Valid file types are jpeg, png of less than 5MB in size.";
      await this.setState({
        faceUpActiveFlag: false,
        faceDownActiveFlag: true,
        idSide: "Upload Doc BACK Side",
        fileNameToDisplay: filenametodisp,
        viewActiveFlag: false,
        showmsg: smsg,
        showmsgErrFlag: false,
      });
    }

    let dirmsg = msg + " Click info (i button) for details.";

    await this.setState({
      uploadFileType: type,
      uploadBtnClicked: true,
      uploadMsg: "Click or Tap Dropzone.",
      pictureMsg: dirmsg,
    });
  };

  // --------- End of File blob uploads ---------------------------------------------

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  packagePicData = async () => {
    let id_files = {
      accId: this.props.fileData.accId,
      individual: {
        faceup: {
          verificationFieldId: this.state.faceUpStripeFileId,
          file: this.state.indFaceUpFile,
          fileblob: this.state.faceUpImageBlob,
        },
        facedown: {
          verificationFieldId: this.state.faceDownStripeFileId,
          file: this.state.indFaceDownFile,
          fileblob: this.state.faceDownImageBlob,
        },
      },
    };

    // console.log("id_files:", id_files);

    return id_files;
  };

  handleBackFromIdentity = async () => {
    // console.log("IndUpdateIdentity handle-back: ");
    let picData = await this.packagePicData();
    // console.log("handleBackFromIdentity picData:", picData);
    this.props.handleBack(picData, this.state.IdpictureSkip);
  };

  handleNextToIdentity = async () => {
    // console.log("handleNext data:");
    let picData = await this.packagePicData();
    // console.log("picData:", picData);
    // console.log("this.state:", this.state);

    if (!this.state.IdpictureSkip) {
      if (picData && picData.individual) {
        let doneup = true,
          donedown = true;
        if (
          picData.individual.facedown &&
          picData.individual.facedown.verificationFieldId === ""
        ) {
          donedown = false;
        }
        if (
          picData.individual.faceup &&
          picData.individual.faceup.verificationFieldId === ""
        ) {
          doneup = false;
        }
        if (donedown && doneup) {
          let cret = await this.saveIdInCommunity(picData);
          if (cret.status === "success") {
            this.setState({
              errMsg: "",
            });
            this.props.handleNext(picData, this.state.IdpictureSkip);
          } else {
            this.setState({
              errMsg: cret.Msg,
            });
          }
        } else {
          let msg = "";
          if (!doneup) msg += "Must complete ID face-up; ";
          if (!donedown) msg += "Must complete ID face-down; ";
          msg += "To skip ID, check Skip ID file load now.";
          this.setState({
            errMsg: msg,
          });
        }
      }
    } else {
      this.props.handleNext(picData, this.state.IdpictureSkip);
    }
  };

  saveIdInCommunity = async (picData) => {
    // console.log("save in comm picData:", picData);
    // console.log(
    //   "picData.individual.facedown.file:",
    //   picData.individual.facedown.file
    // );
    // console.log(
    //   "picData.individual.facedown.file.name:",
    //   picData.individual.facedown.file.name
    // );

    let newPicData = {
      accId: picData.accId,
      individual: {
        facedown: {
          fileblob: picData.individual.facedown.fileblob,
          verificationFieldId: picData.individual.facedown.verificationFieldId,
          file: {
            lastModified: picData.individual.facedown.file.lastModified,
            lastModifiedDate: picData.individual.facedown.file.lastModifiedDate,
            name: picData.individual.facedown.file.name,
            size: picData.individual.facedown.file.size,
            type: picData.individual.facedown.file.type,
            webkitRelativePath: "",
          },
        },
        faceup: {
          fileblob: picData.individual.faceup.fileblob,
          verificationFieldId: picData.individual.faceup.verificationFieldId,
          file: {
            lastModified: picData.individual.faceup.file.lastModified,
            lastModifiedDate: picData.individual.faceup.file.lastModifiedDate,
            name: picData.individual.faceup.file.name,
            size: picData.individual.faceup.file.size,
            type: picData.individual.faceup.file.type,
            webkitRelativePath: "",
          },
        },
      },
    };
    // console.log("newPicData:", newPicData);
    let url = baandaServer + saveIndividualIdInCommunity;
    let input = {
      communityId: this.props.communityId,
      newPicData,
    };
    try {
      let svret = await axios.post(url, input);
      if (svret.data.status === "success") {
        return { status: "success", Msg: "" };
      } else {
        return { status: "error", Msg: svret.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  handleViewId = async () => {
    let smsg = "";
    if (this.state.faceUpStripeFileId)
      smsg = "Individual Faceup Id: " + this.state.faceUpStripeFileId + ". ";
    if (this.state.faceDownStripeFileId)
      smsg =
        smsg +
        "Individual Facedown Id: " +
        this.state.faceDownStripeFileId +
        ". ";
    // smsg = smsg + this.state.directionMsg;
    this.setState({
      viewActiveFlag: true,
      faceUpActiveFlag: false,
      faceDownActiveFlag: false,
      uploadBtnClicked: false,
      showmsg: smsg,
    });
  };

  handleExitView = async () => {
    await this.loadDataInStateIdentity();
    this.setState({
      viewActiveFlag: false,
      faceUpActiveFlag: true,
      faceDownActiveFlag: true,
      uploadBtnClicked: true,
      // showmsg: '',
    });
  };

  handleSkip = async () => {
    this.setState((prevstate) => ({
      IdpictureSkip: !prevstate.IdpictureSkip,
    }));
  };

  uploadToVerifyId = async (e) => {
    // console.log("uploadToVerifyId");
    e.preventDefault();
    this.setState({
      loadingFlag: true,
      errMsg: "",
    });
    let retmsg;
    let errflg = false;
    let upDownfileId = "";
    if (this.state.indFaceUpFile || this.state.indFaceDownFile) {
      // console.log(
      //   "in here 111A this.state.indFaceUpFile:",
      //   this.state.indFaceUpFile
      // );
      // console.log(
      //   "in here 111B this.state.indFaceDownFile:",
      //   this.state.indFaceDownFile
      // );
      const formData = new FormData();
      // console.log("this.state.uploadFileType:", this.state.uploadFileType);
      if (this.state.uploadFileType === "faceup") {
        // console.log('>>> this.state.indFaceUpFile:', this.state.indFaceUpFile)
        formData.append("file", this.state.indFaceUpFile);
      } else {
        formData.append("file", this.state.indFaceDownFile);
      }

      formData.append("acc", this.props.fileData.accId);
      formData.append("communityId", this.props.communityId);
      formData.append("documentOfType", this.state.documentOfType);
      formData.append("uploadFileType", this.state.uploadFileType);

      let url = baandaServer + idfilehandling;
      // console.log(">>> url:", url);
      // console.log(">>> formData:", formData);
      try {
        let retstrip = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("retstrip:", retstrip);

        retmsg = "Successfully created for " + this.state.documentOfType + " ";

        if (this.state.uploadFileType === "faceup") {
          retmsg += "Face-up Id: " + retstrip.data.msg;
          if (this.state.faceDownStripeFileId !== "") {
            retmsg += " Face-down Id:" + this.state.faceDownStripeFileId;
          }
        }
        if (this.state.uploadFileType === "facedown") {
          retmsg += "Face-down Id: " + retstrip.data.msg;
          if (this.state.faceUpStripeFileId !== "") {
            retmsg += " Face-up Id:" + this.state.faceUpStripeFileId;
          }
        }

        upDownfileId = retstrip.data.msg;
      } catch (err) {
        // console.log("err:", err.message);
        retmsg = "Error: " + err.message;
        errflg = true;
      }
    } else {
      retmsg = "To upload, you must select a file first.";
      errflg = true;
    }
    this.setState({
      loadingFlag: false,
      // indFaceUpFile: null,
      // indFaceDownFile: null,
      showmsg: retmsg,
      showmsgErrFlag: errflg,

      // faceUpImageBlob: null,
      indFaceUpFileNameToDisplay: "",
      fileNameToDisplay: "",

      // faceDownImageBlob: null,
      indFaceDownFileNameToDisplay: "",
      // uploadFileType: "faceup",
    });

    if (this.state.uploadFileType === "faceup") {
      // console.log("faceup fileId:", upDownfileId);
      this.setState({
        faceUpStripeFileId: upDownfileId,
      });
    } else {
      // console.log("facedown fileId:", upDownfileId);
      this.setState({
        faceDownStripeFileId: upDownfileId,
      });
    }
  };

  handleIdTypeSelection = async (e) => {
    await this.setState({
      uploadFileType: e.target.value,
    });
  };

  render() {
    // console.log("IndUpdateIdentity this.state:", this.state);
    // console.log("IndUpdateIdentity this.props:", this.props);
    console.log("IndUpdateIdentity...");

    // Spinner while uploading, getting stripe IF, updating mongodb, deleting the file, and returning.
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    // ================== File Upload Panel =============================
    // Upload Panel _______________________________________________
    let uploadpanel;
    if (this.state.uploadBtnClicked) {
      if ( this.state.idVerificationDone) {
        uploadpanel = (
          <div>
            <div className="row id-verification-row-pos">
              <div className="col text-center id-verification-done-msg">
                Your verification is done. Once inspected by banking, it cannot be re-done.
              </div>
            </div>
            <div className="row">
              <div className="col text-center id-verification-done-msg">
                If you think you need to change the ID documents, then start again(delete this and re-enter all data.) 
              </div>
            </div>
          </div>
        )
      } else {
        uploadpanel = (
          <div>
            <div className="row">
              <div className="col-6">
                <div
                  className={`dropzone ${
                    this.state.highlight ? "Highlight" : ""
                  }`}
                  onDragOver={this.onDragOver}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
                  onClick={this.openFileDialog}
                  style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  <p className="text-center top_of_card_id">File Dropzone</p>
                  <img alt="upload" className="Icon" src={cosmicDoorway} />
                  <input
                    ref={this.fileInputRef}
                    className="FileInput"
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                  />
                </div>
              </div>
              <div className="col-6 upload_message">
                <div className="row">
                  <div className="col text-center ">
                    <p>{this.state.uploadMsg}</p>
                  </div>
                </div>
                <div className="pic_caption_msg text-center">
                  <p className="id_side">{this.state.idSide}</p>
                  <b>File:</b>&nbsp;<b>{this.state.fileNameToDisplay}</b>
                </div>
              </div>
            </div>
            <div className="cc-id-individual-msg text-center">
              {this.state.pictureMsg}
            </div>
            <div
              className={`${
                !this.state.pictureErrFlag
                  ? "save_review_msg pic_msg_placement"
                  : "save_review_msg_err pic_msg_placement"
              }`}
            >
              <p>{this.state.pictureErrMsg}</p>
            </div>
            <div className="row">
              <div className="col-6 text-center biz_updt_confirm_id">
                <Checkbox
                  checked={this.state.IdpictureSkip}
                  onChange={this.handleSkip}
                />
                &nbsp;&nbsp; Skip ID file load now.
              </div>
              {this.state.viewActiveFlag ? (
                <div className="col-6 text-center"></div>
              ) : (
                <div className="col-6 text-center">
                  <button
                    className="btn_reg_90"
                    type="button"
                    onClick={this.uploadToVerifyId}
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    Upload
                  </button>
                  {uploadingSpin}
                </div>
              )}
            </div>
          </div>
        );
      }

    }

    let viewPanel;
    if (this.state.viewActiveFlag) {
      viewPanel = (
        <div>
          <div className="row">
            <div className="col-6 text-center">
              {/* <div className="picture_review_card_id"> */}
              <p>Id Face-up</p>
              {this.state.faceUpImageBlob ? (
                <img
                  src={this.state.faceUpImageBlob}
                  alt="pic"
                  className="pic-preview-ind-card"
                />
              ) : (
                <div className="view-msg">
                  {this.state.faceUpStripeFileId ? (
                    <p align="justify">
                      The person ID verification file has already been loaded.
                    </p>
                  ) : (
                    <p align="justify">
                      You have not loaded person ID file yet.
                    </p>
                  )}
                </div>
              )}

              {/* </div> */}
            </div>
            <div className="col-6 text-center">
              {/* <div className="picture_review_card_id"> */}
              <p>Id Face-Down</p>
              {this.state.faceDownImageBlob ? (
                <img
                  src={this.state.faceDownImageBlob}
                  alt="pic"
                  className="pic-preview-ind-card"
                />
              ) : (
                <div className="view-msg">
                  {this.state.faceDownStripeFileId ? (
                    <p align="justify">
                      The company verification file has already been loaded.
                    </p>
                  ) : (
                    <p align="justify">
                      You have not loaded company verification file yet.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            &nbsp;
            <br />
          </div>
          <div className="row">
            <div className="col-6 text-center biz_updt_confirm_id">
              <Checkbox
                checked={this.state.IdpictureSkip}
                onChange={this.handleSkip}
              />
              &nbsp;&nbsp;2 Skip ID file load now.
            </div>
            <div className="col-6 text-center">
              &nbsp;
            </div>
          </div>
        </div>
      );
    }

    let docori;
    if (this.state.deviceSize === "small") docori = "Orientation:";
    else docori = "ID Document Orientation:";

    let exitViewBtn;
    if (this.state.viewActiveFlag) {
      exitViewBtn = (
        <button
          className="btn_back_main"
          type="button"
          onClick={this.handleExitView}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      );
    }

    let fileLoadBtn;
    fileLoadBtn = (
      <div className="file_load_id_btn_positions">
        <span>
          <b>{docori}</b>
          &nbsp;
          <button className="btn-upload-orientation" type="button">
            {this.state.uploadFileType === "faceup" ? "Face-Up" : "Face-Down"}
          </button>
          &nbsp;&nbsp;
          {/* <button
            className={`${
              this.state.viewActiveFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={this.handleViewId}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            View
          </button> */}
          &nbsp;&nbsp;
          {exitViewBtn}
        </span>
        <div
          className={`${
            !this.state.uploadErrFlag
              ? "picture_msg text-center"
              : "picture_msg_err text-center"
          }`}
        >
          {this.state.uploadDirection}
        </div>
      </div>
    );

    // ================== End if File Upload Panel ======================
    let commButtonIdentity = (
      <div>
        <button
          onClick={this.handleBackFromIdentity}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleNextToIdentity}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let inpName;
    if (this.state.deviceSize === "small") inpName = "ID Doc Upload";
    else inpName = "Verification Document Upload";

    let headerPanel = (
      <div className="row">
        <div className="col-5 header_comp_text">{inpName}</div>
        <div className="col-7 ">{commButtonIdentity}</div>
      </div>
    );

    // Shows messages at the bottom
    let bottomMsgPanel = (
      <div>
        {/* <div className="col message_text_id">{this.state.showmsg}</div> */}
        <div
          className={`${
            !this.state.showmsgErrFlag
              ? "col message_text_id"
              : "col message_text_id_err"
          }`}
        >
          {this.state.showmsg}
        </div>
        <div className="text-center cc-id-veri-gen-msg">
          {this.state.directionMsg}
        </div>
      </div>
    );

    let doctype;
    if (this.state.deviceSize === "small") doctype = "ID Doc Orientation: ";
    else doctype = "Select ID Document Orientation: ";

    let identityPanel;
    if (this.state.uploadBtnClicked) {
      identityPanel = (
        <div className="id_panel">
          <div className="row">
            <div className="col text-center radio-fonts">
              <strong>{doctype}&nbsp;&nbsp;&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="faceup"
                    checked={this.state.uploadFileType === "faceup"}
                    onChange={this.handleIdTypeSelection}
                  />{" "}
                  faceup
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="facedown"
                    checked={this.state.uploadFileType === "facedown"}
                    onChange={this.handleIdTypeSelection}
                  />{" "}
                  facedown
                </label>
              </div>
            </div>
          </div>

          <div className="input-up-height" />
          <div className="row">
            <div className="col person_label text-center">{fileLoadBtn}</div>
          </div>
          <div className="row">
            <div className="col person_label text-center">{uploadpanel}</div>
          </div>
          {bottomMsgPanel}
        </div>
      );
    }

    let viewtype;
    if (this.state.deviceSize === "small") {
      if (this.state.documentOfType === "company")
        viewtype = "Viewing Company Doc";
      else viewtype = "Viewing Person Id";
    } else {
      if (this.state.documentOfType === "company")
        viewtype = "Viewing company verification documet";
      else viewtype = "Viewing Person Identity document";
    }

    if (this.state.viewActiveFlag) {
      identityPanel = (
        <div>
          <div className="input-up-height" />
          <div className="row">
            <div className="col person_label text-center">
              <b>{viewtype}</b>
            </div>
          </div>
          <div className="row">
            <div className="col person_label text-center">{fileLoadBtn}</div>
          </div>
          <div className="row">
            <div className="col person_label text-center">{viewPanel}</div>
          </div>
          {bottomMsgPanel}
        </div>
      );
    }

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="company_input_box new-update-identity-box">
          {identityPanel}
          <div className="text-center ind-idupdt-err">{this.state.errMsg}</div>
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

IndUpdateIdentity.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IndUpdateIdentity));