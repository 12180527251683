import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
import LoadFileToS3 from "../../../../../utils/components/fileUpload/LoadFileToS3";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import SendBmail from "../../interact/bmail/post/SendBmail";

import "./ShowMessages.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAMsgFromPool = "/routes/interact/getAMsgFromPool?";
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const saveSendMsgs = "/routes/interact/saveSendMsgs";
const getASynapseMail = "/routes/interact/getASynapseMail?";
const getUserByBaandaId = "/routes/users/getUserByBaandaId?";

const maxPicSize = 1000000; // 100KB
const picTypes = ["jpeg", "jpg", "bmp", "tiff", "png", "gif", "webp"];

const readMeCode = "1142000000";

class ShowMessages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      membersList: [],
      imagesUploaded: [],
      recipiantList: [],

      editorState: EditorState.createEmpty(),
      wipEditorState: EditorState.createEmpty(),
      msgOfFocus: null,
      subject: "",
      originSubject: "",

      errFlag: false,
      message: "",

      currSynapseNode: null,

      showRecipiantFlag: false,
      writeResponseFlag: false,
      readOnlyEditorFlag: true,
      showMainRespPanelFlag: true,
      showImageLoadPanelFlag: false,

      showPreviousBtnFlag: false,

      showSendBmailFlag: false,

      recipiants: "",
      picS3FileData: null,
      subDirName: "",

      prevSynapseId: 0,
      prevLinkedMsgPoolId: 0,
      poolMsgId: 0,
      noOfReNow: 0,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.searchMembersInCommunity();

    let subDirName = "bmail-cid-" + this.props.communityId;

    let postMsg = await this.getAMsg(this.props.showPost.linkedPoolMsgId);
    // console.log("11 postMsg:", postMsg);
    let retJson = JSON.parse(postMsg.messageDoc);
    let contentState = convertFromRaw(retJson);
    let newEditorState = EditorState.createWithContent(contentState);

    await this.countNumberOfReInSubject(postMsg.subject);

    this.setState({
      deviceSize,
      msgOfFocus: postMsg,
      currSynapseNode: this.props.showPost,
      editorState: newEditorState,
      subject: postMsg.subject,
      originSubject: postMsg.subject,
      subDirName,
      recipiantList: this.props.showPost.allRecipiants,
    });
  };

  countNumberOfReInSubject = async (subject) => {
    let bs = subject.split(" ");
    let cnt = 0;
    bs.forEach((nn) => {
      if (nn === "Re:") cnt++;
    });

    this.setState({
      noOfReNow: cnt,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getSenderData = async () => {
    let param = "baandaId=" + this.props.showPost.sentByBaandaId;
    let url = baandaServer + getUserByBaandaId + param;
    // console.log("getSenderData url:", url);
    try {
      let usr = await axios.get(url);
      // console.log('usr.data:', usr.data);
      if (usr.data.status.toLowerCase() === "success") {
        let recipiant = [];
        let recpObj = {
          baandaId: this.props.showPost.sentByBaandaId,
          ccFlag: false,
          email: usr.data.Msg[0].email,
          name: this.props.showPost.sentByName,
        };
        recipiant.push(recpObj);
        this.setState({
          recipiantList: recipiant,
        });
      } else {
        this.setState({
          errFlag: true,
          message: usr.data.Msg,
        });
      }
    } catch (err) {
      console.log("getSenderData err:", err.message);
      this.setState({
        errFlag: true,
        message: err.message,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  searchMembersInCommunity = async () => {
    let params = "communityId=" + this.props.communityId + "&memberName= ";
    let url = baandaServer + getMembersOfCommunity + params;
    // console.log("url:", url);
    try {
      let memret = await axios.get(url);
      // console.log("memret.data:", memret.data);
      if (memret.data.status === "success") {
        if (memret.data.Msg.length > 0) {
          this.setState({
            membersList: memret.data.Msg,
            message: "",
            errFlag: false,
          });
        } else {
          this.setState({
            message: "No members found in the community.",
            errFlag: true,
          });
        }
      } else {
        this.setState({
          message: memret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getAMsg = async (poolMsgId) => {
    let params = "poolMsgId=" + poolMsgId;
    let url = baandaServer + getAMsgFromPool + params;
    // console.log("getAMsg url:", url);
    let themsg = null;
    try {
      let msgret = await axios.get(url);
      // console.log("getAMsg msgret:", msgret);
      if (msgret.data.status === "success") {
        themsg = msgret.data.Msg;
        this.setState({
          message: "",
          errFlag: false,
        });
      } else {
        this.setState({
          message: msgret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getAMsg err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }

    return themsg;
  };

  getASynapseNode = async (synapseId) => {
    let params =
      "communityId=" + this.props.communityId + "&synapseId=" + synapseId;
    let url = baandaServer + getASynapseMail + params;
    let synapseNode = null;
    try {
      let synret = await axios.get(url);
      // console.log("synret:", synret);
      if (synret.data.status === "success") {
        synapseNode = synret.data.Msg;
      }
    } catch (err) {
      console.log("getASynapseMail err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }

    return synapseNode;
  };

  handleRecipiant = () => {
    let recipiants = "";
    this.state.currSynapseNode.allRecipiants.forEach((elm) => {
      // console.log("handleRecipiants elm:", elm);
      let cc = "";
      if (elm.ccFlag) cc = " [CC] ";
      recipiants += elm.name + "(" + elm.email + cc + "); ";
    });
    // console.log("recipiants:", recipiants);

    this.setState({
      showRecipiantFlag: true,
      recipiants,
    });
  };

  handleRecipiantExit = () => {
    this.setState({
      showRecipiantFlag: false,
    });
  };

  handleReturnToMailbox = () => {
    this.props.returnToCaller();
  };

  createReSubject = () => {
    // console.log('------------ this.state.noOfReNow:', this.state.noOfReNow);
    let subject = this.state.originSubject;
    for (var i = 0; i < this.state.noOfReNow; i++) {
      // console.log('i= ', i);
      subject = "Re: " + subject;
    }
    // console.log('282 subject:', subject);
    return subject;
  };

  handleResponseWrite = async () => {
    // console.log("handleResponseWrite .... ");
    await this.getSenderData();
    this.setState({
      showRecipiantFlag: false,
      writeResponseFlag: true,
      subject: this.createReSubject(),
      readOnlyEditorFlag: false,
      editorState: this.state.wipEditorState, // Editor state that was worked on ...
      showPreviousBtnFlag: true,
      prevSynapseId: this.state.currSynapseNode.synapseId,
      prevLinkedMsgPoolId: this.state.currSynapseNode.linkedPoolMsgId,
    });
  };

  handlePrevMailShow = async () => {
    // let wipEditorState = this.getContentAsRawJson();
    // console.log("show previous mail ...");
    let messageFromPool = await this.getAMsg(this.state.prevLinkedMsgPoolId);
    // console.log('messageFromPool:', messageFromPool);
    let newSynapseNode = await this.getASynapseNode(this.state.prevSynapseId);
    // console.log('newSynapseNode:', newSynapseNode);
    let retJson = JSON.parse(messageFromPool.messageDoc);
    let contentState = convertFromRaw(retJson);
    let newEditorState = EditorState.createWithContent(contentState);
    let showPreviousBtnFlag = false;
    let prevSynapseId = 0;
    if (newSynapseNode.prevSynapseId !== 0) {
      showPreviousBtnFlag = true;
      prevSynapseId = newSynapseNode.prevSynapseId;
    }
    this.setState({
      readOnlyEditorFlag: true,
      writeResponseFlag: false,
      msgOfFocus: messageFromPool,
      currSynapseNode: newSynapseNode,
      editorState: newEditorState,
      prevSynapseId,
      showPreviousBtnFlag,
      // wipEditorState,
    });
  };

  onEditorChange = (editorState) => {
    this.setState({
      editorState,
      message: "",
    });
  };

  // Returns editorState as JSON string.
  getContentAsRawJson = () => {
    const contentState = this.state.editorState.getCurrentContent(); // It returns content of the editor state
    const raw = convertToRaw(contentState);
    return JSON.stringify(raw, null, 2);
  };

  validateSave = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.subject === "") {
      isValid = "error";
      msg = "Subject is a must. ";
    }
    let jsonStr = this.getContentAsRawJson();
    let jsonObj = JSON.parse(jsonStr);
    if (jsonObj.blocks.length === 1 && jsonObj.blocks[0].text === "") {
      isValid = "error";
      msg += " Nothing to save or send. Blank letter.";
    }
    return { status: isValid, Msg: msg };
  };

  sendBmailsAndExit = async () => {
    let val = await this.validateSave();
    // console.log("sendBmailsAndExit val:", val);
    if (val.status === "success") {
      await this.handleTheSend();
    } else {
      this.setState({
        message: val.Msg,
        errFlag: true,
      });
    }
  };

  handleTheSend = async () => {
    let jsonStr = this.getContentAsRawJson();

    if (this.props.isMember) {
      this.setState({
        contentToSend: jsonStr,
        showSendBmailFlag: true,
        showMainRespPanelFlag: false,
      });
    } else {
      let recipiants = [];
      this.state.recipiantList.forEach((mem) => {
        // this.state.membersList.forEach((mem) => {
        if (mem.baandaId === this.props.showPost.sentByBaandaId) {
          let recObj = {
            baandaId: this.props.showPost.sentByBaandaId,
            name: mem.name,
            email: mem.email,
          };
          recipiants.push(recObj);
        }
      });
      let inpData = {
        reqType: "sent", // send or draft
        poolMsgId: 0, // There is no saved/draft situation and hence no existing poolMsgId
        authorBaandaId: this.props.auth.user.baandaId,
        sentByName: this.props.auth.user.name,
        communityId: this.props.communityId,
        subject: this.state.subject,
        messageDoc: jsonStr,
        clientProgram: "ShowMessages.js",
        clientFunction: "handleTheSend",
        prevSynapseId: this.props.showPost.synapseId,
        messageType: "converse",
        embeds: this.props.imagesUploaded,
        allRecipiants: recipiants,
      };
      let url = baandaServer + saveSendMsgs;
      // console.log("url:", url, " inpData:", inpData);
      try {
        let retsend = await axios.post(url, inpData);
        // console.log("handleTheSend retsend:", retsend);
        if (retsend.data.status === "success") {
          this.props.returnToCaller();
        } else {
          this.setState({
            message: retsend.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          message: err.message,
          errFlag: true,
        });
      }
    }
  };

  returnToBmailFromSend = (ret) => {
    // console.log("returnToBmailFromSend ret:", ret);
    if (ret.ops === "cancel") {
      this.setState({
        showSendBmailFlag: false,
        showMainRespPanelFlag: true,
        recipiantList: ret.recipiantList,
      });
    }

    if (ret.ops === "sent") {
      this.props.returnToCaller();
    }
  };

  handleImageLoad = async () => {
    await this.setState({
      showImageLoadPanelFlag: true,
      showMainRespPanelFlag: false,
    });
  };

  returnToBmailRespond = async (fileData) => {
    // console.log("Check S3 fileData: ", fileData);
    // console.log("Check S3 fileData.s3FileData: ", fileData.s3FileData);

    let iuploaded = [...this.state.imagesUploaded];
    let imagelink = "";
    let gotNewURLFlag = false;
    if (fileData.ops === "done") {
      imagelink = fileData.s3FileData.location;
      iuploaded.push(fileData.s3FileData);
      gotNewURLFlag = true;
    }
    if (fileData.ops === "cancel") imagelink = "Load Canceled";

    // console.log("iuploaded:", iuploaded);

    await this.setState({
      showMainRespPanelFlag: true,
      showImageLoadPanelFlag: false,
      imagelink,
      imagesUploaded: iuploaded,
      gotNewURLFlag,
    });
  };

  selectThisImageToResize = async (obj) => {
    // console.log("selectThisImageToResize obj:", obj);
    await this.setState({
      showPresentImagesFlag: false,
      picS3FileData: obj,
      imagelink: obj.location,
      gotNewURLFlag: true,
    });
  };

  handleImgListExit = () => {
    this.setState({
      showPresentImagesFlag: false,
      showDraftsList: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShowMessages...');

    let loadImageButton;
    loadImageButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleImageLoad}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Image"
        ) : (
          <i className="fas fa-image" />
        )}
      </button>
    );

    let BtnBmailReadRespInfo = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let BtnExitRecipiant = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleRecipiantExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let BtnExitShowMail = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleReturnToMailbox}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitFromImgListButton;
    exitFromImgListButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleImgListExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let BtnPrevious = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handlePrevMailShow}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Prev"
        ) : (
          <i className="fas fa-backward" />
        )}
      </button>
    );

    let buttonSend = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.sendBmailsAndExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Send"
        ) : (
          <i className="fa fa-paper-plane" />
        )}
      </button>
    );

    let BtnNext = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Next"
        ) : (
          <i className="fas fa-forward" />
        )}
      </button>
    );

    let BtnResponse = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleResponseWrite}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Reply"
        ) : (
          <i className="fas fa-reply" />
        )}
      </button>
    );

    let sentto;
    if (this.state.deviceSize === "big") {
      sentto = "SentTo";
    } else {
      sentto = "SnTo";
    }

    let BtnRecipiants = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleRecipiant}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          sentto
        ) : (
          <i className="fas fa-user-friends" />
        )}
      </button>
    );

    let resizeImageButton;

    let currImagesListPanel;
    if (this.state.showPresentImagesFlag) {
      let bool = true;
      currImagesListPanel = (
        <div className="list-darft-comp-bmails-box">
          <div className="text-center curr-image-list-head">
            Select to Resize &nbsp; {exitFromImgListButton}
          </div>
          <div className="row">
            <div className="col-3 draft-list-panel-head text-center">Ops</div>
            <div className="col-2 draft-list-panel-head text-center">Size </div>
            <div className="col-7 draft-list-panel-head text-left">Image</div>
          </div>
          <div className="fixedsize-comp-image-list">
            {this.state.imagesUploaded.map((obj, i) => {
              // console.log("345 obj:", obj);

              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-3 text-center">
                      <button
                        className="btn-sub-line-edit"
                        type="button"
                        onClick={() => this.selectThisImageToResize(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    {/* <div className="col-9 text-left item-category-label"> */}
                    <div className="col-2 text-left">{obj.size} kb</div>
                    <div className="col-7 text-center">
                      <img
                        src={obj.location}
                        alt="pic"
                        className="bmail-comp-image-for-list"
                      />
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let imgnote;
    if (this.state.deviceSize === "small") {
      imgnote = "Link";
    } else {
      imgnote = "Paste @ Img Link";
    }

    let loadImagPanel;
    loadImagPanel = (
      <div>
        <div className="row bmail-resp-image-row">
          <div className="col-1 text-right">{loadImageButton}</div>
          {this.state.gotNewURLFlag ? (
            <div className="col-2 text-right paste-img-note">{imgnote}</div>
          ) : (
            <div className="col-2 text-right paste-img-note">&nbsp;</div>
          )}
          {this.state.gotNewURLFlag ? (
            <div className="col-6 text-left show-link-note-x">
              {this.state.imagelink}
            </div>
          ) : (
            <div className="col-6">&nbsp;</div>
          )}

          <div className="col-2 text-left">
            {this.state.imagesUploaded && this.state.imagesUploaded.length > 0
              ? resizeImageButton
              : ""}
          </div>
        </div>
        {currImagesListPanel}
      </div>
    );

    let subject;
    if (this.state.deviceSize === "big") {
      subject = "Subject:";
    } else {
      subject = "Subject:";
    }

    let showEditorPanel;
    if (this.state.currSynapseNode && this.state.readOnlyEditorFlag) {
      console.log("In read editor... prev 1");
      showEditorPanel = (
        <div>
          <div className="row bmail-ops-resp-row-2">
            <div className="col-2 bmail-resp-lbl test-right">{subject}</div>
            <div className="col-10 bmail-resp-subject text-left">
              {this.state.originSubject}
            </div>
            {/* <div className="col-5 text-right">
              {this.state.showPreviousBtnFlag ? BtnPrevious : null}
              {this.state.currSynapseNode.nextSynapseId === 0 ? null : BtnNext}
              {BtnResponse}
              {BtnRecipiants}
            </div> */}
          </div>
          <div className="row">
            <div className="col  text-center">
              {this.state.showPreviousBtnFlag ? BtnPrevious : null}
              {this.state.currSynapseNode.nextSynapseId === 0 ? null : BtnNext}
              {BtnResponse}
              {BtnRecipiants}
            </div>
          </div>

          <Editor
            wrapperClassName="wrapper-class-resp"
            editorClassName="editor-class-x-resp"
            toolbarClassName="toolbar-class-resp"
            editorState={this.state.editorState}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                // uploadCallback: uploadImageCallBack,
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: "auto",
                  width: "200px",
                },
              },
              remove: { visible: false },
            }}
            readOnly={true}
          />
        </div>
      );
    }

    if (this.state.writeResponseFlag) {
      console.log(
        "In write editor this.state.editorState:",
        this.state.editorState
      );
      showEditorPanel = (
        <div>
          {loadImagPanel}
          <div className="row bmail-ops-resp-row">
            <div className="col-2 bmail-resp-lbl test-right">Subject:</div>
            <div className="col-10 bmail-resp-subject text-left">
              <input
                name="subject"
                type="text"
                value={this.state.subject}
                onChange={this.onChange}
                // size="14"
                maxLength="60"
                className="bmail-resp-text"
                placeholder=""
              />
            </div>
            {/* <div className="col-4 text-right">
              {this.state.showPreviousBtnFlag ? BtnPrevious : null}
              {this.state.currSynapseNode.nextSynapseId === 0 ? null : BtnNext}
              {buttonSend}
              {BtnResponse}
              {BtnRecipiants}
            </div> */}
          </div>
          <div className="row">
            <div className="col text-center">
              {this.state.showPreviousBtnFlag ? BtnPrevious : null}
              {this.state.currSynapseNode.nextSynapseId === 0 ? null : BtnNext}
              {buttonSend}
              {BtnResponse}
              {BtnRecipiants}
            </div>
          </div>
          <Editor
            wrapperClassName="wrapper-class-resp"
            editorClassName="editor-class-x-resp"
            toolbarClassName="toolbar-class-resp"
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorChange}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                // uploadCallback: uploadImageCallBack,
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: "auto",
                  width: "200px",
                },
              },
              remove: { visible: false },
            }}
          />
        </div>
      );
    }

    let showRecipiantPanel;
    if (this.state.showRecipiantFlag) {
      showRecipiantPanel = (
        <div className="show-bmail-recipiant-box ">
          <div className="text-center bmail-res-recipiant-lbl">
            Recipiants &nbsp;{BtnExitRecipiant}
          </div>
          <div className="fixedsize-bmail-recipiant-list">
            {this.state.recipiants}
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showMainRespPanelFlag) {
      outputPanel = (
        <div className="bmail-show-messages">
          <div className="text-center bmail-resp-header">
            BMail &nbsp;{BtnBmailReadRespInfo}
            {BtnExitShowMail}
          </div>
          {showRecipiantPanel}
          <div className="text-center">{showEditorPanel}</div>
          <div
            className={
              this.state.errFlag
                ? "text-center bmail-resp-message-err"
                : "text-center bmail-resp-message"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    if (this.state.showImageLoadPanelFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToBmailRespond}
            communityId={this.props.communityId}
            fileType={picTypes}
            maxSize={maxPicSize}
            s3subdir={this.state.subDirName}
            requestor="bMail Item Embed"
            s3FileData={this.state.picS3FileData}
            type="image"
          />
        </div>
      );
    }

    if (this.state.showSendBmailFlag) {
      outputPanel = (
        <div>
          <SendBmail
            returnToCaller={(ops) => this.returnToBmailFromSend(ops)}
            communityId={this.props.communityId}
            subject={this.state.subject}
            recipiantList={this.state.recipiantList}
            poolMsgId={this.state.poolMsgId}
            contentToSend={this.state.contentToSend}
            imagesUploaded={this.state.imagesUploaded}
            calledBy="reply"
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ShowMessages.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShowMessages));
