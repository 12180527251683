import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import noImageAvailable from "../../../image/notavailable.png";

import "./StockUp.css";

const imageTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const pdfTypes = ["pdf"];

const maxImageSize = 20000000; // 000KB
const maxPdfSize = 50000000; // .5 MB

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnItem = "/routes/dashboard/getAnItem?";
const stockUpSave = "/routes/dashboard/stockUpSave";

const readMeCode = "1111121200";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class StockUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      subitems: [],

      errMsg: "",
      errFlag: false,

      ShowOpeningErrorFlag: false,
      showStockUpPanel: true,
      showMoreFlag: false,

      imageS3FileData: null,
      pdfS3FileData: null,
      subDirName: "",

      stockupInputFlag: true,
      attachImageFlag: false,
      attachPdfFlag: false,

      moreSubitemName: "",
      moreInCatalog: false,
      moreLastCost: 0,
      moreImage: { key: "", type: "", location: "" },
      moreOptions: "",
      moreQuantity: 0,
      moreMovingAvgcost: 0,

      item: null,
      receiptType: "none",

      selectedItem: null,
      itemCost: 0.0,
      itemQuantity: 0,
      comment: "",

      confirmItemUpdate: false,

      stockupMsg: "",
      paymentType: "creditcard",
      receipt: null,

      isNewSubItem: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let subitems = [];
    let errMsg = "",
      errFlag = false,
      ShowOpeningErrorFlag = false,
      showStockUpPanel = true;

    await this.getTheItem();

    let subDirName = this.state.subDirName;
    if (this.state.item) subDirName = this.state.item.subBucket;

    if (subDirName === "") await this.subItemSubDirectory();

    if (
      this.state.item.itemVariance &&
      this.state.item.itemVariance.length > 0
    ) {
      subitems = this.state.item.itemVariance;
    } else {
      errMsg =
        "Please define subitems (just click it) in Inventory Adjustment, even if the item of the context does not have subitems. Please click (i) info for more details.";
      errFlag = true;
      ShowOpeningErrorFlag = true;
      showStockUpPanel = false;
    }
    this.setState({
      deviceSize,
      subitems,
      errMsg,
      errFlag,
      ShowOpeningErrorFlag,
      showStockUpPanel,
      subDirName,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  subItemSubDirectory = async () => {
    // console.log("in subItemSubDirectory ...");
    let randomct, commrn, subBucket;
    if (this.state.subDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "item-cid" +
        this.props.communityId +
        "-itemId" +
        this.props.item.itemId +
        "-image-" +
        commrn;
    } else subBucket = this.state.subDirName;

    // console.log("subSucket:", subBucket);
    await this.setState({
      subDirName: subBucket,
    });
  };

  getTheItem = async () => {
    let param = "itemId=" + this.props.item.itemId + "&requestBy=itemId";
    let url = baandaServer + getAnItem + param;
    // console.log("url:", url);
    try {
      let itm = await axios.get(url);
      // console.log("itm:", itm);
      if (itm.data.status === "success") {
        await this.setState({
          item: itm.data.Msg[0],
        });
      }
    } catch (err) {
      console.log("getTheItem err:", err.message);
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToItemsInventory = async () => {
    this.props.returnToCaller(this.state.item, this.state.isNewSubItem);
  };

  showMoreOfTheSubitem = async (obj) => {
    // console.log("showmore obj:", obj);
    this.setState({
      showMoreFlag: true,
      moreSubitemName: obj.name,
      moreInCatalog: obj.inCatalog,
      moreLastCost: obj.lastcost,
      moreImage: obj.image,
      moreOptions: obj.options,
      moreQuantity: obj.quantity,
      moreMovingAvgcost: obj.movingAvgCost,
    });
  };

  exitShowMore = async () => {
    this.setState({
      showMoreFlag: false,
      moreSubitemName: "",
      moreInCatalog: false,
      moreLastCost: 0,
      moreImage: { key: "", type: "", location: "" },
      moreOptions: "",
      moreQuantity: 0,
      moreMovingAvgcost: 0,
    });
  };

  handleReceiptType = async (e) => {
    this.setState({
      receiptType: e.target.value,
    });
  };

  handlePaymentType = async (e) => {
    this.setState({
      paymentType: e.target.value,
    });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
      });
    } else {
      this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  selectItemToStockup = async (obj) => {
    await this.setState({
      selectedItem: obj,
      // imageS3FileData: receiptImage
    });
  };

  handleConfirmUpdate = async () => {
    await this.setState((prevstate) => ({
      confirmItemUpdate: !prevstate.confirmItemUpdate,
    }));
  };

  returnToStockup = async (fileData) => {
    // console.log("returnToStockup this.state:", this.state);
    let receipt = [];

    // console.log("Check S3 fileData: ", fileData);
    if (!fileData.s3FileData) {
      // console.log("In here ... why? fileData.s3FileData:", fileData.s3FileData);
      await this.setState({
        attachImageFlag: false,
        attachPdfFlag: false,
      });
    }
    let imageS3FileData = this.state.imageS3FileData;
    let pdfS3FileData = this.state.pdfS3FileData;

    if (this.state.attachImageFlag) {
      // console.log("in here  2222");
      if (fileData.s3FileData.key !== "") {
        // console.log("fileData.s3FileData:", fileData.s3FileData);
        imageS3FileData = {
          size: fileData.s3FileData.size,
          key: fileData.s3FileData.key,
          type: "image",
          location: fileData.s3FileData.location,
        };
        receipt.push(imageS3FileData);
      }
    }

    await this.setState({
      attachImageFlag: false,
      attachPdfFlag: false,
      stockupInputFlag: false,
      imageS3FileData,
      pdfS3FileData,
      receipt,
    });
    // console.log('Return from file upload ...this.state:', this.state);
    await this.saveInDb();
  };

  handleSave = async () => {
    // console.log("in handleSave .....");
    let attachImageFlag = false;
    let attachPdfFlag = false;
    if (this.state.receiptType === "image") {
      attachImageFlag = true;
    } else if (this.state.receiptType === "pdf") {
      attachPdfFlag = true;
    } else {
      await this.saveInDb();
    }
    await this.setState({
      attachImageFlag,
      attachPdfFlag,
    });
  };

  processInputData = async () => {
    // Update sub-Items
    let ltemp = [...this.state.subitems];
    let subtemp = [];
    let itmcost = 0;
    let itmqty = 0;
    let deleted = false;
    let lineItem = [];
    ltemp.forEach((obj) => {
      if (obj.seqno !== this.state.selectedItem.seqno) {
        subtemp.push(obj);
      } else {
        if (obj.deleted) deleted = obj.deleted;
      }
      itmcost =
        itmcost + parseFloat(obj.movingAvgCost) * parseFloat(obj.quantity);
      itmqty = itmqty + parseFloat(obj.quantity);
    });

    // Moving average of all non-selected subitems
    let seli = this.state.selectedItem;
    let subitemmavg = 0;
    if (parseFloat(seli.quantity) + parseFloat(this.state.itemQuantity) > 0) {
      subitemmavg =
        (parseFloat(this.state.itemCost) +
          parseFloat(seli.movingAvgCost) * parseFloat(seli.quantity)) /
        (parseFloat(seli.quantity) + parseFloat(this.state.itemQuantity));
    }

    let stockedSubItem = {
      image: seli.image,
      inCatalog: seli.inCatalog,
      lastcost: parseFloat(this.state.itemCost),
      movingAvgCost: subitemmavg,
      name: seli.name,
      opsState: seli.opsState,
      options: seli.options,
      quantity: parseFloat(seli.quantity) + parseFloat(this.state.itemQuantity),
      seqno: seli.seqno,
      deleted,
    };
    // console.log("stockedSubItem:", stockedSubItem);

    subtemp.push(stockedSubItem);
    lineItem.push(stockedSubItem);
    //

    let totalqty = itmqty + parseFloat(this.state.itemQuantity);
    let totalcost = itmcost + parseFloat(this.state.itemCost);
    // console.log("totalqty:", totalqty);
    // console.log("totalcost:", totalcost);
    // Total moving average at item level
    let itemmavg = 0;
    if (totalqty > 0) {
      itemmavg = totalcost / totalqty;
    }
    // console.log("itemmavg:", itemmavg);

    let assetValuation = null; // Think ... it should be an object
    if (this.state.item.categoryOf === "asset") {
      assetValuation = {
        assetType: "capitalAsset",
        actionType: "item-received", // This is because, in direct stockup, it is assumed that item has been received.
        actionDate: new Date(),
        actionNote: this.state.comment,
        byValue: parseFloat(this.state.itemCost),
      };
    }
    if (this.state.item.categoryOf === "raw-item") {
      assetValuation = {
        assetType: "inventoryAsset",
        actionType: "item-received", // This is because, in direct stockup, it is assumed that item has been received.
        actionDate: new Date(),
        actionNote: this.state.comment,
        byValue: parseFloat(this.state.itemCost),
      };
    }

    let coopStoreId = 0;
    if (this.props.coopStoreId && this.props.coopStoreId > 0)
      coopStoreId = this.props.coopStoreId;

    let note =
      "(Stockup: " + this.state.item.itemName + ") " + this.state.comment;

    let input = {
      cost: parseFloat(totalcost),
      purchasePrice: parseFloat(this.state.itemCost),
      paymentType: this.state.paymentType,
      communityId: this.props.communityId,
      coopStoreId,
      itemId: this.state.item.itemId,
      unitName: this.state.item.unitName,
      baandaId: this.props.auth.user.baandaId,
      categoryOf: this.state.item.categoryOf,
      note,
      itemVariance: subtemp,
      receipt: this.state.receipt,
      movingAvgCostPerUnit: itemmavg,
      presentqty: parseFloat(totalqty),
      updtqty: this.state.itemQuantity,
      assetValuation,
      documentType: "inventory",
      calledBy: "textsave",
      clientProgram: "StockUp.js",
      clientFunction: "handleSave",
      lineItem,
    };

    return input;
  };

  saveInDb = async () => {
    // console.log("in save in db. 222");
    let url = baandaServer + stockUpSave;
    let input = await this.processInputData();
    // console.log("url:", url, " input:", input);

    try {
      let updtsub = await axios.post(url, input);
      // console.log("updtsub:", updtsub);
      if (updtsub.data.status === "success") {
        let isNewSubItem = false;
        if (!this.state.isNewSubItem && !this.state.editFlag)
          isNewSubItem = true;
        await this.setState({
          stockupMsg: "Successfully Updated",
          errFlag: false,
          itemCost: 0,
          itemQuantity: 0,
          comment: "",
          selectedItem: null,
          isNewSubItem,
          receipt: [],
          item: updtsub.data.Msg,
          confirmItemUpdate: false,
          subitems: updtsub.data.Msg.itemVariance,
          imageS3FileData: null,
          receiptType: "none",
        });
      } else {
        await this.setState({
          stockupMsg: updtsub.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("StockUp...");

    let noImgItemSelPanel;
    noImgItemSelPanel = (
      <div>
        <img src={noImageAvailable} alt="pic" className="stockup-image-view" />
      </div>
    );

    let imagePanel;
    if (this.state.moreImage && this.state.moreImage.location !== "") {
      imagePanel = (
        <div>
          <img
            src={this.state.moreImage.location}
            alt="pic"
            className="stockup-image-view"
          />
        </div>
      );
    }

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToItemsInventory}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitMoreButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitShowMore}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let confirmPanel;
    if (parseFloat(this.state.itemQuantity) > 0) {
      confirmPanel = (
        <div className="stockup-confirm">
          <Checkbox
            checked={this.state.confirmItemUpdate}
            onChange={this.handleConfirmUpdate}
          />
          {"  "}
          <mark>Confirm. This is final (cannot be changed).</mark>
        </div>
      );
    }

    let showMorePanel;
    if (this.state.showMoreFlag) {
      showMorePanel = (
        <div className="show-more-of-item">
          <div className="text-center show-more-header">
            {this.state.moreSubitemName}&nbsp;&nbsp;{exitMoreButton}
          </div>
          <div className="row">
            <div className="col-md-4 text-right">
              {this.state.moreImage.key === "" ? noImgItemSelPanel : imagePanel}
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-4 text-right stockup-more-lbl">Options</div>
                <div className="col-8 text-left stockup-more-data">
                  {this.state.moreOptions}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let attachTypePanel;
    attachTypePanel = (
      <div className="row stockup-row">
        <div className="col stock-radio-font-style">
          <strong>Receipt Type: &nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="image"
                checked={this.state.receiptType === "image"}
                onChange={this.handleReceiptType}
              />{" "}
              Image
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="pdf"
                checked={this.state.receiptType === "pdf"}
                onChange={this.handleReceiptType}
                disabled
              />{" "}
              Pdf
            </label>
          </div> */}
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="none"
                checked={this.state.receiptType === "none"}
                onChange={this.handleReceiptType}
              />{" "}
              None
            </label>
          </div>
        </div>
      </div>
    );

    let paymentTypePanel;
    paymentTypePanel = (
      <div className="row paytype-row">
        <div className="col stock-radio-font-style">
          <strong>Payed Via: &nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="cash"
                checked={this.state.paymentType === "cash"}
                onChange={this.handlePaymentType}
              />{" "}
              Cash
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="check"
                checked={this.state.paymentType === "check"}
                onChange={this.handlePaymentType}
              />{" "}
              Check
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="creditcard"
                checked={this.state.paymentType === "creditcard"}
                onChange={this.handlePaymentType}
              />{" "}
              CC
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="crypto"
                checked={this.state.paymentType === "crypto"}
                onChange={this.handlePaymentType}
              />{" "}
              Crypto
            </label>
          </div>
        </div>
      </div>
    );

    let entryPanel;
    entryPanel = (
      <div className="stock-up-entry-box">
        <div className="text-center stockup-header">
          Direct Stockup of Items &nbsp;&nbsp;
          {exitButton}
          {modalButton}
        </div>
        <div className="row sub-item-entry-row">
          <div className="col-3 text-right sub-item-entry-lbl">
            Item Selected
          </div>
          <div className="col-9 text-left selected-item-name">
            {this.state.selectedItem ? this.state.selectedItem.name : null}
          </div>
        </div>
        <div className="row sub-item-entry-row">
          <div className="col-3 text-right sub-item-entry-lbl">Qty Bought</div>
          <div className="col-3 text-left">
            <input
              name="itemQuantity"
              type="number"
              value={this.state.itemQuantity}
              onChange={this.onChangePrice}
              className="stockup-item-quantity"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-6 text-left">{paymentTypePanel}</div>
        </div>
        <div className="row sub-item-entry-row">
          <div className="col-3 text-right sub-item-entry-lbl">
            Pay Amount&nbsp;$
          </div>
          <div className="col-9 text-left">
            <input
              name="itemCost"
              type="number"
              value={this.state.itemCost}
              onChange={this.onChangePrice}
              // size="10"
              className="stockup-item-cost"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="row sub-item-entry-row">
          <div className="col-3 text-right sub-item-entry-lbl">Comment</div>
          <div className="col-9 text-left">
            <input
              name="comment"
              type="text"
              value={this.state.comment}
              onChange={this.onChange}
              size="200"
              maxLength="200"
              className="sub-stockup-comment"
              placeholder="Comment in 200 chars"
            />
          </div>
        </div>
        <div className="row stockup-save-row">
          <div className="col-md-6 text-right">{attachTypePanel}</div>
          <div className="col-md-5 text-center">
            {this.state.selectedItem ? confirmPanel : null}
          </div>
          <div className="col-md-1 text-left ">
            {this.state.selectedItem &&
            parseFloat(this.state.itemQuantity) > 0 &&
            this.state.confirmItemUpdate ? (
              <button
                className="btn_reg_60 inventory-stockup-save-btn-pos"
                type="button"
                onClick={this.handleSave}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );

    let bool = true;
    let subItemsListPanel;
    let subit = [...this.state.subitems];
    // console.log("subit:", subit);
    if (subit && subit.length > 0) {
      if (this.props.item.willHaveItemVariance) {
        subItemsListPanel = (
          <div className="stockup-list-pos-x">
            <div className="row sub-item-list-row">
              <div className="col-2 text-center sub-item-list-head">
                Operations
              </div>
              <div className="col-4 text-center sub-item-list-head">
                Sub-name
              </div>
              <div className="col-3 text-center sub-item-list-head">
                Stock-Qty
              </div>
              <div className="col-3 text-center sub-item-list-head">
                Mv.Avg. $
              </div>
            </div>
            <div className="fixedsize-sub-items-list">
              {subit.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-2 text-center">
                        {obj.deleted || (this.props.item.willHaveItemVariance && obj.seqno <= 1)? (
                          <button className="btn_reg_60_disabled" type="button">
                            Stock
                          </button>
                        ) : (
                          <button
                            className="btn_reg_60"
                            type="button"
                            onClick={() => this.selectItemToStockup(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            Stock
                          </button>
                        )}
                      </div>
                      <div className="col-4 text-left item-category-label">
                        {obj.name}&nbsp;
                        {(obj.image && obj.image.key === "") ||
                        !obj.image ? null : (
                          <i className="fa fa-paperclip" />
                        )}
                        {obj.deleted ? " Deleted" : null}
                      </div>
                      <div className="col-3 text-center item-category-label">
                        {obj.quantity}
                      </div>
                      <div className="col-3 text-center item-category-label">
                        {this.commaFormattedCurrency(obj.movingAvgCost)}
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      } else {
        subItemsListPanel = (
          <div className="stockup-list-pos-x">
            <div className="row sub-item-list-row">
              <div className="col-2 text-center sub-item-list-head">
                Operations
              </div>
              <div className="col-4 text-center sub-item-list-head">
                Sub-name
              </div>
              <div className="col-3 text-center sub-item-list-head">
                Stock-Qty
              </div>
              <div className="col-3 text-center sub-item-list-head">
                Mv.Avg. $
              </div>
            </div>
            <div className="fixedsize-sub-items-list">
              {subit.map((obj, i) => {
                return (
                  <div key={i}>
                    {obj.seqno === 0 ? (
                      <div
                        className={
                          bool
                            ? "row row-col-schdule-dr"
                            : "row row-col-schdule-lt"
                        }
                      >
                        <div className="col-2 text-center">
                          <button
                            className="btn_reg_60"
                            type="button"
                            onClick={() => this.selectItemToStockup(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            Stock
                          </button>
                        </div>
                        <div className="col-4 text-left item-category-label">
                          {obj.name}&nbsp;
                        </div>
                        <div className="col-3 text-center item-category-label">
                          {obj.quantity}
                        </div>
                        <div className="col-3 text-center item-category-label">
                          {this.commaFormattedCurrency(obj.movingAvgCost)}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.ShowOpeningErrorFlag) {
      outputPanel = (
        <div className="item-stockup-box text-center">
          <div className="on-open-err">{this.state.errMsg}</div>
          {modalButton}
          {exitButton}
        </div>
      );
    }

    if (this.state.showStockUpPanel) {
      outputPanel = (
        <div className="item-stockup-box text-center">
          {entryPanel}
          {showMorePanel}
          {subItemsListPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center stock-up-msg-err"
                : "text-center stock-up-msg"
            }
          >
            {this.state.stockupMsg}
          </div>
        </div>
      );
    }

    if (this.state.attachImageFlag) {
      outputPanel = (
        <div>
          <div className="text-center update-upload">Receipt Image Upload</div>
          <LoadFileToS3
            returnToCaller={this.returnToStockup}
            communityId={this.props.communityId}
            fileType={imageTypes}
            maxSize={maxImageSize}
            s3subdir={this.state.subDirName}
            requestor="Receipt Image"
            s3FileData={this.state.imageS3FileData}
            type="image"
          />
        </div>
      );
    }

    if (this.state.attachPdfFlag) {
      outputPanel = (
        <div>
          <div className="text-center update-upload">Receipt PDF Upload</div>
          <LoadFileToS3
            returnToCaller={this.returnToStockup}
            communityId={this.props.communityId}
            fileType={pdfTypes}
            maxSize={maxPdfSize}
            s3subdir={this.state.subDirName}
            requestor="Event's Attachment"
            s3FileData={this.state.pdfS3FileData}
            type="pdf"
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

StockUp.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StockUp));
