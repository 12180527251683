import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import { sortArrayOfObjects } from "../../../../../../utils/sortArrayOfObjects";

import "./Organizer.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCategories = "/routes/dashboard/getCatagories?";
const saveCategories = "/routes/dashboard/saveCategories";
const saveItemMaster = "/routes/dashboard/saveItemMaster";
const getSomeItems = "/routes/dashboard/getSomeItems?";
const getACatalogItem = "/routes/dashboard/getACatalogItem?";
const deleteDigiFileFromDB = "/routes/dashboard/deleteDigiFileFromDB";
const deleteAnItemFromMaster = "/routes/dashboard/deleteAnItemFromMaster";
const canEditSubItemInMaster = "/routes/inventory/canEditSubItemInMaster?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111123000";

class Organizer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invClassification: "catalog",
      itemType: "goods",
      functionType: "new",
      catOpsType: "new",

      itemId: 0,

      itemName: "",
      itemDescription: "",
      categoryName: "",
      prevCatagoryName: "",
      customQuotingFee: 0.0,
      unitName: "",

      categories: [],

      categoryNameMsg: "Category not specified (mandatory)",

      serviceType: "scheduled",
      scheduleType: "selected",

      deviceSize: "",
      loadingFlag: false,
      catSave: false,

      itemMessage: "",
      itemMessageFlag: false,

      showEditFlag: false,

      itemNameToSelect: "",

      editItems: [],
      editMsg: "",
      editErrFlag: false,
      fetchFlag: false,

      attachImageFlag: false,
      showItemMasterPanelFlag: true,

      itemSubDirName: "",
      imageS3FileData: null,

      selectedItemToEdit: null,

      canSellFrac: "no",
      canSellFraction: false,

      canDeleteItemFlag: false,

      willHaveItemVariance: false,
      canEditSubItem: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
      loadingFlag: true,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangePrice = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      itemMessage: "",
      canDeleteItemFlag: false,
    });
  };

  onChangeClassification = async (e) => {
    await this.setState({
      invClassification: e.target.value,
      canDeleteItemFlag: false,
      showCategoryFlag: false,
    });
  };

  returnToInvMgmt = () => {
    this.props.returnToCaller();
  };

  getCategories = async () => {
    // console.log("getCategories ...........");
    let params =
      "communityId=" +
      this.props.communityId +
      "&categoryOf=item&invClassification=" +
      this.state.invClassification +
      "&coopStoreId=" +
      this.props.coopStoreId +
      "&itemType=" +
      this.state.itemType;
    let url = baandaServer + getCategories + params;
    // console.log("********** getCategoris url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("catret:", catret);
      if (catret.data.status === "success") {
        if (catret.data.Msg.length > 0) {
          let sorted = await sortArrayOfObjects(
            catret.data.Msg,
            "label",
            "dsc"
          );
          // console.log("sorted:", sorted);
          this.setState({
            categories: sorted,
            invOrgErrMsg: "",
            invOrgErrFlag: false,
          });
        } else {
          this.setState({
            invOrgErrMsg: `Add ${this.state.invClassification} category first to select.`,
            invOrgErrFlag: true,
            updateMsg: "",
            categories: [],
          });
        }
      } else {
        this.setState({
          invOrgErrMsg: catret.data.Msg,
          invOrgErrFlag: true,
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
      this.setState({
        invOrgErrMsg: err.message,
        invOrgErrFlag: true,
      });
    }
  };

  handleItemType = async (e) => {
    this.setState({
      itemType: e.target.value,
      functionType: "new",
      showEditFlag: false,
      canDeleteItemFlag: false,
      showCategoryFlag: false,
    });
    await this.clearItemMasterForm();
  };

  handleFunctionType = async (e) => {
    let value = e.target.value;
    let showEditFlag = false;
    if (value === "edit") showEditFlag = true;
    this.setState({
      functionType: e.target.value,
      showEditFlag,
      canDeleteItemFlag: false,
      showCategoryFlag: false,
    });

    await this.clearItemMasterForm();
  };

  clearItemMasterForm = async () => {
    this.setState({
      // functionType: 'new',
      editItems: [],
      fetchFlag: false,
      itemId: 0,
      itemName: "",
      itemDescription: "",
      categoryName: "",
      prevCatagoryName: "",
      customQuotingFee: 0.0,
      unitName: "",
      categories: [],
      categoryNameMsg: "Category not specified (mandatory)",
      serviceType: "scheduled",
      scheduleType: "selected",
      // itemMessage: "",
      // itemMessageFlag: false,
      itemNameToSelect: "",
      attachImageFlag: false,
      itemSubDirName: "",
      imageS3FileData: null,
      selectedItemToEdit: null,
      willHaveItemVariance: false,
      canEditSubItem: true,
    });
  };

  exitEditPanel = async () => {
    await this.setState({
      functionType: "new",
      showEditFlag: false,
      fetchFlag: false,
    });
  };

  handleServiceType = async (e) => {
    await this.setState({
      serviceType: e.target.value,
      showCategoryFlag: false,
    });
  };

  handleScheduleType = async (e) => {
    await this.setState({
      scheduleType: e.target.value,
      showCategoryFlag: false,
    });
  };

  handleCategory = async () => {
    let catname = this.state.categoryName;

    this.setState({
      showCategoryFlag: true,
      prevCatagoryName: catname,
      catOpsType: "new",
      catSave: true,
      canDeleteItemFlag: false,
    });
  };

  exitCategory = async () => {
    let catName = this.state.categoryName;

    if (!this.state.catSave) catName = this.state.prevCatagoryName;

    this.setState({
      showCategoryFlag: false,
      categoryName: catName,
    });
  };

  handleCategorySave = async () => {
    // console.log("in handleCategorySave...");
    if (this.state.categoryName === "") {
      this.setState({
        itemMessage: "Must enter a category name to save a New",
        itemMessageFlag: true,
      });
    } else {
      let url = baandaServer + saveCategories;
      let input = {
        communityId: this.props.communityId,
        coopStoreId: this.props.coopStoreId,
        categoryOf: "item",
        invClassification: this.state.invClassification,
        itemType: this.state.itemType,
        category: this.state.categoryName,
        baandaId: this.props.auth.user.baandaId,
      };
      // console.log("url:", url, " input:", input);
      try {
        // console.log('reached here...');
        let catret = await axios.post(url, input);
        // console.log("catret: ", catret);
        if (catret.data.status === "success") {
          this.setState({
            selectOptMsg: "",
            catSave: true,
          });
          await this.exitCategory();
        } else {
          this.setState({
            selectOptMsg: catret.data.Msg,
            categoryName: "",
          });
        }
      } catch (err) {
        this.setState({
          selectOptMsg: err.message,
          categoryName: "",
        });
      }
    }
  };

  handleCatOpsType = async (e) => {
    let value = e.target.value;
    // console.log("handleCatOpsType value:", value);
    if (value === "select") await this.getCategories();
    this.setState({
      catOpsType: value,
    });
  };

  selectThisCategory = async (obj) => {
    // console.log("obj:", obj);
    this.setState({
      categoryName: obj.label,
      showCategoryFlag: false,
      itemMessage: "",
      itemMessageFlag: false,
    });
  };

  checkIfInCatalog = async (itemId) => {
    // console.log("checkIfInCatalog itemId:", itemId);
    let param = "itemId=" + itemId;
    let url = baandaServer + getACatalogItem + param;
    try {
      let catret = await axios.get(url);
      if (catret.data.status === "success") {
        if (!catret.data.Msg) {
          this.setState({
            canDeleteItemFlag: true,
          });
        }
      }
    } catch (err) {
      this.setState({
        itemMessage: err.message,
        itemMessageFlag: true,
      });
    }
  };

  canEditForSubItem = async (communityId, itemId) => {
    try {
      let params = "communityId=" + communityId + "&itemId=" + itemId;
      let url = baandaServer + canEditSubItemInMaster + params;
      let canret = await axios.get(url);
      if (canret.data.status === "success") {
        await this.setState({
          canEditSubItem: canret.data.Msg,
          itemMessage: "",
          itemMessageFlag: false,
        });
      } else {
        await this.setState({
          itemMessage: canret.data.Msg,
          itemMessageFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        itemMessage: err.message,
        itemMessageFlag: true,
      });
    }
  };

  selectThisItemToedit = async (obj) => {
    // console.log("selectThisItemToedit obj:", obj);
    let imageS3FileData = null;
    if (obj.fileUploads && obj.fileUploads.length > 0)
      imageS3FileData = obj.fileUploads[0];
    let canSellFrac = "no";
    if (obj.canSellFraction) canSellFrac = "yes";
    await this.checkIfInCatalog(obj.itemId);
    await this.canEditForSubItem(obj.communityId, obj.itemId);
    let willHaveItemVariance = false;
    if (obj.willHaveItemVariance)
      willHaveItemVariance = obj.willHaveItemVariance;
    this.setState({
      showEditFlag: false,
      itemName: obj.itemName,
      itemId: obj.itemId,
      itemDescription: obj.itemDescription,
      categoryName: obj.category,
      unitName: obj.unitName,
      itemSubDirName: obj.subBucket,
      serviceType: obj.service.type,
      scheduleType: obj.service.scheduleType,
      customQuotingFee: obj.service.customQuotingFee,
      imageS3FileData,
      selectedItemToEdit: obj,
      canSellFraction: obj.canSellFraction,
      canSellFrac,
      willHaveItemVariance,
    });
    // Load items to edit
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  setUploadType = async (imgType) => {
    // console.log("imgType:", imgType);
    await this.setState({
      attachImageFlag: false,
    });

    let randomct, commrn, subBucket;
    if (this.state.itemSubDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "itemmaster-cid" +
        this.props.communityId +
        "-itemId" +
        this.state.itemId +
        "-da-" +
        commrn;
    } else subBucket = this.state.itemSubDirName;

    switch (imgType) {
      case "image":
        await this.setState({
          attachImageFlag: true,
        });
        break;

      default:
        console.log("default");
    }

    await this.setState({
      itemSubDirName: subBucket,
      showItemMasterPanelFlag: false,
    });
  };

  returnToUpload = async (fileData) => {
    let handledAssetType = "";
    if (this.state.attachImageFlag) handledAssetType = "image";

    if (!fileData.s3FileData) {
      // console.log("In here ... why? fileData.s3FileData:", fileData.s3FileData);
      await this.setState({
        attachImageFlag: false,
      });
    }
    let imageS3FileData = this.state.imageS3FileData;

    // let imageExists = this.state.imageExists;
    if (this.state.attachImageFlag) {
      // console.log("in here  2222");
      if (fileData.ifDeletedFlag) {
        imageS3FileData = null;
        await this.deleteDigiFileFromDb(fileData, "image");
      }
      if (fileData.s3FileData.key !== "") {
        // console.log("fileData.s3FileData:", fileData.s3FileData);
        imageS3FileData = {
          key: fileData.s3FileData.key,
          type: "image",
          location: fileData.s3FileData.location,
          caption: this.state.itemName,
        };
        await this.uploadFileInDB(imageS3FileData, "image");
      }
    }

    if (fileData.ifDeletedFlag) {
      await this.deleteDigiFileFromDb(fileData, handledAssetType);
      if (handledAssetType === "image") imageS3FileData = null;
    }

    await this.setState({
      attachImageFlag: false,
      openButtonPanel: true, // ????? is this needed?
      imageS3FileData,
      showItemMasterPanelFlag: true,
    });
  };

  uploadFileInDB = async (digitalFile, type) => {
    // console.log("inside uploadFileInDB digitalFile:", digitalFile);
    // if ( this.state.itemSubDirName === '') await this.setUploadType();
    let input = {
      communityId: this.props.communityId,
      digitalFile,
      itemId: this.state.itemId,
      s3subBucket: this.state.itemSubDirName,
      type: type,
      opsModeType: "edit",
      saveType: "attach",
    };
    // console.log(">>> input:", input);

    let url = baandaServer + saveItemMaster;
    try {
      let resp = await axios.post(url, input);
      // console.log("Upload resp:", resp);
      if (resp.data.status === "success") {
        // console.log("In here ... 999");
        await this.setState({
          itemId: resp.data.Msg,
          itemMessage: "",
          itemMessageFlag: false,
        });
      }
    } catch (err) {
      console.log("uploadFileInDB:", err.message);
      this.setState({
        itemMessage: err.message,
        itemMessageFlag: true,
      });
    }
  };

  deleteDigiFileFromDb = async (fileToDelete, type) => {
    // console.log("FileToDelete:", fileToDelete, " type:", type);
    // let eventSubDirName = this.state.eventSubDirName;
    let url = baandaServer + deleteDigiFileFromDB;
    let inpData;
    let imageS3FileData = this.state.imageS3FileData;

    if (type === "image") {
      inpData = {
        communityId: this.props.communityId,
        itemId: this.state.itemId,
        type: "image",
        caller: "item",
      };
    }

    // console.log("file deleted. url:", url);
    // console.log("file deleted inpData:", inpData);
    try {
      let retdel = await axios.post(url, inpData);
      // console.log("zz retdel:", retdel);
      if (retdel.data.status === "success") {
        if (type === "image") {
          imageS3FileData = null;
        }

        await this.setState({
          imageS3FileData,
        });
      }
    } catch (err) {
      console.log("Err: ", err.message);
      this.setState({
        itemMessage: err.message,
        itemMessageFlag: true,
      });
    }
  };

  validateInput = async () => {
    let isValid = true;
    let msg = "";
    if (this.state.itemName === "") {
      isValid = false;
      msg = msg + "Item must have a name. | ";
    }
    if (this.state.categoryName === "") {
      isValid = false;
      msg = msg + "Item must have a category. | ";
    }
    if (this.state.itemDescription === "") {
      isValid = false;
      msg = msg + "Item must have a description. | ";
    }

    return { status: isValid, Msg: msg };
  };

  packInputForDB = async () => {
    let service = {
      type: this.state.serviceType,
      scheduleType: this.state.scheduleType,
      customQuotingFee: this.state.customQuotingFee,
    };
    let unitName = this.state.unitName;
    if (this.state.itemType === "education") unitName = "NA";

    let itemVariance = [];
    if (this.state.itemType === "goods") {
      itemVariance = [
        {
          seqNo: 1,
          name: this.state.itemName,
          options: "",
          quantity: 0,
          lastCost: 0,
          movingAvgCost: 0,
          image: null,
          opsState: true,
          inCatalog: false,
        },
      ];
    }

    let input = {
      opsModeType: this.state.functionType,
      itemId: this.state.itemId,
      communityId: this.props.communityId,
      coopStoreId: this.props.coopStoreId,
      coopStoreName: this.props.coopStoreName,
      categoryOf: this.state.invClassification,
      itemType: this.state.itemType,
      service,
      catOpsType: this.state.catOpsType,
      category: this.state.categoryName.trim(),
      itemName: this.state.itemName,
      itemDescription: this.state.itemDescription,
      unitName,
      subBucket: this.state.itemSubDirName,
      baandaId: this.props.auth.user.baandaId,
      itemVariance,
      clientProgram: "dashboard/store/inventory/mgmt/Organizer.js",
      clientFunction: "handleItemMasterSave",
      saveType: "updatedb",
      canSellFraction: this.state.canSellFraction,
      willHaveItemVariance: this.state.willHaveItemVariance,
    };

    return input;
  };

  handleItemMasterSave = async () => {
    let isValid = await this.validateInput();
    if (isValid.status) {
      let url = baandaServer + saveItemMaster;
      let input = await this.packInputForDB();

      try {
        let retitem = await axios.post(url, input);
        // console.log("retitem:", retitem);
        if (retitem.data.status === "success") {
          await this.setState({
            itemMessage: "Successfully Updated",
            itemMessageFlag: false,
            itemId: retitem.data.Msg,
            functionType: "new",
          });
          await this.clearItemMasterForm();
        } else {
          await this.setState({
            itemMessage: retitem.data.Msg,
            itemMessageFlag: true,
          });
        }
      } catch (err) {
        // console.log("err:", err.message);
        await this.setState({
          itemMessage: err.Msg,
          itemMessageFlag: true,
        });
      }
    } else {
      await this.setState({
        itemMessage: "Error: " + isValid.Msg,
        itemMessageFlag: true,
      });
    }
  };

  fetchItemsToEdit = async () => {
    // await this.setState({ fetchFlag: true });
    let coopStoreId = 0;
    if (this.props.coopStoreId) coopStoreId = this.props.coopStoreId;
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        coopStoreId +
        "&invClassification=" +
        this.state.invClassification +
        "&itemType=" +
        this.state.itemType +
        "&itemName=" +
        this.state.itemNameToSelect;
      let url = baandaServer + getSomeItems + params;
      // console.log("url:", url);
      let retitems = await axios.get(url);
      // console.log("retitems:", retitems);
      if (retitems.data.status === "success") {
        // console.log("success");
        if (retitems.data.Msg.length > 0) {
          this.setState({
            editItems: retitems.data.Msg,
            editMsg: "",
            editErrFlag: false,
            fetchFlag: false,
          });
        } else {
          this.setState({
            editItems: retitems.data.Msg,
            editMsg: "No items found. ",
            editErrFlag: true,
            fetchFlag: true,
          });
        }
      } else {
        // console.log("error1");
        this.setState({
          editItems: [],
          editMsg: retitems.data.Msg,
          editErrFlag: true,
          fetchFlag: true,
        });
      }
    } catch (err) {
      // console.log("error1");
      this.setState({
        editItems: [],
        editMsg: err.message,
        editErrFlag: true,
        fetchFlag: true,
      });
    }
  };

  handleCanSellFracType = async (e) => {
    let canSellFraction = false;
    let canSellFrac = e.target.value;
    if (canSellFrac === "yes") canSellFraction = true;
    await this.setState({
      // canSellFrac: e.target.value,
      canSellFrac,
      canSellFraction,
      canDeleteItemFlag: false,
    });
  };

  handleDeleteItem = async () => {
    // console.log("will delete item :", this.state.itemId);
    let inp = {
      itemId: this.state.itemId,
    };
    let url = baandaServer + deleteAnItemFromMaster;
    try {
      let delret = await axios.post(url, inp);
      if (delret.data.status === "success") {
        this.setState({
          invClassification: "catalog",
          itemType: "goods",
          functionType: "new",
          catOpsType: "new",
          itemMessage: "Successfully deleted",
          itemMessageFlag: false,
          showEditFlag: false,
          itemName: "",
          itemId: 0,
          itemDescription: "",
          categoryName: "",
          unitName: "",
          itemSubDirName: "",
          serviceType: "scheduled",
          scheduleType: "selected",
          customQuotingFee: 0,
          imageS3FileData: null,
          selectedItemToEdit: null,
          canSellFraction: false,
          canSellFrac: "no",
        });
      } else {
        this.setState({
          itemMessage: delret.data.Msg,
          itemMessageFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        itemMessage: err.message,
        itemMessageFlag: true,
      });
    }
  };

  handleHasVriances = async () => {
    await this.setState((prevstate) => ({
      willHaveItemVariance: !prevstate.willHaveItemVariance,
    }));
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Organizer...");

    let canSellFractionPanel;
    canSellFractionPanel = (
      <div className="row can-sell-frac-row">
        <div className="col text-center radio-font-style">
          <strong>Can sell in fraction&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="no"
                checked={this.state.canSellFrac === "no"}
                onChange={this.handleCanSellFracType}
              />{" "}
              No
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="yes"
                checked={this.state.canSellFrac === "yes"}
                onChange={this.handleCanSellFracType}
              />{" "}
              Yes
            </label>
          </div>
        </div>
      </div>
    );

    let iname, idesc, catbtn, catname, minquote, unit;
    if (this.state.deviceSize === "small") {
      iname = "iName";
      idesc = "iDesc";
      catbtn = "Cat";
      catname = "Name";
      minquote = "MinQte";

      unit = "Unit";
    } else {
      iname = "Item Name";
      idesc = "Description";
      catbtn = "Category";
      catname = "Name";
      minquote = "Quotation Fee";
      unit = "Unit Name";
    }
    let bool = true;

    let toEditItemsListPanel;
    if (this.state.showEditFlag) {
      // if (this.state.functionType === "edit" && this.state.showEditFlag) {
      if (this.state.editItems.length > 0) {
        toEditItemsListPanel = (
          <div className="fixedsize-items-toedit-list">
            {this.state.editItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left item-category-label">
                      {obj.itemName}&nbsp;|&nbsp;Cat:{obj.category}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisItemToedit(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        );
      } else {
        if (this.state.fetchFlag) {
          // console.log("I was here fetchFlag: ", this.state.fetchFlag);
          toEditItemsListPanel = (
            <div className="text-center no-items-to-edit">
              No items found for the Classification ={" "}
              {this.state.invClassification}
              ,&nbsp;Item Type = {this.state.itemType}, and Item Name filter
              =&nbsp;{this.state.itemNameToSelect}.
            </div>
          );
        }
      }
    }

    let selectToEditPanel;
    if (this.state.showEditFlag) {
      selectToEditPanel = (
        <div className="show-toedit-select-box">
          <div className="text-center item-select-panel-header">
            Select Item to Edit &nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.exitEditPanel}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row toedit-name-row">
            <div className="col-3 text-right toedit-name-lbl">{iname}</div>
            <div className="col-9 text-left">
              <input
                name="itemNameToSelect"
                type="text"
                value={this.state.itemNameToSelect}
                onChange={this.onChange}
                size="75"
                maxLength="75"
                className="item-edit-name"
                placeholder="Part/full item name ..."
              />{" "}
              &nbsp;{" "}
              <button
                className="btn_reg_50"
                type="button"
                onClick={this.fetchItemsToEdit}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Fetch
              </button>
            </div>
          </div>
          {toEditItemsListPanel}
        </div>
      );
    }

    let invtypePanel;
    invtypePanel = (
      <div>
        <div className="row">
          <div className="col text-center radio-fonts">
            <strong>Classification: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="asset"
                  checked={this.state.invClassification === "asset"}
                  onChange={this.onChangeClassification}
                />{" "}
                Assets
              </label>
            </div>

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="catalog"
                  checked={this.state.invClassification === "catalog"}
                  onChange={this.onChangeClassification}
                />{" "}
                Catalog
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="raw-item"
                  checked={this.state.invClassification === "raw-item"}
                  onChange={this.onChangeClassification}
                />{" "}
                Raw-Item (to manufacture)
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let itemTypePanel;
    if (this.state.invClassification === "catalog") {
      itemTypePanel = (
        <div>
          <div className="row">
            <div className="col text-center radio-catlog-fonts">
              <strong>Item Type: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="goods"
                    checked={this.state.itemType === "goods"}
                    onChange={this.handleItemType}
                  />{" "}
                  Goods (material)
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="service"
                    checked={this.state.itemType === "service"}
                    onChange={this.handleItemType}
                  />{" "}
                  Services
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let functionTypePanel;
    // if (this.state.invClassification === "catalog") {
    functionTypePanel = (
      <div className="text-center function-type-row">
        <div className="row">
          <div className="col text-center radio-catlog-fonts">
            <strong>Operation Type: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="new"
                  checked={this.state.functionType === "new"}
                  onChange={this.handleFunctionType}
                />{" "}
                New
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="edit"
                  checked={this.state.functionType === "edit"}
                  onChange={this.handleFunctionType}
                />{" "}
                Edit
              </label>
            </div>
          </div>
        </div>
        <div className="text-center category-def-msg">
          {this.state.selectOptMsg}
        </div>
      </div>
    );
    // }

    let categoryBtnPanel;
    categoryBtnPanel = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleCategory}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {catbtn}
      </button>
    );

    let catButtonPanel;
    catButtonPanel = (
      <div className="cat-button-position text-center">
        {this.state.catOpsType === "new" ? (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleCategorySave}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            New
          </button>
        ) : null}
      </div>
    );

    let catNameEntryPanel;
    catNameEntryPanel = (
      <div className="row inv-org-row-spacing">
        <div className="col-3 text-right inv-org-lbl">{catname}</div>
        <div className="col-9 text-left">
          <input
            name="categoryName"
            type="text"
            value={this.state.categoryName}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="org-category-name"
            placeholder="Enter new category name"
          />
        </div>
      </div>
    );

    // For selecting existing category ... do it after save.
    let catSelectPanel;
    if (this.state.catOpsType === "select") {
      if (this.state.categories.length > 0) {
        catSelectPanel = (
          <div className="fixedsize-caetgory-select">
            {this.state.categories.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left item-category-label">
                      {obj.label}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisCategory(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        );
      } else {
        catSelectPanel = (
          <div className="text-center no-categories-defined">
            Please create a new category for this asset.
          </div>
        );
      }
    }

    let categoryPanel;
    if (this.state.showCategoryFlag) {
      categoryPanel = (
        <div className="item-category-box">
          <div className="item-cat-header">
            Specify Item's Category&nbsp;&nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.exitCategory}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="text-center function-type-row">
            <div className="row">
              <div className="col text-center radio-catlog-fonts">
                <strong>Ops Type: &nbsp;&nbsp;</strong>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="budget-ops"
                      type="radio"
                      value="new"
                      checked={this.state.catOpsType === "new"}
                      onChange={this.handleCatOpsType}
                    />{" "}
                    New
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="budget-ops"
                      type="radio"
                      value="select"
                      checked={this.state.catOpsType === "select"}
                      onChange={this.handleCatOpsType}
                    />{" "}
                    Select
                  </label>
                </div>
              </div>
            </div>
            {/* <div>{this.state.selectOptMsg}</div> */}
          </div>
          {this.state.catOpsType === "new" ? catNameEntryPanel : catSelectPanel}
          {catButtonPanel}
          <div className="text-center category-def-msg">
            {this.state.selectOptMsg}
          </div>
        </div>
      );
    }

    let scheduleTypePanel;
    if (this.state.serviceType === "scheduled") {
      scheduleTypePanel = (
        <div className="text-center schedule-type-row">
          <div className="row">
            <div className="col text-center radio-catlog-fonts">
              <strong>Schedule Type: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="selected"
                    checked={this.state.scheduleType === "selected"}
                    onChange={this.handleScheduleType}
                  />{" "}
                  Selected Slots
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="all"
                    checked={this.state.scheduleType === "all"}
                    onChange={this.handleScheduleType}
                  />{" "}
                  All instances
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let minQuoteFeePanel;
    if (this.state.serviceType === "custom") {
      minQuoteFeePanel = (
        <div>
          <div className="row quation-fee-row">
            <div className="col-3 text-right custom-quote-lbl">{minquote}</div>
            <div className="col-9 text-left">
              <input
                name="customQuotingFee"
                type="number"
                min="0"
                className="min-quote-fee"
                onChange={this.onChangePrice}
                value={this.state.customQuotingFee}
                placeholder="Minimum quote fee (if any)"
                step="1"
              />{" "}
              &nbsp;&nbsp;* Click( i ) for details.
            </div>
          </div>
        </div>
      );
    }

    let servicesPanel;
    if (this.state.itemType === "service") {
      servicesPanel = (
        <div className="text-center service-type-row">
          <div className="row">
            <div className="col text-center radio-catlog-fonts">
              <strong>Service Type: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="scheduled"
                    checked={this.state.serviceType === "scheduled"}
                    onChange={this.handleServiceType}
                  />{" "}
                  Scheduled
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="custom"
                    checked={this.state.serviceType === "custom"}
                    onChange={this.handleServiceType}
                  />{" "}
                  Custom
                </label>
              </div>
              {this.props.coopStoreId === 0 ? (
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="budget-ops"
                      type="radio"
                      value="membership"
                      checked={this.state.serviceType === "membership"}
                      onChange={this.handleServiceType}
                    />{" "}
                    Membership
                  </label>
                </div>
              ) : null}
            </div>
          </div>
          {scheduleTypePanel}
          {minQuoteFeePanel}
        </div>
      );
    }

    let digitalServicePanel;
    if (this.state.itemType === "education") {
      digitalServicePanel = (
        <div className="digital-service-msg">
          <b>Education:</b> Save the name of the program and a short description
          as a guidance towards the detail setup. Refer to (i)-Info for details.
        </div>
      );
    }

    let hasSubItemPanel;
    if (this.state.itemType === "goods") {
      if (this.state.canEditSubItem) {
        hasSubItemPanel = (
          <div className="row">
            <div className="col text-center will-have-sub-items">
              <Checkbox
                checked={this.state.willHaveItemVariance}
                onChange={this.handleHasVriances}
              />
              &nbsp;Will have sub items
            </div>
          </div>
        );
      } else {
        hasSubItemPanel = (
          <div className="row">
            <div className="col text-center will-have-sub-items">
              {this.state.willHaveItemVariance
                ? "Can have sub items"
                : "Cannot have sub items."}
            </div>
          </div>
        );
      }
    }

    let itemMasterInputPanel;
    itemMasterInputPanel = (
      <div className="text-cnter">
        <div className="row inv-org-row-spacing">
          <div className="col-3 text-right inv-org-lbl">{iname}</div>
          <div className="col-9 text-left">
            <input
              name="itemName"
              type="text"
              value={this.state.itemName}
              onChange={this.onChange}
              size="75"
              maxLength="75"
              className="org-category-name"
              placeholder="Enter an item name ..."
            />
          </div>
        </div>
        {selectToEditPanel}
        <div className="text-row-directive">
          Max chars: 1000 (Char Count : {this.state.itemDescription.length})
        </div>
        <div className="row inv-org-row-spacing">
          <div className="col-3 text-right inv-org-lbl">{idesc}</div>
          <div className="col-9 text-left">
            <textarea
              name="itemDescription"
              maxLength="1000"
              placeholder="Describe your item"
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="inv-org-describe"
              onChange={this.onChange}
              value={this.state.itemDescription}
              required
            />
          </div>
        </div>
        <div className="row inv-org-row-spacing">
          <div className="col-3 text-right inv-org-lbl">{unit}</div>
          {this.state.itemType === "education" ? (
            "None - N/A"
          ) : (
            <div className="col-9 text-left">
              <input
                name="unitName"
                type="text"
                value={this.state.unitName}
                onChange={this.onChange}
                size="75"
                maxLength="75"
                className="inv-unit-name"
                placeholder="Unit Name"
              />
              &nbsp; * Check ( i ) for the correct usage.
            </div>
          )}
        </div>
        {this.state.invClassification === "catalog" ? (
          <div className="row">
            <div className="col-6 text-center">{canSellFractionPanel}</div>
            <div className="col-6 text-center">{hasSubItemPanel}</div>
          </div>
        ) : null}
        {categoryPanel}
        <div className="row inv-org-row-spacing">
          <div className="col-3 text-right inv-org-lbl">
            {!this.state.showEditFlag ? categoryBtnPanel : null}
          </div>
          <div className="col-9 text-left cat-name-msg">
            {this.state.categoryName === ""
              ? this.state.categoryNameMsg
              : this.state.categoryName}
          </div>
        </div>
        {servicesPanel}
        {digitalServicePanel}
      </div>
    );

    let theButtonPanel;
    theButtonPanel = (
      <div className="text-center itemmaster-button-row">
        {!this.state.showEditFlag ? (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleItemMasterSave}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        ) : null}

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToInvMgmt}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        {this.state.canDeleteItemFlag ? (
          <button
            className="btn_exit_del"
            type="button"
            onClick={this.handleDeleteItem}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fa fa-trash" />
          </button>
        ) : null}
      </div>
    );

    let outputPanel;

    if (this.state.showItemMasterPanelFlag) {
      outputPanel = (
        <div className="inv-organizer-box">
          <div className="text-center item-master-header">
            Manage Item Master
          </div>
          {this.props.coopStoreName !== "" ? (
            <div className="text-center im-mgmt-coop-store-name">
              Store: {this.props.coopStoreName}
            </div>
          ) : null}
          {invtypePanel}
          {itemTypePanel}
          {functionTypePanel}
          <hr className="item-mastr-hr" />
          {itemMasterInputPanel}
          {theButtonPanel}
          <div className="row message-placement">
            <div
              className={
                this.state.itemMessageFlag
                  ? "col text-center message-show-err"
                  : "col text-center message-show"
              }
            >
              {this.state.itemMessage}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Organizer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Organizer));
