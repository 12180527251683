import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import DayPicker, { DateUtils } from "react-day-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import "./ScheduleDays.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const readMeCode = "1111211400";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class ScheduleDays extends Component {
  constructor(props) {
    super(props);

    this.state = {
      daySpecificationProcess: "periodic",
      beginEndSelect: "begindate",
      selectedDays: [],

      begindate: new Date(),
      enddate: new Date(),

      weekSunday: false,
      weekMonday: false,
      weekTuesday: false,
      weekWednesday: false,
      weekThursday: false,
      weekFriday: false,
      weekSaturday: false,
      recurEverySoWeek: 1,

      selheight: 1,
      selheightwk: 1,
      somanymonths: 1,

      numberOfDays: 1,
      recurweeks: 1,

      weekOfTheMonth: "",
      theDayOfWeek: "",

      dayof: 20,
      daymonthof: 1,

      dailyType: "dailyeveryday",
      monthlyType: "monthlyday",

      lastSelectedPattern: "",
      periodPattern: "",

      showSaveBtn: false,

      errMsg: "",
      errFlag: false,

      theEvent: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let begindate = new Date();

    let enddate = new Date();
    enddate.setDate(begindate.getDate() + 1);

    if (this.props.theEvent.services.beginDateString !== "") {
      let ebd = this.props.theEvent.services.beginDateString.replace(/-/g, "/");
      let parseebd = Date.parse(ebd);
      begindate = new Date(parseebd);
    }
    if (this.props.theEvent.services.endStringString) {
      let ed = this.props.theEvent.services.endStringString.replace(/-/g, "/");
      let parseed = Date.parse(ed);
      enddate = new Date(parseed);
    }

    let selectedDays = [];

    if (
      this.props.theEvent.services.selectedDaysString &&
      this.props.theEvent.services.selectedDaysString.length > 0
    ) {
      this.props.theEvent.services.selectedDaysString.forEach((obj) => {
        selectedDays.push(new Date(obj));
      });
    }

    this.setState({
      theEvent: this.props.theEvent,
      deviceSize,
      enddate,
      selectedDays,
      begindate,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangeMinutes = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRepeat = async (e) => {
    e.preventDefault();
    let value = parseFloat(e.target.value);
    if (this.countDecimals(value) === 0) {
      if (value >= 1 && value < 20) {
        await this.setState({ [e.target.name]: e.target.value });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleDaySchedulingType = async (e) => {
    await this.setState({
      daySpecificationProcess: e.target.value,
    });
  };

  formatDateForFirefox = (dt) => {
    let jsdt = new Date(dt);

    var year = jsdt.toLocaleString("default", { year: "numeric" });
    var month = jsdt.toLocaleString("default", { month: "2-digit" });
    var day = jsdt.toLocaleString("default", { day: "2-digit" });
    var formattedDate = month + "/" + day + "/" + year;

    return formattedDate;
  };

  handleBeginDate = async (date) => {
    let fdstr = this.formatDateForFirefox(date);

    let enddate = new Date(fdstr);

    let ed = enddate.setDate(enddate.getDate() + 1);

    this.setState({
      begindate: date,
      enddate: new Date(ed),
    });
  };

  handleEndDate = async (date) => {
    this.setState({
      enddate: date,
      showDailyPopup: false,
    });
  };

  handleDateSelect = async (e) => {
    this.setState({
      beginEndSelect: e.target.value,
    });
  };

  handleDaysow = async () => {
    let value = document.getElementById("seldayofweekxx").value;

    this.setState({
      selheightwk: 1,
      theDayOfWeek: value,
    });
  };

  handlePeriodPattern = async (e) => {
    await this.setState({
      periodPattern: e.target.value,
      showDailyPopup: true,
    });
  };

  clearPeriodic = async () => {
    await this.setState({
      periodPattern: "",
      selectedDays: [],
    });
  };

  processDailyEveryNDays = async () => {
    let calcDates = [];
    let mbd = new Date(this.state.begindate);
    let eed = new Date(this.state.enddate);
    let yy = new Date(this.state.begindate);
    calcDates.push(yy);
    while (mbd < eed) {
      let xx = mbd.setDate(mbd.getDate() + 1);
      calcDates.push(new Date(xx));
    }

    await this.setState({
      selectedDays: calcDates,
      showSaveBtn: true,
      showDailyPopup: false,
      lastSelectedPattern: this.state.periodPattern,
      periodPattern: "",
    });
  };

  processWeekly = async () => {
    let mbd = new Date(this.state.begindate);
    let eed = new Date(this.state.enddate);
    let dow = moment(mbd).weekday();

    // Form the start week from the begin date
    let preWeek = [];
    for (var i = 0; i <= 6; i++) {
      let el = { ...this.state.begindate };
      let eld = moment(el);
      let y = i - dow;
      eld.add(y, "days");

      preWeek.push(eld.toDate());
    }

    // cal the array from the chosen weekday
    let selWeekNo = [];
    if (this.state.weekSunday) selWeekNo.push(0);
    if (this.state.weekMonday) selWeekNo.push(1);
    if (this.state.weekTuesday) selWeekNo.push(2);
    if (this.state.weekWednesday) selWeekNo.push(3);
    if (this.state.weekThursday) selWeekNo.push(4);
    if (this.state.weekFriday) selWeekNo.push(5);
    if (this.state.weekSaturday) selWeekNo.push(6);

    // Filter in only those weekdays of preWeek that's been selected
    let tpweek = [];
    // let k = 1;
    preWeek.forEach((obj) => {
      let z1 = moment(obj).weekday();

      if (selWeekNo.includes(z1)) {
        tpweek.push(moment(obj));
      }
      // k++;
    });
    // console.log("tpweek:", tpweek);

    //Final stream of moment dates with increment of n weeks
    //from the dates in tpWeek.
    let fma = [];
    let rw = parseFloat(this.state.recurweeks);

    let untilTrue = false;
    // ldp or latest date that is to be compared with end date
    // in the begingin, ldp is the begin date of selected week day array.
    let ldp = moment({ ...tpweek[0] });
    // now is the loop number of now. It shows how many of while loop is now in.
    let now = 0;

    while (!untilTrue) {
      for (var j = 0; j < tpweek.length; j++) {
        // twd is the clone of the target week day in the selected weekdays.
        let twd = moment({ ...tpweek[j] }); // get the date clone from tpweek

        // If the ldp (latest date to compare) is less than end date (true)
        if (moment(eed).isAfter(ldp)) {
          let inc = now * rw * 7; // ('now' th loop) * (every rw week) * 7 (days of week)

          // add inc days to date of the selected week-day
          twd.add(inc, "days");
          fma.push(twd);
          if (j + 1 < tpweek.length) {
            // if the next day will still be in the selected day of the week.
            let x2 = moment({ ...tpweek[j + 1] });
            ldp = moment(x2).add(inc, "days");
          } else {
            // If the next date has to be re-set to the first day of the selected week day.
            let x2 = moment({ ...tpweek[0] });
            ldp = moment(x2).add(inc + 7, "days");
          }
        } else {
          j = tpweek.length + 1;
          untilTrue = true;
          break;
        }
      }
      now++;
    }

    let jsdatearr = [];
    fma.forEach((obj) => {
      if (obj.isAfter(moment(this.state.begindate)))
        jsdatearr.push(obj.toDate());
    });

    this.setState({
      lastSelectedPattern: this.state.periodPattern,
      periodPattern: "",
      selectedDays: jsdatearr,
      showSaveBtn: true,
      showDailyPopup: false,
    });
  };

  processMonthyDay = async () => {
    let mOfbd = moment(this.state.begindate).format("M");
    let yOfbd = moment(this.state.begindate).format("YYYY");

    let mon = "";
    if (mOfbd < 10) mon = "0" + mOfbd.toString();
    else mon = mOfbd.toString();
    let day = "";
    if (parseFloat(this.state.dayof) < 10)
      day = "0" + this.state.dayof.toString();
    day = this.state.dayof.toString();

    let selDateStr = yOfbd.toString() + "-" + mon + "-" + day;

    let selDate = moment(selDateStr, "YYYY-MM-DD");

    let esomon = parseFloat(this.state.daymonthof);
    let bdclone = new Date(selDate.toDate());

    let moarr = [];
    let untilTrue = false;
    let now = 1;
    while (!untilTrue) {
      let nextm = moment(bdclone).add(esomon * now, "months");
      if (nextm.isAfter(moment(this.state.begindate))) {
        now++;
        moarr.push(nextm);

        if (nextm.isAfter(moment(this.state.enddate))) {
          untilTrue = true;
          break;
        }
      }
    }

    let jsdatearr = [];
    moarr.forEach((obj) => {
      if (obj.isBefore(moment(this.state.enddate)))
        jsdatearr.push(obj.toDate());
    });

    await this.setState({
      lastSelectedPattern: this.state.periodPattern,
      periodPattern: "",
      selectedDays: jsdatearr,
      showSaveBtn: true,
      showDailyPopup: false,
    });
  };

  proessPeriodicDaily = async () => {
    if (this.state.periodPattern === "daily") {
      if (this.state.dailyType === "dailyeveryday")
        await this.processDailyEveryNDays();
      else await this.processDailyEveryWeekDays();
    }
    if (this.state.periodPattern === "weekly") {
      await this.processWeekly();
    }
    if (this.state.periodPattern === "monthly") {
      if (this.state.monthlyType === "monthlyday")
        await this.processMonthyDay();
      else await this.processMonthlyThe();
    }
  };

  calNthWeekday = async (fswd) => {
    let chosenday;
    if (this.state.weekOfTheMonth === "first") {
      chosenday = moment({ ...fswd });
    }
    if (this.state.weekOfTheMonth === "second") {
      chosenday = moment({ ...fswd }).add(7, "days");
    }
    if (this.state.weekOfTheMonth === "third") {
      chosenday = moment({ ...fswd }).add(14, "days");
    }
    if (this.state.weekOfTheMonth === "fourth") {
      chosenday = moment({ ...fswd }).add(21, "days");
    }
    if (this.state.weekOfTheMonth === "last") {
      let ldm = fswd.endOf("month").format("YYYY-MM-DD");

      chosenday = moment(ldm);
      // lwd = last week day to select
      let lwd;
      if (this.state.theDayOfWeek === "sunday") lwd = 0;
      if (this.state.theDayOfWeek === "monday") lwd = 1;
      if (this.state.theDayOfWeek === "tuesday") lwd = 2;
      if (this.state.theDayOfWeek === "wednesday") lwd = 3;
      if (this.state.theDayOfWeek === "thursday") lwd = 4;
      if (this.state.theDayOfWeek === "friday") lwd = 5;
      if (this.state.theDayOfWeek === "saturday") lwd = 6;

      let loop = false;
      let i = 0;
      while (!loop) {
        let cdow = chosenday.weekday();

        if (cdow !== lwd) {
          chosenday.add(-1, "days");
        }
        i++;
        if (i > 7) break;
      }
    }

    return chosenday;
  };

  processMonthlyThe = async () => {
    // Get the first of the month for the first selection of nth day-of-week
    // Get the month of the begindate
    let mOfbd = moment(this.state.begindate).format("M");
    let mon = "";
    if (mOfbd < 10) mon = "0" + mOfbd.toString();
    else mon = mOfbd.toString();
    // Year of the begin date
    let year = moment(this.state.begindate).format("YYYY").toString();
    // Construct the first of the month of the begindate
    let dtstr = year + "-" + mon + "-01";
    // fom = first of the month
    let fom = moment(dtstr).toDate();

    let loop = true;
    // sda = selected dates array
    let sda = [];
    let firstloop = true;
    while (loop) {
      // Get the date of first specific weekday = fswd
      let fomx = moment(fom);
      let fswd = await this.calcFirstOccuranceOfSelectedWeekday(fomx);

      // Calc the nth (first, second, ... , last), as selected, of fsdo
      // sdm = selected day of month
      let sdm = await this.calNthWeekday(fswd);

      if (moment(this.state.begindate).isAfter(moment(sdm))) {
        // This date should be skipped because it is before the begin date
        // At this time, get to the first of the next month as the starting moneth
        fom = moment(fom).add(1, "months");
      } else {
        sda.push(sdm); // include this in the array
        if (firstloop) {
          // Add 1 only if you are trying to get the first day to start calculation
          fom = moment(fom).add(1, "months");
        } else {
          // somanymonths
          fom = moment(fom).add(parseFloat(this.state.somanymonths), "months");
        }
        if (moment(fom).isAfter(moment(this.state.enddate))) {
          loop = false;
          break;
        }
      }
      firstloop = false;
    }

    let jsdatearr = [];
    sda.forEach((obj) => {
      jsdatearr.push(obj.toDate());
    });

    await this.setState({
      lastSelectedPattern: this.state.periodPattern,
      periodPattern: "",
      selectedDays: jsdatearr,
      showSaveBtn: true,
    });
  };

  handleDayOFWeek = async (e) => {
    let name = e.target.name;

    if (name === "weekSunday") {
      await this.setState((prevstate) => ({
        weekSunday: !prevstate.weekSunday,
      }));
    }
    if (name === "weekMonday") {
      await this.setState((prevstate) => ({
        weekMonday: !prevstate.weekMonday,
      }));
    }
    if (name === "weekTuesday") {
      await this.setState((prevstate) => ({
        weekTuesday: !prevstate.weekTuesday,
      }));
    }
    if (name === "weekWednesday") {
      await this.setState((prevstate) => ({
        weekWednesday: !prevstate.weekWednesday,
      }));
    }
    if (name === "weekThursday") {
      await this.setState((prevstate) => ({
        weekThursday: !prevstate.weekThursday,
      }));
    }
    if (name === "weekFriday") {
      await this.setState((prevstate) => ({
        weekFriday: !prevstate.weekFriday,
      }));
    }
    if (name === "weekSaturday") {
      await this.setState((prevstate) => ({
        weekSaturday: !prevstate.weekSaturday,
      }));
    }
  };

  calcFirstOccuranceOfSelectedWeekday = async (fom) => {
    let loop = true;
    let fsdow = null;

    while (loop) {
      let wd = fom.weekday();

      if (this.state.theDayOfWeek === "sunday" && wd === 0) {
        fsdow = fom;
      }
      if (this.state.theDayOfWeek === "monday" && wd === 1) {
        fsdow = fom;
      }
      if (this.state.theDayOfWeek === "tuesday" && wd === 2) {
        fsdow = fom;
      }
      if (this.state.theDayOfWeek === "wednesday" && wd === 3) {
        fsdow = fom;
      }
      if (this.state.theDayOfWeek === "thursday" && wd === 4) {
        fsdow = fom;
      }
      if (this.state.theDayOfWeek === "friday" && wd === 5) {
        fsdow = fom;
      }
      if (this.state.theDayOfWeek === "saturday" && wd === 6) {
        fsdow = fom;
      }

      if (fsdow) {
        loop = false;
        break;
      }

      fom.add(1, "days");
    }

    return fsdow;
  };

  processDailyEveryWeekDays = async () => {
    let calcDates = [];

    let mbd = new Date(this.state.begindate);
    let eed = new Date(this.state.enddate);

    while (mbd <= eed) {
      let dow = daysOfWeek[mbd.getDay()];
      if (dow.toLowerCase() !== "sunday" && dow.toLowerCase() !== "saturday") {
        let aa = new Date(mbd);
        calcDates.push(aa);
      }
      mbd.setDate(mbd.getDate() + 1);
    }
    // console.log("calcDates:", calcDates);

    await this.setState({
      selectedDays: calcDates,
      showSaveBtn: true,
      showDailyPopup: false,
      lastSelectedPattern: this.state.periodPattern,
      periodPattern: "",
    });
  };

  handleDailyType = async (e) => {
    await this.setState({
      dailyType: e.target.value,
    });
  };

  onChangeHours = async (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = parseFloat(e.target.value);

    if (this.state.monthlyType === "monthlyday" && name === "dayof") {
      if (this.countDecimals(value) === 0) {
        if (value <= 28 && value > 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
    }

    if (this.state.monthlyType === "monthlyday" && name === "daymonthof") {
      if (this.countDecimals(value) === 0) {
        if (value <= 12 && value > 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
    if (
      this.state.periodPattern === "daily" &&
      this.state.dailyType === "dailyeveryday" &&
      name === "numberOfDays"
    ) {
      if (this.countDecimals(value) === 0) {
        if (value <= 20 && value > 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
    if (this.state.periodPattern === "weekly" && name === "recurweeks") {
      if (this.countDecimals(value) === 0) {
        if (value <= 26 && value > 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
    if (
      this.state.periodPattern === "monthly" &&
      this.state.monthlyType === "monthlythe" &&
      name === "somanymonths"
    ) {
      if (this.countDecimals(value) === 0) {
        if (value <= 6 && value > 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
  };

  handleMonthlyType = async (e) => {
    await this.setState({
      monthlyType: e.target.value,
    });
  };

  handleSelNoOfWeeks = async () => {
    let value = document.getElementById("selnoweekthe").value;

    await this.setState({
      selheight: 1,
      weekOfTheMonth: value,
    });
  };

  handleDayClick = async (day, { selected }) => {

    const { selectedDays } = this.state;

    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    await this.setState({
      selectedDays,
      showSaveBtn: true,
    });
  };

  formatDate = (dt) => {
    let jsdt = new Date(dt);

    var year = jsdt.toLocaleString("default", { year: "numeric" });
    var month = jsdt.toLocaleString("default", { month: "2-digit" });
    var day = jsdt.toLocaleString("default", { day: "2-digit" });
    var formattedDate = month + "-" + day + "-" + year;

    return formattedDate;
  };

  packDataForDB = async () => {

    let dbs = "";
    let eds = "";
    let schbegindate = null; // scheduled begin date in date form
    let schenddate = null; // scheduled end date
    let selectedDaysString = [];
    let sortedDtStr = [];
    let sortedSchSelectedDays = [];
    if (this.state.daySpecificationProcess === "custom") {
      this.state.selectedDays.forEach((sdt) => {
        selectedDaysString.push(this.formatDate(sdt));
      });

      // let copySelectedDAysString = [...selectedDaysString];
    //   console.log("selectedDaysString:", selectedDaysString);
      sortedDtStr = selectedDaysString.sort();
    //   console.log('sortedDtStr:', sortedDtStr); 
      let trsDtStr = [];
      if (sortedDtStr.length > 0) {
        sortedDtStr.forEach((obj) => {
          let v1 = obj.replace(/-/g, "/");
          trsDtStr.push(v1);
        });
        dbs = sortedDtStr[0];
        eds = sortedDtStr[sortedDtStr.length - 1];
        schbegindate = new Date(dbs.replace(/-/g, "/"));

        schenddate = new Date(eds.replace(/-/g, "/"));
      }

      this.setState({
        begindate: new Date(schbegindate),
        enddate: new Date(schenddate),
      });
    }
    if (this.state.daySpecificationProcess === "periodic") {
      this.state.selectedDays.forEach((sdt) => {
        selectedDaysString.push(this.formatDate(sdt));
      });

      let copySelectedDAysString = [...selectedDaysString];
      sortedDtStr = copySelectedDAysString.sort();

      let trsDtStr = [];
      sortedDtStr.forEach((obj) => {
        let v1 = obj.replace(/-/g, "/");
        trsDtStr.push(v1);
      });
      dbs = sortedDtStr[0];
      eds = sortedDtStr[sortedDtStr.length - 1];

      let y1 = this.formatDate(this.state.begindate);

      let z1 = y1.replace(/-/g, "/");

      schbegindate = new Date(z1);
      let y2 = this.formatDate(this.state.enddate);

      let z2 = y2.replace(/-/g, "/");

      schenddate = new Date(z2);
    }

    sortedDtStr.forEach((srt) => {
      let a1 = srt.replace(/-/g, "/");
      sortedSchSelectedDays.push(new Date(a1));
    });

    let input = {
      eventId: this.props.theEvent.eventId,
      scheduledBeginDate: schbegindate,
      beginDateString: dbs,
      scheduledEndDate: schenddate,
      endDateString: eds,
      scheduledSelectedDays: sortedSchSelectedDays,
      selectedDaysString: sortedDtStr,
      module: "servicedayslots",
      clientProgram: "ScheduleDays.js",
      clientFunction: "saveUpdateEvent",
    };
    return input;
  };

  saveUpdateEvent = async () => {
    let url = baandaServer + saveUpdtEvent;

    let inp = await this.packDataForDB();
    // console.log("inp:", inp);
    try {
      let resp = await axios.post(url, inp);
      if (resp.data.status === "success") {
        this.setState({
          errMsg: "Day scheduling has been saved.",
          errFlag: false,
          eventId: resp.data.Msg.eventId,
          theEvent: resp.data.Msg.theEvent,
        });
        this.returnToCreateShift();
      } else {
        this.setState({
          errMsg: resp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  returnToCreateShift = async () => {
    this.props.returnToCaller(this.state.theEvent);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ScheduleDays...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div>
        {this.state.showSaveBtn ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.saveUpdateEvent}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        ) : null}

        {infoModalButton}
      </div>
    );

    let scheduleProcessPanel;
    scheduleProcessPanel = (
      <div>
        <div className="row">
          <div className="col">
            <strong>Scheduling Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-idateFormatnput"
                  type="radio"
                  value="periodic"
                  checked={this.state.daySpecificationProcess === "periodic"}
                  onChange={this.handleDaySchedulingType}
                />{" "}
                Repetative
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="custom"
                  checked={this.state.daySpecificationProcess === "custom"}
                  onChange={this.handleDaySchedulingType}
                />{" "}
                Custom
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let theDatePanel;
    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.beginEndSelect === "begindate"
    ) {
      theDatePanel = (
        <div>
          <div className="text-center periodic-date-begin-header">
            Begin Date
          </div>
          <DatePicker
            selected={this.state.begindate}
            onSelect={this.handleBeginDate}
            onChange={this.handleBeginDate}
            minDate={new Date()}
            name="begindate"
            dateFormat="MM/dd/yyyy"
          />
        </div>
      );
    }
    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.beginEndSelect === "enddate"
    ) {
      theDatePanel = (
        <div>
          <div className="text-center periodic-date-end-header">End Date</div>
          <DatePicker
            selected={this.state.enddate}
            onChange={this.handleEndDate}
            minDate={this.state.begindate}
            name="enddate"
            dateFormat="MM/dd/yyyy"
          />
        </div>
      );
    }

    let dayPicPanel;
    if (this.state.daySpecificationProcess === "custom") {
      dayPicPanel = (
        <div className="shift-daypic-custom-box">
          <DayPicker
            selectedDays={this.state.selectedDays}
            onDayClick={this.handleDayClick}
            disabledDays={{ before: new Date() }}
          />
        </div>
      );
    }

    let beginEndPanel;
    if (this.state.daySpecificationProcess === "periodic") {
      beginEndPanel = (
        <div>
          <div className="row row-select-beginorend">
            <div className="col">
              <strong>Select&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="begindate"
                    checked={this.state.beginEndSelect === "begindate"}
                    onChange={this.handleDateSelect}
                  />{" "}
                  Begin Date
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="enddate"
                    checked={this.state.beginEndSelect === "enddate"}
                    onChange={this.handleDateSelect}
                  />{" "}
                  End Date
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let detailCalcButtonPanel;
    if (this.state.daySpecificationProcess === "periodic") {
      detailCalcButtonPanel = (
        <div>
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.proessPeriodicDaily}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Apply
          </button>
          <button
            className="btn_back_main"
            type="button"
            onClick={this.clearPeriodic}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-step-backward" />
          </button>
        </div>
      );
    }

    let periodTypePanel;
    if (this.state.daySpecificationProcess === "periodic") {
      periodTypePanel = (
        <div>
          <div className="row row-select-beginorend">
            <div className="col">
              <strong>Pattern&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="daily"
                    checked={this.state.periodPattern === "daily"}
                    onChange={this.handlePeriodPattern}
                  />{" "}
                  Daily
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="weekly"
                    checked={this.state.periodPattern === "weekly"}
                    onChange={this.handlePeriodPattern}
                  />{" "}
                  Weekly
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="monthly"
                    checked={this.state.periodPattern === "monthly"}
                    onChange={this.handlePeriodPattern}
                  />{" "}
                  Monthly
                </label>
              </div>
            </div>
          </div>
          {this.state.lastSelectedPattern === "" ? null : (
            <div className="text-center day-pattern-selected">
              Pattern Applied:&nbsp;
              <font color="blue">
                {this.state.lastSelectedPattern.toLocaleUpperCase()}
              </font>
            </div>
          )}
        </div>
      );
    }

    let monthlyTypePanel;
    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.monthlyType === "monthlyday"
    ) {
      monthlyTypePanel = (
        <div className="row-monthlyday">
          Day&nbsp;
          <input
            name="dayof"
            type="number"
            value={this.state.dayof}
            onChange={this.onChangeHours}
            className="monthlyday-dayof"
            step="1"
          />
          &nbsp;of every&nbsp;
          <input
            name="daymonthof"
            type="number"
            value={this.state.daymonthof}
            onChange={this.onChangeHours}
            className="monthlyday-daymonthof"
            step="1"
          />
          &nbsp;month(s).
        </div>
      );
    }

    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.monthlyType === "monthlythe"
    ) {
      monthlyTypePanel = (
        <div>
          <div className="text-center recur-for-weeks">
            Recur every&nbsp;
            <select
              size={this.state.selheight}
              onFocus={this.onfocus}
              onBlur={this.onblur}
              id="selnoweekthe"
              name="selnoweekthe"
              onChange={this.handleSelNoOfWeeks}
              onSelect={this.handleSelNoOfWeeks}
              className="recur-sel-no"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select
              </option>
              <option value="first">First</option>
              <option value="second">Second</option>
              <option value="third">Third</option>
              <option value="fourth">Fourth</option>
              <option value="last">Last</option>
            </select>
            &nbsp;
          </div>
          <div className="text-center recur-dayofweek">
            <select
              size={this.state.selheightwk}
              onFocus={this.onfocuswk}
              onBlur={this.onblurwk}
              id="seldayofweekxx"
              name="seldayofweekxx"
              onChange={this.handleDaysow}
              onSelect={this.handleDaysow}
              className="recur-day-of-week"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                On-the
              </option>
              <option value="sunday">Sunday</option>
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
            </select>
          </div>
          <div className="text-center recur-somanymonths">
            &nbsp; of every&nbsp;
            <input
              name="somanymonths"
              type="number"
              value={this.state.somanymonths}
              onChange={this.onChangeHours}
              className="monthly-dayofweek"
              step="1"
            />
            &nbsp;month(s).
          </div>
        </div>
      );
    }

    let patternPanel;
    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.periodPattern === "monthly"
    ) {
      patternPanel = (
        <div className="define-pattern-detail-box">
          <div className="text-center monthly-pattern-head">
            Monthly Pattern
          </div>
          <div className="row">
            <div className="col">
              <div className="row row-daily-patternof">
                <div className="col pattern-daily">
                  <strong>Of&nbsp;&nbsp;</strong>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="monthlyday"
                        checked={this.state.monthlyType === "monthlyday"}
                        onChange={this.handleMonthlyType}
                      />{" "}
                      Day
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="monthlythe"
                        checked={this.state.monthlyType === "monthlythe"}
                        onChange={this.handleMonthlyType}
                      />{" "}
                      The
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {monthlyTypePanel}
          {detailCalcButtonPanel}
        </div>
      );
    }

    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.periodPattern === "daily"
      // && this.state.showDailyPopup
    ) {
      patternPanel = (
        <div className="define-pattern-detail-box">
          <div className="text-center monthly-pattern-head">Daily Pattern</div>

          <div className="row">
            <div className="col text-left daily-columns">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="dailyeveryday"
                  checked={this.state.dailyType === "dailyeveryday"}
                  onChange={this.handleDailyType}
                />{" "}
                Every
              </label>
              &nbsp;
              <input
                name="numberOfDays"
                type="number"
                value={this.state.numberOfDays}
                onChange={this.onChangeHours}
                className="daily-every-day"
                step="1"
              />
              &nbsp;day(s)
            </div>
          </div>
          <div className="row">
            <div className="col text-left daily-columns">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="dailyeveryweekday"
                  checked={this.state.dailyType === "dailyeveryweekday"}
                  onChange={this.handleDailyType}
                />{" "}
                Every Weekday
              </label>
            </div>
          </div>
          {detailCalcButtonPanel}
        </div>
      );
    }

    if (
      this.state.daySpecificationProcess === "periodic" &&
      this.state.periodPattern === "weekly"
    ) {
      patternPanel = (
        <div className="define-pattern-detail-box">
          <div className="text-center monthly-pattern-head">Weekly Pattern</div>
          <div className="">
            Recur every&nbsp;
            <input
              name="recurweeks"
              type="number"
              value={this.state.recurweeks}
              onChange={this.onChangeHours}
              className="daily-every-day"
              step="1"
            />{" "}
            &nbsp;week(s) on:
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekSunday"
              checked={this.state.weekSunday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Sunday
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekMonday"
              checked={this.state.weekMonday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Monday
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekTuesday"
              checked={this.state.weekTuesday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Tuesday
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekWednesday"
              checked={this.state.weekWednesday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Wednesday
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekThursday"
              checked={this.state.weekThursday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Thursday
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekFriday"
              checked={this.state.weekFriday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Friday
          </div>
          <div className="text-left weekday-right-shift">
            <Checkbox
              name="weekSaturday"
              checked={this.state.weekSaturday}
              onChange={this.handleDayOFWeek}
            />
            &nbsp;Saturday
          </div>

          {detailCalcButtonPanel}
        </div>
      );
    }

    let thePeriodicPanel;
    thePeriodicPanel = (
      <div>
        <div className="text-center periodic-header">
          Periodic Date Calculator
        </div>
        <div className="row">
          {/* <div className="col text-center">&nbsp;</div> */}
          <div className="col text-center">{beginEndPanel}</div>
        </div>
        <div className="row">
          <div className="col text-center periodic-date-begin-header">
            <b>Begins at:</b>&nbsp;
            {moment(this.state.begindate).format("MM-DD-YYYY")}
          </div>
        </div>
        <div className="row">
          <div className="col text-center periodic-date-end-header">
            <b>Ends at:</b>&nbsp;
            {moment(this.state.enddate).format("MM-DD-YYYY")}
          </div>
        </div>
        <div className="row">
          {/* <div className="col text-center">&nbsp;</div> */}
          <div className="col text-center">{periodTypePanel}</div>
        </div>
        {patternPanel}
      </div>
    );

    let schedulePlanPanel;
    if (this.state.daySpecificationProcess === "periodic") {
      schedulePlanPanel = (
        <div className="shift-daypic-repeat-box">
          <div className="row">
            <div className="col-md-6">{thePeriodicPanel}</div>
            <div className="col-md-6">{theDatePanel}</div>
          </div>
        </div>
      );
    }

    if (this.state.daySpecificationProcess === "custom") {
      schedulePlanPanel = (
        <div>
          <div className="row">
            <div className="col">{dayPicPanel}</div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {scheduleProcessPanel}
        {schedulePlanPanel}
      </div>
    );

    return (
      <div>
        <div className="text-center time-schedule-head">Day Scheduling</div>
        <div className="text-center shift-days-name-pos">
          <b>Event:&nbsp;{this.props.theEvent.eventName}</b>
        </div>
        {outputPanel}
        {buttonPanel}
        <div className="service-days-msg text-center">{this.state.errMsg}</div>
        <ModalContainer />
      </div>
    );
  }
}

ScheduleDays.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduleDays));
