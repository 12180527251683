import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./InitiateAStore.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const initiateAStore = "/routes/coop/initiateAStore"; 

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1163000000";

class InitiateAStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [],

      selheight: 1,
      selectedMember: null,
      coopCreateNote: "", 
      coopCreateConfirm: false,
      community: null,

      notificationNote: "",

      notifyViaText: false,
      notifyViaEmail: true,
      notifyViaBmail: false,

      notificationSubject: '',

      errMsg: "",
      errFlag: false,
    };
  }
  componentDidMount = async () => {
    let nnote =
      "The store-formation process has been initiated. Please login to Baanda and navigate to the " +
      this.props.community.commName +
       
      " community (click Engage from Lobby and search for the community " +  this.props.community.commName + " and go in). Then click 'Keeper' and complete the admin tasks that are now available to you. When you are done, submit your store for the final approval.";
     
      let members = [];
      this.props.community.members.forEach(mem => {
        if ( mem.role !== 'creator') {
          members.push(mem);
        }
      })

    this.setState({
      members,
      community: this.props.community,
      notificationNote: nnote,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleMemberSelect = async () => {
    let value = document.getElementById("coopMemberInitid").value;
    let data = JSON.parse(value);

    await this.setState({
      selectedMember: data,
      selheight: 1,
    });
  };

  onfocus = async () => {
    await this.setState({
      selheight: 4,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleCoopCreateConfirn = async () => {
    await this.setState((prevstate) => ({
      coopCreateConfirm: !prevstate.coopCreateConfirm,
    }));
  };

  // handleNotifyEmail = async () => {
  //   await this.setState((prevstate) => ({
  //     notifyViaEmail: !prevstate.notifyViaEmail,
  //   }));
  // };

  // handleNotifyText = async () => {
  //   await this.setState((prevstate) => ({
  //     notifyViaText: !prevstate.notifyViaText,
  //   }));
  // };


  createNewStore = async () => {
    let url = baandaServer + initiateAStore;
    let input = {
      communityId: this.props.communityId,
      communityName: this.state.community.commName,
      ownerBaandaId: this.state.selectedMember.baandaId,
      ownerName: this.state.selectedMember.name,
      ownerEmail: this.state.selectedMember.email,
      ownerCell: this.state.selectedMember.cell,
      notificationNote: this.state.notificationNote,
      notificationSubject: this.state.notificationSubject,
      notifyViaText: this.state.notifyViaText,
      notifyViaEmail: this.state.notifyViaEmail,
      notifyViaBmail: this.state.notifyViaBmail,
      talkLog: this.state.coopCreateNote,
      clientProgram: "InitiateAStore.js",
      clientFunction: "createNewStore",
      baandaId: this.props.auth.user.baandaId,
      baandaName: this.props.auth.user.name,
    };
    try {
      let initret = await axios.post(url, input);
      if (initret.data.status === "success") {
        this.setState({
          errMsg: "Successfully initiated",
          errFlag: false,
          community: initret.data.Msg,
          members: initret.data.Msg.members,
          notificationNote: "",
          selectedMember: null,
          coopCreateNote: "",
          coopCreateConfirm: false,
        });
        if (document.getElementById("coopMemberInitid")) {
          document.getElementById("coopMemberInitid").value = "DEFAULT";
        }
      } else {
        this.setState({
          errMsg: initret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('InitiateAStore...');

    let infoButton;
    infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let createButton;
    createButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.createNewStore}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Create
      </button>
    );

    let memberlist;
    if (this.state.members.length > 0) {
      memberlist = this.state.members.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.name}&nbsp;({obj.email}) Curr-role: {obj.role}
          </option>
        );
      });
    }

    let selectMemberPanel;
    selectMemberPanel = (
      <div className="row">
        <div className="col-2">&nbsp;</div>
        <div className="col-8">
          <select
            size={this.state.selheight}
            id="coopMemberInitid"
            name="coopMemberInitid"
            onChange={this.handleMemberSelect}
            className="coop-member-sel-dropdown"
            onFocus={this.onfocus}
            onBlur={this.onblur}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a member
            </option>
            {memberlist}
          </select>
        </div>
        <div className="col-2">&nbsp;</div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="new-coop-init-box">
        <div className="text-center coop-init-header">
          Initiate a Co-op Store &nbsp;&nbsp;{infoButton}
        </div>
        <div className="coop-init-selmem-pos">
          <div className="text-center coop-init-selmem-lbl">
            Select a member to be a store owner
          </div>
          {selectMemberPanel}
        </div>
        <div className="text-center coop-init-selmem-lbl-pos">
          Special note as conversation log
        </div>
        <div className="row">
          <div className="col">
            <textarea
              name="coopCreateNote"
              maxLength="2000"
              placeholder="Special note (optional)"
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="coop_input_init_textarea"
              onChange={this.onChange}
              value={this.state.coopCreateNote}
              required
            />
          </div>
        </div>
        <div className="text-center coop-init-selmem-lbl-pos">
          Notification Note (email / text)
        </div>
        <div className="row">
          <div className="col">
            <textarea
              name="notificationNote"
              maxLength="200"
              placeholder="Message within 200 chars. Your name will be annexed automatically ..."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="coop_notify_init_textarea"
              onChange={this.onChange}
              value={this.state.notificationNote}
              required
            />
          </div>
        </div>
        <div className="row search-other-place">
            <div className="col-3 text-right coop-init-email-lbl">Subject</div>
            <div className="col-9 text-left">
              <input
                name="notificationSubject"
                type="text"
                value={this.state.notificationSubject}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="coop-init-email-subject"
                placeholder="email subject"
              />
            </div>
          </div>
        <div className="row coop-init-confirm-pos">
          <div className="col  test-right coop-init-selmem-lbl">
            Notification via email to {this.state.selectedMember? this.state.selectedMember.email: ""}
            {/* <Checkbox
              checked={this.state.notifyViaEmail}
              onChange={this.handleNotifyEmail}
            /> */}
            {/* &nbsp;&nbsp;email &nbsp;&nbsp; */}
            {/* <Checkbox
              checked={this.state.notifyViaText}
              onChange={this.handleNotifyText}
            />
            &nbsp;&nbsp;Text */}
          </div>
        </div>
        <div className="row coop-init-confirm-pos">
          {this.state.selectedMember ? (
            <div className="col-6 test-right coop-init-selmem-lbl">
              <Checkbox
                checked={this.state.coopCreateConfirm}
                onChange={this.handleCoopCreateConfirn}
              />
              &nbsp;&nbsp;Create a new store for:{" "}
              {this.state.selectedMember.name}
            </div>
          ) : null}

          <div className="col-6 test-left">
            {this.state.coopCreateConfirm ? createButton : null}
          </div>
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center coop-init-msg-err"
              : "text-center coop-init-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

InitiateAStore.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InitiateAStore));
