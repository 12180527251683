import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import LoadFileToS3 from "../../../../utils/components/fileUpload/LoadFileToS3";

import "./AdvertisementSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updatePromoDA = "/routes/dashboard/updatePromoDA";
const savePromoData = "/routes/dashboard/savePromoData";

const imageFileTypes = [
  "jpeg",
  "jpg",
  "bmp",
  "tiff",
  "png",
  "gif",
  "ebp",
  "svg",
];
// const audioFileTypes = ["mp3", "wav", "mpeg"];
const vedioFileTypes = ["mp4", "webm"];

const maxImageSize = 50000000; // 50MB
const maxVideoSize = 150000000; // 150mb  -- about 10 minutes
// const maxAudioSize = 524288000; // 5 5MB
// const maxLogoSize = 52428800; // 250kb

// Digital file types used are
// 1. commPromoImage
// 2. commPromoVideo
// 3. bannerLarge
// 4. bannerSmall
// 5. ad1Image
// 6. ad2Image
// 7. ad3Image

const readMeCode = "1115700000";

class AdvertisementSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s3SelfiFileData: null,
      s3CommImageFileData: null,
      s3CommVideoFileData: null,
      s3BannerLargeFileData: null,
      s3BannerSmallFileData: null,
      s3AdvFileData1: null,
      s3AdvFileData2: null,
      s3AdvFileData3: null,
      s3AdvFileData1Small: null,
      s3AdvFileData2Small: null,
      s3AdvFileData3Small: null,
      advType: "static",

      imageFileTypes: "",
      s3ImageFileData: null,
      maxDASize: 0,

      selectedFileType: "",
      imgRequestorMsg: "",
      loadImageFlag: false,

      fileUploads: [],

      s3communeRefno: "",
      selheight: 1,

      errMsg:
        'Please click info "i" to view recommended image sizes for the best customer experience.',
      errFlag: false,

      entrepreneurs: "",
    };
  }

  componentDidMount = async () => {
    await this.setState({
      s3communeRefno: this.props.community.s3communeRefno,
      fileUploads: this.props.community.fileUploads,
    });

    let entrepreneurs = "";
    if (this.props.community)
      entrepreneurs = this.props.community.entrepreneurs;

    await this.setStateFilePerDBFileUpload(this.props.community.fileUploads);

    this.setState({
      entrepreneurs,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setStateFilePerDBFileUpload = async () => {
    let fileUploads = this.state.fileUploads;
    let s3SelfiFileData = null;
    let s3CommImageFileData = null;
    let s3CommVideoFileData = null;
    let s3BannerLargeFileData = null;
    let s3BannerSmallFileData = null;
    let s3AdvFileData1 = null;
    let s3AdvFileData2 = null;
    let s3AdvFileData3 = null;
    let s3AdvFileData1Small = null;
    let s3AdvFileData2Small = null;
    let s3AdvFileData3Small = null;

    fileUploads.forEach((obj) => {
      switch (obj.type) {
        case "creatorSelfi":
          s3SelfiFileData = obj;
          break;
        case "commPromoImage":
          s3CommImageFileData = obj;
          break;
        case "commPromoVideo":
          s3CommVideoFileData = obj;
          break;
        case "bannerLarge":
          s3BannerLargeFileData = obj;
          break;
        case "bannerSmall":
          s3BannerSmallFileData = obj;
          break;
        case "ad1Image":
          s3AdvFileData1 = obj;
          break;
        case "ad2Image":
          s3AdvFileData2 = obj;
          break;
        case "ad3Image":
          s3AdvFileData3 = obj;
          break;
        case "ad1Imagesmall":
          s3AdvFileData1Small = obj;
          break;
        case "ad2Imagesmall":
          s3AdvFileData2Small = obj;
          break;
        case "ad3Imagesmall":
          s3AdvFileData3Small = obj;
          break;
        default: // do nothing
      }
    });

    this.setState({
      s3SelfiFileData,
      s3CommImageFileData,
      s3CommVideoFileData,
      s3BannerLargeFileData,
      s3BannerSmallFileData,
      s3AdvFileData1,
      s3AdvFileData2,
      s3AdvFileData3,
      s3AdvFileData1Small,
      s3AdvFileData2Small,
      s3AdvFileData3Small,
    });
  };

  handleCarrierSelected = async () => {
    // console.log("in handleCarrierSelected");
    let value = document.getElementById("promoTypeSelect").value;

    let maxDASize = maxImageSize;
    let processingFileType = value;
    let fileUploadType = "image";
    let daFileType = imageFileTypes;

    let imgReq = "Image - MaxSize: " + maxImageSize / 1000000 + " MB";
    let s3FileData = null;
    if (value === "creatorSelfi") {
      imgReq = "Creators Selfi - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3SelfiFileData;
    }
    if (value === "commPromoVideo") {
      daFileType = vedioFileTypes;
      maxDASize = maxVideoSize;
      fileUploadType = "video";
      imgReq = "Promo Video - MaxSize: " + maxVideoSize / 1000000 + " MB";
      s3FileData = this.state.s3CommVideoFileData;
    }
    if (value === "commPromoImage") {
      imgReq = "Promo Image - MaxSize: " + maxImageSize / 1000000 + " MB";

      s3FileData = this.state.s3CommImageFileData;
    }
    if (value === "bannerLarge") {
      imgReq =
        "Banner Image Large - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3BannerLargeFileData;
    }
    if (value === "bannerSmall") {
      imgReq =
        "Banner Image Small - MaxSize: " + maxVideoSize / 1000000 + " MB";
      s3FileData = this.state.s3BannerSmallFileData;
    }
    if (value === "ad1Image") {
      imgReq =
        "Adv Image 1 (large)- MaxSize: " + maxImageSize / 1000000 + " MB";

      s3FileData = this.state.s3AdvFileData1;
    }
    if (value === "ad2Image") {
      imgReq =
        "Adv Image 2 (large) - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3AdvFileData2;
    }
    if (value === "ad3Image") {
      imgReq =
        "Adv Image 3 (large) - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3AdvFileData3;
    }
    if (value === "ad1Imagesmall") {
      imgReq =
        "Adv Image 1 (small) - MaxSize: " + maxImageSize / 1000000 + " MB";

      s3FileData = this.state.s3AdvFileData1Small;
    }
    if (value === "ad2Imagesmall") {
      imgReq =
        "Adv Image 2 (small) - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3AdvFileData2Small;
    }
    if (value === "ad3Imagesmall") {
      imgReq =
        "Adv Image 3 (small) - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3AdvFileData3Small;
    }

    await this.setState({
      imageFileTypes: daFileType,
      selheight: 1,
      s3FileData,
      maxDASize,
      imgRequestorMsg: imgReq,
      processingFileType,
      fileType: fileUploadType,
      loadImageFlag: true,
    });
  };

  callerOfImageUpload = async (s3loadret) => {
    let deletedFileSize = 0;
    let newFileSize = 0;
    let newFileuploads = [];

    if (s3loadret.ops === "cancel") {
      newFileuploads = [...this.state.fileUploads];
    } else {
      this.state.fileUploads.forEach((obj) => {
        if (obj.type === this.state.processingFileType) {
          if (s3loadret.ifDeletedFlag) {
            if (obj.size) {
              deletedFileSize = obj.size;
            }
          }
        } else newFileuploads.push(obj);
      });
      if (s3loadret.s3FileData) {
        // console.log("inside new DA");
        s3loadret.s3FileData.type = this.state.processingFileType;
        newFileuploads.push(s3loadret.s3FileData);
        if (s3loadret.s3FileData.size) newFileSize = s3loadret.s3FileData.size;
        // s3ImageFileData = s3loadret.s3FileData;
      }
    }

    if (s3loadret.ops === "done") {
      // console.log("Will update DA in db ...");
      let adjustOfDASize = newFileSize - deletedFileSize;
      let inputData = {
        adjustOfDASize,
        fileUploads: newFileuploads,
        communityId: this.props.communityId,
      };
      // console.log("inputData:", inputData);
      await this.updateDAInDB(inputData);
      await this.setStateFilePerDBFileUpload();
    } else {
      console.log("Was canceled ... did not update");
    }

    this.setState({
      loadImageFlag: false,
    });
  };

  onfocus = () => {
    this.setState({
      selheight: 8,
    });
  };

  onblur = () => {
    this.setState({
      selheight: 1,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  updateDAInDB = async (inputData) => {
    let url = baandaServer + updatePromoDA;

    try {
      let proret = await axios.post(url, inputData);
      if (proret.data.status === "success") {
        this.setState({
          fileUploads: proret.data.Msg,
          errMsg: "Successfully Updated",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: proret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  saveCreatorNames = async () => {
    let url = baandaServer + savePromoData;
    let input = {
      communityId: this.props.communityId,
      entrepreneurs: this.state.entrepreneurs,
    };
    try {
      let ret = await axios.post(url, input);
      // console.log("ret.data:", ret.data);
      if (ret.data.status === "success") {
        this.setState({
          errMsg: "Successfully Updated",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: ret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("AdvertisementSetup ...");

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let creatorNamePanel;
    creatorNamePanel = (
      <div>
        <div className="row adm-creator-name-pos">
          <div className="col-3 text-right adm-creator-names-lbl">
            Entrepreneurs
          </div>
          <div className="col-9 text-left">
            <input
              name="entrepreneurs"
              type="text"
              value={this.state.entrepreneurs}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="adm-entrepreneurs-field"
              placeholder="Separate names with a semicolon (;)"
            />
          </div>
        </div>
        <div className="text-center adm-creator-save-btn-pos">
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.saveCreatorNames}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        </div>
      </div>
    );

    let promoFiletypePanel;
    promoFiletypePanel = (
      <div>
        <select
          size={this.state.selheight}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          id="promoTypeSelect"
          name="promoTypeSelect"
          onChange={this.handleCarrierSelected}
          onSelect={this.handleCarrierSelected}
          className="adm-promo-type-select"
          defaultValue={"DEFAULT"}
        >
          <option className="" value="DEFAULT" disabled>
            Select file type
          </option>
          <option className="" value="creatorSelfi">
            Creator(s) Selfie
          </option>
          <option className="" value="commPromoImage">
            Landing Page Image
          </option>
          <option className="" value="commPromoVideo">
            Landing Page Video
          </option>
          <option className="" value="bannerLarge">
            Community Banner Large
          </option>
          <option className="" value="bannerSmall">
            Community Banner Small
          </option>
          <option className="" value="ad1Image">
            Promo Bottom image 1 (large)
          </option>
          <option className="" value="ad2Image">
            Promo Bottom image 2 (large)
          </option>
          <option className="" value="ad3Image">
            Promo Bottom image 3 (large)
          </option>
          <option className="" value="ad1Imagesmall">
            Promo Bottom image 1 (small)
          </option>
          <option className="" value="ad2Imagesmall">
            Promo Bottom image 2 (small)
          </option>
          <option className="" value="ad3Imagesmall">
            Promo Bottom image 3 (small)
          </option>
        </select>
      </div>
    );

    let loadImagePanel;
    if (this.state.loadImageFlag) {
      loadImagePanel = (
        <div className="text-center">
          <LoadFileToS3
            returnToCaller={this.callerOfImageUpload}
            communityId={this.props.communityId}
            fileType={this.state.imageFileTypes}
            maxSize={this.state.maxDASize}
            s3subdir={this.state.s3communeRefno}
            requestor={this.state.imgRequestorMsg}
            s3FileData={this.state.s3FileData}
            type={this.state.fileType}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.loadImageFlag) {
      outputPanel = (
        <div className="advertisement-box-zz">
          <div className="text-center admin-ad-header">
            Community Promo Image Mgmt. &nbsp;{infoButton}
          </div>
          {loadImagePanel}
        </div>
      );
    } else {
      outputPanel = (
        <div className="advertisement-box-zz">
          <div className="text-center admin-ad-header">
            Community Promotion Image Mgmt.&nbsp;{infoButton}
          </div>
          <div className="row promo-file-sel-pos">
            <div className="col-4 text-right promo-lbl">
              Select Upload Type{" "}
            </div>
            <div className="col-8 text-left">{promoFiletypePanel}</div>
          </div>
          {creatorNamePanel}
          <div className="row">
            <div
              className={
                this.state.errFlag
                  ? "col text-center admin-promo-msg-err"
                  : "col text-center admin-promo-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

AdvertisementSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementSetup);
