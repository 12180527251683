import React, { Component } from "react";

import "./FormatCompEntry.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class FormatCompEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      format: null,
      color: "#000000",
      fontFamily: "'Times New Roman', serif",
      fontSize: "18pt",
      bgColor: "#ffffff",
      isBold: false,

      ffSelheight: 1,
      fontSizeheight: 1,

      align: "left",

      template: "",
    };
  }

  componentDidMount = () => {
    let template = "";
    if (this.props.selectedTemplate === "basic")
      template = "Basic";
    let fontFamily = this.state.fontFamily;
    let bgColor = this.state.bgColor;
    let fontSize = this.state.fontSize;
    let color = this.state.color;
    let isBold = this.state.isBold;
    let align = this.state.align;
    if (this.props.format) {
      // console.log('in here ...this.props.format.fontSize:', this.props.format.fontSize);
      fontFamily = this.props.format.fontFamily;
      bgColor = this.props.format.bgColor;
      fontSize = this.props.format.fontSize;
      color = this.props.format.color;
      isBold = this.props.format.isBold;
      align = this.props.format.align;
    }

    this.setState({
      format: this.props.format,
      template,
      fontFamily,
      bgColor,
      fontSize,
      color,
      isBold,
      align,
    });
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  returnToOrigin = () => {
    this.props.returnToCaller(this.props.format);
  };

  handleSaveFormat = () => {
    let format = {
      bgColor: this.state.bgColor,
      color: this.state.color,
      fontFamily: this.state.fontFamily,
      fontSize: this.state.fontSize,
      isBold: this.state.isBold,
      align: this.state.align,
    };
    // console.log('Format :', format)
    this.props.returnToCaller(format);
  };

  firstLetterUpperCase = (word) => {
    let fl = word.charAt(0);
    let rest = word.slice(1);

    return fl.toUpperCase() + rest;
  };

  handlefontfamilySelect = async () => {
    let value = document.getElementById("fontfamilyselect").value;
    // console.log("handlefontfamilySelect value:", value);

    await this.setState({
      ffSelheight: 1,
      fontFamily: value,
    });
  };

  ffonfocus = () => {
    this.setState({
      ffSelheight: 5,
    });
  };

  ffonblur = () => {
    this.setState({
      ffSelheight: 1,
    });
  };

  handlefontsizeSelect = async () => {
    let value = document.getElementById("fontsizeselect").value;
    // console.log("value:", value);

    await this.setState({
      fontSizeheight: 1,
      fontSize: value,
    });
  };

  fzonfocus = () => {
    this.setState({
      fontSizeheight: 5,
    });
  };

  fzonblur = () => {
    this.setState({
      fontSizeheight: 1,
    });
  };

  handleBold = async () => {
    this.setState((prevstate) => ({
      isBold: !prevstate.isBold,
    }));
  };

  handlePropertyOf = async (e) => {
    this.setState({
      align: e.target.value,
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    // console.log('FormatCmopEntry...');

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToOrigin}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let saveButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handleSaveFormat}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let fontFamilyPanel;
    fontFamilyPanel = (
      <div>
        <div className="font-family-row-space" />
        <div className="row">
          <div className="col-3 text-right font-family-comp-lbl">Font Fam</div>
          <div className="col-9 text-left">
            <select
              size={this.state.ffSelheight}
              onFocus={this.ffonfocus}
              onBlur={this.ffonblur}
              id="fontfamilyselect"
              name="fontfamilyselect"
              onChange={this.handlefontfamilySelect}
              onSelect={this.handlefontfamilySelect}
              className="comp-format-family-dd"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select a font family
              </option>

              <option value="Arial, sans-serif">Arial (sans-serif)</option>

              <option value="Century Gothic, sans-serif">Century Gothic</option>
              <option value="'Courier New', monospace">
                Courier New (monospace)
              </option>
              <option value="Georgia, serif">Georgia (serif) </option>
              <option value="Garamond, serif">Garamond (serif) </option>
              <option value="Helvetica, sans-serif">
                Helvetica (sans-serif)
              </option>
              <option value="'Lucida Grande', arial, sans-serif">
                Lucida Grande
              </option>
              <option value="Tahoma, sans-serif">Tahoma (sans-serif)</option>
              <option value="'Times New Roman', serif">
                Times New Roman (serif)
              </option>
              <option value="'Trebuchet MS', sans-serif">
                Trebuchet MS (sans-serif)
              </option>
              <option value="Verdana, sans-serif">Verdana (sans-serif)</option>
            </select>
          </div>
        </div>
      </div>
    );

    let fontSizeDDPanel;
    fontSizeDDPanel = (
      <div>
        <div className="font-family-row-space" />
        <div className="row">
          <div className="col-4 text-right font-family-comp-lbl">
            Font Size:
          </div>
          <div className="col-8 text-left">
            <select
              size={this.state.fontSizeheight}
              onFocus={this.fzonfocus}
              onBlur={this.fzonblur}
              id="fontsizeselect"
              name="fontsizeselect"
              onChange={this.handlefontsizeSelect}
              onSelect={this.handlefontsizeSelect}
              className="comp-format-font-size"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select a font size
              </option>
              <option value="8pt">xxxx-Small (8pt)</option>
              <option value="9pt">xxx-Small (9pt)</option>
              <option value="10pt">xx-small (10pt)</option>
              <option value="11pt">x-small (11pt)</option>
              <option value="12pt">Small (12pt)</option>
              <option value="14pt">Medium minus (14pt)</option>
              <option value="16pt">Medium (16pt)</option>
              <option value="18pt">Medium plus (18pt)</option>
              <option value="20pt">Large (20pt)</option>
              <option value="24pt">x-Large (24pt)</option>
              <option value="28pt">xx-Large (28pt)</option>
            </select>
          </div>
        </div>
      </div>
    );

    let wd = "100%";
    let ht = "80px";
    let bdr = "1px solid black";
    let showCurrFormatPanel = (
      <div>
        <hr className="hr-scene" />
        <div className="text-center comp-surr-format-lbl">Current Format</div>
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 text-right font-setup-text">
                Font Family
              </div>
              <div className="col-8 text-left font-setup-text">
                {this.state.fontFamily}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right font-setup-text">BG Color</div>
              <div className="col-8 text-left font-setup-text">
                {this.state.bgColor}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right font-setup-text">Font size</div>
              <div className="col-8 text-left font-setup-text">
                {this.state.fontSize}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right font-setup-text">Font color</div>
              <div className="col-8 text-left font-setup-text">
                {this.state.color}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div
              style={{
                color: this.state.color,
                width: wd,
                height: ht,
                border: bdr,
                backgroundColor: this.state.bgColor,
                fontFamily: this.state.fontFamily,
                fontSize: this.state.fontSize, 
              }}
            >
              {this.state.isBold ? <b>Looks like so</b> : "Looks like so"}
              {/* Looks like so */}
            </div>
          </div>
        </div>
        <hr className="hr-scene" />
      </div>
    );

    let alignPanel;
    alignPanel = (
      <div>
        <div className="row property-of-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Align&nbsp;:&nbsp;</strong>

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="left"
                  checked={this.state.align === "left"}
                  onChange={this.handlePropertyOf}
                />{" "}
                Left
              </label>
            </div>

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="center"
                  checked={this.state.align === "center"}
                  onChange={this.handlePropertyOf}
                />{" "}
                Center
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let formatterPanel;
    formatterPanel = (
      <div>
        <div className="row">
          <div className="col-6">{fontFamilyPanel}</div>
          <div className="col-6">
            <div className="row ">
              <div className="col-4 text-right font-family-comp-lbl">
                BG Color
              </div>
              <div className="col-8 text-left font-family-comp-lbl">
                <input
                  name="bgColor"
                  type="text"
                  value={this.state.bgColor}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="comp-format-bgColor"
                  placeholder=""
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">{fontSizeDDPanel}</div>
          <div className="col-6">
            <div className="row ">
              <div className="col-4 text-right font-family-comp-lbl">
                Font Color
              </div>
              <div className="col-8 text-left font-family-comp-lbl">
                <input
                  name="color"
                  type="text"
                  value={this.state.color}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="comp-format-bgColor"
                  placeholder=""
                />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="row com-bold-save-row-pos">
          <div className="col-4 com-bold-lbl">
            <Checkbox checked={this.state.isBold} onChange={this.handleBold} />{" "}
            Make it bold
          </div>
          <div className="col-4">{alignPanel}</div>
          <div className="col-4">{saveButton}</div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="comp-format-pop-box">
        <div className="text-center format-header-lbl">
          {this.firstLetterUpperCase(this.props.callingField)} Formatter &nbsp;
          {backButton}
        </div>
        <div className="text-center format-selected-temp">
          ( Template: {this.state.template} )
        </div>
        {showCurrFormatPanel}
        {formatterPanel}
        <hr />
        <div className="text-center format-col-find-row">
          For selecting color hash #, go to{" "}
          <a
            href="https://www.google.com/search?q=color+picker"
            target="_blank"
            rel="noreferrer"
          >
            Color Picker
          </a>
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default FormatCompEntry;
