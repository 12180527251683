import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import { sortArrayOfDates } from "../../../../../../utils/sortArrayOfDates";

import ViewQuote from "../../../tasks/utils/ViewQuote";
import ViewCO from './ViewCO';

import "./CustTaskDetails.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateTaskDetailsCust = "/routes/projects/updateTaskDetailsCust";

const readMeCode = "1110511111";

class CustTaskDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      newTaskNote: "",

      showTaskDetails: true,
      showQuoteMgmt: false,
      showQuoteFlag: false,

      showCOButton: false,
      showCOFlag: false,

      nextStatus: "wip",
      nextState: "green",
      taskNotesForAll: [],

      proceedDecision: "wip",
      showDecisionFlag: true,

      errMsg: "",
      errFlag: false,
      theTask: null,

      packagedCOViewData: null,
      packagedViewData: null,

      changeOrderSeqno: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let nextStatus = "wip";
    if (this.props.theTask.taskStatus !== "") {
      if (this.props.theTask.taskStatus === "new") nextStatus = "wip";
      else nextStatus = this.props.theTask.taskStatus;
    }

    let changeOrderSeqno = null;
    let showQuoteFlag = false;
    if (this.props.theTask.quotes.length > 0) {
      this.props.theTask.quotes.forEach((obj) => {
        if (obj.taskStatus !== "wip") {
          changeOrderSeqno = obj.changeOrderSeqno;
          showQuoteFlag = true;
        }
      });
    }

    let sorted = [];
    if (this.props.theTask.taskNotesForAll.length > 0) {
      sorted = await sortArrayOfDates(
        this.props.theTask.taskNotesForAll,
        "onDate",
        "dsc"
      );
    }

    let proceedDecision = "wip";
    let showDecisionFlag = true;
    if (
      this.props.theTask.taskStatus === "oktoproceed" ||
      this.props.theTask.taskStatus === "ready"
    )
      proceedDecision = "oktoproceed";

    if (this.props.theTask.taskStatus === "done") {
      showDecisionFlag = true;
    } else if (this.props.theTask.controller === "seller")
      showDecisionFlag = false;

      let showCOButton = false;
      if ( this.props.theTask.changeOrder.length > 0) showCOButton = true;

    this.setState({
      deviceSize,
      theTask: this.props.theTask,
      taskNotesForAll: sorted,
      nextStatus,
      nextState: this.props.theTask.taskState,
      changeOrderSeqno,
      showQuoteFlag,
      proceedDecision,
      showDecisionFlag,
      showCOButton
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  returnToTasklist = () => {
    this.props.returnToCaller();
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  sendProgressNotes = async () => {
    if (this.state.newTaskNote.length > 15) {
      let url = baandaServer + updateTaskDetailsCust;
      let newTaskNoteObj = {
        note: this.state.newTaskNote,
        party: "Customer",
        noteById: this.props.auth.user.baandaId,
        byName: this.props.auth.user.name,
        byEmail: this.props.auth.user.email,
        onDate: new Date(),
      };
      let input = {
        taskId: this.props.theTask.taskId,
        newTaskNoteObj,
        taskState: this.state.nextState,
        // taskStatus: this.state.nextStatus,
        clientDecision: this.state.proceedDecision,
        controller: 'client',
      };

      // console.log("url: ", url, " input:", input);

      try {
        let tskret = await axios.post(url, input);
        // console.log("tskret.data:", tskret.data);
        if (tskret.data.status === "success") {
          let sorted = await sortArrayOfDates(
            tskret.data.Msg.taskNotesForAll,
            "onDate",
            "asc"
          );
          let showDecisionFlag = false;
          if (this.state.proceedDecision === 'wip') showDecisionFlag = true;
          
          this.setState({
            errMsg: "Successfully updated",
            errFlag: false,
            theTask: tskret.data.Msg,
            taskNotesForAll: sorted,
            nextStatus: tskret.data.Msg.taskStatus,
            nextState: tskret.data.Msg.taskState,
            showDecisionFlag
          });
        } else {
          this.setState({
            errMsg: tskret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: "Please send a message, of at least 15 characters.",
        errFlag: true,
      });
    }
  };

  packageDataForView = async () => {
    let qts = this.props.theTask.quotes[this.props.theTask.quotes.length - 1];
    let manager;
    if (this.props.theTask.taskReligateChain.length > 0) {
      manager =
        this.props.theTask.taskReligateChain[
          this.props.theTask.taskReligateChain.length - 1
        ];
    } else {
      manager = {
        pmBaandaId: this.props.auth.user.baandaId,
        pmCell: this.props.auth.user.cell.number,
        pmEmail: this.props.auth.user.email,
        pmName: this.props.auth.user.name,
      };
    }

    let lineIt = [];
    qts.lineItem.forEach((obj) => {
      let liobj = {
        lineItemNo: obj.seqno,
        lineItemDescription: obj.description,
        lineItemMilestone: obj.milestone,
        lineItemCost: obj.cost,
      };
      lineIt.push(liobj);
    });

    let lter = [];
    qts.lineTerms.forEach((obj) => {
      let liobj = {
        termLineItemNo: obj.seqno,
        termDescription: obj.description,
      };
      lter.push(liobj);
    });

    let input = {
      changeOrderSeqno: qts.changeOrderSeqno,
      theTask: this.props.theTask,
      lineItems: lineIt,
      lineTerms: lter,
      serviceAt: {
        street: qts.serviceAt.street,
        city: qts.serviceAt.city,
        state: qts.serviceAt.state,
        zip: qts.serviceAt.aip,
      },
      contingency: {
        maxDelay: parseFloat(qts.maxDelayAllowed),
        percent: parseFloat(qts.contingencyPercent),
      },
      daysToFinishTheTask: parseFloat(qts.daysToFinishTheTask),
      startByAfterRetained: parseFloat(qts.startByAfterRetained),
      retainerPer: parseFloat(qts.retainerPercent),
      invoiceAt: qts.invoiceAt,
      taxAmount: parseFloat(qts.estimatedTaxAmount),
      manager,
    };

    this.setState({
      packagedViewData: input,
    });
  };

  handleTaskQuote = async () => {
    await this.packageDataForView();
    this.setState({
      showTaskDetails: false,
      showQuoteMgmt: true,
    });
  };

  returnFromQuoteView = () => {
    this.setState({
      showTaskDetails: true,
      showQuoteMgmt: false,
    });
  };

  // packageCODataForView = async () => { 
    
  //   this.setState({
  //     packagedCOViewData: null
  //   })
  // }

  handleTaskChangeOrder = async () => {
    // await this.packageCODataForView();

    this.setState({
      showTaskDetails: false,
      showQuoteMgmt: false,
      showCOFlag: true,
    });
  }

  returnFromChangeOrder = async() => {
    this.setState({
      showTaskDetails: true,
      showQuoteMgmt: false,
      showCOFlag: false,
    });
  }

  handleProceedDecision = async (e) => {
    await this.setState({
      proceedDecision: e.target.value,
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    console.log('CustTaskDetails...');

    let sd = this.props.theTask;

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        onClick={this.returnToTasklist}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );



    let updatebtn, quotebtn, chord;
    if (this.state.deviceSize === "big") {
      updatebtn = "Update";
      quotebtn = "Quote";
      chord = "ChOrd";
    } else {
      updatebtn = "Updt";
      quotebtn = "Quote";
      chord = "CO";
    }

    let buttonPanel;
    buttonPanel = (
      <div className="text-center comm-task-detail-button-row">
        <button
          onClick={this.sendProgressNotes}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn_reg_70"
        >
          {updatebtn}
        </button>
        {this.state.showQuoteFlag ? (
          <button
            onClick={this.handleTaskQuote}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            className="btn_reg_60"
          >
            {quotebtn}
          </button>
        ) : null}
        {this.state.showCOButton ? (
          <button
            onClick={this.handleTaskChangeOrder}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            className="task-details-cust-co"
          >
            {chord}
          </button>
        ) : null}
      </div>
    );

    let taskNotesPanel;
    let bool1 = true;
    if (this.state.taskNotesForAll.length > 0) {
      let retainedOn;
      if (this.props.theTask.quotes.length > 0) {
        let qt = this.props.theTask.quotes[0];
        if (qt.retainerPaidOn) {
          retainedOn = "Retained On: " + qt.retainerPaidOn.substring(0, 10);
        } else {
          retainedOn = "Not yet retained.";
        }
      } else {
        retainedOn = "Not yet retained.";
      }
      taskNotesPanel = (
        <div className="progress-note-row-pos">
          <div className="row task-details-notes-header">
            <div className="col-4 cust-conv-header text-left">
              {this.state.deviceSize === "big" ? (
                <u>Notes & Comments</u>
              ) : (
                <u>Notes</u>
              )}
            </div>
            <div className="col-8 cust-conv-header text-left">{retainedOn}</div>
          </div>
          <div className="row task-detail-notes-header-pos">
            <div className="col-2 text-center task-detail-cust-notes-header">
              Party
            </div>
            <div className="col-2 text-center task-detail-cust-notes-header">
              OnDate{" "}
            </div>
            <div className="col-4 text-left task-detail-cust-notes-header">
              Name
            </div>
            <div className="col-4 text-left task-detail-cust-notes-header">
              Email
            </div>
          </div>
          <div className="fixedsize-cust-task-notes">
            {this.state.taskNotesForAll.map((obj, i) => {
              let odate = obj.onDate.substring(0, 10);
              let sdate;
              if (this.state.deviceSize === "big") sdate = odate;
              else {
                let yr = odate.substring(2, 4);
                let mo = odate.substring(5, 7);
                let dy = odate.substring(8, 10);
                sdate = mo + "." + dy + "." + yr;
              }
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-task-cust-notes-dr"
                        : "row row-task-cust-notes-lt"
                    }
                  >
                    {this.state.deviceSize === "big" ? (
                      <div className="col text-center">
                        <div className="row task-detail-notes-pos">
                          <div className="col-2 text-center task-detail-notes-txt-party">
                            {obj.party.substring(0, 4).toUpperCase() === "BUSI"
                              ? "Business"
                              : "You"}
                          </div>
                          <div className="col-2 text-left task-detail-notes-txt-date">
                            {sdate}
                          </div>
                          {obj.party.substring(0, 4).toUpperCase() ===
                          "BUSI" ? (
                            <div className="col-4 text-left task-detail-notes-txt">
                              {obj.byName}
                            </div>
                          ) : (
                            <div className="col-4 text-left task-detail-notes-txt">
                              &nbsp;
                            </div>
                          )}
                          {obj.party.substring(0, 4).toUpperCase() ===
                          "BUSI" ? (
                            <div className="col-4 text-left task-detail-notes-txt">
                              {obj.byEmail}
                            </div>
                          ) : (
                            <div className="col-4 text-left task-detail-notes-txt">
                              &nbsp;
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div
                            className={
                              obj.party === "business"
                                ? "col text-left task-detail-notes-txt-cust-biz"
                                : "col text-left task-detail-notes-txt-cust-cust"
                            }
                          >
                            <b>Note</b>: &nbsp;{obj.note}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col text-center">
                        <div className="row task-detail-notes-pos">
                          <div className="col-2 text-center task-detail-notes-txt-party">
                            {obj.party.substring(0, 4).toUpperCase() === "BUSI"
                              ? "Biz"
                              : "You"}
                          </div>
                          <div className="col-2 text-center task-detail-notes-txt">
                            {sdate}
                          </div>
                          {obj.party.substring(0, 4).toUpperCase() ===
                          "BUSI" ? (
                            <div className="col-8 text-left task-detail-notes-cust-txt">
                              {obj.byName} | {obj.byEmail}
                            </div>
                          ) : (
                            <div className="col-8 text-left task-detail-notes-cust-txt">
                              &nbsp;
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div
                            className={
                              obj.party === "business"
                                ? "col text-left task-detail-notes-txt-cust-biz"
                                : "col text-left task-detail-notes-txt-cust-cust"
                            }
                          >
                            <b>Note</b>: &nbsp;{obj.note}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      taskNotesPanel = (
        <div className="progress-note-row-pos">
          <div className="text-left cust-conv-header">Progress Note</div>
          <div className="fixedsize-cust-task-notes">
            <div className="text-center comm-task-dtl-nonote">
              No comments exists at this point of time.
            </div>
          </div>
        </div>
      );
    }

    let decision, oktoproceed, wipnotyet, canceled;
    if (this.state.deviceSize === "big") {
      decision = "Decision";
      wipnotyet = "WIP-Negotiate";
      oktoproceed = "OK to proceed";
      canceled = "Cancel";
    } else {
      decision = "Say";
      wipnotyet = "WIP";
      oktoproceed = "OK";
      canceled = "Cncl";
    }

    let decisionPanel;
    if ( this.props.theTask.taskStatus !== 'done') {
      decisionPanel = (
        <div className="row task-cust-decision-row">
          <div className="col text-center radio-font-style">
            <strong>{decision}&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="wip"
                  checked={this.state.proceedDecision === "wip"}
                  onChange={this.handleProceedDecision}
                />{" "}
                {wipnotyet}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="oktoproceed"
                  checked={this.state.proceedDecision === "oktoproceed"}
                  onChange={this.handleProceedDecision}
                />{" "}
                <font color="green">{oktoproceed}</font>
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="cancel"
                  checked={this.state.proceedDecision === "cancel"}
                  onChange={this.handleProceedDecision}
                />{" "}
                <font color="red">{canceled}</font>
              </label>
            </div>
          </div>
        </div>
      );
    }


    let stateColor = "#000000";
    if (sd.taskState === "green") stateColor = "#298c36";
    if (sd.taskState === "yellow") stateColor = "#f7df28";
    if (sd.taskState === "red") stateColor = "#f72f07";

    let description,
      itemname,
      notyetquoted,
      activechn,
      statusnstate,
      customer,
      projmgr,
      newcomment;
    if (this.state.deviceSize === "big") {
      description = "Description";
      itemname = "Item Name";
      // notyetquoted = "Not yet quoted";
      activechn = "Attn: ";
      statusnstate = "Status & State";
      customer = "Customer";
      newcomment = "New Comment";
      projmgr = "Proj. Mgr.";
    } else {
      description = "Desc";
      itemname = "Item";
      // notyetquoted = "No quote yet";
      activechn = "Attn: ";
      statusnstate = "Stats";
      customer = "You";
      projmgr = "PM";
      newcomment = "Comment";
    }

    if (this.props.theTask.controller === "client") {
      if (this.props.theTask.taskStatus === "ready") {
        notyetquoted = "Review the Quote";
      } else {
        notyetquoted = "No quote yet";
      }
    }

    let showDetailsPanel = (
      <div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">Name</div>
          <div className="col-6 text-left task-detail-show-cust-txt">
            {sd.taskName}
          </div>
          <div className="col-4 text-center task-detail-show-cust-txt">
            Id: {sd.taskId}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">
            {description}
          </div>
          <div className="col-10 text-left biz-detail-description-box-x">
            {sd.taskDescription}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">
            {itemname}
          </div>
          <div className="col-10 text-left task-detail-show-cust-txt">
            {sd.itemName}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">
            {statusnstate}
          </div>
          <div className="col-4 text-left task-detail-show-cust-txt">
            {sd.taskStatus} & <font color={stateColor}>{sd.taskState}</font>{" "}
          </div>
          <div className="col-6 text-left task-detail-show-cust-txt">
            {activechn}{" "}
            {this.state.changeOrderSeqno
              ? this.state.changeOrderSeqno
              : notyetquoted}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">
            {customer}
          </div>
          <div className="col-10 text-left task-detail-show-cust-txt">
            {this.props.auth.user.name} ({this.props.auth.user.email} |{" "}
            {this.formatPhoneNumber(this.props.auth.user.cell.number)})
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">
            {projmgr}
          </div>
          <div className="col-10 text-left task-detail-show-cust-txt">
            {this.props.theTask.currentHandlerContact.name} (
            {this.props.theTask.currentHandlerContact.email} |{" "}
            {this.formatPhoneNumber(
              this.props.theTask.currentHandlerContact.cell
            )}
            )
          </div>
        </div>
        {taskNotesPanel}
        <div className="row biz-detail-comment-row">
          <div className="col-2 text-right task-detail-cust-show-lbl">
            {newcomment}
          </div>
          <div className="col-10 text-left">
            <textarea
              name="newTaskNote"
              maxLength="1000"
              placeholder="Notes / message / requests etc."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="comm-task-notes-cust"
              onChange={this.onChange}
              value={this.state.newTaskNote}
              required
            />
          </div>
        </div>
        <div className="row">
          {this.state.showDecisionFlag ? (
            <div className="col-7 text-right">{decisionPanel}</div>
          ) : (
            <div className="col-7 text-center cust-task-control-msg">
              {this.props.theTask.controller === "seller"
                ? "Please wait for the seller to act, or send a message."
                : null}
            </div>
          )}

          <div className="col-5 text-left">{buttonPanel}</div>
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showTaskDetails) {
      outputPanel = (
        <div className="customer-task-details-box">
          <div className="text-center biz-task-details-header">
            Task Details {exitButton} {helpModalButton}
          </div>
          {showDetailsPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center task-detail-cust-msg-err"
                : "text-center task-detail-cust-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.showQuoteMgmt) {
      outputPanel = (
        <div>
          <ViewQuote
            returnToCaller={this.returnFromQuoteView}
            inputdata={this.state.packagedViewData}
          />
        </div>
      );
    }

    if (this.state.showCOFlag) {
      outputPanel = (
        <div>
          <ViewCO
            returnToCaller={this.returnFromChangeOrder}
            theTask={this.state.theTask}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CustTaskDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustTaskDetails)
);
