import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import ReceiveMoneyViaCC from "../../../../../../../account/finance/communities/ReceiveMoneyViaCC";

import "./StoreRecvPay.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBaandaServicePrice = "/routes/architecture/getBaandaServicePrice?";
const getAFreshStoreInfo = "/routes/coop/getAFreshStoreInfo?";
const handlePayments = "/routes/coop/handlePayments";

const transactionFeeServiceName =
  process.env.REACT_APP_FINANCIAL_TRANSACTION_FEE;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class StoreRecvPay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      payRecvConfirm: false,
      amountPaid: 0.0,
      toPayAmount: 0.0,

      paymentMethod: "creditcard",
      checkPayTo: "",
      checkBankName: "",
      accountNumber: "",

      errMsg: "",
      errFlag: false,
      storeDetail: null,

      processFlag: true,
      processDoneFlag: false,
      payBySinglePayCCFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let amountPaid = this.props.toPayAmount.toFixed(2);
    let toPayAmount = this.props.toPayAmount;

    let checkPayTo = "";
    let paymentMethod = "creditcard;";
    if (this.props.payDirection === "storeToComm") {
      checkPayTo = this.props.communityInfo.CheckPayTo;
    } else {
      checkPayTo = this.props.store.storeOwner;
      paymentMethod = "check";
    }

    await this.getTheStoreDetail();

    this.setState({
      deviceSize,
      amountPaid,
      checkPayTo,
      paymentMethod,
      toPayAmount,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTheStoreDetail = async () => {
    let params =
      "communityId=" +
      this.props.store.communityId +
      "&coopStoreId=" +
      this.props.store.coopStoreId;
    let url = baandaServer + getAFreshStoreInfo + params;
    try {
      let strret = await axios.get(url);
      if (strret.data.status === "success") {
        this.setState({
          storeDetail: strret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: strret.data.MSg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getBaandaServiceFeePercent = async () => {
    // let feePercent = 0;
    let status = "success";
    let errMsg = "";
    let errFlag = false;
    let msg = null;
    let param = "serviceName=" + transactionFeeServiceName;
    let url = baandaServer + getBaandaServicePrice + param;
    try {
      let prret = await axios.get(url);
      // console.log("prret.data:", prret.data);
      if (prret.data.status === "success") {
        msg = prret.data.Msg;
        errMsg = "";
        errFlag = false;
      } else {
        errMsg = prret.data.Msg;
        errFlag = true;
      }
    } catch (err) {
      errMsg = err.message;
      errFlag = true;
    }

    await this.setState({
      errMsg,
      errFlag,
    });

    return { status, Msg: msg };
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (value <= this.props.toPayAmount) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          [e.target.name]: value,
          errMsg: "",
          errFlag: false,
        });
      }
    } else {
      this.setState({
        errMsg: "Cannot pay more than you owe.",
        errFlag: true,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  returnToRecvPayable = () => {
    let output;
    if (this.state.processDoneFlag) {
      output = "success";
    } else {
      output = "cancel";
    }

    this.props.returnToCaller(output);
  };

  handlePayRecvConfirm = async () => {
    this.setState((prevstate) => ({
      payRecvConfirm: !prevstate.payRecvConfirm,
    }));
  };

  handlePaymentMethod = async (e) => {
    this.setState({
      paymentMethod: e.target.value,
      payRecvConfirm: false,
    });
  };

  createInputForDB = async () => {
    // 1. Get the baanda fee percent.
    let sfret = await this.getBaandaServiceFeePercent();
    // console.log("sfret:", sfret);
    let baandaFeeType = "";
    let baandaFeePercent = 0;
    let serviceShortDescription = "";
    if (sfret.status === "success") {
      baandaFeeType = sfret.Msg.chargeType;
      baandaFeePercent = sfret.Msg.creditsCharged;
      serviceShortDescription = sfret.Msg.shortDescription;
    }

    let checkInfo = null;
    if (this.state.paymentMethod === "check") {
      // See that this is an input...
      checkInfo = {
        checkNo: "",
        checkDate: new Date(),
        paidTo: this.state.checkPayTo,
        checkBankName: this.state.checkBankName,
        accountNo: this.state.accountNumber,
        routingNo: "",
        checkImage: null,
      };
    }

    let customerCell = "";
    if (this.props.store && this.props.store.contact)
      customerCell = this.props.store.contact.cell;

    let coopStoreId = this.state.storeDetail.coopStoreId;

    let storeInfo = {
      coopStoreId: this.state.storeDetail.coopStoreId,
      displayStoreName: this.state.storeDetail.displayStoreName,
      displayStoreKeeper: this.state.storeDetail.displayStoreKeeper,
      contact: this.state.storeDetail.contact,
      ownerName: this.state.storeDetail.ownerName,
      ownerEmail: this.state.storeDetail.ownerEmail,
      storeName: this.state.storeDetail.storeName,
      ownerBaandaId: this.state.storeDetail.ownerBaandaId,
      paymentCycle: this.state.storeDetail.paymentCycle,
      fixedRent: this.state.storeDetail.agreementMaster.fixedRent,
    };

    let payHandler = "manual";
    if (this.state.paymentMethod === "creditcard") payHandler = "stripe";
    // if ( this.state.paymentMethod === 'crypto' ) payHandler = "tbd";
    // if ( this.state.paymentMethod === 'ach' ) payHandler = "tbd";

    let commCreatorName = "";
    let commCreatorBaandaId = 0;
    let commCreatorEmail = "";
    let commCreatorCell = "";
    this.props.community.members.forEach((mem) => {
      if (mem.role === "creator") {
        commCreatorName = mem.name;
        commCreatorBaandaId = mem.baandaId;
        commCreatorEmail = mem.email;
        commCreatorCell = mem.cell;
      }
    });
    let communityInfo = {
      communityId: this.props.community.communityId,
      commName: this.props.commName,
      communityCheckPayTo: this.props.community.checkPayToName,
      stripeId: this.props.community.stripe.id,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      commCreatorName,
      commCreatorBaandaId,
      commCreatorEmail,
      commCreatorCell,
    };

    let input = {
      communityId: this.props.communityInfo.communityId,
      coopStoreId,
      communityInfo,
      storeInfo,
      finMMDD: this.props.communityInfo.finMMDD,
      finYYYY: this.props.communityInfo.finYYYY,
      communityName: this.props.communityInfo.commName,
      stripeAccId: this.props.communityInfo.stripeId,
      BaandaServiceFeeType: baandaFeeType,
      baandaServiceFeePercent: baandaFeePercent,
      serviceShortDescription,
      toPayAmount: this.state.toPayAmount,
      paidAmount: parseFloat(this.state.amountPaid),
      nextPaymentDate: null,
      theInvoice: null,
      paymentMethod: this.state.paymentMethod,
      checkInfo,
      customerCell, // get the customer cell number
      servicePriceObj: sfret.Msg,
      payHandler,
      paymentFor: "coopExchange",
      payDirection: this.props.payDirection,
      payRecvMsg: this.props.payRecvMsg,
      transactionNote: this.state.transactionNote,
      StripePaymentId: "",
      updated_by_bid: this.props.auth.user.baandaId,
    };

    return input;
  };

  validateInputs = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.paymentMethod === "check") {
      if (this.state.checkPayTo === "") {
        msg = "Check pay to cannot be blank & must have valod name. ";
        isValid = "error";
      }
      if (this.state.checkBankName === "") {
        msg += "Provide a bank name. ";
        isValid = "error";
      }
      if (this.state.accountNumber === "") {
        msg += "Provide an account number. ";
        isValid = "error";
      }
    }

    if (parseFloat(this.state.amountPaid) === 0) {
      msg += "Must pay a positive amount. ";
      isValid = "error";
    }

    return { status: isValid, Msg: msg };
  };

  processThePayment = async () => {
    let valret = await this.validateInputs();
    if (valret.status === "success") {
      let input = await this.createInputForDB();
      // console.log("input:", input);
      // console.log("paymentMethod:", this.state.paymentMethod);
      if (this.state.paymentMethod === "creditcard") {
        this.setState({
          processFlag: false,
          processDoneFlag: false,
          payBySinglePayCCFlag: true,
          input,
        });
      } else {
        let url = baandaServer + handlePayments;
        try {
          let ret = await axios.post(url, input);
          // console.log("ret:", ret);
          if (ret.data.status === "success") {
            this.setState({
              errMsg: "Successfully processed",
              errFlag: false,
              processFlag: false,
              processDoneFlag: true,
            });
          } else {
            this.setState({
              errMsg: ret.data.Msg,
              errFlag: true,
            });
          }
        } catch (err) {
          this.setState({
            errMsg: err.message,
            errFlag: true,
          });
        }
      }
    } else {
      this.setState({
        errMsg: valret.Msg,
        errFlag: true,
      });
    }
  };

  handleExit = (msg) => {
    // console.log("handlExit msg:", msg);

    if (msg === "cancel") {
      this.setState({
        processFlag: true,
        processDoneFlag: false,
        payBySinglePayCCFlag: false,
      });
    }
    if (msg === "success") {
      this.setState({
        processFlag: false,
        processDoneFlag: true,
        payBySinglePayCCFlag: false,
        errMsg: "Successfully processed.",
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("StoreRecvPay...");

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitCompleteTr = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToRecvPayable}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let btnContinue = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.processThePayment}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Continue
      </button>
    );

    let topPanel = (
      <div className="store-recpay-toppanel-pos">
        <div className="row">
          <div className="col-3 text-right store-recvpay-lbl">Community</div>
          <div className="col-9 text-left store-recvpay-txt">
            {this.props.commName}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right store-recvpay-lbl">Store</div>
          <div className="col-9 text-left store-recvpay-txt">
            {this.props.store.storeName}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right store-recvpay-lbl">
            Transaction Msg
          </div>
          <div className="col-9 text-left store-recvpay-txt">
            {this.props.payRecvMsg}
          </div>
        </div>
      </div>
    );

    let payConfirmPanel = (
      <div className="row">
        <div className="col-6 test-left">
          <Checkbox
            checked={this.state.payRecvConfirm}
            onChange={this.handlePayRecvConfirm}
          />
          &nbsp;Check delivered / Wiring done.
        </div>
        {this.state.payRecvConfirm ? (
          <div className="col-6 test-left">{btnContinue}</div>
        ) : (
          <div className="col-6 test-left">&nbsp;</div>
        )}
      </div>
    );

    let selectPayMethod;
    selectPayMethod = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Pay via&nbsp;&nbsp;</strong>
            {this.props.payDirection === "storeTocomm" ? (
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="creditcard"
                    checked={this.state.paymentMethod === "creditcard"}
                    onChange={this.handlePaymentMethod}
                  />{" "}
                  Credit card
                </label>
              </div>
            ) : null}
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="check"
                  checked={this.state.paymentMethod === "check"}
                  onChange={this.handlePaymentMethod}
                />{" "}
                Check
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="ach"
                  checked={this.state.paymentMethod === "ach"}
                  onChange={this.handlePaymentMethod}
                  disabled={true}
                />{" "}
                B-2-B (ACH - coming soon)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="cash"
                  checked={this.state.paymentMethod === "cash"}
                  onChange={this.handlePaymentMethod}
                />{" "}
                Cash
              </label>
            </div>
            {/* <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="crypto"
                  checked={this.state.paymentMethod === "crypto"}
                  onChange={this.handlePaymentMethod}
                  disabled={true}
                />{" "}
                Crypto (FR)
              </label>
            </div> */}
          </div>
        </div>
        <div className="text-center coop-ex-fr-msg">FR: Future Release</div>
      </div>
    );

    let checkPanel;
    if (this.state.paymentMethod === "check") {
      checkPanel = (
        <div>
          <div className="row coexchange-chk-lines">
            <div className="col-3 text-right store-recvpay-lbl">
              Check pay-to
            </div>
            <div className="col-9 text-left">
              <input
                name="checkPayTo"
                type="text"
                value={this.state.checkPayTo}
                onChange={this.onChange}
                className="coex-check-pay-to"
                placeholder=""
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row coexchange-chk-lines">
            <div className="col-3 text-right store-recvpay-lbl">Bank Name</div>
            <div className="col-9 text-left">
              <input
                name="checkBankName"
                type="text"
                value={this.state.checkBankName}
                onChange={this.onChange}
                className="coex-check-bankname"
                placeholder=""
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row coexchange-chk-lines">
            <div className="col-3 text-right store-recvpay-lbl">A/C Number</div>
            <div className="col-9 text-left">
              <input
                name="accountNumber"
                type="text"
                value={this.state.accountNumber}
                onChange={this.onChange}
                className="coex-check-acno"
                placeholder=""
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
        </div>
      );
    }

    let payPanel;
    if (this.props.payFlag) {
      payPanel = (
        <div>
          {selectPayMethod}
          <div className="row coexchange-amt-pos">
            <div className="col-3 text-right store-recvpay-lbl">Amount $</div>
            <div className="col-9 text-left">
              <input
                name="amountPaid"
                type="number"
                value={this.state.amountPaid}
                onChange={this.onChangePrice}
                className="store-payrecv-amt"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row payrecv-note-row-pos">
            <div className="col-3 text-right store-recvpay-lbl">Note</div>
            <div className="col-9 text-left">
              <textarea
                name="transactionNote"
                maxLength="2000"
                placeholder="Keep a note for reference in case of dispute."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="store-payrecv-note"
                onChange={this.onChange}
                value={this.state.transactionNote}
                required
              />
            </div>
          </div>

          {checkPanel}
          {this.state.paymentMethod === "creditcard"
            ? btnContinue
            : payConfirmPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.processFlag) {
      outputPanel = (
        <div>
          <div className="store-comp-tr-header">
            Complete Transaction &nbsp;{helpModalButton}&nbsp;{exitCompleteTr}
          </div>
          {topPanel}

          {payPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center coex-msg-err"
                : "text-center coex-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.processDoneFlag) {
      outputPanel = (
        <div>
          <div className="store-comp-tr-header">
            Transaction Completed&nbsp;{helpModalButton}&nbsp;{exitCompleteTr}
          </div>

          <div
            className={
              this.state.errFlag
                ? "text-center coex-msg-err"
                : "text-center coex-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.payBySinglePayCCFlag) {
      let totalInvoicePanel = (
        <div className="text-center store-recv-cc-pay-head">
          Complete Pament
        </div>
      );

      outputPanel = (
        <div>
          {totalInvoicePanel}
          <ReceiveMoneyViaCC
            inputData={this.state.input}
            deviceSize={this.state.deviceSize}
            paymentAmount={parseFloat(this.state.amountPaid)}
            stripeaccId={this.props.communityInfo.stripeId}
            handleExit={(msg) => this.handleExit(msg)}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

StoreRecvPay.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoreRecvPay)
);
