import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

// import PaymentPending from "./pending/PayPending";
import NewInvoice from "./newInvoice/NewInvoice";
import ListReceivable from "./ListReceivable";
import RecvPayable from "./coopExchange/RecvPayable";
import LegacyTransaction from "./legacy/LegacyTransaction";
import ManageCreditMemo from "./creditmemo/ManageCreditMemo";

import "./ReceiveMain.css";

// const readMeCode = "1112111110";
const readMeCode = "1112111110";

class ReceiveMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receiveOpsType: "receivable",

      // showPendingsFlag: true,
      // showNewInvoiceFlag: false,
      // showRecvPayableFlag: false,
      // showLegacyFlag: false,
    };
  }

  componentDidMount = async () => {};

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleOpsType = async (e) => {
    this.setState({
      receiveOpsType: e.target.value,
    });
  };

  returnFromCoopExchange = () => {
    this.setState({
      receiveOpsType: "receivable",
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ReceiveMain...");

    let modalHelpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let opsTypePanel;
    opsTypePanel = (
      <div className="row rec-main-select-ops-row">
        <div className="col text-center radio-font-style">
          <strong>Operation Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="receivable"
                checked={this.state.receiveOpsType === "receivable"}
                onChange={this.handleOpsType}
              />{" "}
              Receivable
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="creditmemo"
                checked={this.state.receiveOpsType === "creditmemo"}
                onChange={this.handleOpsType}
              />{" "}
              Credit Memo
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.receiveOpsType === "new"}
                onChange={this.handleOpsType}
              />{" "}
              Raise Invoice
            </label>
          </div>
          {this.props.community.communityType === "coop" ? (
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="coopexchange"
                  checked={this.state.receiveOpsType === "coopexchange"}
                  onChange={this.handleOpsType}
                />{" "}
                Coop-Exchange
              </label>
            </div>
          ) : null}
        </div>
      </div>
    );

    let store = null;
    if (this.props.store) store = this.props.store;

    let activePanel;

    if (this.state.receiveOpsType === "receivable") {
      activePanel = (
        <div>
          <ListReceivable
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.receiveOpsType === "new") {
      activePanel = (
        <div>
          <NewInvoice
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            returnToCaller={this.returnFromCoopExchange}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.receiveOpsType === "coopexchange") {
      let storeCheckPayTo = "";
      if (this.props.store) storeCheckPayTo = this.props.store.ownerName;
      activePanel = (
        <div>
          <RecvPayable
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
            returnToCaller={this.returnFromCoopExchange}
            communityCheckPayTo={this.props.community.checkPayToName}
            storeCheckPayTo={storeCheckPayTo}
          />
        </div>
      );
    }

    if (this.state.receiveOpsType === "legacy") {
      activePanel = (
        <div>
          <LegacyTransaction
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
            returnToCaller={this.returnFromCoopExchange}
            communityCheckPayTo={this.props.community.checkPayToName}
          />
        </div>
      );
    }

    if (this.state.receiveOpsType === "creditmemo") {
      activePanel = (
        <div>
          <ManageCreditMemo
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
            returnToCaller={this.returnFromCoopExchange}
            communityCheckPayTo={this.props.community.checkPayToName}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.receiveOpsType === "coopexchange") {
      outputPanel = <div>{activePanel}</div>;
    } else if (this.state.receiveOpsType === "legacy") {
      outputPanel = <div>{activePanel}</div>;
    } else if (this.state.receiveOpsType === "new") {
      outputPanel = <div>{activePanel}</div>;
    } else if (this.state.receiveOpsType === "creditmemo") {
      outputPanel = <div>{activePanel}</div>;
    } else {
      outputPanel = (
        <div>
          <div className="text-center receiveable-header">
            Manage Receivables & Raise Invoices {modalHelpButton}
          </div>
          {opsTypePanel}
          {activePanel}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ReceiveMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReceiveMain));
