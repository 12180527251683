import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./TaskHandler.css";
import axios from "axios";

import CustTaskDetails from "./CustTaskDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getActiveTasks = "/routes/task/getActiveTasks?";

class TaskHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      showTaskListflag: true,
      showTaskDetailflag: false,

      taskStatus: "new",
      tasks: [],

      selectedTask: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    // await this.getTasks();

    await this.setState({
      deviceSize,
      taskStatus: 'new'
    });

    await this.getTasks();
  };

  getTasks = async () => {
    // console.log('in getTasks');
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&taskStatus=" +
      this.state.taskStatus +
      "&customerBaandaId=" +
      this.props.auth.user.baandaId +
      // this.props.auth.user.baandaId +
      "&type=customer";
    let url = baandaServer + getActiveTasks + param;
    // console.log("url:", url);
    try {
      let taskret = await axios.get(url);
      // console.log("taskret.data:", taskret.data);
      if (taskret.data.status === "success") {
        await this.setState({
          tasks: taskret.data.Msg,
        });
      }
    } catch (err) {
      console.log("getTasks err:", err.message);
    }
  };

  handleTaskType = async (e) => {
    // console.log('e.target.value:', e.target.value);
    await this.setState({
      taskStatus: e.target.value,
    });
    await this.getTasks();
  };

  returnFromTaskDetails = () => {
    this.setState({
      showTaskDetailflag: false,
      showTaskListflag: true,
    });
  };

  handleTaskDetails = (obj) => {
    // console.log('obj:', obj);
    this.setState({
      showTaskDetailflag: true,
      showTaskListflag: false,
      selectedTask: obj,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("TaskHandler...");

    let newtask, wip, done, tasktype, canceled;
    // let newtask, wip, quote, active, done, tasktype;
    if (this.state.deviceSize === "small") {
      newtask = "New";
      wip = "WIP";
      // quote = "Qot";
      // active = "Actv";
      done = "Done";
      tasktype = "Type";
      canceled = "Cncl";
    } else {
      newtask = "New Tasks";
      wip = "WorkInProgress";
      // quote = "Quotes & CO";
      // active = "Active";
      done = "Done";
      tasktype = "Task Type";
      canceled = "Canceled";
    }

    let taskStatusPanel;
    taskStatusPanel = (
      <div className="row cust-task-type-row">
        <div className="col text-center radio-font-style">
          <strong>{tasktype}&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.taskStatus === "new"}
                onChange={this.handleTaskType}
              />{" "}
              {newtask}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="wip"
                checked={this.state.taskStatus === "wip"}
                onChange={this.handleTaskType}
              />{" "}
              {wip}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="done"
                checked={this.state.taskStatus === "done"}
                onChange={this.handleTaskType}
              />{" "}
              {done}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="canceled"
                checked={this.state.taskStatus === "canceled"}
                onChange={this.handleTaskType}
              />{" "}
              {canceled}
            </label>
          </div>
        </div>
      </div>
    );

    let taskListPanel;
    let bool = true;
    taskListPanel = (
      <div>
        <div className="row comm-tasklist-head-pos">
          {this.state.deviceSize === "big" ? (
            <div className="col-11 text-left comm-tasklist-cust-header">
              Item Name | Initiated On | Task Name | Status (id)
            </div>
          ) : (
            <div className="col-11 text-left comm-tasklist-cust-header">
              Item Name | Initiated On | Status (id)
            </div>
          )}

          <div className="col-1 text-left comm-tasklist-cust-header-ops">
            Ops
          </div>
        </div>
        <div className="fixedsize-cust-tasklist-box">
          {this.state.tasks.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool
                      ? "row row-col-task-list-dr"
                      : "row row-col-task-list-lt"
                  }
                >
                  {this.state.deviceSize === "big" ? (
                    <div className="col-11 text-left cust-task-list-lbl">
                      {obj.itemName} | {obj.taskInitiatedAt.substring(0, 10)} |{" "}
                      {obj.taskName} | {obj.taskStatus} ({obj.taskId})
                    </div>
                  ) : (
                    <div className="col-10 text-left cust-task-list-lbl">
                      {obj.itemName} | {obj.taskInitiatedAt.substring(0, 10)} |{" "}
                      {obj.taskStatus} ({obj.taskId})
                    </div>
                  )}

                  {this.state.deviceSize === "big" ? (
                    <div className="col-1 text-left">
                      <button
                        onClick={() => this.handleTaskDetails(obj)}
                        className="btn_reg_50 cust-task-but-pos"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  ) : (
                    <div className="col-2 text-left">
                      <button
                        onClick={() => this.handleTaskDetails(obj)}
                        className="btn_reg_50 cust-task-but-pos"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        O
                      </button>
                    </div>
                  )}
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showTaskListflag) {
      outputPanel = (
        <div className="customer-task-box">
          <div className="text-center customer-task-mgmt-header">
            Task Management
          </div>
          {taskStatusPanel}
          {taskListPanel}
        </div>
      );
    }

    if (this.state.showTaskDetailflag) {
      outputPanel = (
        <div>
          <CustTaskDetails
            communityId={this.props.communityId}
            community={this.props.community}
            theTask={this.state.selectedTask}
            returnToCaller={this.returnFromTaskDetails}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

TaskHandler.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskHandler)
);
