import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./UpdateIndividual.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1151160000";

class UpdateIndividual extends Component {
  constructor(props) {
    super(props);

    this.state = {
      individual: {
        object: "individual",
        first_name: "",
        last_name: "",
        maiden_name: "",
        phone: "",

        dob: new Date(),
        address: {
          city: "",
          country: "US",
          line1: "",
          line2: "",
          state: "",
          postal_code: "",
        },
        id_number: "", // SSN
        gender: "",
        ssn_last_4: "",
      },

      ifirstname: "",
      ilastname: "",
      // imaidenName: '',
      iemail: "",
      iphone: "",

      idob: new Date(),

      gender: "male",
      issn: "",
      issnlast4: "",
      icity: "",
      iline1: "",
      iline2: "",
      icountry: "US",
      istate: "",
      ipostal_code: "",

      deviceSize: "",
      individualSkip: false,

      account: "",
      owner: "yes",
      percentOwner: 0,
      individualId: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });

    await this.loadDataInStateOfIndividual();
  };

  loadDataInStateOfIndividual = async () => {
    let indi = this.props.individualData;

    let fname, lname, email, phone;
    let city,
      line1,
      line2,
      state,
      zip = "";
    let idob = new Date();
    let ssn, gender, account, individualId;

    if (indi.first_name) fname = indi.first_name;
    else fname = "";
    if (indi.last_name) lname = indi.last_name;
    else lname = "";
    if (indi.email) email = indi.email;
    else email = this.props.auth.user.email;
    if (indi.phone) phone = indi.phone;
    else phone = "";
    let dob = new Date();
    if (indi.dob) dob = this.transformToJSDate(indi.dob);

    if (indi.address && indi.address.line1) line1 = indi.address.line1;
    else line1 = "";
    if (indi.address && indi.address.line2) line2 = indi.address.line2;
    else line2 = "";
    if (indi.address && indi.address.state) state = indi.address.state;
    else state = "";
    if (indi.address && indi.address.city) city = indi.address.city;
    else city = "";
    if (indi.address && indi.address.postal_code)
      zip = indi.address.postal_code;
    else zip = "";
    if (indi.id_number) ssn = indi.id_number;
    else ssn = "";
    if (indi.gender) gender = indi.gender;
    else gender = "male";
    if (indi.account) account = indi.account;
    else account = "";
    if (indi.individualId) individualId = indi.individualId;
    individualId = "";
    idob = dob;

    this.setState({
      individualSkip: this.props.individualSkip,

      ifirstname: fname,
      ilastname: lname,
      iemail: email,
      iphone: phone,

      idob,
      icity: city,
      iline1: line1,
      iline2: line2,
      icountry: "US",
      istate: state,
      ipostal_code: zip,
      gender: gender,
      issn: ssn,
      // issnlast4: pssn,
      individualId: individualId,
      account: account,
      individual: indi,
    });
  };

  transformToJSDate = (idob) => {
    let nd = new Date();
    if (idob && idob.day && (idob.day > 0) & (idob.month > 0)) {
      let trday = "";
      if (idob.day < 10) trday = "0" + idob.day.toString();
      else trday = idob.day.toString();

      let trmonth = "";
      if (idob.month < 10) trmonth = "0" + idob.month.toString();
      else trmonth = idob.month.toString();

      let tryear = idob.year.toString();
      let drstr = tryear + "/" + trmonth + "/" + trday;
      // console.log("1. drstr:", drstr);
      let trdob = new Date(drstr);
      return trdob;
    } else {
      let d1 = nd.getDate();
      let m1 = nd.getMonth() + 1;
      let yyyy = nd.getFullYear();

      let d2 = d1.toString();
      if (d1 < 10) d2 = "0" + d1.toString();

      let m2 = m1.toString();
      if (m1 < 10) m2 = "0" + m1.toString();

      let drstr = yyyy.toString() + "/" + m2 + "/" + d2;

      return new Date(drstr);
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  loadStateIndividual = async () => {
    let last4 = "";
    if (this.state.issn && this.state.issn.length === 9)
      last4 = this.state.issn.substring(5, 9);
    let individual = {
      object: "individual",
      account: this.state.account,
      individualId: this.state.individualId,
      first_name: this.state.ifirstname,
      last_name: this.state.ilastname,
      email: this.state.iemail,
      phone: this.state.iphone,
      dob: this.state.idob,
      address: {
        city: this.state.icity,
        country: "US",
        line1: this.state.iline1,
        line2: this.state.iline2,
        state: this.state.istate,
        postal_code: this.state.ipostal_code,
      },
      id_number: this.state.issn,
      gender: this.state.gender,
      ssn_last_4: last4,
      verification: {},
    };
    return individual;
  };

  handleBackFromIndividual = async () => {
    let individual = await this.loadStateIndividual();

    this.props.handleBack(individual, this.state.individualSkip);
  };

  handleNextToIndividual = async () => {
    let individual = await this.loadStateIndividual();

    this.props.handleNext(individual, this.state.individualSkip);
  };

  handleDirector = async (e) => {
    await this.setState({
      prdir: e.target.value,
    });
  };

  handleExecutive = async (e) => {
    await this.setState({
      prexec: e.target.value,
    });
  };

  handleGender = async (e) => {
    await this.setState({
      gender: e.target.value,
    });
  };

  handleSkip = async () => {
    await this.setState((prevstate) => ({
      individualSkip: !prevstate.individualSkip,
      // confirmErrorMsg: "On Finish, invoice will be in your email."
    }));
 
  };

  handledob = async (date) => {
    await this.setState({
      idob: date,
    });
  };

  render() {
    // console.log("updateIndividual this.state:", this.state);
    // console.log("updateIndividual this.props:", this.props);
    console.log("UpdateIndividual...");

    let commButtonCompany = (
      <div>
        <button
          onClick={this.handleBackFromIndividual}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleNextToIndividual}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let inpName;
    if (this.state.deviceSize === "small") inpName = "Your Info";
    else inpName = "Your Information";

    let headerPanel = (
      <div className="row">
        <div className="col-5 header_comp_text">
          {inpName}&nbsp;(Individual)
        </div>
        <div className="col-7 ">{commButtonCompany}</div>
      </div>
    );

    let bottomMsgPanel = (
      <div className="row">
        <div className="col message_text">
          &nbsp;<font color="red">*</font>&nbsp; are mandaroty fields. Please
          click 'Next', or Back, buttons to continue.
        </div>
      </div>
    );

    let irfname, irlname, prddob, prssn, igender, iline1, iline2, prskip, przip;
    // imaidenName;
    if (this.state.deviceSize === "small") {
      irfname = "First Name";
      irlname = "Last Name";
      prddob = "Date of Birth";
      igender = "Gender";
      prssn = "SSN/TaxId";
      iline1 = "St. Addr.-1";
      iline2 = "Apt#";
      przip = "Zip";
      prskip = "Check to skip Individual setup.";
      // imaidenName="Maiden Name";
    } else {
      prssn = "last-4 SSN";
      irfname = "First Name";
      irlname = "Last Name";
      prddob = "Date of Birth";
      igender = "Gender";
      prssn = "SSN/TaxId";
      iline1 = "Street Address";
      iline2 = "Apartment# / Suit#";
      przip = "Postal Code (ZIP)";
      prskip = "Check to skip Individual setup.";
      // imaidenName="Maiden Name (optional)"
    }
    let individualPanel;
    individualPanel = (
      <div>
        <div className="input-up-height" />
        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{irfname}{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="ifirstname"
              type="text"
              value={this.state.ifirstname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="First Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{irlname}{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="ilastname"
              type="text"
              value={this.state.ilastname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;Email{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="iemail"
              type="text"
              value={this.state.iemail}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="email"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;Phone#
          </div>
          <div className="col-8 person_input text-left">
            <PhoneInput
              placeholder="Your phone#"
              value={this.state.iphone}
              onChange={(iphone) => this.setState({ iphone })}
              country="US"
              className="input_iphone"
            />
            {/* &nbsp;<font color="red">*</font> */}
          </div>
        </div>
        <div className="row">
          <div className="col-4 text-right person_label">
            <font color="red">*</font>&nbsp;{prddob}{" "}
          </div>
          <div className="col-8 text-left dob_sizing">
            {" "}
            <DatePicker
              selected={this.state.idob}
              onChange={this.handledob}
              name="idob"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
        {/* <div className="dob_header">Relation</div> */}
        <div className="row gender_placement">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{igender}
          </div>
          <div className="col-8 text-left radio-fonts">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="male"
                  checked={this.state.gender === "male"}
                  onChange={this.handleGender}
                />{" "}
                Male
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="female"
                  checked={this.state.gender === "female"}
                  onChange={this.handleGender}
                />{" "}
                Female
              </label>
            </div>
          </div>
        </div>

        <div className="text-center indi_addr_header">Your Address</div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{iline1}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="iline1"
              type="text"
              value={this.state.iline1}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Street Address"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">{iline2}</div>
          <div className="col-8 comp_input text-left">
            <input
              name="iline2"
              type="text"
              value={this.state.iline2}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Apt"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;City
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="icity"
              type="text"
              value={this.state.icity}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="City"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;State
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="istate"
              type="text"
              value={this.state.istate}
              onChange={this.onChange}
              size="2"
              maxLength="2"
              className="input_compstate"
              placeholder="State"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{przip}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="ipostal_code"
              type="text"
              value={this.state.ipostal_code}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="input_comppostal"
              placeholder="postal_code"
            />{" "}
          </div>
        </div>

        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{prssn}{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="issn"
              type="text"
              value={this.state.issn}
              onChange={this.onChange}
              size="9"
              maxLength="9"
              className="input_issn"
              placeholder="9 digits"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-left biz_updt_confirm_person">
            <Checkbox
              checked={this.state.individualSkip}
              onChange={this.handleSkip}
            />
            &nbsp;&nbsp;{prskip}
          </div>
          <div className="col-4">&nbsp;</div>
        </div>
        {bottomMsgPanel}
        <br />
        <br />
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div>
        {headerPanel}
        <div className="company_input_box fixedsize-person-individual">
          {individualPanel}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

UpdateIndividual.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateIndividual));
