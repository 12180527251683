import React, { Component } from "react";

import SliderShowcase from "./slider/SliderShowcase";
import VideoShowcase from "./video/VideoShowcase";

import queryString from "query-string";

class PresentCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      communityId: 0,
      broadcastId: 0,
      opsType: "",
      name: "",
      email: "",
      forBaandaStore: true,
      nonBaandaWebSite: "",
      itemId: 0,
      finalprotocol: "",
      finalhost: "",
      isUrl: "",
    };
  }
  componentDidMount = async () => {
    const values = queryString.parse(this.props.location.search);
    // console.log("1. >> PresentCampaign didmount values:", values);
    if (!this.isObjEmpty(values)) {
      let forBaandaStore = true;
      if (values.forBaandaStore === "false") forBaandaStore = false;
      let nonBaandaWebSite = "";
      if (values.nonBaandaWebSite !== "")
        nonBaandaWebSite = values.nonBaandaWebSite;
      if (nonBaandaWebSite !== "") {
        try {
          new URL(nonBaandaWebSite);
        } catch (err) {
          nonBaandaWebSite = ""; // invalid website url
        }
      }
      let finalprotocol = "";
      let finalhost = "";
      let isUrl = "false";
      if (values.isUrl === "true") {
        finalprotocol = values.finalprotocol;
        finalhost = values.finalhost;
        isUrl = values.isUrl;
      }

      this.setState({
        communityId: values.communityId,
        broadcastId: values.broadcastId,
        opsType: values.opstype,
        name: values.name,
        email: values.email,
        itemId: parseFloat(values.itemId),
        message: "",
        errFlag: false,
        forBaandaStore,
        nonBaandaWebSite,
        finalprotocol,
        finalhost,
        isUrl,
      });
    }
  };

  getTheBroadacast = async () => {};

  isObjEmpty = (obj) => {
    let len = Object.getOwnPropertyNames(obj).length;
    if (len > 0) return false;
    return true;
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PresentCampaign...");

    let outputPanel;

    if (this.state.opsType === "slider" && this.state.broadcastId !== "") {
      outputPanel = (
        <div>
          <SliderShowcase
            broadcastId={this.state.broadcastId}
            communityId={this.state.communityId}
            opsType={this.state.opsType}
            name={this.state.name}
            email={this.state.email}
            forBaandaStore={this.state.forBaandaStore}
            nonBaandaWebSite={this.state.nonBaandaWebSite}
            itemId={this.state.itemId}
            finalprotocol={this.state.finalprotocol}
            finalhost={this.state.finalhost}
            isUrl={this.state.isUrl}
          />
        </div>
      );
    }

    if (this.state.opsType === "video" && this.state.broadcastId !== "") {
      outputPanel = (
        <div>
          <VideoShowcase
            broadcastId={this.state.broadcastId}
            communityId={this.state.communityId}
            opsType={this.state.opsType}
            name={this.state.name}
            email={this.state.email}
            forBaandaStore={this.state.forBaandaStore}
            nonBaandaWebSite={this.state.nonBaandaWebSite}
            itemId={this.state.itemId}
            finalprotocol={this.state.finalprotocol}
            finalhost={this.state.finalhost}
            isUrl={this.state.isUrl}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default PresentCampaign;
