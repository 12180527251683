import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./UpdatePerson.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1151140000";

class UpdatePerson extends Component {
  constructor(props) {
    super(props);

    this.state = {
      person: {
        object: "person",
        first_name: "",
        last_name: "",

        dob: new Date(),
        relationship: {
          director: false, // true or false for the company
          executive: false, // true or false for the company
          owner: false, // true or false for the company
          percent_ownership: null, // number betwee 0 to 100
          // representative: false, // true or false for the company
          title: null, // CEO, CIO, COO, President, etc.
        },
        address: {
          city: "",
          country: "US",
          line1: "",
          line2: "",
          state: "",
          postal_code: "",
        },
        ssn_last_4: "",
      },

      dob: new Date(),
      pfirstname: "",
      plast_name: "",
      pemail: "",
      prphone: "",

      prdob: new Date(),
      prdir: "no",
      prexec: "no",
      prowner: "no",
      praccount_opener: "yes",
      // prrepsent: "no",
      prpercent: 0,
      prtitle: "",
      prssnlast4: "",
      personSsn: "",
      prcity: "",
      prline1: "",
      prline2: "",
      prcountry: "US",
      prstate: "",
      prapostal_code: "",

      deviceSize: "",
      personSkip: false,

      account: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });

    await this.loadDataInStatePerson();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  loadDataInStatePerson = async () => {
    let pd = this.props.personData;
    if (pd.account) {
      let fname = "";
      if (pd.first_name) fname = pd.first_name;

      let lname = "";
      if (pd.last_name) lname = pd.last_name;

      let email = "";
      if (pd.email && pd.email !== "") email = pd.email;
      else email = this.props.auth.user.email;

      let phone = "";
      if (pd.phone) phone = pd.phone;

      let rdir = "no",
        rexec = "no",
        rowner = "no",
        acopenr = "yes",
        ssn;

      if (typeof pd.relationship.director === "boolean") {
        if (pd.relationship.director) rdir = "yes";
      } else if (pd.relationship.director === "yes") rdir = "yes";

      if (typeof pd.relationship.executive === "boolean") {
        if (pd.relationship.executive) rexec = "yes";
      } else if (pd.relationship.executive === "yes") rexec = "yes";

      if (typeof pd.relationship.owner === "boolean") {
        if (pd.relationship.owner) rowner = "yes";
      } else if (pd.relationship.owner === "yes") rowner = "yes";

      if (typeof pd.relationship.account_opener === "boolean") {
        if (!pd.relationship.account_opener) acopenr = "no";
      } else if (pd.relationship.account_opener === "no") acopenr = "no";

      if (pd.id_number) ssn = pd.id_number;
      else ssn = "";

      let rper = 0,
        rtitle = "";
      if (pd.relationship.percent_ownership)
        rper = pd.relationship.percent_ownership;
      if (pd.relationship.title) rtitle = pd.relationship.title;
      let pcity = "",
        pline1 = "",
        pline2 = "",
        pstate = "",
        pzip = "";
      let pad = pd.address;
      if (pad.city) pcity = pad.city;
      if (pad.line1) pline1 = pad.line1;
      if (pad.line2) pline2 = pad.line2;
      if (pad.state) pstate = pad.state;
      if (pad.postal_code) pzip = pad.postal_code;

      let pssn = pd.ssn_last_4;

      await this.setState({
        personSkip: this.props.personSkip,

        pfirstname: fname,
        plast_name: lname,
        pemail: email,
        prphone: phone,

        prdob: pd.dob,

        prdir: rdir,
        praccount_opener: acopenr,
        prexec: rexec,
        prowner: rowner,

        prcity: pcity,
        prline1: pline1,
        prline2: pline2,
        prcountry: "US",
        prstate: pstate,
        prapostal_code: pzip,

        prpercent: rper,

        prtitle: rtitle,
        prssnlast4: pssn,
        prssn: ssn,

        account: this.props.personData.account,
        personId: this.props.personData.personId,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  loadStatePerson = async () => {
    let thisdob = this.state.prdob;
    var dobdd = String(thisdob.getDate()).padStart(2, "0");
    var dobmonth = thisdob.getMonth() + 1;
    let dobmm = dobmonth < 10 ? "0" + dobmonth.toString() : dobmonth.toString();
    var dobyyyy = thisdob.getFullYear().toString();

    let person = {
      object: "person",
      account: this.state.account,
      personId: this.state.personId,
      first_name: this.state.pfirstname,
      last_name: this.state.plast_name,
      email: this.state.pemail,
      phone: this.state.prphone,
      dob: this.state.prdob,
      dobdd,
      dobmm,
      dobyyyy,
      relationship: {
        director: this.state.prdir,
        executive: this.state.prexec,
        owner: this.state.prowner,
        percent_ownership: this.state.prpercent,
        account_opener: this.state.praccount_opener,
        title: this.state.prtitle,
      },
      address: {
        city: this.state.prcity,
        country: "US",
        line1: this.state.prline1,
        line2: this.state.prline2,
        state: this.state.prstate,
        postal_code: this.state.prapostal_code,
      },
      ssn_last_4: this.state.personSsn,
      verification: {},
    };
    return person;
  };

  handleBackFromPerson = async () => {
    let person = await this.loadStatePerson();
    this.props.handleBack(person, this.state.personSkip);
  };

  handleNextToPerson = async () => {
    let person = await this.loadStatePerson();
    this.props.handleNext(person, this.state.personSkip);
  };

  handleDirector = async (e) => {
    await this.setState({
      prdir: e.target.value,
    });
  };

  handleExecutive = async (e) => {
    await this.setState({
      prexec: e.target.value,
    });
  };

  handleAccountOpener = async (e) => {
    await this.setState({
      praccount_opener: e.target.value,
    });
  };

  handleOwner = async (e) => {
    await this.setState({
      prowner: e.target.value,
    });
  };

  handleSkip = async () => {
    await this.setState((prevstate) => ({
      personSkip: !prevstate.personSkip,
    }));
  };

  handledob = async (date) => {
    await this.setState({
      prdob: date,
    });
  };

  render() {
    // console.log("updateSetup this.state:", this.state);
    // console.log("updateSetup this.props:", this.props);
    console.log("UpdatePerson...");

    let commButtonCompany = (
      <div>
        <button
          onClick={this.handleBackFromPerson}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleNextToPerson}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let inpName;
    if (this.state.deviceSize === "small") inpName = "Person";
    else inpName = "Your Relation to the Account";

    let headerPanel = (
      <div className="row">
        <div className="col-5 header_comp_text">{inpName}</div>
        <div className="col-7 ">{commButtonCompany}</div>
      </div>
    );

    let bottomMsgPanel = (
      <div className="row">
        <div className="col message_text">
          &nbsp;<font color="red">*</font>&nbsp; are mandatory fields. Please
          click 'Next', or Back, buttons to continue.
        </div>
      </div>
    );

    let prfname,
      prlname,
      prddob,
      // prdir,
      prexec,
      prowner,
      prperowner,
      // prrepx,
      prtitle,
      prssn,
      praccopener,
      prline1,
      prline2,
      prskip,
      przip;
    if (this.state.deviceSize === "small") {
      prfname = "First Name";
      prlname = "Last Name";
      prddob = "Date of Birth";
      // prdir = "Director";
      prexec = "Executive";
      praccopener = "AC-opener";
      prowner = "Owner";
      prperowner = "% ownership";
      // prrepx = "Represent";
      prtitle = "Title";
      prssn = "last4 ssn";
      prline1 = "St. Addr.-1";
      prline2 = "Apt#";
      przip = "Zip";
      prskip = "Check to skip Person setup.";
    } else {
      // prssn = "last-4 SSN";
      prfname = "First Name";
      prlname = "Last Name";
      prddob = "Date of Birth";
      // prdir = "You are a director";
      prexec = "You are an executive";
      praccopener = "You are the Account Opener";
      // prrepx = "You are a represenative";
      prowner = "You are an owner";
      prperowner = "Your ownership percentage";
      prtitle = "Your Title (e.g. CEO)";
      prssn = "Your SSN";
      prline1 = "Street Address";
      prline2 = "Apartment# / Suit#";
      przip = "Postal Code (ZIP)";
      prskip = "Check to skip Person setup.";
    }
    let personPanel;
    personPanel = (
      <div>
        <div className="input-up-height" />
        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{prfname}{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="pfirstname"
              type="text"
              value={this.state.pfirstname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="First Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{prlname}{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="plast_name"
              type="text"
              value={this.state.plast_name}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="Last Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;Email{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="pemail"
              type="text"
              value={this.state.pemail}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="email"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;Phone#
          </div>
          <div className="col-8 person_input text-left">
            <PhoneInput
              placeholder="Your phone#"
              value={this.state.prphone}
              onChange={(prphone) => this.setState({ prphone })}
              country="US"
              defaultCountry={"US"}
              countries={["US", "CA", "IN"]}
              className="input_prphone"
            />
            {/* &nbsp;<font color="red">*</font> */}
          </div>
        </div>
        <div className="row">
          <div className="col-4 text-right person_label">
            <font color="red">*</font>&nbsp;{prddob}{" "}
          </div>
          <div className="col-8 text-left dob_sizing">
            {" "}
            <DatePicker
              selected={this.state.prdob}
              onChange={this.handledob}
              // onSelect={this.handledob}
              name="prdob"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
        <div className="dob_header">Relation</div>
        <div className="row">
          <div className="col-4 person_label text-right">{praccopener}</div>
          <div className="col-8 text-left radio-fonts">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="yes"
                  checked={this.state.praccount_opener === "yes"}
                  onChange={this.handleAccountOpener}
                />{" "}
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="no"
                  checked={this.state.praccount_opener === "no"}
                  onChange={this.handleAccountOpener}
                />{" "}
                No
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">{prexec}</div>
          <div className="col-8 text-left radio-fonts">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="yes"
                  checked={this.state.prexec === "yes"}
                  onChange={this.handleExecutive}
                />{" "}
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="no"
                  checked={this.state.prexec === "no"}
                  onChange={this.handleExecutive}
                />{" "}
                No
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">{prowner}</div>
          <div className="col-8 text-left radio-fonts">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  name="prowner"
                  className="form-check-input"
                  type="radio"
                  value="yes"
                  checked={this.state.prowner === "yes"}
                  onChange={this.onChange}
                />{" "}
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  name="prowner"
                  className="form-check-input"
                  type="radio"
                  value="no"
                  checked={this.state.prowner === "no"}
                  onChange={this.onChange}
                />{" "}
                No
              </label>
            </div>
          </div>
        </div>
        <div className="text-center comp_addr_header">Person's Address</div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{prline1}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="prline1"
              type="text"
              value={this.state.prline1}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Street Address"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">{prline2}</div>
          <div className="col-8 comp_input text-left">
            <input
              name="prline2"
              type="text"
              value={this.state.prline2}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Apt"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;City
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="prcity"
              type="text"
              value={this.state.prcity}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="City"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;State
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="prstate"
              type="text"
              value={this.state.prstate}
              onChange={this.onChange}
              size="2"
              maxLength="2"
              className="input_compstate"
              placeholder="State"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{przip}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="prapostal_code"
              type="text"
              value={this.state.prapostal_code}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="input_comppostal"
              placeholder="postal_code"
            />{" "}
          </div>
        </div>
        <div className="dob_header">Miscellaneous</div>
        <div className="row">
          <div className="col-4 person_label text-right">{prperowner} </div>
          <div className="col-8 person_input text-left">
            <input
              name="prpercent"
              type="number"
              value={this.state.prpercent}
              onChange={this.onChange}
              size="4"
              maxLength="4"
              min="0"
              max="100"
              className="input_pdob"
              placeholder="% owner"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">{prtitle} </div>
          <div className="col-8 person_input text-left">
            <input
              name="prtitle"
              type="text"
              value={this.state.prtitle}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_ptext"
              placeholder="Title"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 person_label text-right">
            <font color="red">*</font>&nbsp;{prssn}{" "}
          </div>
          <div className="col-8 person_input text-left">
            <input
              name="personSsn"
              type="text"
              value={this.state.personSsn}
              onChange={this.onChange}
              size="9"
              maxLength="9"
              className="input_pdob"
              placeholder="9-digits"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-left biz_updt_confirm_person">
            <Checkbox
              checked={this.state.personSkip}
              onChange={this.handleSkip}
            />
            &nbsp;&nbsp;{prskip}
          </div>
          <div className="col-4">&nbsp;</div>
        </div>
        {bottomMsgPanel}
        <br />
        <br />
      </div>
    );

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="company_input_box fixedsize_person">{personPanel}</div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

UpdatePerson.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdatePerson));
