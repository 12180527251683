import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from "react-loading";

import "./PayForCCPlatform.css";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import axios from "axios";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;

const saveUpdateCommunity = "/routes/create/saveUpdateCommunity";
// const publishBroadcast = "/routes/interact/publishBroadcast"; 
const broadcastACampign = "/routes/interact/broadcastACampign";

const publishAnEvent = "/routes/interact/publishAnEvent";
const postPurchaseCredits = "/routes/finance/postPurchaseCredits";
const postOneShipment = "/routes/finance/postOneShipment";

const CCPayoutForm = (props) => {
  // console.log("2. props:", props);
  // console.log("2. props.stripeaccId:", props.stripeaccId);
  const stripe = useStripe();
  const elements = useElements();

  let amount = props.chargeAmount;

  const [valueof, setValueof] = useState(true);
  const [message, setMessage] = useState(0);
  const [errmsg, setErrmsg] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [errflag, setErrflag] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isShownPay, setIsShownPay] = useState(false);
  const [isShownCancel, setIsShownCancel] = useState(false);
  const [isShownFinito, setIsShownFinito] = useState(false);

  const handleSubmit = async (event) => {
    // console.log("stripeaccId props:", props.stripeaccId);
    // console.log("3. props:", props);
    // console.log("3a. state: isShownPay: ", isShownPay);

    event.preventDefault();
    setMessage(1);
    setDisabled(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.log("error message:", error.message);
    }
    // console.log("props.caller:", props.caller);

    if (!error) {
      // console.log("ZZZzzzzzzzZ props.caller:", props.caller);

      const { id } = paymentMethod;
      // console.log("paymentMethod id:", id);
      setSpinner(true);
      // console.log('props.caller:', props.caller);
      if (props.caller === "broadcast") {
        setErrmsg("");
        setErrflag(false);
        let ret = await publishThisBroadcast(id);
        // console.log('broadcast ret:', ret);
        if (ret.status === "error") {
          // console.log('broadcast err:', ret.Msg);
          setErrmsg(ret.Msg);
          setValueof(false);
          setSpinner(false);
          setErrflag(true);
        }
      }
      if (props.caller === "communitycreation") {
        // console.log('in communitycreation id:', id);
        setErrmsg("");
        setErrflag(false);
        let ret = await publishCommunityCreation(id);
        // console.log('communitycreation ret:', ret);
        if (ret.status === "error") {
          // console.log('broadcast err:', ret.Msg);
          setErrmsg(ret.Msg);
          setValueof(false);
          setSpinner(false);
          setErrflag(true);
        } else {
          // setValueof(false);
          // setSpinner(false);
        }
      }

      if (props.caller === "event") {
        // console.log("EEEEEEEEEEEEEEEEEEEEvent");
        setErrmsg("");
        setErrflag(false);
        let ret = await publishThisEvent(id);
        // console.log("Event ret:", ret);
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
        if (ret.status === "error") {
          // console.log("publishing err:", ret.Msg);
          setErrmsg(ret.Msg);
          setValueof(false);
          setSpinner(false);
          setErrflag(true);
        } else {
          setErrmsg("");
          setErrflag(false);
        }
      }

      if (props.caller === "creditbuy") {
        // console.log("FFFFFFFFFFFFFFFFFFF creditbuy");
        setErrmsg("");
        setErrflag(false);
        let ret = await purchaseShippingCredits(id);
        // console.log("Event ret:", ret);
        // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
        if (ret.status === "success") {
          setValueof(false);
          setSpinner(false);
          setErrmsg("");
          setErrflag(false);
          // doFinito();
          // setValueof(false);
        } else {
          // console.log("I am in the error path ,,,, ret.Msg:", ret.Msg);
          setValueof(false);
          setErrmsg(ret.Msg);
          setSpinner(false);
          setErrflag(true);
          // console.log(">>>>>>>>> errmsg:", errmsg);
        }
      }

      if (props.caller === "individulpost") {
        // console.log("XXXXXXXXXXXXXXX individulpost");
        setErrmsg("");
        setErrflag(false);
        let ret = await purchaseOneShipment(id);
        // console.log("Event ret:", ret);
        // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
        if (ret.status === "success") {
          setValueof(false);
          setSpinner(false);
          setErrmsg("");
          setErrflag(false);
        } else {
          // console.log("xxxx I am in the error path ,,,, ret.Msg:", ret.Msg);
          setValueof(false);
          setErrmsg(ret.Msg);
          setSpinner(false);
          setErrflag(true);
          // console.log(">>>>>>>>> xxxx errmsg:", errmsg);
        }
      }
    } else {
      console.log("createPaymentMethod error:", error);
      setMessage(0);
      setErrmsg(error.message);
    }
  };

  // Community Publishing
  const publishCommunityCreation = async (id) => {
    // console.log('publishCommunityCreation id:', id)
    try {
      let inpdata = props.inputData;
      inpdata.StripePaymentId = id;
      // console.log("publishCommunityCreation inpdata:", inpdata);
      let url = baandaServer + saveUpdateCommunity;
      // console.log("url:", url);
      const { data } = await axios.post(url, inpdata);
      // console.log("data:", data);
      if (data.status === "error") throw new Error(data.Msg);
      setValueof(false);
      setSpinner(false);
      return { staus: "success", Msg: "" };
    } catch (err) {
      console.log("publishCommunityCreation err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  // Broacast Publishing
  const publishThisBroadcast = async (id) => {
    // console.log("----------- publishBroadcast id:", id);
    try {
      let inpdata = props.inputData;
      inpdata.StripePaymentId = id;
      let url = baandaServer + broadcastACampign;
      console.log('inpdata:', inpdata);
      const { data } = await axios.post(url, inpdata);
      // console.log('data:', data);
      if (data.status === "error") throw new Error(data.Msg);
      setValueof(false);
      setSpinner(false);
      setErrmsg("");
      // console.log('@#@#@#@#@ errmsg:', errmsg);

      return { status: "success", Msg: "" };
    } catch (err) {
      console.log("publishBroadcast err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  // Event Publishing
  const publishThisEvent = async (id) => {
    // console.log("----------- publishThisEvent id:", id);
    try {
      let inpdata = props.inputData;
      inpdata.StripePaymentId = id;
      let url = baandaServer + publishAnEvent;
      // console.log("url:", url);
      const { data } = await axios.post(url, inpdata);
      // console.log("data:", data);
      if (data.status === "error") throw new Error(data.Msg);
      setValueof(false);
      setSpinner(false);
      setErrmsg("");

      return { status: "success", Msg: "" };
    } catch (err) {
      console.log("publishThisEvent err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  const purchaseShippingCredits = async (id) => {
    // console.log("purchaseShippingCredits id:", id);
    // console.log("purchaseShippingCredits props:", props);
    try {
      let inpdata = props.inputData;
      inpdata.StripePaymentId = id;
      let url = baandaServer + postPurchaseCredits;
      const { data } = await axios.post(url, inpdata);
      // console.log("data:", data);
      if (data.status === "success") return { status: "success", Msg: "" };
      else return { status: "error", Msg: data.Msg };
    } catch (err) {
      console.log("purchaseShippingCredits err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  const purchaseOneShipment = async (id) => {
    // console.log("purchaseOneShipment id:", id);
    // console.log("purchaseOneShipment props:", props);
    try {
      let inpdata = props.inputData;
      inpdata.StripePaymentId = id;
      let url = baandaServer + postOneShipment;
      const { data } = await axios.post(url, inpdata);
      // console.log("data:", data);
      if (data.status === "success") return { status: "success", Msg: "" };
      else return { status: "error", Msg: data.Msg };
    } catch (err) {
      console.log("purchaseOneShipment err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  const doFinito = async () => {
    // console.log("in doFinito ...");
    let msg = "success";
    if (errflag) msg = "error";
    props.handleExit(msg);
  };

  const doCancel = async () => {
    props.handleExit("cancel");
  };

  let buttonPanel;
  if (message === 0) {
    buttonPanel = (
      <div className="test-pay-button-place">
        <button
          style={{ cursor: disabled ? "default" : "pointer" }}
          type="submit"
          className={isShownPay ? "btn_reg_60" : "btn_reg_60"}
          disabled={!stripe}
          onMouseEnter={() => setIsShownPay(true)}
          onMouseLeave={() => setIsShownPay(false)}
        >
          Pay
        </button>
        <div>{message}</div>
      </div>
    );
  }

  let cancelBtn = (
    <div>
      <button
        onClick={doCancel}
        style={{ cursor: disabled ? "default" : "pointer" }}
        className={isShownCancel ? "btn_reg_80" : "btn_reg_70"}
        onMouseEnter={() => setIsShownCancel(true)}
        onMouseLeave={() => setIsShownCancel(false)}
      >
        Cancel
      </button>
      <div className="platform-cc-payment-description">{props.description}</div>
      <div className="platform-cc-payment-wait">
        Note: Please enter cc info. Cancel and return to continue later.
      </div>
      <div className="platform-cc-payment-wait"></div>
    </div>
  );

  let finishBtn = (
    <div>
      <button
        onClick={doFinito}
        className={isShownFinito ? "btn_reg_80" : "btn_reg_80"}
        style={{ cursor: disabled ? "default" : "pointer" }}
        onMouseEnter={() => setIsShownFinito(true)}
        onMouseLeave={() => setIsShownFinito(false)}
      >
        Finito
      </button>
      {errmsg === "" ? null : (
        <div className="platform-cc-payment-description">
          <font color="red">
            Error: {errmsg}.&nbsp;Contact baanda support at support@baanda.com
          </font>
        </div>
      )}
    </div>
  );

  let btnbelow;
  if (valueof) btnbelow = cancelBtn;
  else btnbelow = finishBtn;

  let uploadingSpin;
  if (spinner) {
    uploadingSpin = (
      <div>
        <ReactLoading
          type={"spokes"}
          color={"#dfe9f0"}
          height={30}
          width={30}
        />
      </div>
    );
  }

  let creditCardPanel;
  // console.log("###### valueof: ", valueof, " -- errFlag:", errflag, ' ');
  if (valueof) {
    creditCardPanel = (
      <div className="text-center">
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "300px", margin: "0 auto" }}
        >
          <div className="cc_platform_msg">
            Amount to pay is ${amount.toFixed(2)}
          </div>
          <CardElement className="card-strip-cc-platform" />
          {buttonPanel}
        </form>
        <div className="text-center spin_platform_position">
          {uploadingSpin}
        </div>
      </div>
    );
  } else if (!errflag) {
    creditCardPanel = (
      <div className="cc-thanks-platform text-center">
        <h3>Thank you for the business</h3>
      </div>
    );
  } else {
    creditCardPanel = (
      <div className="cc-thanks-platform text-center">
        <h3>
          <font color="red">Error ... contact Baanda Support</font>
        </h3>
      </div>
    );
  }

  // return <div>{creditCardPanel}</div>;
  let cconcard = (
    <div className="card-box-cc-signup box-shadow-cc-invoice">
      {creditCardPanel}
    </div>
  );

  let outputPanel;
  if (props.deviceSize === "small") {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col-1 text-center">&nbsp;</div>
          <div className="col-11">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center deliver-to-text">{btnbelow}</div>
        </div>
        <div className="pay-cc-platform-error"> {errmsg}</div>
      </div>
    );
  } else {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col text-center cc-place">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center btn-below-place">{btnbelow}</div>
        </div>
        <div className="pay-cc-platform-error"> {errmsg}</div>
      </div>
    );
  }

  return <div>{outputPanel}</div>;
};

//=====================================================================
// Calls the function, invoked from other cmomponent, to expose CC form.
const PayUsingCC = (props) => {
  console.log("PayForCCPlatform PayUsingCC ...");
  let description = "";
  let amount =
    Math.round(parseFloat(props.inputData.paymentAmount) * 100) / 100;
  let stripeaccId = props.inputData.StripeAccId;
  // console.log('stripeaccId:', stripeaccId);
  if (
    props.caller === "broadcast" ||
    props.caller === "event" ||
    props.caller === "creditbuy" ||
    props.caller === "individulpost"
  ) {
    description = props.inputData.description;
  } else {
    description =
      "Creation Fee for <" + props.inputData.commCaption + "> community";
  }
  
  const stripePromise = loadStripe(stripePublicKey, {
    stripeAccount: stripeaccId,
  });

  return ( 
    <div>
      <Elements stripe={stripePromise}>
        <CCPayoutForm
          chargeAmount={amount}
          stripeaccId={stripeaccId}
          description={description}
          handleExit={(retdata) => props.handleExit(retdata)}
          inputData={props.inputData}
          caller={props.caller}
        />
      </Elements>
    </div>
  );
};

export default PayUsingCC;
