import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./InitFinancialState.css";
import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const setInitFinancialState = "/routes/dashboard/setInitFinancialState";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115100000"; // Change this to align with excel

class InitFinancialState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transType: "receivable",
      refName: "",
      refEmail: "",
      refCell: "",
      refDocId: "",

      invDate: new Date(),
      payBy: new Date(),
      amount: 0,
      description: "",
      confirm: false,
      message: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {};

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleRecConfirm = async () => {
    this.setState((prevstate) => ({
      confirm: !prevstate.confirm,
    }));
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
    });
  };

  handleExposureType = async (e) => {
    this.setState({
      transType: e.target.value,
    });
  };

  handleInvDate = async (date) => {
    this.setState({
      invDate: date,
    });
  };

  handlePaybyDate = async (date) => {
    this.setState({
      payBy: date,
    });
  };

  onChangeMembershipFee = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        itemPrice: val,
        message: "",
      });
    } else {
      this.setState({
        amount: value,
        message: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + parseFloat(communityId);
    let url = baandaServer + getCommunityInfo + parms;
    let community = null;
    try {
      let retData = await axios.get(url);

      if (retData.data.status === "success") {
        community = retData.data.Msg;
      } else {
        await this.setState({
          message: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }

    return community;
  };

  validateEmailSyntax = async (email) => {
    if (email !== "" && typeof email === "string") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    } else return false;
  };

  validate = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.refName === "") {
      isValid = "error";
      msg = "Must provide a Reference Name. ";
    }
    if (this.state.refEmail === "") {
      isValid = "error";
      msg += "| Must provide a Reference Email. ";
    } else {
      let ev = this.validateEmailSyntax(this.state.refEmail);
      if (!ev) {
        isValid = "error";
        msg += "| Invalid email syntax. ";
      }
    }
    if (this.state.amount === 0) {
      isValid = "error";
      msg += "| Must provide an Amount. ";
    }

    return { status: isValid, Msg: msg };
  };

  handleSave = async () => {
    let isValid = await this.validate();

    if (isValid.status === "success") {
      let comm = await this.getCommunityInfo(this.props.communityId);
      let coopStoreId = 0;

      let input = {
        communityId: this.props.communityId,
        coopStoreId,
        activityType: this.state.transType,
        refName: this.state.refName,
        refEmail: this.state.refEmail,
        refCell: this.state.refCell,
        refDocId: this.state.refDocId,
        transactionDate: this.state.invDate,
        remitBy: this.state.payBy,
        amount: parseFloat(this.state.amount),
        description: this.state.description,
        baandaId: this.props.auth.user.baandaId,
        clientProgram: "InitFinancialState",
        clientFunction: "handleSave",
        finYYYY: comm.finYYYY,
        finMMDD: comm.finMMDD,
      };
      let url = baandaServer + setInitFinancialState;

      try {
        let finret = await axios.post(url, input);

        if (finret.data.status === "success") {
          this.setState({
            message: "Successfully updated",
            errFlag: false,
            transType: "receivable",
            refName: "",
            refEmail: "",
            refCell: "",
            refDocId: "",

            invDate: new Date(),
            payBy: new Date(),
            amount: 0,
            description: "",
            confirm: false,
          });
        } else {
          this.setState({
            message: finret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handleSave err:", err.message);
        this.setState({
          message: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        message: isValid.Msg,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("InitFinancialState ...");

    let buttonPanel = (
      <div>
        <div className="row finstate-row-pos">
          <div className="col-6 text-center finstate-confirm">
            <Checkbox
              checked={this.state.confirm}
              onChange={this.handleRecConfirm}
            />{" "}
            Confirm to save (it is final)
          </div>
          <div className="col-6 text-center">
            <button
              onClick={this.openAlertModal}
              className="btn_info_main"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
            {this.state.confirm ? (
              <button
                onClick={this.handleSave}
                className="btn_reg_60"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>

        <div
          className={
            this.state.errFlag
              ? "text-center admin-finstate-msg-err"
              : "text-center admin-finstate-msg"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    let finStatePanel;
    finStatePanel = (
      <div className="fin-state-pos">
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Select Transaction Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="receivable"
                  checked={this.state.transType === "receivable"}
                  onChange={this.handleExposureType}
                />{" "}
                Receivable
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="payable"
                  checked={this.state.transType === "payable"}
                  onChange={this.handleExposureType}
                />{" "}
                Payable
              </label>
            </div>
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-3 text-right fin-state-label">Ref Name</div>
          <div className="col-9 text-left">
            <input
              name="refName"
              type="text"
              value={this.state.refName}
              onChange={this.onChange}
              size="60"
              maxLength="60"
              className="finstate-ref-name"
              placeholder=""
            />
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-3 text-right fin-state-label">Ref Email</div>
          <div className="col-9 text-left">
            <input
              name="refEmail"
              type="text"
              value={this.state.refEmail}
              onChange={this.onChange}
              size="60"
              maxLength="60"
              className="finstate-ref-name"
              placeholder=""
            />
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-3 text-right fin-state-label">Ref Cell</div>
          <div className="col-9 text-left">
            <PhoneInput
              placeholder="Enter Cell number"
              value={this.state.refCell}
              onChange={(refCell) => this.setState({ refCell })}
              country="US"
              className="adm-fin-phoneFlag"
            />
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-3 text-right fin-state-label">
            Ref Document Id
          </div>
          <div className="col-9 text-left">
            <input
              name="refDocId"
              type="text"
              value={this.state.refDocId}
              onChange={this.onChange}
              size="60"
              maxLength="60"
              className="finstate-ref-docid"
              placeholder=""
            />
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-2 text-right fin-state-label">Date</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.invDate}
              onChange={this.handleInvDate}
              name="invDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-2 text-right fin-state-label">Pay By</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.payBy}
              onChange={this.handlePaybyDate}
              name="invDate"
              minDate={moment().add("days", 1).toDate()}
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-3 text-right fin-state-label">Amount</div>
          <div className="col-9 text-left">
            $&nbsp;
            <input
              name="amount"
              type="number"
              value={this.state.amount}
              onChange={this.onChangeMembershipFee}
              className="finstate-amount"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="row fin-state-row-pos">
          <div className="col-3 text-right fin-state-label">Description</div>
          <div className="col-9 text-left">
            <textarea
              name="description"
              maxLength="500"
              placeholder="Describe the transaction."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="finstate-description"
              onChange={this.onChange}
              value={this.state.description}
              required
            />
          </div>
        </div>
        <div className="text-center textarea-comm-msg">
          Describe within 500 characters. Used {this.state.description.length}{" "}
          chars.
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="init-finstate-box">
        <div className="init-finstate-head">Manual Financial State Setup</div>
        {finStatePanel}
        {buttonPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

InitFinancialState.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InitFinancialState)
);
