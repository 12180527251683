import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ReactLoading from "react-loading";

import "./UploadNewTarget.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const uploadNewTarget = "/routes/interact/uploadNewTarget";

class UploadNewTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: "",
      errFlag: false,

      newTargetName: "",
      selectedTargets: [],
      brdSelectHeight: 1,
      targetListNames: [],

      loadingFlag: false,
      showSaveInDBFlag: false,
    };

    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    this.setState({
      targetListNames: this.props.targetListNames,
    });
  };

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  exitUploadNewTarget = () => {
    this.props.returnToCaller();
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  checkWhitespace = (str) => {
    return /\s/.test(str);
  };

  verifyFiles = (file, type, size) => {
    let xten = file.name.split(".").pop();

    let righttype = type.includes(xten.toLowerCase());

    let isValid = true,
      msg = "";
    if (!righttype) {
      isValid = false;
      msg =
        "Incorrect file type (" +
        xten +
        ") selected. Should be " +
        // type.toString() +
        type.join(", ") +
        ". ";
    }
 
    if (file.size > size) {
      isValid = false;
      let sizeinkb = size / 1042;
      msg = msg + `File size must be less then ${sizeinkb} kb.`;
    }

    if (this.checkWhitespace(file.name)) {
      isValid = false;
      msg = msg + ` Remove spaces from the file name <${file.name}>.`;
    }
    return { status: isValid, msg: msg };
  };

  onFilesAdded = async (evt) => {
 
    this.setState({
      loadingFlag: true,
    });
    let fullPath = evt.target.value;
    let filename;
    // let fileExt;
    if (fullPath) {
      filename = fullPath.split(/(\\|\/)/g).pop();
      // fileExt = fullPath.split(".").pop();
    }
 

    const file = evt.target.files[0];
    let isVerified = { status: false, msg: "" };
    if (filename) {
      isVerified = this.verifyFiles(file, ["csv"], 10000);
    }
 
    let errmsg = "";
    let errFlag = false;
    let showSaveInDBFlag = false;
    if (isVerified.status) {
      let reader = new FileReader();

      reader.readAsText(file);

      reader.onload = () => this.storeResults(reader.result);
      showSaveInDBFlag = true;
    } else {
      errmsg = isVerified.msg;
      errFlag = true;
    }

    this.setState({
      loadingFlag: false,
      errMsg: errmsg,
      errFlag,
      showSaveInDBFlag,
    });
  };

  storeResults = async (result) => {
    let cat = result;
  
    let output = cat.split("\r\n");
  
    let out = [];
    output.forEach((cc) => {
   
      let zz = cc.split(",");
      if (zz.length > 0) out.push(cc);
    });

    // eliminate blank lines
    let finalOut = [];
    out.forEach((fo) => {
      if (fo !== "") finalOut.push(fo);
    });

    this.setState({
      loadedFile: finalOut,
    });
  };

  validateToSave = () => {
    let msg = "";
    let state = "success";
    if (this.state.newTargetName.length < 5) {
      msg = "New Target must be at least 5 characters long. ";
      state = "error";
    } else if ( this.state.newTargetName === 'customer') {
      msg = "customer as target name is reserved. ";
      state = "error";
    } 
    
    else {
      this.state.targetListNames.forEach((tln) => {
        if (tln.targetListName === this.state.newTargetName) {
          msg += "Target name exists. Please pick a unique name.";
          state = "error";
        }
      });
    }

    return { state, Msg: msg };
  };

  handleSaveInDB = async () => {
 
    let isVal = this.validateToSave();
  
    if (isVal.state === "success") {
      let input = {
        communityId: this.props.communityId,
        newTargetName: this.state.newTargetName,
        loadedFile: this.state.loadedFile,
        baandaId: this.props.auth.user.baandaId,
        loadtime: new Date(),
        targetFrom: 'upload'
      };
      try {
        let url = baandaServer + uploadNewTarget;
 
        let ldret = await axios.post(url, input);
        if (ldret.data.status === "success") {
          this.setState({
            errMsg: "Successfully Saved",
            errFlag: false,
            loadedFile: [],
            newTargetName: "",
            showSaveInDBFlag: false,
            targetListNames: ldret.data.Msg
          });
        } else {
          this.setState({
            errMsg: ldret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('UploadNewTarget...');

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <ReactLoading
          type={"spokes"}
          color={"#237547"}
          height={25}
          width={25}
          className="comm-entry-spinner"
        />
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitUploadNewTarget}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let saveButton;
    if (this.state.showSaveInDBFlag && this.state.newTargetName !== "") {
      saveButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleSaveInDB}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
      );
    }

    let newTargetNamePanel;
    newTargetNamePanel = (
      <input
        name="newTargetName"
        type="text"
        value={this.state.newTargetName}
        onChange={this.onChange}
        size="25"
        maxLength="25"
        className="merge-tgt-new-name"
        placeholder="Name in 25"
      />
    );

    let fileUploadPanel;
    fileUploadPanel = (
      <div>
        <div className="text-center">
          <input
            ref={this.fileInputRef}
            className="btn_reg_fl"
            type="file"
            // multiple
            onChange={this.onFilesAdded}
          />
        </div>
        {/* {this.state.loadedFile.length > 0 ? priceGrpButton : null} */}
        <div
          className={
            this.state.priceErrFlag
              ? "text-center price-quote-msg-err"
              : "price-quote-msg"
          }
        >
          {this.state.priceErrMsg}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="">
        <div className="row brd-merge-top-pos">
          <div className="col-8 text-right ">
            <b>New Target:</b>&nbsp;{newTargetNamePanel}
          </div>
          <div className="text-left">{backButton} &nbsp;</div>
        </div>
        <div className="row brd-merge-top-pos">
          <div className="col-5 text-center ">&nbsp;</div>
          <div className="col-2 text-right ">{uploadingSpin}</div>
          <div className="col-5 text-center ">&nbsp;</div>
        </div>
        <div className="row brd-merge-top-pos">
          <div className="col text-center ">{fileUploadPanel}</div>
        </div>
        <div className="row brd-merge-top-pos">
          <div className="col text-center ">{saveButton}</div>
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center brd-tg-view-msg-err"
              : "text-center brd-tg-view-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

UploadNewTarget.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(mapStateToProps, null)(withRouter(UploadNewTarget));
