import React, { Component } from "react";
// import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ViewCommList from "./view/ViewCommList";
import ViewItemList from "./view/ViewItemList";

import searchPic from "../../images/searchPic1.jpg";

import ModalContainer from "../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../actions/modalActions";
import "../../modal/css/localModal.css";
import "../../modal/css/template.css";

import "./LoginSearch.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
const getGeoLocFromAddr = "/routes/search/getGeoLocFromAddr?";

const geoapi =
  "https://ipapi.co/json/?key=ImpfWF0IIqsEaQrL9tJAlMjZLS89zwG8uZRtvf1DFbB9ksFb47";

// const myptvKey = "VVNfOGVmZDNiMGRjMzQ1NDJmOGE2ZmU1M2VmYTJhZjlmOTI6YWRjNmI1MzEtMTFmMy00OWYxLTkxOTAtNmEwYzZhMzU0MmQ5";

const readMeCode = "1110000000";

class LoginSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mainSearchPath: "business",
      // bizSearchType: "bizname",
      distanceType: "distanceall",
      itemType: "goods",
      itemSearchBy: "itemname",

      businessText: "",
      businessKeyword: "",

      itemTexts: "",
      deviceSize: "big",
      distance: 0,

      outputFlag: "search",
      searchInput: {},

      distanceFrom: "current",

      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "USA",

      currLocation: null,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 480) deviceSize = "small";
    else deviceSize = "big";

    await this.getCurrLocation();

    let distanceFrom = "current";

    this.setState({
      deviceSize,
      distanceFrom,
    });
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToLogin = () => {
    if (this.props.calledBy === "login") {
      this.props.returnToLogin();
    }
    if (this.props.calledBy === "bazzar") {
      this.props.returnToCaller();
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  businessMainSearchPath = async (e) => {
    this.setState({
      mainSearchPath: e.target.value,
    });
  };

  handlebizDistanceType = async (e) => {
    this.setState({
      distanceType: e.target.value,
    });
  };

  handlebizDistanceFrom = async (e) => {
    this.setState({
      distanceFrom: e.target.value,
    });
  };

  handleitemType = async (e) => {
    this.setState({
      itemType: e.target.value,
    });
  };

  handleItemSearchBy = async (e) => {
    this.setState({
      itemSearchBy: e.target.value,
    });
  };

  onChangeDistance = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        distance: val,
      });
    } else {
      await this.setState({
        distance: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  checkAddress = async () => {
    let param =
      "street1=" +
      this.state.street +
      "&city=" +
      this.state.city +
      "&zip=" +
      this.state.postalCode +
      "&state=" +
      this.state.state +
      "&country=" +
      this.state.country;
    let url = baandaServer + easypostAddressCheck + param;
    let status = "success";
    let msg = "";
    try {
      let check = await axios.get(url);
      // console.log("check:", check);
      if (check.data.status === "success") {
        await this.setState({
          message: "",
          errFlag: false,
        });
      } else {
        status = "error";
        msg = check.data.Msg;
      }
    } catch (err) {
      console.log("err:", err.message);
      status = "error";
      msg = err.message;
    }

    return { status, Msg: msg };
  };

  getCurrLocation = async () => {
    // console.log("In getCurrentLocation ...");
    try {
      if (navigator.geolocation) {
        // console.log("in navigator.geolocation getCurrLocation...");
        navigator.geolocation.getCurrentPosition(await this.showPosition);
      } else {
        await this.setState({
          errMsg:
            "Geolocation is not supported by this browser. Trying IP addr.",
          errFlag: true,
        });
        let url = geoapi;
        let retdata = await axios.get(url);
        let rd = retdata.data;
        if (!retdata.data && retdata.data && !retdata.data.asn) {
          await this.setState({
            errMsg:
              "This browser doesn't support geolocation or location by IP addr.",
            errFlag: true,
          });
        } else {
          this.setState({
            currLocation: {
              lat: rd.latitude,
              long: rd.longitude,
            },
          });
        }
      }
    } catch (err) {
      console.log("GerCurrLocation Error ... 225:", err.message);
      this.setState({
        errMSg: err.message,
        errFlag: true,
      });
    }
  };

 

  errCallback = (error) => {
    // console.log("error :", error);
    this.setState({
      errMsg: error,
      errFlag: true,
    });
  };

  showPosition = async (position) => {
    // console.log("position:", position);
    if (position) {
      this.setState({
        currLocation: {
          lat: position.coords.latitude,
          long: position.coords.longitude,
        },
      });
    }
  };

  getTheGeoLocFromAddr = async () => {
    let params =
      "line1=" +
      this.state.street +
      "&city=" +
      this.state.city +
      "&state=" +
      this.state.state +
      "&zip=" +
      this.state.postalCode +
      "&country=usa";
    let url = baandaServer + getGeoLocFromAddr + params;
    let msg = null;
    try {
      let locret = await axios.get(url);
      if (locret.data.status === "success") {
        this.setState({
          errMsg: "",
          errFlag: false,
        });
        msg = { status: "success", Msg: locret.data.Msg };
      } else {
        this.setState({
          errMsg: locret.data.Msg,
          errFlag: true,
        });
        msg = { status: "error", Msg: locret.data.Msg };
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      msg = { status: "error", Msg: err.message };
    }

    return msg;
  };

  validateSearchData = async () => {
    // console.log(
    //   "in validateSearchData ...this.state.distanceFrom:",
    //   this.state.distanceFrom
    // );
    let msg = "";
    let state = "success";
    if (this.state.distanceType === "distance") {
      if (parseFloat(this.state.distance) === 0) {
        msg = "Must provide a positive distance. ";
        state = "error";
      }

      if (this.state.distanceFrom === "fromaddress") {
        // console.log("in fromaddress...");
        let chk = await this.checkAddress();
        // console.log("chk:", chk);
        if (chk.status === "error") {
          state = "error";
          msg = msg + " Invalid address.";
        }
      }
    }

    return { state, msg };
  };

  processSearch = async () => {
    // console.log("in process Search ...");
    let type = "";
    let outputFlag = "";
    let geoLocation = null;
    let itemType = "";
    let isValid = await this.validateSearchData();
    // console.log("isValid:", isValid);
    if (isValid.state === "success") {
      // console.log("in isValid.state:", isValid.state);
      try {
        if (this.state.mainSearchPath === "business") {
          if (this.state.distanceType === "distance") {
            if (this.state.businessText === "") {
              type = "bydistanceonly";
            } else {
              type = "bykeywordanddistance";
            }
          } else {
            type = "bizkeyword";
          }
          outputFlag = "viewcommunities";
        }

        if (this.state.mainSearchPath === "item") {
          type = "itemkeyword";
          outputFlag = "viewitems";
          itemType = this.state.itemType;
        }

        if (this.state.distanceType === "distance") {
          // console.log("in here 306 ...");
          if (this.state.distanceFrom === "current") {
            geoLocation = this.state.currLocation;
          }
          if (this.state.distanceFrom === "fromaddress") {
            // console.log("in here 317 ...");
            let retloc = await this.getTheGeoLocFromAddr();
            // console.log("retloc:", retloc);
            if (retloc.status === "error") throw new Error(retloc.Msg);
            geoLocation = retloc.Msg;
          }
        }

        let input = {
          searchPath: this.state.mainSearchPath,
          businessText: this.state.businessText,
          itemTexts: this.state.itemTexts,
          distanceType: this.state.distanceType,
          distance: Number(this.state.distance),
          itemType,
          type,
          geoLocation,
        };
        // console.log(">>> input:", input);
        // console.log(">>> outputFlag:", outputFlag);
        this.setState({
          outputFlag,
          searchInput: input,
        });
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
        console.log("err:", err.message);
      }
    }
  };

  returnFromCommList = (msg) => {
    // console.log("msg:", msg);
    // console.log("this.props.calledBy:", this.props.calledBy);
    if (msg.type === "search") {
      this.setState({
        outputFlag: "search",
      });
    } else {
      this.setState({
        outputFlag: "",
      });
      if (this.props.calledBy === "login") {
        this.props.returnToLogin(msg);
      }
      if (this.props.calledBy === "bazzar") {
        this.props.returnToCaller(msg);
      }
    }
  };

  render() {
    // console.log("this.state:", this.state);
    // console.log("this.props:", this.props);
    console.log('LoginSearch...');

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToLogin}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.processSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Search"
        ) : (
          <i className="fas fa-search"></i>
        )}
      </button>
    );

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    // let returnToSearch = (
    //   <button
    //     className="btn_reg_70"
    //     type="button"
    //     onClick={this.returnToSearch}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     {this.state.deviceSize === "big" ? (
    //       "Search"
    //     ) : (
    //       <i className="fas fa-search"></i>
    //     )}
    //   </button>
    // );

    let mainSearchType;
    mainSearchType = (
      <div className="row main-search-type-pos">
        <div className="col text-center radio-font-style">
          Looking for &nbsp;&nbsp;
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="business"
                checked={this.state.mainSearchPath === "business"}
                onChange={this.businessMainSearchPath}
              />{" "}
              Business
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="item"
                checked={this.state.mainSearchPath === "item"}
                onChange={this.businessMainSearchPath}
              />{" "}
              Item
            </label>
          </div>
        </div>
      </div>
    );

    let bName, bKeyword, distance; //, iname;
    if (this.state.deviceSize === "big") {
      bName = "Search Text";
      bKeyword = "Keywords";
      distance = "Within";
      // iname = "Item Name";
    } else {
      bName = "Text";
      bKeyword = "Keys";
      distance = "Wthn";
      // iname = "Name";
    }

    let businessTextPanel;
    if (this.state.mainSearchPath === "business") {
      businessTextPanel = (
        <div className="row item-name-panel-pos">
          <div className="col-3 text-right log-biz-search-lbl">{bName}</div>
          <div className="col-9 text-left">
            <input
              name="businessText"
              type="text"
              value={this.state.businessText}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="log-srch-buz-name"
              placeholder="Name in 50 chars or less"
            />
          </div>
        </div>
      );
    }

    let distanceInMilesPanel;
    if (this.state.distanceType === "distance") {
      distanceInMilesPanel = (
        <div className="row srch-distance-pos">
          <div className="col-3 text-right log-biz-search-lbl">{distance}</div>
          <div className="col-9 text-left">
            <input
              name="distance"
              type="number"
              value={this.state.distance}
              onChange={this.onChangeDistance}
              className="log-srch-distance"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            miles
          </div>
        </div>
      );
    }

    let distancePanel;

    distancePanel = (
      <div className="log-search-distance-pos">
        <div className="row ">
          <div className="col text-center radio-font-style">
            Search by &nbsp;&nbsp;
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="distance"
                  checked={this.state.distanceType === "distance"}
                  onChange={this.handlebizDistanceType}
                  disabled={this.state.mainSearchPath === "item" ? true : false}
                />{" "}
                By distance
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="distanceall"
                  checked={this.state.distanceType === "distanceall"}
                  onChange={this.handlebizDistanceType}
                />{" "}
                All
              </label>
            </div>
          </div>
        </div>
        {distanceInMilesPanel}
      </div>
    );

    let distanceFromPanel;
    if (this.state.distanceType === "distance") {
      distanceFromPanel = (
        <div className="log-search-distance-pos">
          <div className="row ">
            <div className="col text-center radio-font-style">
              From Location&nbsp;&nbsp;
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="current"
                    checked={this.state.distanceFrom === "current"}
                    onChange={this.handlebizDistanceFrom}
                    disabled={!this.state.currLocation ? true : false}
                  />{" "}
                  Current
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="fromaddress"
                    checked={this.state.distanceFrom === "fromaddress"}
                    onChange={this.handlebizDistanceFrom}
                  />{" "}
                  Address
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let addressPanel;
    if (this.state.distanceFrom === "fromaddress") {
      addressPanel = (
        <div className="log-srch-from-adr-pos">
          <div className="row">
            <div className="col-3 text-right srch-adr-lbl">Street</div>
            <div className="col-9 text-left">
              <input
                name="street"
                type="text"
                value={this.state.street}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="log-srch-street"
                placeholder=""
              />
            </div>
          </div>
          <div className="row log-srch-adr-lines">
            <div className="col-3 text-right srch-adr-lbl">City</div>
            <div className="col-9 text-left">
              <input
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="log-srch-city"
                placeholder=""
              />
            </div>
          </div>
          <div className="row log-srch-adr-lines">
            <div className="col-2 text-right srch-adr-lbl">State</div>
            <div className="col-4 text-left">
              <input
                name="state"
                type="text"
                value={this.state.state}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="log-srch-state"
                placeholder=""
              />
            </div>
            <div className="col-2 text-right srch-adr-lbl">Zip</div>
            <div className="col-4 text-left">
              <input
                name="postalCode"
                type="text"
                value={this.state.postalCode}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="log-srch-zip"
                placeholder=""
              />
            </div>
          </div>
        </div>
      );
    }

    let businessTypes;
    if (this.state.mainSearchPath === "business") {
      businessTypes = <div>{businessTextPanel}</div>;
    }

    let itemTextPanel;

    itemTextPanel = (
      <div className="row item-name-panel-pos">
        <div className="col-3 text-right log-biz-search-lbl">{bKeyword}</div>
        <div className="col-9 text-left">
          <input
            name="itemTexts"
            type="text"
            value={this.state.itemTexts}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="log-srch-buz-name"
            placeholder=""
          />
        </div>
      </div>
    );

    let itemTypePanel;
    if (this.state.mainSearchPath === "item") {
      itemTypePanel = (
        <div>
          <div className="row ">
            <div className="col text-center radio-font-style">
              <strong>Item Type&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="goods"
                    checked={this.state.itemType === "goods"}
                    onChange={this.handleitemType}
                  />{" "}
                  Goods
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="service"
                    checked={this.state.itemType === "service"}
                    onChange={this.handleitemType}
                  />{" "}
                  Services
                </label>
              </div>
            </div>
          </div>
          {itemTextPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.outputFlag === "search") {
      outputPanel = (
        <div
          className={
            this.props.calledBy === "login"
              ? "login-search-bazzar-box"
              : "inside-search-bazzar-box"
          }
        >
          <div className="text-center">
            <img src={searchPic} className="bazaar-search-img" alt="" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.props.calledBy === "login" ? backButton : null}&nbsp;
            {infoButton}
          </div>
          <div className="login-search-input-box">
            {mainSearchType}
            {businessTypes}
            {itemTypePanel}
            {distancePanel}
            {distanceFromPanel}
            {addressPanel}
          </div>
          <div className="row srch-search-btn-pos">
            <div className="col text-center">{searchButton}</div>
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center srch-message-err"
                : "text-center srch-message"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }
    if (this.state.outputFlag === "viewcommunities") {
      outputPanel = (
        <div>
          <ViewCommList
            returnToCaller={(msg) => this.returnFromCommList(msg)}
            searchInput={this.state.searchInput}
          />
        </div>
      );
    }

    if (this.state.outputFlag === "viewitems") {
      outputPanel = (
        <div>
          <ViewItemList
            returnToCaller={(msg) => this.returnFromCommList(msg)}
            searchInput={this.state.searchInput}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

LoginSearch.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginSearch)
);

// export default LoginSearch;
