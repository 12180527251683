import React, { Component } from "react";

import axios from "axios";

import "./SliderShowcase.css";

import ImageSlider from "../../utils/campaign/ImageSlider";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getABroadcast = "/routes/interact/getABroadcast?";
const prepProspectResponse = "/routes/interact/broadcastResponse";

class SliderShowcase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      showReviewFlag: true,

      bannerLocation: "",
      imageLocation: "",

      introbgColor: "#fff",
      isPersonal: false,
      salute: "",
      signoff: "",

      footer: null,

      bodyForm: null,
      slides: [],
      theBroadcast: null,
      sliderReviewFrameColor: "#000",

      prospectName: "",
      prospectEmail: "",
      prospectCity: "",
      prospectState: "",
      prospectNote: "",
      prospectRole: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getTheBroadcast();

    let bannerLocation = "";
    let introbgColor = "";
    let isPersonal = false;
    let salute = "";
    let bodyForm = null;
    let sliderReviewFrameColor = "#000";

    let bmc = this.state.theBroadcast.mailComposition;
    let slides = [];
    if (this.state.theBroadcast && bmc) {
      if (bmc.fileUploads.length > 0) {
        bmc.fileUploads.forEach((obj) => {
          if (obj.compositionComponent === "banner") {
            bannerLocation = obj.location;
          }
          if (obj.compositionComponent === "frames") {
            slides.push(obj);
          }
        });
      }

      introbgColor = "white";
      if (bmc.interactionType === "personal") {
        isPersonal = true;
        salute = bmc.salute;
      }

      bodyForm = bmc.introBodyFormat;

      if (
        this.state.theBroadcast.presentationFrameColor &&
        this.state.theBroadcast.presentationFrameColor !== ""
      ) {
        sliderReviewFrameColor = this.state.theBroadcast.presentationFrameColor;
      }
    }

    this.setState({
      bannerLocation,
      prospectName: this.props.name,
      prospectEmail: this.props.email,
      introbgColor,
      isPersonal,
      salute,
      bodyForm,
      slides,
      deviceSize,
      sliderReviewFrameColor,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  getTheBroadcast = async () => {
    let params = "broadcastId=" + this.props.broadcastId;
    let url = baandaServer + getABroadcast + params;
    // console.log("url:", url);
    try {
      let brdret = await axios.get(url);
      if (brdret.data.status === "success") {
        this.setState({
          theBroadcast: brdret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: brdret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  sendProspectResponse = async () => {
    let url = baandaServer + prepProspectResponse;
    let input = {
      prospectName: this.state.prospectName,
      prospectEmail: this.state.prospectEmail,
      prospectCity: this.state.prospectCity,
      prospectState: this.state.prospectState,
      prospectNote: this.state.prospectNote,
      prospectRole: this.state.prospectRole,
      communityId: this.props.communityId,
      broadcastId: this.props.broadcastId,
      opsType: this.props.opsType,
    };
    try {
      let brdret = await axios.post(url, input);
      if (brdret.data.status === "success") {
        this.setState({
          errMsg: "Information Sent",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: brdret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  exitFromPopup = () => {
    this.props.returnToCaller();
  };

  sendSupportRequest = async () => {};

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("SliderShowcase...");

    let entryUrl;

    if (this.props.forBaandaStore === "true") {
      entryUrl =
        this.props.finalprotocol +
        "//" +
        this.props.finalhost +
        "/login?communityId=" +
        parseFloat(this.props.communityId) +
        "&broadcastId=" +
        this.props.broadcastId +
        "&opstype=store&email=" +
        this.props.email +
        "&itemId=" +
        this.props.itemId +
        "&calledFrom=web";
    } else {
      entryUrl = this.props.nonBaandaWebSite;
    }

    let bannerPanel;
    if (this.state.bannerLocation) {
      bannerPanel = (
        <div className="text-center">
          <img
            src={this.state.bannerLocation}
            alt="banner"
            className="campaign-show-banner"
          />
        </div>
      );
    }

    let sliderPanel;
    if (this.state.slides.length > 0) {
      sliderPanel = (
        <div className="camp-slider-container">
          <ImageSlider
            slides={this.state.slides}
            domain="campaignshow"
            size={this.state.deviceSize}
          />
        </div>
      );
    }

    let connectPanel;
    if (this.props.forBaandaStore) {
      connectPanel = (
        <div>
          <div className="text-center camp-conn-header">Contact Us</div>
          <div className="row camp-prospect-row-pos">
            <div className="col-3 text-right camp-prospect-lbl">Name</div>
            <div className="col-9 text-left">
              <input
                name="prospectName"
                type="text"
                value={this.state.prospectName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="camp-prospect-inp"
                placeholder="Name in 50 chars"
              />
            </div>
          </div>
          <div className="row camp-prospect-row-pos">
            <div className="col-3 text-right camp-prospect-lbl">Email</div>
            <div className="col-9 text-left">{this.state.prospectEmail}</div>
          </div>
          <div className="row camp-prospect-row-pos">
            <div className="col-3 text-right camp-prospect-lbl">City</div>
            <div className="col-9 text-left">
              <input
                name="prospectCity"
                type="text"
                value={this.state.prospectCity}
                onChange={this.onChange}
                size="35"
                maxLength="35"
                className="camp-prospect-inp"
                placeholder=""
              />
            </div>
          </div>
          <div className="row camp-prospect-row-pos">
            <div className="col-3 text-right camp-prospect-lbl">State</div>
            <div className="col-9 text-left">
              <input
                name="prospectState"
                type="text"
                value={this.state.prospectState}
                onChange={this.onChange}
                size="35"
                maxLength="35"
                className="camp-prospect-inp"
                placeholder=""
              />
            </div>
          </div>
          <div className="row camp-prospect-row-pos">
            <div className="col-3 text-right camp-prospect-lbl">Q?</div>
            <div className="col-9 text-left">
              <textarea
                name="prospectNote"
                maxLength="250"
                placeholder="Ask a question, or make a comment."
                rows={this.state.deviceSize === "big" ? 5 : 3}
                wrap="hard"
                spellCheck="true"
                className="camp-prospect-textarea-inp"
                onChange={this.onChange}
                value={this.state.prospectNote}
                required
              />
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={this.sendProspectResponse}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_reg_90"
            >
              {this.state.deviceSize === "big" ? (
                "Connect"
              ) : (
                <i className="fas fa-link" />
              )}
            </button>
          </div>
          <div className="text-center camp-footnote">
            Someone from the community will respond as soon as possible.
          </div>
          <div className="text-center">
            <a href={entryUrl}>
              <button className="btn_reg_70">
                {this.state.deviceSize === "big" ? (
                  "Store"
                ) : (
                  <i className="fas fa-store" />
                )}
              </button>
            </a>
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center camp-slider-msg-err"
                : "text-center camp-slider-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    } else {
      connectPanel = (
        <div>
          <dib className="text-center camp-website-entry-slider">
            To the Website
          </dib>
          <div className="text-center">
            <a href={entryUrl}>
              <button className="btn_reg_70">
                {this.state.deviceSize === "big" ? (
                  "WebSite"
                ) : (
                  <i className="fas fa-store" />
                )}
              </button>
            </a>
          </div>
        </div>
      );
    }

    let showTemplatePanel;

    if (this.state.deviceSize === "big") {
      showTemplatePanel = (
        <div className="row">
          <div className="col-md-8 camp-show-pos">
            <div
              className="slider-campaign-showcase-box"
              style={{
                backgroundColor: this.state.sliderReviewFrameColor,
              }}
            >
              <div className="row">
                <div className="col">{bannerPanel}</div>
              </div>
              <div className="row camp-slider-pos">
                <div className="col">{sliderPanel}</div>
              </div>
            </div>
          </div>
          <div className="col-md-4 connect-panel-pos">{connectPanel}</div>
        </div>
      );
    } else {
      showTemplatePanel = (
        <div>
          <div className="slider-test-showcase-box">
            <div className="row">
              <div className="col">
                <div
                  className="slider-campaign-showcase-box"
                  style={{
                    backgroundColor: this.state.sliderReviewFrameColor,
                  }}
                >
                  <div className="row camp-slider-pos">
                    <div className="col">{sliderPanel}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row connect-row-pos">
            <div className="col text-center">{connectPanel}</div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = <div>{showTemplatePanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default SliderShowcase;
