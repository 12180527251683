import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { sortArrayOfObjects } from "../../../../utils/sortArrayOfObjects";

import "./CreateStoreQ.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveQuestionnaire = "/routes/dashboard/saveQuestionnaire";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const readMeCode = "1161000000";

class CreateStoreQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reqIdOK: "yes",
      backgroundCheckOk: "yes",

      seqno: 1,
      theQuestion: "",

      questions: [],
      community: null,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let seqno = 1;

    await this.getThisCommunity();

    let reqIdOK = "yes";
    let backgroundCheckOk = "yes";
    let questions = [];
    if (this.state.community && this.state.community.storeQuestionsMaster) {
      reqIdOK = this.state.community.storeQuestionsMaster.reqIdOK;
      backgroundCheckOk =
        this.state.community.storeQuestionsMaster.backgroundCheckOk;
      questions = [...this.state.community.storeQuestionsMaster.questions];
      seqno = this.state.community.storeQuestionsMaster.questions.length + 1;
    }

    this.setState({
      reqIdOK,
      seqno,
      backgroundCheckOk,
      questions,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getThisCommunity = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      if (retData.data.status === "success") {
        this.setState({
          community: retData.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleReqID = async (e) => {
    this.setState({
      reqIdOK: e.target.value,
    });
  };

  handleBackgroundChk = async (e) => {
    this.setState({
      backgroundCheckOk: e.target.value,
    });
  };

  onChangeSeqno = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);
      this.setState({
        seqno: val,
      });
    } else {
      this.setState({
        seqno: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  addTheQuestion = async () => {

    if (this.state.theQuestion !== "") {
      let currQuestions = [...this.state.questions];
      let seq = this.state.seqno;

      let nextQ = {
        seqno: seq,
        question: this.state.theQuestion,
      };
      currQuestions.push(nextQ);

      let sorted = await sortArrayOfObjects(currQuestions, "seqno", "dsc");

      let sn = 1;
      let reSeqArr = [];
      sorted.forEach((kkk) => {
        kkk.seqno = sn;
        reSeqArr.push(kkk);
        sn++;
      });

      this.setState({
        seqno: currQuestions.length + 1,
        questions: reSeqArr,
        theQuestion: ''
      });
    }
  };

  selectThisQToDelete = (obj) => {

    let newseq = 1;
    let newQ = [];
    this.state.questions.forEach((elm) => {
      if (elm.seqno !== obj.seqno) {
        elm.seqno = newseq;
        newQ.push(elm);
        newseq++;
      }
    });
    this.setState({
      seqno: newQ.length + 1,
      questions: newQ,
    });
  };

  saveTheQuestions = async () => {
    let storeQuestionsMaster = {
      reqIdOK: this.state.reqIdOK,
      backgroundCheckOk: this.state.backgroundCheckOk,
      questions: this.state.questions,
    };
    let input = {
      communityId: this.props.communityId,
      storeQuestionsMaster,
    };

    let url = baandaServer + saveQuestionnaire;

    try {
      let qret = await axios.post(url, input);
      if (qret.data.status === "success") {
        this.setState({
          errMsg: "Updated successfully",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: qret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CreateStoreQ...");

    let infoButton;
    infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addButton;
    addButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.addTheQuestion}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        +Add
      </button>
    );

    let saveButton;
    saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.saveTheQuestions}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let reqIdPanel;
    reqIdPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Request for Id OK?&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="yes"
                checked={this.state.reqIdOK === "yes"}
                onChange={this.handleReqID}
              />{" "}
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="no"
                checked={this.state.reqIdOK === "no"}
                onChange={this.handleReqID}
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
    );

    let permitForBackgroundCheck;
    permitForBackgroundCheck = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Background Check OK?&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="yes"
                checked={this.state.backgroundCheckOk === "yes"}
                onChange={this.handleBackgroundChk}
              />{" "}
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="no"
                checked={this.state.backgroundCheckOk === "no"}
                onChange={this.handleBackgroundChk}
              />{" "}
              No
            </label>
          </div>
        </div>
      </div>
    );

    let qPanel;
    qPanel = (
      <div>
        <div className="row">
          <div className="col-2 text-right store-q-lbl">Seq #</div>
          <div className="col-4 text-left">
            <input
              name="seqno"
              type="number"
              value={this.state.seqno}
              onChange={this.onChangeSeqno}
              className="store-q-seqno"
              //   step=".1"
              //   placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-6 text-center store-q-lbl"> {addButton}</div>
        </div>
        <div className="row">
          <div className="col-2 text-right">Question</div>
          <div className="col-10">
            <textarea
              name="theQuestion"
              maxLength="300"
              placeholder=""
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="store-q-textarea"
              onChange={this.onChange}
              value={this.state.theQuestion}
              required
            />
          </div>
        </div>
      </div>
    );

    let qListPanel;
    let bool = true;
    qListPanel = (
      <div>
        <div className="row store-q-list-pos">
          <div className="col-1 text-center store-q-list-header">Ops</div>
          <div className="col-1 text-center store-q-list-header">#</div>
          <div className="col-10 text-left store-q-list-header">Question</div>
        </div>
        <div className="new-q-list-box">
          {this.state.questions.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-qlist-dr" : "row row-col-qlist-lt"
                  }
                >
                  <div className="col-1 text-center">
                    <button
                      className="btn_exit_main"
                      type="button"
                      onClick={() => this.selectThisQToDelete(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-1 text-left adm-pkg-line-text">
                    {obj.seqno}
                  </div>
                  <div className="col-10 text-left adm-pkg-line-text">
                    {obj.question}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="new-coop-q-box">
        <div className="coop-q-heading">
          Co-op Store Induction Questionnaire {infoButton}
        </div>
        <div className="row">
          <div className="col-6">{reqIdPanel}</div>
          <div className="col-6">{permitForBackgroundCheck}</div>
        </div>
        {qPanel}
        {qListPanel}
        <div className="text-center coop-q-save-button-pos">{saveButton}</div>
        <div className={this.state.errFlag ? "text-center coop-q-msg-err" : "text-center coop-q-msg" }>{this.state.errMsg}</div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CreateStoreQ.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateStoreQ));
