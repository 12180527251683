import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./EventDefine.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";
const saveCategories = "/routes/dashboard/saveCategories";
const getEventsByName = "/routes/interact/getEventsByName?";
const getCatagories = "/routes/dashboard/getCatagories?";
const getAnEvent = "/routes/inetract/getAnEvent?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

// const readMeCode = "1145200000";

class EventDefine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      eventName: "",
      shortName: "",
      eventDesc: "",
      saveFlag: false,
      eventId: 0,
      intent: "",
      successCriteria: "",

      eventDefinition: {
        eventName: "",
        shortName: "",
        description: "",
        saveFlag: false,
        eventId: 0,
      },

      categoryType: "",
      category: "",
      eventType: "service",
      catgoryList: [],

      saveModule: "eventdefine",
      errMsg: "",
      errFlag: false,

      theEvent: null,
      copiedEvent: null,
      copyOldEvent: false,
      toCopyEvents: [],
      searchEventName: "",
      selheight: 1,
      selCatheight: 4,
      showCategoryPanel: false,
      specialInstruction: "",

      copyOfEventName: "",
      showClearButton: false,

      searchWords: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    if (this.props.theEvent && this.props.theEvent.eventName !== "") {
      let ped = this.props.theEvent;

      this.setState({
        category: ped.eventCategory,
        eventName: ped.eventName,
        shortName: ped.shortName,
        eventDesc: ped.description,
        eventType: ped.eventType,
        specialInstruction: ped.specialInstruction,
        intent: ped.intent,
        successCriteria: ped.successCriteria,
        eventId: ped.eventId,
        theEvent: ped,
        searchWords: ped.searchWords,
        deviceSize,
      });
    } else {
      this.setState({
        deviceSize,
      });
    }
  };

  validateData = async () => {
    let isValid = true;
    let msg = "";

    if (this.state.eventName === "") {
      isValid = false;
      msg = "Event name is mandatory; ";
    } else if (this.state.eventName === this.state.copyOfEventName) {
      isValid = false;
      msg = "Please change the copied event name; ";
    }
    if (this.state.shortName === "") {
      isValid = false;
      msg = "Event shor name, for calendar, is mandatory; ";
    }

    return { status: isValid, Msg: msg };
  };

  packDataForDB = async () => {
    let input = {
      communityId: this.props.communityId,
      commName: this.props.communityName,
      hostBaandaId: this.props.auth.user.baandaId,
      hostName: this.props.auth.user.name,
      hostEmail: this.props.auth.user.email,
      hostCell: this.props.auth.user.cell.number,
      eventId: this.state.eventId,
      eventName: this.state.eventName,
      eventType: this.state.eventType,
      eventCategory: this.state.category,
      shortName: this.state.shortName,
      description: this.state.eventDesc,
      intent: this.state.intent,
      successCriteria: this.state.successCriteria,
      searchWords: this.state.searchWords,
      specialInstruction: this.state.specialInstruction,
      copiedEvent: this.state.copiedEvent,
      module: this.state.saveModule,
      clientProgram: "EventDefinition.js",
      clientFunction: "saveUpdateEvent",
    };

    return input;
  };

  saveUpdateEvent = async () => {
    let url = baandaServer + saveUpdtEvent;
    let isValid = await this.validateData();
    if (isValid.status) {
      let inp = await this.packDataForDB();
      // console.log('save url:', url, ' input:', inp);
      try {
        let resp = await axios.post(url, inp);

        if (resp.data.status === "success") {
          await this.setState({
            errMsg: "Successfully Defined.",
            saveModule: "eventdefine",
            copiedEvent: null,
            errFlag: false,
            eventId: resp.data.Msg.eventId,
            theEvent: resp.data.Msg.theEvent,
            showClearButton: false,
          });
          this.returnToOrigin();
        } else {
          await this.setState({
            errMsg: resp.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("saveUpdateEvent err:", err.message);
      }
    } else {
      await this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  // openAlertModal = async () => {
  //   console.log("in openAlertModal info:", readMeCode);
  //   this.props.showModal(
  //     {
  //       open: true,
  //       infoId: readMeCode,
  //       closeModal: this.closeModal,
  //     },
  //     "showHelp"
  //   );
  // };

  returnToOrigin = () => {
    this.props.returnToCaller(this.state.theEvent);
  };

  copyOldEvent = async () => {
    await this.setState((prevstate) => ({
      copyOldEvent: !prevstate.copyOldEvent,
    }));
  };

  handleCategoryType = async (e) => {
    let value = e.target.value;
    if (value === "select") {
      await this.getEventCategories();
    }
    await this.setState({
      categoryType: value,
      showCategoryPanel: true,
    });
  };

  getEventCategories = async () => {
    let params = "communityId=" + this.props.communityId + "&categoryOf=event";
    let url = baandaServer + getCatagories + params;
    // console.log('getEventCategories url:', url)
    try {
      let cats = await axios.get(url);
      // console.log('cats:', cats);
      if (cats.data.status === "success") {
        await this.setState({
          catgoryList: cats.data.Msg,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }

    if (document.getElementById("selectEventCategory"))
      document.getElementById("selectEventCategory").value = "DEFAULT";
  };

  saveEventCategory = async () => {
    // console.log("in saveEventCategory");
    let url = baandaServer + saveCategories;
    let input = {
      communityId: this.props.communityId,
      categoryOf: "event",
      category: this.state.category,
      baandaId: this.props.auth.user.baandaId,
    };
    // console.log("url:", url, " input:", input);
    try {
      await axios.post(url, input);
      // console.log("retc:", retc);
    } catch (err) {
      console.log("err:", err.message);
    }

    this.setState({
      showCategoryPanel: false,
    });
  };

  handleEventType = async (e) => {
    this.setState({
      eventType: e.target.value,
      categoryType: "",
    });
  };

  clearCopiedEvent = async () => {
    this.setState({
      eventName: "",
      shortName: "",
      eventDesc: "",
      saveFlag: false,
      eventId: 0,
      intent: "",
      successCriteria: "",
      specialInstruction: "",

      eventDefinition: {
        eventName: "",
        shortName: "",
        description: "",
        saveFlag: false,
        eventId: 0,
      },

      categoryType: "",
      category: "",
      eventType: "nonservice",
      catgoryList: [],

      saveModule: "eventdefine",
      errMsg: "",
      errFlag: false,

      theEvent: null,
      copyOldEvent: false,
      toCopyEvents: [],
      searchEventName: "",
      selheight: 1,
      selCatheight: 4,
      showCategoryPanel: false,
      searchWords: "",

      copyOfEventName: "",
      showClearButton: false,
    });
  };

  getToCopyEvents = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&hostEmail=" +
      this.props.auth.user.email +
      "&eventName=" +
      this.state.searchEventName +
      "&clientProgram=EventDefine&clientFunction=getToCopyEvents";
    let url = baandaServer + getEventsByName + params;

    try {
      let evts = await axios.get(url);
      let el = [];
      if (evts.data.status === "success") {
        el = [...evts.data.Msg];
      }
      this.setState({
        toCopyEvents: el,
      });
    } catch (err) {
      console.log("Err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    if (document.getElementById("tocopyEventSelected"))
      document.getElementById("tocopyEventSelected").value = "DEFAULT";
  };

  onfocus = async () => {
    let selht = 10;

    if (this.state.toCopyEvents.length < 10)
      selht = this.state.toCopyEvents.length + 1;

    this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    this.setState({
      selheight: 1,
    });
  };

  getTheEvent = async (eventId) => {
    let param = "eventId=" + eventId;

    let url = baandaServer + getAnEvent + param;
    // console.log("url:", url);
    let event = null;
    try {
      let eret = await axios.get(url);
      if (eret.data.status === "success") {
        event = eret.data.Msg;
      } else {
        // console.log("Err data:", eret.data.Msg);
        this.setState({
          errMsg: eret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("Err sys:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
    return event;
  };

  SelectEventToCopy = async () => {
    let value = document.getElementById("tocopyEventSelected").value;
    // console.log("value:", value);
    let obj = JSON.parse(value);
    // console.log(">>> obj:", obj);
    // console.log("obj.eventId: ", obj.eventId);
    let event = await this.getTheEvent(obj.eventId);

    event.eventId = 0;
    event.eventStatus = "prepmode";
    event.eventName = event.eventName + " (copy)";

    // console.log("event:", event);

    this.setState({
      copyOfEventName: event.eventName,
      copyOldEvent: false,
      // showClearButton: true,
      eventId: 0,
      copiedEvent: event,
      eventName: event.eventName,
      shortName: event.shortName,
      eventDesc: event.description,
      intent: event.intent,
      category: event.eventCategory,
      eventType: event.eventType,
      successCriteria: event.successCriteria,
      specialInstruction: event.specialInstruction,
      searchWords: event.searchWords,
      saveModule: "copiedNewEvent",
      errMsg: "Copied event. Rename, modify and save to progress",
      errFlag: false,
    });
  };

  SelectACategory = async () => {
    let value = document.getElementById("selectEventCategory").value;
    let obj = JSON.parse(value);
    // console.log("obj:", obj);
    this.setState({
      category: obj.label,
      showCategoryPanel: false,
    });
  };

  removeSelectDropdown = async () => {
    this.setState({
      showCategoryPanel: false,
      categoryType: "",
    });
  };

  exitCopyEvents = async () => {
    this.setState({
      copyOldEvent: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("EventDefine...");

    let buttonPanel = (
      <div>
        <button
          className="btn_reg_70"
          type="button"
          onClick={this.saveUpdateEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>

        <button
          className="btn_reg_70"
          type="button"
          onClick={this.clearCopiedEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Clear
        </button>

        {/* {infoModalButton} */}
      </div>
    );

    let tocopyEventsDropdown = this.state.toCopyEvents.map((obj, i) => {
      return (
        <option key={i + 2} value={JSON.stringify(obj)}>
          {obj.eventName}
        </option>
      );
    });

    let toCopyEventsDropdown;
    toCopyEventsDropdown = (
      <div>
        <div className="row">
          <div className="col">
            <select
              size={this.state.selheight}
              onFocus={this.onfocus}
              onBlur={this.onblur}
              id="tocopyEventSelected"
              name="tocopyEventSelected"
              onChange={this.SelectEventToCopy}
              className="event-to-copy"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select an event to copy
              </option>
              {tocopyEventsDropdown}
            </select>
          </div>
        </div>
      </div>
    );

    let copyEventPanel;
    copyEventPanel = (
      <div className="copy-event-box">
        <div className="text-center copy-event-header">
          Copy from published events
        </div>
        <div className="row">
          <div className="col text-center">
            <input
              name="searchEventName"
              type="text"
              value={this.state.searchEventName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="search-event-name"
              placeholder="Filter by event name (part/full) ..."
            />
            &nbsp;&nbsp;
            <button onClick={this.getToCopyEvents} className="btn_reg_60">
              Filter
            </button>
            <button onClick={this.exitCopyEvents} className="btn_back_main">
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">{toCopyEventsDropdown}</div>
        </div>
      </div>
    );

    let categoryPanel;
    if (this.state.categoryType === "new" && this.state.showCategoryPanel) {
      categoryPanel = (
        <div className="copy-event-box">
          <div className="text-center new-category">
            Define new event category
          </div>
          <div className="row">
            <div className="col-1">&nbsp;</div>
            <div className="col-10 text-center">
              <input
                name="category"
                type="text"
                value={this.state.category}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="new-event-category"
                placeholder="Enter a new event category"
              />
            </div>
            <div className="col-1">&nbsp;</div>
          </div>
          <div className="text-center event-category-btn-pos">
            <button
              className="btn_reg_120"
              type="button"
              onClick={this.saveEventCategory}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Save Category
            </button>
            <button
              className="btn_back_main"
              type="button"
              onClick={this.removeSelectDropdown}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
      );
    }

    let selCatgoryList = this.state.catgoryList.map((obj, i) => {
      return (
        <option key={i + 2} value={JSON.stringify(obj)}>
          {obj.label}
        </option>
      );
    });

    let categoryDropdown;
    categoryDropdown = (
      <div>
        <div className="row">
          <div className="col">
            <select
              size={this.state.selCatheight}
              // onFocus={this.onfocusCategory}
              // onBlur={this.onblurCategory}
              id="selectEventCategory"
              name="selectEventCategory"
              onChange={this.SelectACategory}
              className="category-to-select"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select an category
              </option>
              {selCatgoryList}
            </select>
          </div>
        </div>
      </div>
    );

    if (this.state.categoryType === "select" && this.state.showCategoryPanel) {
      categoryPanel = (
        <div className="copy-event-box">
          <div className="text-center new-category">Select a Category</div>
          {categoryDropdown}
          <div>
            <button
              className="btn_back_main"
              type="button"
              onClick={this.removeSelectDropdown}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
      );
    }

    let categoryTopPanel;
    categoryTopPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Category:&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="select"
                checked={this.state.categoryType === "select"}
                onChange={this.handleCategoryType}
              />{" "}
              Select
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.categoryType === "new"}
                onChange={this.handleCategoryType}
              />{" "}
              New
            </label>
          </div>
          &nbsp;
          {this.state.category === ""
            ? "(Not Specified)"
            : "(" + this.state.category + ")"}
        </div>
        {categoryPanel}
      </div>
    );

    let eventTypePanel;
    if (this.props.store) {
      eventTypePanel = (
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Event Type:&nbsp;&nbsp;</strong> Service (for Catalog)
          </div>
        </div>
      );
    } else {
      eventTypePanel = (
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Event Type:&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="service"
                  checked={this.state.eventType === "service"}
                  onChange={this.handleEventType}
                />{" "}
                Service
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="nonservice"
                  checked={this.state.eventType === "nonservice"}
                  onChange={this.handleEventType}
                />{" "}
                Event (no service rendered)
              </label>
            </div>
          </div>
        </div>
      );
    }

    let describePanel;
    describePanel = (
      <div>
        <div className="row">
          <div className="col-8 text-center textarea-event-def">
            Maximum 1000 characters allowed. Click 'Enter' to paraagraph.
          </div>
          <div className="col-4 text-center textarea-event-def">
            Characters Used = {this.state.eventDesc.length}
          </div>
        </div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">Describe</div>
          <div className="col-9 text-left">
            <textarea
              name="eventDesc"
              maxLength="1000"
              placeholder="Describe your event ..."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="cal-event-desc-define"
              onChange={this.onChange}
              value={this.state.eventDesc}
              required
            />
          </div>
        </div>
      </div>
    );

    let intentNSuccessPanel;
    intentNSuccessPanel = (
      <div>
        <div className="row">
          <div className="col-8 text-center textarea-event-def">
            Maximum 500 characters allowed. Click 'Enter' to paraagraph.
          </div>
          <div className="col-4 text-center textarea-event-def">
            Characters Used = {this.state.intent.length}
          </div>
        </div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">Intent or Focus:</div>
          <div className="col-9 text-left">
            <textarea
              name="intent"
              maxLength="500"
              placeholder="Intent and/or focus ...(optional)"
              rows="1"
              wrap="hard"
              spellCheck="true"
              className="cal-event-desc-define"
              onChange={this.onChange}
              value={this.state.intent}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-center textarea-event-def">
            Maximum 500 characters allowed. Click 'Enter' to paraagraph.
          </div>
          <div className="col-4 text-center textarea-event-def">
            Characters Used = {this.state.successCriteria.length}
          </div>
        </div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">
            Success Criteria:
          </div>
          <div className="col-9 text-left">
            <textarea
              name="successCriteria"
              maxLength="500"
              placeholder="Success criteria ... (optional)"
              rows="1"
              wrap="hard"
              spellCheck="true"
              className="cal-event-desc-define"
              onChange={this.onChange}
              value={this.state.successCriteria}
              required
            />
          </div>
        </div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">Spl Instruction:</div>
          <div className="col-9 text-left">
            <textarea
              name="specialInstruction"
              maxLength="500"
              placeholder="List any information/instruction for attendees here..."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="cal-event-desc-define"
              onChange={this.onChange}
              value={this.state.specialInstruction}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-center textarea-event-def">
            Enter words/phrases separated by comma (max 500 chars)
          </div>
          <div className="col-4 text-left text-search-words">
            Characters Used = {this.state.searchWords.length}
          </div>
        </div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">Search Words:</div>
          <div className="col-9 text-left">
            <input
              name="searchWords"
              type="text"
              value={this.state.searchWords}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="search-words"
              placeholder="Search words/phrases separated by commas"
            />
          </div>
        </div>
      </div>
    );

    let defPanel;
    defPanel = (
      <div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">Event Name</div>
          <div className="col-9 text-left">
            <input
              name="eventName"
              type="text"
              value={this.state.eventName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="cal-event-name"
              placeholder="Maximum 50 characters"
            />
          </div>
        </div>
        <div className="row cal-event-row">
          <div className="col-3 text-right cal-event-lbl">Calendar Name</div>
          <div className="col-6 text-left">
            <input
              name="shortName"
              type="text"
              value={this.state.shortName}
              onChange={this.onChange}
              size="12"
              maxLength="12"
              className="cal-event-short-name"
              placeholder="Max 12 character"
            />
          </div>
          {this.props.store ? (
            <div className="col-3">&nbsp;</div>
          ) : (
            <div className="col-3 text-left cal-event-lbl">
              Copy an Event{" "}
              <Checkbox
                checked={this.state.copyOldEvent}
                onChange={this.copyOldEvent}
              />
              &nbsp;?
            </div>
          )}
        </div>
        {this.state.copyOldEvent ? copyEventPanel : null}
        <div className="row">
          {this.state.eventType === "service" ? (
            <div className="col-md-6 text-center">{categoryTopPanel}</div>
          ) : (
            <div className="col-md-6 text-center">&nbsp;</div>
          )}
          <div className="col-md-6 text-center">{eventTypePanel}</div>
        </div>
        {describePanel}
        {intentNSuccessPanel}
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div>
        {defPanel}
        {buttonPanel}
        <div className="row">
          <div className="col-2">&nbsp;</div>
          <div
            className={
              this.state.errFlag
                ? "col-8 event-define-err"
                : "col-8 event-define-msg"
            }
          >
            {this.state.errMsg}
          </div>
          <div className="col-2">&nbsp;</div>
        </div>
        <div className="row">
          <div className="col-2">&nbsp;</div>
          <div className="col-8 text-left event-def-warning">
            <b>Note:</b>
            <ul>
              <li>Click 'Save' to initiate the event definition. </li>
              <li>Once saved, other operations will appear. </li>
            </ul>
          </div>
          <div className="col-2">&nbsp;</div>
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        {/* <ModalContainer /> */}
      </div>
    );
  }
}

EventDefine.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(
  mapStateToProps,
  null
  // mapDispatchToProps
)(withRouter(EventDefine));
