import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import ReactLoading from "react-loading";

import axios from "axios";

import "./SearchTheMarket.css";

import searchPic from "../../../../../images/searchPic1.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getProfile = "/routes/myaccount/getProfile?";

class SearchTheMarket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      searchType: "storeword",
      originLocationType: "",

      storeSearchText: "",
      latitude: 0,
      longitude: 0,
      loadingFlag: false,
      showlat: false,
      milesFromOrigin: 0,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    // await this.getMyLocation();

    this.setState({
      deviceSize,
    });
  };

  getMyLocation = async () => {
    const location = window.navigator && window.navigator.geolocation;
    this.setState({
      loadingFlag: true,
    });

    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            showlat: true,
            loadingFlag: false,
          });
        },
        (error) => {
          this.setState({
            latitude: "err-latitude",
            longitude: "err-longitude",
          });
        }
      );
    }
  };

  getTheProfile = async () => {
    let param = "email=" + this.props.auth.user.email;
    let url = baandaServer + getProfile + param;
    try {
      let proret = await axios.get(url);
      if (proret.data.status === "success") {
        this.setState({
          // profile: proret.data.Msg,
          // geoInfo: JSON.parse(proret.data.Msg.geoCentricInfo),
          latitude: JSON.parse(proret.data.Msg.geoCentricInfo).latitude,
          longitude: JSON.parse(proret.data.Msg.geoCentricInfo).longitude,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: proret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChangeMiles = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);
      this.setState({
        milesFromOrigin: val,
      });
    } else {
      this.setState({
        milesFromOrigin: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  justExit = () => {
    this.props.returnToCaller("ssss");
  };

  handleSearchType = async (e) => {
    await this.setState({
      searchType: e.target.value,
    });
  };

  handleOriginType = async (e) => {
    let value = e.target.value;
    if (value === "current") await this.getCurrentLocation();
    if (value === "home") await this.getTheProfile();
    await this.setState({
      originLocationType: e.target.value,
    });
  };

  getCurrentLocation = async () => {
    try {
      await this.getMyLocation();
    } catch (err) {
      this.setState({
        loadingFlag: false,
      });
    }
  };


  goSearch = async () => {
    // console.log("this.state.searchType:", this.state.searchType);
    // console.log("storeSearchText:", this.state.storeSearchText);
    let searchObj;
    if ( this.state.searchType === "storeword") {
      searchObj = {
        searchType: this.state.searchType,
        searchText: this.state.storeSearchText,
      };
    }

    if ( this.state.searchType === "distance") {
      searchObj = {
        searchType: this.state.searchType,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        distance: parseFloat(this.state.milesFromOrigin)
      };
    }

    if ( this.state.searchType === "itemword") {
      searchObj = {
        searchType: this.state.searchType,
        searchText: this.state.storeSearchText,
      };
    }

    // console.log('searchObj:', searchObj)
   
    this.props.returnToCaller(searchObj);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('SearchTheMarket...');

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div className="subscription-spinner-pos text-center">
          <ReactLoading
            type={"spokes"}
            color={"#1f3d6b"}
            height={30}
            width={30}
          />
        </div>
      );
    }

    let searchExitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.justExit}
        style={{ cursor: "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchButton = (
      <button
        className="btn_searh_main"
        type="button"
        onClick={this.goSearch}
        style={{ cursor: "pointer" }}
      >
        <i className="fa fa-search-plus" />
      </button>
    );

    let stores, dist, itm, srch, forstore;
    if (this.state.deviceSize === "big") {
      stores = "via words";
      dist = "by distance";
      itm = "Item across stores";
      srch = "Search for";
      forstore = "Search away";
    } else {
      stores = "words";
      dist = "distance";
      itm = "Item";
      srch = "Search";
      forstore = "Search";
    }

    let searchTypePanel;
    searchTypePanel = (
      <div className="row coop-search-type-row">
        <div className="col text-center radio-font-style">
          <strong>{forstore}&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="storeword"
                checked={this.state.searchType === "storeword"}
                onChange={this.handleSearchType}
              />{" "}
              {stores}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="distance"
                checked={this.state.searchType === "distance"}
                onChange={this.handleSearchType}
              />{" "}
              {dist}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="itemword"
                checked={this.state.searchType === "itemword"}
                onChange={this.handleSearchType}
              />{" "}
              {itm}
            </label>
          </div>
        </div>
      </div>
    );

    let storesByWordPanel;
    if (this.state.searchType === "storeword" || this.state.searchType === "itemword") {
      storesByWordPanel = (
        <div>
          <div className="row text-center community-search-pos">
            <div className="col-3 text-right community-search-lbl">{srch}</div>
            <div className="col-9 text-left">
              <input
                name="storeSearchText"
                type="text"
                value={this.state.storeSearchText}
                onChange={this.onChange}
                size="60"
                maxLength="60"
                className="coop-store-search-word"
                placeholder=""
              />
              &nbsp;{searchButton}
            </div>
          </div>
          <div className="text-center coop-store-search-note">
            {" "}
            <b>Note: </b>Enter search words / phrase.{" "}
          </div>
        </div>
      );
    }

    let milesFromLocationPanel;
    milesFromLocationPanel = (
      <div className="row coop-search-distance-row">
        <div className="col-5 text-right coop-miles-ask">
          Within 
        </div>
        <div className="col-7 text-left">
          <input
            name="milesFromOrigin"
            type="number"
            value={this.state.milesFromOrigin}
            onChange={this.onChangeMiles}
            className="coop-miles-txt"
            step=".1"
            placeholder=""
          />&nbsp; miles from here.
        </div>
      </div>
    );

    let locationGetData;
    if (this.state.searchType === "distance") {
      locationGetData = (
        <div className="row coop-search-type-row">
          <div className="col text-center radio-font-style">
            <strong>From&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="current"
                  checked={this.state.originLocationType === "current"}
                  onChange={this.handleOriginType}
                />{" "}
                Current location
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="home"
                  checked={this.state.originLocationType === "home"}
                  onChange={this.handleOriginType}
                />{" "}
                Home
              </label>
            </div>
            {/* <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="distance"
                  checked={this.state.originLocationType === "distance"}
                  onChange={this.handleOriginType}
                />{" "}
                An address
              </label>
            </div> */}
            {this.state.originLocationType === "current" ? (
              <div>
                {this.state.showlat ? (
                  <div className="text-center">
                    <div className="search-coop-curr-geo-location">
                      Your current Geo-location
                    </div>
                    <div> Latitude: {this.state.latitude.toFixed(8)}</div>
                    <div> Longitude: {this.state.longitude.toFixed(8)}</div>
                    {milesFromLocationPanel}
                  </div>
                ) : (
                  <div>{uploadingSpin}&nbsp;&nbsp; Calculating ... </div> // Calculating ...
                )}
              </div>
            ) : null}
            {this.state.originLocationType === "home" ? (
              <div>
                <div className="text-center">
                  <div className="search-coop-curr-geo-location">
                    Your home Geo-location
                  </div>
                  <div> Latitude: {this.state.latitude.toFixed(8)}</div>
                  <div> Longitude: {this.state.longitude.toFixed(8)}</div>
                  {milesFromLocationPanel}
                </div>
              </div>
            ) : null}
            <div className="text-center coop-distance-search-btn-pos">
              {this.state.milesFromOrigin > 0 ? searchButton : null}
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="search-coop-stores-box">
        <div>
          <img src={searchPic} className="bazaar-search-img" alt="" />
          &nbsp;&nbsp;&nbsp;{searchExitButton}
        </div>

        {searchTypePanel}
        {storesByWordPanel}
        {locationGetData}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

SearchTheMarket.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(SearchTheMarket);
