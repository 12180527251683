import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import ModalContainer from "../modal/components/ModalContainer";
import { showModal, hideModal } from "../actions/modalActions";
import "../modal/css/localModal.css";
import "../modal/css/template.css";

// import SearchBazaar from "./SearchBazaar";

import LoginSearch from "../utils/search/LoginSearch";

import "./BazzarFramework.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAllBaandaCommunities = "/routes/create/getAllBaandaCommunities?";
const getRoleOfUserInACommunity = "/routes/create/getRoleOfUserInACommunity?";
const getIfCustomerOfACommunity =
  "/routes/architecture/getIfCustomerOfACommunity?";
const getNumberOfCommunities = "/routes/create/getNumberOfCommunities?";
const searchForCommunities = "/routes/architecture/searchForCommunities?";

const readMeCode = "4000000000";

class BazzarFramework extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      messageBoxFlag: true,
      openStoreFlag: false,
      storeSelected: "",

      storesInMall: [],
      storesGroupd: [],
      stores: [],

      communities: [],
      enterMarketFlag: false,
      showBazzarFlag: true,
      role: "",

      isCustomer: false,

      totalComm: 0,
      toSkip: 0,
      currEndCount: 0,
      prevButtonFlag: false,
      nextButtonFlag: false,

      errMsg: "",
      errFlag: false,

      showBazzarSearchFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    this.setState({
      deviceSize,
    });

    await this.setBlockCount(deviceSize);
    await this.setNumberOfComm();
    await this.setSkipValue(deviceSize);

    let searchCond = null;
    await this.getAllCommunities(searchCond);

    // Get all the store for show
    await this.setStores();
    // Arrange the stores based on device size
    await this.setStoresforMall();
  };

  setBlockCount = async (deviceSize) => {
    let blockCount = 16;
    if (deviceSize === "small") blockCount = 6;
    await this.setState({
      blockCount,
    });
  };

  setNumberOfComm = async () => {
    let url = baandaServer + getNumberOfCommunities;
    try {
      let cnt = await axios.get(url);
      await this.setState({
        totalComm: cnt.data.Msg,
        errMsg: "",
        errFlag: false,
      });
    } catch (err) {
      console.log("BazzarFramework setNumberOfComm err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  setSkipValue = async (direction) => {
    let toSkip = this.state.toSkip;

    if (direction === "prev") {
      toSkip = this.state.toSkip - this.state.blockCount;
      if (toSkip < 0) {
        toSkip = 0;
      }
    }
    if (direction === "next") {
      toSkip = this.state.toSkip + this.state.blockCount;
    }

    this.setState({
      toSkip,
    });
  };

  setPaginationValues = async (deviceSize) => {
    let toSkip = this.state.toSkip;
    let blockCount = 16;
    if (deviceSize === "small") blockCount = 6;
    let prevButtonFlag = false;
    let nextButtonFlag = false;
    if (this.state.totalComm > blockCount) {
      if (toSkip < this.state.totalComm) nextButtonFlag = true;
      if (toSkip - blockCount > 0) prevButtonFlag = true;
    }
    await this.setState({
      prevButtonFlag,
      nextButtonFlag,
    });
  };

  getAllCommunities = async (searchCond) => {
    let param =
      "toSkip=" +
      parseFloat(this.state.toSkip) +
      "&blockCount=" +
      parseFloat(this.state.blockCount) +
      "&condition=" +
      searchCond;
    let url = baandaServer + getAllBaandaCommunities + param;

    try {
      let retcomm = await axios.get(url);
      // console.log("retcomm:", retcomm);
      await this.setState({
        communities: retcomm.data.Msg,
        errMsg: "",
        errFlag: false,
      });
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  setStores = async () => {
    let stores = [];
    this.state.communities.forEach((obj) => {
      let name, desc, btntext;
      if (obj.signBoardShort !== "") {
        name = obj.signBoardShort;
        btntext = obj.signBoardShort;
      } else {
        if (obj.commName.length > 16) {
          name = obj.commName.substring(0, 15);
          btntext = obj.commName.substring(0, 15);
        } else {
          name = obj.commName;
          btntext = obj.commName;
        }
      }
      if (obj.signBoardLong !== "") {
        desc = obj.signBoardLong;
      } else {
        if (obj.commDescription.length > 55)
          desc = obj.commDescription.substring(0, 55);
        else desc = obj.commDescription;
      }

      let location = "";
      obj.fileUploads.forEach((loc) => {
        if (loc.type === "image") location = loc.location;
      });
      let strobj = {
        name,
        desc,
        btntext,
        location,
        communityId: obj.communityId,
        commCaption: obj.commCaption,
        commName: obj.commName,
      };
      stores.push(strobj);
    });

    await this.setState({
      stores,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getRoleOfUserInCommnunity = async (communityId) => {
    // console.log('getRoleOfUserInCommnunity communityId:', communityId);
    let params =
      "communityId=" +
      communityId +
      "&baandaId=" +
      this.props.auth.user.baandaId;
    try {
      let url = baandaServer + getRoleOfUserInACommunity + params;
      // console.log('url:', url);
      let rd = await axios.get(url);
      // console.log('rd.data:', rd.data);
      await this.setState({
        role: rd.data.Msg,
      });
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  openTheStore = async (msg) => {
    // console.log('openThetore msg:', msg);
    // console.log('--------------------------')

    let communityId = 0;
    let commName = "";
    let commCaption = "";
    let itemId = 0;
    if (msg.type === "community") {
      communityId = msg.commmunity.communityId;
      commName = msg.commmunity.commName;
      commCaption = msg.commmunity.signBoardShort;
    }
    if (msg.type === "item") {
      communityId = msg.item.communityId;
      commName = msg.item.commName;
      itemId = msg.item.itemId;
      commCaption = msg.item.signBoardShort;
      // console.log('here ....item');
      // console.log('communityId:', communityId)
      // console.log('commName:', commName)
      // console.log('itemId:', itemId);
      // console.log('commCaption:',commCaption)
    }
    await this.getRoleOfUserInCommnunity(communityId);
    await this.checkIsCustomer(communityId);
    // console.log('========================')
    let retdata = {
      type: msg.type,
      communityId,
      role: this.state.role,
      commCaption,
      commName,
      comingFrom: "bazzar",
      isCustomer: this.state.isCustomer,
      itemId,
    };
    // console.log("retdata:", retdata);
    this.props.returnToCaller(retdata);
  };

  checkIsCustomer = async (comId) => {
    // console.log('checkIsCustomer comId:', comId);
    let param =
      "communityId=" + comId + "&baandaId=" + this.props.auth.user.baandaId;
    let url = baandaServer + getIfCustomerOfACommunity + param;
    // console.log("checkIsCustomer url:", url);
    try {
      let isCust = await axios.get(url);
      // console.log("isCust:", isCust);
      if (isCust.data.status === "success") {
        let isCustomer = isCust.data.Msg;
        let role = this.state.role;
        if (isCustomer) role = "customer";
        await this.setState({
          isCustomer,
          role,
          errMsg: "",
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("checkIsCustomer err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  exitStoreMsg = async () => {
    await this.setState({
      openStoreFlag: false,
      storeSelected: "",
    });
  };

  setStoresforMall = async () => {
    let stores = this.state.stores;

    let allgrparr = [];
    try {
      if (this.state.deviceSize === "small") {
        let i = 0;
        let stgrp = [];
        stores.forEach((obj) => {
          if (i < 2) {
            let strobj = {
              name: obj.name,
              desc: obj.desc,
              btntext: obj.btntext,
              location: obj.location,
              communityId: obj.communityId,
              commCaption: obj.commCaption,
              commName: obj.commName,
            };
            stgrp.push(strobj);
          }

          if (i === 1) {
            allgrparr.push(stgrp);
            stgrp = [];
          }
          i++;
          if (i === 2) i = 0;
        });
        if (i === 1) {
          let strobj = {
            name: "",
            desc: "",
            btntext: "",
            communityOD: 0,
          };
          stgrp.push(strobj);
          allgrparr.push(stgrp);
        }
      } else {
        // console.log("to figure out groups of 4");
        let i = 0;
        let stgrp = [];
        stores.forEach((obj) => {
          // console.log("obj:", obj, " i:", i);
          if (i < 4) {
            let strobj = {
              name: obj.name,
              desc: obj.desc,
              btntext: obj.btntext,
              location: obj.location,
              communityId: obj.communityId,
              commCaption: obj.commCaption,
              commName: obj.commName,
            };
            stgrp.push(strobj);
          }

          if (i === 3) {
            allgrparr.push(stgrp);
            stgrp = [];
          }
          i++;
          if (i === 4) i = 0;
        });
        // console.log(">>>> i:", i);
        if (i < 4) {
          for (var j = i; j < 4; j++) {
            let strobj = {
              name: "",
              desc: "",
              btntext: "",
            };
            stgrp.push(strobj);
          }
          allgrparr.push(stgrp);
        }
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    await this.setState({
      storesInMall: stores,
      storesGroupd: allgrparr,
    });
  };

  handleBazzarSearch = async () => {
    await this.setState({
      messageBoxFlag: true,
      showBazzarSearchFlag: false,
    });
  };

  returnFromSearch = async (searchText) => {
    // console.log('returnFromSearch searchText:', searchText);
    // console.log("return from search searchText:", searchText);

    // await this.filterCommunities(searchText);
    // await this.setStores();
    // await this.setStoresforMall();

    this.openTheStore(searchText);

    this.setState({
      messageBoxFlag: false,
      showBazzarSearchFlag: false,
    });
  };

  filterCommunities = async (searchText) => {
    let param = "searchText=" + searchText;
    let url = baandaServer + searchForCommunities + param;
    // console.log("url:", url);
    try {
      let comret = await axios.get(url);
      // console.log("comret.data:", comret.data);
      if (comret.data.status === "success") {
        let communities = [];
        if (comret.data.Msg) communities = comret.data.Msg;
        this.setState({
          communities,
        });
      } else {
        this.setState({
          errMsg: comret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("BazzarFramework...");

    let searchButton;
    searchButton = (
      <button
        className="btn_searh_main"
        type="button"
        onClick={this.handleBazzarSearch}
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        <i className="fa fa-search-plus" />
      </button>
    );

    let searchPanel;
    if (this.state.messageBoxFlag) {
      // console.log("in searchpanel...");
      searchPanel = (
        <div className="bazzar-sp-pos">
          {/* <div className="bazzar-search-box">  */}
          {/* <SearchBazaar returnToCaller={(obj) => this.returnFromSearch(obj)} /> */}
          <LoginSearch
            returnToCaller={(obj) => this.returnFromSearch(obj)}
            calledBy="bazzar"
          />
        </div>
      );
    }

    if (this.state.openStoreFlag) {
      searchPanel = (
        <div className="bazzar-search-box">
          <div className="text-center bazzar-message">
            {this.state.storeSelected}
          </div>
          <div className="text-center">
            <button
              className="btn-bazzar-exit"
              type="button"
              onClick={this.exitStoreMsg}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Exit&nbsp;
              <i className="fa fa-search-plus" />
            </button>
          </div>
        </div>
      );
    }

    let bazzarBlockPanel;
    bazzarBlockPanel = <div>{searchPanel}</div>;

    let outputPanel;
    if (this.state.showBazzarFlag) {
      outputPanel = (
        <div className="bazzar-landing-from-dash">
          {this.state.showBazzarSearchFlag ? (
            <div className="row">
              <div className="col-6 text-right stores-header">Market</div>
              <div className="col-6 text-left ">{searchButton}</div>
            </div>
          ) : null}

          <div className="fixedsize-bazaar-storesx">{bazzarBlockPanel}</div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

// export default BazzarFramework;
BazzarFramework.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BazzarFramework);
