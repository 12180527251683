import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./CreateShift.css";

import DefineShift from "./DefineShift";
import CreateServiceTeam from "./CreateServiceTeam";
import ScheduleTime from "./ScheduleTime";
import ScheduleDays from "./ScheduleDays.js";
import Assign from "./Assign.js";

const readMeCode = "1145500000";

class CreateShift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
 
      eventInitiated: true,

      defineShiftBtn: true,
      createTeamBtn: false,
      timeBtn: false,
      viewBtn: false,

      showDefineShifts: true,
      showCreateTeam: false,
      showSchedule: false,
      showView: false,

      showtimeSchedule: false,
      showDaySchedule: false,

      assignBtn: false,
      showAssign: false,

      theEvent: null,
      taggedCatalog: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  goToEventCreation = async () => {
    // await this.setState({});
    this.props.returnToCaller(this.state.theEvent);
  };

  handleTaskOps = async (inp) => {
    await this.setState({
      defineShiftBtn: false,
      createTeamBtn: false,
      timeBtn: false,
      dayBtn: false,
      viewBtn: false,

      showDefineShifts: false,
      showCreateTeam: false,
      showtimeSchedule: false,
      showDaySchedule: false,
      showView: false,

      assignBtn: false,
      showAssign: false,
    });

    switch (inp) {
      case "setup":
        await this.setState({
          cdefineShiftBtn: true,
          showDefineShifts: true,
        });
        break;
      case "team":
        await this.setState({
          createTeamBtn: true,
          showCreateTeam: true,
        });
        break;
      case "time":
        await this.setState({
          timeBtn: true,
          showtimeSchedule: true,
        });
        break;
      case "days":
        await this.setState({
          dayBtn: true,
          showDaySchedule: true,
        });
        break;
      case "assign":
        await this.setState({
          assignBtn: true,
          showAssign: true,
        });
        break;
      case "view":
        await this.setState({
          viewBtn: true,
          showView: true,
        });
        break;
      default:
        console.log("really ...");
    }
  };

  returnFromDefineShift = async (theEvent) => {
    // console.log("returnFromDefineShift theEvent:", theEvent);

    await this.setState({
      theEvent,
    });
  };

  returnFromShiftOps = async (theEvent) => {
    // console.log("returnFromShiftOps theEvent:", theEvent);
    await this.setState({
      theEvent,
    });
  };

  returnFromAssign = async (theEvent) => {
    // console.log("returnFromAssign theEvent:", theEvent);
    await this.setState({
      theEvent,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CreateShift...');

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let shiftDef, team, time, days, assign; // view;
    if (this.state.deviceSize === "small") {
      shiftDef = "Setup";
      team = "Team";
      time = "Time";
      days = "Days";
      // view = "View";
      assign = "Asgn";
    } else {
      shiftDef = "Setup";
      team = "Team";
      time = "Time";
      days = "Days";
      // view = "View";
      assign = "Assign";
    }

    let opsButtonPanel;
    let isCustom = false;
    if ( this.state.theEvent && this.state.theEvent.eventType === 'service') {
       if ( this.state.theEvent.services.taggedCatalog ) {
         if ( this.state.theEvent.services.taggedCatalog.service.type === 'custom') isCustom = true;
       } 
    }
    if ( this.state.theEvent )  
    opsButtonPanel = (
      <div className="row">
        <div className="col-3 text-center task-panel-header">Shift Mgmt.</div>
        <div className="col-9 text-left event-ctrl-button">
          <button
            className={
              this.state.defineShiftBtn
                ? "btn_reg_70_active"
                : "btn_reg_70"
            }
            type="button"
            onClick={() => this.handleTaskOps("setup")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {shiftDef}
          </button>
          { !isCustom ? (
            <button
              className={
                this.state.createTeamBtn
                ? "btn_reg_70_active"
                : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleTaskOps("team")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {team}
            </button>
          ) : null}
          {!isCustom ? (
            <button
              className={
                this.state.timeBtn
                ? "btn_reg_70_active"
                : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleTaskOps("time")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {time}
            </button>
          ) : null}
          {!isCustom ? (
            <button
              className={
                this.state.dayBtn
                ? "btn_reg_70_active"
                : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleTaskOps("days")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {days}
            </button>
          ) : null}
          {!isCustom ? (
            <button
              className={
                this.state.assignBtn
                ? "btn_reg_70_active"
                : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleTaskOps("assign")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {assign}
            </button>
          ) : null}
          <button
            className="btn_back_main"
            type="button"
            onClick={this.goToEventCreation}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
             <i className="fas fa-step-backward" />
          </button>

          {infoModalButton}
        </div>
      </div>
    );

    let outputpanel;
    if (this.state.showDefineShifts) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-shift-box">
          <div className="create-shift-ctrl-btn-pos">{opsButtonPanel}</div>
          <hr className="create-shift-hr" />
          <DefineShift
            theEvent={this.props.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromDefineShift}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showCreateTeam) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-shift-box">
         <div className="create-shift-ctrl-btn-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <CreateServiceTeam
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromShiftOps}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showtimeSchedule) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-shift-box">
         <div className="create-shift-ctrl-btn-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <ScheduleTime
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromShiftOps}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showDaySchedule) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-shift-box">
         <div className="create-shift-ctrl-btn-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <ScheduleDays
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromShiftOps}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showAssign) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-shift-box">
         <div className="create-shift-ctrl-btn-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <Assign
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromAssign}
            store={store}
          />
        </div>
      );
    }


    return (
      <div>
        {outputpanel}
        <ModalContainer />
      </div>
    );
  }
}

CreateShift.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateShift));
