import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import LoadFileToS3 from "../../../../../utils/components/fileUpload/LoadFileToS3";

import "./ComposeBmail.css";
import axios from "axios";

import SendBmail from "./post/SendBmail";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtMsg = "/routes/interact/saveUpdtMsg";
const getDrafts = "/routes/interact/getDrafts?";

const maxPicSize = 100000; // 100KB
const picTypes = ["jpeg", "jpg", "bmp", "tiff", "png", "gif", "webp"];

const readMeCode = "1141000000";

class ComposeBmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      imagelink: "empty",

      newBtnActive: true,
      showNewPanel: true,
      showNewbutton: false,

      postBtnActive: false,
      showPostPanel: false,

      draftBtnActive: false,
      showDraftPanel: false,

      showImageLoadPanel: false,
      subDirName: "",
      picS3FileData: null,

      showPresentImagesFlag: false,
      showDraftsList: false,
      draftsList: [],

      opsType: "",
      imagesUploaded: [],
      subject: "",
      gotNewURLFlag: false,

      editorState: EditorState.createEmpty(),

      poolMsgId: 0,

      message: "",
      errFlag: false,
      contentToSend: null,
      recipiantList: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    // console.log('cdm 1 ...')
    let opsType = "";
    if (this.props.caller === "new") opsType = "new";

    let subDirName = "bmail-cid-" + this.props.communityId;
    // console.log('cdm 2 ...')
    await this.setState({
      deviceSize,
      opsType,
      subDirName,
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onEditorChange = (editorState) => {
    this.setState({
      editorState,
      message: "",
      gotNewURLFlag: false,
    });
  };

  getDraftsList = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.auth.user.baandaId;
    let url = baandaServer + getDrafts + params;
    try {
      let retdraft = await axios.get(url);
      // console.log("retdraft.data:", retdraft.data);
      if (retdraft.data.status === "success") {
        this.setState({
          draftsList: retdraft.data.Msg,
          message: "",
          errFlag: false,
        });
      } else {
        this.setState({
          message: retdraft.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getDraftsList err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  handleImageLoad = async () => {
    // console.log('in handleImage Load')
    await this.setState({
      newBtnActive: false,
      showNewPanel: false,
      postBtnActive: false,
      showPostPanel: false,
      draftBtnActive: false,
      showDraftPanel: false,
      showImageLoadPanel: true,
    });
  };

  returnToBmailCompose = async (fileData) => {
    let iuploaded = [...this.state.imagesUploaded];
    let imagelink = "";
    let gotNewURLFlag = false;
    if (fileData.ops === "done") {
      imagelink = fileData.s3FileData.location;
      iuploaded.push(fileData.s3FileData);
      gotNewURLFlag = true;
    }
    if (fileData.ops === "cancel") imagelink = "Load Canceled";

    await this.setState({
      newBtnActive: true,
      showNewPanel: true,

      postBtnActive: false,
      showPostPanel: false,
      draftBtnActive: false,
      showDraftPanel: false,
      showImageLoadPanel: false,
      imagelink,
      imagesUploaded: iuploaded,
      gotNewURLFlag,
    });
  };

  returnToBmailFromSend = (retobj) => {
    let recipiantList = this.state.recipiantList;
    if (retobj.ops === "cancel") {
      recipiantList = retobj.recipiantList;
      this.setState({
        newBtnActive: true,
        showNewPanel: true,

        postBtnActive: false,
        showPostPanel: false,
        draftBtnActive: false,
        showDraftPanel: false,
        showImageLoadPanel: false,
        recipiantList,
      });
    } else {
      this.handleExit();
    }
  };

  //   Save as draft or part of send on completion ---------------
  // Returns editorState as JSON string.
  getContentAsRawJson = () => {
    const contentState = this.state.editorState.getCurrentContent(); // It returns content of the editor state
    const raw = convertToRaw(contentState);
    return JSON.stringify(raw, null, 2);
  };

  //------------------------------------------------
  validateSave = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.subject === "") {
      isValid = "error";
      msg = "Subject is a must. ";
    }
    let jsonStr = this.getContentAsRawJson();
    let jsonObj = JSON.parse(jsonStr);
    if (jsonObj.blocks.length === 1 && jsonObj.blocks[0].text === "") {
      isValid = "error";
      msg += " Nothing to save or send. Blank letter.";
    }
    return { status: isValid, Msg: msg };
  };

  handleSaveBmailAsDraft = async () => {
    let isValid = await this.validateSave();

    if (isValid.status === "success") {
      let json = this.getContentAsRawJson();
      //   localStorage.setItem("DraftEditorContentJson", json);
      // poolMsgId from this.state.
      let input = {
        poolMsgId: parseFloat(this.state.poolMsgId),
        communityId: this.props.communityId,
        authorBaandaId: this.props.auth.user.baandaId,
        subject: this.state.subject,
        messageDoc: json,
        embeddedMedias: this.state.imagesUploaded,
        reqType: "draft", // Save as draft mode
        clientFunction: "handleSaveBmailAsDraft",
        clientProgram: "ComposeBmail",
      };
      let url = baandaServer + saveUpdtMsg;

      try {
        let retsave = await axios.post(url, input);

        if (retsave.data.status === "success") {
          this.setState({
            poolMsgId: retsave.data.Msg,
            message: "Successfuly saved",
            errFlag: false,
          });
        } else {
          this.setState({
            message: retsave.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handleSaveBmailAsDraft err:", err.message);
        this.setState({
          message: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        message: isValid.Msg,
        errFlag: true,
      });
    }
  };

  handleImageResize = async () => {
    this.setState({
      showPresentImagesFlag: true,
    });
  };

  handleImgListExit = () => {
    this.setState({
      showPresentImagesFlag: false,
      showDraftsList: false,
    });
  };

  selectThisImageToResize = async (obj) => {
    await this.setState({
      showPresentImagesFlag: false,
      picS3FileData: obj,
      imagelink: obj.location,
      gotNewURLFlag: true,
    });
  };

  selectThisDraftToWork = async (obj) => {
    let retData = JSON.parse(obj.messageDoc);
    //   let savedData = localStorage.getItem("DraftEditorContentJson");
    //   let sd = JSON.parse(savedData)

    const contentState = convertFromRaw(retData);
    const newEditorState = EditorState.createWithContent(contentState);
    this.setState({
      editorState: newEditorState,
      subject: obj.subject,
      showDraftsList: false,
      opsType: "draft",
      imagesUploaded: obj.embeddedMedias,
      poolMsgId: obj.poolMsgId,
    });
  };

  handleShowDarftBmail = async () => {
    await this.getDraftsList();
    this.setState({
      showPresentImagesFlag: false,
      showDraftsList: true,
      showNewbutton: true,
      newBtnActive: false,
    });
  };

  handleNewBmail = () => {
    this.setState({
      showNewbutton: false,
      newBtnActive: false,
      editorState: EditorState.createEmpty(),
      opsType: "new",
      subject: "",
    });
  };

  handlePostBmailMgmt = async () => {
    let isValid = await this.validateSave();

    let json = this.getContentAsRawJson();

    if (isValid.status === "success") {
      this.setState({
        showNewbutton: false,
        newBtnActive: false,
        showImageLoadPanel: false,
        showPostPanel: true,
        contentToSend: json,
        opsType: "post",
      });
    } else {
      await this.setState({
        message: isValid.Msg,
        errFlag: true,
      });
    }
  };

  handleExit = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ComposeBmail ...");

    let buttonPanelInfo = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let newBmailButton;
    if (this.state.showNewbutton) {
      newBmailButton = (
        <button
          className={
            this.state.newBtnActive
              ? "btn-new-comp-bmail-active"
              : "btn-new-comp-bmail"
          }
          type="button"
          onClick={this.handleNewBmail}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === "big" ? (
            "New"
          ) : (
            <i className="fas fa-plus-square" />
          )}
        </button>
      );
    }

    // let draft;
    // if (this.state.deviceSize === "small") {
    //   draft = "Drf";
    // } else {
    //   draft = "Draft";
    // }

    let draftBmailButton;
    // draftBmailButton = (
    //   <button
    //     className="btn_reg_60"
    //     type="button"
    //     onClick={this.handleShowDarftBmail}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     {this.state.deviceSize === 'big' ? draft : <i className="fas fa-check-double" /> }
    //   </button>
    // );

    let saveBmailButton;
    saveBmailButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleSaveBmailAsDraft}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Save"
        ) : (
          <i className="fas fa-save" />
        )}
      </button>
    );

    let postBmailButton;
    postBmailButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handlePostBmailMgmt}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Post"
        ) : (
          <i className="fas fa-paper-plane" />
        )}
      </button>
    );

    // let replyBmailButton;

    let loadImageButton;
    loadImageButton = (
      <button
        className="btn_reg_70 small-img-btn-pos"
        type="button"
        onClick={this.handleImageLoad}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Image"
        ) : (
          <i className="fas fa-image" />
        )}
      </button>
    );

    let resizeImageButton;
    resizeImageButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleImageResize}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "ReOrg"
        ) : (
          <i className="fas fa-expand-arrows-alt" />
        )}
      </button>
    );

    let exitFromImgListButton;
    exitFromImgListButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleImgListExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitButton;
    exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let currImagesListPanel;
    if (this.state.showPresentImagesFlag) {
      let bool = true;
      currImagesListPanel = (
        <div className="list-darft-comp-bmails-box">
          <div className="text-center curr-image-list-head">
            Select to Resize &nbsp; {exitFromImgListButton}
          </div>
          <div className="row">
            <div className="col-3 draft-list-panel-head text-center">Ops</div>
            <div className="col-2 draft-list-panel-head text-center">Size </div>
            <div className="col-7 draft-list-panel-head text-left">Image</div>
          </div>
          <div className="fixedsize-comp-image-list">
            {this.state.imagesUploaded.map((obj, i) => {
              // console.log("345 obj:", obj);

              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-3 text-center">
                      <button
                        className="btn-sub-line-edit"
                        type="button"
                        onClick={() => this.selectThisImageToResize(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    {/* <div className="col-9 text-left item-category-label"> */}
                    <div className="col-2 text-left">{obj.size} kb</div>
                    <div className="col-7 text-center">
                      <img
                        src={obj.location}
                        alt="pic"
                        className="bmail-comp-image-for-list"
                      />
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let draftslistPanel;
    if (this.state.showDraftsList) {
      let bool1 = true;
      draftslistPanel = (
        <div className="list-darft-comp-bmails-box">
          <div className="text-center curr-image-list-head">
            Select from Drafts &nbsp; {exitFromImgListButton}
          </div>
          <div className="row">
            <div className="col-3 draft-list-panel-head text-center">Ops</div>
            <div className="col-9 draft-list-panel-head text-left">Subject</div>
          </div>
          {this.state.draftsList.length === 0 ? (
            <div className="text-center no-drafts-list">
              No work-in-progress drafts to work on
            </div>
          ) : (
            <div className="fixedsize-comp-image-list">
              {this.state.draftsList.map((obj, i) => {
                // console.log("345 obj:", obj);

                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-3 text-center">
                        <button
                          className="btn-sub-line-edit"
                          type="button"
                          onClick={() => this.selectThisDraftToWork(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      {/* <div className="col-9 text-left item-category-label"> */}

                      <div className="col-9 text-left draft-subject-lbl">
                        {obj.subject}
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    }

    let imgnote;
    if (this.state.deviceSize === "small") {
      imgnote = "Link";
    } else {
      imgnote = "Paste @ Img Link";
    }

    let loadImagPanel;
    loadImagPanel = (
      <div>
        <div className="row">
          <div className="col-1 text-right">{loadImageButton}</div>
          {this.state.gotNewURLFlag ? (
            <div className="col-2 text-right paste-img-note">{imgnote}</div>
          ) : (
            <div className="col-2 text-right paste-img-note">&nbsp;</div>
          )}
          {this.state.gotNewURLFlag ? (
            <div className="col-6 text-left show-link-note-x">
              {this.state.imagelink}
            </div>
          ) : (
            <div className="col-6">&nbsp;</div>
          )}

          <div className="col-3 text-left">
            {this.state.imagesUploaded && this.state.imagesUploaded.length > 0
              ? resizeImageButton
              : ""}
          </div>
        </div>
        {currImagesListPanel}
        {draftslistPanel}
      </div>
    );

    let outputPanel;
    let activePanel;

    let opsType;
    if (this.state.opsType !== "") {
      if (this.state.opsType === "new") opsType = "New";
      if (this.state.opsType === "draft") opsType = "Draft-WIP";
    }

    let opsButtonPanel;
    opsButtonPanel = (
      <div className="row">
        <div className="col-2 text-center ops-text-lbl">{opsType}</div>
        <div className="col-9 text-center">
          {newBmailButton}
          {draftBmailButton}
          {saveBmailButton}
          {/* {replyBmailButton} */}
          {postBmailButton}
          {buttonPanelInfo}
          {exitButton}
        </div>
        <div className="col-1 text-center ops-text-lbl">&nbsp;</div>
      </div>
    );

    let subjectPanel;
    subjectPanel = (
      <div className="row comp-subject-row">
        <div className="col-3 bmail-comp-subject-lbl text-right">Subject</div>
        <div className="col-8 text-left show-subject-comp">
          <input
            name="subject"
            type="text"
            value={this.state.subject}
            onChange={this.onChange}
            // size="14"
            maxLength="60"
            className="bmail-comp-subject"
            placeholder=""
          />
        </div>
        <div className="col-1">&nbsp;</div>
      </div>
    );

    if (this.state.showNewPanel) {
      activePanel = (
        <div>
          {opsButtonPanel}
          {loadImagPanel}
          {subjectPanel}
          <div>
            <Editor
              editorState={this.state.editorState}
              onEditorStateChange={this.onEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class-x"
              toolbarClassName="toolbar-class"
              //   editorClassName="demo-editor"
              toolbar={{
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: {
                  // uploadCallback: uploadImageCallBack,
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "200px",
                  },
                },
              }}
            />
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center bmail-comp-msg-err"
                : "text-center bmail-comp-msg"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    if (
      this.state.showNewPanel ||
      this.state.showDraftPanel ||
      !this.state.showPostPanel
    ) {
      outputPanel = (
        <div className="bmail-mgmt-box">
          <div className="text-center">{activePanel}</div>
        </div>
      );
    }

    if (this.state.showImageLoadPanel) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToBmailCompose}
            communityId={this.props.communityId}
            fileType={picTypes}
            maxSize={maxPicSize}
            s3subdir={this.state.subDirName}
            requestor="bMail Item Embed"
            s3FileData={this.state.picS3FileData}
            type="image"
          />
        </div>
      );
    }

    if (this.state.showPostPanel) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <SendBmail
            returnToCaller={this.returnToBmailFromSend}
            communityId={this.props.communityId}
            subject={this.state.subject}
            recipiantList={this.state.recipiantList}
            poolMsgId={this.state.poolMsgId}
            contentToSend={this.state.contentToSend}
            imagesUploaded={this.state.imagesUploaded}
            calledBy="newmail"
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ComposeBmail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComposeBmail));
