import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./CustomServiceTagging.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const getTemplatesOfCommunity = "/routes/projects/getTemplatesByName?";

const readMeCode = "1111590000";

class CustomServiceTagging extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catalog: null,
      errMsg: "",
      errFlag: false,

      initiatorName: "",
      showMembersFlag: false,
      selectedMember: null,
      members: [],

      templateName: "",
      showTemplateFlag: false,
      taggedTemplateName: "",
      taggedTemplateId: 0,
      templates: [],

      tagIntent: "no",
    };
  }

  componentDidMount = async () => {
    let selectedMember = null;
    if (this.props.catalog.service.customFirstResponder)
      selectedMember = this.props.catalog.service.customFirstResponder;

    let taggedTemplateName = "";
    let showTemplateFlag = false;

    if (this.props.catalog.service.templateId !== 0) {
      taggedTemplateName = this.props.catalog.service.taggedTemplateName;
      showTemplateFlag = true;
    }

    await this.setState({
      catalog: this.props.catalog,
      selectedMember,
      taggedTemplateName,
      showTemplateFlag,
    });
  };


  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleAnswerType = async (e) => {
    await this.setState({
      answerType: e.target.value,
    });
  };

  handleSave = async (calledby) => {
    // console.log("calledby:", calledby);
    let taggedTemplateId = this.state.taggedTemplateId;
    let taggedTemplateName = this.state.taggedTemplateName;
    if (this.state.tagIntent === "no") {
      taggedTemplateId = 0;
      taggedTemplateName = "";
    }

    let input = {
      communityId: this.state.catalog.communityId,
      coopStoreId: this.props.coopStoreId,
      itemId: this.state.catalog.itemId,
      selectedMember: this.state.selectedMember,
      taggedTemplateId,
      taggedTemplateName,
      updtmode: "customtagging",
      clientProgram: "ServiceMgmt.js",
      clientFunction: "handleSave",
    };
    let url = baandaServer + updateCatalogItem;
    // console.log("url:", url);
    // console.log("input:", input);

    try {
      let retserv = await axios.post(url, input);
      // console.log("retserv:", retserv);
      if (retserv.data.status === "success") {
        await this.setState({
          catalog: retserv.data.Msg,
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        await this.setState({
          errMsg: retserv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  returnToCatalogAdmin = () => {
    this.props.returnToCaller(this.state.catalog);
  };

  searchInitiator = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" + this.props.coopStoreId +
      "&memberName=" +
      this.state.initiatorName;
    let url = baandaServer + getMembersOfCommunity + params;
    // console.log("url:", url);
    try {
      let mem = await axios.get(url);
      // console.log("mem.data:", mem.data);
      if (mem.data.status === "success") {
        await this.setState({
          members: mem.data.Msg,
          errMsg: "",
          errFlag: false,
          showMembersFlag: true,
          showTemplateFlag: false,
        });
      } else {
        await this.setState({
          errMsg: mem.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      console.log("searchInitiator err:", err.message);
    }
  };

  searchTemplates = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&templateName=" +
      this.state.templateName;
    let url = baandaServer + getTemplatesOfCommunity + params;
    // console.log("url:", url);
    try {
      let temp = await axios.get(url);
      // console.log("temp.data:", temp.data);
      if (temp.data.status === "success") {
        let errMsg = "",
          errFlag = false;
        if (temp.data.Msg.length === 0) {
          errMsg = "No project template exist for the community.";
          errFlag = true;
        }
        await this.setState({
          templates: temp.data.Msg,
          errMsg,
          errFlag,
          showMembersFlag: false,
          showTemplateFlag: true,
        });
      } else {
        await this.setState({
          errMsg: temp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      console.log("searchTemplates err:", err.message);
    }
  };

  handleSelectMember = async (obj) => {
    // console.log("obj:", obj);
    await this.setState({
      selectedMember: obj,
      showMembersFlag: false,
    });
  };

  handleTagIntent = async (e) => {
    await this.setState({
      tagIntent: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CustomServiceTagging...');

    let initSearchButton = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.searchInitiator}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let initTemplateButton = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.searchTemplates}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let customTagModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel = (
      <div className="cat-custom-service-button-pos">
        <div className="row">
          <div className="col text-center">
            <button
              className="btn_back_main"
              type="button"
              onClick={this.returnToCatalogAdmin}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
            {this.state.selectedMember &&
            this.state.selectedMember.name !== "" ? (
              <button
                className="btn_reg_60"
                type="button"
                onClick={() => this.handleSave("save")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Save
              </button>
            ) : null}
            {customTagModalButton}
          </div>
        </div>
      </div>
    );

    let taskInitiatorPanel;
    taskInitiatorPanel = (
      <div>
        <div className="row">
          <div className="col-3 text-right custom-tag-lbl">Task Intiator</div>
          <div className="col-9 text-left">
            <input
              name="initiatorName"
              type="text"
              value={this.state.initiatorName}
              onChange={this.onChange}
              //   size="16"
              //   maxLength="16"
              className="cat-custom-initiator"
              placeholder="Enter a member name"
            />
            &nbsp;{initSearchButton}
          </div>
        </div>
        {this.state.selectedMember ? (
          <div className="text-center tagged-member-lbl">
            Tagged First Responder: {this.state.selectedMember.name}{" "}
            &nbsp;|&nbsp;{this.state.selectedMember.email}
          </div>
        ) : null}
      </div>
    );

    let membersPanel;
    let bool = true;
    if (this.state.showMembersFlag) {
      membersPanel = (
        <div className="text-center">
          <div className="cat-custom-mem-temp-box">
            {this.state.members.length > 0 ? (
              //   <div className="fixedsize-custom-tag-stuff">
              <div>
                {this.state.members.map((obj, i) => {
                  return (
                    <div key={i}>
                      <div
                        className={`${bool ? "row-color-a" : "row-color-b"}`}
                      >
                        {/* <div className="row">
                        <div className="col text-left"> */}
                        <div className="row cat-member-list-row">
                          <div className="col-10 text-left cat-member-tag">
                            {obj.name}&nbsp;&nbsp;-&nbsp;{obj.email}
                          </div>

                          <div className="col-2 text-center cust-tag-sel-pos">
                            <button
                              className="btn_reg_40"
                              type="button"
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                              onClick={() => this.handleSelectMember(obj)}
                            >
                              Sel
                            </button>
                          </div>
                        </div>
                      </div>
                      {(bool = !bool)}
                    </div>
                    //   </div>
                    // </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    let templateIntentPanel;
    // if (this.state.selectedMember && this.state.selectedMember.name !== "") {
    //   templateIntentPanel = (
    //     <div className="row custom-tag-intent-row">
    //       <div className="col text-center radio-font-style">
    //         <strong>Want to tag a project template&nbsp;&nbsp;</strong>
    //         <div className="form-check form-check-inline">
    //           <label className="form-check-label">
    //             <input
    //               className="form-check-input"
    //               type="radio"
    //               value="yes"
    //               checked={this.state.tagIntent === "yes"}
    //               onChange={this.handleTagIntent}
    //               disabled
    //             />{" "}
    //             Yes
    //           </label>
    //         </div>
    //         <div className="form-check form-check-inline">
    //           <label className="form-check-label">
    //             <input
    //               className="form-check-input"
    //               type="radio"
    //               value="no"
    //               checked={this.state.tagIntent === "no"}
    //               onChange={this.handleTagIntent}
    //             />{" "}
    //             No
    //           </label>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    let templateInitiatorPanel;
    if (this.state.tagIntent === "yes") {
      templateInitiatorPanel = (
        <div>
          <div className="row template-row-placement">
            <div className="col-3 text-right custom-tag-lbl">Tag Template</div>
            <div className="col-9 text-left">
              <input
                name="templateName"
                type="text"
                value={this.state.templateName}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="cat-custom-initiator"
                placeholder="Enter a template template name"
              />
              &nbsp;{initTemplateButton}
            </div>
          </div>
          {this.state.selectedMember ? (
            <div className="text-center tagged-member-lbl">
              Tagged template:{" "}
              {this.state.taggedTemplateName === ""
                ? "Template not yet tagged"
                : this.state.taggedTemplateName}
            </div>
          ) : null}
        </div>
      );
    }

    let templatesPanel;
    if (this.state.showTemplateFlag) {
      if (this.state.templates.length > 0) {
      }
    }

    let outputPanel;
    outputPanel = (
      <div className="catshow-custom-tagging-box text-center">
        <div className="text-center cat-custom-tag-header">
          Custom Service Tagging
        </div>
        {taskInitiatorPanel}
        {membersPanel}
        {templateIntentPanel}
        {templateInitiatorPanel}
        {templatesPanel}
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center cat-custom-msg-err"
              : "text-center cat-custom-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CustomServiceTagging.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomServiceTagging));
