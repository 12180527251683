import React, { Component } from "react";

import axios from "axios";

import "./GroupTarget.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const checkIfTargetListExists = "/routes/interact/checkIfTargetListExists?";
const groupToTarget = "/routes/interact/groupToTarget";

class GroupTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      showGroupList: false,
      communityGroups: [],
      selectedGroup: null,
      groupName: "",
      searchGroupName: "",
      showTargetFlag: false,
      searchPanelFlag: true,

      newTargetName: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = () => {};

  exitMergeTargets = () => {
    this.props.returnToCaller();
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleGetGroups = async () => {
    this.setState({
      showFromMemlistFlag: false,
      selectedGroup: null,
      showGroupList: false,
    });
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&groupName=" +
        this.state.searchGroupName;

      let url = baandaServer + getGroupsOfCommunity + params;
      // console.log(">> url:", url);
      let grpret = await axios.get(url);
      // console.log("grpret.data:", grpret.data);
      if (grpret.data.status === "success") {
        if (grpret.data.Msg.length > 0) {
          this.setState({
            communityGroups: grpret.data.Msg,
            noGroupsFlag: false,
            showGroupList: true,
            searchPanelFlag: false,
          });
        } else {
          this.setState({
            communityGroups: [],
            noGroupsFlag: true,
            showGroupList: false,
          });
        }
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleSelectThisGroup = async (inp) => {
    this.setState({
      selectedGroup: inp,
      showGroupList: false,
      showTargetFlag: true,
    });
  };

  handleReSearch = async () => {
    this.setState({
      selectedGroup: null,
      showGroupList: true,
      showTargetFlag: false,
      searchPanelFlag: true,
    });
  };

  checkIfTGNameExists = async () => {
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&targetListName=" +
        this.state.newTargetName +
        "&broadcastId=" +
        this.props.broadcastId;
      let url = baandaServer + checkIfTargetListExists + params;
      // console.log("target url:", url);
      let tle = await axios.get(url);
      // console.log("tle.data:", tle.data);
      if (tle.data.status === "error") {
        return { status: "error", Msg: tle.data.Msg };
      } else {
        return { status: "success", Msg: "" };
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
      return { status: "error", Msg: err.message };
    }
  };

  migrateTheGroup = async () => {
    // console.log("in migrateTheGroup");
    let ret = await this.checkIfTGNameExists();
    // console.log("ret:", ret);
    if (ret.status === "success") {
      try {
        let members = [];
        this.state.selectedGroup.members.forEach((gp) => {
          let obj = {
            email: gp.email,
            cell: gp.cell,
            name: gp.name,
            additionalInfo: null,
          };
          members.push(obj);
        });
        let input = {
          communityId: parseFloat(this.props.communityId),
          newTargetName: this.state.newTargetName,
          listDetails: members,
          targetFrom: "group",
          uploadedBy: this.props.baandaId,
          uploadedAt: new Date(),
        };
        let url = baandaServer + groupToTarget;

        // console.log("url:", url, " input:", input);

        let upret = await axios.post(url, input);
        if (upret.data.status === "success") {
          this.setState({
            errMsg: "Successfully migrated",
            errFlag: false,
            showGroupList: false,
            showTargetFlag: false,
            searchPanelFlag: true,
            selectedGroup: null,
            newTargetName: "",
          });
        } else {
          this.setState({
            errMsg: upret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('GRoupTarget...');

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitMergeTargets}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchButtonPanel;
    searchButtonPanel = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.handleGetGroups}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let reSearchButton;
    reSearchButton = (
      <button
        className="btn_reg_100"
        type="button"
        onClick={this.handleReSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Re-Search
      </button>
    );

    let saveButton = (
      <button
        className="btn_reg_80 adm-grp-save-pos"
        type="button"
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
        onClick={this.migrateTheGroup}
      >
        Migrate
      </button>
    );

    let groupListPanel;
    console.log("this.state.showGroupList:", this.state.showGroupList);
    if (this.state.showGroupList) {
      let bool = true;
      groupListPanel = (
        <div>
          <div className="adm-grp-item-sel-box-head">
            <div className="row">
              <div className="col-1 adm-grp-grp-lines-ops text-left">Ops</div>
              <div className="col-10 adm-grp-grp-lines-name text-left">
                Group Name (id) Description (50 chars)
              </div>
              <div className="col-1 adm-grp-grp-lines-lvl text-left">Level</div>
            </div>
          </div>
          <div className="adm-grp-group-sel-box">
            {this.state.communityGroups.map((obj, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool
                      ? "adm-grp-item-row-color-lt"
                      : "adm-grp-item-row-color-dr"
                  }`}
                >
                  <div className="row">
                    <div className="col-1 adm-grp-grp-lines text-left">
                      <button
                        className="btn_reg_40"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.handleSelectThisGroup(obj)}
                      >
                        <i className="fas fa-check-square" />
                      </button>
                    </div>
                    <div className="col-10 adm-grp-grp-ind-lines text-left">
                      &nbsp;&nbsp;
                      {obj.groupName};&nbsp; ({obj.groupId})&nbsp;&nbsp;
                      {this.state.selectedGroup &&
                      this.state.selectedGroup.groupId === obj.groupId ? (
                        <i className="fas fa-check-square" />
                      ) : (
                        ""
                      )}
                      {obj.description.length > 50
                        ? obj.description.substring(0, 50) + "..."
                        : obj.description}
                    </div>
                    <div className="col-1 adm-grp-grp-ind-lines text-left">
                      {obj.groupLevel}
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let findExistingPanel;

    findExistingPanel = (
      <div className="grps-search-box">
        <div className="grp-wip-search-header">View Existing Groups</div>
        {this.state.searchPanelFlag ? (
          <div className="row grp-name-row">
            <div className="col-2 text-right brd-rec-search-lbl">Group</div>
            <div className="col-10 text-left">
              <input
                name="searchGroupName"
                type="text"
                value={this.state.searchGroupName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="brd-grp-search-name"
                placeholder="Enter a Group Name to Search"
              />
              &nbsp;{searchButtonPanel}
            </div>
          </div>
        ) : null}

        <div>{groupListPanel}</div>
      </div>
    );

    let newTargetNamePanel;
    newTargetNamePanel = (
      <div className="row grp-tg-name-pos">
        <div className="col-3 text-right">Target Name</div>
        <div className="col-9 text-left">
          <input
            name="newTargetName"
            type="text"
            value={this.state.newTargetName}
            onChange={this.onChange}
            size="25"
            maxLength="25"
            className="grp-target-name"
            placeholder=""
          />
        </div>
      </div>
    );

    let targetPanel;
    if (this.state.showTargetFlag) {
      targetPanel = (
        <div>
          <div className="text-center">
            Selected Group &nbsp;{reSearchButton}
          </div>
          <div className="row">
            <div className="col-3 text-right tg-selected-grp-lbl">
              Name&nbsp;
            </div>
            <div className="col-9 text-left tg-selected-grp-lbl">
              {this.state.selectedGroup.groupName}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right tg-selected-grp-lbl">
              Description&nbsp;
            </div>
            <div className="col-9 text-left tg-selected-grp-lbl">
              {this.state.selectedGroup.description}
            </div>
          </div>
          <div className="text-center group-to-tg-msg">Migrate to target</div>
          {newTargetNamePanel}
          <div className="text-center">{saveButton}</div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center gptg-head">
          Group To Target &nbsp;{backButton}
          {findExistingPanel}
          {targetPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center grp-to-tg-msg-err"
                : "text-center grp-to-tg-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default GroupTarget;
