import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./CartShow.css";

import CheckoutCart from "./checkout/CheckoutCart";

import { sortArrayOfObjects } from "../../../../utils/sortArrayOfObjects";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

const guestLoginId = process.env.REACT_APP_BAANDA_GUEST;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getItemsOfCart = "/routes/dashboard/getItemsOfCart?";
const deleteItemInCart = "/routes/dashboard/deleteItemInCart";
// const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const readMeCode = "1112020000";

class CartShow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsInCart: [],
      cartMsg: "",
      cartErrMsg: "",
      paymentProcessMsg: "",
      paymentErrFlag: false,
      showCartFlag: true,
      checkoutFlag: false,
      payProcessHandlingFlag: false,
      reviewFlag: false,
      customerHandlingFlag: false,
      deviceSize: "",
      cartTotal: 0,
      communityName: "",
      optionData: null,

      cartId: 0,

      community: null,
      creditcardOk: false,
      okToProceed: false,

      serviceExists: false,
      discountExists: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let itemsInCart = await this.getCartItems();
    let msg = "",
      cartTotal = 0;
    if (itemsInCart && itemsInCart.length === 0) {
      msg = "There are no items in your cart.";
      itemsInCart = [];
    } else if (this.props.pointOfCall === "store") {
      msg = "";
    }

    let serviceExists = false;
    let cartMsg = "";
    let customMsgWritten = false;
    let scheduledMsgWritten = false;
    let discountExists = false;
    if (itemsInCart.length > 0) {
      itemsInCart.forEach((obj) => {

        if (obj.discountPercent > 0) {
          let discPrice = obj.price * ((100 - obj.discountPercent) / 100);
          cartTotal = cartTotal + discPrice * obj.quantity;
          discountExists = true;
        } else cartTotal = cartTotal + obj.price * obj.quantity;
        if (obj.itemType === "service") {
          serviceExists = true;
          if (obj.service.type === "custom" && !customMsgWritten) {
            if (obj.eventTaggingOnly)
              cartMsg =
                cartMsg +
                " The cart has at least one custom service. Check your TaskList, after checkout, for the step.";
            customMsgWritten = true;
          }
          if (obj.service.type === "scheduled" && !scheduledMsgWritten) {
            if (obj.eventTaggingOnly) {
              cartMsg =
                cartMsg +
                " The cart has at least one scheduled service that is by invite only.";
            }
            if (obj.service.reqInvitation === "yes")
              cartMsg =
                cartMsg +
                " You may request for an invite from your tasklist after you checkout.";
            // else cartMsg = cartMsg + " It is private.";
            scheduledMsgWritten = true;
          }
        }
      });
    }

    cartTotal = Math.round(cartTotal * 100) / 100;
    // this.getDelivryInfo();
    await this.getCommunitiesInfo();

    await this.setState({
      deviceSize,
      itemsInCart: itemsInCart,
      cartMsg,
      cartErrMsg: msg,
      cartTotal: cartTotal,
      community: this.props.community,
      serviceExists,
      discountExists,
    });
  };

  // This is to show the info panel
  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getCommunitiesInfo = async () => {
    await this.setState({
      creditcardOk: this.props.community.adminTaskStates.creditCardSetup,
      okToProceed: this.props.community.adminTaskStates.creditCardSetup,
    });
  };

  getCartItems = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let itemsInCart = [];
    let msg = "";
    try {
      let params =
        "baandaId=" +
        this.props.cartOfBaandaId +
        "&communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        coopStoreId;
      let url = baandaServer + getItemsOfCart + params;
      let ret = await axios.get(url);
      if (ret.data.Msg.length > 0) {
        this.setState({
          cartId: ret.data.Msg[0].cartId,
        });
      }
      if (ret.data.Msg && ret.data.Msg.length !== 0) {
        itemsInCart = ret.data.Msg[0].cartContent;
      } else itemsInCart = [];

      if (itemsInCart && itemsInCart.length === 0) {
        msg = "There are no items in your cart.";
      } else if (this.props.pointOfCall === "store") {
        msg = "";
      }

      let sorted = await sortArrayOfObjects(itemsInCart, "itemName", "dsc");
      await this.setState({
        itemsInCart: sorted,
        cartMsg: msg,
      });
    } catch (err) {
      console.log("GetCartItems Err:, ", err);
    }

    return itemsInCart;
  };

  goback = () => {
    this.props.returnToDetails(this.props.searchItemName);
  };

  handleDelete = async (obj) => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let subItem = null;
    this.state.itemsInCart.forEach((elm) => {
      if (obj.itemId === elm.itemId) subItem = elm.subItem;
    });

    let quantity = obj.quantity;
    if (
      obj.periodicShipData &&
      obj.periodicShipData.enabled &&
      obj.periodicShipData.type === "boxed"
    ) {
      let bp = obj.periodicShipData.boxedPack;
      quantity = obj.quantity * bp.scheduleQty;
    }

    try {
      let data = {
        baandaId: this.props.cartOfBaandaId,
        communityId: this.props.communityId,
        cartId: this.state.cartId,
        coopStoreId,
        subItem,
        itemId: obj.itemId,
        itemType: obj.itemType,
        quantity,
        showCartFlag: obj.showCartFlag,
        pkgComposedFlag: obj.pkgComposedFlag,
        periodicShipData: obj.periodicShipData,
        clientProgram: "CartShow",
        clientFunction: "handleDelete",
      };
      let url = baandaServer + deleteItemInCart;
      await axios.post(url, data);
      await this.getCartItems();
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handlecheckout = async () => {
    await this.setState({
      checkoutFlag: true,
      showCartFlag: false,
    });
  };

  returnFromCheckout = async (status) => {
    if (status === "totalout") {
      this.setState({
        itemsInCart: [],
      });
      this.goback();
    } else if (status === "cancel") {
      await this.setState({
        checkoutFlag: false,
        showCartFlag: true,
      });
    } else {
      this.goback();
    }
  };

  handleDeliveryProcess = async (e) => {
    await this.setState({
      deliveryProcess: e.target.value,
    });
  };

  handlePayProcess = async (e) => {
    await this.setState({
      payProcess: e.target.value,
    });
  };

  handleBackToCarts = async () => {
    await this.setState({
      showCartFlag: true,
      checkoutFlag: false,
      reviewFlag: false,
      customerHandlingFlag: false,
      optionData: null,
    });
  };

  handleReview = async () => {
    // let paySchedule;
    let billAmt = this.state.totalBillAmount;
    let paidAmt = 0;

    if (this.state.amountPaid === 0) paidAmt = billAmt;
    else paidAmt = this.state.amountPaid;

    await this.setState({
      reviewFlag: true,
      payProcessHandlingFlag: false,
      showTheCartFlag: false,
      checkoutFlag: false,
      customerHandlingFlag: false,
      installmentDateOfMonth: null,
      // paySchedule,
      paymentType: this.state.payMediumLin,
      toPayTotal: this.state.cartTotal,
      totalBillAmount: billAmt,
      amountPaid: paidAmt,
      amountPending:
        Math.round((this.state.totalBillAmount - paidAmt) * 100) / 100,
    });
  };

  handleOptions = async () => {
    await this.setState({
      toPayTotal: this.state.totalBillAmount,
      payProcessHandlingFlag: true,
      checkoutFlag: false,
      showCartFlag: false,
      amountPending: this.state.totalBillAmount - this.state.amountPaid,
      optionData: this.state.optionData,
      totalBillAmount: this.state.preProcessBillAmt,
    });
  };

  returnToCartShow = async (data) => {
    await this.setState({
      payProcessHandlingFlag: false,
      checkoutFlag: true,
      showCartFlag: false,
      okToProceed: data.okToProceed,
      optionData: data,
    });

    await this.loadOptionsData(data);
  };

  handleReturnToCartShow = async () => {
    await this.setState({
      reviewFlag: false,
      customerHandlingFlag: false,
      payProcessHandlingFlag: false,
      showCartFlag: false,
      checkoutFlag: true,
    });
  };

  handleCustomerCheckout = async () => {
    await this.setState({
      reviewFlag: false,
      payProcessHandlingFlag: false,
      showCartFlag: false,
      checkoutFlag: false,
      customerHandlingFlag: true,
    });
  };

  handleSaleComplete = async () => {
    this.goback();
  };

  // handleAdminOpsSelected = async () => {
  //   // let value = document.getElementById("mySelect").value;
  // };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  showDiscount = async (obj) => {
    await this.setState({
      showDiscountPanelFlag: true,
      discObj: obj,
    });
  };

  showPriceReason = async (obj) => {
    await this.setState({
      showNoPriceFlag: true,
      discObj: obj,
    });
  };

  exitDiscount = async () => {
    await this.setState({
      showDiscountPanelFlag: false,
    });
  };

  exitNoPrice = async () => {
    await this.setState({
      showNoPriceFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CartShow...');

    let cartAmount = 0.0;
    if (this.state.itemsInCart.length > 0) {
      this.state.itemsInCart.forEach((obj) => {
        cartAmount = cartAmount + obj.quantity * obj.price;
      });
    }

    let chkoutbtn; // shopbtn;
    if (this.state.deviceSize === "small") {
      chkoutbtn = "CkOut";
      // shopbtn = "Shop";
    } else {
      chkoutbtn = "Check Out";
      // shopbtn = "Shop More";
    }

    let retbut = (
      <div>
        {this.state.itemsInCart.length > 0 ? (
          <button
            className="btn_reg_90_ck"
            type="button"
            onClick={this.handlecheckout}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? chkoutbtn : <i className="fas fa-wallet" />}
          </button> 
        ) : null}
        &nbsp;
        <button
          className="btn_back_main"
          type="button"
          onClick={this.goback}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        <button
          onClick={this.openAlertModal}
          className="btn_info_main"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let showDiscountPanel;
    if (this.state.showDiscountPanelFlag) {
      showDiscountPanel = (
        <div className="cart-disc-box">
          <div className="text-center cart-disc-price-lbl">
            Price is ${this.commaFormattedCurrency(this.state.discObj.price)}
          </div>
          <div className="text-center cart-disc-lbl">
            Discount is&nbsp;{this.state.discObj.discountPercent}%
          </div>
          <div>
            <button
              className="btn_back_main"
              type="button"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
              onClick={this.exitDiscount}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
      );
    }

    let showNoPricePanel;
    if (this.state.showNoPriceFlag) {
      showNoPricePanel = (
        <div className="cart-disc-box">
          <div className="text-center cart-disc-price-lbl">
            Job scope drives price.
          </div>
          <div className="text-center cart-disc-lbl">
            Check tasklist for the next step.
          </div>
          <div>
            <button
              className="btn_back_main"
              type="button"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
              onClick={this.exitNoPrice}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
      );
    }

    let qty, price, discMsg;
    if (this.state.deviceSize === "small") {
      qty = "Qty";
      price = "Prc";
      discMsg = " -Disc";
    } else {
      qty = "Quantity";
      price = "Price";
      discMsg = " - Discounted";
    }

    let cartPanel;
    if (this.state.showCartFlag) {
      let bool = true;
      cartPanel = (
        <div className="text-center">
          <div className="row">
            <div className="col-6 text-left cart-item-header-topline">
              The Cart Items
            </div>
            <div className="col-6 text-centerc cart-btn-pos">{retbut}</div>
          </div>
          <div className="row">
            {
              (this.props.auth.user.email.toLowerCase() === guestLoginId.toLowerCase() ? (
                <div className="col-6 text-center cart-item-header">
                  As&nbsp;<mark>Guest</mark>
                </div>
              ) : (
                <div className="col-6 text-center cart-item-header">
                  Hello&nbsp;<mark>{this.props.cartOf.name}</mark>
                  {this.props.coopStoreId !== 0
                    ? ", at " + this.props.displayStoreName
                    : null}
                </div>
              ))
            }
 
            <div className="col-6 text-center cart-item-total">
              Total: ${this.commaFormattedCurrency(this.state.cartTotal)}
              {this.state.discountExists ? discMsg : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-1 text-right">&nbsp;</div>
            <div className="col-6 text-right cart-item-header-name">
              Item Name&nbsp;
              <i className="fa fa-shopping-cart" />
            </div>
            <div className="col-2 text-right cart-item-header-p">&nbsp;</div>
            <div className="col-2 text-center cart-item-header-q">{qty}</div>
            <div className="col-1 text-right cart-item-header-p">
              &nbsp;&nbsp;$&nbsp;{price}
            </div>
          </div>

          {/* <div className="cart-box"> */}
          <div>
            {showDiscountPanel}
            {showNoPricePanel}
            {this.state.itemsInCart.length > 0 ? (
              <div className="fixedsize_cartview">
                {this.state.itemsInCart.map((obj, i) => {
                  let itemName;
                  if (this.state.deviceSize === "small") {
                    if (obj.itemName.length >= 35) {
                      itemName = obj.itemName.substring(0, 35) + "...";
                    } else {
                      let len = 35 - obj.itemName.length;
                      itemName = obj.itemName;
                      itemName = itemName.padEnd(len, ".");
                    }
                  } else {
                    if (obj.itemName.length >= 60) {
                      itemName = obj.itemName.substring(0, 60) + "..."; 
                    } else {
                      let len = 35 - obj.itemName.length;
                      itemName = obj.itemName;
                      itemName = itemName.padEnd(len, ".");
                    }
                  }

                  if (
                    obj.subscription &&
                    Number(obj.subscription.registrationFee) > 0
                  ) {
                    itemName +=
                      " Reg. Fee: $" +
                      this.commaFormattedCurrency(
                        obj.subscription.registrationFee
                      );
                  }

                  let price = obj.price,
                    discFlag = false;
                  if (obj.discountPercent > 0) {
                    discFlag = true;
                    // price = obj.price * ((100 - obj.discountPercent) / 100);
                  }
                  // else price = obj.price;

                  let disc = null;
                  if (discFlag) {
                    disc = (
                      <button
                        className="btn_reg_30 cartshow_btn_D"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.showDiscount(obj)}
                      >
                        D
                      </button>
                    );
                  }

                  if (price === 0 && obj.itemType === "service") {
                    disc = (
                      <button
                        className="btn_reg_30 cartshow_btn_D"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.showPriceReason(obj)}
                      >
                        R
                      </button>
                    );
                  }

                  let nameext = "";
                  if (obj.eventTaggingOnly) {
                    nameext = " ";
                  }
                  return (
                    <div key={i}>
                      {/* <div className="row"> */}
                      <div
                        className={`${
                          bool ? "row-cart-color-dr" : "row-cart-color-lt"
                        }`}
                      >
                        <div className="col text-left">
                          <div className="row">
                            <div className="col-1 cart-del-btn-pos">
                              <button
                                className="btn_exit_del"
                                type="button"
                                style={{
                                  cursor: this.state.disabled
                                    ? "default"
                                    : "pointer",
                                }}
                                onClick={() => this.handleDelete(obj)}
                              >
                                <i className="fas fa-trash-alt" />
                              </button>
                            </div>
                            <div className="col-8 cart-items-name">
                              {itemName}
                              {nameext}
                              {disc}
                            </div>
                            <div className="col-1 text-center cart-qty">
                              {obj.quantity}
                            </div>
                            <div className="col-2 cart-cost text-right">
                              {this.commaFormattedCurrency(
                                price * obj.quantity
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {(bool = !bool)}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center cart-msg-err">
                {this.state.cartErrMsg}
              </div>
            )}
          </div>
        </div>
      );
    }

    let cartMsgPanel;
    if (this.state.serviceExists) {
      cartMsgPanel = (
        <div className="text-center cart-msg">{this.state.cartMsg}</div>
      );
    }

    let outputPanel;
    if (this.state.showCartFlag) {
      outputPanel = (
        <div>
          {cartPanel}
          {cartMsgPanel}
        </div>
      );
    }
    if (this.state.checkoutFlag) {
      let store = null;
      let coopStoreId = 0;
      if (this.props.store) {
        store = this.props.store;
        coopStoreId = this.props.store.coopStoreId;
      }
      outputPanel = (
        <div>
          <CheckoutCart
            itemsInCart={this.state.itemsInCart}
            community={this.state.community}
            store={store}
            coopStoreId={coopStoreId}
            cartId={this.state.cartId}
            caller={this.props.caller}
            returnToCaller={this.returnFromCheckout}
          />
        </div>
      );
    }

    return (
      <div>
        <div>
          {outputPanel}
          <ModalContainer />
        </div>
      </div>
    );
  }
}

CartShow.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CartShow));
