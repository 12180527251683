import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { sortArrayOfObjects } from "../../../../utils/sortArrayOfObjects";

import "./WriteAgreementClauses.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveAgreement = "/routes/dashboard/saveAgreement";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const readMeCode = "1162000000";

class WriteAgreementClauses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seqno: 1,
      anAgreementClause: "",

      agreements: [], 
      community: null,

      subscriptionType: "metered",
      meterValue: 0,
      fixedRent: 0,

      discountAmt: 0,
      discountForMonths: 0,

      graceInDays: 0,
      aprPercent: 0.0,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let seqno = 1;

    await this.getThisCommunity();

    let agreements = [];
    let subscriptionType = "metered";
    let discountAmt = 0;
    let discountForMonths = 0;
    let fixedRent = 0;
    let meterValue = 0;
    if (this.state.community && this.state.community.storeAgreementMaster) {
      agreements = [...this.state.community.storeAgreementMaster.agreements];
      seqno = this.state.community.storeAgreementMaster.agreements.length + 1;
      subscriptionType = this.state.community.storeAgreementMaster.subscriptionType;
      discountAmt = this.state.community.storeAgreementMaster.discountAmt;
      discountForMonths = this.state.community.storeAgreementMaster.discountForMonths;
      fixedRent = this.state.community.storeAgreementMaster.fixedRent;
      meterValue = this.state.community.storeAgreementMaster.meterValue;
    }

    this.setState({
      seqno,
      agreements,
      subscriptionType,
      discountAmt,
      discountForMonths,
      fixedRent,
      meterValue
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getThisCommunity = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);

      if (retData.data.status === "success") {
        await this.setState({
          community: retData.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          errMsg: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubscriptionType = async (e) => {
    this.setState({
      subscriptionType: e.target.value,
    });
  };

  onChangeSeqno = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);
      this.setState({
        seqno: val,
      });
    } else {
      this.setState({
        seqno: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  addTheQuestion = async () => {
    if (this.state.anAgreementClause !== "") {
      let currQuestions = [...this.state.agreements];
      let seq = this.state.seqno;

      let nextClause = {
        seqno: seq,
        anAgreementClause: this.state.anAgreementClause,
      };
      currQuestions.push(nextClause);

      let sorted = await sortArrayOfObjects(currQuestions, "seqno", "dsc");

      let sn = 1;
      let reSeqArr = [];
      sorted.forEach((kkk) => {
        kkk.seqno = sn;
        reSeqArr.push(kkk);
        sn++;
      });

      this.setState({
        seqno: currQuestions.length + 1,
        anAgreementClause: "",
        agreements: reSeqArr,
      });
    }
  };

  selectThisQToDelete = (obj) => {
    let newseq = 1;
    let newQ = [];
    this.state.agreements.forEach((elm) => {
      if (elm.seqno !== obj.seqno) {
        elm.seqno = newseq;
        newQ.push(elm);
        newseq++;
      }
    });
    this.setState({
      seqno: newQ.length + 1,
      agreements: newQ,
    });
  };

  saveTheQuestions = async () => {
    if ( parseFloat(this.state.discountAmt) > 0 && parseFloat(this.state.discountForMonths === 0)) {
      this.setState({
        errMsg: "You must specify number of months for the discount, when discount amount is more than zero.",
        errFlag: true
      })
    } else {
      let storeAgreementMaster = {
        agreements: this.state.agreements,
        subscriptionType: this.state.subscriptionType,
        fixedRent: parseFloat(this.state.fixedRent),
        meterValue: parseFloat(this.state.meterValue),
        discountAmt: parseFloat(this.state.discountAmt),
        discountForMonths: parseFloat(this.state.discountForMonths),
        graceInDays: parseFloat(this.state.graceInDays),
        aprPercent: parseFloat(this.state.aprPercent)
      };
      let input = {
        communityId: this.props.communityId,
        storeAgreementMaster,
      };
  
      let url = baandaServer + saveAgreement;
      try {
        let qret = await axios.post(url, input);
        if (qret.data.status === "success") {
          this.setState({
            errMsg: "Updated successfully",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: qret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    }
   
  };

  onChangeMonths = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) === 0) {
      
   
      this.setState({
        [e.target.name]: value,
      });
    }
  };

  onChangePrice = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('WriteAgreementClauses...');

    let infoButton;
    infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addButton;
    addButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.addTheQuestion}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        +Add
      </button>
    );

    let saveButton;
    saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.saveTheQuestions}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    // let reqIdPanel;
    // reqIdPanel = (
    //   <div className="row name-panel-row">
    //     <div className="col text-center radio-font-style">
    //       <strong>Request for Id OK?&nbsp;&nbsp;</strong>
    //       <div className="form-check form-check-inline">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="yes"
    //             checked={this.state.reqIdOK === "yes"}
    //             onChange={this.handleReqID}
    //           />{" "}
    //           Yes
    //         </label>
    //       </div>
    //       <div className="form-check form-check-inline">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="no"
    //             checked={this.state.reqIdOK === "no"}
    //             onChange={this.handleReqID}
    //           />{" "}
    //           no
    //         </label>
    //       </div>
    //     </div>
    //   </div>
    // );

    let subscriptionTypePanel;
    subscriptionTypePanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Subscription&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="metered"
                checked={this.state.subscriptionType === "metered"}
                onChange={this.handleSubscriptionType}
              />{" "}
              Metered %
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="rent"
                checked={this.state.subscriptionType === "rent"}
                onChange={this.handleSubscriptionType}
              />{" "}
              Monthly $
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="combo"
                checked={this.state.subscriptionType === "combo"}
                onChange={this.handleSubscriptionType}
              />{" "}
              Combo ($ + %)
            </label>
          </div>
        </div>
      </div>
    );

    let subValPanel;
    if (this.state.subscriptionType === "metered") {
      subValPanel = (
        <div>
          <input
            name="meterValue"
            type="number"
            value={this.state.meterValue}
            onChange={this.onChangePrice}
            className="coop-wr-agg-meter"
            step=".01"
            placeholder="0.00"
            autoComplete="off"
            spellCheck="false"
          />
          &nbsp;% of sales
        </div>
      );
    }

    if (this.state.subscriptionType === "rent") {
      subValPanel = (
        <div>
          <input
            name="fixedRent"
            type="number"
            value={this.state.fixedRent}
            onChange={this.onChangePrice}
            className="coop-wr-agg-meter"
            step=".01"
            placeholder="0.00"
            autoComplete="off"
            spellCheck="false"
          />
          &nbsp;$ monthly
        </div>
      );
    }

    if (this.state.subscriptionType === "combo") {
      subValPanel = (
        <div>
          <input
            name="meterValue"
            type="number"
            value={this.state.meterValue}
            onChange={this.onChangePrice}
            className="coop-wr-agg-meter"
            step=".01"
            placeholder="0.00"
            autoComplete="off"
            spellCheck="false"
          />
          &nbsp;% of sales & &nbsp;
          <input
            name="fixedRent"
            type="number"
            value={this.state.fixedRent}
            onChange={this.onChangePrice}
            className="coop-wr-agg-meter"
            step=".01"
            placeholder="0.00"
            autoComplete="off"
            spellCheck="false"
          />
          &nbsp;$ monthly
        </div>
      );
    }

    let discountPanel;
    discountPanel = (
      <div className="text-center coop-disc-col">
        Store will receive &nbsp;
        <input
          name="discountAmt"
          type="number"
          value={this.state.discountAmt}
          onChange={this.onChangePrice}
          className="coop-wr-agg-disc"
          step=".01"
          placeholder="0.00"
          autoComplete="off"
          spellCheck="false"
        />{" "}
        % discount for first &nbsp;
        <input
          name="discountForMonths"
          type="number"
          value={this.state.discountForMonths}
          onChange={this.onChangeMonths}
          className="coop-wr-agg-disc"
          step="1"
          placeholder="0"
          autoComplete="off"
          spellCheck="false"
        />{" "}months.
      </div>
    );

    let aprPanel;
    aprPanel = (
      <div className="text-center coop-disc-paydelay">
        <b>Payment delay </b>fine will ensue&nbsp;
        <input
          name="graceInDays"
          type="number"
          value={this.state.graceInDays}
          onChange={this.onChangeMonths}
          className="coop-wr-agg-fine"
          step=".01"
          placeholder="0"
          autoComplete="off"
          spellCheck="false"
        />{" "}
        &nbsp; post payment due date & will charge &nbsp;
        <input
          name="aprPercent"
          type="number"
          value={this.state.aprPercent}
          onChange={this.onChangePrice}
          className="coop-wr-agg-fine"
          step="1"
          placeholder="0"
          autoComplete="off"
          spellCheck="false"
        />% APR after that.
      </div>
    );

    let qPanel;
    qPanel = (
      <div>
        <div className="row">
          <div className="col-2 text-right store-q-lbl">Seq #</div>
          <div className="col-4 text-left">
            <input
              name="seqno"
              type="number"
              value={this.state.seqno}
              onChange={this.onChangeSeqno}
              className="store-q-seqno"
              //   step=".1"
              //   placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-6 text-center store-q-lbl"> {addButton}</div>
        </div>
        <div className="row">
          <div className="col-2 text-right">Clause</div>
          <div className="col-10">
            <textarea
              name="anAgreementClause"
              maxLength="2000"
              placeholder=""
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="store-q-textarea"
              onChange={this.onChange}
              value={this.state.anAgreementClause}
              required
            />
          </div>
        </div>
      </div>
    );

    let qListPanel;
    let bool = true;
    qListPanel = (
      <div>
        <div className="row store-q-list-pos">
          <div className="col-1 text-center store-q-list-header">Ops</div>
          <div className="col-1 text-center store-q-list-header">#</div>
          <div className="col-10 text-left store-q-list-header">
            Agreement clause
          </div>
        </div>
        <div className="new-q-list-box">
          {this.state.agreements.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-qlist-dr" : "row row-col-qlist-lt"
                  }
                >
                  <div className="col-1 text-center">
                    <button
                      className="btn_exit_main"
                      type="button"
                      onClick={() => this.selectThisQToDelete(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-1 text-left adm-pkg-line-text">
                    {obj.seqno}
                  </div>
                  <div className="col-10 text-left adm-pkg-line-text">
                    {obj.anAgreementClause}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="new-coop-qna-box">
        <div className="coop-q-heading">
          Agreement with a Store &nbsp;&nbsp;{infoButton}
        </div>

        {qPanel}
        {qListPanel}
        <div className="row">
          <div className="col-6">{subscriptionTypePanel}</div>
          <div className="col-6 coop-price-agr">{subValPanel}</div>
        </div>
        {discountPanel}
        {aprPanel}
        <div className="text-center coop-q-save-button-pos">{saveButton}</div>
        <div
          className={
            this.state.errFlag
              ? "text-center coop-q-msg-err"
              : "text-center coop-q-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

WriteAgreementClauses.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WriteAgreementClauses));
