import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { sortArrayOfDates } from "../../../../utils/sortArrayOfDates";

import QuoteMgmt from "./QuoteMgmt";

import "./TaskDetails.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateTaskDetails = "/routes/projects/updateTaskDetails";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111570000";

class TaskDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newTaskNote: "",

      showTaskDetails: true,
      showQuoteMgmt: false,
      showPMSelectFlag: false,
 
      nextStatus: "wip",
      nextState: "green",
      taskNotesForAll: [],

      errMsg: "",
      errFlag: false,
      theTask: null,

      changeOrderSeqno: 9999,
      manager: {
        pmBaandaId: 0,
        pmName: "",
        pmEmail: "",
        pmCell: "",
      },
      reasonForDeligation: "",

      okToProceedMsg: "",

      taskDoneCanceled: false,
    };
  }

  componentDidMount = async () => {
    let nextStatus = "wip";
    // console.log(
    //   "this.props.theTask.taskStatus:",
    //   this.props.theTask.taskStatus
    // );
    if (this.props.theTask.taskStatus !== "") {
      if (
        this.props.theTask.taskStatus === "new" ||
        this.props.theTask.taskStatus === "oktoproceed" ||
        this.props.theTask.taskStatus === "active" ||
        this.props.theTask.taskStatus === "ready"
      )
        nextStatus = "wip";
      else nextStatus = this.props.theTask.taskStatus;
    }

    let sorted = [];
    if (this.props.theTask.taskNotesForAll.length > 0) {
      sorted = await sortArrayOfDates(
        this.props.theTask.taskNotesForAll,
        "onDate",
        "asc"
      );
    }

    let manager = null;
    let reasonForDeligation = "";
    if (this.props.theTask.taskReligateChain.length === 0) {
      if (this.props.theTask.currentHandlerContact) {
        manager = {
          pmBaandaId: this.props.theTask.currentHandlerContact.baandaId,
          pmName: this.props.theTask.currentHandlerContact.name,
          pmEmail: this.props.theTask.currentHandlerContact.email,
          pmCell: this.props.theTask.currentHandlerContact.cell,
        };
      } else {
        manager = {
          pmBaandaId: this.props.auth.user.baandaId,
          pmName: this.props.auth.user.name,
          pmEmail: this.props.auth.user.email,
          pmCell: this.props.auth.user.cell.number,
        };
      }
      reasonForDeligation = "Auto delegation";
    } else {
      let trc =
        this.props.theTask.taskReligateChain[
          this.props.theTask.taskReligateChain.length - 1
        ];

      manager = {
        pmBaandaId: trc.pmBaandaId,
        pmName: trc.pmName,
        pmEmail: trc.pmEmail,
        pmCell: trc.pmCell,
      };
      reasonForDeligation = trc.reasonForDeligation;
    }

    let changeOrderSeqno = 9999;
    let okToProceedMsg = "";
    if (this.props.theTask.quotes.length > 0) {
      this.props.theTask.quotes.forEach((obj) => {
        // console.log("obj:", obj);
        changeOrderSeqno = obj.changeOrderSeqno;
        if (obj.proceedDecision === "oktoproceed")
          okToProceedMsg = "Client approved.";
        else okToProceedMsg = "";
      });
    }

    this.setState({
      theTask: this.props.theTask,
      taskNotesForAll: sorted,
      nextStatus,
      manager,
      nextState: this.props.theTask.taskState,
      changeOrderSeqno,
      reasonForDeligation,
      okToProceedMsg,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleTaskdoneConfirm = async () => {
    this.setState((prevstate) => ({
      taskDoneCanceled: !prevstate.taskDoneCanceled,
    }));
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  returnToTaskList = () => {
    this.props.returnToCaller("cancel");
  };

  handleNextStatus = async (e) => {
    this.setState({
      nextStatus: e.target.value,
    });
  };

  handleNextState = async (e) => {
    this.setState({
      nextState: e.target.value,
    });
  };

  handleTaskQuote = async () => {
    this.setState({
      showTaskDetails: false,
      showQuoteMgmt: true,
    });
  };

  returnFromQuote = async () => {
    this.setState({
      showTaskDetails: true,
      showQuoteMgmt: false,
      nextStatus: "wip",
    });
  };

  sendProgressNotes = async () => {
    if (this.state.newTaskNote.length > 15) {
      let url = baandaServer + updateTaskDetails;
      let newTaskNoteObj = {
        note: this.state.newTaskNote,
        party: "Business",
        noteById: this.props.auth.user.baandaId,
        byName: this.props.auth.user.name,
        byEmail: this.props.auth.user.email,
        onDate: new Date(),
      };
      let input = {
        taskId: this.props.theTask.taskId,
        newTaskNoteObj,
        taskState: this.state.nextState,
        taskStatus: this.state.nextStatus,
        manager: this.state.manager,
        reasonForDeligation: this.state.reasonForDeligation,
        currBaandaId: this.props.auth.user.baandaId,
      };

      // console.log("url: ", url, " input:", input);

      try {
        let tskret = await axios.post(url, input);
        // console.log("tskret.data:", tskret.data);
        if (tskret.data.status === "success") {
          let sorted = await sortArrayOfDates(
            tskret.data.Msg.taskNotesForAll,
            "onDate",
            "asc"
          );
          this.setState({
            errMsg: "Successfully updated",
            errFlag: false,
            theTask: tskret.data.Msg,
            taskNotesForAll: sorted,
            nextStatus: tskret.data.Msg.taskStatus,
            nextState: tskret.data.Msg.taskState,
            newTaskNote: "",
          });
        } else {
          this.setState({
            errMsg: tskret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: "Message Length must be 15 characters or more.",
        errFlag: true,
      });
    }
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  handlePMdeligation = async () => {
    // console.log("handlePMdeligation");
    this.setState({
      showTaskDetails: true,
      showQuoteMgmt: false,
      showPMSelectFlag: true,
    });
  };

  exitPMdeligation = async () => {
    this.setState({
      showTaskDetails: true,
      showQuoteMgmt: false,
      showPMSelectFlag: false,
    });
  };

  donePMdeligation = async (mem) => {
    // console.log("mem:", mem);
    let manager = {
      pmBaandaId: mem.baandaId,
      pmName: mem.name,
      pmEmail: mem.email,
      pmCell: mem.cell,
    };
    this.setState({
      showTaskDetails: true,
      showQuoteMgmt: false,
      showPMSelectFlag: false,
      manager,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('TaskDetails...');

    let sd = this.props.theTask;

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        onClick={this.returnToTaskList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let buttonPanel;
    if (!this.state.showPMSelectFlag) {
      buttonPanel = (
        <div className="text-center comm-task-detail-button-row">
          {this.state.nextStatus === "wip" ? (
            <button
              onClick={this.sendProgressNotes}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_reg_80"
            >
              Update
            </button>
          ) : null}

          {this.state.nextStatus !== "wip" && this.state.taskDoneCanceled ? (
            <button
              onClick={this.sendProgressNotes}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_reg_80"
            >
              Update
            </button>
          ) : null}

          <button
            onClick={this.handleTaskQuote}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            className="btn_reg_80"
          >
            Quote
          </button>
        </div>
      );
    }

    let SelectMemberButton = (
      <button
        onClick={this.handlePMdeligation}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_80 task-delegate-btn-pos"
      >
        Delegate
      </button>
    );

    let taskNotesPanel;
    let bool1 = true;
    if (this.state.taskNotesForAll.length > 0) {
      let retainedOn;
      if (this.props.theTask.quotes.length > 0) {
        let qt = this.props.theTask.quotes[0];
        if (qt.retainerPaidOn) {
          retainedOn = "Retained On: " + qt.retainerPaidOn.substring(0, 10);
        } else {
          retainedOn = "Not yet retained.";
        }
      } else {
        retainedOn = "Not yet retained.";
      }
      taskNotesPanel = (
        <div className="progress-note-row-pos">
          {/* <div className="text-left cust-conv-header">
            <u>Progressive Notes</u>
          </div> */}

          <div className="row task-details-notes-header">
            <div className="col-4 cust-conv-header text-left">
              <u>Notes & Comments</u>
            </div>
            <div className="col-8 cust-conv-header text-left">{retainedOn}</div>
          </div>
          <div className="row task-detail-notes-header-pos">
            <div className="col-2 text-center task-detail-notes-header-lbl-x">
              Party
            </div>
            <div className="col-2 text-center task-detail-notes-header-lbl-x">
              OnDate{" "}
            </div>
            <div className="col-4 text-left task-detail-notes-header-lbl-x">
              Name
            </div>
            <div className="col-4 text-left task-detail-notes-header-lbl-x">
              Email
            </div>
          </div>
          <div className="fixedsize-prev-task-notes">
            {this.state.taskNotesForAll.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-details-dr"
                        : "row row-col-details-lt"
                    }
                  >
                    <div className="col text-center">
                      <div className="row task-detail-notes-pos">
                        <div className="col-2 text-center task-detail-notes-txt">
                          {obj.party}
                        </div>
                        <div className="col-2 text-center task-detail-notes-txt">
                          {obj.onDate.substring(0, 10)}
                        </div>
                        <div className="col-4 text-left task-detail-notes-txt">
                          {obj.byName}
                        </div>
                        <div className="col-4 text-left task-detail-notes-txt">
                          {obj.byEmail}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className={
                            obj.party === "business"
                              ? "col text-left task-detail-notes-txt-note-biz"
                              : "col text-left task-detail-notes-txt-note-cust"
                          }
                        >
                          <b>Note</b>: &nbsp;{obj.note}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      taskNotesPanel = (
        <div className="progress-note-row-pos">
          <div className="text-left cust-conv-header">Progress Note</div>
          <div className="fixedsize-prev-task-notes">
            <div className="text-center comm-task-dtl-nonote">
              No comments exists at this point of time.
            </div>
          </div>
        </div>
      );
    }

    let nextStatusPanel;
    nextStatusPanel = (
      <div>
        <div className="row biz-detail-next-status-row">
          <div className="col-2 text-right biz-detail-show-lbl">Next</div>
          <div className="col text-left radio-font-style">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="wip"
                  checked={this.state.nextStatus === "wip"}
                  onChange={this.handleNextStatus}
                />{" "}
                In progress
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="done"
                  checked={this.state.nextStatus === "done"}
                  onChange={this.handleNextStatus}
                />{" "}
                Done
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="canceled"
                  checked={this.state.nextStatus === "canceled"}
                  onChange={this.handleNextStatus}
                />{" "}
                Canceled
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let currentState;
    currentState = (
      <div>
        <div className="row biz-detail-next-status-row">
          <div className="col-2 text-right biz-detail-show-lbl">State</div>
          <div className="col text-left radio-font-style">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="green"
                  checked={this.state.nextState === "green"}
                  onChange={this.handleNextState}
                />{" "}
                <font color="green">Green</font>
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="yellow"
                  checked={this.state.nextState === "yellow"}
                  onChange={this.handleNextState}
                />{" "}
                <font color="#bda640">Yellow</font>
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="red"
                  checked={this.state.nextState === "red"}
                  onChange={this.handleNextState}
                />{" "}
                <font color="#f50f07">Red</font>
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let stateColor = "#000000";
    if (sd.taskState === "green") stateColor = "#298c36";
    if (sd.taskState === "yellow") stateColor = "#f7df28";
    if (sd.taskState === "red") stateColor = "#f72f07";

    let selectMemberPanel;
    if (this.state.showPMSelectFlag) {
      let bool2 = true;
      selectMemberPanel = (
        <div className="member-select-box">
          <div className="text-center task-pm-select-header">
            Delegate a Proj. Mgr. &nbsp;&nbsp;
            <button
              onClick={this.exitPMdeligation}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_back_main"
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div>
            {this.props.community.members.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool2
                        ? "row row-col-details-dr"
                        : "row row-col-details-lt"
                    }
                  >
                    <div className="col-10 text-left task-pm-select-lbl">
                      {obj.name} ({obj.email} |{" "}
                      {this.formatPhoneNumber(obj.cell)})
                    </div>
                    <div className="col-2 text-center">
                      <button
                        onClick={() => this.donePMdeligation(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        className="btn_reg_40"
                      >
                        Sel
                      </button>
                    </div>
                  </div>
                  {(bool2 = !bool2)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    console.log(
      "1 **** this.state.changeOrderSeqno: ",
      this.state.changeOrderSeqno
    );
    // let changeOrderSeqno = null;
    let COOKMsg = null;
    COOKMsg = (
      <div className="col-6 text-left biz-detail-show-txt-cono">
        <b>Client Decision:</b>&nbsp;&nbsp;
        {this.props.theTask.clientDecision === "negotiate" ? (
          <font color="blue">Request to negotiate</font>
        ) : null}
        {this.props.theTask.clientDecision === "cancel" ? (
          <font color="red">Cancel order</font>
        ) : null}
        {this.props.theTask.clientDecision === "oktoproceed" ? (
          <font color="green">OK to Proceed</font>
        ) : null}
      </div>
    );

    let directiveMsg = "";
    if (this.props.theTask.controller === "seller") {
      if (this.props.theTask.taskStatus === "retainerinvoiced") {
        directiveMsg =
          "Customer to activate the project by paying the retainer.";
      } else if (this.props.theTask.taskStatus === "active") {
        directiveMsg = "Active. Proceed to complete the task.";
      } else {
        directiveMsg = "Your turn to act on the task.";
      }
    } else {
      directiveMsg = "Wait for customer's decision / action.";
    }

    let showDetailsPanel = (
      <div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right biz-detail-show-lbl">Name</div>
          <div className="col-6 text-left biz-detail-show-txt">
            {sd.taskName}
          </div>
          <div className="col-4 text-center biz-detail-show-txt">
            Id: {sd.taskId}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right biz-detail-show-lbl">
            Description
          </div>
          <div className="col-10 text-left biz-detail-description-box-x">
            {sd.taskDescription}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right biz-detail-show-lbl">item Name</div>
          <div className="col-10 text-left biz-detail-show-txt">
            {sd.itemName}
          </div>
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right biz-detail-show-lbl">
            Status & state
          </div>
          <div className="col-4 text-left biz-detail-show-txt">
            {sd.taskStatus} & <font color={stateColor}>{sd.taskState}</font>{" "}
            &nbsp;&nbsp;
          </div>

          {COOKMsg}
        </div>
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right biz-detail-show-lbl">Customer</div>
          <div className="col-10 text-left biz-detail-show-txt">
            {sd.targetContact.name} ({sd.targetContact.email} |{" "}
            {this.formatPhoneNumber(sd.targetContact.cell)})
          </div>
        </div>
        {selectMemberPanel}
        <div className="row biz-detail-show-row">
          <div className="col-2 text-right biz-detail-show-lbl">Proj. Mgr.</div>
          <div className="col-8 text-left biz-detail-show-txt">
            {this.state.manager.pmName} ({this.state.manager.pmEmail} |{" "}
            {this.formatPhoneNumber(this.state.manager.pmCell)} |{" "}
            {this.state.manager.pmBaandaId})
          </div>
          <div className="col-2 text-left">
            {this.props.theTask.coopStoreId === 0 ? SelectMemberButton : null}
          </div>
        </div>
        {this.props.theTask.coopStoreId === 0 ? (
          <div className="row biz-detail-reason-row">
            <div className="col-2 text-right biz-detail-show-lbl">Reason</div>
            <div className="col-10 text-left">
              <input
                name="reasonForDeligation"
                type="text"
                value={this.state.reasonForDeligation}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="task-pm-deligation-reason"
                placeholder=""
              />
            </div>
          </div>
        ) : null}

        {taskNotesPanel}
        <div className="row">
          <div className="col-6 text-right">{nextStatusPanel}</div>
          <div className="col-6 text-left">{currentState}</div>
        </div>

        <div className="row biz-detail-comment-row">
          <div className="col-2 text-right biz-detail-show-lbl">
            New Comment
          </div>
          <div className="col-10 text-left">
            <textarea
              name="newTaskNote"
              maxLength="1000"
              placeholder="Notes / comments / requests etc."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="comm-task-notes"
              onChange={this.onChange}
              value={this.state.newTaskNote}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 text-center seller-task-detail-msg">
            {directiveMsg}
          </div>
          {this.state.nextStatus === "wip" ? (
            <div className="col-md-4 text-center">&nbsp;</div>
          ) : (
            <div className="col-md-4 text-center task-close-msg">
              <Checkbox
                checked={this.state.taskDoneCanceled}
                onChange={this.handleTaskdoneConfirm}
              />
              &nbsp;Confirm. Update is final (task-closed).
            </div>
          )}

          <div className="col-md-4 text-center">{buttonPanel}</div>
        </div>
      </div>
    );

    let outputPanel;

    if (this.state.showTaskDetails) {
      outputPanel = (
        <div className="biz-task-detail-box">
          <div className="text-center biz-task-details-header">
            Task Details {exitButton} {helpModalButton}
          </div>
          {showDetailsPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center biz-task-detail-msg-err"
                : "text-center biz-task-detail-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }
    if (this.state.showQuoteMgmt) {
      outputPanel = (
        <div>
          <QuoteMgmt
            theTask={this.props.theTask}
            communityId={this.props.communityId}
            community={this.props.community}
            returnToCaller={this.returnFromQuote}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

TaskDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskDetails)
);
