export const arraySortNum = (data, property, ascDsc) => {

    let sorted;
    sorted = data.sort(compare(property, ascDsc));
  
    return sorted;
  };

  
  const compare = (property, ascDsc) => {
    let updown = 1;
    if (ascDsc === "asc") {
      updown = -1;
    }
    return function(a, b) {
      const A = parseFloat(a[property]);
      const B = parseFloat(b[property]);
      // console.log("A:", A, " B:", B);
      if (A > B) {
        return 1 * updown;
      } else if (A < B) {
        return -1 * updown;
      } else {
        return 0;
      }
    };
  };
  
  
 
  