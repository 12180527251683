import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import ViewATarget from "./viewTarget/ViewATarget";
import MergeTargets from "./merge/MergeTargets";
import UploadNewTarget from "./upload/UploadNewTarget";
import GroupTarget from "./group/GroupTarget";

import "./ManageTarget.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getExistingTargetNames = "/routes/interact/getExistingTargetNames?";

const readMeCode = "0000000000";

class ManageTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opsType: "view",
      targetListNames: [],
      selectedTargetName: "",

      brdSelectHeight: 1,

      manageGeneralFlag: true,
      ViewFlag: false,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getTargetListNames();

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTargetListNames = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getExistingTargetNames + param;

    try {
      let tgret = await axios.get(url);

      if ((tgret.data.status = "success")) {
        this.setState({
          targetListNames: tgret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          targetListNames: [],
          errMsg: tgret.data.status,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleOpsType = async (e) => {
    this.setState({
      opsType: e.target.value,
    });
  };

  exitTargetPurchase = () => {
    this.props.returnToCaller();
  };

  handleFromAddSelect = async () => {
    let value = document.getElementById("brdSelectTragetNames").value;

    let data = JSON.parse(value);

    let selectedTargetName = data.targetListName;
    this.setState({
      selectedTargetName,
      brdSelectHeight: 1,
      manageGeneralFlag: false,
      ViewFlag: true,
    });
  };

  onFocusTgName = async () => {
    this.setState({
      brdSelectHeight: 6,
    });
  };

  onBlurTgName = async () => {
    this.setState({
      brdSelectHeight: 1,
    });
  };

  returnFromOps = async () => {
    await this.getTargetListNames();
    this.setState({
      opsType: "view",
      manageGeneralFlag: true,
      ViewFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('MAnageTarget')

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitTargetPurchase}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let opsTypePanel;
    opsTypePanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Ops Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="view"
                checked={this.state.opsType === "view"}
                onChange={this.handleOpsType}
              />{" "}
              View
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="merge"
                checked={this.state.opsType === "merge"}
                onChange={this.handleOpsType}
              />{" "}
              Merge
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="upload"
                checked={this.state.opsType === "upload"}
                onChange={this.handleOpsType}
              />{" "}
              Upload
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="group"
                checked={this.state.opsType === "group"}
                onChange={this.handleOpsType}
              />{" "}
              Groups
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="purchase"
                checked={this.state.opsType === "purchase"}
                onChange={this.handleOpsType}
                disabled
              />{" "}
              Purchase
            </label>
          </div>
        </div>
      </div>
    );

    let targetnamelist;
    let targetNamesPanel;
    console.log(
      "this.state.targetListNames.length:",
      this.state.targetListNames.length
    );
    if (this.state.targetListNames.length > 0) {
      console.log("this.state.targetListNames:", this.state.targetListNames);
      targetnamelist = this.state.targetListNames.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.targetListName}&nbsp;
          </option>
        );
      });

      targetNamesPanel = (
        <div className="ship-addr-from-pos">
          <div className="row ship-addr-dropdown">
            <div className="col text-center ">
              <select
                size={this.state.brdSelectHeight}
                id="brdSelectTragetNames"
                name="brdSelectTragetNames"
                onChange={this.handleFromAddSelect}
                className="brd-mgmt-target-list"
                onFocus={this.onFocusTgName}
                onBlur={this.onBlurTgName}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select a target name
                </option>
                {targetnamelist}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let targetViewPanel;
    if (this.state.opsType === "view") {
      targetViewPanel = (
        <div>
          <div className="row">
            <div className="col-4 text-center">&nbsp;</div>
            <div className="col-4 text-left">{targetNamesPanel}</div>
            <div className="col-4 text-center">&nbsp;</div>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.manageGeneralFlag) {
      outputPanel = (
        <div className="">
          {/* <div className="broadcast-purchase-box"> */}
          <div className="brd-buy-target-header text-center">
            Manage Target Data {backButton} {infoModalButton}
          </div>
          {opsTypePanel}
          {targetViewPanel}
        </div>
      );
    }
    if (this.state.opsType === "view" && this.state.ViewFlag) {
      outputPanel = (
        <div className="">
          {/* <div className="broadcast-purchase-box"> */}
          <div className="brd-buy-target-header text-center">
            Manage Target Data {backButton} {infoModalButton}
          </div>
          <ViewATarget
            communityId={this.props.communityId}
            selectedTargetName={this.state.selectedTargetName}
            returnToCaller={this.returnFromOps}
          />
        </div>
      );
    }

    if (this.state.opsType === "merge") {
      outputPanel = (
        <div className="">
          {/* <div className="broadcast-purchase-box"> */}
          <div className="brd-buy-target-header text-center">
            Manage Target Data {backButton} {infoModalButton}
          </div>
          <MergeTargets
            communityId={this.props.communityId}
            targetListNames={this.state.targetListNames}
            returnToCaller={this.returnFromOps}
          />
        </div>
      );
    }

    if (this.state.opsType === "upload") {
      outputPanel = (
        <div className="">
          <div className="brd-buy-target-header text-center">
            Upload New Target Data {backButton} {infoModalButton}
          </div>
          <UploadNewTarget
            communityId={this.props.communityId}
            targetListNames={this.state.targetListNames}
            returnToCaller={this.returnFromOps}
          />
        </div>
      );
    }

    if (this.state.opsType === "group") {
      outputPanel = (
        <div className="">
          <div className="brd-buy-target-header text-center">
            Form Target from a Group {backButton} {infoModalButton}
          </div>
          <GroupTarget
            communityId={this.props.communityId}
            targetListNames={this.state.targetListNames}
            broadcastId={this.props.broadcastId}
            returnToCaller={this.returnFromOps}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ManageTarget.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ManageTarget));
