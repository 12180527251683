import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import PresentMaps from "../../../../community/PresentMaps";
import SendEmailToOwner from "./SendEmailToOwner";

import "./MarketLanding.css";

import SomeStore from "../../../../images/someStore.jpg";
import ImageNotAvailable from "../../../../images/notavailable4.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getProfile = "/routes/myaccount/getProfile?";

class MarketLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      creatorSelfi: "",
      commPromoImage: "",
      ad1Image: "",
      ad2Image: "",
      ad3Image: "",
      ad1ImageSmall: "",
      ad2ImageSmall: "",
      ad3ImageSmall: "",
      bannerLarge: "",
      bannerSmall: "",

      errMsg: "",
      errFlag: false,

      creatorVerbose: "Creators",
      communityVerbose: "Community",

      missionStatement: "",

      profile: null,

      showImage: true,
      showVideo: false,
      showMap: false,
      videoExists: false,
      imageExists: false,

      daLoaded: false,
      showEmailBoxFlag: false,
    };
  }

  componentDidMount = async () => {
    // console.log("MarketLanding componentDidMount ...");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    if (this.props.community) await this.getCreatorProfile();

    if (this.props.community && this.state.profile) {
      await this.setDAForDisplay();
    }

    await this.setState({
      deviceSize,
    });
  };

  setDAForDisplay = async () => {
    let creatorSelfi = "",
      ad1Image = "",
      ad2Image = "",
      ad3Image = "",
      ad1ImageSmall = "",
      ad2ImageSmall = "",
      ad3ImageSmall = "",
      commPromoImage = "",
      commPromoVideo = "",
      bannerLarge = "",
      bannerSmall = "";

    let videoExists = false,
      imageExists = false;

    this.props.community.fileUploads.forEach((obj) => {
      if (obj.type === "commPromoImage") {
        commPromoImage = obj.location;
        imageExists = true;
      }
      if (obj.type === "commPromoVideo") {
        commPromoVideo = obj.location;
        videoExists = true;
      }

      if (obj.type === "ad1Imagesmall") ad1ImageSmall = obj.location;
      if (obj.type === "ad2Imagesmall") ad2ImageSmall = obj.location;
      if (obj.type === "ad3Imagesmall") ad3ImageSmall = obj.location;

      if (obj.type === "ad1Image") ad1Image = obj.location;
      if (obj.type === "ad2Image") ad2Image = obj.location;
      if (obj.type === "ad3Image") ad3Image = obj.location;

      if (obj.type === "creatorSelfi") creatorSelfi = obj.location;
      if (obj.type === "bannerLarge") bannerLarge = obj.location;
      if (obj.type === "bannerSmall") bannerSmall = obj.location;
    });

    if (commPromoImage === "") {
      this.props.community.fileUploads.forEach((obj) => {
        if (obj.type === "image") {
          commPromoImage = obj.location;
          imageExists = true;
        }
      });
    }
    if (commPromoVideo === "") {
      this.props.community.fileUploads.forEach((obj) => {
        if (obj.type === "vedio") {
          commPromoVideo = obj.location;
          videoExists = true;
        }
      });
    }

    let missionStatement = "";
    if (this.props.community.missionStatement)
      missionStatement = this.props.community.missionStatement;

    if (creatorSelfi === "") {
      if (
        this.state.profile.fileUploads &&
        this.state.profile.fileUploads.location !== ""
      ) {
        creatorSelfi = this.state.profile.fileUploads.location;
      } else {
        creatorSelfi = ImageNotAvailable;
      }
    }

    this.setState({
      creatorSelfi,
      commPromoImage,
      commPromoVideo,
      ad1Image,
      ad2Image,
      ad3Image,
      ad1ImageSmall,
      ad2ImageSmall,
      ad3ImageSmall,
      videoExists,
      imageExists,
      bannerLarge,
      bannerSmall,
      daLoaded: true,
      missionStatement,
    });
  };

  getCreatorProfile = async () => {
    // console.log("in getCreateProfile ...");
    let email = "";
    this.props.community.members.forEach((eml) => {
      if (eml.role === "creator") email = eml.email;
    });
    let params = "email=" + email;
    let url = baandaServer + getProfile + params;
    // console.log("url:", url);
    try {
      let proret = await axios.get(url);
      // console.log("proret:", proret);

      if (proret.data.status === "success") {
        this.setState({
          profile: proret.data.Msg,
        });
      } else {
        this.setState({
          errMsg: proret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  showvideoPlease = () => {
    this.setState({
      showImage: false,
      showVideo: true,
      showMap: false,
    });
  };

  showImagePlease = () => {
    this.setState({
      showImage: true,
      showVideo: false,
      showMap: false,
    });
  };

  showMapPlease = () => {
    this.setState({
      showImage: false,
      showVideo: false,
      showMap: true,
    });
  };

  phoneFormat = (inp) => {
    // console.log("phone inp:", inp);
    let input = inp;
    if (inp.length === 12) input = inp.substring(2, 12);
    if (inp.length === 11) input = inp.substring(1, 11);
    // console.log("input: ", input);
    if (!input || isNaN(input))
      return `input must be a number was sent ${input}`;
    if (typeof input !== "string") input = input.toString();
    if (input.length === 10) {
      return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (input.length < 10) {
      return "was not supplied enough numbers please pass a 10 digit number";
    } else if (input.length > 10) {
      return "was supplied too many numbers please pass a 10 digit number";
    } else {
      return "something went wrong";
    }
  };

  returnFromMap = () => {
    // console.log('Returning from map...')
    this.setState({
      showMap: false,
      showImage: true,
    });
  };

  showEmailBox = async () => {
    this.setState({
      showEmailBoxFlag: true,
    });
  };

  returnFromEmail = () => {
    this.setState({
      showEmailBoxFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("MarketLanding...");

    let videoButton;
    if (this.state.videoExists) {
      videoButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.showvideoPlease}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === "big" ? (
            "Video"
          ) : (
            <i className="fas fa-video" />
          )}
        </button>
      );
    }

    let image;
    if (this.state.deviceSize === "big") {
      image = "Image";
    } else {
      image = "Img";
    }

    let imageButton;
    if (this.state.imageExists) {
      imageButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.showImagePlease}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === "big" ? (
            image
          ) : (
            <i className="fas fa-image" />
          )}
        </button>
      );
    }

    let mapButton;
    // if (this.state.imageExists) {
    mapButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.showMapPlease}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {this.state.deviceSize === "big" ? (
          "Map"
        ) : (
          <i className="fas fa-map-marked-alt" />
        )}
      </button>
    );
    // }

    let showDAPanel;
    if (this.state.showImage) {
      showDAPanel = (
        <div className="">
          <img
            src={this.state.creatorSelfi}
            className="main-landing-creator-img"
            alt="commImg"
          />
        </div>
      );
    }

    let showCommDA;
    if (this.state.showImage && this.state.commPromoImage) {
      showCommDA = (
        <div className="text-center">
          <img
            src={this.state.commPromoImage}
            className="main-landing-comm-img"
            alt="commImg"
          />
        </div>
      );
    }

    if (this.state.showVideo && this.state.commPromoVideo) {
      let vext =
        "video/" +
        this.state.commPromoVideo.split(/[#?]/)[0].split(".").pop().trim();
      showCommDA = (
        <div className="text-center">
          <video className="main-land-video" controls>
            <source src={this.state.commPromoVideo} type={vext} />
          </video>
        </div>
      );
    }

    if (this.state.showMap) {
      showCommDA = (
        <div className="text-center main-land-map-placement">
          <PresentMaps
            baandaId={this.props.auth.user.baandaId}
            communityLocation={this.props.community.centerOfActivityLocation}
            communityCaption={this.props.community.commCaption}
            returnToCaller={this.returnFromMap}
          />
        </div>
      );
    }

    let creatorPanel;
    if (this.props.community && this.state.profile) {
      let timezone = "";
      if (
        JSON.parse(this.state.profile.geoCentricInfo) &&
        JSON.parse(this.state.profile.geoCentricInfo).timezone !== ""
      ) {
        timezone = JSON.parse(this.state.profile.geoCentricInfo).timezone;
      }
      let creators = "";
      if (this.props.community.entrepreneurs !== "")
        creators = this.props.community.entrepreneurs;
      else
        creators =
          this.state.profile.preferredName +
          " (" +
          this.state.profile.formalName +
          ")";
      if (this.state.deviceSize === "big") {
        creatorPanel = (
          <div>
            {showDAPanel}
            <div className="row">
              <div className="col main-landing-cr-body text-center">
                {creators}
              </div>
            </div>
            {timezone !== "" ? (
              <div className="row">
                <div className="col main-landing-cr-body text-center">
                  {JSON.parse(this.state.profile.geoCentricInfo).timezone}
                </div>
              </div>
            ) : null}

            <div className="row landing-main-contact">
              <div className="col  text-center main-landing-comm-lbltop">
                Contact
              </div>
            </div>
            <div className="row">
              <div className="col text-center main-landing-comm-txt">
                {this.props.community.contact[0].contactName}
              </div>
            </div>
            <div className="row">
              <div className="col text-center main-landing-comm-txt">
                <button
                  className="landing-email-button"
                  type="button"
                  onClick={this.showEmailBox}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {this.props.community.contact[0].contactEmail}
                </button>
                {/* {this.props.community.contact[0].contactEmail} */}
              </div>
            </div>

            <div className="row">
              <div className="col text-center main-landing-comm-txt">
                {this.phoneFormat(this.props.community.contact[0].contactCell)}
              </div>
            </div>
          </div>
        );
      } else {
        let telno = "tel:" + this.props.community.contact[0].contactCell;
        // console.log('telno:', telno);
        creatorPanel = (
          <div className="">
            <div className="row">
              <div className="col-6 text-center">{showDAPanel}</div>
              <div className="col-6">
                <div className="row">
                  <div className="col main-landing-cr-body text-center">
                    TZ: {JSON.parse(this.state.profile.geoCentricInfo).timezone}
                  </div>
                </div>
                <div className="row landing-main-contact">
                  <div className="col  text-center main-landing-comm-lbltop">
                    Contact
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center main-landing-comm-txt-small">
                    {this.props.community.contact[0].contactName}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center main-landing-comm-txt">
                    <button
                      className="landing-email-button"
                      type="button"
                      onClick={this.showEmailBox}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      {this.props.community.contact[0].contactEmail}
                    </button>
                    {/* {this.props.community.contact[0].contactEmail} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col text-center main-landing-comm-txt-small">
                    Call{" "}
                    <a href={telno}>
                      {this.phoneFormat(
                        this.props.community.contact[0].contactCell
                      )}
                    </a>
                    {/* Call <a href={telno}>{this.props.community.contact[0].contactCell}</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    let communityPanel;
    if (this.props.community && this.state.profile) {
      communityPanel = (
        <div>
          <div className="row">
            <div className="col-10">{showCommDA}</div>
            <div className="col-2">
              <div className="row">
                <div className="col text-center">
                  {this.state.showMap ? null : mapButton}
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  {this.state.showImage ? videoButton : imageButton}
                </div>
              </div>
            </div>
          </div>

          {this.state.missionStatement !== "" ? (
            <div className="row">
              <div className="col text-center land-mission-stmt">
                {/* <p align="justify" className="main-landing-comm-txt"> */}
                  {this.state.missionStatement}
                {/* </p> */}
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col main-landing-comm-desc">
              <p align="justify" className="main-landing-comm-txt">
                {this.props.community.commDescription}
              </p>
            </div>
          </div>
        </div>
      );
    }

    let bigAdOK = true;
    if (this.state.daLoaded && !this.state.ad1Image) bigAdOK = false;

    let smallAdOK = true;
    if (this.state.daLoaded && !this.state.ad1ImageSmall) smallAdOK = false;

    let adverstisementPanel;
    if (this.state.deviceSize === "big") {
      if (bigAdOK) {
        adverstisementPanel = (
          <div className="text-center">
            <div className="main-landing-adv-box">
              <div className="outer-land">
                <div className="inner-land">
                  <img
                    src={this.state.ad1Image}
                    width="100%"
                    height="140px"
                    alt=""
                  />
                  <img
                    src={this.state.ad2Image}
                    width="100%"
                    height="140px"
                    alt=""
                  />
                  <img
                    src={this.state.ad3Image}
                    width="100%"
                    height="140px"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        adverstisementPanel = (
          <div className="text-center">
            <img src={SomeStore} width="100%" height="140px" alt="" />
          </div>
        );
      }
    } else {
      if (smallAdOK) {
        adverstisementPanel = (
          <div className="text-center">
            <div className="">
              <div className="outer-land">
                <div className="inner-land">
                  <img
                    src={this.state.ad1ImageSmall}
                    width="100%"
                    height="70px"
                    alt=""
                  />
                  <img
                    src={this.state.ad2ImageSmall}
                    width="100%"
                    height="70px"
                    alt=""
                  />
                  <img
                    src={this.state.ad3ImageSmall}
                    width="100%"
                    height="70px"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        adverstisementPanel = (
          <div className="text-center">
            <img src={SomeStore} width="100%" height="70px" alt="" />
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.showEmailBoxFlag) {
      outputPanel = (
        // <div className="text-center">
        //   <SendEmailToOwner returnToCaller={this.returnFromEmail} />
        // </div>
        <div>
          <div className="brd-buy-target-header text-center">
            <SendEmailToOwner
              toEmail={this.props.community.contact[0].contactEmail}
              communityId={this.props.communityId}
              returnToCaller={this.returnFromEmail}
            />
          </div>
        </div>
      );
    } else {
      if (this.state.deviceSize === "big") {
        // console.log(
        //   "this.state.showEmailBoxFlag:",
        //   this.state.showEmailBoxFlag
        // );
        outputPanel = (
          <div>
            <div className="row">
              <div className="col-md-3 main-landing-creator-box">
                {creatorPanel}
              </div>
              <div className="col-md-9 main-landing-comm-box">
                {communityPanel}
              </div>
            </div>

            <div className="mkt-landing-adv-row">{adverstisementPanel}</div>
          </div>
        );
      } else {
        outputPanel = (
          <div>
            <div className="row">
              <div className="col main-landing-creator-box">{creatorPanel}</div>
            </div>
            {communityPanel}
            <div className="mkt-landing-adv-row">{adverstisementPanel}</div>
          </div>
        );
      }
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

MarketLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketLanding)
);
