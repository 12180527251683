import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import ReactLoading from "react-loading";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import UpdateIndividual from "./UpdateIndividual";
import InpUpdtExternalAcc from "./IndUpdtExternalAcc";
import IndUpdateIdentity from "./IndUpdateIdentity";
import IndividualFinalUpdate from "./IndividualFinalUpdate";

import "./IndUpdateSetup.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
// const checkAddressValidity = "/routes/shared/checkAddressValidity?";
const getAccountFull = "/routes/stripe/account/getAccountFull?";

const readMeCode = "1151150000";

class IndUpdateSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stripeAccId: "",
      businessType: "",

      flowIndex: 0,
      nextFlag: false,
      backFlag: false,

      bpmcc: "",
      bpname: "",
      bpproduct_description: "",
      bpurl: "",

      deviceSize: "",
      communityId: 0,
      commonButtonsFlag: false,

      business_profile: {},
      individual: {},
      external_account: {},
      id_files: {},

      requested_capabilities: ["card_payments", "transfers"],
      loadingFlag: false,
      businessProfileSkip: false,
      externalAccountSkip: false,
      individualSkip: false,
      IdpictureSkip: false,
      noUpdateFlag: false,
      ccdata: {},
    };
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
    // console.log("onChange caphone:", this.state.caphone);
  };

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let businessProfileSkip = false;
    let externalAccountSkip = false;
    let individualSkip = false;
    if (this.props.pendingReqLength === 0) {
      businessProfileSkip = true;
      externalAccountSkip = true;
      individualSkip = true;
    }

    this.setState({
      deviceSize,
      loadingFlag: true,
      businessProfileSkip,
      externalAccountSkip,
      individualSkip,
    });

 
    await this.loadDataFromStripe();
  };

  loadDataFromStripe = async () => {
    try {
      let param =
        "accId=" +
        this.props.stripeAccId +
        "&communityId=" +
        this.props.communityId;
      let url = baandaServer + getAccountFull + param;

      let acc = await axios.get(url);

      let dacc = acc.data.msg.account;

      let bstripe = acc.data.msg.communityStripe;

      let bpmcc, bpname, bpproduct_description, bpurl;
      if (dacc.business_profile.mcc) {
        bpmcc = dacc.business_profile.mcc;
      } else bpmcc = "7399";
      if (dacc.business_profile.name) {
        bpname = dacc.business_profile.name;
      } else bpname = "";
      if (dacc.business_profile.product_description) {
        bpproduct_description = dacc.business_profile.product_description;
      } else {
        bpproduct_description = "";
      }
      if (dacc.business_profile.url) {
        bpurl = dacc.business_profile.url;
      } else bpurl = "";

      let individual = this.state.individual;
      if (dacc.individual) individual = dacc.individual;

      let bprof = {
        mcc: bpmcc,
        business_name: bpname,
        product_description: bpproduct_description,
        url: bpurl,
      };

      let individualacc = null;
      if (dacc.individual) individualacc = dacc.individual;
      await this.loadIndividualDataFromStripe(individualacc, bstripe.stripe);

      let extac = null;
      if (dacc.external_accounts) extac = dacc.external_accounts;
      await this.loadExternalAccDataFromStripe(extac, bstripe.stripe);

      await this.initiateIdImages(dacc.id, bstripe.stripe);

      this.setState({
        communityId: this.props.communityId,
        bpmcc,
        bpname,
        bpproduct_description,
        bpurl,
        stripeAccId: this.props.stripeAccId,
        businessType: this.props.businessType,
        loadingFlag: false,
        stripe: bstripe,
        commonButtonsFlag: true,
        business_profile: bprof,
        individual,
      });
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  loadExternalAccDataFromStripe = async (extacc, stripe) => {
    // console.log("loadExternalAccDataFromStripe extacc:", extacc, '  stripe:', stripe);
    let dlen = extacc.data.length;
    let extdata, exta; // local external account
    if (dlen > 0) {
      extdata = extacc.data[0]; // for now, we will only condier record 1 of the array of external_account
    }
    // console.log("dlen:", dlen);
    let baccno = "";
    if (stripe && stripe.bank_account_no) baccno = stripe.bank_account_no;
    // console.log("baccno:", baccno);
    if (dlen === 0) {
      exta = {
        object: "bank_account",
        account_holder_name: "",
        account_holder_type: this.props.businessType,
        bank_name: "",
        country: "US",
        currency: "usd",
        last4: "",
        routing_number: "",
        account_number: "",
      };
    } else {
      exta = {
        object: "bank_account",
        account_holder_name: extdata.account_holder_name,
        account_holder_type: extdata.account_holder_type,
        bank_name: extdata.bank_name,
        country: extdata.country,
        currency: extdata.currency,
        last4: "",
        routing_number: extdata.routing_number,
        account_number: baccno,
      };
    }
    // console.log('exta:', exta);
    await this.setState({
      external_account: exta,
    });

    return true;
  };

  loadIndividualDataFromStripe = async (indacc, stripe) => {
    // console.log("loadIndividualDataFromStripe indacc:", indacc);

    let ssn4 = "";
    if (stripe.individual_last4ssn) ssn4 = stripe.individual_last4ssn;

    let individual;
    if (!indacc) {
      // console.log("loadIndividualDataFromStripe here 1111");
      individual = {
        object: "individual",
        first_name: "",
        last_name: "",
        email: this.props.auth.user.email,
        phone: "",
        dob: new Date(),
        address: {
          city: "",
          country: "US",
          line1: "",
          line2: "",
          postal_code: "",
          state: "",
        },
        gender: "male",
        ssn: "",
        ssn_last_4: ssn4,
        verification: {},
      };
    } else {
      // console.log("loadIndividualDataFromStripe here 2222");

      let idob = moment([indacc.dob.year, indacc.dob.month, indacc.dob.day]);
      // console.log("Moment d2:", idob.format("ll"));
      individual = {
        object: "individual",
        first_name: indacc.first_name,
        last_name: indacc.last_name,
        email: indacc.email,
        phone: indacc.phone,
        dob: idob.toDate(),
        address: {
          city: indacc.address.city,
          country: "US",
          line1: indacc.address.line1,
          line2: indacc.address.line2,
          state: indacc.address.state,
          postal_code: indacc.address.postal_code,
        },
        ssn: stripe.individual_ssn,
        gender: indacc.gender,
        ssn_last_4: "",
        verification: {},
      };
    }
    await this.setState({
      individual: individual,
    });

    return true;
  };

  initiateIdImages = async (accId, stripe) => {
    // console.log("initiateIdImages accId:", accId, "  stripe:", stripe);
    let individualVerificaionFileUpId = "",
      individualVerificaionFiledownId = "";

    if (stripe.individualIdFiles && stripe.individualIdFiles.individual) {
      if (stripe.individualIdFiles.individual.faceup) {
        individualVerificaionFileUpId =
          stripe.individualIdFiles.individual.faceup.verificationFieldId;
      }
      if (stripe.individualIdFiles.individual.facedown) {
        individualVerificaionFiledownId =
          stripe.individualIdFiles.individual.facedown.verificationFieldId;
      }
    }

    let fileData;
    if (stripe.individualIdFiles) fileData = stripe.individualIdFiles;
    else {
      fileData = {
        accId: accId,
        individual: {
          faceup: {
            verificationFieldId: "",
            file: null,
            fileblob: null,
          },
          facedown: {
            verificationFieldId: "",
            file: null,
            fileblob: null,
          },
        },
      };
    }

    this.setState({
      id_files: fileData,
      individualVerificaionFileUpId,
      individualVerificaionFiledownId,
    });

    return true;
  };

  handleNext = async () => {
    let bprof = {
      mcc: this.state.bpmcc,
      business_name: this.state.bpname,
      product_description: this.state.bpproduct_description,
      url: this.state.bpurl,
    };
    this.setState({
      flowIndex: 1,
      business_profile: bprof,
    });
  };

  handleBackBegin = async (data) => {
    this.props.handleBackBegin();
  };

  handleBackFromIndividual = async (data, skip) => {
    // console.log("handleBackFromIndividual data:", data);
    this.setState({
      flowIndex: 0,
      individual: data,
      individualSkip: skip,
    });
  };

  handleNextToIndividual = async (data, skip) => {
    // console.log("handleNextToIndividual data:", data);
    this.setState({
      flowIndex: 2,
      individual: data, // Load  this to UpdateSetup state.
      individualSkip: skip,
    });
  };

  handleBackFromExtAcc = async (data, skip) => {
    // console.log("handleBackFromCompany data:", data, " skip:", skip);

    this.setState({
      flowIndex: 1,
      external_account: data,
      externalAccountSkip: skip,
    });
  };

  handleNextToExtAcc = async (data, skip) => {
    // console.log("handleNextToExtAcc data:", data, " skip:", skip);
    let flowIndex = 3;

    await this.setState({
      external_account: data,
      externalAccountSkip: skip,
    });

    if (this.props.pendingReqLength !== 0) {
      flowIndex = 4;
      await this.handleNextToIdentity(data, true);
    }
    await this.setState({
      flowIndex,
    });
  };

  handleBackFromIdentity = async (data, skip) => {
    // console.log("handleBackFromIdentity data:", data);
    await this.setState({
      flowIndex: 2,
      id_files: data,
      IdpictureSkip: skip,
    });
  };

  handleNextToIdentity = async (data, skip) => {
    // console.log("handleNextToIdentity data:", data, " skip:", skip);
    await this.setState({
      id_files: data,
      IdpictureSkip: skip,
    });
    // console.log("this.props.pendingReqLength:", this.props.pendingReqLength);

    let noUpdateFlag = false;
    if (this.props.pendingReqLength === 0) {
      if (
        this.state.businessProfileSkip &&
        this.state.individualSkip &&
        this.state.externalAccountSkip &&
        this.state.IdpictureSkip
      )
        noUpdateFlag = true;
    } else {
      if (
        this.state.businessProfileSkip &&
        this.state.individualSkip &&
        this.state.externalAccountSkip
      )
        noUpdateFlag = true;
    }

    let business_profile = {
      mcc: this.state.bpmcc,
      name: this.state.bpname,
      product_description: this.state.bpproduct_description,
      url: this.state.bpurl,
    };

    let ccdata = {
      stripeAccId: this.state.stripeAccId,
      businessType: this.state.businessType,
      business_profile,
      individual: this.state.individual,
      external_account: this.state.external_account,
      id_files: this.state.id_files,
      businessProfileSkip: this.state.businessProfileSkip,
      individualSkip: this.state.individualSkip,
      externalAccountSkip: this.state.externalAccountSkip,
      IdpictureSkip: this.state.IdpictureSkip,
      communityId: this.state.communityId,
    };
    await this.setState({
      ccdata,
      flowIndex: 4,
      noUpdateFlag,
    });
  };

  handleBackFromIndFinal = async (data) => {
    let flowIndex = 3;
    if (this.props.pendingReqLength !== 0) flowIndex = 2;
    await this.setState({
      flowIndex,
    });
  };

  handleSkip = async () => {
    await this.setState((prevstate) => ({
      businessProfileSkip: !prevstate.businessProfileSkip,
    }));
  };

  render() {
    // console.log("IndUpdateSetup this.state:", this.state);
    // console.log("IndUpdateSetup this.props:", this.props);
    console.log("IndUpdateSetup...");

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    let commonButtons;
    if (this.state.commonButtonsFlag) {
      commonButtons = (
        <div>
          <button onClick={this.handleBackBegin} className="btn_reg_60">
            Back
          </button>
          &nbsp;
          <button onClick={this.handleNext} className="btn_reg_60">
            Next
          </button>
          &nbsp;
          <button
            className="btn_info_main"
            type="button"
            onClick={this.openAlertModal}
          >
            <i className="fas fa-info-circle" />
          </button>
          &nbsp;
        </div>
      );
    }

    let headerPanel;
    if (this.state.flowIndex === 0) {
      headerPanel = (
        <div className="row">
          <div className="col-5 header_text">
            Business Profile &nbsp;(Individual)
          </div>
          <div className="col-7 ">{commonButtons}</div>
        </div>
      );
    }

    let bottomMsgPanel = (
      <div className="row">
        <div className="col message_text">
          &nbsp;<font color="red">*</font>&nbsp; are mandaroty fields. Please
          click 'Next' to continue. 'Back' will return you to the origin and you
          will lose the data entered.
        </div>
      </div>
    );

    let bpmcc, bpname, bpdesc, bpurl, bpskip;
    if (this.state.deviceSize === "small") {
      bpmcc = "MCC";
      bpname = "Biz-Name";
      bpdesc = "Description";
      bpurl = "Biz Url";
      bpskip = "Skip biz-profile";
    } else {
      bpmcc = "Merchant Category Code";
      bpname = "Business Name";
      bpdesc = "Short Description";
      bpurl = "Business Url";
      bpskip = "Skip business profile for now";
    }

    let businessProfilePanel;
    businessProfilePanel = (
      <div>
        <div className="input-up-height" />
        <div className="row">
          <div className="col-4 bprofile_label text-right">
            <font color="red">*</font>&nbsp;{bpmcc}
          </div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpmcc"
              type="number"
              value={this.state.bpmcc}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="input_mcc"
              pattern="[0-9]"
              min="1000"
              max="9999"
              placeholder="mcc"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 bprofile_label text-right">
            <font color="red">*</font>&nbsp;{bpname}
          </div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpname"
              type="text"
              value={this.state.bpname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_bpname"
              placeholder="business name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 bprofile_label text-right">{bpdesc}</div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpproduct_description"
              type="text"
              value={this.state.bpproduct_description}
              onChange={this.onChange}
              size="150"
              maxLength="150"
              className="input_bpname"
              placeholder="Business Description"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4 bprofile_label text-right">{bpurl}</div>
          <div className="col-8 bprofile_input text-left">
            <input
              name="bpurl"
              type="text"
              value={this.state.bpurl}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="input_bpname"
              placeholder="Business URL"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-left biz_updt_confirm_biz">
            <Checkbox
              checked={this.state.businessProfileSkip}
              onChange={this.handleSkip}
            />
            &nbsp;{bpskip}
          </div>
          <div className="col-4 text-center">{uploadingSpin}</div>
        </div>
        <div>{bottomMsgPanel}</div>
      </div>
    );

    let outputPanel;
    if (this.state.flowIndex === 0) {
      outputPanel = (
        <div>
          {headerPanel}
          <div className="profile_input_box fixedsize_ccsetup">
            {businessProfilePanel}
          </div>
        </div>
      );
    }

    if (this.state.flowIndex === 1) {
      outputPanel = (
        <div>
          <UpdateIndividual
            handleBack={this.handleBackFromIndividual}
            handleNext={this.handleNextToIndividual}
            individualData={this.state.individual}
            individualSkip={this.state.individualSkip}
          />
        </div>
      );
    }

    if (this.state.flowIndex === 2) {
      let AccName = "";
      if (this.state.individual) {
        AccName =
          this.state.individual.first_name +
          " " +
          this.state.individual.last_name;
      }

      outputPanel = (
        <div>
          <InpUpdtExternalAcc
            handleBack={this.handleBackFromExtAcc}
            handleNext={this.handleNextToExtAcc}
            externalAccountData={this.state.external_account}
            externalAccountSkip={this.state.externalAccountSkip}
            accHolderName={AccName}
            AndAnother="AAAZZZ"
          />
        </div>
      );
    }

    if (this.state.flowIndex === 3 && this.props.pendingReqLength === 0) {
      outputPanel = (
        <div>
          <IndUpdateIdentity
            handleBack={this.handleBackFromIdentity}
            handleNext={this.handleNextToIdentity}
            IdpictureSkip={this.state.IdpictureSkip}
            fileData={this.state.id_files}
            stripe={this.state.stripe}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    if (this.state.flowIndex === 4) {
      outputPanel = (
        <div>
          <IndividualFinalUpdate
            handleBack={this.handleBackFromIndFinal}
            handleCancel={this.handleBackBegin}
            ccdata={this.state.ccdata}
            noUpdateFlag={this.state.noUpdateFlag}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

IndUpdateSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IndUpdateSetup));
