import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import Inventory from "./inventory/InventoryMgmt";
import SaleMain from "./SaleMain";
import Finance from "./finance/FinanceMain";

import "./Store.css";

const logesticButtonList = ["shipping", "pickup", "returnhandling"];
const inventoryButtonList = ["inventoryctrl"];
const financeButtonList = ["recvpayment", "expense", "budgeting", "buycredits"];

const readMeCode = "1111000000";

class Store extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      posActiveFlag: true,
      poActiveFlag: false,
      inventoryActiveFlag: false,
      financeActiveFlag: false,

      deviceSize: "",
      showErrMsgPanel: true,
      errMsg: "",

      btnShowLogistics: false,
      btnShowInventory: false,
      btnShowFinance: false,
      store: this.props.store,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let showErrMsgPanel = false;
    let errMsg = "";

    if (deviceSize === "small") {
      showErrMsgPanel = true;
      errMsg =
        "A device (not a smart phone) with a bigger display area is needed for most of Store's function. Please access this module on a larger device.";
    }

    await this.setAuthorizedButtons();

    await this.setState({
      deviceSize,
      errMsg,
      showErrMsgPanel,
    });
  };

  // This is to show the info panel
  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.props.authorizedFuncs);

    let btnShowLogistics = false;
    let btnShowInventory = false;
    let btnShowFinance = false;

    let inventoryActiveFlag = false;
    let financeActiveFlag = false;
    let posActiveFlag = false;

    logesticButtonList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) btnShowLogistics = true;
    });

    inventoryButtonList.forEach((obj2) => {
      if (this.props.authorizedFuncs.includes(obj2)) btnShowInventory = true;
    });

    financeButtonList.forEach((obj3) => {
      // console.log('obj3:', obj3);
      if (this.props.authorizedFuncs.includes(obj3)) btnShowFinance = true;
    });

    if (btnShowLogistics) posActiveFlag = true;
    else if (btnShowInventory) inventoryActiveFlag = true;
    else if (btnShowFinance) financeActiveFlag = true;

    this.setState({
      btnShowLogistics,
      btnShowInventory,
      btnShowFinance,

      inventoryActiveFlag,
      financeActiveFlag,
      posActiveFlag,
    });
  };

  handleSelect = async (choice) => {
    // console.log("The selection is: " + choice);
    if (choice === "POS") {
      await this.setState({
        posActiveFlag: true,
        poActiveFlag: false,
        inventoryActiveFlag: false,
        financeActiveFlag: false,
      });
    }
    if (choice === "PO") {
      await this.setState({
        posActiveFlag: false,
        poActiveFlag: true,
        inventoryActiveFlag: false,
        financeActiveFlag: false,
      });
    }
    if (choice === "Inventory") {
      await this.setState({
        posActiveFlag: false,
        poActiveFlag: false,
        inventoryActiveFlag: true,
        financeActiveFlag: false,
      });
    }

    if (choice === "Finance") {
      await this.setState({
        posActiveFlag: false,
        poActiveFlag: false,
        inventoryActiveFlag: false,
        financeActiveFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Store...");

    let inventory, logistic;
    if (this.state.deviceSize === "small") {
      inventory = "Invtry";
      logistic = "lgist";
    } else {
      inventory = "Inventory";
      logistic = "Logistic";
    }

    let storebuttons = (
      <div>
        <div className="row">
          <div className="col-2 header_store_text1 text-center">Store</div>
          <div className="col-10 header_store_buttons">
            {this.state.btnShowLogistics ? (
              <button
                className={`${
                  !this.state.posActiveFlag ? "btn_reg_75" : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("POS")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {logistic}
              </button>
            ) : null}

            {/* <button
              className={`${
                !this.state.poActiveFlag
                  ? "btn_store"
                  : "btn_store_active"
              }`}
              type="button"
              onClick={() => this.handleSelect("PO")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Purchase
            </button> */}
            {this.state.btnShowInventory ? (
              <button
                className={`${
                  !this.state.inventoryActiveFlag
                    ? "btn_reg_75"
                    : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("Inventory")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {inventory}
              </button>
            ) : null}

            {this.state.btnShowFinance ? (
              <button
                className={`${
                  !this.state.financeActiveFlag
                    ? "btn_reg_75"
                    : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("Finance")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Finance
              </button>
            ) : null}

            <button
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
      </div>
    );

    let storePanel;
    // console.log("this.state.showErrMsgPanel:", this.state.showErrMsgPanel);
    if (!this.state.showErrMsgPanel) {
      if (this.state.inventoryActiveFlag) {
        storePanel = (
          <div>
            <Inventory
              commName={this.props.commName}
              communityId={this.props.communityId}
              community={this.props.community}
              goToDashboard={this.props.goToDashboard}
              role={this.props.role}
              authorizedFuncs={this.props.authorizedFuncs}
              store={this.state.store}
            />
          </div>
        );
      }
      if (this.state.posActiveFlag) {
        storePanel = (
          <div>
            <SaleMain
              commName={this.props.commName}
              communityId={this.props.communityId}
              community={this.props.community}
              goToDashboard={this.props.goToDashboard}
              role={this.props.role}
              authorizedFuncs={this.props.authorizedFuncs}
              store={this.state.store}
            />
          </div>
        );
      }
      if (this.state.financeActiveFlag) {
        storePanel = (
          <Finance
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            goToDashboard={this.props.goToDashboard}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={this.state.store}
          />
        );
      }
    }

    let errorPanel;
    if (this.state.showErrMsgPanel) {
      errorPanel = (
        <div className="catalog-error-box">
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="device-error-msg">
              {this.state.errMsg}
            </p>
          </div>
        </div>
      );
    }

    // storePanel = <div>{storebuttons}</div>;
    let outputPanel;
    if (this.state.showErrMsgPanel) {
      outputPanel = errorPanel;
    } else {
      outputPanel = (
        <div>
          {storebuttons}
          {storePanel}
        </div>
      );
    }

    return (
      <div className="container">
        {outputPanel}
        <div className="bottom-padding" />
        <ModalContainer />
      </div>
    );
  }
}

Store.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Store));
