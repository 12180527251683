import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./SubscriptionPkgPlan.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getItemsByCategory = "/routes/dashboard/getItemsByCategory?";
const updateCatalogCompPkg = "/routes/dashboard/updateCatalogCompPkg";
const getCatalogPkgForDelivery = "/routes/dashboard/getCatalogPkgForDelivery?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class SubscriptionPkgPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      compShow: "",

      next12Months: [],
      workCategories: [],
      selheightmy: 1,
      selectedMonth: null,
      selheightcat: 1,
      selectedCategory: "",
      selheightItem: 1,
      selheightSubItem: 1,
      noOfPkg: 1,

      noOfSubscriptionThisMonth: 0,
      noOfPackagesMonth: 0,
      packsThisMonth: 0,

      catItems: [],
      subCatItems: [],
      selectedItem: null,
      subItemSelected: null,
      noOfAItemInPkg: 1,

      configFlag: false,
      showPlansFlag: false,
      wipPkgPlanned: [],
      getNoOfPkg: true,
      readyToLocknSave: false,
      catalog: null,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let compShow = "(Category : Qty) ";
    let delim = "";
    this.props.catalog.pkgComposedOfItems.forEach((pkg) => {
      compShow += delim + pkg.category + " : " + pkg.quantity;
      delim = " || ";
    });

    let workCategories = [];
    this.props.catalog.pkgComposedOfItems.forEach((cat) => {
      workCategories.push(cat.category);
    });

    let selectedCategory = "";
    if (workCategories.length === 1) selectedCategory = workCategories[0];

    await this.setNext12Months();

    this.setState({
      compShow,
      workCategories,
      selectedCategory,
      catalog: this.props.catalog,
    });
  };

  // axiosJwt = () => {
  //   const axiosJwtHeader = axios.create({
  //     headers: {
  //       Authorization: this.props.auth.user.token,
  //     },
  //   });

  //   return axiosJwtHeader;
  // };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getPkgsPlanned = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&itemId=" +
      this.state.catalog.itemId +
      "&year=" +
      this.state.selectedMonth.yearOf +
      "&month=" +
      this.state.selectedMonth.monthNo;
    let url = baandaServer + getCatalogPkgForDelivery + params;
    // console.log("url:", url);
    try {
      let plnret = await axios.get(url);
      // console.log('plnret.data:', plnret.data);
      if (plnret.data.status === "success") {
        let planned = 0;
        let packs = 0;
        if (plnret.data.Msg) {
          planned = plnret.data.Msg.batch.length;
          plnret.data.Msg.batch.forEach((pk) => {
            packs += parseFloat(pk.batchQty);
          });
        }
        // console.log("planned:", planned);
        this.setState({
          noOfPackagesMonth: planned,
          packsThisMonth: packs,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: plnret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleLocknSave = async () => {
    await this.setState((prevstate) => ({
      readyToLocknSave: !prevstate.readyToLocknSave,
    }));
  };

  setNext12Months = async () => {
    let thisMonth = new Date().getMonth() + 1;
    let thisYear = new Date().getFullYear();
    let next12Months = [];
    // console.log("thisMonth:", thisMonth, " thisYear:", thisYear);
    for (var i = 0; i < 12; i++) {
      let nextObj = {
        monthNo: thisMonth,
        yearOf: thisYear,
        monthName: this.getMonthName(thisMonth),
      };
      // console.log(i, '. nextObj:', nextObj)
      next12Months.push(nextObj);

      if (thisMonth > 11) {
        thisMonth = 1;
        thisYear++;
      } else {
        thisMonth++;
      }
    }

    this.setState({
      next12Months,
    });
  };

  getMonthName = (monthNumber) => {
    // const date = new Date();
    // let xx = date.setMonth(monthNumber - 1);
    // console.log('monthNumber:', monthNumber, ' xx:', new Date(xx))
    // return date.toLocaleString("en-US", { month: "long" });
    let month = "";
    switch (monthNumber) {
      case 1:
        month = "January";
        break;
      case 2:
        month = "February";
        break;
      case 3:
        month = "March";
        break;
      case 4:
        month = "April";
        break;
      case 5:
        month = "May";
        break;
      case 6:
        month = "June";
        break;
      case 7:
        month = "July";
        break;
      case 8:
        month = "August";
        break;
      case 9:
        month = "September";
        break;
      case 10:
        month = "October";
        break;
      case 11:
        month = "November";
        break;
      case 12:
        month = "December";
        break;
      default:
        month = "Default err";
        break;
    }

    return month;
  };

  calculateNoOfSubscription = async (month, year) => {};

  getItemsForACategory = async () => {
    // console.log('getItemsForACategory...');
    let params =
      "communityId=" +
      this.props.catalog.communityId +
      "&coopStoreId=" +
      this.props.catalog.coopStoreId +
      "&itemCategory=" +
      this.state.selectedCategory;
    let url = baandaServer + getItemsByCategory + params;
    // console.log("url:", url);
    try {
      let retItm = await axios.get(url);
      // console.log("retItm:", retItm);
      if (retItm.data.status === "success") {
        if (retItm.data.Msg.length === 0) {
          this.setState({
            catItems: [],
            errMsg:
              "There are no items of the category, in catalog, to plan with.",
            errFlag: true,
          });
        } else {
          this.setState({
            catItems: retItm.data.Msg,
            errMsg: "",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          errMsg: retItm.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.massage,
        errFlag: true,
      });
    }
  };

  handleCompose = async () => {
    await this.getItemsForACategory();
  };

  returnToCatalogAdmin = () => {
    this.props.returnToCaller(null);
  };

  clearPlanWrk = (how) => {
    if (document.getElementById("workWithCategory")) {
      document.getElementById("workWithCategory").value = "DEFAULT";
    }
    if (document.getElementById("itemForPkg")) {
      document.getElementById("itemForPkg").value = "DEFAULT";
    }
    if (document.getElementById("subitemForPkg")) {
      document.getElementById("subitemForPkg").value = "DEFAULT";
    }

    let getNoOfPkg = false;
    let noOfPkg = this.state.noOfPkg;
    let wipPlan = this.state.wipPkgPlanned;
    if (how === "full") {
      getNoOfPkg = true;
      wipPlan = [];
      noOfPkg = 1;
    }
    // console.log("wipPlan:", wipPlan);
    let selectedCategory = "";
    if (this.state.workCategories.length === 1)
      selectedCategory = this.state.workCategories[0];

    this.setState({
      catItems: [],
      subCatItems: [],
      selectedItem: null,
      subItemSelected: null,
      selectedCategory,
      configFlag: false,
      getNoOfPkg,
      wipPkgPlanned: wipPlan,
      noOfPkg,
    });
  };

  handleMonthSelect = async () => {
    let value = document.getElementById("catPkgMonthYr").value;
    // console.log("handleMonthSelect value:", value);
    let data = JSON.parse(value);
    // console.log("valobj:", data);

    await this.setState({
      selectedMonth: data,
      selheightmy: 1,
    });

    await this.getPkgsPlanned();
  };

  onfocusa = async () => {
    this.setState({
      selheightmy: 4,
    });
  };

  onblura = async () => {
    this.setState({
      selheightmy: 1,
    });
  };

  handleCategorySelect = async () => {
    let value = document.getElementById("workWithCategory").value;
    // console.log("handleCategorySelect value:", value);
    // let data = JSON.parse(value);
    // console.log("valobj:", data);
    let configFlag = false;
    if (this.state.wipPkgPlanned.length > 0) configFlag = true;

    this.setState({
      selectedCategory: value.trim(),
      catItems: [],
      subCatItems: [],
      selectedItem: null,
      subItemSelected: null,
      selheightcat: 1,
      configFlag,
    });
  };

  onfocus = async () => {
    this.setState({
      selheightcat: 4,
    });
  };

  onblur = async () => {
    this.setState({
      selheightcat: 1,
    });
  };

  handleItemSelect = async () => {
    let value = document.getElementById("itemForPkg").value;
    // console.log("handleItemSelect value:", value);
    let data = JSON.parse(value);
    // console.log("valobj:", data);

    let subItemSelected = null;
    let configFlag = false;
    // console.log("data.itemVariance.length:", data.itemVariance.length);
    if (data.itemVariance.length === 1) {
      subItemSelected = data.itemVariance[0];
      configFlag = true;
    }

    this.setState({
      selectedItem: data,
      selheightItem: 1,
      subCatItems: data.itemVariance,
      subItemSelected,
      configFlag,
    });
  };

  onfocusit = async () => {
    let height = 7;
    if (this.state.catItems.length < 7) height = this.state.catItems.length + 1;
    this.setState({
      selheightItem: height,
    });
  };

  onblurit = async () => {
    this.setState({
      selheightItem: 1,
    });
  };

  handleSubItemSelect = async () => {
    let value = document.getElementById("subitemForPkg").value;
    // console.log("handleItemSelect value:", value);
    let data = JSON.parse(value);
    // console.log("valobj:", data);

    this.setState({
      selheightSubItem: 1,
      subItemSelected: data,
      configFlag: true,
    });
  };

  onfocussubit = async () => {
    let height = 4;
    if (this.state.subCatItems.length < 4)
      height = this.state.subCatItems.length + 1;
    this.setState({
      selheightSubItem: height,
    });
  };

  onblursubit = async () => {
    this.setState({
      selheightSubItem: 1,
    });
  };

  onChangeNumber = async (e) => {
    let value = e.target.value;
    // let name = e.target.name;
    if (value < 0) {
      this.setState({
        errFlag: true,
        errMsg: "Value entered must be positive.",
      });
    } else {
      if (this.countDecimals(value) > 0) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          [e.target.name]: value,
          errMsg: "",
          errFlag: false,
        });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  vlaidateAdd = () => {
    // Got stuff to do about validating max number allowed...
    // console.log(
    //   "this.props.catalog.pkgComposedOfItems:",
    //   this.props.catalog.pkgComposedOfItems
    // );
    let status = "success";
    let Msg = "";
    let invPlannedQty = 0;
    this.props.catalog.pkgComposedOfItems.forEach((pkg) => {
      if (this.state.selectedCategory.trim() === pkg.category.trim()) {
        invPlannedQty += parseFloat(pkg.quantity);
      }
    });
    // console.log("invPlannedQty:", invPlannedQty);
    let wipqty = 0;
    this.state.wipPkgPlanned.forEach((wip) => {
      if (wip.category.trim() === this.state.selectedCategory.trim()) {
        wipqty += parseFloat(wip.noInPkg);
      }
    });
    // console.log("wipqty:", wipqty);
    // console.log(
    //   "parseFloat(this.state.noOfAItemInPkg): ",
    //   parseFloat(this.state.noOfAItemInPkg)
    // );

    if (invPlannedQty < wipqty + parseFloat(this.state.noOfAItemInPkg)) {
      status = "error";
      Msg =
        "No of items assigned for the category (" +
        this.state.selectedCategory +
        ") exceeds allocated items in pkg plan.";
    }

    return { status, Msg };
  };

  handleConfigAdd = async () => {
    let isOk = this.vlaidateAdd();
    // console.log(">>>>>>>>> isOk:", isOk);
    if (isOk.status === "success") {
      let wipPkgArr = [];
      this.state.wipPkgPlanned.forEach((itm) => {
        if (
          itm.selectedMonth.monthNo === this.state.selectedMonth.monthNo &&
          itm.selectedMonth.yearOf === this.state.selectedMonth.yearOf &&
          itm.category === this.state.selectedCategory
        ) {
          if (
            parseFloat(this.state.selectedItem.itemId) !==
            parseFloat(itm.pkgItemId)
          ) {
            wipPkgArr.push(itm);
          } else {
            if (
              parseFloat(this.state.subItemSelected) !==
              parseFloat(itm.subItemSeqno)
            ) {
              wipPkgArr.push(itm);
            }
          }
        } else {
          wipPkgArr.push(itm);
        }
      });

      let showSubItem = false;
      if (this.state.selectedItem.itemVariance.length > 1) showSubItem = true;

      let newLineObj = {
        selectedMonth: this.state.selectedMonth,
        category: this.state.selectedCategory,
        pkgItemId: this.state.selectedItem.itemId,
        PkgItemName: this.state.selectedItem.itemName,
        subItemSeqno: this.state.subItemSelected.seqno,
        subItemName: this.state.subItemSelected.name,
        noInPkg: parseFloat(this.state.noOfAItemInPkg),
        availQty: parseFloat(this.state.subItemSelected.quantity),
        showSubItem,
      };
      wipPkgArr.push(newLineObj);
      // console.log("2 wipPkgArr:", wipPkgArr);

      await this.setState({
        wipPkgPlanned: wipPkgArr,
        showPlansFlag: true,
        errMsg: "To modify 'No of Pkg' or to start over click 'Clear'.",
        errFlag: false,
      });

      this.clearPlanWrk("part");
    } else {
      this.setState({
        errMsg: isOk.Msg,
        errFlag: true,
      });
    }
  };

  validateBeforeSave = () => {
    let status = "success",
      msg = "Insufficiant inventory: ";
    this.state.wipPkgPlanned.forEach((pkg) => {
      if (
        parseFloat(pkg.availQty) <
        parseFloat(pkg.noInPkg) * parseFloat(this.state.noOfPkg)
      ) {
        status = "error";
        msg += pkg.PkgItemName;
        if (pkg.showSubItem) msg += " sub: " + pkg.subItemName;
        msg +=
          " Req:" +
          parseFloat(pkg.noInPkg) * parseFloat(this.state.noOfPkg) +
          " Avl:" +
          parseFloat(pkg.availQty) +
          " | ";
      }
    });

    return { status, Msg: msg };
  };

  pkgDataForSaving = () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      noOfPkg: parseFloat(this.state.noOfPkg),
      itemId: this.state.catalog.itemId,
      selectedMonth: this.state.selectedMonth,
      pkgLines: this.state.wipPkgPlanned,
      numberOfPacks: parseFloat(this.state.noOfPkg),
      clientProgram: "SubscriptionPkgPlan.js",
      clientFunction: "savepkgPlanForMonthYr",
    };

    return input;
  };

  savepkgPlanForMonthYr = async () => {
    // let comret = await this.axiosJwt().post(url, input);
    let val = this.validateBeforeSave();
    // console.log("val: ", val);
    if (val.status === "success") {
      let input = this.pkgDataForSaving();
      // console.log("input:", input);
      let url = baandaServer + updateCatalogCompPkg;
      try {
        let pkret = await axios.post(url, input);
        if (pkret.data.status === "success") {
          // let nopkg = parseFloat(this.state.noOfPackagesMonth);

          await this.getPkgsPlanned();
          this.clearPlanWrk("full");
          this.setState({
            catalog: pkret.data.Msg,
            errMsg: "Successfully saved.",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: pkret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
      });
    }
  };

  handleLineDelete = (line) => {
    // console.log("line:", line);
    let wip = [];
    this.state.wipPkgPlanned.forEach((obj) => {
      if (obj.pkgItemId !== line.pkgItemId) wip.push(obj);
    });

    this.setState({
      wipPkgPlanned: wip,
      errMsg: "",
      errFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.State:", this.state);
    console.log("SubscriptionPkgPlan...");

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToCatalogAdmin}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let clearButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={() => this.clearPlanWrk("full")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Clear
      </button>
    );

    let saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.savepkgPlanForMonthYr}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let addConfigBtn;
    // if ( this.state.isnoOfAItemInPkgalid){
    addConfigBtn = (
      <button
        className="btn_reg_60 config-btn-pos"
        type="button"
        onClick={this.handleConfigAdd}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-plus">&nbsp;Add</i>
      </button>
    );
    // }

    let compButton;
    if (this.state.selectedMonth && this.state.selectedCategory !== "") {
      compButton = (
        <button
          className="btn_reg_80 cat-comp-btn-pos"
          type="button"
          onClick={this.handleCompose}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Compose
        </button>
      );
    }

    let showCompPanel;
    showCompPanel = (
      <div className="row cat-comp-show-pos">
        <div className="col-3 text-right cat-comp-show-lbl">Composition</div>
        <div className="col-9 text-left cat-comp-show-txt">
          {this.state.compShow}
        </div>
      </div>
    );

    let selMonthPanel;
    let monYrList = this.state.next12Months.map((obj, i) => {
      return (
        <option key={i} value={JSON.stringify(obj)}>
          {obj.monthName}&nbsp;{obj.yearOf}
        </option>
      );
    });

    selMonthPanel = (
      <div className="row cat-pkg-mon-pos">
        <div className="col-3 text-right cat-comp-show-lbl-dd">
          For month of
        </div>
        <div className="col-5 text-left">
          <select
            size={this.state.selheightmy}
            id="catPkgMonthYr"
            name="catPkgMonthYr"
            onChange={this.handleMonthSelect}
            className="cat-pkgplan-dropdown"
            onFocus={this.onfocusa}
            onBlur={this.onblura}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a plannning month
            </option>
            {monYrList}
          </select>
        </div>
        <div className="col-4 text-left cat-comp-show-lbl-planed">
          Batchs: {this.state.noOfPackagesMonth} | Packs:{" "}
          {this.state.packsThisMonth} | Subscribed:{" "}
          {this.state.noOfSubscriptionThisMonth}
        </div>
      </div>
    );

    let catagoryListPanel;
    if (this.state.workCategories.length === 1) {
      catagoryListPanel = (
        <div className="row pkg-item-cat-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            Item category
          </div>
          <div className="col-4 text-left cat-comp-show-txt">
            {this.state.selectedCategory}
          </div>
          {this.state.getNoOfPkg ? (
            <div className="col-3 text-left cat-nopkg-show-lbl">
              No of Pkg&nbsp;
              <input
                name="noOfPkg"
                type="number"
                value={this.state.noOfPkg}
                onChange={this.onChangeNumber}
                className="noOfPkg-field"
                step="1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          ) : (
            <div className="col-3 text-left cat-nopkg-show-lbl">
              No of Pkg :&nbsp;{this.state.noOfPkg}
            </div>
          )}
          <div className="col-2 text-center">{compButton}</div>
        </div>
      );
    } else {
      let catList = this.state.workCategories.map((obj, i) => {
        return (
          <option key={i} value={obj}>
            {obj}
          </option>
        );
      });
      catagoryListPanel = (
        <div className="row pkg-item-cat-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            Item category
          </div>
          <div className="col-4 text-left cat-comp-show-txt">
            <select
              size={this.state.selheightcat}
              id="workWithCategory"
              name="workWithCategory"
              onChange={this.handleCategorySelect}
              className="cat-workcat-dropdown"
              onFocus={this.onfocus}
              onBlur={this.onblur}
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select a category
              </option>
              {catList}
            </select>
          </div>
          {this.state.getNoOfPkg ? (
            <div className="col-3 text-left cat-nopkg-show-lbl">
              No of Pkg&nbsp;
              <input
                name="noOfPkg"
                type="number"
                value={this.state.noOfPkg}
                onChange={this.onChangeNumber}
                className="noOfPkg-field"
                step="1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          ) : (
            <div className="col-3 text-left cat-nopkg-show-lbl">
              No of Pkg :&nbsp;{this.state.noOfPkg}
            </div>
          )}

          <div className="col-2 text-left cat-comp-show-txt">{compButton}</div>
        </div>
      );
    }

    let itemsListPanel;
    if (this.state.catItems.length > 0) {
      let itemList = this.state.catItems.map((itm, i) => {
        console.log(
          "itm.id:",
          itm.itemId,
          " itemVariance[0]:",
          itm.itemVariance[0]
        );
        return (
          <option key={i} value={JSON.stringify(itm)}>
            {itm.itemName} (
            {itm.itemVariance.length > 1
              ? "Select Sub Item for qty"
              : "Qty:" + itm.itemVariance[0].quantity}
            )
          </option>
        );
      });
      itemsListPanel = (
        <div className="row cat-pkg-itm-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            Item List
          </div>
          <div className="col-9 text-left cat-comp-show-item-msg">
            <select
              size={this.state.selheightItem}
              id="itemForPkg"
              name="itemForPkg"
              onChange={this.handleItemSelect}
              className="cat-itemlist-dropdown"
              onFocus={this.onfocusit}
              onBlur={this.onblurit}
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select an item for pkg
              </option>
              {itemList}
            </select>
          </div>
        </div>
      );
    } else {
      itemsListPanel = (
        <div className="row cat-pkg-itm-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            Items List
          </div>
          <div className="col-6 text-left cat-comp-show-item-msg">
            Select month, categoy, click Compose
          </div>
          <div className="col-3 text-left cat-comp-show-txt">&nbsp;</div>
        </div>
      );
    }

    let subItemList;
    if (this.state.subCatItems.length > 1) {
      let subitemList = this.state.subCatItems.map((sitm, i) => {
        return (
          <option key={i} value={JSON.stringify(sitm)}>
            {sitm.name} (Qty: {sitm.quantity})
          </option>
        );
      });
      subItemList = (
        <div className="row cat-pkg-subitm-pos-dd">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            Sub Items
          </div>
          <div className="col-6 text-left cat-comp-show-item-msg">
            <select
              size={this.state.selheightSubItem}
              id="subitemForPkg"
              name="subitemForPkg"
              onChange={this.handleSubItemSelect}
              className="cat-sub-itemlist-dropdown"
              onFocus={this.onfocusssubit}
              onBlur={this.onblurssubit}
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select an sub item
              </option>
              {subitemList}
            </select>
          </div>
          <div className="col-3 text-left cat-comp-show-txt">&nbsp;</div>
        </div>
      );
    } else {
      subItemList = (
        <div className="row cat-pkg-subitm-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            Sub Items
          </div>
          <div className="col-6 text-left cat-comp-show-item-msg">None</div>
          <div className="col-3 text-left cat-comp-show-txt">&nbsp;</div>
        </div>
      );
    }

    let itemsInPkgPanel;
    if (this.state.configFlag) {
      itemsInPkgPanel = (
        <div className="row cat-items-in-pkg-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            No of item in pkg
          </div>
          <div className="col-6 text-left cat-comp-show-item-msg">
            <input
              name="noOfAItemInPkg"
              type="number"
              value={this.state.noOfAItemInPkg}
              onChange={this.onChangeNumber}
              className="noOfItemin-Pkg-field"
              step="1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-3 text-left cat-comp-show-txt">
            {parseFloat(this.state.noOfAItemInPkg) > 0 ? addConfigBtn : null}
          </div>
        </div>
      );
    } else {
      itemsInPkgPanel = (
        <div className="row cat-items-in-pkg-pos">
          <div className="col-3 text-right cat-comp-show-lbl-wrk">
            No of item in pkg
          </div>
          <div className="col-9 text-left cat-comp-show-item-msg">&nbsp;</div>
        </div>
      );
    }

    let configBoxPanel;
    if (this.state.showPlansFlag) {
      let bool = true;
      configBoxPanel = (
        <div>
          <div className="row">
            <div className="col-1 text-center config-list-header">Ops</div>
            <div className="col-7 text-left config-list-header">
              Item Specified
            </div>
            <div className="col-2 text-center config-list-header">Qty Avl</div>
            <div className="col-2 text-center config-list-header">Qty Req</div>
          </div>
          <div className="catalog-pkgplan-config-box">
            {this.state.wipPkgPlanned.map((sch, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool ? "row-sch-ship-color-dr" : "row-sch-ship-color-lt"
                  }`}
                >
                  <div className="row">
                    <div className="col-1 text-center">
                      <button
                        onClick={() => this.handleLineDelete(sch)}
                        className="btn_exit_del"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-7 text-left cat-plan-item-spec">
                      {sch.PkgItemName}&nbsp;{" "}
                      {!sch.showSubItem ? null : " | sub: " + sch.subItemName}
                    </div>
                    <div className="col-2 text-center cat-plan-item-spec">
                      {sch.availQty}
                    </div>
                    <div className="col-2 text-center cat-plan-item-spec">
                      {sch.availQty >= sch.noInPkg * this.state.noOfPkg ? (
                        <font color="green">
                          {sch.noInPkg * this.state.noOfPkg}
                        </font>
                      ) : (
                        <font color="red">
                          {sch.noInPkg * this.state.noOfPkg}
                        </font>
                      )}
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      configBoxPanel = (
        <div>
          <div className="row">
            <div className="col-1 text-center config-list-header">Ops</div>
            <div className="col-7 text-left config-list-header">
              Item Specified
            </div>
            <div className="col-2 text-left config-list-header">Qty Req</div>
            <div className="col-2 text-left config-list-header">Qty Avl</div>
          </div>
          <div className="catalog-pkgplan-config-box">
            Complete selections to confgure item
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="catalog-show-pkgplan-box cat-pkgplan-header">
        Scheduled Delivery Pkg Plannning &nbsp;{modalButton}&nbsp;{exitButton}
        {showCompPanel}
        {selMonthPanel}
        {catagoryListPanel}
        {itemsListPanel}
        {subItemList}
        {itemsInPkgPanel}
        {configBoxPanel}
        <div className="row">
          {this.state.wipPkgPlanned.length > 0 ? (
            <div className="col-6 text-center">
              <Checkbox
                checked={this.state.readyToLocknSave}
                onChange={this.handleLocknSave}
              />
              &nbsp;Lock plan to Save&nbsp;&nbsp;
              {this.state.readyToLocknSave ? saveButton : null}
            </div>
          ) : (
            <div className="col-6 text-left">&nbsp;</div>
          )}
          <div className="col-6 text-left">{clearButton}</div>
        </div>
        <div className="cat-pkgplan-err-box">
          <div
            className={
              this.state.errFlag
                ? "text-center cat-pkg-comp-msg-err"
                : "text-center cat-pkg-comp-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
        {/* <div
          className={
            this.state.errFlag
              ? "text-center cat-pkg-comp-msg-err"
              : "text-center cat-pkg-comp-msg"
          }
        >
          {this.state.errMsg}
        </div> */}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SubscriptionPkgPlan.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionPkgPlan));
