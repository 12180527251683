import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./BroadcastTags.css";
import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";
const searchForBroadcastTagging = "/routes/interact/searchForBroadcastTagging?";

const readMeCode = "1144300000";

class BroadcastTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      openCatalog: true,
      openRecipiant: false,
      openEvent: false,
      viewAnItem: false,

      tagType: "service",
      theBroadcast: null,

      tagMsg: "",
      tagMsgFlag: false,

      itemName: "",

      showEntitiesToTagFlag: false,
      toTagEntities: [],
      taggedEntity: null,
      showSelectedFlag: false,
      message: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let theBroadcast = this.state.theBroadcast;
    if (this.props.theBroadcast) theBroadcast = this.props.theBroadcast;

    await this.setState({
      deviceSize,
      theBroadcast,
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleTagSelection = async (e) => {
    let value = e.target.value;
    let openCatalog = false,
      openEvent = false,
      openRecipiant = false;
    let showSelectetdItem = false;

    if (value === "catalog") {
      openCatalog = true;
    }
    if (value === "recipiantgroup") openRecipiant = true;
    if (value === "event") openEvent = true;

    await this.setState({
      tagType: value,
      openCatalog,
      openEvent,
      openRecipiant,
      showSelectetdItem,
      showSelectedFlag: false,
      showEntitiesToTagFlag: false,
      taggedEntity: null,
    });
    // this.props.returnToCaller(this.state.theBroadcast);
  };

  handleRecipiantType = async (e) => {
    let value = e.target.value;
    await this.setState({
      recipiantType: value,
    });
  };

  getStuffToTag = async () => {
    let params;
    if (this.state.tagType === "event") {
      params =
        "communityId=" +
        this.props.communityId +
        "&tagName=" +
        this.state.itemName +
        "&tagType=" +
        this.state.tagType;
    } else {
      params =
        "communityId=" +
        this.props.communityId +
        "&tagName=" +
        this.state.itemName +
        "&tagType=" +
        this.state.tagType;
    }
    let url = baandaServer + searchForBroadcastTagging + params;
    // console.log("url:", url);
    try {
      let tagret = await axios.get(url);
      // console.log("tagret.data:", tagret.data);
      if (tagret.data.status === "success") {
        let toTagEntities = [];
        if (this.state.tagType === "event") {
          tagret.data.Msg.forEach((elm) => {
            let pubStatus = false;
            if (elm.eventStatus === "published") pubStatus = true;
            let entobj = {
              entId: elm.eventId,
              name: elm.eventName,
              pubStatus,
            };
            toTagEntities.push(entobj);
          });
        } else {
          // for tagType === service or goods
          tagret.data.Msg.forEach((elm) => {
            let pubStatus = false;
            if (elm.isPublished) pubStatus = true;
            let entobj = {
              entId: elm.itemId,
              name: elm.itemName,
              pubStatus,
            };
            toTagEntities.push(entobj);
          });
        }
        await this.setState({
          toTagEntities,
        });
      }
    } catch (err) {
      console.log("getStuffToTag err:", err.message);
    }
  };

  onfocusgrp = async () => {
    let selht = 4;
    if (this.state.selectedGroupNames.length < 4)
      selht = this.state.selectedGroupNames.length + 1;
    await this.setState({
      selheightgrp: selht,
    });
  };

  onblurgrp = async () => {
    await this.setState({
      selheightgrp: 1,
    });
  };

  onfocus = async () => {
    let selht = 4;
    if (this.state.catalogItems.length < 4)
      selht = this.state.catalogItems.length + 1;
    await this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  getTaggingEntities = async () => {
    // console.log("Search getTaggingEntities");
    await this.getStuffToTag();
    await this.setState({
      showEntitiesToTagFlag: true,
      showSelectedFlag: false,
      taggedEntity: null,
    });
  };

  selectThisItemToTag = async (obj) => {
    // console.log("obj::", obj);
    await this.setState({
      showEntitiesToTagFlag: false,
      showSelectedFlag: true,
      taggedEntity: obj,
    });
  };

  closeviewSelectedCatalogItem = async () => {
    await this.setState({
      viewAnItem: false,
    });
  };

  dBUpdateDataPackage = async () => {
    let tag = this.state.theBroadcast.tag;
    if (this.state.tagType === "service" || this.state.tagType === "goods") {
      tag = {
        domain: "catalog",
        domainRefId: this.state.selectedItem.itemId,
        tagType: this.state.selectedItem.itemType,
      };
    }
    if (this.state.tagType === "event") {
      tag = {
        domain: "event",
        domainRefId: 0,
        tagType: "plainevent",
      };
    }

    let recipiants;
    if (this.state.tagType === "recipiantgroup") {
      recipiants = {
        type: this.state.recipiantType,
        broadcastGroupId: this.state.broadcastGroupId,
        broadcastGroupName: this.state.selectedGroupName,
      };
    }

    let input = {
      communityId: this.props.communityId,
      broadcastId: this.state.theBroadcast.broadcastId,
      updateType: this.state.tagType,
      recipiants,
      tag,
      module: "tags",
      clientProgram: "BroadcastTags.js",
      clientFunction: "saveBroadcastTags",
    };

    return input;
  };

  saveBroadcastTags = async () => {
    try {
      let input = await this.dBUpdateDataPackage();
      let url = baandaServer + saveBroadcastWip;
      // console.log("url:", url, " input:", input);
      let brdret = await axios.post(url, input);
      // console.log("brdret:", brdret);
      if (brdret.data.status === "success") {
        await this.setState({
          updtMsg: "Successfully Updated",
          updtErrFlag: false,
          theBroadcast: brdret.data.Msg,
        });
        this.props.returnToCaller(this.state.theBroadcast);
      } else {
        await this.setState({
          updtMsg: brdret.data.Msg,
          updtErrFlag: true,
        });
      }
    } catch (err) {
      await this.setState({
        updtMsg: err.message,
        updtErrFlag: true,
      });
    }
  };

  saveTagToBroadcast = async () => {
    let tagobj = {
      tagged: false,
      domain: "catalog",
      domainRefId: 0,
      tagName: "",
      tagType: "",
    };
    if (this.state.taggedEntity) {
      let domain = "catalog";
      if (this.state.tagType === "event") domain = "event";
      tagobj = {
        tagged: false,
        domain,
        domainRefId: this.state.taggedEntity.entId,
        tagName: this.state.taggedEntity.name,
        tagType: this.state.tagType,
      };
    }

    let input = {
      module: "tags",
      broadcastId: this.props.theBroadcast.broadcastId,
      tag: tagobj,
      clientProgram: "BroadcastTags.js",
      clientFunction: "saveTagToTarget",
    };
    let url = baandaServer + saveBroadcastWip;
    // console.log('url:', url, ' input:', input);
    try {
      let retsave = await axios.post(url, input);
      // console.log('retsave.data:', retsave.data);
      if (retsave.data.status === "success") {
        await this.setState({
          message: "Successfuly updated.",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: retsave.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('BroadcastTags...');

    let selectionPanel;
    selectionPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Tag Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="service"
                checked={this.state.tagType === "service"}
                onChange={this.handleTagSelection}
              />{" "}
              Catalog ( Service )
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="goods"
                checked={this.state.tagType === "goods"}
                onChange={this.handleTagSelection}
              />{" "}
              Catalog ( Goods )
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="event"
                checked={this.state.tagType === "event"}
                onChange={this.handleTagSelection}
              />{" "}
              Events
            </label>
          </div>
        </div>
      </div>
    );

    let infoModalButton = (
      <button
        className="btn_modal_cal_event"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let goSearchButton;
    goSearchButton = (
      <button
        className="btn-go-search"
        onClick={this.getTaggingEntities}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let saveButton;
    saveButton = (
      <button
        className="btn-brd-tag-save"
        onClick={this.saveTagToBroadcast}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let itemNamePanel;
    if (this.state.tagType === "service") {
      itemNamePanel = (
        <div>
          <div className="row">
            <div className="col mktteam-name-lbl">
              Service Name: &nbsp;
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="market-team-input"
                placeholder="Enter a service name (part/full)"
              />
              &nbsp;&nbsp;{goSearchButton}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.tagType === "goods") {
      console.log("inside goods");
      itemNamePanel = (
        <div>
          <div className="row">
            <div className="col mktteam-name-lbl">
              Goods Name: &nbsp;
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="market-team-input"
                placeholder="Enter a goods name (part/full)"
              />
              &nbsp;&nbsp;{goSearchButton}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.tagType === "event") {
      console.log("inside goods");
      itemNamePanel = (
        <div>
          <div className="row">
            <div className="col mktteam-name-lbl">
              Events Name: &nbsp;
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="market-team-input"
                placeholder="Enter an event name (part/full)"
              />
              &nbsp;&nbsp;{goSearchButton}
            </div>
          </div>
        </div>
      );
    }

    // let saveTagsButton;
    // saveTagsButton = (
    //   <button
    //     className="btn-go-tag-item-savex"
    //     onClick={this.saveBroadcastTags}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     Save
    //   </button>
    // );

    let showEntitiesToTagPanel;
    if (this.state.showEntitiesToTagFlag) {
      let bool = true;
      showEntitiesToTagPanel = (
        <div>
          <div className="row tag-entity-list-row">
            <div className="col-3 text-center tag-list-head-lbl">Ops</div>
            <div className="col-6 text-center tag-list-head-lbl">Names</div>
            <div className="col-3 text-center tag-list-head-lbl">
              ( {this.state.tagType.toUpperCase()}S )
            </div>
          </div>
          <div className="fixedsize-tag-entity-list">
            {this.state.toTagEntities.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-3 text-center">
                      <button
                        className="btn-sub-line-edit"
                        type="button"
                        onClick={() => this.selectThisItemToTag(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Tag
                      </button>
                    </div>
                    <div className="col-9 text-left item-category-label">
                      {obj.name} || &nbsp;
                      {obj.pubStatus ? (
                        <font color="green">Published</font>
                      ) : (
                        <font color="brown">Not Published</font>
                      )}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let showSelectedPanel;
    if (this.state.showSelectedFlag) {
      showSelectedPanel = (
        <div className="tag-selected-panel-row">
          <div className="text-center tag-selected-panel-lbl">
            Selected {this.state.tagType} name to tag
          </div>
          <div className="text-center tag-selected-tag-name">
            {this.state.taggedEntity.name}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center tagging-header">
          Tag Broadcast Elements &nbsp;&nbsp;{infoModalButton}&nbsp;{saveButton}
        </div>
        {selectionPanel}
        {itemNamePanel}
        {showEntitiesToTagPanel}
        {showSelectedPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center brd-tag-msg-err"
              : "text-center brd-tag-msg"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

BroadcastTags.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BroadcastTags));
