import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./BrDefine.css";

import basicComposeTemplate from "../../../../../images/basicComp.jpg";
import sliderTemplate from "../../../../../images/sliderComp.jpg";
import newsLetter from "../../../../../images/newsletter.jpg";
import videoTemplate from "../../../../../images/videoTemplate.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastInit = "/routes/interact/saveBroadcastInit";
const deleteABroadcast = "/routes/interact/deleteABroadcast";
const ifExistsBroadcastName = "/routes/interact/ifExistsBroadcastName?";
const getBroadcasts = "/routes/interact/getBroadcasts?";

class BrDefine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      broadcastInitType: "new",
      broadcastName: "",
      description: "",
      letterSubject: "",
      contactEmail: "",

      broadcastId: 0,
      theBroadcast: null,

      updtMsg: "",
      updtErrflg: false,

      status: "prepmode",
      broadcastNameToFilter: "",
      showTemplateOnPopup: false,

      compTypeheight: 1,
      selectedCompType: "",

      filteredBroadcasts: [],

      filtered: false,
      presentBGColor: "#fff",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let broadcastId = 0;
    let status = this.state.status;
    let broadcastName = this.state.broadcastName;
    let description = this.state.description;
    let letterSubject = this.state.letterSubject;
    let contactEmail = this.state.contactEmail;

    // await this.getFilteredBroadcasts();
    let presentBGColor = "#fff";
    let selectedCompType = "";
    if (this.props.theBroadcast && this.props.theBroadcast.broadcastId) {
      broadcastId = this.props.theBroadcast.broadcastId;
      status = this.props.theBroadcast.status;
      broadcastName = this.props.theBroadcast.broadcastName;
      description = this.props.theBroadcast.description;
      letterSubject = this.props.theBroadcast.letterSubject;
      presentBGColor = this.props.theBroadcast.presentationFrameColor;
      selectedCompType =
        this.props.theBroadcast.mailComposition.mailCompositionType;
      contactEmail = this.props.theBroadcast.contactEmail;
    }
    await this.setState({
      deviceSize,
      theBroadcast: this.props.theBroadcast,
      broadcastId,
      status,
      broadcastName,
      description,
      letterSubject,
      presentBGColor,
      selectedCompType,
      contactEmail,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleBroadcastInit = async (e) => {
    let value = e.target.value;
    let showFilterPopup = false;
    if (value !== "new") showFilterPopup = true;

    let theBroadcast = this.state.theBroadcast;
    let broadcastName = this.state.broadcastName;
    let broadcastId = this.state.broadcastId;
    let description = this.state.description;
    let letterSubject = this.state.letterSubject;
    let presentBGColor = this.state.presentBGColor;
    let contactEmail = this.state.contactEmail;
    if (value === "new") {
      theBroadcast = null;
      broadcastName = "";
      broadcastId = 0;
      description = "";
      letterSubject = "";
      presentBGColor = "#000";
      contactEmail = "";
    }
    await this.setState({
      broadcastInitType: e.target.value,
      showFilterPopup,
      presentBGColor,
      // status,
      theBroadcast,
      broadcastName,
      broadcastId,
      description,
      letterSubject,
      contactEmail,
      updtMsg: '',
      updtErrflg: false
    });

    this.props.returnToCaller(this.state.theBroadcast);
  };

  closeFilterPopup = async () => {
    await this.setState({
      showFilterPopup: false,
      broadcastInitType: "new",
      filtered: false,
    });
  };

  broadcastNameIfExists = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&broadcastName=" +
      this.state.broadcastName +
      "&broadcastType=email";
    let url = baandaServer + ifExistsBroadcastName + params;
    // console.log("url:", url);
    let doExists = false;
    try {
      let retexist = await axios.get(url);
      // console.log("retexist:", retexist);
      if (
        (retexist.data.status === "success") &
        (retexist.data.Msg === "doesnotexist")
      )
        doExists = true;
    } catch (err) {
      console.log("err:", err.message);
    }
    return doExists;
  };

  validateForSave = async () => {
    let isValid = true;
    let msg = "";
    let nameExists = true;
    if (this.state.broadcastInitType === "new") {
      nameExists = await this.broadcastNameIfExists();
      if (!nameExists) msg = "This campaign name already exists. Rename your campaign or click Compose to continue.";
    }
    if (this.state.broadcastInitType === "copy") {
      let iof = this.state.broadcastName.indexOf("(copy)");
      if (iof !== -1) {
        nameExists = false;
        msg = "Must rename the copied Broadcast Name to save";
      }
    }
    // console.log("nameExists:", nameExists);
    if (!nameExists) {
      isValid = false;
    }

    if (this.state.selectedCompType === "") {
      msg += " Must select composition type to save; ";
      isValid = false;
    }

    return { status: isValid, Msg: msg };
  };

  dBUpdateDataPackage = async () => {
    let input = {
      communityId: this.props.communityId,
      coopStoreId: this.props.coopStoreId,
      communityName: this.props.communityName,
      authorBaandaId: this.props.authorBaandaId,
      authorName: this.props.auth.user.name,
      authorEmail: this.props.auth.user.email,
      authorCell: this.props.auth.user.cell.number,
      broadcastId: this.state.broadcastId,
      broadcastName: this.state.broadcastName,
      description: this.state.description,
      letterSubject: this.state.letterSubject,
      contactEmail: this.state.contactEmail,
      selectedCompType: this.state.selectedCompType,
      presentBGColor: this.state.presentBGColor,
      broadcastType: "email",
      module: "define",
      clientProgram: "BrDefine.js",
      clientFunction: "saveBroadcast",
    };

    return input;
  };

  saveBroadcast = async () => {
    // console.log("Will save");
    try {
      let isValid = await this.validateForSave();
      // console.log("isValid:", isValid);

      if (isValid.status) {
        let input = await this.dBUpdateDataPackage();
        let url = baandaServer + saveBroadcastInit;
        // console.log(">>> url:", url, " input:", input);
        let brdret = await axios.post(url, input);
        // console.log("brdret:", brdret);
        if (brdret.data.status === "success") {
          await this.setState({
            updtMsg: "Successfully Updated",
            updtErrflg: false,
            theBroadcast: brdret.data.Msg,
          });
          this.props.returnToCaller(this.state.theBroadcast);
        } else {
          await this.setState({
            updtMsg: brdret.data.Msg,
            updtErrflg: true,
          });
        }
      } else {
        await this.setState({
          updtMsg: isValid.Msg,
          updtErrflg: true,
        });
      }
    } catch (err) {
      await this.setState({
        updtMsg: err.message,
        updtErrflg: true,
      });
    }
  };

  deleteBroadcast = async () => {
    let input = {
      communityId: this.props.communityId,
      broadcastId: this.state.broadcastId,
      authorBaandaId: this.props.authorBaandaId,
      clientProgram: "BrDefine.js",
      clientFunction: "deleteBroadcast",
    };
    let url = baandaServer + deleteABroadcast;
    // console.log("delete url:", url, "input:", input);
    try {
      let delret = await axios.post(url, input);
      if (delret.data.status === "success") {
        this.setState({
          updtMsg: "Successfully Deleted",
          updtErrflg: false,
          broadcastInitType: "new",
          broadcastName: "",
          description: "",
          letterSubject: "",
          contactEmail: "",
          broadcastId: 0,
          theBroadcast: null,
        });
        this.props.returnToCaller(this.state.theBroadcast);
      } else {
        this.setState({
          updtMsg: delret.data.Msg,
          updtErrflg: true,
        });
      }
    } catch (err) {
      this.setState({
        updtMsg: err.message,
        updtErrflg: true,
      });
    }
  };

  getFilteredBroadcasts = async () => {
    // console.log("in getFilteredBroadcasts");
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "broadcastName=" +
      this.state.broadcastNameToFilter +
      "&communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&status=" +
      this.state.status +
      "&baandaId=" +
      this.props.auth.user.baandaId +
      "&filterType=name&domain=email";
    let url = baandaServer + getBroadcasts + params;
    // console.log("getFilteredBroadcasts url:", url);
    try {
      let getbrd = await axios.get(url);
      // console.log("getbrd:", getbrd);
      if (getbrd.data.status === "success") {
        this.setState({
          filteredBroadcasts: getbrd.data.Msg,
          filtered: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handleABroadcast = async (brd) => {
    // console.log("handleABroadcast brd:", brd);
    // console.log("this.state:", this.state);

    let broadcastName = brd.broadcastName;
    let broadcastId = brd.broadcastId;
    let presentBGColor = "#000";
    if (brd.presentationFrameColor) presentBGColor = brd.presentationFrameColor;

    await this.setState({
      theBroadcast: brd,
      broadcastName,
      description: brd.description,
      letterSubject: brd.letterSubject,
      contactEmail: brd.contactEmail,
      presentBGColor,
      selectedCompType: brd.mailCompositionType,
      showFilterPopup: false,
      broadcastId,
      filteredBroadcasts: [],
      filtered: false,
    });
    // }

    this.props.returnToCaller(this.state.theBroadcast);
  };

  handleCompTypeSelected = async () => {
    // console.log("in handleCompTypeSelected");
    let value = document.getElementById("compTypeSelect").value;
    // console.log("value:", value);
    let selectedCompType = value;

    this.setState({
      compTypeheight: 1,
      selectedCompType,
    });
  };

  onfocus = async () => {
    this.setState({
      compTypeheight: 5,
    });
  };

  onblur = async () => {
    await this.setState({
      compTypeheight: 1,
    });
  };

  viewTemplate = () => {
    // console.log("In view ...");
    this.setState({
      showTemplateOnPopup: true,
    });
  };

  exitFromPopup = () => {
    this.setState({
      showTemplateOnPopup: false,
    });
  };

  clearToNew = () => {
    this.setState({
      broadcastInitType: "new",
      broadcastName: "",
      description: "",
      letterSubject: "",
      contactEmail: "",

      broadcastId: 0,
      theBroadcast: null,

      updtMsg: "",
      updtErrflg: false,

      status: "prepmode",
      broadcastNameToFilter: "",
      showTemplateOnPopup: false,

      compTypeheight: 1,
      selectedCompType: "",

      filteredBroadcasts: [],

      filtered: false,
      presentBGColor: "#000",
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('BrDefine...');

    let selectionPanel;
    selectionPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Select an action&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.broadcastInitType === "new"}
                onChange={this.handleBroadcastInit}
              />{" "}
              New 
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="existing"
                checked={this.state.broadcastInitType === "existing"}
                onChange={this.handleBroadcastInit}
              />{" "}
              Edit
            </label>
          </div>
        </div>
      </div>
    );

    let buttonSavePanel;
    if ( this.state.broadcastName !== '' && this.state.selectedCompType !== '') {
      buttonSavePanel = (
        <div>
          <button
            className="btn_reg_60 "
            type="button"
            onClick={this.saveBroadcast}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save 
          </button>
        </div>
      );
    }


    let buttonDeletePanel = (
      <div>
        <button
          className="btn_exit_del"
          type="button"
          onClick={this.deleteBroadcast}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fa fa-trash" />
        </button>
      </div>
    );

    let clearButton;
    clearButton = (
      <div>
        <button
          className="btn_reg_60 "
          type="button"
          onClick={this.clearToNew}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Clear
        </button>
      </div>
    );

    let buttonViewTemplate;
    if (this.state.selectedCompType !== "") {
      buttonViewTemplate = (
        <div>
          <button
            className="btn_reg_90"
            type="button"
            onClick={this.viewTemplate}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            View Type
          </button>
        </div>
      );
    }

    let compTypePanel;
    compTypePanel = (
      <div>
        <select
          size={this.state.compTypeheight}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          id="compTypeSelect"
          name="compTypeSelect"
          onChange={this.handleCompTypeSelected}
          onSelect={this.handleCompTypeSelected}
          className="composition_select_type"
          defaultValue={"DEFAULT"}
        >
          <option className="" value="DEFAULT" disabled>
            Select composition type
          </option>
          <option className="" value="basic">
            Basic composition
          </option>
          <option className="" value="newsletter">
            News Letter
          </option>
          <option className="" value="slider">
            Slider
          </option>
          <option className="" value="video">
            Video
          </option>
        </select>
      </div>
    );

    let newPanel;
    // if (this.state.broadcastInitType === "new" || this.state.broadcastInitType === "copy") {
    newPanel = (
      <div>
        {this.state.theBroadcast ? (
          <div className="row broadcast-name-row">
            <div className="col-3 text-right broadcast-def-lbl">
              Campaign Name:
            </div>
            <div className="col-9 text-left">
              {this.state.theBroadcast.broadcastName}
            </div>
          </div>
        ) : (
          <div className="row broadcast-name-row">
            <div className="col-3 text-right broadcast-def-lbl">
              Campaign Name:
            </div>
            <div className="col-9 text-left">
              <input
                name="broadcastName"
                type="text"
                value={this.state.broadcastName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="broadcast-name-inp"
                placeholder="Enter unique broadcast name"
              />
            </div>
          </div>
        )}

        <div className="text-center broadcast-define-chars">
          (Allowed 500 chars) &nbsp;&nbsp;Used:&nbsp;
          {this.state.description.length}
        </div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-def-lbl">Description:</div>
          <div className="col-9 text-left">
            <textarea
              name="description"
              maxLength="1000"
              placeholder="Description - internal."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="broadcast-define-descrition"
              onChange={this.onChange}
              value={this.state.description}
              required
            />
          </div>
        </div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-def-lbl">
            Letter Subject:
          </div>
          <div className="col-9 text-left">
            <input
              name="letterSubject"
              type="text"
              value={this.state.letterSubject}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="broadcast-name-inp"
              placeholder="Subject of the broadcast letter"
            />
          </div>
        </div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-def-lbl">
            Contact Email:
          </div>
          <div className="col-9 text-left">
            <input
              name="contactEmail"
              type="text"
              value={this.state.contactEmail}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="broadcast-name-inp"
              placeholder="State the email recipiant may contact"
            />
          </div>
        </div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-def-lbl">
            Presentation BG Color:
          </div>
          <div className="col-3 text-left">
            <input
              name="presentBGColor"
              type="text"
              value={this.state.presentBGColor}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="broadcast-bgcolor"
              placeholder=""
            />
          </div>
          <div
            className="col-1 text-left brd-color-box"
            style={{
              backgroundColor: this.state.presentBGColor,
            }}
          ></div>
          <div className="col-4 text-left">
            <a
              href="https://www.w3schools.com/colors/colors_picker.asp"
              target="_blank"
              rel="noreferrer"
            >
              Color Choice
            </a>
          </div>
        </div>
        <div className="row br-define-comptype-pos">
          <div className="col-6 text-right">
            {this.state.theBroadcast ? "" : compTypePanel}
          </div>
          <div className="col-2 text-right">
            {" "}
            {this.state.theBroadcast ? "" : buttonViewTemplate}
          </div>
          <div className="col-1 text-right">
            {this.state.theBroadcast ? buttonDeletePanel : null}
          </div>
          <div className="col-1 text-right">{buttonSavePanel}</div>
          <div className="col-2 text-left">{clearButton}</div>
        </div>
        {/* <div className="text-center br-define-save-btn-pos">
          {buttonSavePanel} clearButton
        </div> */}
        <div
          className={
            this.state.updtErrflg
              ? "text-center brd-define-msg-err"
              : "text-center brd-define-msg"
          }
        >
          {this.state.updtMsg}
        </div>
      </div>
    );
    // }

    let closeFilterPanel;
    closeFilterPanel = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.closeFilterPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let goSearchButton;
    goSearchButton = (
      <button
        className="btn_reg_40"
        onClick={this.getFilteredBroadcasts}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main comp-back-btn"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchOutputPanel;
    let bool = true;
    if (this.state.filteredBroadcasts.length > 0) {
      searchOutputPanel = (
        <div className="text-center">
          <div className="brd-filtered-box">
            <div>
              {this.state.filteredBroadcasts.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={`${
                        bool
                          ? "row-existing-campaign-dr"
                          : "row-existing-campaign-lt"
                      }`}
                    >
                      <div className="row">
                        <div className="col-9 text-left broadcast-filt-name-lbl">
                          {obj.broadcastName}
                        </div>
                        <div className="col-3 text-center">
                          <button
                            className="btn_reg_30_small"
                            type="button"
                            onClick={() => this.handleABroadcast(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-folder-open" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (this.state.filtered) {
      searchOutputPanel = (
        <div className="text-center">
          <div className="brd-filtered-box">
            <div className="text-center broadcast-no-records-found">
              No records found for&nbsp;{this.state.broadcastInitType}
            </div>
          </div>
        </div>
      );
    }

    let filterNameInputpanel;
    filterNameInputpanel = (
      <div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-filter-lbl">
            Campaigns:
          </div>
          <div className="col-9 text-left">
            <input
              name="broadcastNameToFilter"
              type="text"
              value={this.state.broadcastNameToFilter}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="broadcast-filter-name-inp"
              placeholder="Enter part/full broadcast name"
            />{" "}
            &nbsp;{goSearchButton}
          </div>
        </div>
        {searchOutputPanel}
      </div>
    );

    let showFilterPanel;
    if (this.state.showFilterPopup) {
      let header = "Existing Broadcasts";
      showFilterPanel = (
        <div className="brd-define-filter-popup">
          <div className="text-center brd-def-filter-header">
            {header}&nbsp;&nbsp;{closeFilterPanel}
          </div>
          <div>{filterNameInputpanel}</div>
        </div>
      );
    }

    let showTemplatePanel;
    if (this.state.showTemplateOnPopup) {
      let temp;
      if (this.state.selectedCompType === "basic") temp = basicComposeTemplate;
      if (this.state.selectedCompType === "slider") temp = sliderTemplate;
      if (this.state.selectedCompType === "newsletter") temp = newsLetter;
      if (this.state.selectedCompType === "video") temp = videoTemplate;

      showTemplatePanel = (
        <div className="show-comp-template-box">
          <div className="template-pop-header text-center">
            Template: {this.state.selectedCompType.toUpperCase()}&nbsp;&nbsp;
            {backButton}
          </div>
          <div className="text-center pop-image-placement">
            <img src={temp} alt="Template 1" hight="600px" width="350px" />
          </div>
        </div>
      );
    }

    let activePanel;
    // if (this.state.broadcastInitType) {
    activePanel = (
      <div>
        {newPanel}
        {showFilterPanel}
        {showTemplatePanel}
      </div>
    );
    // }

    let outputPanel = (
      <div>
        <div className="text-ecnter define-header">
          Email Broadcast Initiation
        </div>
        {selectionPanel}
        {activePanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

BrDefine.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(BrDefine));
