import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import StartBusiness from "./start/StartBusiness";
import RoleDeligation from "./roleDeligation/RoleDeligation";
import Templates from "./templates/Templates";
import StripeSetup from "./ccSetup/StripeSetup";
import Invite from "./inviteMembers/Invite";
// import SchoolSetup from "./school/SchoolSetup";
import Groups from "./grouping/GroupSetup";
import ShippingCostSetup from "./shipping/ShippingCostSetup";
import DefaultSetup from "./defaults/DefaultsSetup";

import InitFinancialState from "./receivable/InitFinancialState";

import FincialStartSetup from "./finYearStartSetup/manageFinancialYear";
import SubscriptionSetup from "./subscription/SubscriptionSetup";

import OpenForBusiness from "./open/OpenForBusiness";
import ReturnSetup from "./returns/ReturnSetup";
import DefaultPkgSetup from "./shipping/DefaultPkgSetup";
import AdvertisementSetup from "./advertise/AdvertisementSetup";

import CreateStoreQ from "./coopStore/CreateStoreQ";
import WriteAgreementClauses from "./coopStore/WriteAgreementClauses";
import InitiateAStore from "./coopStore/InitiateAStore";
import CoopStoreMgmt from "./coopStore/CoopStoreMgmt";

import CampaignFooter from "./campaign/CampaignFooter";
import StorewideCoupon from "./coupons/StorewideCoupon";

import axios from "axios";

import "./AdminRouter.css";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

const adminCoreList = ["admincore"];
const adminHrList = ["adminhr"];
const adminStoreList = ["adminstore"];

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

let baandaCommId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;

const readMeCode = "1115000000";

class AdminRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignRoleFlag: false,
      adminOpsSelectorFlag: true,
      templateFlag: false,
      creditCardSetupFlag: false,
      inviteMemberFlag: false,
      // schoolSetupFlag: false,
      groupSetupFlag: false,
      defaultSetupFlag: false,
      shippingCostFlag: false,

      investLoanSetupFlag: false,
      initFinStateFlag: false,
      startTheCompanyFlag: false,
      financialStartYearFlag: false,

      subscriptionFlag: false,
      unlockCatalogItemMasterFlag: false,
      defineCryptoAddressFlag: false,
      openForBusinessFlag: false,

      campaignFooterFlag: false,

      returnSetupFlag: false,
      defaultPkgSetupFlag: false,
      advertisementFlag: false,

      reactStoreQFlag: false,
      writeAgreementFlag: false,
      InitiateAStoreFlag: false,
      coopStoreMgmtFlag: false,
      twilioMgmtFlag: false,
      storewideCouponFlag: false,

      adminStoreMgmtFlag: false,

      thisIsACoopFlag: false,

      selectedOption: "select_id",

      seq01: true,
      seq02: false,
      seq03: false,

      selheight: 1,
      deviceSize: "",

      showErrMsgPanel: false,
      errMsg: "",
      firstEntry: true,

      adminCoreFlag: false,
      adminHrFlag: false,
      adminStoreFlag: false,

      testFlag: false,
      community: null,
    };

    this.doThisAtFirst();
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let showErrMsgPanel = false;
    let errMsg = "";
    if (deviceSize === "small") {
      // showSearchFlag = false;
      showErrMsgPanel = true;
      errMsg =
        "A device (not a smart phone) with a bigger display area is needed for most of the administrative tasks. Please access this module on a larger device. ";
    } else {
      await this.getTheCommunityInfo(this.props.communityId);
      await this.setAdminOptionAccess();
    }
    
    await this.setAuthorizedButtons();

    let thisIsACoopFlag = false;
    if (this.props.community && this.props.community.communityType === "coop")
      thisIsACoopFlag = true;

    await this.setState({
      deviceSize,
      showErrMsgPanel,
      errMsg,
      thisIsACoopFlag,
    });
  };

  doThisAtFirst = async () => {

    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
  };

  setAdminOptionAccess = async () => {
    let seq01 = false;
    let seq02 = false;
    let seq03 = false;

    if (
      parseFloat(this.props.community.communityId) === parseFloat(baandaCommId)
    ) {
      seq01 = true;
      seq02 = true;
      seq03 = true;
    } else {
      let ats = this.state.adminTasksStatus;
      if (ats.financialYearSetup) seq02 = true;
      if (ats.subscriptionSetup) {
        seq03 = true;
      }
    }

    await this.setState({
      seq01,
      seq02,
      seq03,
    });
  };

  setAuthorizedButtons = async () => {

    let adminCoreFlag = false;
    let adminHrFlag = false;
    let adminStoreFlag = false;

    adminCoreList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) adminCoreFlag = true;
    });

    adminHrList.forEach((obj2) => {
      if (this.props.authorizedFuncs.includes(obj2)) adminHrFlag = true;
    });

    adminStoreList.forEach((obj3) => {
      if (this.props.authorizedFuncs.includes(obj3)) adminStoreFlag = true;
    });

    this.setState({
      adminCoreFlag,
      adminHrFlag,
      adminStoreFlag,
    });
  };

  getTheCommunityInfo = async (communityId) => {
    let parms = "communityId=" + communityId;
    let url = baandaServer + getCommunityInfo + parms;

    try {
      let retData = await axios.get(url);

      this.setState({
        adminTasksStatus: retData.data.Msg.adminTaskStates,
        community: retData.data.Msg
      });
    } catch (err) {
      console.log("Error:", err.message);
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleAdminOpsSelected = async () => {
    let value = document.getElementById("adminOpsSelect").value;

    await this.getTheCommunityInfo(this.props.communityId);

    this.setState({
      startTheCompanyFlag: false,
      assignRoleFlag: false,
      adminOpsSelectorFlag: false,
      templateFlag: false,
      creditCardSetupFlag: false,
      inviteMemberFlag: false,
      // schoolSetupFlag: false,
      groupSetupFlag: false,
      defaultSetupFlag: false,
      shippingCostFlag: false,
      subscriptionFlag: false,
      ownershipAllocationFlag: false,
      investLoanSetupFlag: false,
      initFinStateFlag: false,
      financialStartYearFlag: false,
      defineCryptoAddressFlag: false,
      unlockCatalogItemMasterFlag: false,
      openForBusinessFlag: false,
      campaignFooterFlag: false,
      returnSetupFlag: false,
      defaultPkgSetupFlag: false,
      advertisementFlag: false,
      reactStoreQFlag: false,
      writeAgreementFlag: false,
      InitiateAStoreFlag: false,
      coopStoreMgmtFlag: false,
      twilioMgmtFlag: false,
      storewideCouponFlag: false,
      selheight: 1,
    });

    switch (value) {
      case "startTheCompany":
        this.setState({
          startTheCompanyFlag: true,
        });
        break;
      case "assignRole":
        this.setState({
          assignRoleFlag: true,
        });
        break;
      case "setupTemplates":
        this.setState({
          templateFlag: true,
        });
        break;
      case "creditCardSetup":
        this.setState({
          creditCardSetupFlag: true,
        });
        break;
      case "inviteMembers":
        this.setState({
          inviteMemberFlag: true,
        });
        break;

      // case "schoolSetup":
      //   this.setState({
      //     schoolSetupFlag: true,
      //   });
      //   break;
      case "groupSetup":
        this.setState({
          groupSetupFlag: true,
        });
        break;
      case "defaults":
        this.setState({
          defaultSetupFlag: true,
        });
        break;
      case "shippingCost":
        this.setState({
          shippingCostFlag: true,
        });
        break;
      case "investLoanSetup":
        this.setState({
          investLoanSetupFlag: true,
        });
        break;

      case "rereivepayables":
        this.setState({
          initFinStateFlag: true,
        });
        break;

      case "financialStartSummaryValues":
        this.setState({
          financialStartYearFlag: true,
        });
        break;

      case "returnpolicy":
        this.setState({
          returnSetupFlag: true,
        });
        break;

      case "subscriptionSetup":
        this.setState({
          subscriptionFlag: true,
        });
        break;
      case "defineCryptoAddress":
        this.setState({
          defineCryptoAddressFlag: true,
        });
        break;

      case "campaignFooter":
        this.setState({
          campaignFooterFlag: true,
        });
        break;

      case "storewideCoupon":
        this.setState({
          storewideCouponFlag: true,
        });
        break;

      case "openForBusiness":
        this.setState({
          openForBusinessFlag: true,
        });
        break;
      case "DefaultPkgSetup":
        this.setState({
          defaultPkgSetupFlag: true,
        });
        break;
      case "advertisement":
        this.setState({
          advertisementFlag: true,
        });
        break;

      case "coopstoreq":
        this.setState({
          reactStoreQFlag: true,
        });
        break;

      case "coopagreement":
        this.setState({
          writeAgreementFlag: true,
        });
        break;

      case "coopinitstore":
        this.setState({
          InitiateAStoreFlag: true,
        });
        break;

      case "coopstoremgmt":
        this.setState({
          coopStoreMgmtFlag: true,
        });
        break;

      case "twiliomgmt":
        this.setState({
          twilioMgmtFlag: true,
        });
        break;

      default:
    }
  };

  handleReturnToAdminRouter = async () => {
    await this.setState({
      assignRoleFlag: false,
      adminOpsSelectorFlag: false,
      templateFlag: false,
      creditCardSetupFlag: false,
      inviteMemberFlag: false,
      // schoolSetupFlag: false,
      groupSetupFlag: false,
      defaultSetupFlag: false,
      shippingCostFlag: false,
      financialStartYearFlag: false,
      unlockCatalogItemMasterFlag: false,
      advertisementFlag: false,
      reactStoreQFlag: false,
      writeAgreementFlag: false,
      InitiateAStoreFlag: false,
      coopStoreMgmtFlag: false,
      twilioMgmtFlag: false,
      storewideCouponFlag: false,
    });
  };

  onfocus = async () => {
    if (!this.state.firstEntry && !this.state.seq03) {
      await this.getTheCommunityInfo(this.props.communityId);
      await this.setAdminOptionAccess();
    }
    await this.setState({
      selheight: 12,

      firstEntry: false,
      startTheCompanyFlag: false,
      assignRoleFlag: false,
      adminOpsSelectorFlag: true,
      templateFlag: false,
      creditCardSetupFlag: false,
      inviteMemberFlag: false,
      // schoolSetupFlag: false,
      groupSetupFlag: false,
      defaultSetupFlag: false,
      shippingCostFlag: false,
      ownershipAllocationFlag: false,
      investLoanSetupFlag: false,
      initFinStateFlag: false,
      financialStartYearFlag: false,
      subscriptionFlag: false,
      unlockCatalogItemMasterFlag: false,
      openForBusinessFlag: false,
      campaignFooterFlag: false,
      returnSetupFlag: false,
      defaultPkgSetupFlag: false,
      advertisementFlag: false,
      reactStoreQFlag: false,
      writeAgreementFlag: false,
      InitiateAStoreFlag: false,
      coopStoreMgmtFlag: false,
      twilioMgmtFlag: false,
      storewideCouponFlag: false,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  render() {
    // console.log("AdminRouter this.props:", this.props);
    // console.log("AdminRouter this.state:", this.state);
    console.log("AdminRouter...");

    let opsSelectPanel;
    opsSelectPanel = (
      <div>
        <div className="admin_router_space" />
        <div className="row">
          <div className="col-2">&nbsp;</div>
          <div className="col-8 text-center admin-option-text">
            <select
              size={this.state.selheight}
              onFocus={this.onfocus}
              onBlur={this.onblur}
              id="adminOpsSelect"
              name="adminOpsSelect"
              onChange={this.handleAdminOpsSelected}
              onSelect={this.handleAdminOpsSelected}
              className="admin_select"
              defaultValue={"DEFAULT"}
            >
              <option className="admin-option-row-sel" value="DEFAULT" disabled>
                Select an Admin Task
              </option>

              {this.state.adminCoreFlag ? (
                <option
                  className="admin-option-row-light"
                  value="financialStartSummaryValues"
                >
                  &nbsp;Set Date for the Financial Year (One Time)
                </option>
              ) : (
                <option
                  // className="admin-option-row-light"
                  value="financialStartSummaryValues"
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;Set Date for the Financial Year (One Time)
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq02
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="subscriptionSetup"
                  disabled={this.state.seq02 ? false : true}
                >
                  &nbsp;Initiate Membership Subscription/Rent (One Time)
                </option>
              ) : (
                <option
                  // className={
                  //   this.state.seq02
                  //     ? "admin-option-row-dark"
                  //     : "admin-option-row-dark-red"
                  // }
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;Initiate Membership Subscription/Rent (One Time)
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="creditCardSetup"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;CreditCard Payment Setup (One Time)
                </option>
              ) : (
                <option
                  // className={
                  //   this.state.seq03
                  //     ? "admin-option-row-light"
                  //     : "admin-option-row-light-red"
                  // }
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;CreditCard Payment Setup (One Time)
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="setupTemplates"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Templates (invite/reminder letter, invoice)
                </option>
              ) : (
                <option
                  // className={
                  //   this.state.seq03
                  //     ? "admin-option-row-dark"
                  //     : "admin-option-row-dark-red"
                  // }
                  // value="setupTemplates"
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;Templates (invite/reminder letter, invoice)
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="inviteMembers"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Invite New Members
                </option>
              ) : (
                <option
                  // className={
                  //   this.state.seq03
                  //     ? "admin-option-row-light"
                  //     : "admin-option-row-light-red"
                  // }
                  value="inviteMembers"
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;Invite New Members
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  // className={
                  //   this.state.seq03
                  //     ? "admin-option-row-dark"
                  //     : "admin-option-row-dark-red"
                  // }
                  className="admin-option-disabled"
                  value="schoolSetup"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;School Setup and Activate (soon)
                </option>
              ) : (
                <option
                  value="schoolSetup"
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;School Setup and Activate (soon)
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="assignRole"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Role Assignments (on-going)
                </option>
              ) : (
                <option
                  // className={
                  //   this.state.seq03
                  //     ? "admin-option-row-light"
                  //     : "admin-option-row-light-red"
                  // }
                  // value="assignRole"
                  className="admin-option-disabled"
                  disabled={true}
                >
                  &nbsp;Role Assignments (on-going)
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="groupSetup"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Create & Manage Groups{" "}
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Create & Manage Groups{" "}
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="defaults"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Pickup Addresses, Bank Info, & Invoice Terms{" "}
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Pickup Addresses & Check Pay (Remit) To{" "}
                </option>
              )}

              {this.state.adminHrFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="shippingCost"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Shipping From-Addresses & Prices Setup{" "}
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Shipping From-Addresses & Prices Setup{" "}
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="rereivepayables"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Initial Financial State Setup{" "}
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Initial Financial State Setup{" "}
                </option>
              )}

              <option
                // className={
                //   this.state.seq03
                //     ? "admin-option-row-dark"
                //     : "admin-option-row-dark-red"
                // }
                value="investLoanSetup"
                // disabled={this.state.seq03 && this.props.authorizedFuncs.includes('adminbizheads') ? false : true}
                className="admin-option-disabled"
                disabled={true}
              >
                &nbsp;Record an investment or a Loan (soon)
              </option>

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="returnpolicy"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Return Policy Setup{" "}
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Return Policy Setup{" "}
                </option>
              )}

              <option
                className="admin-option-disabled"
                value="defineCryptoAddress"
                disabled={true}
              >
                &nbsp;Define company's crypto wallet address (soon)
              </option>

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="DefaultPkgSetup"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Setup default shipping package size{" "}
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Setup default shipping package size{" "}
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="advertisement"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Branding & promotion image mgmt.
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Branding & promotion image mgmt.
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="campaignFooter"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Configure Email campaign footer
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Configure Email campaign footer
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="storewideCoupon"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Configure Store Wide Sale Coupon
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Configure Store Wide Sale Coupon
                </option>
              )}

              {this.state.adminCoreFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="openForBusiness"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Open For Business - Cut the ribbon
                </option>
              ) : (
                <option className="admin-option-disabled" disabled={true}>
                  &nbsp;Open For Business - Cut the ribbon
                </option>
              )}

              {this.state.adminCoreFlag && this.state.thisIsACoopFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-coop-row-light"
                      : "admin-option-row-light-red"
                  }
                  value="coopstoreq"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Co-op store agreement question setup.
                </option>
              ) : null}

              {this.state.adminCoreFlag && this.state.thisIsACoopFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-coop-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="coopagreement"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Co-op agreement authoring.
                </option>
              ) : null}

              {this.state.adminCoreFlag && this.state.thisIsACoopFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-coop-row-light"
                      : "admin-option-row-dark-red"
                  }
                  value="coopinitstore"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Initiate a new store.
                </option>
              ) : null}

              {this.state.adminCoreFlag && this.state.thisIsACoopFlag ? (
                <option
                  className={
                    this.state.seq03
                      ? "admin-option-coop-row-dark"
                      : "admin-option-row-dark-red"
                  }
                  value="coopstoremgmt"
                  disabled={this.state.seq03 ? false : true}
                >
                  &nbsp;Store approval and other store mgmt.
                </option>
              ) : null}
            </select>
          </div>
          <div className="col-2 text-left admin-info-pos">
            <button
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
        {!this.state.seq03 ? (
          <div className="text-center admin-select-msg">
            Items in red are disabled pending essentials. #1 & #2 must be done
            to initiate.
          </div>
        ) : null}
      </div>
    );

    // console.log("this.props.commpunityid", this.props.communityId);
    let startCompanyPanel;
    startCompanyPanel = (
      <StartBusiness
        communityId={this.props.communityId}
        communityName={this.props.commName}
        returnToOpsRouter={this.handleReturnToAdminRouter}
        role={this.props.role}
      />
    );

    let AssignRolePanel;
    AssignRolePanel = (
      <RoleDeligation
        communityId={this.props.communityId}
        communityName={this.props.commName}
        communityType={this.props.community.communityType}
        returnToOpsRouter={this.handleReturnToAdminRouter}
        role={this.props.role}
      />
    );

    let templatePanel;
    templatePanel = (
      <Templates
        communityId={this.props.communityId}
        communityName={this.props.commName}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let ccSetupPanel;
    ccSetupPanel = (
      <StripeSetup
        communityId={this.props.communityId}
        communityName={this.props.commName}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let invitePanel;
    invitePanel = (
      <Invite
        communityId={this.props.community.communityId}
        communityName={this.props.community.commName}
        communityCaption={this.props.community.commCaption}
        membershipFee={this.props.community.membershipFee}
        fileUploads={this.props.community.fileUploads}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    // let schoolSetupPanel;
    // schoolSetupPanel = (
    //   <SchoolSetup
    //     communityId={this.props.communityId}
    //     communityName={this.props.commName}
    //     community={this.state.community}
    //     baandaId={this.props.auth.user.baandaId}
    //   />
    // );

    let groupPanel;
    groupPanel = (
      <Groups
        communityId={this.props.communityId}
        communityName={this.props.commName}
        calledBy="Admin"
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let shippingPanel;
    shippingPanel = (
      <ShippingCostSetup
        communityId={this.props.communityId}
        communityName={this.props.commName}
        returnToOpsRouter={this.handleReturnToAdminRouter}
        role={this.props.role}
      />
    );

    let returnSetupPanel;
    returnSetupPanel = (
      <ReturnSetup
        communityId={this.props.communityId}
        communityName={this.props.commName}
        community={this.state.community}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let defaultPanel;
    defaultPanel = (
      <DefaultSetup
        communityId={this.props.communityId}
        communityName={this.props.commName}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let initFinStatePanel;
    initFinStatePanel = (
      <InitFinancialState
        communityId={this.props.communityId}
        communityName={this.props.commName}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let investLoanSetupPanel;
    // investLoanSetupPanel = (
    //   <Invest
    //     communityId={this.props.communityId}
    //     communityName={this.props.commName}
    //     returnToOpsRouter={this.handleReturnToAdminRouter}
    //   />
    // );

    let fincialFirstPanel;
    fincialFirstPanel = (
      <FincialStartSetup
        communityId={this.props.communityId}
        communityName={this.props.commName}
        community={this.state.community}
        baandaId={this.props.auth.user.baandaId}
        returnToOpsRouter={this.handleReturnToAdminRouter}
      />
    );

    let subscriptionPanel;
    subscriptionPanel = (
      <div>
        <SubscriptionSetup
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let cryptoPanel;
    // cryptoPanel = (
    //   <div>
    //     <CryptoAddress
    //       communityId={this.props.communityId}
    //       communityName={this.props.commName}
    //       community={this.props.community}
    //       returnToOpsRouter={this.handleReturnToAdminRouter}
    //     />
    //   </div>
    // );

    // let unlockCatalogPanel;
    // unlockCatalogPanel = (
    //   <div>
    //     <UnlockCatalog
    //       communityId={this.props.communityId}
    //       communityName={this.props.commName}
    //       community={this.props.community}
    //       returnToOpsRouter={this.handleReturnToAdminRouter}
    //     />
    //   </div>
    // );

    let setPkgsizePanel;
    setPkgsizePanel = (
      <div>
        <DefaultPkgSetup
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let advertisementPanel;
    advertisementPanel = (
      <div>
        <AdvertisementSetup
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let coopStoreQPanel;
    coopStoreQPanel = (
      <div>
        <CreateStoreQ
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let writeAgreementPanel;
    writeAgreementPanel = (
      <div>
        <WriteAgreementClauses
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let initiateStorePanel;
    initiateStorePanel = (
      <div>
        <InitiateAStore
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let coopStoreMgmtPanel;
    coopStoreMgmtPanel = (
      <div>
        <CoopStoreMgmt
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let campaignFooterPanel;
    campaignFooterPanel = (
      <div>
        <CampaignFooter
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let storewideCouponPanel;
    storewideCouponPanel = (
      <div>
        <StorewideCoupon
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    let openForBizPanel;
    openForBizPanel = (
      <div>
        <OpenForBusiness
          communityId={this.props.communityId}
          communityName={this.props.commName}
          community={this.state.community}
          returnToOpsRouter={this.handleReturnToAdminRouter}
        />
      </div>
    );

    //SetTwilioAccount
    // let setTwilioPhone;
    // setTwilioPhone = (
    //   <div>
    //     <SetTwilioAccount
    //       communityId={this.props.communityId}
    //       communityName={this.props.commName}
    //       community={this.props.community}
    //       returnToOpsRouter={this.handleReturnToAdminRouter}
    //     />
    //   </div>
    // );

    let outputPanel;
    if (this.state.adminOpsSelectorFlag && !this.state.showErrMsgPanel) {
      outputPanel = opsSelectPanel;
    }

    // if (this.state.twilioMgmtFlag && !this.state.showErrMsgPanel) {
    //   outputPanel = (
    //     <div>
    //       {opsSelectPanel}
    //       {setTwilioPhone}
    //     </div>
    //   );
    // }

    if (this.state.assignRoleFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {AssignRolePanel}
        </div>
      );
    }

    if (this.state.templateFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {templatePanel}
        </div>
      );
    }

    if (this.state.creditCardSetupFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {ccSetupPanel}
        </div>
      );
    }

    if (this.state.inviteMemberFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {invitePanel}
        </div>
      );
    }

    // if (this.state.schoolSetupFlag && !this.state.showErrMsgPanel) {
    //   outputPanel = (
    //     <div>
    //       {opsSelectPanel}
    //       {schoolSetupPanel}
    //     </div>
    //   );
    // }

    if (this.state.groupSetupFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {groupPanel}
        </div>
      );
    }

    if (this.state.shippingCostFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {shippingPanel}
        </div>
      );
    }

    if (this.state.defaultSetupFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {defaultPanel}
        </div>
      );
    }

    if (this.state.investLoanSetupFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {investLoanSetupPanel}
        </div>
      );
    }

    if (this.state.startTheCompanyFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {startCompanyPanel}
        </div>
      );
    }

    if (this.state.initFinStateFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {initFinStatePanel}
        </div>
      );
    }

    if (this.state.financialStartYearFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {fincialFirstPanel}
        </div>
      );
    }

    if (this.state.subscriptionFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {subscriptionPanel}
        </div>
      );
    }

    if (this.state.defineCryptoAddressFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {cryptoPanel}
        </div>
      );
    }

    // if (this.state.unlockCatalogItemMasterFlag && !this.state.showErrMsgPanel) {
    //   outputPanel = (
    //     <div>
    //       {opsSelectPanel}
    //       {unlockCatalogPanel}
    //     </div>
    //   );
    // }

    if (this.state.campaignFooterFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {campaignFooterPanel}
        </div>
      );
    }

    if (this.state.storewideCouponFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {storewideCouponPanel}
        </div>
      );
    }

    if (this.state.openForBusinessFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {openForBizPanel}
        </div>
      );
    }

    if (this.state.defaultPkgSetupFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {setPkgsizePanel}
        </div>
      );
    }

    if (this.state.advertisementFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {advertisementPanel}
        </div>
      );
    }

    if (this.state.returnSetupFlag && !this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          {opsSelectPanel}
          {returnSetupPanel}
        </div>
      );
    }

    if (this.props.community && this.props.community.communityType === "coop") {
      if (this.state.reactStoreQFlag && !this.state.showErrMsgPanel) {
        outputPanel = (
          <div>
            {opsSelectPanel}
            {coopStoreQPanel}
          </div>
        );
      }
    }

    if (this.props.community && this.props.community.communityType === "coop") {
      if (this.state.writeAgreementFlag && !this.state.showErrMsgPanel) {
        outputPanel = (
          <div>
            {opsSelectPanel}
            {writeAgreementPanel}
          </div>
        );
      }
    }

    if (this.props.community && this.props.community.communityType === "coop") {
      if (this.state.InitiateAStoreFlag && !this.state.showErrMsgPanel) {
        outputPanel = (
          <div>
            {opsSelectPanel}
            {initiateStorePanel}
          </div>
        );
      }
    }

    if (this.props.community && this.props.community.communityType === "coop") {
      if (this.state.coopStoreMgmtFlag && !this.state.showErrMsgPanel) {
        outputPanel = (
          <div>
            {opsSelectPanel}
            {coopStoreMgmtPanel}
          </div>
        );
      }
    }

    let errorPanel;
    if (this.state.showErrMsgPanel) {
      errorPanel = (
        <div className="admin-error-box">
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="device-error-msg">
              {this.state.errMsg}
            </p>
          </div>
        </div>
      );
    }

    if (this.state.showErrMsgPanel) {
      outputPanel = errorPanel;
    }

    return (
      <div className="text-center">
        <div className="admin-text">Setup & Admin Tasks</div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

AdminRouter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRouter);
