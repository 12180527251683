import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import axios from "axios";

import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";

import logo from "../../../../../images/logo/baandaNavbar2.png";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./Toolbar.css";

// const guestLoginId = process.env.REACT_APP_BAANDA_GUEST;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBaandaDna = "/routes/admin/getBaandaDna?";

const readMeCode = "0100000000";

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      deviceSize: "",

      baandaDna: null,
      // showToolbar: true,
    };
  }

  componentDidMount = async () => {
    // console.log("In here ...........................");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getBaandaAdmins();

    // let showToolbar = true;
    // if (this.props.auth.user.email === guestLoginId) {
    //   showToolbar = false;
    // }

    this.setState({
      deviceSize,
      // showToolbar
    });
  };

  getBaandaAdmins = async () => {
    // console.log('in getBaandaAdmins...')
    let url = baandaServer + getBaandaDna;
    // console.log('getBaandaAdmin url:', url);
    try {
      let bdnaret = await axios.get(url);
      // console.log("bdnaret.data:", bdnaret.data);
      if (bdnaret.data.status === "success") {
        this.setState({
          baandaDna: bdnaret.data.Msg,
        });
      } else {
        // console.log("getBaandaDna API err:", bdnaret.data.Msg);
      }
    } catch (err) {
      console.log("getBaandaDna try-catch err:", err.message);
    }
    // await this.setState({
    //   baandaAdmins: ["jit@baanda.com", "jc@baanda.com"],
    // });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleBaandaAdmin = async () => {
    if (this.state.deviceSize === "big") {
      if (!this.props.auth.user.isInitProfileDone) {
        // Create  initial profile
        this.props.history.push("/profilemgmt");
      } else {
        // Go and create/edit communities to author & publish
        this.props.history.push("/theBaandaAdmin");
      }
    } else {
      await this.setState({
        adminDeviceErrorFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Toolbar...");

    const { isAuthenticated, user } = this.props.auth;

    let showAdm = false;
    if (isAuthenticated) {
      if (this.state.baandaDna) {
        this.state.baandaDna.accessibleTo.forEach((elm) => {
          // console.log("elm:", elm);
          if (elm.email === this.props.auth.user.email) {
            showAdm = true;
          }
        });
      }
    }

    let navbardisp;
    if (isAuthenticated) {
      if (this.props.guest) {
        navbardisp = (
          <div>
            <ul>
              <li>
                <button
                  className="nav-readme-btn"
                  type="button"
                  // onClick={this.openAlertModal("tokenInput")}
                  onClick={this.openAlertModal}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Readme&nbsp;
                  {this.state.deviceSize === "big" ? (
                    <i className="fas fa-info-circle" />
                  ) : null}
                </button>
              </li>
              <li>
                <button
                  onClick={this.props.onLogoutClickApp}
                  className="nav-logout-btn "
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Exit
                </button>
              </li>
            </ul>
          </div>
        );
      } else {
        navbardisp = (
          <div>
            <ul>
              <li className="toolbar-acc-name">
                {/* <font color="#ffffff">{user.name}'s</font>&nbsp;&nbsp; */}
                {user.name}'s&nbsp;
                <button
                  onClick={this.props.onMyAccountClickApp}
                  className="nav-logout-btn"
                  // className="nav-logout-btn "
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Account
                </button>
              </li>
              <li>
                <button
                  onClick={this.props.onLobbyClickApp}
                  className="nav-logout-btn"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Lobby
                </button>
              </li>

              {/* <li>
                <button
                  className="popup nav-logout-btn"
                  onClick={this.popOpen}
                  onMouseOut={this.popClose}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Buzz
                </button>
              </li> */}

              <li>
                <button
                  className="nav-readme-btn"
                  type="button"
                  // onClick={this.openAlertModal("tokenInput")}
                  onClick={this.openAlertModal}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Readme&nbsp;
                  {this.state.deviceSize === "big" ? (
                    <i className="fas fa-info-circle" />
                  ) : null}
                </button>
              </li>
              <li>
                <button
                  onClick={this.props.onLogoutClickApp}
                  className="nav-logout-btn "
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Logout
                </button>
              </li>
              {showAdm ? (
                <button
                  // className="btn-lobby-starthere"
                  className="btn-baanda-admin-tool"
                  type="button"
                  onClick={this.handleBaandaAdmin}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Adm
                </button>
              ) : null}
            </ul>
          </div>
        );
      }
    } else {
      navbardisp = (
        <div>
          <ul>
            <li>
              <button
                onClick={this.props.onSignupClickApp}
                // className="transparent-button"
                className="nav-signup-btn"
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {this.state.user} Sign Up
              </button>
            </li>
            <li>
              <Link className="nav-link" to="/login">
                <button
                  onClick={this.props.onSignupClickApp}
                  // className="transparent-button"
                  className="nav-login-btn"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Login
                </button>
              </Link>
            </li>
          </ul>
        </div>
      );
    }

    return (
      <div>
        <header className="toolbar">
          <nav className="toolbar_navigation">
            <div className="toolbar__toggle-button">
              <DrawerToggleButton click={this.props.drawerClickHandler} />
            </div>
            <div className="toolbar_logo">
              {/* <img className="logof" src={logo} alt="logo" className="baanda-logo-img"/> &nbsp;&nbsp; */}
              <a
                href="http://www.baanda.com"
                target="_blank"
                rel="noopener noreferrer"
                className="baanda-site"
              >
                &nbsp;&nbsp;
                <img src={logo} alt="logo" className="baanda-logo-img" />{" "}
                &nbsp;&nbsp;
              </a>
              &nbsp; &nbsp;
            </div>
            <div className="spacer" />
            <div className="toolbar_navigation-items">{navbardisp}</div>
            {/* {this.state.showToolbar ? (
              <div className="toolbar_navigation-items">{navbardisp}</div>
            ) : <div className="toolbar_navigation-items">&nbsp;</div>} */}
          </nav>
        </header>
        <ModalContainer />
      </div>
    );
  }
}

// Toolbar.propTypes = {
//   // auth: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   // auth: state.auth,
// });

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(connect(null, mapDispatchToProps)(Toolbar));
