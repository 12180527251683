import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";

import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import ReactLoading from "react-loading";

// import CacheBuster from "./CacheBuster";

import store from "./store";

// List of components that work together to form Baanda
import Navbar from "./entrance/components/layout/Navbar/Navbar";
import Login from "./entrance/components/auth/Login";
import Lobby from "./entrance/components/layout/Lobby";

// import Register from "./entrance/components/auth/Register";
// import TheBuzz from "./thebuzz/TheBuzzMain";
// import BaandaAdmin from "./baandaAdmin/BaandaAdminRouter";
// import InitialProfile from "./intelligence/components/persona/InitialProfile";
// import CreateComm from "./communityCreate/create/CommunityCreation";
// import JoinComm from "./communityJoin/components/CommunityJoin";
// import MyAccount from "./account/myAccount/MyAccount";

import PresentCampaign from "./campaign/PresentCampaign";
import Dashboard from "./dashboard/components/dashboardMain/Dashboard";

import CacheBuster from "./CacheBuster";

import "./App.css";

const BaandaAdmin = lazy(() => import("./baandaAdmin/BaandaAdminRouter"));
const Register = lazy(() => import("./entrance/components/auth/Register"));
const MyAccount = lazy(() => import("./account/myAccount/MyAccount"));
const JoinComm = lazy(() => import("./communityJoin/components/CommunityJoin"));
const CreateComm = lazy(() =>
  import("./communityCreate/create/CommunityCreation")
);
const TheBuzz = lazy(() => import("./thebuzz/TheBuzzMain"));
const InitialProfile = lazy(() =>
  import("./intelligence/components/persona/InitialProfile")
);
const Xchange = lazy(() => import("./xchange/ExchangeMain"));

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));

  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <Provider store={store}>
              <Router>
                <div className="total-space">
                  <Navbar />
                  <div className="container">
                    <Route exact path="/" component={Lobby} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/lobby" component={Lobby} />
                    <Route exact path="/presentCampaign" component={PresentCampaign} />

                    <Suspense
                      fallback={
                        <div className="text-center lazy-loading-pos">
                          <ReactLoading
                            type={"spokes"}
                            color={"#dfe9f0"}
                            height={30}
                            width={30}
                          /> 
                        </div>
                      }
                    >
                      <Route
                        exact
                        path="/theBaandaAdmin"
                        component={BaandaAdmin}
                      />
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route exact path="/register" component={Register} />
                      <Route exact path="/myaccount" component={MyAccount} />
                      <Route exact path="/joincommunity" component={JoinComm} />
                      <Route
                        exact
                        path="/createcommunity"
                        component={CreateComm}
                      />
                      <Route exact path="/xchange" component={Xchange} />
                      <Route exact path="/thebuzz" component={TheBuzz} />
                      <Route
                        exact
                        path="/profilemgmt"
                        component={InitialProfile}
                      />
                    </Suspense>
                  </div>
                </div>
              </Router>
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
