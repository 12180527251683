import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./SendEmailToOwner.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const sendCatalogEmail = "/routes/dashboard/sendCatalogEmail";

class SendEmailToOwner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      emailtext: "",
      subject: "",
    };
  }

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  exitEmailBox = () => {
    this.props.returnToCaller();
  };

  validation = () => {
    let msg = "";
    let status = "success";
    if (this.state.emailtext.length < 20) {
      status = "error";
      msg = "Message must be more than 20 and less than 1000 chars.";
    }
    if (this.state.subject === "") {
      status = "error";
      if (msg !== "") {
        msg += " Must have a subject.";
      } else {
        msg = "Must have a subject.";
      }
    }

    return { status, Msg: msg };
  };

  handleEmailSend = async () => {
    let valret = this.validation();
    if (valret.status === "success") {
      try {
        let cell = "";
        if (this.props.auth.user.cell) cell = this.props.auth.user.cell.number;
        let input = {
          toEmail: this.props.toEmail,
          fromEmail: this.props.auth.user.email,
          fromName: this.props.auth.user.name,
          fromCell: cell,
          subject: this.state.subject,
          emailText: this.state.emailtext,
          communityId: this.props.communityId
        };
        let url = baandaServer + sendCatalogEmail;
        // console.log('url:', url, ' input:', input );
        let sret = await axios.post(url, input);
        if (sret.data.status === "success") {
          this.setState({
            subject: "",
            emailtext: "",
            errMsg: "Successfully sent. Click back to exit.",
            errFlag: false,
          });
        } else {
          this.setState({
            errMSg: sret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMSg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMSg: valret.Msg,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('SendEmailToOwner...');

    // let infoModalButton = (
    //   <button
    //     className="btn_info_main"
    //     type="button"
    //     onClick={this.openAlertModal}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     <i className="fas fa-info-circle" />
    //   </button>
    // );

    let sendButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleEmailSend}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Send
      </button>
    );

    let inputPanel = (
      <div>
        <div className="row land-sub-pos">
          <div className="col-2 text-right">Subject</div>
          <div className="col-10 text-left">
            <input
              name="subject"
              type="text"
              value={this.state.subject}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="landing-subject-txt"
              placeholder="Subject within 50 characters"
            />
          </div>
        </div>
        <div className="row land-note-pos">
          <div className="col-2 text-right">Note</div>
          <div className="col-10 text-left">
            <textarea
              name="emailtext"
              maxLength="5000"
              placeholder="Write an inquiry note to owner within 1000 characters."
              rows="8"
              wrap="hard"
              spellCheck="true"
              className="input_email_text_aa"
              onChange={this.onChange}
              value={this.state.emailtext}
              required
            />
          </div>
        </div>

        <div className="text-center">{sendButton}</div>
      </div>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitEmailBox}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let outputPanel;
    outputPanel = (
      <div className="landing-email-owner-box">
        <div className="landing-email-header">Note to Owner {backButton}</div>
        <div className="text-center send-email-to-owner-lbl">
          Sender: {this.props.auth.user.name} -- {this.props.auth.user.email}
        </div>

        <div className="text-center send-email-to-owner-lbl">{inputPanel}</div>
        <div className={this.state.errFlag? "landing-email-owner-msg-err": "landing-email-owner-msg"}>
          {this.state.errMsg}          
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

SendEmailToOwner.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(
  mapStateToProps
  //   mapDispatchToProps
)(withRouter(SendEmailToOwner));
