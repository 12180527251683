import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { emailValidation } from "../../../../utils/emailValidation";

import axios from "axios";

import DatePicker from "react-datepicker";
// import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import "./Invite.css";

import { sortArrayOfObjects } from "../../../../utils/sortArrayOfObjects";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getTemplateInfo = "/routes/reports/getTemplateInfo?";
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const sendNewMemberInvite = "/routes/join/sendInvitesToJoinCommunity";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115600000";

class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inviteName: "",
      inviteEmail: "",
      invitationMsg: "Write name, email & invite letter.",
      inviteNameErrFlag: false,

      subject: "",
      salute: "",
      theletter: "",
      validTillDate: new Date(),
      respect: "",
      invitorEmail: "",
      invitorName: "",
      membershipFee: 0,
      deviceSize: "",
      showSendButton: true,

      inviteeType: "individual",
      searchGroupName: "",
      groups: [],
      groupSearchDone: false,

      selectedGroup: null,
      selectedFlag: false,
      sortedMembers: [],
      showGroupPanel: false,
      showMemberPanel: false,

      agreementFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
      membershipFee: this.props.membershipFee,
      validTillDate: new Date().setDate(new Date().getDate() + 1),
    });

    let params =
      "communityId=" + this.props.communityId + "&templateType=inviteLetter";
    let url = baandaServer + getTemplateInfo + params;
    try {
      let info = await axios.get(url);

      if (info.data.Msg.length === 0) {
        await this.setState({
          invitationMsg:
            "Please setup invite-letter template under 'Administration' before inviting.",
          inviteNameErrFlag: true,
          showSendButton: false,
          invitorEmail: this.props.auth.user.email,
          invitorName: this.props.auth.user.name,
        });
      } else {
        await this.setState({
          subject: info.data.Msg[0].subject,
          salute: info.data.Msg[0].salute,
          theletter: info.data.Msg[0].letterBody,
          respect: info.data.Msg[0].invoiceSignature.bottomLine1,
          invitorEmail: info.data.Msg[0].invoiceSignature.bottomLine3,
          invitorName: info.data.Msg[0].invoiceSignature.bottomLine2,
          showSendButton: true,
        });
      }
    } catch (err) {
      console.log("Err:", err.message);
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  getGroups = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&groupName=" +
      this.state.searchGroupName;

    let url = baandaServer + getGroupsOfCommunity + params;

    try {
      let grp = await axios.get(url);

      if (grp.data.status.toLowerCase() === "success") {
        if (grp.data.Msg.length > 0) {
          await this.setState({
            groups: grp.data.Msg,
            errMsg: "",
            groupSearchDone: true,
            selectedFlag: false,
          });
        } else {
          await this.setState({
            groups: grp.data.Msg,
            errMsg: "No match available with the search criteria.",
            groupSearchDone: false,
            selectedFlag: false,
          });
        }
      }
    } catch (err) {
      console.log("err:", err.message);
    }

    if (document.getElementById("adminInvGroupSel"))
      document.getElementById("adminInvGroupSel").value = "DEFAULT";
  };

  fomratPhoneNumber = (phonestr) => {
    let ntype = "nocountrycode";
    if (phonestr.length > 10) ntype = "countrycode";

    if (ntype === "nocountrycode") {
      let cleaned = ("" + phonestr).replace(/\D/g, "");
      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
    }
    if (ntype === "countrycode") {
      let cleaned = ("" + phonestr).replace(/\D/g, "");

      //Check if the input is of correct
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
    }

    return null;
  };

  createInputData = () => {
    let imageFile = null;
    if (this.props.fileUploads.length > 0) {
      this.props.fileUploads.forEach((obj) => {
        if (obj.type === "image") {
          imageFile = {
            fileName: obj.key.split("\\").pop().split("/").pop(),
            location: obj.location,
          };
        }
      });
    }

    let invitees = [];
    // For an individual
    if (this.state.inviteeType === "individual") {
      let invObj = {
        inviteeEmail: this.state.inviteEmail.toLowerCase(),
        inviteeName: this.state.inviteName,
      };
      invitees.push(invObj);
    }
    // For a group
    if (this.state.inviteeType === "group") {
      this.state.selectedGroup.members.forEach((obj) => {
        let invObj = {
          inviteeEmail: obj.email.toLowerCase(),
          inviteeName: obj.name,
        };
        invitees.push(invObj);
      });
    }

    let logoLocation = "";
    this.props.fileUploads.forEach((lg) => {
      if (lg.type === "logo") logoLocation = lg.location;
    });

    let data = {
      communityId: this.props.communityId,
      communityCaption: this.props.communityCaption,
      logoLocation,
      inviteeType: this.state.inviteeType, // individual or group
      invitees,
      subject: this.state.subject,
      salute: this.state.salute,
      theletter: this.state.theletter,
      respect: this.state.respect,
      invitorEmail: this.state.invitorEmail.toLowerCase(),
      invitorName: this.state.invitorName,
      baandaId: this.props.auth.user.baandaId,
      membershipFee: this.state.membershipFee,
      offerValidTillDate: new Date(this.state.validTillDate),
      offerValidTill: new Date(
        new Date(this.state.validTillDate).toString().split("GMT")[0] + " UTC"
      ).toISOString(),
      // offerValidTillxx: this.state.validTillDate,
      imageFile,
      clientProgram: "Invite.js",
      clientFunction: "handleInviteDespatch",
    };

    return data;
  };

  handleInviteDespatch = async () => {
    let isValid = await this.validate();

    if (isValid.status) {
      let inputData = this.createInputData();

      let url = baandaServer + sendNewMemberInvite;

      try {
        let ret = await axios.post(url, inputData);

        if (ret.data.status === "error") {
          this.setState({
            invitationMsg: ret.data.Msg,
            inviteNameErrFlag: true,
          });
        } else {
          this.setState({
            invitationMsg: ret.data.Msg,
            inviteNameErrFlag: false,
            inviteName: "",
            inviteEmail: "",
          });
        }
      } catch (err) {
        console.log("Invite post Err:", err.message);
        this.setState({
          invitationMsg: "Invite error:" + err.message,
          inviteNameErrFlag: true,
        });
      }
    } else {
      this.setState({
        invitationMsg: "Invite error:" + isValid.Msg,
        inviteNameErrFlag: true,
      });
    }
  };

  validate = async () => {
    let emailValid = emailValidation(this.state.inviteEmail);

    let nameLength = this.state.inviteName.length;
    let msg = "";
    let valid = true;
    if (this.state.inviteeType === "individual") {
      if (nameLength < 2) {
        msg = "Name must be min 2 chars+. | ";
        valid = false;
      }
      if (!emailValid) {
        msg = msg + "Invalid email syntax. | ";
        valid = false;
      }
    }

    if (
      this.state.inviteeType === "group" &&
      this.state.selectedGroup === null
    ) {
      valid = false;
      msg = "You elected group of invitees but did not select any group.";
    }

    if (!valid) {
      await this.setState({
        inviteNameErrFlag: true,
        invitationMsg: msg,
      });
    } else {
      await this.setState({
        invitationMsg: "Please confirm & click 'Send' to invite.",
        inviteNameErrFlag: false,
      });
    }

    return { status: valid, Msg: msg };
  };

  onChangeMembershipFee = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        membershipFee: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleInviteValidTill = async (date) => {
    await this.setState({
      validTillDate: date,
    });
  };

  handleInviteeType = async (e) => {
    let value = e.target.value;
    let showGroupPanel = false;
    if (value === "group") showGroupPanel = true;
    await this.setState({
      inviteeType: value,
      showGroupPanel,
      selectedGroup: null,
      sortedMembers: [],
      showMemberPanel: false,
    });
  };

  goToGroups = async () => {
    await this.setState({
      showGroupPanel: true,
    });
  };

  onfocus = async () => {
    let selht = 4;
    if (this.state.groups.length < 4) selht = this.state.groups.length + 1;
    await this.setState({
      selheight: selht,
      showMemberPanel: false,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  handleGroupSelected = async () => {
    let value = document.getElementById("adminInvGroupSel").value;
    let val = JSON.parse(value);

    await this.setState({
      selectedGroup: val,
      selheight: 1,
      selectedFlag: true,
    });
  };

  handleShowMembersxx = async () => {
    let sortedMembers = await sortArrayOfObjects(
      this.state.selectedGroup.members,
      "name",
      "dsc"
    );

    await this.setState({
      sortedMembers,
      showMemberPanel: true,
    });
  };

  handleExitShowMember = async () => {
    await this.setState({
      showMemberPanel: false,
    });
  };

  exitGroups = async () => {
    await this.setState({
      showGroupPanel: false,
    });
  };

  exitMemberPanel = async () => {
    await this.setState({
      showMemberPanel: false,
    });
  };

  handleAgreementSelect = async () => {
    await this.setState((prevstate) => ({
      agreementFlag: !prevstate.agreementFlag,
      invitationMsg: "Write name, email & invite letter.",
      inviteNameErrFlag: false,
    }));
  };

  render() {
    // console.log("Invite this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Invite...");

    let subModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let groupsNamePanel;
    if (this.state.showGroupPanel) {
      groupsNamePanel = (
        <div>
          <div className="row name-row">
            <div className="col-3 text-right inv-group-name-lbl">
              Group Name
            </div>
            <div className="col-9 text-left input_placement">
              <input
                name="searchGroupName"
                type="text"
                value={this.state.searchGroupName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="admin-inv-group-name"
                placeholder="Enter group name to search"
                spellCheck="false"
              />
              &nbsp;
              <button
                className="btn_searh_main"
                type="button"
                onClick={this.getGroups}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fa fa-search" />
              </button>
              <button
                className="btn_searh_main"
                type="button"
                onClick={this.exitGroups}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <div>
            <p className="text-cente admin-inv-groupname-msg">
              Part/full/blank name to search
            </p>
          </div>
        </div>
      );
    }

    let grplist = this.state.groups.map((obj, i) => {
      return (
        <option key={i} value={JSON.stringify(obj)} className="name-of-groups">
          {obj.groupName}&nbsp;
        </option>
      );
    });

    let groupListPanel;
    groupListPanel = (
      <div className="row">
        <div className="col text-center">
          <select
            size={this.state.selheight}
            id="adminInvGroupSel"
            name="adminInvGroupSel"
            onChange={this.handleGroupSelected}
            className="admin-inv-group-name-select"
            onFocus={this.onfocus}
            onBlur={this.onblur}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a Group
            </option>
            {grplist}
          </select>
        </div>
      </div>
    );

    let groupButtonPanel;
    groupButtonPanel = (
      <div>
        <button
          className="btn_reg_90"
          type="button"
          onClick={this.exitGroups}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Select This
        </button>
        <button
          className="btn_reg_120"
          type="button"
          onClick={this.handleShowMembersxx}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Show Members
        </button>
      </div>
    );

    let theMemberPanel;

    let bool = true;
    let showSelectedMembers;

    if (this.state.showMemberPanel) {
      // console.log("In showMemberPanel >>>>>>");
      showSelectedMembers = (
        <div className="fixedsize_invite-members">
          {this.state.sortedMembers.map((post, i) => (
            <div key={i}>
              <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
                <div className="row">
                  <div className="col text-left member-list-text">
                    {post.name}&nbsp;( {post.email} :{" "}
                    {this.fomratPhoneNumber(post.cell)} )
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );

      theMemberPanel = (
        // <div>
        <div className="admin-inv-member-box">
          <div className="text-left inv-admin-member-header">
            Member Name (Email) of {this.state.selectedGroup.groupName}&nbsp;
            {/* <button
              className="btn-admin-inv-exit-group"
              type="button"
              onClick={this.exitMemberPanel}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fa fa-times" />
            </button> */}
          </div>
          {showSelectedMembers}
        </div>
      );
    }

    let groupPanel;
    if (this.state.showGroupPanel) {
      groupPanel = (
        <div className="new-group-box">
          {groupsNamePanel}
          {this.state.groupSearchDone ? groupListPanel : this.state.errMsg}
          {this.state.selectedFlag ? groupButtonPanel : null}
          {theMemberPanel}
        </div>
      );
    }

    let sendToPanel;
    let lblname,
      lblemail,
      lblsubject,
      lblsalute,
      lblrespect,
      lblinvitorname,
      lblinvitoremail;
    if (this.state.deviceSize === "small") {
      lblname = "Name";
      lblemail = "Email";
      lblsubject = "Subject";
      lblsalute = "Salute";
      lblrespect = "Respect";
      lblinvitorname = "Y'r Name";
      lblinvitoremail = "Y'r Email";
    } else {
      lblname = "Invitee Name";
      lblemail = "Invitee Email";
      lblsubject = "Subject";
      lblsalute = "Salutation";
      lblrespect = "Respect";
      lblinvitorname = "Your Name";
      lblinvitoremail = "Your Email";
    }

    sendToPanel = (
      <div>
        <div className="row invitee-type-row">
          {/* <div className="col text-center radio-font-style"> */}
          <div className="col text-center invite-radio-font-style">
            <strong>Invitee Type: </strong>One person at a time.
            {/* <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="individual"
                  checked={this.state.inviteeType === "individual"}
                  onChange={this.handleInviteeType}
                />{" "}
                An individual
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="group"
                  checked={this.state.inviteeType === "group"}
                  onChange={this.handleInviteeType}
                />{" "}
                A group
              </label>
            </div> */}
          </div>
        </div>

        {this.state.inviteeType === "individual" ? (
          <div className="row">
            <div className="col-3 text-right inv-lbl">{lblname}</div>
            <div className="col-9">
              <input
                name="inviteName"
                type="text"
                value={this.state.inviteName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="input_text_inviting"
                placeholder="Name of person you are inviting"
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col inv-lbl text-center">
              {this.state.selectedGroup ? (
                <font color="green">
                  Invitation target Group is '
                  {this.state.selectedGroup.groupName}'.
                </font>
              ) : (
                <div className="col inv-lbl text-center">
                  <font color="red">No target group selected.</font>
                  {/* <button
                    className="btn-admin-inv-go-group"
                    type="button"
                    onClick={this.goToGroups}
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fa fa-cog" />
                  </button> */}
                </div>
              )}
            </div>
          </div>
        )}

        {groupPanel}
        {/* {theMemberPanel} */}
        {/* {!this.state.showGroupPanel ? ( */}
        {this.state.inviteeType === "individual" ? (
          <div className="row">
            <div className="col-3 text-right inv-lbl">{lblemail}</div>
            <div className="col-9">
              <input
                name="inviteEmail"
                type="text"
                value={this.state.inviteEmail}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="input_text_inviting"
                placeholder="Email of the invitee"
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col inv-lbl text-center inv-inp-static-gap">
              &nbsp;
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-3 text-right inv-lbl">{lblsubject}</div>
          <div className="col-9">
            <input
              name="subject"
              type="text"
              value={this.state.subject}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_text_inviting"
              placeholder="Letter Subject"
            />
          </div>
        </div>
        <div className="row mem-fee-till">
          <div className="col-md-6 invite-date-position">
            <div className="row">
              <div className="col-4 text-right">Valid To</div>
              <div className="col-8 text-left">
                <DatePicker
                  selected={this.state.validTillDate}
                  onChange={this.handleInviteValidTill}
                  name="actionDate"
                  minDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {this.props.membershipFee === 0 ? null : (
              <div className="inv-lbl">
                Membership Fee&nbsp;$&nbsp;
                <input
                  name="membershipFee"
                  type="number"
                  value={this.state.membershipFee}
                  onChange={this.onChangeMembershipFee}
                  // size="10"
                  className="comm-membership-fee"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                  spellCheck="false"
                />
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-lbl">{lblsalute}</div>
          <div className="col-9">
            <input
              name="salute"
              type="text"
              value={this.state.salute}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="input_text_inviting"
              placeholder="Heil the invitee"
            />
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
            <textarea
              name="theletter"
              maxLength="500"
              placeholder="Write a short description about your community or catalog."
              rows="4"
              wrap="hard"
              spellCheck="true"
              className="input_inviteletter"
              onChange={this.onChange}
              value={this.state.theletter}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-lbl">{lblrespect}</div>
          <div className="col-9">
            <input
              name="respect"
              type="text"
              value={this.state.respect}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="input_text_inviting"
              //   placeholder="Respect"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-lbl">{lblinvitorname}</div>
          <div className="col-9">
            <input
              name="invitorName"
              type="text"
              value={this.state.invitorName}
              onChange={this.onChange}
              size="50"
              maxLength="75"
              className="input_text_inviting"
              //   placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-lbl">{lblinvitoremail}</div>
          <div className="col-9">
            <input
              name="invitorEmail"
              type="text"
              value={this.state.invitorEmail}
              onChange={this.onChange}
              size="50"
              maxLength="150"
              className="input_text_inviting"
            />
          </div>
          <div className="col-md-6">&nbsp;</div>
        </div>

        <div className="row button_row_space">
          <div
            className={`${
              !this.state.inviteNameErrFlag
                ? "invite_name_msg col-6 text-center"
                : "invite_name_msg_err col-6 text-center"
            }`}
          >
            {this.state.invitationMsg}
          </div>
          {this.state.showSendButton ? (
            <div className="col-6 text-center">
              <Checkbox
                checked={this.state.agreementFlag}
                onChange={this.handleAgreementSelect}
              />{" "}
              I consent to send&nbsp;&nbsp;
              {this.state.agreementFlag ? (
                <button
                  className="btn_reg_80"
                  onClick={this.handleInviteDespatch}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Send&nbsp;
                  <i className="fas fa-paper-plane" />
                </button>
              ) : (
                <button
                  className="btn_reg_80_disabled"
                  // onClick={this.handleInviteDespatch}
                  // style={{
                  //   cursor: this.state.disabled ? "default" : "pointer",
                  // }}
                >
                  Send&nbsp;
                  <i className="fas fa-paper-plane" />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );

    let outputPanel;

    outputPanel = (
      <div className="new-invite-box">
        <div className="admin-send-invite-header">
          Send invites to join your community &nbsp;{subModalButton}
        </div>

        {sendToPanel}
      </div>
    );

    return (
      <div className="text-center">
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Invite.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Invite));
// export default connect(mapStateToProps)(withRouter(Invite));
