import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modalActions";
import "../../../css/localModal.css";

import "./ReadPdf.css";

class ReadPdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todotext: "",
    };
  }
  closeModal = (e) => {
    e.preventDefault();
    const modTask = {
      todotext: this.state.todotext,
    };
    this.props.hideModal(modTask);
  }; 

  render() {
    // console.log("ReadPdf this.props:", this.props);
    console.log('ReadPdf...');

    let s3Url = this.props.message.Body.s3Url;
    let fileName = this.props.message.Body.fileName;
    // console.log("s3Url:", s3Url, " fileName:", fileName);

    let footermsg, closeButton;
    if ( this.props.deviceSize === 'big') {
      footermsg = "Please wait moment ... to fetch it from the cloud";
      closeButton = "Close";
    } else {
      footermsg = "Please wait to load ...";
      closeButton = "Cl";
    }

    return (
      <div className="container">
        <div className="modal-content-z">
          <div className="row text-center justify-content-center">
            <div className="pdf-header-text">
              <font color="white">{fileName}</font>
            </div>
          </div>

          <div className="modal-body">
            <div className="fixedsize-showpdf">
              <iframe
                src={s3Url}
                title={fileName}
                className="show_pdf_doc"
              ></iframe>
            </div>
          </div>

          <div className="modal-footer text-center footer-note">
            {footermsg} &nbsp;&nbsp;
            <button
              type="button"
              className="btn_view_pdf_close"
              onClick={this.closeModal}
              onChange={this.onChange}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <strong>{closeButton}</strong> &nbsp;
              <div className="float-right">
                <i className="far fa-window-close" />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ReadPdf.propTypes = {
  hideModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { hideModal })(ReadPdf);
