import React, { Component } from "react";

import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import "./manageFinancialYear.css";
import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveFinancialYear = "/routes/dashboard/saveFinancialYear";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const monname = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul", 
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class manageFinancialYear extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finYYYY: "",
      finMMDD: "",
      minimalAdminSetupDone: false,
      financialYearSetup: false,

      finStartDate: new Date(),
      maxDate: new Date(),
      confirmwords: "",

      updateOK: false,
      monDateStr: "",
      finMsg: "Warning: Published date CANNOT be modified.",
      finDateStr: "",

      deviceSize: "",
      community: null,
      communityId: 0,

      dateISSetupFlag: false,

      errMsg: '',
      errFlag : false,

    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getTheCommunityInfo(this.props.communityId);
    // console.log("this.state.community:", this.state.community);

    let finYYYY = "",
      finMMDD = "";
    if (this.state.community) {
      finYYYY = this.state.community.finYYYY;
      finMMDD = this.state.community.finMMDD;
    }

    if ( this.state.community ) {
      let minimalAdminSetupDone = this.state.community.minimalAdminSetupDone;

      let financialYearSetup = false;
      if (this.state.community.adminTaskStates.financialYearSetup)
        financialYearSetup =
          this.state.community.adminTaskStates.financialYearSetup;
  
      let todayStr = moment().format("YYYY-MM-DD");
      // console.log("todayStr:", todayStr);
      let finDateStr;
  
      if (finYYYY === "") finYYYY = todayStr.substring(0, 4);
      let month, dat;
      if (finMMDD === "") {
        finMMDD = todayStr.substring(5, 10);
        month = todayStr.substring(5, 7);
        dat = todayStr.substring(8, 10);
      } else {
        month = finMMDD.substring(0, 2);
        dat = finMMDD.substring(3, 5);
      }
      finDateStr = finYYYY + "-" + finMMDD;
      // console.log("finMMDD:", finMMDD);
  
      await this.setState({
        communityId: this.props.communityId,
        deviceSize,
        finYYYY,
        finMMDD,
        minimalAdminSetupDone,
        monDateStr: monname[parseFloat(month) - 1] + ", " + dat.toString(),
        finDateStr,
        financialYearSetup,
        dateISSetupFlag: true,
      });
    } else {
      await this.setState({
        errMsg: "Network error. ",
        errFlag: true
      })
    }  
  };


  getTheCommunityInfo = async (communityId) => {
    let parms = "communityId=" + communityId;
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("url: ", url, " parms:", parms);
    let community = null;
    try {
      let retData = await axios.get(url);
      // console.log("retData:", retData);
      if ( retData.data.status === 'success') {
        community = retData.data.Msg;
        await this.setState({
          community: retData.data.Msg,
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
    }

    return community;
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
    await this.testIfSame();
  };

  testIfSame = async () => {
    if (this.state.confirmwords === "Above is the financial start date") {
      await this.setState({
        updateOK: true,
      });
    } else {
      await this.setState({
        updateOK: false,
      });
    }
  };

  hnadleFinStartDate = async (date) => {
    let seldate = moment(date).format("YYYY-MM-DD");
    let selyearStr = seldate.substring(0, 4);
    let selyearmmdd = seldate.substring(5, 10);
    let month = seldate.substring(5, 7);
    let dat = seldate.substring(8, 10);
    // console.log("month:", month);
    // console.log("mstr:", monname[parseFloat(month) - 1] + " " + dat.toString());
    let finyear = monname[parseFloat(month) - 1] + " " + dat.toString();
    await this.setState({
      finStartDate: date,
      finYYYY: selyearStr,
      finMMDD: selyearmmdd,
      monDateStr: finyear,
      finDateStr: selyearStr + "-" + selyearmmdd,
    });
  };

  packageForDb = async () => {
    let input = {
      communityId: this.state.communityId,
      finYearStartDateString: this.state.finDateStr,
      financialYearString: this.state.finYYYY,
      financialMMDDString: this.state.finMMDD,
      baandaId: this.props.baandaId,
      // communityCreationExpense: this.state.commCreationFeeObj.creditsCharged,
      clientProgram: "manageFinancialYear",
      clientFunction: "saveFinYearDate",
    };

    return input;
  };

  saveFinYearDate = async () => {
    try {
      let inp = await this.packageForDb();
      let url = baandaServer + saveFinancialYear;
      // console.log("url:", url, " inp:", inp);
      let finret = await axios.post(url, inp);
      // console.log("finret:", finret);
      if (finret.data.status === "success") {
        await this.setState({
          finMsg: "First financial year data has been set and initiated.",
          minimalAdminSetupDone: true,
          financialYearSetup: true,
        });
      }
    } catch (err) {
      console.log("saveFinYearDate err:", err.message);
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('manageFinancialYear...');

    let dateSelectPanel;
    dateSelectPanel = (
      <div className="row">
        <div className="col-3 text-right person_label">Start Date:</div>
        <div className="col-9 text-left dob_sizing">
          {" "}
          <DatePicker
            selected={this.state.finStartDate}
            onChange={this.hnadleFinStartDate}
            // onSelect={this.hnadleFinStartDate}
            maxDate={this.state.maxDate}
            name="finStartDate"
            dateFormat="MM/dd/yyyy"
          />
        </div>
      </div>
    );

    let infoPanel;
    infoPanel = (
      <div className="fixedsize-finadmin-info">
        <ul>
          <li>
            <p align="justify">
              Set the start date for the financial year of your community. The financial year is also known as the fiscal year.
            </p>
          </li>
          <li>
            <p align="justify">
              This will be set once in the life of your business or community.
            </p>
          </li>
          <li>
            <p align="justify">
              This date should align with the company/community tax date and
              other governmental documents financial year.
            </p>
          </li>
          <li>
            <p align="justify">
              <b>The year should be the year your business started on the Baanda
              platform irrespective of how old your business is. Example: Say
              your financial start date is April 1. Now it is January 10 of this
              year. If you want to start on Baanda platform now, use April 1 of
              the previous year.</b>
            </p>
          </li>
          <li>
            <p align="justify" className="warning-finyear-msg">
              Warning: Once the financial date is published, it will be fixed
              for the rest of the business' life on Baanda. The reason being, all
              financial transactions including year-end book keeping will work
              off of this date. If you are not sure,
              consult with your accountant before setting this
              up. In an extreme case, contact Baanda Support to see if the date can be
              changed.
            </p>
          </li>
        </ul>
      </div>
    );

    let rightPanel;
 
    if (this.state.dateISSetupFlag) {
      if (this.state.financialYearSetup) {
        rightPanel = (
          <div className="text-center fin-year-setup-locked">
            <h5>Financial Year Setup Status</h5>
            <p align="justify">
              Your financial year setup is complete. Your current financial
              start date is {this.state.monDateStr} of the year{" "}
              {this.state.finYYYY}.
            </p>
          </div>
        );
      } else {
        rightPanel = (
          <div>
            <div className="row">
              {/* <div className="col">&nbsp;</div> */}
              <div className="col text-center">{dateSelectPanel}</div>
        
            </div>
            <div className="row fin-year-mm-dd-pos">
              <div className="col-4 text-right admin-fin-lbl">Year</div>
              <div className="col-8 text-left admin-fin-lbl">
                {this.state.finYYYY}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right admin-fin-lbl">MM-DD</div>
              <div className="col-8 text-left admin-fin-lbl">
                {this.state.finMMDD}&nbsp;({this.state.monDateStr})
              </div>
            </div>
            <div className="row fin-confirm-words-pos">
              <div className="col text-left admin-fin-word-lbl">
                Above is the financial start date
              </div>
            </div>
            <div className="row search-other-place">
              <div className="col text-left search-lbl">
                <input
                  name="confirmwords"
                  type="text"
                  value={this.state.confirmwords}
                  onChange={this.onChange}
                  size="50"
                  maxLength="50"
                  className="admin-fin-confirm"
                  placeholder="Type the words in blue exactly to Publish"
                />
              </div>
            </div>
            <div>
              {this.state.updateOK ? (
                <button
                  className="btn_reg_70 admin-finyear-bub-btn-pos"
                  type="button"
                  onClick={this.saveFinYearDate}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Publish
                </button>
              ) : (
                <button
                  className="btn_reg_70_disabled admin-finyear-bub-btn-pos"
                  type="button"
                  disabled
                >
                  Publish
                </button>
              )}
            </div>
            <div className="text-center fin-setup-msg">{this.state.finMsg}</div>
          </div>
        );
      }
    }

    let outputPanel;
    if ( !this.state.errFlag ) {
      outputPanel = (
        <div className="admin-finyear-box">
          <div className="row">
            <div className="col-md-6">{infoPanel}</div>
            <div className="col-md-6">{rightPanel}</div>
          </div>
        </div>
      );
    } else {
      outputPanel = (
        <div className="admin-finyear-box text-center">
           {this.state.errMsg}
        </div>
      );
    }
    

    return <div>{outputPanel}</div>;
  }
}

export default manageFinancialYear;
