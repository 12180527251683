import React, { Component } from "react";

import axios from "axios";

import "./View.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";

const yourtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const yourTimeOffset = new Date().getTimezoneOffset() / 60;

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      tasklist: [],
      showDetailPanelFlag: false,
      selectedTask: null,
      theEvent: null,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let tet = await this.getTheEvent(this.props.theEvent.eventId);
    // console.log('tet:', tet);
    this.setState({
      deviceSize,
      tasklist: tet.prepTasks,
      theEvent: tet,
    });


  };

  getTheEvent = async (eventId) => {
    let param = "eventId=" + eventId;

    let url = baandaServer + getAnEvent + param;
    // console.log("url:", url);
    let theEvent = null;
    try {
      let eret = await axios.get(url);
      if (eret.data.status === "success") {
        theEvent = eret.data.Msg;
      } else {
        // console.log("Err data:", eret.data.Msg);
        this.setState({
          errMsg: eret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("Err sys:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
    return theEvent;
  };

  handleTaskOpen = async (task) => {
    // console.log("task:", task);
    await this.setState({
      selectedTask: task,
      showDetailPanelFlag: true,
      taskId: task.taskId,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("View...");

    let bool = true;
    let taskListPanel;
    if (this.state.tasklist.length > 0) {
      taskListPanel = (
        <div className="cal-view-task-list-box">
          {this.state.tasklist.map((obj, i) => (
            <div key={i}>
              <div className={`${bool ? "row-ops-task-a" : "row-ops-task-b"}`}>
                <div
                  className={
                    obj.taskId === this.state.taskId
                      ? "row task-ops-active-row"
                      : "row"
                  }
                >
                  <div className="col-8 text-left">{obj.taskName}</div>
                  <div className="col-2 text-left task-view-done-red">
                    {obj.percentageDone}%&nbsp;done
                  </div>
                  <div className="col-2 text-center">
                    <button
                      onClick={() => this.handleTaskOpen(obj)}
                      className="btn_reg_50"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );
    }

    let barPanel;

    if (this.state.selectedTask) {
      let perdone = this.state.selectedTask.percentageDone;
      let perdonstr = perdone.toString();
      let stl = {
        height: "20px",
        width: perdonstr + "%",
      };

      barPanel = (
        <div className="task-done-bar-position">
          <div className="row">
            <div className="col-3 text-right task-completion-lbl">
              Completion
            </div>
            <div className="col-6">
              {" "}
              <div className="w3-light-grey">
                <div
                  className="w3-green text-right task-view-done-red"
                  // style={{ height: "24px", width: '50%' }}
                  style={stl}
                >
                  <font color="#000000">{perdone}%&nbsp;Done</font>
                </div>
              </div>
            </div>
            <div className="col-3">&nbsp;</div>
          </div>
        </div>
      );
    }

    let statusPanel;
    if (this.state.selectedTask) {
      statusPanel = (
        <div>
          <div className="row">
            <div className="col text-center task-state-format">
              <b>Task Budget Amount:</b>&nbsp;$
              {this.commaFormattedCurrency(
                this.state.selectedTask.amountAllocated
              )}
              &nbsp; ||&nbsp;<b>Amount Spent:</b>&nbsp;${" "}
              {this.commaFormattedCurrency(this.state.selectedTask.amountSpent)}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center task-state-format">
              <b>Start Date:</b>&nbsp;
              {this.state.selectedTask.startDate.substring(0, 10)}
            </div>
            <div className="col-md-6 text-center task-state-format">
              <b>End Date:</b>&nbsp;
              {this.state.selectedTask.endDate.substring(0, 10)}
            </div>
          </div>
          <div className="task-state-format text-center">
            <b>Task Status:</b>&nbsp;
            {this.state.selectedTask.status.toUpperCase()}
          </div>
        </div>
      );
    }

    let tskbool = true;
    let commentsPanel;
    if (
      this.state.selectedTask &&
      this.state.selectedTask.comments.length > 0
    ) {
      commentsPanel = (
        <div>
          <div className="cal-comment-list-box">
            {this.state.selectedTask.comments.map((obj, i) => (
              <div key={i}>
                <div
                  className={`${tskbool ? "row-ops-task-a" : "row-ops-task-b"}`}
                >
                  <div className="row">
                    <div className="col name-time text-left">
                      By&nbsp;{obj.byName}&nbsp;({obj.byEmail}
                      )&nbsp;(State&nbsp;
                      {obj.percentAssociate}%)
                    </div>
                  </div>
                  <div className="row">
                    <div className="col name-time text-left">
                      Commented On&nbsp;{obj.commentedOn} GMT [Your Timezone:{" "}
                      {yourtimezone}, Offset: {yourTimeOffset}]
                    </div>
                  </div>
                  <div className="row">
                    <div className="col the-comment">
                      <p align="justify">{obj.comment}</p>
                    </div>
                  </div>
                </div>

                {(tskbool = !tskbool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let showDetailPanel;
    if (this.state.showDetailPanelFlag) {
      showDetailPanel = (
        <div>
          {barPanel}
          {commentsPanel}
          {statusPanel}
        </div>
      );
    }

    let outputPanel = (
      <div>
        <div className="view-header">Event's Tasks State</div>
        {taskListPanel}
        {showDetailPanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default View;
