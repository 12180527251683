import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./Direct.css";

import { emailValidation } from "../../../../../../utils/emailValidation";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
// const getCustomersOfCommunity = "/routes/dashboard/getCustomersOfCommunity?";
// const getMembersOfGroup = "/routes/dashboard/getMembersOfGroup?";
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1145910000";

class Direct extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      audienceType: "none",
      groups: [],
      selectedGroups: [],
      searchGroupName: "",
      selheight: 1,
      price: 0,
      gateprice: 0,
      exposureType: "public",

      memberName: "",
      members: [],
      customers: [],
      selectedMembers: [],

      outsidersName: "",
      outsidersEmail: "",
      outsidersCell: "",
      memErrMsg: "",
      directions: "",
      attendeeBye: "",
      attendeeHello: "",

      toNotifyMembers: [],
      notifyVia: null,
      smsText: "",
      smsHello: "",
      smsBye: "",

      emailText: "",
      emailHello: "",
      emailBye: "",

      theEvent: null,
      eventId: 0,

      getEventMsgFlag: false,
      getMMSMsgFlag: false,
      getEmailFlag: false,

      bmailFlag: true,
      textFlag: false,
      emailTargetType: "emailall",
      inviteSubject: "",

      showMemberSelectionFlag: false,
      showGroupsFlag: false,
      refundMessage: "",
      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let audienceType = "none";
    if (this.props.theEvent.audienceType !== "")
      audienceType = this.props.theEvent.audienceType;

    let showGroupsFlag = false;
    if (this.props.theEvent.audienceType === "groups") {
      showGroupsFlag = true;
      await this.getGroups();
    }

    let notifyVia = this.state.notifyVia;
    if (
      this.props.theEvent &&
      this.props.theEvent.inviteMedium &&
      this.props.theEvent.inviteMedium.email &&
      this.props.theEvent.inviteMedium.email.length >= 0
    ) {
      notifyVia = this.props.theEvent.inviteMedium;
      notifyVia.bmail = true;
    } else {
      notifyVia = {
        email: "emailall",
        bmail: true,
        text: true,
      };
    }

    let inviteSubject = "";
    if (this.props.theEvent.inviteSubject)
      inviteSubject = this.props.theEvent.inviteSubject;

    let refundMessage = "";
    if (this.props.theEvent.refundMessage)
      refundMessage = this.props.theEvent.refundMessage;

    // let bmailFlag = notifyVia.bmail;
    // let textFlag = notifyVia.text;
    let textFlag = false;
    let emailTargetType = notifyVia.email;

    await this.getMembers();

    let exposureType = "public";
    if (this.props.theEvent.eventOpenTo !== "")
      exposureType = this.props.theEvent.eventOpenTo;

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
      eventId: this.props.theEvent.eventId,
      exposureType,
      selectedMembers: this.props.theEvent.sendInvitesTo,
      selectedGroups: this.props.theEvent.groups,
      audienceType: audienceType,
      notifyVia,
      smsText: this.props.theEvent.smsText,
      smsHello: this.props.theEvent.smsHello,
      smsBye: this.props.theEvent.smsBye,
      directions: this.props.theEvent.attendeeDirection,
      attendeeBye: this.props.theEvent.attendeeBye,
      attendeeHello: this.props.theEvent.attendeeHello,
      emailText: this.props.theEvent.emailText,
      emailHello: this.props.theEvent.emailHello,
      emailBye: this.props.theEvent.emailBye,
      price: this.props.theEvent.priceToAttend,
      gateprice: this.props.theEvent.priceToAttendAtGate,
      bmailFlag: this.state.bmailFlag,
      textFlag,
      emailTargetType,
      inviteSubject,
      refundMessage,
      showGroupsFlag,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  validateDirection = async () => {
    let isValid = true;
    let msg = "";

    if (this.state.audienceType !== "none") {
      // if (this.state.notifyVia.text && this.state.smsText === "") {
      //   msg = "MMS Msg. cannot be blank for SMS notification; ";
      //   isValid = false;
      // }
      if (this.state.notifyVia.bmail && this.state.directions === "") {
        msg = msg + "Bmail msg cannot be blank for bmail notification; ";
        isValid = false;
      }
      if (
        this.state.notifyVia.email !== "noemail" &&
        this.state.emailText === ""
      ) {
        msg = msg + "Email msg cannot be blank for email notification; ";
        isValid = false;
      }

      if (this.state.audienceType === "individuals") {
        if (this.state.selectedMembers.length === 0) {
          msg += "Select at least one individual to notify; ";
          isValid = false;
        }
      }

      if (this.state.audienceType === "groups") {
        if (this.state.groups.length === 0) {
          msg += "Select a group to notify; ";
          isValid = false;
        }
      }
    }

    return { status: isValid, Msg: msg };
  };

  setNotificationTargets = async () => {
    let toNotifyMembers = [];
    if (this.state.audienceType === "community") {
      await this.setState({
        memberName: "", // Make membername space for all members
      });
      await this.getMembers();
      if (this.state.members.length > 0) {
        this.state.members.forEach((obj) => {
          let cell = "";
          if (obj.cell) cell = obj.cell;
          let mem = {
            baandaId: obj.baandaId,
            name: obj.name,
            email: obj.email.toLowerCase(),
            cell,
            type: obj.role,
            isMember: true,
          };
          toNotifyMembers.push(mem);
        });
      }
    }

    if (
      this.state.audienceType === "individuals" &&
      this.state.selectedMembers.length > 0
    ) {
      await this.getMembers();

      let tagMembers = [];
      this.state.selectedMembers.forEach((obj) => {
        let isMember = false;
        let isCustomer = false;
        let role = "";
        let baandaId = 0;
        this.state.members.forEach((elm) => {
          if (obj.email.toLowerCase() === elm.email.toLowerCase()) {
            isMember = true;
            baandaId = elm.baandaId;
            role = elm.role;
          }
        });

        let mem = {
          baandaId: baandaId,
          name: obj.name,
          email: obj.email.toLowerCase(),
          cell: obj.cell,
          type: role,
          isMember,
          isCustomer,
        };
        tagMembers.push(mem);
      });

      toNotifyMembers = [...tagMembers];
    }

    await this.setState({
      toNotifyMembers,
    });
  };

  packDataForDB = async () => {
    if (this.state.audienceType !== "none") await this.setNotificationTargets();

    let inputDirect = {
      eventId: this.state.eventId,
      communityId: this.props.communityId,
      hostBaandaId: this.props.auth.user.baandaId,
      sendInviteTo: this.state.toNotifyMembers,
      priceToAttend: this.state.price,
      priceToAttendAtGate: this.state.gateprice,
      attendeeDirection: this.state.directions,
      attendeeBye: this.state.attendeeBye,
      attendeeHello: this.state.attendeeHello,
      exposureType: this.state.exposureType,
      groups: this.state.selectedGroups,
      audienceType: this.state.audienceType,
      notifyVia: this.state.notifyVia,
      smsText: this.state.smsText,
      smsHello: this.state.smsHello,
      smsBye: this.state.smsBye,
      emailText: this.state.emailText,
      emailHello: this.state.emailHello,
      emailBye: this.state.emailBye,
      inviteSubject: this.state.inviteSubject,
      refundMessage: this.state.refundMessage,
      module: "direct",
      clientProgram: "Direct.js",
      clientFunction: "saveUpdateDirect",
    };
    // console.log(">>>>>>>>>> inputDirect:", inputDirect);
    return inputDirect;
  };

  saveUpdateDirect = async () => {
    let url = baandaServer + saveUpdtEvent;
    let isValid = await this.validateDirection();
    // console.log("isValid:", isValid);
    if (isValid.status) {
      let inp = await this.packDataForDB();
      // console.log(" url:", url, " inp:", inp);
      try {
        let resp = await axios.post(url, inp);
        // console.log("rest:", resp);
        if (resp.data.status === "success") {
          let locEvent = resp.data.Msg.theEvent;

          await this.setState({
            errMsg: "Updated Successfully.",
            errFlag: false,
            eventId: resp.data.Msg.eventId,
            theEvent: locEvent,
          });
          this.returnToOrigin();
        }
      } catch (err) {
        await this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  returnToOrigin = () => {
    this.props.returnToCaller(this.state.theEvent);
  };

  getGroups = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&communityName=" +
      this.props.communityName +
      "&groupName=" +
      this.state.searchGroupName +
      "&fieldType=name";
    let url = baandaServer + getGroupsOfCommunity + params;
    // console.log("url:", url);
    try {
      let grp = await axios.get(url);
      // console.log("grp:", grp);
      if (grp.data.status.toLowerCase() === "success") {
        await this.setState({
          groups: grp.data.Msg,
          errMsg: "",
        });
      } else {
        this.setState({
          errMsg: grp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }

    // console.log("this.state:", this.state);

    if (document.getElementById("groupSelect"))
      document.getElementById("groupSelect").value = "DEFAULT";
  };

  getMembers = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&memberName=" +
      this.state.memberName +
      "&clientProgram=Direct&clientFunction=getMembers";
    let url = baandaServer + getMembersOfCommunity + params;
    try {
      let mems = await axios.get(url);
      // console.log("getMembers mems:", mems);
      if (mems.data.status === "success") {
        await this.setState({
          members: mems.data.Msg,
          errMsg: "",
        });
      } else {
        this.setState({
          errMsg: mems.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      // console.log("Err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    if (document.getElementById("targetMemberSelect"))
      document.getElementById("targetMemberSelect").value = "DEFAULT";
  };

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleParticipantType = async (e) => {
    let value = e.target.value;
    let showGroupsFlag = this.state.showGroupsFlag;
    if (value === "groups") showGroupsFlag = true;

    await this.setState({
      audienceType: value,
      groups: [],
      selectedGroups: [],
      selectedMembers: [],
      toNotifyMembers: [],
      showGroupsFlag,
    });

    if (value === "groups") {
      // console.log("Selected Group");
      if (document.getElementById("groupSelect"))
        document.getElementById("groupSelect").value = "DEFAULT";
    }
  };

  handleExposureType = async (e) => {
    await this.setState({
      exposureType: e.target.value,
    });
  };

  handleGroupSelected = async () => {
    let value = document.getElementById("groupSelect").value;
    let val = JSON.parse(value);
    // console.log("handleGroupSelected:", val);

    let selectedGroups = [];
    selectedGroups.push(val);

    await this.setState({
      selheight: 1,
      selectedGroups,
    });

    if (document.getElementById("groupSelect"))
      document.getElementById("groupSelect").value = "DEFAULT";
  };

  handleMemberSelected = async () => {
    let value = document.getElementById("targetMemberSelect").value;
    let val = JSON.parse(value);
    // console.log("handleMemberSelected val:", val);
    let newSelect = true;
    this.state.selectedMembers.forEach((obj) => {
      if (obj.email === val.email) newSelect = false;
    });
    if (newSelect) {
      this.state.selectedMembers.push(val);
      await this.setState({
        selheight: 1,
      });
    }
  };

  validateNonMember = async () => {
    let isValid = true;
    let msg = "";
    if (this.state.outsidersName === "") {
      isValid = false;
      msg = "Name cannot be blank. | ";
    }
    if (this.state.outsidersEmail === "") {
      isValid = false;
      msg = msg + "Email cannot be blank. | ";
    }
    let emailval = await emailValidation(this.state.outsidersEmail);

    if (!emailval) {
      isValid = false;
      msg = msg + "invalid email syntax. | ";
    }

    return { isValid, Msg: msg };
  };

  handleGuestAdd = async () => {
    let check = await this.validateNonMember();
    if (check.isValid) {
      let selectMember = {
        email: this.state.outsidersEmail,
        cell: this.state.outsidersCell,
        name: this.state.outsidersName,
        type: "nonmember",
        comment: "",
        response: "",
      };
      // console.log("selectMember:", selectMember);
      let newmem = [...this.state.selectedMembers];
      newmem.push(selectMember);
      await this.setState({
        selectedMembers: newmem,
        outsidersEmail: "",
        outsidersName: "",
        outsidersCell: "",
        memErrMsg: "",
      });
    } else {
      await this.setState({
        memErrMsg: check.Msg,
      });
    }

    // console.log("this.state.selectedMembers:", this.state.selectedMembers);
  };

  onfocus = async () => {
    let selht = 4;
    if (this.state.groups.length < 4) selht = this.state.groups.length + 1;
    this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    this.setState({
      selheight: 1,
    });
  };

  onmemfocus = async () => {
    let selht = 4;
    if (this.state.members.length < 4) selht = this.state.members.length + 1;
    this.setState({
      selheight: selht,
    });
  };

  onmemblur = async () => {
    this.setState({
      selheight: 1,
    });
  };

  handleGroupsDelete = async (obj) => {
    let ind = this.state.selectedGroups.indexOf(obj);

    let yy = this.state.selectedGroups;
    yy.splice(ind, 1);
    this.setState({
      selectedGroups: yy,
      errMsg: "",
    });
  };

  handleDeleteMembers = async (obj) => {
    let ind = this.state.selectedMembers.indexOf(obj);

    let yy = this.state.selectedMembers;
    yy.splice(ind, 1);
    this.setState({
      selectedMembers: yy,
      errMsg: "",
    });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;

    let gateprice = this.state.gateprice;
    if (parseFloat(value) === 0) gateprice = 0;

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
        gateprice,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
        gateprice,
      });
    }
  };

  onChangeGatePrice = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  showMessageBox = async (type) => {
    if (type === "eventmessage") {
      this.setState({
        getEventMsgFlag: true,
        getMMSMsgFlag: false,
        getEmailFlag: false,
      });
    }
    if (type === "smsmessage") {
      this.setState({
        getMMSMsgFlag: true,
        getEventMsgFlag: false,
        getEmailFlag: false,
      });
    }
    if (type === "emailmessage") {
      this.setState({
        getEmailFlag: true,
        getMMSMsgFlag: false,
        getEventMsgFlag: false,
      });
    }
  };

  doneMessageBox = async () => {
    this.setState({
      getEventMsgFlag: false,
      getMMSMsgFlag: false,
      getEmailFlag: false,
    });
  };

  handleEmailTargetType = async (e) => {
    let value = e.target.value;
    let nv = JSON.parse(JSON.stringify(this.state.notifyVia));
    nv.email = value;
    this.setState({
      emailTargetType: e.target.value,
      notifyVia: nv,
      errMsg: "",
    });
  };

  handleBmailSelect = async () => {
    let nv = JSON.parse(JSON.stringify(this.state.notifyVia));
    nv.bmail = !this.state.bmailFlag; // If bmail is false not then it will be true in next step.
    this.setState((prevstate) => ({
      bmailFlag: !prevstate.bmailFlag,
      notifyVia: nv,
      errMsg: "",
    }));
    
  };

  handleTextSelect = async () => {
    let nv = JSON.parse(JSON.stringify(this.state.notifyVia));
    nv.text = !this.state.textFlag;
    this.setState((prevstate) => ({
      textFlag: !prevstate.textFlag,
      notifyVia: nv,
      errMsg: "",
    }));
  };

  handleSelectCommunityMember = async () => {
    this.setState({
      showMemberSelectionFlag: true,
    });
  };

  exitSelectCommunityMember = async () => {
    this.setState({
      showMemberSelectionFlag: false,
    });
  };

  render() {
    // console.log("the.props:", this.props);
    // console.log("the.state:", this.state);
    console.log('Direct...');

    let buttonPanel;
    if (
      this.props.theEvent.eventStatus !== "published" ||
      this.props.theEvent.eventStatus === "canceled"
    ) {
      buttonPanel = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.saveUpdateDirect}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
      );
    } else {
      buttonPanel = (
        <div className="text-center post-publish-notify-msg">
          For a published event, this is for view only. You can add note for
          canceling/rescheduling from Review.
        </div>
      );
    }

    let subjectPanel;
    let subjectPanelDefined = false;
    if (this.state.audienceType !== "none") {
      if (this.state.emailTargetType !== "noemail" || this.state.bmailFlag) {
        subjectPanelDefined = true;
        subjectPanel = (
          <div className="row dir-subject-row-placement">
            <div className="col-4 text-right direct-subject-lbl">
              E/Bmail Subject
            </div>
            <div className="col-8 text-left">
              <input
                name="inviteSubject"
                type="text"
                value={this.state.inviteSubject}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="direct-invite-subject"
                placeholder="Enter notification E/Bmail Subject"
              />
            </div>
          </div>
        );
      }
    }
    if (!subjectPanelDefined) {
      subjectPanel = (
        <div>
          <div className="row dir-subject-row-placement">
            <div className="col dir-no-subject">&nbsp;</div>
          </div>
        </div>
      );
    }

    let buttonNMediumPanel;
    if (this.state.audienceType !== "none") {
      buttonNMediumPanel = (
        <div>
          <div className="row name-panel-row-event">
            <div className="col text-center radio-font-style">
              <strong>Notification Via&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="emailall"
                    checked={this.state.emailTargetType === "emailall"}
                    onChange={this.handleEmailTargetType}
                  />{" "}
                  Email All
                </label>
              </div>
              {this.state.audienceType !== "community" ? (
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="emailnonmembers"
                      checked={this.state.emailTargetType === "emailnonmembers"}
                      onChange={this.handleEmailTargetType}
                    />{" "}
                    Email Non-members
                  </label>
                </div>
              ) : null}

              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="noemail"
                    checked={this.state.emailTargetType === "noemail"}
                    onChange={this.handleEmailTargetType}
                  />{" "}
                  No Email
                </label>
              </div>
              {/* <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <Checkbox
                    checked={this.state.textFlag}
                    onChange={this.handleTextSelect}
                  />
                  &nbsp;Text
                </label>
              </div> */}
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <Checkbox
                    checked={this.state.bmailFlag}
                    onChange={this.handleBmailSelect}
                    // disabled
                  />
                  {/* <i className="fa fa-check-square" aria-hidden="true" />  */}
                  &nbsp;Bmailcc
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col text-center direct-msg-lbl">
              {this.state.bmailFlag ||
              this.state.textFlag ||
              this.state.emailTargetType !== "noemail"
                ? "Enter Messages"
                : null}
              &nbsp;&nbsp;&nbsp;
              {this.state.bmailFlag ? (
                <button
                  className="btn_reg_70"
                  type="button"
                  onClick={() => this.showMessageBox("eventmessage")}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Bmail
                </button>
              ) : null}
              {/* {this.state.textFlag ? (
                <button
                  className="btn_reg_70"
                  type="button"
                  onClick={() => this.showMessageBox("smsmessage")}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  SMS
                </button>
              ) : null} */}
              {this.state.emailTargetType === "noemail" ? null : (
                <button
                  className="btn_reg_70"
                  type="button"
                  onClick={() => this.showMessageBox("emailmessage")}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Email
                </button>
              )}
            </div>
          </div>
          {subjectPanel}
          <div className="row direct-save-btn-row">
            <div className="col text-center">{buttonPanel}</div>
          </div>
        </div>
      );
    }

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let notifySelectPanel;
    notifySelectPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Notify:&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="none"
                  checked={this.state.audienceType === "none"}
                  onChange={this.handleParticipantType}
                />{" "}
                None
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="community"
                  checked={this.state.audienceType === "community"}
                  onChange={this.handleParticipantType}
                />{" "}
                Community
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="groups"
                  checked={this.state.audienceType === "groups"}
                  onChange={this.handleParticipantType}
                />{" "}
                Groups
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="individuals"
                  checked={this.state.audienceType === "individuals"}
                  onChange={this.handleParticipantType}
                />{" "}
                Individuals
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let groupsNamePanel;
    if (this.state.audienceType === "groups") {
      groupsNamePanel = (
        <div>
          <div className="row name-row">
            <div className="col-3 text-right inventory-label">Group Name</div>
            <div className="col-9 text-left input_placement">
              <input
                name="searchGroupName"
                type="text"
                value={this.state.searchGroupName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="group-search-field"
                placeholder="Enter group name to filter"
                spellCheck="false"
              />
              &nbsp;
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.getGroups}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Fetch
              </button>
            </div>
          </div>
          <div>
            <p className="text-cente name-msg">
              Part/full/blank of group name.
            </p>
          </div>
        </div>
      );
    }

    let grplist = this.state.groups.map((obj, i) => {
      return (
        <option key={i} value={JSON.stringify(obj)} className="name-of-groups">
          {obj.groupName}&nbsp;
        </option>
      );
    });

    

    let groupListPanel;
    // if (this.state.audienceType === "groups" && this.state.groups.length > 0) {
    if (this.state.showGroupsFlag) {
      groupListPanel = (
        <div>
          <div className="row event-direct-group-row">
            <div className="col-md-6 text-center">
              <select
                size={this.state.selheight}
                id="groupSelect"
                name="groupSelect"
                onChange={this.handleGroupSelected}
                className="group-name-select"
                onFocus={this.onfocus}
                onBlur={this.onblur}
              >
                <option value="DEFAULT" disabled>
                  Select a Group
                </option>
                {grplist}
              </select>
            </div>
            <div className="col-md-6">
              <div className="selected-group-header">Selected Groups</div>
              <div className="selected-grp-box">
                <div>
                  {this.state.selectedGroups.map((obj, i) => {
                    return (
                      <div key={i}>
                        <div className="row">
                          <div className="col-10 text-left group-name-lbl">
                            <b>{obj.groupName}</b> ( {obj.description} )
                          </div>
                          <div className="col-2 text-right">
                            <button
                              className="btn-direct-mem-delete"
                              type="button"
                              onClick={() => this.handleGroupsDelete(obj)}
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let groupPanel;
    if (this.state.audienceType === "groups") {
      groupPanel = (
        <div>
          {groupsNamePanel}
          {groupListPanel}
        </div>
      );
    }

    let memberlNamePanel;
    memberlNamePanel = (
      <div>
        <div className="row name-row">
          <div className="col-3 text-right event-member-name-label">
            Member Name
          </div>
          <div className="col-9 text-left input_placement">
            <input
              name="memberName"
              type="text"
              value={this.state.memberName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="member-search-field"
              placeholder="Enter member name to filter"
              spellCheck="false"
            />
            &nbsp;
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.getMembers}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Fetch
            </button>
          </div>
        </div>
        <div>
          <p className="text-cente event-name-msg">
            Part/full/blank of member name.
          </p>
        </div>
      </div>
    );

    let memberlist = this.state.members.map((obj, i) => {
      return (
        <option key={i} value={JSON.stringify(obj)} className="name-of-groups">
          {obj.name}&nbsp;({obj.email})
        </option>
      );
    });

    let memberListPanel;
    if (
      this.state.audienceType === "individuals" &&
      this.state.members.length > 0
    ) {
      memberListPanel = (
        <div>
          <div className="row">
            <div className="col text-center">
              <select
                size={this.state.selheight}
                id="targetMemberSelect"
                name="targetMemberSelect"
                onChange={this.handleMemberSelected}
                className="event-member-name-select"
                onFocus={this.onmemfocus}
                onBlur={this.onmemblur}
              >
                <option value="DEFAULT">
                  {/* <option value="DEFAULT" disabled> */}
                  Select a Member
                </option>
                {memberlist}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let nonMemberPanel;
    if (this.state.audienceType === "individuals") {
      nonMemberPanel = (
        <div>
          <div className="text-center non-member-guest">
            Non Member Guest Invitee
          </div>
          <div className="row">
            <div className="col-3 text-right nom-member-lbl">Name</div>
            <div className="col-9 text-left">
              <input
                name="outsidersName"
                type="text"
                value={this.state.outsidersName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="non-members-name-email"
                placeholder="Enter guest name"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right nom-member-lbl">Email</div>
            <div className="col-9 text-left">
              <input
                name="outsidersEmail"
                type="text"
                value={this.state.outsidersEmail}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="non-members-name-email"
                placeholder="Enter guest email"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right nom-member-lbl">Cell</div>
            <div className="col-9 text-left">
              <PhoneInput
                placeholder="Guest's cell#"
                value={this.state.outsidersCell}
                onChange={(outsidersCell) => this.setState({ outsidersCell })}
                country="US"
                className="event-direct-cell"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-10 text-center nonmember-err-msg">
              {this.state.memErrMsg}
            </div>
            <div className="col-2 text-left">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.handleGuestAdd}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                <i className="fa fa-address-book" />
                &nbsp;+
              </button>
            </div>
          </div>
        </div>
      );
    }

    let selectedMembersPanel;
    if (this.state.audienceType === "individuals") {
      selectedMembersPanel = (
        <div>
          <div className="col">
            <div className="selected-group-header">Selected Members</div>
            <div className="selected-grp-box">
              <div>
                {this.state.selectedMembers.map((obj, i) => {
                  return (
                    <div key={i}>
                      <div className="row">
                        <div className="col-10 text-left group-name-lbl">
                          {obj.email}
                        </div>
                        <div className="col-2">
                          <button
                            className="btn-direct-mem-delete"
                            type="button"
                            onClick={() => this.handleDeleteMembers(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }

    let memberPanel;
    if (this.state.audienceType === "individuals") {
      memberPanel = (
        <div>
          {/* {memberlNamePanel} */}
          <div className="row individual-data">
            <div className="col-md-6">
              <div className="row">
                <div className="col">
                  <button
                    className="btn_reg_120"
                    type="button"
                    onClick={this.handleSelectCommunityMember}
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    Select Members
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">{nonMemberPanel}</div>
              </div>
            </div>
            <div className="col-md-6">{selectedMembersPanel}</div>
          </div>
        </div> 
      );
    }

    let selectMemberPanel;
    if (this.state.showMemberSelectionFlag) {
      selectMemberPanel = (
        <div className="get-members-box">
          <div className="text-center select-member-header">
            Select Members&nbsp;
            {/* <button onClick={this.exitSelectCommunityMember}>R</button> */}
            <button
              className="btn_back_main"
              type="button"
              onClick={this.exitSelectCommunityMember}
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="text-center">{memberlNamePanel}</div>
          {this.state.members.length > 0 ? (
            <div className="text-center">{memberListPanel}</div>
          ) : null}
        </div>
      );
    }

    let pricePanel;
    pricePanel = (
      <div>
        <div className="row price-position">
          <div className="col-md-4 text-center">
            <b>Participation Fee</b>:&nbsp;$
            <input
              name="price"
              type="number"
              // min="1"
              // max="100.00"
              className="attendence-price"
              onChange={this.onChangePrice}
              value={this.state.price}
              placeholder="0"
              step="1"
            />
          </div>
          {this.state.audienceType === "none" ? (
            <div className="col-md-8">&nbsp;</div>
          ) : (
            <div className="col-md-6">
              <div className="row name-panel-row">
                <div className="col text-left radio-font-style">
                  <strong>Exposure Type&nbsp;</strong>

                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="private"
                        checked={this.state.exposureType === "private"}
                        onChange={this.handleExposureType}
                      />{" "}
                      Private
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="public"
                        checked={this.state.exposureType === "public"}
                        onChange={this.handleExposureType}
                      />{" "}
                      Public
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="community"
                        checked={this.state.exposureType === "community"}
                        onChange={this.handleExposureType}
                      />{" "}
                      Community
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {parseFloat(this.state.price) > 0 ? (
          <div className="row price-position">
            {/* <div className="col-md-4 text-center">
              <b>(Pay at Gate) Fee</b>:&nbsp;$
              <input
                name="gateprice"
                type="number"
                // min="1"
                // max="100.00"
                className="attendence-price"
                onChange={this.onChangeGatePrice}
                value={this.state.gateprice}
                placeholder="0"
                step="1"
              />
            </div> */}
            <div className="col-md-4 text-center">&nbsp;</div>
            <div className="col-md-8 text-center">
              <b>Refund Msg:</b>&nbsp;
              <input
                name="refundMessage"
                type="text"
                value={this.state.refundMessage}
                onChange={this.onChange}
                size="150"
                maxLength="150"
                className="event-refund-msg"
                placeholder="Refund note in 15 chars "
              />
            </div>
          </div>
        ) : null}
      </div>
    );

    let showMessageInputPanel;
    if (this.state.getEventMsgFlag) {
      showMessageInputPanel = (
        <div className="get-messages-box-big">
          <div className="event-message-popup-header">Enter Bmail Message</div>
          <div className="row">
            <div className="col-4 text-right sms-hello-bye">Hail</div>
            <div className="col-4 text-left">
              <input
                name="attendeeHello"
                type="text"
                value={this.state.attendeeHello}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-8 event-directions text-center">
              Additional Messages (with 1000 chars)
            </div>
            <div className="col-4 event-directions text-left">
              Used:&nbsp;{this.state.directions.length}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="directions"
                maxLength="1000"
                placeholder="Provide event's directives for bmail."
                rows="7"
                wrap="hard"
                spellCheck="true"
                className="event-direction-textarea"
                onChange={this.onChange}
                value={this.state.directions}
                required
              />
            </div>
          </div>
          <div className="row sms-signoff-row">
            <div className="col-4 text-right sms-hello-bye">SignOff</div>
            <div className="col-4 text-left">
              <input
                name="attendeeBye"
                type="text"
                value={this.state.attendeeBye}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="text-center">
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.doneMessageBox}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    if (this.state.getMMSMsgFlag) {
      showMessageInputPanel = (
        <div className="get-messages-box">
          <div className="event-message-popup-header">
            Text Notification Message
          </div>
          <div className="row">
            <div className="col-4 text-right sms-hello-bye">Hail</div>
            <div className="col-4 text-left">
              <input
                name="smsHello"
                type="text"
                value={this.state.smsHello}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-8 event-directions-mms text-center">
              Messages (within 200 chars)
            </div>
            <div className="col-4 event-directions-mms text-left">
              Used:&nbsp;{this.state.smsText.length}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="smsText"
                type="text"
                value={this.state.smsText}
                onChange={this.onChange}
                rows="3"
                wrap="hard"
                size="200"
                maxLength="200"
                className="notify-sms-text"
                placeholder="SMS in 150 chars or less"
              />
            </div>
          </div>
          <div className="row sms-signoff-row">
            <div className="col-4 text-right sms-hello-bye">SignOff</div>
            <div className="col-4 text-left">
              <input
                name="smsBye"
                type="text"
                value={this.state.smsBye}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="text-center">
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.doneMessageBox}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    if (this.state.getEmailFlag) {
      showMessageInputPanel = (
        <div className="get-messages-box-big">
          <div className="event-message-popup-header">
            Email Notification Message
          </div>
          <div className="row">
            <div className="col-4 text-right sms-hello-bye">Hail</div>
            <div className="col-4 text-left">
              <input
                name="emailHello"
                type="text"
                value={this.state.emailHello}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-8 event-directions-mms text-center">
              Messages (within 1000 chars)
            </div>
            <div className="col-4 event-directions-mms text-left">
              Used:&nbsp;{this.state.emailText.length}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="emailText"
                type="text"
                value={this.state.emailText}
                onChange={this.onChange}
                rows="7"
                wrap="hard"
                size="1000"
                maxLength="1000"
                className="notify-email-text"
                placeholder="Email in 1000 chars or less"
              />
            </div>
          </div>
          <div className="row sms-signoff-row">
            <div className="col-4 text-right sms-hello-bye">SignOff</div>
            <div className="col-4 text-left">
              <input
                name="emailBye"
                type="text"
                value={this.state.emailBye}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="text-center">
            <button
              className="btn_reg_60 btn-email-done-pos"
              type="button"
              onClick={this.doneMessageBox}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    let outputPanel = (
      <div className="text-center">
        <div className="direction-header">
          Directives - Notifications&nbsp;&nbsp;{infoModalButton}
        </div>
        {notifySelectPanel}
        {groupPanel}
        {memberPanel}
        {/* <hr /> */}
        {selectMemberPanel}
        {this.state.theEvent && this.state.theEvent.eventType === "nonservice"
          ? pricePanel
          : null}
        {showMessageInputPanel}
        {/* <hr /> */}
        <div className="text-center">
          {this.state.audienceType === "none" ? (
            <div className="text-center">{buttonPanel}</div>
          ) : (
            buttonNMediumPanel
          )}
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center event-direct-msg-err"
              : "text-center event-direct-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Direct.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Direct));
