import React, { Component } from "react";

import axios from "axios";

import "./SliderReview.css";

import { sortArrayOfObjects } from "../../../../../../../../utils/sortArrayOfObjects";

import fbicon from "../../../../../../../../images/icons8-facebook-48.png";
import insticon from "../../../../../../../../images/instagram.png";
import twiticon from "../../../../../../../../images/icons8-twitter-48.png";
import youicon from "../../../../../../../../images/youtube.png";
import storeicon from "../../../../../../../../images/store.jpg";
import websiteicon from "../../../../../../../../images/website.jpg";

import ImageSlider from "../../../../../../../../utils/campaign/ImageSlider";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCampaignFooter = "/routes/interact/getCampaignFooter?";

class SliderReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReviewFlag: true,

      bannerLocation: "",
      imageLocation: "",

      introbgColor: "",
      isPersonal: false,
      salute: "",
      signoff: "",

      footer: null,

      bodyForm: null,
      slides: [],

      sliderReviewFrameColor: "#000",
      sliderFontColor: "#fff",
    };
  }

  componentDidMount = async () => {
    let bannerLocation = "";
    // let imageLocation = "";
    let introbgColor = "";
    let isPersonal = false;
    let salute = "";
    let bodyForm = null;

    let sliderReviewFrameColor = "#fff";
    let sliderFontColor = "#000";
    if (this.props.theBroadcast.presentationFrameColor)
      sliderReviewFrameColor = this.props.theBroadcast.presentationFrameColor;

    if (
      sliderReviewFrameColor === "#000" ||
      sliderReviewFrameColor === "#000000"
    ) {
      sliderFontColor = "#fff";
    }
    let bmc = this.props.theBroadcast.mailComposition;
    let slides = [];
    if (bmc) {
      if (this.props.theBroadcast.mailComposition.fileUploads.length > 0) {
        this.props.theBroadcast.mailComposition.fileUploads.forEach((obj) => {
          if (obj.compositionComponent === "banner") {
            bannerLocation = obj.location;
          }
          if (obj.compositionComponent === "frames") {
            slides.push(obj);
          }
        });
      }

      slides = await sortArrayOfObjects(slides, "seqNo", "dsc");

      introbgColor = "white";
      if (bmc.interactionType === "personal") {
        isPersonal = true;
        salute = bmc.salute + " " + this.props.yourName + ", ";
      }

      bodyForm = bmc.introBodyFormat;
    }

    await this.getFooter();

    this.setState({
      bannerLocation,
      sliderReviewFrameColor,
      sliderFontColor,
      introbgColor,
      isPersonal,
      salute,
      bodyForm,
      slides,
    });
  };

  getFooter = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCampaignFooter + param;
    try {
      // console.log("url:", url);
      let fret = await axios.get(url);
      // console.log("fret.data:", fret.data);
      if (fret.data.status === "success") {
        this.setState({
          footer: fret.data.Msg.campaignFooterObj,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: fret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  exitFromPopup = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('SliderReview...');

    let backFromTemplatePop = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let bannerPanel;
    if (this.state.bannerLocation) {
      bannerPanel = (
        <div className="text-center">
          <img
            src={this.state.bannerLocation}
            alt="banner"
            hight="50"
            width="460"
            className="slider-review-banner-pos"
          />
        </div>
      );
    }

    let bcb = this.props.theBroadcast.mailComposition;

    // let introBodyFontSize, introBodyFontFamily;
    // if (this.state.bodyForm) {
    //   introBodyFontSize = this.state.bodyForm.fontSize;
    //   introBodyFontFamily = this.state.bodyForm.fontFamily;
    // }

    let introBodyFontSize,
      introBodyColor,
      introBodybgColor,
      introBodyIsBold,
      introBodyFontFamily,
      introBodyAlign;
    if (bcb.introBodyFormat) {
      introBodyFontSize = bcb.introBodyFormat.fontSize;
      introBodyColor = bcb.introBodyFormat.color;
      introBodybgColor = bcb.introBodyFormat.bgColor;
      introBodyIsBold = bcb.introBodyFormat.isBold;
      introBodyFontFamily = bcb.introBodyFormat.fontFamily;
      introBodyAlign = bcb.introBodyFormat.align;
    }

    let titleFontSize,
      titleColor,
      titlebgColor,
      titleIsBold,
      titleFontFamily,
      titleAlign;
    if (bcb.introTitleFormat) {
      titleFontSize = bcb.introTitleFormat.fontSize;
      titleColor = bcb.introTitleFormat.color;
      titlebgColor = bcb.introTitleFormat.bgColor;
      titleIsBold = bcb.introTitleFormat.isBold;
      titleFontFamily = bcb.introTitleFormat.fontFamily;
      titleAlign = bcb.introTitleFormat.align;
    }

    // let titleFontSize,
    //   titleColor,
    //   titlebgColor,
    //   titleIsBold,
    //   titleFontFamily,
    //   titleAlign;
    // if (bcb.introTitleFormat) {
    //   titleFontSize = bcb.introTitleFormat.fontSize;
    //   titleColor = bcb.introTitleFormat.color;
    //   titlebgColor = bcb.introTitleFormat.bgColor;
    //   titleIsBold = bcb.introTitleFormat.isBold;
    //   titleFontFamily = bcb.introTitleFormat.fontFamily;
    //   titleAlign = bcb.introTitleFormat.align;
    // }

    let introTitlePanel;
    if (bcb.introTitle !== "") {
      introTitlePanel = (
        <div
          style={{
            backgroundColor: titlebgColor,
            width: "460px",
            fontSize: titleFontSize,
            fontFamily: titleFontFamily,
            color: titleColor,
            textAlign: titleAlign,
          }}
          className="comp-title-adj"
        >
          {titleIsBold ? <b>{bcb.introTitle}</b> : bcb.introTitle}
        </div>
      );
    }

    let introPersonalizePanel;
    if (this.state.isPersonal) {
      introPersonalizePanel = (
        <div
          style={{
            backgroundColor: introBodybgColor,
            width: "460px",
            fontSize: introBodyFontSize,
            fontFamily: introBodyFontFamily,
            color: introBodyColor,
            textAlign: introBodyAlign,
          }}
          className="basic-linebreak-text"
        >
          {introBodyIsBold ? <b>{this.state.salute}</b> : this.state.salute}
          {/* <div>{this.state.salute}</div> */}
          <div>&nbsp;</div>
        </div>
      );
    }

    let introPanel;
    introPanel = (
      <div
        style={{
          backgroundColor: introBodybgColor,
          width: "460px",
          fontSize: introBodyFontSize,
          fontFamily: introBodyFontFamily,
          color: introBodyColor,
          textAlign: introBodyAlign,
        }}
        className="basic-linebreak-text"
      >
        {introBodyIsBold ? (
          <b>
            <p align="justify">{bcb.introBody}</p>
          </b>
        ) : (
          <p align="justify">{bcb.introBody}</p>
        )}
        {/* <div> {bcb.introBody} </div> */}
        <div>&nbsp;</div>
      </div>
    );

    let topPanel;
    if (introTitlePanel || introPersonalizePanel || introPanel)
      topPanel = (
        <div className="slide-intro-box">
          {introTitlePanel}
          {introPersonalizePanel}
          {introPanel}
        </div>
      );

    let sliderPanel;
    if (this.state.slides.length > 0) {
      sliderPanel = (
        <div className="slider-container">
          <ImageSlider slides={this.state.slides} domain="review" />
        </div>
      );
    }

    let signoffPanel;
    if (
      this.props.theBroadcast.mailComposition.interactionType === "personal"
    ) {
      signoffPanel = (
        <div
          style={{
            backgroundColor: this.state.introbgColor,
            width: "475px",
            marginLeft: "-10px",
            paddingLeft: "10px",
            color: "black",
          }}
          className="slider-signoff-pos"
        >
          {this.props.theBroadcast.mailComposition.senderPosition !== "" ? (
            <div className="row">
              <div className="col text-left slider-comp-signoff-rev">
                ( {this.props.theBroadcast.mailComposition.senderPosition} )
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col text-left slider-comp-signoff-name">
              {this.props.theBroadcast.mailComposition.senderName}
            </div>
          </div>
          {this.props.theBroadcast.mailComposition.senderPosition !== "" ? (
            <div className="row">
              <div className="col text-left slider-comp-signoff-rev">
                ( {this.props.theBroadcast.mailComposition.senderPosition} )
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let footerPanel;
    if (this.state.footer) {
      footerPanel = (
        <div
          className="slider-footer-box"
          style={{
            backgroundColor: "#fff",
            marginLeft: "-5px",
            paddingleft: "3px",
            paddingright: "15px",
            width: "470px",
          }}
        >
          <div className="text-center">
            {this.state.footer.activeFacebook ? (
              <a
                href={this.state.footer.facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbicon} alt="fbicon" hight="auto" width="30px" />
              </a>
            ) : null}
            &nbsp;
            {this.state.footer.activeInstagram ? (
              <a
                href={this.state.footer.instagramLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={insticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeTwitter ? (
              <a
                href={this.state.footer.twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={twiticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeYoutube ? (
              <a
                href={this.state.footer.youtubeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={youicon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeStore ? (
              <a
                href={this.state.footer.storeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={storeicon} alt="insticon" hight="auto" width="35px" />
              </a>
            ) : null}
            {this.state.footer.activeWebsite ? (
              <a
                href={this.state.footer.websiteLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={websiteicon}
                  alt="insticon"
                  hight="auto"
                  width="35px"
                />
              </a>
            ) : null}
          </div>
          <div className="text-center comp-footer-msg">
            <p className="comp-footer-padding" align="justify">
              {this.state.footer.footerMessage}
            </p>
          </div>
          <div className="text-center comp-unsubscribe-msg">
            <u>Unsubscribe</u>
          </div>
        </div>
      );
    }
    // if (this.state.footer) {
    //   footerPanel = (
    //     <div
    //       className="slider-footer-box"
    //       // style={{
    //       //   backgroundColor: "#ffffff",
    //       //   marginLeft: "3px",
    //       //   paddingLeft: "3px",
    //       //   paddingRight: "15px",
    //       //   width: "457px",
    //       // }}
    //     >
    //       <div className="text-center">
    //         {this.state.footer.activeFacebook ? (
    //           <a
    //             href={this.state.footer.facebookLink}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             <img src={fbicon} alt="fbicon" hight="auto" width="30px" />
    //           </a>
    //         ) : null}
    //         &nbsp;
    //         {this.state.footer.activeInstagram ? (
    //           <a
    //             href={this.state.footer.instagramLink}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             <img src={insticon} alt="insticon" hight="auto" width="30px" />
    //           </a>
    //         ) : null}
    //         {this.state.footer.activeTwitter ? (
    //           <a
    //             href={this.state.footer.twitterLink}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             {" "}
    //             <img src={twiticon} alt="insticon" hight="auto" width="30px" />
    //           </a>
    //         ) : null}
    //         {this.state.footer.activeYoutube ? (
    //           <a
    //             href={this.state.footer.youtubeLink}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             {" "}
    //             <img src={youicon} alt="insticon" hight="auto" width="30px" />
    //           </a>
    //         ) : null}
    //         {this.state.footer.activeStore ? (
    //           <a
    //             href={this.state.footer.storeLink}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             {" "}
    //             <img src={storeicon} alt="insticon" hight="auto" width="35px" />
    //           </a>
    //         ) : null}
    //         {this.state.footer.activeWebsite ? (
    //           <a
    //             href={this.state.footer.websiteLink}
    //             target="_blank"
    //             rel="noreferrer"
    //           >
    //             {" "}
    //             <img
    //               src={websiteicon}
    //               alt="insticon"
    //               hight="auto"
    //               width="35px"
    //             />
    //           </a>
    //         ) : null}
    //       </div>
    //       <div className="text-center comp-footer-msg">
    //         <p className="comp-footer-padding" align="justify">
    //           {this.state.footer.footerMessage}
    //         </p>
    //       </div>
    //       <div className="text-center comp-unsubscribe-msg">
    //         <u>Unsubscribe</u>
    //       </div>
    //     </div>
    //   );
    // }

    let showTemplatePanel;
    showTemplatePanel = (
      <div
        style={{
          backgroundColor: "#fff", //this.state.sliderReviewFrameColor,
          color: "#fff"
        }}
        className="slider-email-review"
      >
        <div className="slider-pop-header text-center">
          Slider Review &nbsp;{backFromTemplatePop}
        </div>
        {bannerPanel}
        {topPanel}
        {sliderPanel}
        {signoffPanel}
        {footerPanel}
      </div>
    );

    let outputPanel;
    outputPanel = <div>{showTemplatePanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default SliderReview;
