import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import CatalogItemFilter from "./CatalogItemFilter";
import CatalogPaginated from "./parts/CatalogPaginated";
import CatalogAdmin from "./parts/CatalogAdmin";
import ViewCatalog from "./ViewPagiCatalog";
import ManageCarts from "./cartmgmt/ManageCarts";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./Catalog.css";

const catalogSalesList = ["sales"];
const cartManagementList = ["cartmgmt"];
const catalogSetupList = ["catalogsetup"];

const readMeCode = "1112000000";

class Catalog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      manageCatalogFlag: true,
      sellButtonFlag: false,
      manageCartFlag: false,

      showSearchFlag: true,
      showpagiFlag: false,
      pagiFilter: null,

      showViewCatalogFlag: false, // Is used for selling

      errMsg: "",
      errFlag: false,

      // Access buttons
      btnSellFlag: false,
      btnCartFlag: false,
      btnCatalogSetupFlag: false,
      catalogItem: null,
      tempCatalogItem: null
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAuthorizedButtons();

    let showErrMsgPanel = false;
    let errMsg = "";

    // if (deviceSize === "small") {
    //   showErrMsgPanel = true;
    //   errMsg =
    //     'A device (not a smart phone) with a bigger display area is needed for the catalog managerial tasks. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).';
    // }

    let btnCatalogSetupFlag = this.state.btnCatalogSetupFlag;
    // let btnSellFlag = this.state.btnSellFlag;
    let btnCartFlag = this.state.btnCartFlag;
    let showSearchFlag = this.state.showSearchFlag;
    let showViewCatalogFlag = this.state.showViewCatalogFlag;
    let sellButtonFlag = this.state.sellButtonFlag;

    if (deviceSize === "small") {
      btnCatalogSetupFlag = false;
      btnCartFlag = false;
      showSearchFlag = false;
      if (this.state.btnSellFlag) {
        showViewCatalogFlag = true;
        sellButtonFlag = true;
      } else {
        showErrMsgPanel = true;
        errMsg =
          'A device (not a smart phone) with a bigger display area is needed for the catalog managerial tasks. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).';
      }
    }

    await this.setState({
      deviceSize,
      showErrMsgPanel,
      errMsg,

      btnCatalogSetupFlag,
      btnCartFlag,
      showSearchFlag,
      showViewCatalogFlag,
      sellButtonFlag,
    });
  };

  // This is to show the info panel
  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.props.authorizedFuncs);
    // console.log("catalogSetupList:", catalogSetupList);
    let btnSellFlag = false;
    let btnCartFlag = false;
    let btnCatalogSetupFlag = false;
    let showSearchFlag = false;

    catalogSalesList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) btnSellFlag = true;
    });
    cartManagementList.forEach((obj2) => {
      if (this.props.authorizedFuncs.includes(obj2)) btnCartFlag = true;
    });
    catalogSetupList.forEach((obj3) => {
      if (this.props.authorizedFuncs.includes(obj3)) {
        btnCatalogSetupFlag = true;
        showSearchFlag = true;
      }
    });

    this.setState({
      btnSellFlag,
      btnCartFlag,
      btnCatalogSetupFlag,
      showSearchFlag,
    });
  };

  handleManage = async (obj) => {
    // console.log("handle Manage obj:", obj);
    // manageCatalogFlag
    if (obj === "sell") {
      await this.setState({
        showViewCatalogFlag: true,
        sellButtonFlag: true,
        showSearchFlag: false,
        showpagiFlag: false,
        showCatalogAdminFlag: false,
        manageCartFlag: false,
        manageCatalogFlag: false,
        showErrMsgPanel: false,
        errMsg: "",
        ErrFlag: false,
      });
    }

    if (obj === "admin") {
      if (this.state.deviceSize === "big") {
        await this.setState({
          showViewCatalogFlag: false,
          showSearchFlag: true,
          showpagiFlag: false,
          showCatalogAdminFlag: false,
          showErrMsgPanel: false,
          manageCartFlag: false,
          manageCatalogFlag: true,
          sellButtonFlag: false,
          errMsg: "",
          ErrFlag: false,
        });
      } else {
        await this.setState({
          showViewCatalogFlag: false,
          showSearchFlag: false,
          showpagiFlag: false,
          showCatalogAdminFlag: false,
          showErrMsgPanel: true,
          errMsg:
            'A device (not a smart phone) with a bigger display area is needed for the catalog managerial tasks. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).',
          ErrFlag: true,
        });
      }
    }

    if (obj === "cart") {
      if (this.state.deviceSize === "big") {
        await this.setState({
          sellButtonFlag: false,
          manageCatalogFlag: false,
          manageCartFlag: true,
          showViewCatalogFlag: false,
          showSearchFlag: false,
          showpagiFlag: false,
          showCatalogAdminFlag: false,
          showErrMsgPanel: false,
          errMsg: "",
          ErrFlag: false,
        });
      } else {
        await this.setState({
          showViewCatalogFlag: false,
          showSearchFlag: false,
          manageCatalogFlag: false,
          showpagiFlag: false,
          showCatalogAdminFlag: false,

          showErrMsgPanel: true,
          errMsg:
            'A device (not a smart phone) with a bigger display area is needed for the catalog managerial tasks. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).',
          ErrFlag: true,
        });
      }
    }
  };

  returnFromCatalogItemFilter = async (filterobj) => {
    // console.log("filterobj:", filterobj);
    await this.setState({
      showSearchFlag: false,
      showpagiFlag: true,
      showCatalogAdminFlag: false,
      pagiFilter: filterobj,
    });
  };

  returnFromPagination = async (catobj) => {
    // console.log("returnFromPagination catobj:", catobj);
    if (catobj.opsType === "exittosearch") {
      await this.setState({
        showSearchFlag: true,
        showpagiFlag: false,
        showCatalogAdminFlag: false,
      });
    }
    if (catobj.opsType === "adminanitem") {
      await this.setState({
        showSearchFlag: false,
        showpagiFlag: false,
        showViewCatalogFlag: false,
        showCatalogAdminFlag: true,
        catalogItem: catobj,
        tempCatalogItem: JSON.parse(JSON.stringify(catobj))
      });
      // console.log('>> this.state.catalogItem:',  this.state.catalogItem);
      // console.log('>> tempCatalogItem:', this.state.tempCatalogItem)
    }
  };

  returnFrommCatalogAdmin = async () => {
    await this.setState({
      showSearchFlag: true,
      showpagiFlag: false,
      showCatalogAdminFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Catalog...");

    // Buttons ===============================================
    let sell, manage, catmgmt, cartmgmt;
    if (this.state.deviceSize === "small") {
      sell = "Sell";
      manage = "Mgm";
      catmgmt = "Catlog Mgmt";
      cartmgmt = "CaM";
    } else {
      sell = "Sell";
      manage = "Manage";
      catmgmt = "Catalog Management";
      cartmgmt = "CartMgmt";
    }
    let catalogbuttons;
    catalogbuttons = (
      <div className="catalog-handling-pos">
        <div className="row">
          <div className="col-6 header_text_style">{catmgmt}</div>
          <div className="col-6 cat-button-pos">
            &nbsp;
            {this.state.btnSellFlag ? (
              <button
                className={
                  this.state.sellButtonFlag ? "btn_reg_60_active" : "btn_reg_60"
                }
                type="button"
                onClick={() => this.handleManage("sell")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === "big" ? (
                  sell
                ) : (
                  <i className="fas fa-people-carry" />
                )}
                {/* {this.state.deviceSize === 'big' ? sell : <i className="fas fa-people-carry" />} */}
              </button>
            ) : null}
            {this.state.btnCatalogSetupFlag ? (
              <button
                className={
                  this.state.manageCatalogFlag
                    ? "btn_reg_70_active"
                    : "btn_reg_70"
                }
                type="button"
                onClick={() => this.handleManage("admin")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {manage}
              </button>
            ) : null}
            &nbsp;
            {this.state.btnCartFlag ? (
              <button
                className={
                  this.state.manageCartFlag ? "btn_reg_80_active" : "btn_reg_80"
                }
                type="button"
                onClick={() => this.handleManage("cart")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {cartmgmt}
              </button>
            ) : null}
            &nbsp;
            <button
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
      </div>
    );

    // console.log('this.state.showCatalogAdminFlag:', this.state.showCatalogAdminFlag);
    let catAdminPanel;
    if (this.state.showCatalogAdminFlag) {
      catAdminPanel = (
        <div>
          <CatalogAdmin
            returnToCaller={this.returnFrommCatalogAdmin}
            communityId={this.props.communityId}
            // catalogItem={this.state.catalogItem}
            catalogItem={this.state.tempCatalogItem}
            community={this.props.community}
            store={this.props.store}
          />
        </div>
      );
    }

    let paginationPanel;
    // console.log('this.state.showpagiFlag:', this.state.showpagiFlag);
    if (this.state.showpagiFlag) {
      paginationPanel = (
        <div>
          <CatalogPaginated
            returnToCaller={this.returnFromPagination}
            pagiFilter={this.state.pagiFilter}
            communityId={this.props.communityId}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            store={this.props.store}
          />
        </div>
      );
    }

    let searchPanel;
    // console.log('this.state.showSearchFlag:', this.state.showSearchFlag);
    if (this.state.showSearchFlag) {
      searchPanel = (
        <div>
          <CatalogItemFilter
            returnToCaller={this.returnFromCatalogItemFilter}
            merchandiseType={this.state.merchandiseType}
            communityId={this.props.communityId}
            authorizedFuncs={this.props.authorizedFuncs}
            role={this.props.role}
            store={this.props.store}
            caller="catalog"
          />
        </div>
      );
    }

    let errorPanel;
    if (this.state.showErrMsgPanel) {
      errorPanel = (
        <div className="catalog-error-box">
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="device-error-msg">
              {this.state.errMsg}
            </p>
          </div>
        </div>
      );
    }

    let viewCatalogToSellPanel;
    // console.log('this.state.showViewCatalogFlag:', this.state.showViewCatalogFlag);
    if (this.state.showViewCatalogFlag) {
      viewCatalogToSellPanel = (
        <div>
          <ViewCatalog
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            goToDashboard={this.goToDashboard}
            role={this.props.role}
            authorization={this.props.authorization}
            authorizedFuncs={this.props.authorizedFuncs}
            cartOf={this.props.auth.user}
            store={this.props.store}
            caller="seller"
          />
        </div>
      );
    }

    let cartManagePanel;
    if (this.state.manageCartFlag) {
      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.store.coopStoreId;
      cartManagePanel = (
        <div>
          <ManageCarts
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
          />
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {catalogbuttons}
        {searchPanel}
        {errorPanel}
        {catAdminPanel}
        {viewCatalogToSellPanel}
        {cartManagePanel}
        {paginationPanel}
      </div>
    );

    return (
      <div className="container">
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Catalog.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Catalog));
