import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ReactLoading from "react-loading";

import "./MergeTargets.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const mergeTargets = "/routes/interact/mergeTargets";

class MergeTargets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: "",
      errFlag: false,

      newTargetName: "",
      selectedTargets: [],
      brdSelectHeight: 1,
      targetListNames: [],

      loadingFlag: false,
    };
  }

  componentDidMount = async() => {
    await this.setState({
        targetListNames: this.props.targetListNames
    })
  }

  exitMergeTargets = () => {
    this.props.returnToCaller();
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFromAddSelect = async () => {
    let value = document.getElementById("brdMrgTargetSelects").value;

    let data = JSON.parse(value);
    // console.log("data:", data);
    let stl = [...this.state.selectedTargets];
    let exists = false;
    this.state.selectedTargets.forEach((tl) => {
      if (tl.name === data.targetListName) exists = true;
    });

    if (!exists) {
      let tlnameObj = {
        name: data.targetListName,
      };
      stl.push(tlnameObj);
      this.setState({
        selectedTargets: stl,
      });
    }

    this.setState({
      brdSelectHeight: 1,
    });
  };

  onFocusTgName = async () => {
    this.setState({
      brdSelectHeight: 4,
    });
  };

  onBlurTgName = async () => {
    this.setState({
      brdSelectHeight: 1,
    });
  };

  deleteThis = (obj) => {
    let currst = [];
    this.state.selectedTargets.forEach((st) => {
      if (st.name !== obj.name) currst.push(st);
    });

    this.setState({
      selectedTargets: currst,
    });
  };

  validate = () => {
    let msg = "";
    let status = "success";
    this.props.targetListNames.forEach((ctl) => {
      if (this.state.newTargetName === ctl.targetListName) {
        status = "error";
        msg = "New target name must be unique.";
      }
    });

    if ( this.state.newTargetName === 'customer') {
      status = "error";
      msg = "New target cannot be customer (reserved word). "
    }

    return { status, Msg: msg };
  };

  mergeUpdateTargets = async () => {
    let val = this.validate();
    if (val.status === "success") {
      this.setState({
        loadingFlag: true,
      });
      let input = {
        communityId: this.props.communityId,
        currTargets: this.state.selectedTargets,
        newTargetName: this.state.newTargetName,
      };
      let url = baandaServer + mergeTargets;
      // console.log("url: ", url, " input:", input);
      let mret = await axios.post(url, input);
      // console.log('mret.data:', mret.data);
      if (mret.data.status === "success") {
        let tln = [...this.state.targetListNames];
        let newtnobj = {
            targetListName: this.state.newTargetName
        }
        tln.push(newtnobj);
        this.setState({
          errMsg: "Merged successfully",
          errFlag: false,
          loadingFlag: false,
          targetListNames: tln,
          selectedTargets: [],
          newTargetName: ''
        });
      } else {
        this.setState({
          errMsg: mret.data.Msg,
          errFlag: true,
          loadingFlag: false
        });
      }
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
        loadingFlag: false
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('MergeTargets...');

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <ReactLoading
          type={"spokes"}
          color={"#237547"}
          height={25}
          width={25}
          className="comm-entry-spinner"
        />
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitMergeTargets}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let mergeButton;
    if (
      this.state.newTargetName !== "" &&
      this.state.selectedTargets.length >= 2
    ) {
      mergeButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.mergeUpdateTargets}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Merge
        </button>
      );
    }

    let targetNamesPanel;

    if (this.state.targetListNames.length > 1) {
        console.log("this.props.targetListNames:", this.props.targetListNames);
      let targetnamelist = this.state.targetListNames.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.targetListName}&nbsp;
          </option>
        );
      });

      targetNamesPanel = (
        <div className="ship-addr-from-pos">
          <div className="row ship-addr-dropdown">
            <div className="col text-center ">
              <select
                size={this.state.brdSelectHeight}
                id="brdMrgTargetSelects"
                name="brdMrgTargetSelects"
                onChange={this.handleFromAddSelect}
                className="brd-mgmt-target-list"
                onFocus={this.onFocusTgName}
                onBlur={this.onBlurTgName}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select current targets
                </option>
                {targetnamelist}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let targetViewPanel;
    targetViewPanel = (
      <div>
        <div className="row merge-tgt-sel-drop-pos">
          <div className="col-4 text-center">&nbsp;</div>
          <div className="col-4 text-left">{targetNamesPanel}</div>
          <div className="col-4 text-center">&nbsp;</div>
        </div>
      </div>
    );

    let bool = true;
    let listTargetPanel;
    if (this.state.selectedTargets.length > 0) {
      listTargetPanel = (
        <div className="text-center">
          <div className="brd-trg-merge-box">
            <div>
              {this.state.selectedTargets.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={`${
                        bool ? "row-merge-list-dr" : "row-merge-list-lt"
                      }`}
                    >
                      <div className="row">
                        <div className="col-8 text-left broadcast-filt-name-lbl">
                          {obj.name}
                        </div>

                        <div className="col-4 text-center">
                          <button
                            className="btn_exit_del_30"
                            type="button"
                            onClick={() => this.deleteThis(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fas fa-trash" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else {
      if ( this.state.targetListNames.length === 1) {
        listTargetPanel = (
          <div className="text-center">
            <div className="brd-trg-merge-box">
              <div className="text-center">You have only one targetList ({this.state.targetListNames[0].targetListName}). Nothing to merge to.</div>
            </div>
          </div>
        );
      } else {
        listTargetPanel = (
          <div className="text-center">
            <div className="brd-trg-merge-box">
              <div className="text-center">Please select targets to merge</div>
            </div>
          </div>
        );
      }
    
    }

    let newTargetNamePanel;
    newTargetNamePanel = (
      <input
        name="newTargetName"
        type="text"
        value={this.state.newTargetName}
        onChange={this.onChange}
        size="25"
        maxLength="25"
        className="merge-tgt-new-name"
        placeholder="Name in 25"
      />
    );

    let outputPanel;
    outputPanel = (
      <div className="">
        <div className="row brd-merge-top-pos">
          <div className="col-8 test-right ">
            <b>New Target:</b>&nbsp;{newTargetNamePanel}
          </div>
          <div className="text-left">
            {backButton} &nbsp; {mergeButton}
          </div>
        </div>
        {targetViewPanel}
        {listTargetPanel}
        {this.state.loadingFlag ? (
          <div className="row">
            <div className="col-6 text-right">Processing ... please wait</div>
            <div className="col-6 text-left">{uploadingSpin}</div>
          </div>
        ) : null}
        <div
          className={
            this.state.errFlag
              ? "text-center brd-tg-view-msg-err"
              : "text-center brd-tg-view-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

MergeTargets.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(mapStateToProps, null)(withRouter(MergeTargets));
