import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";
import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

// import { emailValidation } from "../../../utils/emailValidation";
import { emailValidation } from "../../../../../utils/emailValidation";

import "./IndividualFinalUpdate.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateStripeIndividual = "/routes/stripe/updateStripeIndividual";
const checkAddressValidity = "/routes/shared/checkAddressValidity?";

const readMeCode = "1151180000";

class IndividualFinalUpdate extends Component {
  constructor(props) {
    super(props);

    this.state = {

      pfirst_name: "",
      plast_name: "",
      pday: 0,
      pmonth: 0,
      pyear: 0,
      prdir: "no",
      prexec: "no",
      prowner: "no",
      prpercent: 0,
      prtitle: "",
      prssnlast4: "",

      deviceSize: "",
      loadingFlag: false,

      updateBeginFlag: false,
      updateDoneFlag: false,

      errBizMsg: "",
      errIndMsg: "",
      errEAMsg: "",
      errPersonMsg: "",
      errIdMsg: "",

      errBizFlag: false,
      errIndFlag: false,
      errEAFlag: false,
      errPersonFlag: false,
      errIdFlag: false,

      errFlg: false,

      ccdataForUpdate: {},
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleBackFromFinal = async () => {
    this.props.handleBack();
  };

  handleCancel = async () => {
    this.props.handleCancel();
  };

  handleDirector = async (e) => {
    await this.setState({
      prdir: e.target.value,
    });
  };

  handleExecutive = async (e) => {
    await this.setState({
      prexec: e.target.value,
    });
  };

  handleOwner = async (e) => {
    await this.setState({
      prowner: e.target.value,
    });
  };

  repackageCCdata = async () => {
    let updtccdata = "nothing";
    let ccd = this.props.ccdata;
    // console.log("ccd:", ccd);

    updtccdata = {
      stripeAccId: ccd.stripeAccId,
      businessType: ccd.businessType,
      business_profile: ccd.business_profile,
      company: ccd.company,
      external_account: ccd.external_account,
      businessProfileSkip: ccd.businessProfileSkip,
      individualSkip:  ccd.individualSkip,
      externalAccountSkip: ccd.externalAccountSkip,
      IdpictureSkip: ccd.IdpictureSkip,
      communityId: ccd.communityId,
      verification: ccd.id_files.individual,
    };



    return updtccdata;
  };

  handlefinalUpdate = async () => {
    // console.log("handlefinalUpdate");
    await this.setState({
      updateBeginFlag: true,
      loadingFlag: true,
    });
    let isValid = true;
    isValid = await this.validateCCData();
    // console.log(">>>>>>>>>>>> isValid:", isValid);

    if (isValid) {
      let ccdataForUpdate;
      if (!this.props.ccdata.IdpictureSkip) {
        ccdataForUpdate = await this.repackageCCdata();
        // console.log("going to update id ccdataForUpdate:");
      } else ccdataForUpdate = this.props.ccdata;

      try {
        let url = baandaServer + updateStripeIndividual;
        // console.log(">>>>>>>> url:", url, "  ccdata:", ccdataForUpdate);
        let ret = await axios.post(url, ccdataForUpdate);
        // console.log("ret:", ret);
        await this.processOutputError(ret.data);
      } catch (err) {
        console.log("finalupdate err:", err.message);
      }
    }

    await this.setState({
      updateBeginFlag: true,
      updateDoneFlag: true,
      loadingFlag: false,
    });
  };

  processOutputError = async (out) => {
    let bflg = false,
      cflg = false,
      eaflg = false,
      pflg = false,
      iflg = false,
      erflg = false;
    let bmsg = "",
      cmsg = "",
      eamsg = "",
      pmsg = "",
      imsg = "";
    if (out.bret && out.bret.status === "error") {
      bflg = true;
      erflg = true;
      bmsg = out.bret.msg;
    }
    if (out.cret && out.cret.status === "error") {
      cflg = true;
      erflg = true;
      cmsg = out.cret.msg;
    }
    if (out.earet && out.earet.status === "error") {
      eaflg = true;
      erflg = true;
      eamsg = out.earet.msg;
    }
    if (out.pret && out.pret.status === "error") {
      pflg = true;
      erflg = true;
      pmsg = out.pret.msg;
    }
    if (out.idret && out.idret.status === "error") {
      iflg = true;
      erflg = true;
      imsg = out.iret.msg;
    }

    await this.setState({
      errBizMsg: bmsg,
      errIndMsg: cmsg,
      errEAMsg: eamsg,
      errPersonMsg: pmsg,
      errIdMsg: imsg,
      errBizFlag: bflg,
      errIndFlag: cflg,
      errEAFlag: eaflg,
      errPersonFlag: pflg,
      errIdFlag: iflg,
      errFlg: erflg,
    });
  };

  handleClose = async () => {
    this.props.handleCancel();
  };

  validateCCData = async () => {
    // console.log('>>>>>>>>>>> validateCCData ccdata:', this.props.ccdata);
    let isValid = true;
    let bizvalid;

    if (!this.props.ccdata.businessProfileSkip) {
      // console.log('Will validate businessProfile')
      bizvalid = await this.doBizProfileValidation();
      if (!bizvalid.status) {
        isValid = false;
        await this.setState({
          errBizMsg: bizvalid.msg,
          errBizFlag: true,
          errFlg: true, // Got errors
        });
      }
    }

    let indValid;
    if (!this.props.ccdata.individualSkip) {
      // console.log('Will validate IndividualValidation...Person')
      indValid = await this.doIndividualValidation();
      if (!indValid.status) {
        isValid = false;
        await this.setState({
          errIndMsg: indValid.msg,
          errIndFlag: true,
          errFlg: true,
        });
      }
    }

    let eaValid;
    if (!this.props.ccdata.externalAccountSkip) {
      // console.log('Will validate external account (bank)')
      eaValid = await this.doEAValidation();
      if (!eaValid.status) {
        isValid = false;
        await this.setState({
          errEAMsg: eaValid.msg,
          errEAFlag: true,
          errFlg: true,
        });
      }
    }    

    let idValid;
    if (!this.props.ccdata.IdpictureSkip) {
      idValid = await this.doIdValidation();
      // console.log("idValid:", idValid, "  idValid.status:", idValid.status);
      if (!idValid.status) {
        isValid = false;
        await this.setState({
          errIdMsg: idValid.msg,
          errIdFlag: true,
          errFlg: true,
        });
      }
    }

    return isValid;
  };

  doBizProfileValidation = async () => {
    let bvalid = true;
    let bd = this.props.ccdata.business_profile;
    let bmsg = "";

    if (!bd.mcc) {
      bvalid = false;
      bmsg = "MCC (Merchant Category Code) is missing. ";
    } else if (bd.mcc < 1000 && bd.mcc > 9999) {
      bvalid = false;
      bmsg = 'Enter 4 digits valid MCC. Check info "i" for direction. ';
    }

    if (!bd.name) {
      bvalid = false;
      bmsg = bmsg + "Business name is mandatory. ";
    }

    // if (!bd.url) {
    //   bvalid = false;
    //   bmsg = bmsg + "Business URL is mandatory. ";
    // }

    if (bmsg !== "") {
      bmsg = "Error (Business Profile) : " + bmsg;
    }

    return { status: bvalid, msg: bmsg };
  };

  doEAValidation = async () => {
    let eaValid = true;
    let emsg = "";
    let ea = this.props.ccdata.external_account;
    if (!ea.account_holder_name || ea.account_holder_name === "") {
      eaValid = false;
      emsg = "Account holder name is required. ";
    }
    if (!ea.bank_name || ea.bank_name === "") {
      eaValid = false;
      emsg = emsg + "Bank name is required. ";
    }
    if (!ea.routing_number || ea.routing_number === "") {
      eaValid = false;
      emsg = emsg + "Routing number is required. ";
    }
    if (!ea.account_number || ea.account_number === "") {
      eaValid = false;
      emsg = emsg + "Account number is required. ";
    }

    if (emsg !== "") {
      emsg = "Error (Bank Info.) : " + emsg;
    }

    return { status: eaValid, msg: emsg };
  };

  doIndividualValidation = async () => {
    let indValid = true;
    let imsg = "";
    let ind = this.props.ccdata.individual;
    // console.log("doPersonValidation ind:", ind);

    if (!ind.first_name || ind.first_name === "") {
      indValid = false;
      imsg = "First Name is required. ";
    }
    if (!ind.last_name || ind.last_name === "") {
      indValid = false;
      imsg = imsg + "Last Name is required. ";
    }

    // console.log('ind.id_number:', ind.id_number);
    if (ind.id_number.length !== 9) {
      indValid = false;
      imsg = imsg + "Invalid SSN. ";
    } else {
      let last = parseFloat(ind.id_number);
      if (isNaN(last)) {
        // Checks if value enterd is NaN
        indValid = false;
        imsg = imsg + "Invalid SSN (must be numeric). ";
      }
    }

    if (ind.address.line1 !== "address_full_match") {
      if (
        ind.address.line1 === "" ||
        ind.address.city === "" ||
        ind.address.state === "" ||
        ind.address.postal_code === ""
      ) {
        imsg = imsg + "A valid address is required. ";
        indValid = false;
      } else {
        let params =
          "street=" +
          ind.address.line1 +
          "&city=" +
          ind.address.city +
          "&state=" +
          ind.address.state;
        let url = baandaServer + checkAddressValidity + params;
        // console.log("here 5 url:", url);
        try {
          let res = await axios.get(url);
          // console.log("here 6 address res:", res);
          if (res.data.status === "success" && res.data.Msg === "invalid") {
            indValid = false;
            imsg = imsg + "Invalid Person Address. ";
          }
        } catch (err) {
          console.log("person addr validation err:", err.message);
        }
      }
    }

    if (ind.email === "") {
      indValid = false;
      imsg = imsg + "A valid email is required. ";
    } else {
      let emv = emailValidation(ind.email);
      if (!emv) {
        indValid = false;
        imsg = imsg + "Invalid email format. ";
      }
    }

    return { status: indValid, msg: imsg };
  };

  doIdValidation = async () => {
    let indid = this.props.ccdata.id_files;
    
    let msg = '', isValid=true;
 
    if ( indid ) {
      if ( indid.individual && indid.individual.faceup ) {
        if ( indid.individual.faceup.verificationFieldId === '') {
          isValid = false;
          msg += "Please upload Id (faceup) image; "
        }
      }
      if ( indid.individual && indid.individual.facedown ) {
        if ( indid.individual.facedown.verificationFieldId === '') {
          isValid = false;
          msg += "Please upload Id (facedown) image; "
        }
      }
    } else {
      msg += "You have not uploaded identity image-files to be verified. "
    }

    return { status: isValid, msg: msg };
  };

  // For checking to see if a year is leapyear for validation.
  quater = async (no) => {
    return no % 4;
  };

  render() {
    // console.log("Final this.state:", this.state);
    // console.log("Final this.props:", this.props);
    console.log('IndividualFinalUpdate...');

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    let commButtonCompany = (
      <div>
        <button onClick={this.handleBackFromFinal} className="btn_reg_60">
          Back
        </button>
        &nbsp;
        <button onClick={this.handleCancel} className="btn_reg_70">
          Cancel
        </button>
        &nbsp;
        {!this.props.noUpdateFlag ? (
          <button onClick={this.handlefinalUpdate} className="btn_reg_70">
            Update
          </button>
        ) : null}
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let inpName;
    if (this.state.deviceSize === "small") inpName = "Individual";
    else inpName = "Type: Individual";

    let headerPanel;
    if (!this.state.updateBeginFlag || this.state.errFlg) {
      headerPanel = (
        <div className="row">
          <div className="col-4 header_comp_text">{inpName}</div>
          <div className="col-8 ">{commButtonCompany}</div>
        </div>
      );
    }

    let successPanel;
    if (!this.state.errFlg) {
      successPanel = (
        <div className="success_msg">
          <p align="justify">
            Successfully updated. Click 'Finish' to complete.
          </p>
          <button onClick={this.handleClose} className="btn_reg_60">
            Next
          </button>
        </div>
      );
    }

    let errorPanel;
    if (this.state.errFlg) {
      errorPanel = (
        <div>
        <div>
          <div className="err_msg_header text-center">
            <u>Errors</u>
          </div>
          {this.state.errBizFlag ? (
            <p align="justify" className="err_msg">
              <b>Business Profile:&nbsp;</b>
              {this.state.errBizMsg}
            </p>
          ) : null}
          {this.state.errIndFlag ? (
            <p align="justify" className="err_msg">
              <b>Company:&nbsp;</b>
              {this.state.errIndMsg}
            </p>
          ) : null}
          {this.state.errEAFlag ? (
            <p align="justify" className="err_msg">
              <b>Banking:&nbsp;</b>
              {this.state.errEAMsg}
            </p>
          ) : null}
          {this.state.errPersonFlag ? (
            <p align="justify" className="err_msg">
              <b>Person</b>:&nbsp;
              {this.state.errPersonMsg}
            </p>
          ) : null}
          {this.state.errIdFlag ? (
            <p align="justify" className="err_msg">
              <b>ID Card:&nbsp;</b>
              {this.state.errIdMsg}
            </p>
          ) : null}
        </div>
        <div className="space-below-errors" />
        </div>
      );
    }

    let finalPanel;
    if (!this.state.updateBeginFlag) {
      if (!this.props.noUpdateFlag) {
        finalPanel = (
          <div>
            <div className="up-height" />
            <div className="row">
              <div className="col final_msg text-right">
                <p align="justify">
                  When you click 'Update' here, all the data you have entered
                  will be validated to minimum requirements; then verified
                  extensively by the CC payment processing providers.
                </p>
                <p align="justify">
                  {" "}
                  If you checked ‘Skip for now’ in any section then that section
                  will be ignored.
                </p>
                <p align="justify">
                  You will be directed to the section if there are error(s).
                  Click ‘Cancel’ to abandon the work and ‘Back’ to review and
                  fix data in any section.
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        finalPanel = (
          <div>
            <div className="up-height" />
            <div className="row">
              <div className="col final_msg">
                <font color="red">
                  <p>
                    You have skipped all. Nothing to update.  
                  </p>
                </font>
              </div>
            </div>
          </div>
        );
      }
    } else if (!this.state.updateDoneFlag) {
      finalPanel = (
        <div>
          <div className="text-center spin-position" />
          <h6>Processing ... Please do not switch. </h6>
          <div className="row">
            <div className="col-5">&nbsp;</div>
            <div className="col-2">{uploadingSpin}</div>
            <div className="col-5">&nbsp;</div>
          </div>
        </div>
      );
    } else {
      finalPanel = <div>{this.state.errFlg ? errorPanel : successPanel}</div>;
    }

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="text-center">{finalPanel}</div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

IndividualFinalUpdate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IndividualFinalUpdate));