import React, { Component } from "react";

import { emailValidation } from "../../../../utils/emailValidation";

import "./PayCtrlFilter.css";

class PayCtrlFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payCtrlType: "all",
      custemail: "",
      errMsg: "Click Fetch to search with the filter.",
      errFlag: false,
    };
  }

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errMsg: "Click Fetch to search with the filter.",
      errFlag: false,
    });
  };

  returnToPayCtrlMain = () => {
    let isValid = true;
    if (this.state.payCtrlType === "email") {
      if (this.state.custemail === "") {
        this.setState({
          errMsg: "Must provide a valid email to search.",
          errFlag: true,
        });
        isValid = false;
      } else {
        let emailval = emailValidation(this.state.custemail);
        if (!emailval) {
          this.setState({
            errMsg: "Invalid email syntax.",
            errFlag: true,
          });
          isValid = false;
        }
      }
    }
    if (isValid) {
      let filter = {
        payCtrlType: this.state.payCtrlType,
        custemail: this.state.custemail,
      };
      // console.log('filter:', filter);
      this.props.returnToCaller(filter);
    }
  };

  handlePayCtrlType = async (e) => {
    this.setState({
      payCtrlType: e.target.value,
      errMsg: "Click Fetch to search with the filter.",
      errFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('PayCtrlFilter...');

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToPayCtrlMain}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let fetchButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.returnToPayCtrlMain}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Fetch <i className="fa fa-search" />
      </button>
    );

    let selectOptionsPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Filter by&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.payCtrlType === "all"}
                onChange={this.handlePayCtrlType}
              />{" "}
              All
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="active"
                checked={this.state.payCtrlType === "active"}
                onChange={this.handlePayCtrlType}
              />{" "}
              Active
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="paused"
                checked={this.state.payCtrlType === "paused"}
                onChange={this.handlePayCtrlType}
              />{" "}
              Paused
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="canceled"
                checked={this.state.payCtrlType === "canceled"}
                onChange={this.handlePayCtrlType}
              />{" "}
              Canceled
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="error"
                checked={this.state.payCtrlType === "error"}
                onChange={this.handlePayCtrlType}
              />{" "}
              Error
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="email"
                checked={this.state.payCtrlType === "email"}
                onChange={this.handlePayCtrlType}
              />{" "}
              Customer Email
            </label>
          </div>
        </div>
      </div>
    );

    let emailPanel;
    if (this.state.payCtrlType === "email") {
      emailPanel = (
        <div className="row pay-ctrl-filter-email-pos">
          <div className="col-3 text-right pay-ctrl-filter-email-lbl">
            Email
          </div>
          <div className="col-9 text-left ">
            <input
              name="custemail"
              type="text"
              value={this.state.custemail}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="pay-ctrl-filter-email"
              placeholder="Customer of interest's email..."
            />
          </div>
        </div>
      );
    }

    let outputPanel = (
      <div className="payment-ctrl-filter-box">
        <div className="text-center pay-ctrl-filter-header">
          Payment Ctrl Filter {exitButton} {fetchButton}
        </div>
        {selectOptionsPanel}
        {emailPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center pay-ctrl-filter-msg-err"
              : "text-center pay-ctrl-filter-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default PayCtrlFilter;
