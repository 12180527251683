import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";
import ReferenceHub from "./references/ReferenceHub";

// import DayPicker, { DateUtils } from "react-day-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./Expense.css";

const picTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const maxPicSize = 5000000; // 5MB
const maxPdfSize = 10000000; // 10MB
const pdfTypes = ["pdf"];

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBroadCategories = "/routes/finance/getBroadCategories?";
const getExpenseBudget = "/routes/finance/getExpenseBudget?";
const saveExpense = "/routes/dashboard/saveExpense";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111230000";

class Expense extends Component {
  constructor(props) {
    super(props);

    this.state = {
      broadCategory: [],
      categoryBudget: null,
      selectedCategoryOf: "",

      recName: "",
      recEmail: "",
      recCell: "",

      selheightbc: 1,
      selectedBroadCategory: null,
      selectedPreptask: null,
      showSubCatFlag: false,

      selectedSubCategory: "",
      showPayrollTypeFlag: false,
      showAmountInputFlag: false,
      selheightsub: 1,
      selheightad: 1,

      workerType: "contractor",
      paymentType: "fixed",
      calculatedAmt: 0,
      expAmount: 0,
      percent: 0,
      companyContribution: false,
      companyContributionAmount: 0,
      markAsDeductable: false,
      expLineItem: "",

      spentAmtList: [],
      receiptUploadType: "none",

      expenseDate: new Date(),
      expenseNote: "",
      paymentMedium: "cash",
      showPayInfoFlag: false,
      showReferenceFlag: false,
      payDescription: "",

      subDirName: "",
      // picS3FileData: { key: "", type: "", location: "" },
      picS3FileData: null,
      pdfS3FileData: null,
      fileUploads: [],

      showMainExpPanelFlag: true,
      attachPdfFlag: false,
      attachPicFlag: false,
      adjustOfDASize: 0,
      image: null,
      community: null,
      confirmExpenseData: false,

      bankName: "",
      accountNo: "",
      wiredToBankName: "",
      wiredToAccountNo: "",
      hrPaymentType: null,
      selectedEvent: null,

      targetServer: null,

      spentAmount: 0,
      seqno: 1,
      itemwo: null,
    };
  }

  componentDidMount = async () => {
    // await this.getCommunityInfo();
    await this.getExpenseCategoryOfs();
    await this.getCommunityInfo();

    let bankName = "";
    let accountNo = "";
    if (this.state.community && this.state.community.bankName)
      bankName = this.state.community.bankName;
    if (this.state.community && this.state.community.accountNo)
      accountNo = this.state.community.accountNo;
    await this.setState({
      bankName,
      accountNo,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getCommunityInfo = async () => {
    // console.log('in getCommunityInfo ...');

    let parms = "communityId=" + this.props.community.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      // console.log("CheckoutCart retData: ", retData);
      this.setState({
        community: retData.data.Msg,
      });
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
      errFlag: false,
    });
  };

  handleWorkerType = async (e) => {
    this.setState({
      workerType: e.target.value,
    });
  };

  handlePaymentMedium = async (e) => {
    this.setState({
      paymentMedium: e.target.value,
    });
  };

  receiptUploadType = async (e) => {
    this.setState({
      receiptUploadType: e.target.value,
    });
  };

  handlePaymentType = async (e) => {
    this.setState({
      paymentType: e.target.value,
    });
  };

  getExpenseCategoryOfs = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&classification=expense";
    let url = baandaServer + getBroadCategories + params;
    // console.log("getExpenseCategoryOfs url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("catret:", catret);
      if (catret.data.status === "success") {
        this.setState({
          // message: "",
          errFlag: false,
          broadCategory: catret.data.Msg,
        });
      } else {
        this.setState({
          message: catret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getExpenseCategoryOfs err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getExpenseBudget = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&classification=expense" +
      "&categoryOf=" +
      this.state.selectedCategoryOf;
    let url = baandaServer + getExpenseBudget + params;

    try {
      let bgtret = await axios(url);

      if (bgtret.data.status === "success") {
        let message = "";
        if (bgtret.data.Msg[0] === undefined) {
          message = "Must allocate some budget to spend. Return to Budget.";
          throw new Error(message);
        }
        this.setState({
          message: "",
          errFlag: false,
          categoryBudget: bgtret.data.Msg,
          spentAmount: bgtret.data.Msg[0].spentAmount,
        });
      } else {
        this.setState({
          message: bgtret.data.Msg,
          errFlag: true,
          categoryBudget: null,
        });
      }
    } catch (err) {
      console.log("getExpenseBudget err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
        categoryBudget: null,
      });
    }
  };

  onfocusbc = async () => {
    this.setState({
      selheightbc: 5,
    });
  };

  onblurbc = async () => {
    this.setState({
      selheightbc: 1,
    });
  };

  onfocussub = async () => {
    this.setState({
      selheightsub: 4,
    });
  };

  onblursub = async () => {
    this.setState({
      selheightsub: 1,
    });
  };

  handleBroadCategorySelect = async () => {
    let value = document.getElementById("expenseBroadCategory").value;
    // console.log("handleBroadCategorySelect value:", value);
    let data = JSON.parse(value);
    // console.log("data:", data);
    let showPayrollTypeFlag = false;
    let referenceType = "";
    if (data.categoryOf === "Professional Service Fee") {
      showPayrollTypeFlag = true;
      referenceType = "Payroll";
    }
    if (data.categoryOf === "Event") {
      referenceType = "Event";
    }
    if (data.categoryOf === "Writeoff") {
      referenceType = "Writeoff";
    }

    // console.log('data.categoryOf:', data.categoryOf);

    await this.setState({
      selectedBroadCategory: data,
      selectedCategoryOf: data.categoryOf,
      showSubCatFlag: true,
      showPayrollTypeFlag,
      spentAmtList: [],
      workerType: "contractor",
      paymentType: "fixed",
      calculatedAmt: 0,
      expAmount: 0,
      percent: 0,
      expLineItem: "",
      selectedSubCategory: "",
      selheightbc: 1,
      referenceType,
    });

    await this.getExpenseBudget();
    document.getElementById("expenseSubCategory").value = "DEFAULT";
  };

  handleSubCategorySelect = async () => {
    let value = document.getElementById("expenseSubCategory").value;
    // console.log("handleSubCategorySelect value:", value);
    let datasub = JSON.parse(value);
    // console.log("valobj:", datasub);
    this.setState({
      selectedSubCategory: datasub.label,
      showAmountInputFlag: true,
    });
  };

  onChangeExpAmount = async (e) => {
    let value = e.target.value;
    // let name = [e.target.name];
    // console.log("name:", name[0]);
    // await this.setState({ [e.target.name]: e.target.value });

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
      });
    } else {
      this.setState({
        [e.target.name]: value,
      });
    }

    if (this.state.paymentType === "percent") this.calculatePercentAmt();
  };

  calculatePercentAmt = async () => {
    let calcAmt =
      parseFloat(this.state.expAmount) * (parseFloat(this.state.percent) / 100);
    this.setState({
      calculatedAmt: calcAmt,
    });
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  // let showAddButton = true;
  // if ( this.state.expLineItem === '') showAddButton = false;
  // if ( this.state.amount === 0 && !this.state.companyContribution) showAddButton = false;
  // if ( this.state.companyContribution && parseFloant(this.state.companyContributionAmount) === 0) showAddButton = false;

  validateAdd = async () => {
    let msg = "";
    let errFlag = "success";
    if (this.state.selectedSubCategory === "") {
      msg = "Must select subcategory to expense. |";
      errFlag = "error";
    }

    if (this.state.expLineItem === "") {
      msg += " Item description is must and unique. |";
      errFlag = "error";
    }

    if (this.state.selectedCategoryOf === "Professional Service Fee") {
      if (this.state.expAmount === 0 && !this.state.companyContribution) {
        msg +=
          " You can keep amount to zero iff company contribution is checked. |";
        errFlag = "error";
      }
      if (
        this.state.companyContribution &&
        this.state.companyContributionAmount === 0
      ) {
        msg +=
          " Must state contribution amount for checked company contribution. |";
        errFlag = "error";
      }
    } else {
      if (this.state.expAmount === 0) {
        msg += " Amount cannot be zero. |";
        errFlag = "error";
      }
    }

    let noteExists = false;
    this.state.spentAmtList.forEach((obj) => {
      if (obj.expLineItem === this.state.expLineItem) noteExists = true;
    });

    if (noteExists) {
      msg += " Expense note must exist. |";
      errFlag = "error";
    }

    return { status: errFlag, Msg: msg };
  };

  addThisExpense = async () => {
    // console.log("addThisExpense ...");
    let spentAmt = 0;
    let seq = this.state.seqno;

    let isValid = await this.validateAdd();
    if (isValid.status === "success") {
      if (this.state.paymentType === "percent") {
        spentAmt =
          parseFloat(this.state.expAmount) *
          (parseFloat(this.state.percent) / 100);
      } else {
        spentAmt = parseFloat(this.state.expAmount);
      }

      let newSpentAmt = [...this.state.spentAmtList];
      let newSpentObj = {
        seqno: seq,
        subCategory: this.state.selectedSubCategory,
        spentAmt,
        expLineItem: this.state.expLineItem,
        paymentType: this.state.paymentType,
        companyMustPay: this.state.companyContribution,
        companyPayAmount: parseFloat(this.state.companyContributionAmount),
        markAsDeductable: this.state.markAsDeductable,
      };

      let x = this.state.spentAmount + spentAmt;

      newSpentAmt.push(newSpentObj);
      this.setState({
        seqno: seq + 1,
        spentAmtList: newSpentAmt,
        spentAmount: x,
        expAmount: 0,
        percent: 0,
        paymentType: "fixed",
        expLineItem: "",
        message: "",
        errFlag: false,
        companyContribution: false,
        companyContributionAmount: 0,
        markAsDeductable: false,
      });
    } else {
      this.setState({
        message: isValid.Msg,
        errFlag: true,
      });
    }

    // document.getElementById("expenseSubCategory").value = "DEFAULT";
  };

  selectThisItemToDelete = async (obj) => {
    // console.log("selectThisItemToDelete obj:", obj);
    // console.log('this.state.spentAmount:', this.state.spentAmount)
    let spentList = [];
    let seq = 1;
    this.state.spentAmtList.forEach((elm) => {
      if (elm.seqno !== obj.seqno) spentList.push(elm);
      else seq++;
    });

    let y = parseFloat(this.state.spentAmount) - parseFloat(obj.spentAmt);
    // console.log('y:', y)

    this.setState({
      spentAmtList: spentList,
      spentAmount: y,
      seqno: seq,
    });
  };

  handleExpenseConfirm = async () => {
    this.setState((prevstate) => ({
      confirmExpenseData: !prevstate.confirmExpenseData,
    }));
  };

  handleCompanyContribution = async () => {
    this.setState((prevstate) => ({
      companyContribution: !prevstate.companyContribution,
    }));
  };

  handleMarkAsDeductable = async () => {
    this.setState((prevstate) => ({
      markAsDeductable: !prevstate.markAsDeductable,
    }));
  };

  handleExpenseDate = async (date) => {
    // console.log("1 date:", date);
    this.setState({
      expenseDate: date,
    });
  };

  subItemSubDirectory = async () => {
    // console.log("in subItemSubDirectory ...");
    let commrn, subBucket;
    // let randomct, commrn, subBucket;
    if (this.state.subDirName === "") {
      // randomct = this.randfn(100000000, 999999999);
      // commrn = randomct.toString(16);
      commrn = "expense";
      subBucket = "cid-" + this.props.communityId + commrn;
    } else subBucket = this.state.subDirName;

    // console.log("subSucket:", subBucket);
    this.setState({
      subDirName: subBucket,
    });
  };

  validateEmailSyntax = async (email) => {
    // console.log('email:', email, ' typeof email:', typeof email);
    if (email !== "" && typeof email === "string") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // return re.test(email.toString.toLowercase());
      return re.test(email);
    } else return false;
  };

  validateInput = async () => {
    let Msg = "",
      status = "success";
    let errFlag = false;
    if (this.state.recName === "") {
      Msg = "Must have a Recipient Name.";
      status = "error";
      errFlag = true;
    }
    if (this.state.recEmail === "") {
      Msg += " Email is a must.";
      status = "error";
      errFlag = true;
    } else {
      let vemail = await this.validateEmailSyntax(this.state.recEmail);
      if (!vemail) {
        Msg += " Invalid email syntax.";
        status = "error";
        errFlag = true;
      }
    }

    if (this.state.spentAmtList.length === 0) {
      Msg += " Must have at least one expense line.";
      status = "error";
      errFlag = true;
    }

    if (
      this.state.selectedCategoryOf.toLowerCase() === "professional service fee"
    ) {
      if (!this.state.targetServer) {
        Msg +=
          " For professional service fee/payment, a Reference must be created.";
        errFlag = true;
        status = "error";
      }
    }

    if (
      this.state.selectedCategoryOf === "Event" &&
      this.state.selectedSubCategory === "Tasks"
    ) {
      if (!this.state.selectedEvent || !this.state.selectedPreptask) {
        Msg += " For event's task, Event and prep task must be referenced..";
        errFlag = true;
        status = "error";
      }
    }

    if (this.state.referenceType.toLowerCase() === "payroll") {
      if (!this.state.targetServer) {
        Msg += " Must select a target member for professional service payment.";
        errFlag = true;
      } else {
        if (this.state.recEmail !== this.state.targetServer.email) {
          Msg +=
            " For professional service payment, Recipient and Target emails must be same.";
          errFlag = true;
          status = "error";
        }
      }
    }

    if (this.state.referenceType.toLowerCase() === "writeoff") {
      if (this.state.spentAmtList.length > 1) {
        Msg +=
          " For writeoff, you can reference on one item + sub-item only at a time. ";
        errFlag = true;
        status = "error";
      }
    }

    this.setState({
      message: Msg,
      errFlag,
    });

    return { status, Msg };
  };

  createInput = async () => {
    let totalAmt = 0;
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let hasDeductible = false;
    this.state.spentAmtList.forEach((obj) => {
      if (obj.markAsDeductable) {
        totalAmt -= parseFloat(obj.spentAmt);
        hasDeductible = true;
      } else totalAmt += parseFloat(obj.spentAmt);
    });

    // console.log("this.state.fileUploads:", this.state.fileUploads);
    // console.log("this.state.adjustOfDASize:", this.state.adjustOfDASize);

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      recipiantName: this.state.recName,
      recipiantEmail: this.state.recEmail,
      recipiantCell: this.state.recCell,
      expenseCategoryOf: this.state.selectedBroadCategory.categoryOf,
      expenseSubCategory: this.state.selectedSubCategory,
      expenseCategory: "inLineItems",
      expenseMedium: this.state.paymentMedium,
      expenseAmount: totalAmt,
      expenseNote: this.state.expenseNote,
      documentType: "directExpense",
      fileUploads: this.state.fileUploads,
      hasDeductible,
      paymentInfo: [
        {
          paymentId: 0,
          payMedium: this.state.paymentMedium,
          checkNo: this.state.checkNumber,
          status: "cleared",
          amount: totalAmt,
          description: this.state.payDescription,
          bankName: this.state.bankName,
          accountNo: this.state.accountNo,
          wiredToBankName: this.state.wiredToBankName,
          wiredToAccountNo: this.state.wiredToAccountNo,
        },
      ],
      spent_at: this.state.expenseDate,
      baandaId: this.props.auth.user.baandaId,
      lineItems: this.state.spentAmtList,
      dgAssetVolume: this.state.adjustOfDASize,
      finYYYY: this.props.community.finYYYY,
      finMMDD: this.props.community.finMMDD,
      hrPaymentType: this.state.hrPaymentType,
      targetBaanda: this.state.targetServer,
      selectedEvent: this.state.selectedEvent,
      selectedPrepTask: this.state.selectedPreptask,
      referenceType: this.state.referenceType,
      itemwo: this.state.itemwo,
      clientProgram: "Expense.js",
      clientFunction: "CreateInput",
    };

    return input;
  };

  saveExpensePost = async () => {
    let input = await this.createInput();
    // console.log("saveExpensePost input:", input);
    try {
      let url = baandaServer + saveExpense;
      // console.log("saveExpensePost url:", url, " input:", input);
      let expret = await axios.post(url, input);
      // console.log("expret.data:", expret.data);
      // console.log("expret.data.status:", expret.data.status);
      if (expret.data.status === "success") {
        document.getElementById("expenseBroadCategory").value = "DEFAULT";

        this.setState({
          message: "Expense successfully recorded.",
          errFlag: false,
        });
        await this.clearExpScreen();
      } else {
        this.setState({
          message: expret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("saveExpsne:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  handleExpenseSave = async () => {
    let isValid = await this.validateInput();
    // console.log("isValid:", isValid);
    if (isValid.status === "success") {
      if (this.state.receiptUploadType === "none") {
        // console.log('Via none $$$$$$$$$$$');
        await this.saveExpensePost();
      } else {
        let attachPdfFlag = false;
        let attachPicFlag = false;
        let showMainExpPanelFlag = false;
        let attachNone = true;
        let picS3FileData = null;
        let pdfS3FileData = null;
        if (this.state.fileUploads.length > 0) {
          this.state.fileUploads.forEach((da) => {
            if (da.type === "image") picS3FileData = da;
            if (da.type === "pdf") pdfS3FileData = da;
          });
        }
        if (this.state.receiptUploadType === "image") {
          attachNone = false;
          attachPicFlag = true;
        }
        if (this.state.receiptUploadType === "pdf") {
          attachNone = false;
          attachPdfFlag = true;
        }
        if (attachNone) {
          showMainExpPanelFlag = true;
        }
        if (this.state.subDirName === "") {
          // console.log("call toget subdirName");
          await this.subItemSubDirectory();
        }
        this.setState({
          showMainExpPanelFlag,
          attachPdfFlag,
          attachPicFlag,
          picS3FileData,
          pdfS3FileData,
        });
      }
    }
  };

  returnToExpense = async (fileData) => {
    // console.log("returnToExpense fileData:", fileData);
    let picS3FileData = this.state.image;
    let adjustOfDASize = 0;
    let message = "";
    let fileUploads = [];
    if (fileData.ops === "done") {
      if (fileData.ifDeletedFlag) {
        picS3FileData = null;
      }

      if (fileData.s3FileData) {
        picS3FileData = {
          key: fileData.s3FileData.key,
          type: "image",
          location: fileData.s3FileData.location,
          subBucket: this.state.subDirName,
          size: fileData.s3FileData.size,
        };
        fileUploads.push(picS3FileData);
        adjustOfDASize = fileData.s3FileData.size;
      }

      await this.setState({
        showMainExpPanelFlag: true,
        attachPdfFlag: false,
        attachPicFlag: false,
        adjustOfDASize,
        fileUploads,
        // message,
        picS3FileData: null,
        pdfS3FileData: null,
        message,
      });

      await this.saveExpensePost();

      // Clear rest of the screen
      // await this.clearExpScreen();
    }
    if (fileData.ops === "cancel") {
      this.setState({
        showMainExpPanelFlag: true,
        attachPdfFlag: false,
        attachPicFlag: false,
      });

      if (this.state.fileUploads.length > 0) await this.saveExpensePost();
    }
  };

  handlePayInfo = async () => {
    this.setState({
      showPayInfoFlag: true,
      showReferenceFlag: false,
    });
  };

  exitPayInfo = async () => {
    this.setState({
      showPayInfoFlag: false,
      showReferenceFlag: false,
    });
  };

  handleReference = async () => {
    if (
      this.state.selectedCategoryOf.toLowerCase() === "writeoff" &&
      this.state.spentAmtList.length > 1
    ) {
      this.setState({
        message:
          "For writeoff, you can have only one line item to manage references. Check info for more.",
        errFlag: true,
      });
    } else {
      this.setState({
        message: "",
        errFlag: false,
        showPayInfoFlag: false,
        showMainExpPanelFlag: false,
        showReferenceFlag: true,
        selectedEvent: null,
        selectedPrepTask: null,
      });
    }
  };

  exitReference = async (retval) => {
    // console.log("retval:", retval);

    if (retval.status === "cancel") {
      this.setState({
        showPayInfoFlag: false,
        showReferenceFlag: false,
        showMainExpPanelFlag: true,
        selectedEvent: null,
        selectedPreptask: null,
      });
    }
    if (retval.status === "done") {
      this.setState({
        showPayInfoFlag: false,
        showReferenceFlag: false,
        showMainExpPanelFlag: true,
        selectedEvent: retval.selectedEvent,
        selectedPreptask: retval.selectedPrepTask,
        targetServer: retval.targetServer,
        referenceType: retval.type,
        hrPaymentType: retval.hrPaymentType,
        itemwo: retval.itemwo,
      });
    }
  };

  clearExpScreen = async () => {
    // console.log("clearExpScreen start ...");
    this.setState({
      broadCategory: [],
      categoryBudget: null,
      selectedCategoryOf: "",
      recName: "",
      recEmail: "",
      recCell: "",
      selheightbc: 1,
      selectedBroadCategory: null,
      selectedPreptask: null,
      showSubCatFlag: false,
      selectedSubCategory: "",
      showPayrollTypeFlag: false,
      showAmountInputFlag: false,
      selheightsub: 1,
      selheightad: 1,
      workerType: "contractor",
      paymentType: "fixed",
      calculatedAmt: 0,
      expAmount: 0,
      percent: 0,
      companyContribution: false,
      companyContributionAmount: 0,
      markAsDeductable: false,
      expLineItem: "",
      spentAmtList: [],
      receiptUploadType: "none",
      expenseDate: new Date(),
      expenseNote: "",
      paymentMedium: "cash",
      showPayInfoFlag: false,
      showReferenceFlag: false,
      payDescription: "",
      subDirName: "",
      picS3FileData: null,
      pdfS3FileData: null,
      fileUploads: [],
      showMainExpPanelFlag: true,
      attachPdfFlag: false,
      attachPicFlag: false,
      adjustOfDASize: 0,
      image: null,
      community: null,
      confirmExpenseData: false,
      bankName: "",
      accountNo: "",
      wiredToBankName: "",
      wiredToAccountNo: "",
      hrPaymentType: null,
      selectedEvent: null,
    });
    await this.getExpenseCategoryOfs();
    // console.log("here here 905");
    document.getElementById("expenseBroadCategory").value = "DEFAULT";
    // console.log("here here 907");
    // document.getElementById("expenseSubCategory").value = "DEFAULT";
  };

  handleLiability = async () => {
    alert("handle liabilities.");
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Expense...");

    let helpButton;
    helpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let clearButton;
    clearButton = (
      <button
        className="btn_reg_60 store-exp-clear-btn-pos"
        type="button"
        onClick={this.clearExpScreen}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Clear
      </button>
    );

    let paymentButton;
    paymentButton = (
      <button
        className="btn_reg_80 exp-pay-type-pos"
        type="button"
        onClick={this.handlePayInfo}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Pay Type
      </button>
    );

    let buttonReference;
    if (
      (this.state.selectedCategoryOf === "Event" &&
        this.state.selectedSubCategory === "Tasks") ||
      this.state.selectedCategoryOf === "Professional Service Fee" ||
      this.state.selectedCategoryOf === "Writeoff"
    ) {
      buttonReference = (
        <button
          className="btn_reg_80"
          type="button"
          onClick={this.handleReference}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Reference
        </button>
      );
    }

    let brdCatList;

    if (this.state.broadCategory.length > 0) {
      brdCatList = this.state.broadCategory.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.categoryOf}&nbsp;
          </option>
        );
      });
    }

    let broadCategoryDropdownPanel;
    if (this.state.broadCategory.length > 0) {
      broadCategoryDropdownPanel = (
        <div className="ship-addr-from-pos">
          <select
            size={this.state.selheightbc}
            id="expenseBroadCategory"
            name="expenseBroadCategory"
            onChange={this.handleBroadCategorySelect}
            className="exp-broad-cat-dd"
            onFocus={this.onfocusbc}
            onBlur={this.onblurbc}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a broad category
            </option>
            {brdCatList}
          </select>
        </div>
      );
    } else {
      broadCategoryDropdownPanel = (
        <div className="set-expense-categories-msg">
          Define Categories in Budget first.
        </div>
      );
    }

    let subCatList = [];
    if (this.state.selectedBroadCategory) {
      subCatList = this.state.selectedBroadCategory.categories.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.label}&nbsp;
          </option>
        );
      });
    }

    let subCategoryDropdownPanel;
    if (subCatList.length > 0) {
      subCategoryDropdownPanel = (
        <div className="ship-addr-from-pos">
          <select
            size={this.state.selheightad}
            id="expenseSubCategory"
            name="expenseSubCategory"
            onChange={this.handleSubCategorySelect}
            className="exp-broad-cat-dd"
            onFocus={this.onfocussub}
            onBlur={this.onblursub}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a Sub-category
            </option>
            {subCatList}
          </select>
        </div>
      );
    }

    let wfTypePanel;
    wfTypePanel = (
      <div className="row worker-type-row-pos">
        <div className="col text-center radio-font-style">
          <strong>Worker Type&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="contractor"
                checked={this.state.workerType === "contractor"}
                onChange={this.handleWorkerType}
              />{" "}
              Gig
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="employee"
                checked={this.state.workerType === "employee"}
                onChange={this.handleWorkerType}
              />{" "}
              Perm
            </label>
          </div>
        </div>
      </div>
    );

    let expenseTopPanel;
    expenseTopPanel = (
      <div className="text-center exp-top-pan-pos">
        <div className="row exp-top-rows-pos">
          <div className="col-3 text-right exp-top-pnl-lbl">Recipient Name</div>
          <div className="col-8 text-left exp-bc-pos">
            <input
              name="recName"
              type="text"
              value={this.state.recName}
              onChange={this.onChange}
              size="60"
              maxLength="60"
              className="exp-name-email"
              placeholder=""
            />
          </div>
          <div className="col-1 text-left">&nbsp;</div>
        </div>
        <div className="row exp-top-rows-pos">
          <div className="col-3 text-right exp-top-pnl-lbl"> Email</div>
          <div className="col-8 text-left exp-bc-pos">
            <input
              name="recEmail"
              type="text"
              value={this.state.recEmail}
              onChange={this.onChange}
              size="60"
              maxLength="60"
              className="exp-name-email"
              placeholder=""
            />
          </div>
          <div className="col-1 text-left exp-top-pnl-lbl">&nbsp;</div>
        </div>
        <div className="row exp-top-rows-pos">
          <div className="col-3 text-right exp-top-pnl-lbl">Phone</div>
          <div className="col-3 text-left exp-bc-phone">
            <PhoneInput
              placeholder="Enter Cell number"
              value={this.state.recCell}
              onChange={(recCell) => this.setState({ recCell })}
              country="US"
              className="exp-phone-inp"
            />
          </div>
          <div className="col-6">&nbsp;</div>
        </div>
        <div className="row exp-top-rows-pos">
          <div className="col-3 text-right exp-top-pnl-lbl"> Expense Note</div>
          <div className="col-9 text-left exp-bc-pos">
            <input
              name="expenseNote"
              type="text"
              value={this.state.expenseNote}
              onChange={this.onChange}
              size="150"
              maxLength="150"
              className="exp-name-email"
              placeholder=""
            />
          </div>
        </div>
        <div className="row exp-top-rows-pos">
          <div className="col-3 text-right exp-top-pnl-lbl">Category Broad</div>
          <div className="col-3 text-left ">{broadCategoryDropdownPanel}</div>
          <div className="col-2 text-right exp-top-pnl-lbl">Sub </div>
          <div className="col-3 text-left exp-bc-pos">
            {this.state.showSubCatFlag ? subCategoryDropdownPanel : null}
          </div>
          <div className="col-1 text-right exp-top-pnl-lbl">&nbsp;</div>
        </div>
        <div className="row exp-top-rows-pos">
          <div className="col-6">
            {this.state.showPayrollTypeFlag ? wfTypePanel : null}
          </div>
          <div className="col-1 text-right exp-top-date-pos">Dated</div>
          <div className="col-5">
            <DatePicker
              selected={this.state.expenseDate}
              onSelect={this.handleExpenseDate}
              onChange={this.handleExpenseDate}
              // minDate={new Date()}
              name="expenseDate"
              dateFormat="MM/dd/yyyy"
              className="expense-date"
            />
          </div>
        </div>
      </div>
    );

    let addButton;
    addButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.addThisExpense}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-plus" />
        &nbsp;Add
      </button>
    );

    let postButton;
    postButton = (
      <button
        className="btn_reg_50 fin-expense-post-btn-pos"
        type="button"
        onClick={this.handleExpenseSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Post
      </button>
    );

    let paymentTypePanel;
    paymentTypePanel = (
      <div className="row exp-payment-medium-row">
        <div className="col text-center radio-font-style">
          <strong>Payment Type&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="cash"
                checked={this.state.paymentMedium === "cash"}
                onChange={this.handlePaymentMedium}
              />{" "}
              Cash
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="check"
                checked={this.state.paymentMedium === "check"}
                onChange={this.handlePaymentMedium}
              />{" "}
              Check
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="wire"
                checked={this.state.paymentMedium === "wire"}
                onChange={this.handlePaymentMedium}
              />{" "}
              Wire
            </label>
          </div> */}
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="bank-Trnasfer"
                checked={this.state.paymentMedium === "bank-Trnasfer"}
                onChange={this.handlePaymentMedium}
                disabled
              />{" "}
              <font color="gray">Bank-Transfer</font>
            </label>
          </div>
        </div>
      </div>
    );

    let budgetAmtPanel;
    if (this.state.categoryBudget && this.state.categoryBudget.length > 0) {
      // let spentamt = this.state.categoryBudget[0].spentAmount;

      // if (this.state.paymentType === "percent")
      //   spentamt += parseFloat(this.state.calculatedAmt);
      // else spentamt += parseFloat(this.state.expAmount);

      budgetAmtPanel = (
        <div className="text-center row aloc-spent-save-row">
          <div className="col-2 text-right bgt-exp-stmt-lbl">Allocated Amt</div>
          <div className="col-2 text-left bgt-exp-stmt-lbl-alloc">
            ${" "}
            {this.commaFormattedCurrency(
              this.state.categoryBudget[0].allocatedAmount
            )}
          </div>
          <div className="col-2 text-right bgt-exp-stmt-lbl">Spent Amt</div>

          {parseFloat(this.state.categoryBudget[0].allocatedAmount) >=
          parseFloat(this.state.spentAmount) ? (
            <div className="col-2 text-left bgt-spent-brown">
              ${this.commaFormattedCurrency(parseFloat(this.state.spentAmount))}
            </div>
          ) : (
            <div className="col-2 text-left bgt-spent-red">
              ${this.commaFormattedCurrency(parseFloat(this.state.spentAmount))}
            </div>
          )}
          <div className="text-center">{addButton}</div>
        </div>
      );
    }

    let expInputPanel;
    expInputPanel = (
      <div className="fixedsize-inp-box">
        <div className="row exp-line-item-top">
          <div className="col-4 text-right radio-font-style-exp">
            <strong>Pay Type&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="fixed"
                  checked={this.state.paymentType === "fixed"}
                  onChange={this.handlePaymentType}
                />{" "}
                Fixed
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="percent"
                  checked={this.state.paymentType === "percent"}
                  onChange={this.handlePaymentType}
                />{" "}
                Percent
              </label>
            </div>
          </div>
          {this.state.paymentType === "percent" ? (
            <div className="col-2 text-left percent-inp-lbl">
              Percent&nbsp;
              <input
                name="percent"
                type="number"
                value={this.state.percent}
                onChange={this.onChangeExpAmount}
                className="exp-percent-inp"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />{" "}
              %
            </div>
          ) : (
            <div className="col-2 text-left">&nbsp;</div>
          )}
          {this.state.selectedCategoryOf === "Professional Service Fee" ? (
            <div className="col-2 text-center exp-line-item-top">
              <Checkbox
                checked={this.state.markAsDeductable}
                onChange={this.handleMarkAsDeductable}
              />{" "}
              Deductable
            </div>
          ) : (
            <div className="col-2 text-center exp-line-item-top">&nbsp;</div>
          )}

          {this.state.selectedCategoryOf === "Professional Service Fee" ? (
            <div className="col-4 text-center exp-line-item-top">
              <Checkbox
                checked={this.state.companyContribution}
                onChange={this.handleCompanyContribution}
              />{" "}
              Company Pays $&nbsp;
              {this.state.companyContribution ? (
                <input
                  name="companyContributionAmount"
                  type="number"
                  value={this.state.companyContributionAmount}
                  onChange={this.onChangeExpAmount}
                  className="exp-comp-exp-amount"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                  spellCheck="false"
                />
              ) : null}
            </div>
          ) : (
            <div className="col-4 test-center exp-line-item-top">&nbsp;</div>
          )}
        </div>
        <div className="row amt-rows-pos">
          <div className="col-3 text-right exp-top-pnl-lbl"> Amount</div>
          <div className="col-4 text-left exp-bc-pos">
            <input
              name="expAmount"
              type="number"
              value={this.state.expAmount}
              onChange={this.onChangeExpAmount}
              className="exp-fixed-amt"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          {this.state.paymentType === "percent" ? (
            <div className="col-5 text-left">
              Calculated $
              {this.commaFormattedCurrency(this.state.calculatedAmt)}
            </div>
          ) : (
            <div className="col-5 text-left">&nbsp;</div>
          )}
        </div>
        {/* {paymentTypePanel} */}
        <div className="row exp-note-pos">
          <div className="col-3 text-right exp-top-pnl-lbl">
            Item Description
          </div>
          <div className="col-9 text-left exp-bc-pos">
            <input
              name="expLineItem"
              type="text"
              value={this.state.expLineItem}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="exp-note-txt"
              placeholder="In 100 chars"
            />
          </div>
        </div>
        {budgetAmtPanel}
      </div>
    );

    let expenseListPanel;
    let bool = true;
    expenseListPanel = (
      <div>
        <div className="row exp=list-row">
          <div className="col-1 text-center exp-list-head">Ops</div>
          <div className="col-2 text-right exp-list-head"> Amount</div>
          <div className="col-3 text-left exp-list-head">Sub-category</div>
          <div className="col-6 text-left exp-list-head">Note</div>
        </div>
        <div className="fixedsize-exp-list-box">
          {this.state.spentAmtList.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-1 text-center">
                    <button
                      className="btn_exit_del exp-line-del-pos"
                      type="button"
                      onClick={() => this.selectThisItemToDelete(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-2 text-right sub-cat-list-txt ">
                    {this.commaFormattedCurrency(obj.spentAmt)}
                  </div>
                  <div className="col-3 text-left sub-cat-list-txt">
                    {obj.subCategory}
                  </div>
                  <div className="col-6 text-left sub-cat-list-txt ">
                    {obj.expLineItem}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let total = 0;
    this.state.spentAmtList.forEach((obj) => {
      if (obj.markAsDeductable) {
        total -= parseFloat(obj.spentAmt);
      } else total += parseFloat(obj.spentAmt);
    });

    let bottomTypePanel;
    bottomTypePanel = (
      <div className="row expense-bottom-row-pos">
        <div className="col-5 text-right">
          <strong>Receipt Upload&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="image"
                checked={this.state.receiptUploadType === "image"}
                onChange={this.receiptUploadType}
              />{" "}
              Image
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="pdf"
                checked={this.state.receiptUploadType === "pdf"}
                onChange={this.receiptUploadType}
              />{" "}
              Pdf
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="none"
                checked={this.state.receiptUploadType === "none"}
                onChange={this.receiptUploadType}
              />{" "}
              None
            </label>
          </div>
        </div>
        <div className="col-5 text-center exp-confirm-to-save">
          <Checkbox
            checked={this.state.confirmExpenseData}
            onChange={this.handleExpenseConfirm}
          />{" "}
          <mark>Confirm to complete. Posting is final.</mark>
        </div>
        <div className="col-2 text-center">
          {this.state.confirmExpenseData ? postButton : null}
        </div>
      </div>
    );

    let checkPanel;
    if (this.state.paymentMedium === "check") {
      checkPanel = (
        <div>
          <div className="row exp-note-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">check Number</div>
            <div className="col-9 text-left exp-bc-pos">
              <input
                name="checkNumber"
                type="text"
                value={this.state.checkNumber}
                onChange={this.onChange}
                size="35"
                maxLength="35"
                className="exp-checkno"
                placeholder=""
              />
            </div>
          </div>
        </div>
      );
    }

    let wiredToBankPanel;
    if (this.state.paymentMedium === "wire") {
      wiredToBankPanel = (
        <div>
          <div className="row exp-bank-row-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">
              Wired To Bank
            </div>
            <div className="col-9 text-left exp-bc-pos">
              <input
                name="wiredToBankName"
                type="text"
                value={this.state.wiredToBankName}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="exp-bank-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row exp-bank-row-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">
              Wired To A/C #
            </div>
            <div className="col-9 text-left exp-bc-pos">
              <input
                name="wiredToAccountNo"
                type="text"
                value={this.state.wiredToAccountNo}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="exp-bank-name"
                placeholder=""
              />
            </div>
          </div>
        </div>
      );
    }

    let yourBankInfoPanel;
    if (
      this.state.paymentMedium === "check" ||
      this.state.paymentMedium === "wire"
    ) {
      yourBankInfoPanel = (
        <div>
          <div className="row exp-bank-row-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">
              Community Bank
            </div>
            <div className="col-9 text-left exp-bc-pos">
              <input
                name="bankName"
                type="text"
                value={this.state.bankName}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="exp-bank-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row exp-bank-row-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">Comm A/c #</div>
            <div className="col-9 text-left exp-bc-pos">
              <input
                name="accountNo"
                type="text"
                value={this.state.accountNo}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="exp-account-no "
                placeholder=""
              />
            </div>
          </div>
        </div>
      );
    }

    let payInfoPanel;
    if (this.state.showPayInfoFlag) {
      payInfoPanel = (
        <div className="fixedsize-payinfo-box">
          <div className="row">
            <div className="col-10 text-center exp-payinfo-head">
              Payment Information
            </div>
            <div className="col-2 text-left">
              {/* <button
                className="btn_back_main"
                type="button"
                onClick={this.exitPayInfo}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-step-backward" />
              </button> */}
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.exitPayInfo}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Done
              </button>
            </div>
          </div>
          {paymentTypePanel}
          <div className="row exp-note-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">Description</div>
            <div className="col-9 text-left exp-bc-pos">
              <textarea
                name="payDescription"
                maxLength="1000"
                placeholder="Additional info on payment."
                rows="3"
                wrap="hard"
                spellCheck="true"
                className="input-exp-pay-desc"
                onChange={this.onChange}
                value={this.state.payDescription}
                required
              />
            </div>
          </div>
          {checkPanel}
          {yourBankInfoPanel}
          {wiredToBankPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.showMainExpPanelFlag) {
      outputPanel = (
        <div className="fixedsize-expense-box text-center">
          <div className="text-center">
            <div className="row">
              <div className="col-9 text-center expense-header">
                Expense Recording&nbsp;&nbsp;{helpButton}{" "}
              </div>
              {/* <div className="col-1 text-left">xxx</div>
              <div className="col-1 text-left">yyy</div> */}
              {/* <div className="col-2 text-left">{buttonReference}</div> */}
              <div className="col-3 text-left">
                {clearButton}&nbsp;{buttonReference}
              </div>
            </div>

            {expenseTopPanel}
            {expInputPanel}
            {payInfoPanel}
            {expenseListPanel}
            <div className="row exp-total-row">
              <div className="col-1 text-center exp-total-txt">Total</div>
              <div className="col-2 text-right exp-total-txt">
                {this.commaFormattedCurrency(total)}
              </div>
              <div className="col-3 text-left">{paymentButton}</div>
              <div className="col-6 text-left">&nbsp;</div>
            </div>
            {bottomTypePanel}
            <div className="row exp-message-row">
              <div
                className={
                  this.state.errFlag
                    ? "col text-center expense-message-err"
                    : "col text-center expense-message"
                }
              >
                {this.state.message}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.attachPicFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToExpense}
            communityId={this.props.communityId}
            fileType={picTypes}
            maxSize={maxPicSize}
            s3subdir={this.state.subDirName}
            requestor="Expense"
            s3FileData={this.state.picS3FileData}
            type="image"
          />
        </div>
      );
    }

    if (this.state.showReferenceFlag) {
      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.store.coopStoreId;
      outputPanel = (
        <div>
          <ReferenceHub
            returnToCaller={this.exitReference}
            communityId={this.props.communityId}
            members={this.props.community.members}
            categoryOf={this.state.selectedCategoryOf}
            subCategory={this.state.selectedSubCategory}
            coopStoreId={coopStoreId}
          />
        </div>
      );
    }

    if (this.state.attachPdfFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToExpense}
            communityId={this.props.communityId}
            fileType={pdfTypes}
            maxSize={maxPdfSize}
            s3subdir={this.state.subDirName}
            requestor="Expense"
            s3FileData={this.state.pdfS3FileData}
            type="pdf"
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Expense.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Expense));
