import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./CreateServiceTeam.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

const readMeCode = "1111211200";

class CreateServiceTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      memberName: "",

      members: [],
      selectedMembers: [],

      teamTrait: "individual",
      theEvent: null,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getMembers();

    let selectedMembers = [];
    if (
      this.props.theEvent.services &&
      this.props.theEvent.services.serviceTeam.length > 0
    ) {
      selectedMembers = this.props.theEvent.services.serviceTeam;
    }

    await this.setState({
      deviceSize,
      selectedMembers,
      theEvent: this.props.theEvent,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getMembers = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&memberName=" +
      this.state.memberName;
    let url = baandaServer + getMembersOfCommunity + params;
    // console.log("url:", url);
    try {
      let mems = await axios.get(url);
      this.setState({
        members: mems.data.Msg,
      });
    } catch (err) {
      console.log("Err:", err.message);
    }

    if (document.getElementById("serviceTeamSelect"))
      document.getElementById("serviceTeamSelect").value = "DEFAULT";
  };

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleMemberSelected = async () => {
    let value = document.getElementById("serviceTeamSelect").value;
    let val = JSON.parse(value);
    // console.log("handleMemberSelected val:", val);
    let newSelect = true;
    this.state.selectedMembers.forEach((obj) => {
      if (obj.email === val.email) newSelect = false;
    });

    if (newSelect) {
      let obj = {
        baandaId: val.baandaId,
        name: val.name,
        email: val.email,
        cell: val.cell,
      };
      this.state.selectedMembers.push(obj);
      await this.setState({
        selheight: 1,
      });
    }
  };

  validateData = async () => {
    let isValid = true;
    let msg = "";

    if (this.state.location === "") {
      isValid = false;
      msg = "Location description is needed to save. || ";
    }

    return { status: isValid, Msg: msg };
  };

  packDataForDB = async () => {
    let input = {
      eventId: this.props.theEvent.eventId,
      serviceTeam: this.state.selectedMembers,
      module: "createserviceteam",
      clientProgram: "DefineShift.js",
      clientFunction: "saveUpdateEvent",
    };

    return input;
  };

  saveUpdateEvent = async () => {
    let url = baandaServer + saveUpdtEvent;
    let isValid = await this.validateData();
    // console.log("isValid:", isValid);
    if (isValid.status) {
      let inp = await this.packDataForDB();
      // console.log(" url:", url, " inp:", inp);
      try {
        let resp = await axios.post(url, inp);
        // console.log("rest:", resp);
        if (resp.data.status === "success") {
          this.setState({
            errMsg: "Team successfully included.",
            eventId: resp.data.Msg.eventId,
            theEvent: resp.data.Msg.theEvent,
          });
          this.returnToCreateShift();
        } else {
          this.setState({
            errMsg: resp.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        errMsg: isValid.Msg,
      });
    }
  };

  returnToCreateShift = async () => {
    this.props.returnToCaller(this.state.theEvent);
  };

  handleRenderTeamTraits = async (e) => {
    await this.setState({
      teamTrait: e.target.value,
    });
  };

  handleDeleteMembers = async (mem) => {
    // console.log("mem:", mem);
    let delmem = [];
    this.state.selectedMembers.forEach((obj) => {
      if (obj.baandaId !== mem.baandaId) {
        delmem.push(obj);
      }
    });
    await this.setState({
      selectedMembers: delmem,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CreateServiceTram...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div>
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.saveUpdateEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
        {infoModalButton}
      </div>
    );

    let memberlNamePanel;
    if (!this.props.store) {
      memberlNamePanel = (
        <div>
          <div className="row name-row">
            <div className="col text-center inventory-label input_placement">
              Member Name&nbsp;&nbsp;
              <input
                name="memberName"
                type="text"
                value={this.state.memberName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="group-search-field"
                placeholder="Enter member name to filter"
                spellCheck="false"
              />
              &nbsp;
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.getMembers}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Fetch
              </button>
            </div>
          </div>
          <div>
            <p className="text-cente event-name-msg">
              Part/full/blank of member name.
            </p>
          </div>
        </div>
      );
    }

    let memberlist = this.state.members.map((obj, i) => {
      return (
        <option key={i} value={JSON.stringify(obj)} className="name-of-groups">
          {obj.name}&nbsp;({obj.email})
        </option>
      );
    });

    let selheight = 7;

    let memberListPanel;
    memberListPanel = (
      <div>
        <div className="selected-group-header-mem">Member List</div>
        <div className="selected-servers-box-mem">
          <div className="row">
            <div className="col text-center">
              <select
                size={selheight}
                id="serviceTeamSelect"
                name="serviceTeamSelect"
                onChange={this.handleMemberSelected}
                className="server-member-select"
                //   onFocus={this.onfocus}
                //   onBlur={this.onblur}
              >
                <option value="DEFAULT" disabled>
                  Select a Member
                </option>
                {memberlist}
              </select>
            </div>
          </div>
        </div>
      </div>
    );

    let selectedMembersPanel;
    selectedMembersPanel = (
      <div className="row">
        <div className="col">
          <div className="selected-group-header">Server Members</div>
          <div className="selected-servers-box">
            <div>
              {this.state.selectedMembers.map((obj, i) => {
                return (
                  <div key={i}>
                    <div className="row">
                      <div className="col-10 text-left group-name-lbl">
                        {obj.email}
                      </div>
                      <div className="col-2 text-left">
                        <button
                          className="btn-server-delete"
                          type="button"
                          onClick={() => this.handleDeleteMembers(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );

    // let teamTraitsPanel;
    // teamTraitsPanel = (
    //   <div>
    //     <div className="row row-team-trait">
    //       <div className="col">
    //         <strong>Serve as&nbsp;&nbsp;</strong>
    //         <div className="form-check form-check-inline">
    //           <label className="form-check-label">
    //             <input
    //               className="form-check-input"
    //               type="radio"
    //               value="individual"
    //               checked={this.state.teamTrait === "individual"}
    //               onChange={this.handleRenderTeamTraits}
    //             />{" "}
    //             Individual
    //           </label>
    //         </div>
    //         <div className="form-check form-check-inline">
    //           <label className="form-check-label">
    //             <input
    //               className="form-check-input"
    //               type="radio"
    //               value="asteam"
    //               checked={this.state.teamTrait === "asteam"}
    //               onChange={this.handleRenderTeamTraits}
    //             />{" "}
    //             A Team
    //           </label>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );

    let memberPanel;
    memberPanel = (
      <div>
        <div className="text-center">{memberlNamePanel}</div>

        <div className="row">
          <div className="col-6 text-center">{memberListPanel}</div>
          <div className="col-6 text-center">{selectedMembersPanel}</div>
        </div>
        <div className="row row-team-trait">
          <div className="col text-center">{buttonPanel}</div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center time-service-team-head">
          <b>Event</b>:&nbsp;{this.props.theEvent.eventName}
        </div>
        {memberPanel}

        <div
          className={
            this.state.errFlag
              ? "text-center createserviceteam-msg-err"
              : "text-center createserviceteam-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CreateServiceTeam.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateServiceTeam));
