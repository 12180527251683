/*
 **  Author: Jit (Sarbojit Mukherjee)
 **  Desc:   Provies navigation to landing, lobby, signup, or signin based on state of user's authenticaiton.
 **
 **  Date:   Julye 9, 2018
 **  Version:0.01
 */
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../../../../actions/authActions";

// import queryString from "query-string";

import Toolbar from "./Toolbar/Toolbar";
import SideDrawer from "./SideDrawer/SideDrawer";
import Backdrop from "../Backdrop/Backdrop";

const guestLoginId = process.env.REACT_APP_BAANDA_GUEST;

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false,
      logoutFlag: false,
      
      asGuest: false
    };

    this.doInTheBegining();
  }
  

  onLogoutClick = () => {
    // e.preventDefault();
    // console.log("onLogoutClick this.props.history:", this.props);
    this.setState({ sideDrawerOpen: false });
    this.props.logoutUser(this.props.history);
    // console.log("onLogoutClick this.props.history:", this.props);
    this.props.history.push("/login");
  };

  onSignupClick = () => {
    this.setState({ sideDrawerOpen: false });
    this.props.history.push("/register");
  };

  onMyAccountClick = () => {
    this.setState({ sideDrawerOpen: false });
    this.props.history.push("/myaccount");
  };

  onLobbyClickApp = () => {
    this.setState({ sideDrawerOpen: false });
    this.props.history.push("/lobby");
  };

  componentDidMount = () => {
    // console.log('Navbar testing ...')
    // const values = queryString.parse(this.props.location.search);
    // console.log("1. Navbar onmount values:", values);
  }

  doInTheBegining = () => {
    var url = window.location.search.substring(1);
    var qArray = url.split("&");
    // console.log('qArray:', qArray);
    if (qArray.length > 0) {
      for (var i = 0; i < qArray.length; i++) {
        var pArr = qArray[i].split("=");
        if (pArr[0] === "type") {
          if (pArr[1] === "logout") {
            this.props.logoutUser(this.props.history);
            this.props.history.push("/login");
          }
          if (pArr[1] === "finance") {
            // this.props.logoutUser(this.props.history);
            this.props.history.push("/finance");
          }
        }
      }
    }
  };

  drawerToggleClickHandler = () => {
    if (this.state.sideDrawerOpen) {
      localStorage.setItem("backdrop", "close");
    } else {
      localStorage.setItem("backdrop", "open");
    }

    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
    let yy = localStorage.getItem("backdrop");
    // console.log("drawToggleClickHandler:" + yy);
    if (yy === "close") {
      this.setState({ sideDrawerOpen: false });
    }
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    // console.log('this.props:', this.props);
    // console.log('this.state:', this.state);
    console.log('Navbar...');

    let isGuest = false; 
    if ( this.props.auth.user.email === guestLoginId) isGuest = true;

    const toolbar = (
      <Toolbar
        drawerClickHandler={this.drawerToggleClickHandler}
        onSignupClickApp={this.onSignupClick}
        onLogoutClickApp={this.onLogoutClick}
        onMyAccountClickApp={this.onMyAccountClick}
        onLobbyClickApp={this.onLobbyClickApp}
        auth={this.props.auth}
        guest={isGuest}
      />
    );

    const sideDrawer = (
      <SideDrawer
        show={this.state.sideDrawerOpen}
        onSignupClickApp={this.onSignupClick}
        onLogoutClickApp={this.onLogoutClick}
        onMyAccountClickApp={this.onMyAccountClick}
        onLobbyClickApp={this.onLobbyClickApp}
        auth={this.props.auth}
        guest={isGuest}
      />
    );

    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop CloseSideDrawer={this.backdropClickHandler} />;
    }

    return (
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark mb-4 fixed-top">
        <div className="container">
          {toolbar}
          {sideDrawer}
          {backdrop}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Navbar));
