import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./StorewideCoupon.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveStorewideCoupon = "/routes/dashboard/saveStorewideCoupon";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class StorewideCoupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      couponName: "",

      fromDate: new Date(),
      toDate: new Date(),
      percentDiscount: 0,
      openToAll: false,

      couponList: [],
      allCoupon: [],
      dataType: "active",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let storewideCoupons = [];
    if (this.props.community.storewideCoupons) {
      this.props.community.storewideCoupons.forEach((cu) => {
        let oldCoupon = {
          couponName: cu.couponName,
          fromDate: cu.fromDate,
          toDate: cu.toDate,
          percentDiscount: cu.percentDiscount,
          openToAll: cu.openToAll,
          inDB: true,
        };
        storewideCoupons.push(oldCoupon);
      });
    }

    let actCoupon = [];
    let today = new Date().getTime();
    storewideCoupons.forEach((act) => {
      let dt = new Date(act.toDate);

      if (dt.getTime() >= today) {
        actCoupon.push(act);
      }
    });

    this.setState({
      allCoupon: this.props.community.storewideCoupons,
      couponList: actCoupon,
    });
  };

  handleDataType = async (e) => {
    let value = e.target.value;
    let coplist = [];
    if (value === "all") {
      this.state.allCoupon.forEach((all) => {
        all.inDB = true;
        coplist.push(all);
      });
    } else {
      let today = new Date().getTime();
      this.state.allCoupon.forEach((act) => {
        let dt = new Date(act.toDate);

        act.inDB = true;
        if (dt.getTime() >= today) {
          coplist.push(act);
        }
      });
    }
    this.setState({
      dataType: e.target.value,
      couponList: coplist,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleFromDate = async (date) => {
    let dx = date.getTime();

    let dy = new Date(dx);
    let dd = dy.setDate(dy.getDate() + 1);

    this.setState({
      fromDate: date,
      toDate: dd,
    });
  };

  handleToDate = async (date) => {
    this.setState({
      toDate: date,
    });
  };

  onChangePercent = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  validateEntry = () => {
    let errstate = "success";
    let msg = "";

    if (this.state.couponName === "") {
      errstate = true;
      msg = "Must have a unique coupon name. ";
    } else {
      this.state.couponList.forEach((cl) => {
        if (cl.couponName === this.state.couponName) {
          errstate = "error";
          msg = "Duplicate coupon name not allowed.";
        }
      });
    }
    if (this.state.percentDiscount === 0) {
      errstate = "error";
      if (msg === "") {
        msg = "Discount percent must be positive.";
      } else {
        msg += "| Discount percent must be positive.";
      }
    }

    return { status: errstate, Msg: msg };
  };

  addACoupon = async () => {
    let val = this.validateEntry();

    if (val.status === "success") {
      let couponListx = this.state.couponList;
      let newCoupon = {
        couponName: this.state.couponName,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        percentDiscount: parseFloat(this.state.percentDiscount),
        openToAll: this.state.openToAll,
        inDB: false,
      };

      couponListx.push(newCoupon);

      this.setState({
        couponList: couponListx,
        errMsg: "",
        errFlag: false,
      });
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
      });
    }
  };

  dateFormat = (dateinp) => {
    let date = new Date(dateinp);
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });

    let formattedDate = year + "-" + month + "-" + day;

    return formattedDate;
  };

  saveCoupons = async () => {
    let cpnlist = [];
    this.state.couponList.forEach((cl) => {
      let linobj = {
        couponName: cl.couponName,
        fromDate: cl.fromDate,
        toDate: cl.toDate,
        percentDiscount: parseFloat(cl.percentDiscount),
        openToAll: cl.openToAll,
      };
      cpnlist.push(linobj);
    });
    let input = {
      communityId: this.props.communityId,
      cpnlist,
      clientProgram: "StorewideCoupon",
      clientFunction: "saveCoupon",
    };
    let url = baandaServer + saveStorewideCoupon;

    try {
      let cret = await axios.post(url, input);

      if (cret.data.status === "success") {
        let adjlist = [];
        this.state.couponList.forEach((clt) => {
          clt.inDB = true;
          adjlist.push(clt);
        });

        let coplist = [];
        if (this.state.dataType === "active") {
          let today = new Date().getTime();
          adjlist.forEach((act) => {
            let dt = new Date(act.toDate);

            act.inDB = true;
            if (dt.getTime() >= today) {
              coplist.push(act);
            }
          });
        } else coplist = adjlist;
        this.setState({
          errMsg: "Successfully saved.",
          errFlag: false,
          couponList: coplist,
          allCoupon: adjlist,
          couponName: "",
          percentDiscount: 0,
          fromDate: new Date(),
          toDate: new Date(),
        });
      } else {
        this.setState({
          errMsg: cret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  selectThisToDelete = (obj) => {
    let delList = [];
    this.state.couponList.forEach((cl) => {
      if (cl.couponName !== obj.couponName) delList.push(cl);
    });

    this.setState({
      couponList: delList,
    });
  };

  handleOpenToAll = async () => {
    await this.setState((prevstate) => ({
      openToAll: !prevstate.openToAll,
    }));
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("StorewideCoupon...");

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addLineButton = (
      <button
        className="btn_reg_70 coupon-add-pos"
        type="button"
        onClick={this.addACoupon}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add <i className="fa fa-plus" />
      </button>
    );

    let saveButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.saveCoupons}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let addCouponPanel;
    addCouponPanel = (
      <div>
        <div className="row">
          <div className="col-4 text-right adm-coupon-lbl">Coupon Name</div>
          <div className="col-8 text-left">
            <input
              name="couponName"
              type="text"
              value={this.state.couponName}
              onChange={this.onChange}
              maxLength="50"
              className="coupon-name-text"
              placeholder=""
            />{" "}
          </div>
        </div>
        <div className="row coupon-inp-row">
          <div className="col-4 text-right adm-coupon-lbl">Open From</div>
          <div className="col-8 text-left">
            <DatePicker
              selected={this.state.fromDate}
              onChange={this.handleFromDate}
              name="fromDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
        <div className="row coupon-inp-row">
          <div className="col-4 text-right adm-coupon-lbl">Open Till</div>
          <div className="col-8 text-left">
            <DatePicker
              selected={this.state.toDate}
              onChange={this.handleToDate}
              minDate={this.state.toDate}
              name="fromDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
        <div className="row coupon-inp-row">
          <div className="col-4 text-right adm-coupon-lbl">Percent</div>
          <div className="col-8 text-left">
            <input
              name="percentDiscount"
              type="number"
              value={this.state.percentDiscount}
              onChange={this.onChangePercent}
              className="coupon-percent"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {addLineButton}&nbsp;
            <Checkbox
              checked={this.state.openToAll}
              onChange={this.handleOpenToAll}
            />{" "}
            Open - Storewide Sale
          </div>
        </div>
        <div className="row ">
          <div className="col text-center radio-font-style">
            <strong>Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="active"
                  checked={this.state.dataType === "active"}
                  onChange={this.handleDataType}
                />{" "}
                Active
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="all"
                  checked={this.state.dataType === "all"}
                  onChange={this.handleDataType}
                />{" "}
                All
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let couponListPanel;

    if (this.state.couponList.length > 0) {
      let bool = true;
      couponListPanel = (
        <div>
          <div className="row">
            <div className="col-1 text-left coupon-hedr-lbl">Ops</div>
            <div className="col-5 text-left coupon-hedr-lbl">
              Coupon Name (Type)
            </div>
            <div className="col-2 text-center coupon-hedr-lbl">From date</div>
            <div className="col-2 text-center coupon-hedr-lbl">To date</div>
            <div className="col-2 text-center coupon-hedr-lbl">Percent</div>
          </div>
          <div className="adm-pkg-lines-box">
            {this.state.couponList.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={bool ? "row row-coupon-dr" : "row row-coupon-lt"}
                  >
                    <div className="col-1 text-center">
                      {obj.inDB ? (
                        <button
                          className="btn_exit_main_disabled"
                          type="button"
                        >
                          <i className="fa fa-trash" />
                        </button>
                      ) : (
                        <button
                          className="btn_exit_del"
                          type="button"
                          onClick={() => this.selectThisToDelete(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                    <div className="col-5 text-left coupon-name-map">
                      {obj.couponName}{" "}
                      {obj.openToAll ? " (Open)" : "(Required)"}
                    </div>
                    <div className="col-2 text-left coupon-from-date">
                      {this.dateFormat(obj.fromDate)}
                    </div>
                    <div className="col-2 text-left coupon-to-date">
                      {this.dateFormat(obj.toDate)}
                    </div>
                    <div className="col-2 text-left coupon-disc-percent">
                      {obj.percentDiscount}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      couponListPanel = (
        <div>
          <div className="row">
            <div className="col-1 text-left coupon-hedr-lbl">Ops</div>
            <div className="col-5 text-left coupon-hedr-lbl">Coupon Name</div>
            <div className="col-2 text-center coupon-hedr-lbl">From date</div>
            <div className="col-2 text-center coupon-hedr-lbl">To date</div>
            <div className="col-2 text-center coupon-hedr-lbl">Percent</div>
          </div>
          <div className="adm-pkg-lines-box">No active coupons exists.</div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="init-coupon-box">
        <div className="adm-coupon-header">
          Storewide Coupon Mgmt. &nbsp;{infoButton}
        </div>
        {addCouponPanel}
        {couponListPanel}
        {this.state.couponList.length > 0 ? (
          <div className="text-center">{saveButton}</div>
        ) : (
          ""
        )}
        <div
          className={
            this.state.errFlag
              ? "text-center adm-coupon-msg-err"
              : "text-center adm-coupon-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

StorewideCoupon.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StorewideCoupon));
