import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./SideDrawer.css";

const readMeCode = "0100000000";

class SideDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // closeDrawer: false
    };
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  render() {
    console.log("SideDrawer...");

    const { isAuthenticated, user } = this.props.auth;
    // console.log('SideDrawer: user', user, ' isAuthenticated:', isAuthenticated + ' props:', this.props );
    let drawerClasses = "side-drawer";
    if (this.props.show) {
      drawerClasses = "side-drawer open";
    }

    let navbardisp;
    if (isAuthenticated) {
      if (this.props.guest) {
        navbardisp = (
          <div>
            <ul>
              <li>
                <button
                  className="nav-readme-btn"
                  type="button"
                  // onClick={this.openAlertModal("tokenInput")}
                  onClick={this.openAlertModal}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Readme&nbsp;
                  {this.state.deviceSize === "big" ? (
                    <i className="fas fa-info-circle" />
                  ) : null}
                </button>
              </li>
              <li>
                <button
                  onClick={this.props.onLogoutClickApp}
                  className="nav-logout-btn "
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Exit
                </button>
              </li>
            </ul>
          </div>
        );
      } else {
        navbardisp = (
          <div className="font-color">
            <ul>
              <li className="text-left draw-user-name">
                {/* <h6>{user.name}</h6> */}
                {user.name}
              </li>

              <li>
                <button
                  onClick={this.props.onLobbyClickApp}
                  // className="transparent-button"
                  className="nav-btn-sidebar"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <div className="font-color">Lobby</div>
                  {/* <div className="font-color">Home</div> */}
                </button>
              </li>
              <li>
                <button
                  onClick={this.props.onMyAccountClickApp}
                  // className="transparent-button"
                  className="nav-btn-sidebar"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <div className="font-color">MyAccount</div>
                </button>
              </li>
              <li>
                <button
                  className="nav-btn-sidebar-x"
                  type="button"
                  onClick={this.openAlertModal}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Readme
                  {this.state.deviceSize === "big" ? (
                    <i className="fas fa-info-circle" />
                  ) : null}
                </button>
              </li>
              <li>
                <button
                  onClick={this.props.onLogoutClickApp}
                  className="nav-btn-sidebar"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <div className="font-color">Logout</div>
                </button>
              </li>
            </ul>
          </div>
        );
      }
    } else {
      navbardisp = (
        <div>
          <ul>
            <li>
              <Link to="/register" onClick={this.props.onSignupClickApp}>
                Sign Up
              </Link>
            </li>
            <li>
              <Link to="/login" onClick={this.props.onSignupClickApp}>
                Login
              </Link>
            </li>
          </ul>
        </div>
      );
    }

    return (
      <div>
        <nav className={drawerClasses}>{navbardisp}</nav>
        <ModalContainer />
      </div>
    );
  }
}

// export default SideDrawer;

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(connect(null, mapDispatchToProps)(SideDrawer));
