import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import RegisterPIWIP from "./RegisterPIWIP";

import "./RegisterPayableInvoice.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getUserByEmailForInvoice = "/routes/users/getUserByEmailForInvoice?";
const handlePayableInvoice = "/routes/finance/handlePayableInvoice";
const sendInviteToJoinBaanda = "/routes/finance/sendInviteToJoinBaanda";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1166000000";

class RegisterPayableInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionType: "newinvoice",
      toEdit: false,

      billFromEmail: "",
      billFromCell: "",
      billFromAddress: "",

      payByDate: new Date(),

      sendInviteToRegister: false,
      showBillFromDetailFlag: false,

      invoiceId: 0,

      lineNo: 1,
      lineDescription: "",
      linePrice: 0,
      lineQty: 1,
      lineUnit: "",
      total: 0,
      okToDraft: false,
      vendorInvoiceNo: "",

      addedLineItems: [],

      selInvoice: null,

      showMainInvoiceFlag: true,
    };
  }

  componentDidMount = async () => {
    this.setState({
      toEdit: false,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    return [
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/");
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  initForNew = async () => {
    this.setState({
      actionType: "newinvoice",
      toEdit: false,

      billFromEmail: "",
      billFromCell: "",
      billFromAddress: "",

      payByDate: new Date(),

      sendInviteToRegister: false,
      showBillFromDetailFlag: false,

      invoiceId: 0,

      lineNo: 1,
      lineDescription: "",
      linePrice: 0,
      lineQty: 1,
      lineUnit: "",
      total: 0,
      okToDraft: false,
      vendorInvoiceNo: "",

      addedLineItems: [],

      selInvoice: null,

      showMainInvoiceFlag: true,
    });
  };

  handleOpsType = async (e) => {
    let showMainInvoiceFlag = false;
    let showWIPPanelFlag = false;
    let value = e.target.value;
    if (value === "newinvoice") {
      showMainInvoiceFlag = true;
      showWIPPanelFlag = false;
      await this.initForNew();
    } else {
      showMainInvoiceFlag = false;
      showWIPPanelFlag = true;
    }
    this.setState({
      actionType: e.target.value,
      toEdit: false,
      showMainInvoiceFlag,
      showWIPPanelFlag,
    });
  };

  initWipInvoice = async (inv) => {
    let vendorInvoiceNo = inv.vendorReference[0].referenceId;
    let billFromAddress = inv.vendorReference[0].vendorAddress;

    let npd = new Date(inv.paySchedulePolicy.nextPayBy);

    let total = 0;
    inv.itemDetails.forEach((td) => {
      total += td.price * td.quantity;
    });

    this.setState({
      actionType: "editinvoice",
      showMainInvoiceFlag: true,
      showWIPPanelFlag: false,
      toEdit: true,

      billFromName: inv.customerName,
      billFromEmail: inv.customerEmail,
      billFromCell: inv.customerCell,
      billFromAddress,

      payByDate: npd,

      sendInviteToRegister: false,
      showBillFromDetailFlag: true,

      invoiceId: inv.invoiceId,

      lineNo: 1,
      lineDescription: "",
      linePrice: 0,
      lineQty: 1,
      lineUnit: "",
      total,
      okToDraft: true,
      vendorInvoiceNo,

      addedLineItems: inv.itemDetails,
    });
  };

  returnFromRegisterPIWip = async (wipinv) => {
    if (wipinv) await this.initWipInvoice(wipinv);
    this.setState({
      selInvoice: wipinv,
      showWIPPanelFlag: false,
      showMainInvoiceFlag: true,
    });
  };

  returnFromWipInvoiceList = async (inv) => {
    // console.log("inv:", inv);
  };

  initializeWithSelectedInvoice = async (inv) => {};

  initializeInvoice = async () => {
    this.setState({
      actionType: "newinvoice",
      toEdit: false,

      billFromEmail: "",
      billFromCell: "",
      billFromAddress: "",

      payByDate: new Date(),

      sendInviteToRegister: false,
      showBillFromDetailFlag: false,

      invoiceId: 0,

      lineNo: 1,
      lineDescription: "",
      linePrice: 0,
      lineQty: 1,
      lineUnit: "",
      total: 0,
      okToDraft: false,
      vendorInvoiceNo: "",

      addedLineItems: [],

      selInvoice: null,

      showMainInvoiceFlag: true,
    });
  };

  handleSubLineitemtype = async (e) => {
    this.setState({
      subLineItemType: e.target.value,
    });
  };

  handleTaxPaidBy = async (e) => {
    this.setState({
      taxPaidBy: e.target.value,
    });
  };

  onChangeInteger = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) === 0) {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  onChangeCurrency = async (e) => {
    // console.log("[e.target.name]:", [e.target.name]);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  handlecheckIfBaanda = async () => {
    let param =
      "email=" +
      this.state.billFromEmail +
      "&communityId=" +
      this.props.community.communityId;
    let url = baandaServer + getUserByEmailForInvoice + param;
    // console.log("url:", url);
    try {
      let baandaret = await axios.get(url);
      // console.log("baandaret.data:", baandaret.data);
      if (baandaret.data.status === "success") {
        let bdm = baandaret.data.Msg;
        if (bdm.baandaId === 0) {
          this.setState({
            customerBaandaId: bdm.baandaId,
            showBillFromDetailFlag: true,
            billFromAddress: "",
            billFromName: "",
            billFromCell: "",
            billToBaandaId: bdm.baandaId,
            errFlag: false,
            errMsg: "",
            sendInviteToRegister: false,
          });
        } else {
          let bdmf = bdm.profileInfo;
          let bdma = bdm.profileInfo.address;
          this.setState({
            isCustomer: true, // If a valid baanda, they must be assumed to be a customer to raise an invoice.
            billFromAddress:
              bdma.street +
              ", " +
              bdma.city +
              " " +
              bdma.state +
              " " +
              bdma.postalCode,
            shipToAddress:
              bdma.street +
              ", " +
              bdma.city +
              " " +
              bdma.state +
              " " +
              bdma.postalCode,
            billFromName: bdmf.formalName,
            billFromCell: bdmf.cell.number,
            showBillFromDetailFlag: true,
            billToBaandaId: bdm.baandaId,
            line1: bdma.street,
            city: bdma.city,
            state: bdma.state,
            postalCode: bdma.postalCode,
            sendInviteToRegister: false,
          });
        }
      } else {
        this.setState({
          errMsg: baandaret.data.Msg,
          errFlag: true,
          showBillFromDetailFlag: false,
          sendInviteToRegister: false,
        });
      }
    } catch (err) {
      console.log("handleCheckifBaanda err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
        showBillFromDetailFlag: false,
        sendInviteToRegister: false,
      });
    }
  };

  exitTaskSselect = () => {
    this.setState({
      showTasksToInvoiceFlag: false,
    });
  };

  handleSendInvite = async () => {
    this.setState((prevstate) => ({
      sendInviteToRegister: !prevstate.sendInviteToRegister,
    }));
  };

  handleIsTaxable = async () => {
    this.setState((prevstate) => ({
      lineIsTaxable: !prevstate.lineIsTaxable,
    }));
  };

  handleConfirmToSubmit = async () => {
    this.setState((prevstate) => ({
      invConfirm: !prevstate.invConfirm,
    }));
  };

  sendAnInvite = async () => {
    let invitees = [];
    let inviteedObj = {
      inviteeEmail: this.state.billFromEmail,
      inviteeName: this.state.inviteeName,
    };
    invitees.push(inviteedObj);
    let input = {
      communityId: this.props.communityId,
      communityCaption: this.props.community.commCaption,
      inviteeType: "individual", // individual or group
      invitees,

      subject: this.state.subject,
      salute: this.state.salute,
      respect: this.state.respect,
      theletter: this.state.inviteLetter,
      invitorEmail: this.props.auth.user.email,
      invitorName: this.props.auth.user.name,
      baandaId: this.props.auth.user.baandaId,
      membershipFee: 0,
      offerValidTill: null,
      imageFile: null,
      clientProgram: "RegisterPayableInvoice.js",
      clientFunction: "sendAnInvite",
    };

    // console.log("input:", input);

    try {
      let url = baandaServer + sendInviteToJoinBaanda;
      let sendret = await axios.post(url, input);
      if (sendret.data.status === "success") {
        this.setState({
          errMsg:
            "An invite will be sent to the email you provided. Please circle back once the recipiant registers.",
          errFlag: false,
        });
        return { status: "success", Msg: "" };
      } else {
        this.setState({
          errMsg: sendret.data.Msg + ". Failed to send message.",
          errFlag: true,
        });
        return { status: "error", Msg: "" };
      }
    } catch (err) {
      console.log("Err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  handleSendInviteToJoinBaanda = async () => {
    if (await this.validateEmail(this.state.billFromEmail)) {
      // Please send an invite
      await this.sendAnInvite();
      this.setState({
        showBillFromDetailFlag: false,
        sendInviteToRegister: false,
      });
    } else {
      this.setState({
        errMsg: "Invalid email syntax. Please enter a valid email.",
        errFlag: false,
      });
    }
  };

  validateEmail = async (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return true;
    else return false;
  };

  validateInvoiceLines = async () => {
    let isValid = "success",
      msg = "";
    if (parseFloat(this.state.linePrice) === 0) {
      isValid = "error";
      msg = "Price is required";
    }
    if (parseFloat(this.state.lineQty) === 0) {
      isValid = "error";
      msg = " | Quantity cannot be 0";
    }
    if (this.state.lineDescription === "") {
      isValid = "error";
      msg = " | Line must have a description";
    }

    return { status: isValid, Msg: msg };
  };

  addAnInvoiceLine = async () => {
    let currLines = [...this.state.addedLineItems];

    let total = 0;
    let isval = await this.validateInvoiceLines();
    if (isval.status === "success") {
      let newLineObj = {
        description: this.state.lineDescription,
        price: parseFloat(this.state.linePrice),
        quantity: parseFloat(this.state.lineQty),
        unit: this.state.lineUnit,
        lineNo: this.state.lineNo,
      };
      currLines.push(newLineObj);

      currLines.forEach((elm) => {
        total +=
          parseFloat(this.state.linePrice) * parseFloat(this.state.lineQty);
      });

      let newLineNo = parseFloat(this.state.lineNo) + 1;

      let okToDraft = false;
      if (currLines.length > 0) okToDraft = true;

      this.setState({
        addedLineItems: currLines,
        total,
        okToDraft,
        lineNo: newLineNo,
        lineDescription: "",
        linePrice: 0.0,
        lineQty: 1,
        lineUnit: "",
        errMsg: "",
        errFlag: false,
        showFinalSubmit: false,
      });
    } else {
      this.setState({
        errMsg: isval.Msg,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleChangeTargetAddress = async () => {
    this.setState({
      showMainInvoiceCreation: false,
      showEditAddress: true,
    });
  };

  handlePayByDate = async (date) => {
    let byDate = await this.formatDate(date);
    await this.setState({
      payByDate: date,
      byDate,
    });
  };

  selectThisItemToDelete = async (obj) => {
    let subTotalNontaxable = 0;
    let subTotalTaxable = 0;
    let ln = 1;
    let adl = [];
    this.state.addedLineItems.forEach((elm) => {
      if (elm.lineNo !== obj.lineNo) {
        elm.lineNo = ln;
        adl.push(elm);
        ln++;
        if (elm.lineisTaxable) {
          subTotalTaxable += elm.linePrice * elm.lineQty;
        } else {
          subTotalNontaxable += elm.linePrice * elm.lineQty;
        }
      }
    });

    let lineNo = adl.length + 1;

    this.setState({
      addedLineItems: adl,
      subTotalNontaxable,
      subTotalTaxable,
      lineNo,
      errMsg: "",
      errFlag: false,
    });
  };

  dateFormat = async (date) => {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  };

  packageDataForDB = async (ask) => {
    let paySchedulePolicy = {
      installmentType: "",
      nextSchedulePayday: this.state.payByDate,
      nextPayAmount: parseFloat(this.state.total), // This will be the total amount
      lastReminderSentDate: null,
      numberOfInstallment: 0,
      numberOfInstallmentPaid: 0,
      nextPayBy: this.state.payByDate,
      invoiceTerms: this.state.invoiceTerms,
    };

    let shipToAddress = null;
    let spType = "na";
    let spRegularExists = false;
    let spRegularStatus = "na";
    let totalDeliveryStatus = "na";

    let spInfo = null;
    let manualInvoiceParams = null;
    let shippingAddress = "";

    let taxInfo = null;

    let financeBreakdown = {
      totalInvoiceAmount: this.state.total, // Should contain the invoice total
      amountPaid: 0,
      lastPaymentAmount: 0,
      lastPaymentDate: null,
      salesTaxAmount: 0, // Put the tax amount
      writeOffAmt: 0,
      shippingAmt: 0,
    };

    let invoiceType = "payable";

    let coopStoreId = 0;
    let coopStoreName = "";
    let coopStoreKeeper = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      coopStoreName = this.props.store.displayStoreName;
      coopStoreKeeper = this.props.store.displayStoreKeeper;
    }

    let invoiceTerms =
      this.state.invoiceTerms +
      " Invoice to be paid by (" +
      this.props.community.commName +
      " - " +
      coopStoreName +
      " to " +
      this.state.billFromName +
      " by " +
      this.state.byDate;

    let taskId = 0;

    let vendorReference = [];
    let vendorRefObj = {
      referenceType: "invoice",
      referenceId: this.state.vendorInvoiceNo,
      vendorAddress: this.state.billFromAddress,
    };

   
    vendorReference.push(vendorRefObj);

    let input = {
      invoiceOfId: this.state.billToBaandaId, // This will be target's / customer's baandaId
      invoiceId: this.state.invoiceId,
      invoiceCreationType: "manual",
      invoiceOfType: "individual",
      customerEmail: this.state.billFromEmail,
      customerName: this.state.billFromName,
      customerCell: this.state.billFromCell,
      communityId: this.props.community.communityId,
      coopStoreId,
      coopStoreName,
      coopStoreKeeper,
      commName: this.props.community.commName,
      invoiceDate: new Date(),
      invoiceType,
      financeBreakdown,
      paySchedule: true,
      paySchedulePolicy,
      manualEntryState: "", // To be filled in based if this was Draft (then wip) or Submit ( then posted)
      initPayMedium: "",
      vendorReference,
      handlingFlag: "",
      created_at: new Date(),
      manualInvoiceParams,
      itemDetails: this.state.addedLineItems,
      taxInfo,
      invoiceCategory: "vendorinvoice",
      opsType: "",

      shippingAddress,
      invoiceTerms,

      shipToAddress,
      spType,
      spRegularExists,
      spRegularStatus,
      totalDeliveryStatus,
      spInfo,

      updated_by_bid: this.props.auth.user.baandaId,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      clientProgram: "RegisterPayableInvoice.js",
      clientFunction: "packageDataForDB",
      isCustomer: this.state.isCustomer,
      taskId,
    };

    return input;
  };

  handleUpdate = async (ask) => {
 
    let input = await this.packageDataForDB(ask);
    input.manualEntryState = ask;
    input.handlingFlag = ask;
 
    let url = baandaServer + handlePayableInvoice;
 
    try {
      let retinv = await axios.post(url, input);
 
      if (retinv.data.status === "success") {
        // In case of Draft
        if (ask === "wip") {
          this.setState({
            errMsg: "Drafted successfully.",
            errFlag: false,
            invoiceId: retinv.data.Msg,
          });
        } else {
          this.setState({
            errMsg: "Invoice has been raised successfully.",
            errFlag: false,
          });
          await this.initializeInvoice();
        }
      } else {
        this.setState({
          errMsg: retinv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  returnToLiabilityMain = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("RegisterPayableInvoice...");

    let exitRegisterInvoice = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToLiabilityMain}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addLineButton = (
      <button
        className="btn_reg_60 manual-inv-add-pos"
        type="button"
        onClick={this.addAnInvoiceLine}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add <i className="fa fa-plus" />
      </button>
    );

    let checkIfBaandaButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handlecheckIfBaanda}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Fetch
      </button>
    );

    // let editAddresslButton = (
    //   <button
    //     className="btn_reg_70 manual-inv-edit-pos"
    //     type="button"
    //     onClick={this.handleChangeTargetAddress}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     Edit <i className="fa fa-edit"></i>
    //   </button>
    // );

    let invoiceSubmitButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={() => this.handleUpdate("post")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Submit
      </button>
    );

    let invoiceDraftButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={() => this.handleUpdate("wip")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Draft
      </button>
    );

    let sendInviteButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleSendInviteToJoinBaanda}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Invite
      </button>
    );

    // let invoiceTermButton = (
    //   <button
    //     className="btn_reg_60"
    //     type="button"
    //     onClick={this.handleCreateTerms}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //   >
    //     Terms
    //   </button>
    // );

    let opsTypePanel;
    opsTypePanel = (
      <div className="row rec-main-select-ops-row-x">
        <div className="col text-center radio-font-style">
          <strong>Act on&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="newinvoice"
                checked={this.state.actionType === "newinvoice"}
                onChange={this.handleOpsType}
              />{" "}
              New Invoice
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="wipinvoice"
                checked={this.state.actionType === "wipinvoice"}
                onChange={this.handleOpsType}
              />{" "}
              Work In progress
            </label>
          </div>
        </div>
      </div>
    );

    let vendorPanel;
    if (this.state.showBillFromDetailFlag) {
      vendorPanel = (
        <div className="row pay-inv-vend-row-pos">
          <div className="col-2 pay-inv-vend-lbl text-right">Vendor Ref#</div>
          <div className="col-4 text-left">
            <input
              name="vendorInvoiceNo"
              type="text"
              value={this.state.vendorInvoiceNo}
              onChange={this.onChange}
              size="25"
              maxLength="25"
              className="inv-pay-vend-no"
              placeholder=""
            />
          </div>
          <div className="col-2 text-right pay-inv-vend-lbl">Pay By</div>
          <div className="col-4 text-left ">
            <DatePicker
              selected={this.state.payByDate}
              onSelect={this.handlePayByDate}
              onChange={this.handlePayByDate}
              minDate={new Date()}
              name="payByDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      );
    }

    let aLineItemPanel;
    if (this.state.showBillFromDetailFlag) {
      aLineItemPanel = (
        <div className="pay-inv-lineitem-box">
          <div className="row inv-line-of-row">
            <div className="col-2 text-right inv-sl-lbl">Price</div>
            <div className="col-2 text-left inv-sl-lbl">
              <input
                name="linePrice"
                type="number"
                value={this.state.linePrice}
                onChange={this.onChangeCurrency}
                className="pay-invoice-numbers"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-2 text-right inv-sl-lbl">Qty</div>
            <div className="col-2 text-left inv-sl-lbl">
              <input
                name="lineQty"
                type="number"
                value={this.state.lineQty}
                onChange={this.onChangeCurrency}
                className="pay-invoice-numbers"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-2 text-right inv-sl-lbl">Unit</div>
            <div className="col-2 text-left inv-sl-lbl">
              <input
                name="lineUnit"
                type="text"
                value={this.state.lineUnit}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="inv-line-unit"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-line-of-row">
            <div className="col-2 text-right inv-sl-lbl">Describe</div>
            <div className="col-10 text-left inv-sl-lbl">
              <textarea
                name="lineDescription"
                type="text"
                value={this.state.lineDescription}
                onChange={this.onChange}
                rows="2"
                size="250"
                maxLength="250"
                className="inv-pay-desc"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-line-of-row1">
            <div className="col-2 text-right inv-sl-lbl">Line #</div>
            <div className="col-2 text-left inv-refid-lbl">
              {this.state.lineNo}
            </div>
            <div className="col-8 text-center inv-refid-lbl">
              {addLineButton}
            </div>
          </div>
        </div>
      );
    }

    let invLineBody;
    if (this.state.showBillFromDetailFlag) {
      let bool = true;
      invLineBody = (
        <div className="">
          <div className="row">
            <div className="col-1 text-center invoice-line-list-header">#</div>
            <div className="col-1 text-center invoice-line-list-header">
              Ops
            </div>
            <div className="col-5 text-left invoice-line-list-header">
              Description
            </div>
            <div className="col-3 text-left invoice-line-list-header">
              Price | Qty | Unit
            </div>
            <div className="col-2 text-right inv-pay-amount-head">
              Amount ${" "}
            </div>
          </div>
          <div className="invoice-pay-lineitem-box">
            {this.state.addedLineItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-pay-line-dr" : "row row-pay-line-lt"
                    }
                  >
                    <div className="col-1 text-left invoice-line-item-no">
                      {obj.lineNo}.
                    </div>
                    <div className="col-1 text-center">
                      <button
                        className="btn_exit_del inv_del_btn_pos"
                        type="button"
                        onClick={() => this.selectThisItemToDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-5 text-left invoice-line-item">
                      {" "}
                      {obj.description}
                    </div>
                    <div className="col-3 text-left invoice-line-item">
                      ${this.commaFormattedCurrency(obj.price)} |{" "}
                      {this.commaFormattedCurrency(obj.quantity)} | {obj.unit}
                    </div>
                    <div className="col-2 text-right invoice-line-item">
                      {this.commaFormattedCurrency(obj.price * obj.quantity)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let totalLinePanel;
    if (this.state.showBillFromDetailFlag) {
      totalLinePanel = (
        <div className="row total-line-pos">
          <div className="col-6 text-center sub-total-inv">&nbsp;</div>

          <div className="col-4 text-center sub-total-inv">Total</div>
          <div className="col-2 text-right inv-pay-inv-total">
            {this.commaFormattedCurrency(this.state.total)}
          </div>
        </div>
      );
    }

    let finalPanel;
    if (this.state.showBillFromDetailFlag) {
      finalPanel = (
        <div className="row inv-final-row-pos">
          {this.state.okToDraft ? (
            <div className="col-4 text-right inv-final-lbl">
              <Checkbox
                checked={this.state.invConfirm}
                onChange={this.handleConfirmToSubmit}
              />{" "}
              Confirm to submit (final)
            </div>
          ) : (
            <div className="col-4 text-right inv-final-lbl">&nbsp;</div>
          )}
          <div className="col-4 text-left inv-final-lbl">
            {this.state.invConfirm ? invoiceSubmitButton : null}
          </div>

          <div className="col-4 text-center inv-final-lbl">
            {this.state.okToDraft ? invoiceDraftButton : null}
          </div>
        </div>
      );
    }
    let inviteSetupPanel;
    if (this.state.sendInviteToRegister) {
      inviteSetupPanel = (
        <div className="">
          <div className="text-center new-inv-invite-head">
            Invite with a letter
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Subject</div>
            <div className="col-10 text-left">
              <input
                name="subject"
                type="text"
                value={this.state.subject}
                onChange={this.onChange}
                size="60"
                maxLength="60"
                className="inv-invite-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Salute</div>
            <div className="col-10 text-left">
              <input
                name="salute"
                type="text"
                value={this.state.salute}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="inv-invite-salute"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Name</div>
            <div className="col-10 text-left">
              <input
                name="inviteeName"
                type="text"
                value={this.state.inviteeName}
                onChange={this.onChange}
                size="60"
                maxLength="60"
                className="inv-invite-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Letter</div>
            <div className="col-10 text-left">
              <textarea
                name="inviteLetter"
                maxLength="1000"
                placeholder=""
                rows="4"
                wrap="hard"
                spellCheck="true"
                className="inv-invite-letter"
                onChange={this.onChange}
                value={this.state.inviteLetter}
                required
              />
            </div>
          </div>
          <div className="row inv-invite-let-row">
            <div className="col-2 text-right new-inv-invite-lbl">Respect</div>
            <div className="col-10 text-left">
              <input
                name="respect"
                type="text"
                value={this.state.respect}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="inv-invite-salute"
                placeholder=""
              />
            </div>
          </div>
          {sendInviteButton}
        </div>
      );
    }

    let billFromDetailPanel;
    if (this.state.showBillFromDetailFlag) {
      if (this.state.billToBaandaId === 0) {
        billFromDetailPanel = (
          <div>
            <div className="text-center invoice-not-a-baanda-msg">
              Plaease enter a valid and Baanda email. Baanda registration is
              needed to process the invoice and the follow up activities. When
              your recipiant registers in Baanda, you will be able to raise the
              invoice. For more information, please click the help 'i' button.
            </div>
            {inviteSetupPanel}
            <div className="text-center invoice-send-invite">
              <Checkbox
                checked={this.state.sendInviteToRegister}
                onChange={this.handleSendInvite}
              />{" "}
              Check to send an invite to join Baanda. &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        );
      } else {
        billFromDetailPanel = (
          <div>
            <div className="row">
              <div className="col-2 text-right manual-invoice-lbl">Name</div>
              <div className="col-6 text-left manual-invoice-lbl">
                {this.state.billFromName}
              </div>
              <div className="col-1 text-right manual-invoice-lbl">Cell</div>
              <div className="col-3 text-left manual-invoice-lbl">
                {this.formatPhoneNumber(this.state.billFromCell)}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right manual-invoice-lbl">Address</div>
              <div className="col-10 text-left manual-invoice-lbl">
                {this.state.billFromAddress}
                {/* {editAddresslButton} */}
              </div>
            </div>
            {vendorPanel}
            {aLineItemPanel}
            {invLineBody}
            {totalLinePanel}
            {finalPanel}
          </div>
        );
      }
    }

    let billFromPanel;
    billFromPanel = (
      <div>
        <div className="row">
          <div className="col-2 text-right manual-invoice-lbl">
            Bill From Email
          </div>
          {!this.state.toEdit ? (
            <div className="col-8 text-left manual-invoice-lbl">
              <input
                name="billFromEmail"
                type="text"
                value={this.state.billFromEmail}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-bill-to-email"
                placeholder="email..."
              />
            </div>
          ) : (
            <div className="col-8 text-left manual-invoice-lbl">
              {this.state.billFromEmail}
            </div>
          )}
          {!this.state.toEdit ? (
            <div className="col-2 text-left manual-invoice-lbl">
              {checkIfBaandaButton}
            </div>
          ) : null}
        </div>
      </div>
    );

    // let billToPanel;

    // let invCreatePanel;
    // invCreatePanel = (
    //   <div className="fixedsize-invoice-workarea-box">{billToPanel}</div>
    // );

    let outputPanel;
    if (this.state.showMainInvoiceFlag) {
      outputPanel = (
        <div className="">
          <div className="text-center ">
            <div className="row">
              <div className="col  text-center manual-inv-header">
                Manual Invoice Registration {helpModalButton}&nbsp;
                {exitRegisterInvoice}
              </div>
            </div>
            {opsTypePanel}
            {billFromPanel}
            {billFromDetailPanel}
            <div
              className={
                this.state.errFlag
                  ? "text-center invoice-entry-msg-err"
                  : "text-center invoice-entry-msg-xx"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.showWIPPanelFlag) {
      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.store.coopStoreId;
      outputPanel = (
        <div>
          <RegisterPIWIP
            coopStoreId={coopStoreId}
            communityId={this.props.community.communityId}
            returnToCaller={(wipinv) => this.returnFromRegisterPIWip(wipinv)}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

RegisterPayableInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterPayableInvoice)
);
