import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../../actions/modalActions";
import "../../../../../../../../modal/css/localModal.css";
import "../../../../../../../../modal/css/template.css";

import FormatCompEntry from "./FormatCompEntry";
 
import "./CommonIntro.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";

const readMeCode = "0000000000";

class CommonIntro extends Component {
  constructor(props) {
    super(props); 

    this.state = {
      deviceSize: "",

      introTitle: "",
      // introTitleFormat: null,
      introBody: "",
      // introBodyFormat: null, 

      connectType: "personal",
      salute: "",
      signoff: "",
      senderName: "",
      senderPosition: "",

      defaultTitleFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: 'left'
      },

      defaultBodyFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "11pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: 'left'
      },

      showFormatFlag: false,

      callingField: "",
      btnType: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = () => {
    let mc = this.props.theBroadcast.mailComposition;
    let introTitle = mc.introTitle;
    let introBody = mc.introBody;
    let connectType = "personal";
    let salute = mc.salute;
    let signoff = mc.signoff;
    let senderName = "";

    if (mc.senderName !== "") senderName = mc.senderName;
    else senderName = this.props.auth.user.name;

    let senderPosition = mc.senderPosition;

    let defaultTitleFormat = this.state.defaultTitleFormat;
    if (mc.introTitleFormat) defaultTitleFormat = mc.introTitleFormat;

    let defaultBodyFormat = this.state.defaultBodyFormat;
    if (mc.introBodyFormat) defaultBodyFormat = mc.introBodyFormat;

    this.setState({
      introTitle,
      introBody,
      connectType,
      salute,
      signoff,
      senderName,
      senderPosition,
      defaultTitleFormat,
      defaultBodyFormat,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    let fieldname = e.target.name;
    let errMsg = '';
    let errFlag = false;
    let setField = true;
    if ( fieldname === 'introBody' && this.props.theBroadcast.mailCompositionType === 'slider' ) {
      if ( e.target.value.length > 2000) {
        errMsg = "Slider Intro must be less than 2000 characters.";
        errFlag = true;
        setField = false;
      }
    }
    if ( setField) {
      this.setState({ 
        [e.target.name]: e.target.value, 
        errMsg,
        errFlag
      });
    } else {
      this.setState({
        errMsg,
        errFlag
      })
    }
    
  };

  handleCopnnectType = async (e) => {
    this.setState({
      connectType: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  handleTitleFormat = (type) => {
    // console.log("type:", type);
    let format;
    let btnType;
    if (type === "title") {
      format = this.state.titleFormat;
      btnType = "Intro";
    }
    if (type === "body") {
      format = this.state.writeupFormat;
      btnType = "Body";
    }

    this.setState({
      showFormatFlag: true,
      callingField: type,
      format,
      btnType,
    });
  };

  returnFromFormat = (newFormat) => {
    // console.log("newFormat:", newFormat);
    let defaultTitleFormat = this.state.defaultTitleFormat;
    let defaultBodyFormat = this.state.defaultBodyFormat;
    // console.log("this.state.btnType:", this.state.btnType);

    if (this.state.btnType === "Intro") {
      // console.log('in here 154')
      defaultTitleFormat = newFormat;  
    }
    if (this.state.btnType === "Body") {
      defaultBodyFormat = newFormat;
    }
    this.setState({
      showFormatFlag: false,
      defaultBodyFormat,
      defaultTitleFormat,
    });
  };

  packageInputForDB = () => {
    let input = {
      interactionType: this.state.connectType,
      salute: this.state.salute,
      signoff: this.state.signoff,
      senderName: this.state.senderName,
      senderPosition: this.state.senderPosition,
      introTitle: this.state.introTitle,
      introTitleFormat: this.state.defaultTitleFormat,
      introBody: this.state.introBody,
      introBodyFormat: this.state.defaultBodyFormat,
      communityId: this.props.communityId,
      broadcastId: this.props.broadcastId,
      templateType: this.props.selectedTemplate,
      updateType: "intro",
      clientProgram: "CommonIntro",
      clientFunction: "handleIntroSave",
    };

    return input;
  };  

  handleIntroSave = async () => {
    // console.log("inside uploadFileInDB");
    let input = this.packageInputForDB();
    let url = baandaServer + saveBroadcastWip;
    // console.log("### url:", url, " input:", input);
    try {
      let resp = await axios.post(url, input);
      // console.log("Upload resp:", resp);
      if (resp.data.status === "success") {
        // console.log("In here ... 999");
        this.setState({
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: resp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("uploadFileInDB:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CommonIntro...');

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let formatBtn1 = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={() => this.handleTitleFormat("title")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-paint-roller" />
      </button>
    );

    let formatBtn2 = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={() => this.handleTitleFormat("body")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-paint-roller" />
      </button>
    );

    let saveBtn = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleIntroSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let connectPanel;

    connectPanel = (
      <div className="text-center comp-intro-type-pos">
        <div className="row">
          <div className="col text-center mms-ops-type">
            <strong>Intro Type </strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="personal"
                  checked={this.state.connectType === "personal"}
                  onChange={this.handleCopnnectType}
                />{" "}
                Personalized
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="mass"
                  checked={this.state.connectType === "mass"}
                  onChange={this.handleCopnnectType}
                />{" "}
                Mass Mail
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let personalPanel;
    if (this.state.connectType === "personal") {
      personalPanel = (
        <div>
          <div className="row">
            <div className="col-2 text-right intro-comp-lbl">Greeting</div>
            <div className="col-4 text-left">
              <input
                name="salute"
                type="text"
                value={this.state.salute}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="comp-intro-salute"
                placeholder=""
              />{" "}
            </div>
            <div className="col-2 text-right intro-comp-lbl">Sign Off</div>
            <div className="col-4 text-left">
              <input
                name="signoff"
                type="text"
                value={this.state.signoff}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="comp-intro-salute"
                placeholder=""
              />{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right intro-comp-lbl">Sender Name</div>
            <div className="col-4 text-left">
              <input
                name="senderName"
                type="text"
                value={this.state.senderName}
                onChange={this.onChange}
                size="40"
                maxLength="40"
                className="comp-intro-salute"
                placeholder=""
              />{" "}
            </div>
            <div className="col-2 text-right intro-comp-lbl">
              Position (optional)
            </div>
            <div className="col-4 text-left">
              <input
                name="senderPosition"
                type="text"
                value={this.state.senderPosition}
                onChange={this.onChange}
                size="40"
                maxLength="40"
                className="comp-intro-salute"
                placeholder=""
              />{" "}
            </div>
          </div>
        </div>
      );
    }

    let bodyPanel;
    bodyPanel = (
      <div>
        <div className="row">
          <div className="col-2 intro-comp-lbl text-right">Intro Title</div>
          <div className="col-9 ">
            <input
              name="introTitle"
              type="text"
              value={this.state.introTitle}
              onChange={this.onChange}
              size="40"
              maxLength="40"
              className="comp-intro-title"
              placeholder=""
            />{" "}
          </div>
          <div className="col-1 text-left title-body-fm-btn1">
            {" "}
            {formatBtn1}
          </div>
        </div>
        <div className="row">
          <div className="col-2 intro-comp-lbl text-right">Intro Body</div>
          <div className="col-9 ">
            {" "}
            <textarea
              name="introBody"
              maxLength="2000"
              placeholder="Introduction in 2000 chars or less."
              rows="5"
              wrap="hard"
              spellCheck="true"
              className="intro_body_textarea"
              onChange={this.onChange}
              value={this.state.introBody}
              required
            />
          </div>
          <div className="col-1 text-left title-body-fm-btn2">
            {formatBtn2}{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-2 intro-comp-lbl text-right">&nbsp;</div>
          <div className="col-9 text-left title-body-char-count">
            Char count = {this.state.introBody.length}
          </div>
          <div className="col-1 text-left title-body-fm-btn2">&nbsp; </div>
        </div>
        <div className="text-center compo-intro-save-pos">{saveBtn}</div>
      </div>
    );

    let formatPanel;
    if (this.state.showFormatFlag) {
      let sendFormat;
      if ( this.state.callingField === 'title') sendFormat = this.state.defaultTitleFormat;
      if ( this.state.callingField === 'body') sendFormat = this.state.defaultBodyFormat;
      formatPanel = (
        <div className="">
          <FormatCompEntry
            callingField={this.state.callingField}
            format={sendFormat}
            selectedTemplate={this.props.selectedTemplate}
            returnToCaller={this.returnFromFormat}
          />
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center comp-intro-header">
          Configure Introduction &nbsp;&nbsp;{infoModalButton}
        </div>
        {connectPanel}
        {formatPanel}
        {personalPanel}
        {bodyPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center comp-basic-intro-msg-err"
              : "text-center comp-basic-intro-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CommonIntro.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommonIntro));
