import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import ReceiveCCForMeterSub from "../../../../account/finance/communities/ReceiveCCForMeterSub";

import "./SubscriptionSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBaandaDna = "/routes/admin/getBaandaDna?";
const getSubscriptionPrice = "/routes/architecture/getSubscriptionPrices?";
const setupMeterSubscription =
  "/routes/architecture/setupInitMeteredSubscription";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const saveBaandaSubscription = "/routes/architecture/saveBaandaSubscription?";

const memJoinServiceName = "communityJoinBaanda";
const monthlyRent = "monthlyCommunityMembership";
const usageStorageFee = "meteringForStorage";
const usageTrafficFee = "meteringForTraffic";

const baseStorageAllocation = process.env.REACT_APP_BASE_STORAGE_ALLOCATION;
// const baseTrafficAllocation = process.env.REACT_APP_BASE_TRAFFIC_ALLOCATION;
const agreementName = process.env.REACT_APP_MEMBERSHIP_AGREEMENT_NAME;
const productName = process.env.REACT_APP_METERED_SUBSCRIPTION_PRODUCT_NAME;
const productDescription =
  process.env.REACT_APP_METERED_SUBSCRIPTION_PRODUCT_DESCRIPTION;
const baandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1160000000";

class SubscriptionSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      baandaDna: null,
      membershipPrices: null,

      joinFee: 0,
      monthlyFee: 0,
      storageFeePerBlock: 0,
      trafficFeePerBlock: 0,
      storageBlockSize: 0,
      trafficBlockSize: 0,
      agreements: [],
      discountSelected: null,

      postDiscRegiFee: 0,
      postDiscMonthlyFee: 0,

      getCCPaymentFlag: false,
      showDisplayFlag: true,
      subscriptionErrFlag: false,
      subscriptionDoneFlag: false,

      inputData: null,

      errMsg: "",
      errFlag: false,

      iAgree: false,
      loadingFlag: false,
      community: null,

      selheight: 3,
      baandaStripeId: "",
      couponCode: "",
      selectedDiscount: null,
      discountPercent: 0,
      discountCreatedOn: null,
      discountForMonths: 0,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let baandaDna = await this.getBaandaDnaNow();

    let errMsg = "",
      errFlag = false;
    if (!baandaDna) {
      errMsg = "Baanda coreFile/dna not setup or network error.";
      errFlag = true;
    }

    let membershipPrices = await this.getMembershipFee();

    if (membershipPrices.length === 0) {
      errMsg += " Pricing not setup of network error.";
      errFlag = true;
    }

    if (errMsg !== "") errMsg += " Contact support@baanda.com";

    let custcomm = await this.getTheCommunityInfo(this.props.communityId);

    let joinFee = 0;
    let monthlyFee = 0;
    let storageFeePerBlock = 0;
    let storageBlockSize = 0;
    let trafficFeePerBlock = 0;
    let trafficBlockSize = 0;
    membershipPrices.forEach((obj) => {
      if (obj.serviceName === memJoinServiceName) {
        joinFee = parseFloat(obj.creditsCharged);
      }
      if (obj.serviceName === monthlyRent) {
        monthlyFee = parseFloat(obj.creditsCharged);
      }
      if (obj.serviceName === usageStorageFee) {
        storageFeePerBlock = parseFloat(obj.creditsCharged);
        storageBlockSize = parseFloat(obj.blockSize);
      }
      if (obj.serviceName === usageTrafficFee) {
        trafficFeePerBlock = parseFloat(obj.creditsCharged);
        trafficBlockSize = parseFloat(obj.blockSize);
      }
    });
    let agreements = [];

    if (this.state.baandaDna && this.state.baandaDna.agreements.length > 0) {
      this.state.baandaDna.agreements.forEach((obj) => {
        if (obj.agreementName === agreementName) {
          agreements = [...obj.agreementLines];
        }
      });
    }

    let subscriptionDoneFlag = custcomm.adminTaskStates.subscriptionSetup;

    let postDiscRegiFee = joinFee;
    let postDiscMonthlyFee = monthlyFee;

    await this.setState({
      deviceSize,
      joinFee,
      postDiscRegiFee,
      postDiscMonthlyFee,
      monthlyFee,
      storageFeePerBlock,
      trafficFeePerBlock,
      storageBlockSize,
      trafficBlockSize,
      agreements,
      subscriptionDoneFlag,
      community: custcomm,
      errFlag,
      errMsg,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  getTheCommunityInfo = async (communityId) => {
    try {
      let param = "communityId=" + communityId;
      let url = baandaServer + getCommunityInfo + param;
      let comm = await axios.get(url);
      if (comm.data.status === "success") {
        let newcomm = comm.data.Msg;

        if (communityId === baandaCommunityId) {
          await this.setState({
            baandaStripeId: comm.data.Msg.stripe.id,
          });
        }
        await this.setState({
          community: comm.data.Msg,
          errMsg: "",
          errFlag: false,
        });
        return newcomm;
      } else {
        await this.setState({
          errMsg: comm.data.Msg,
          errFlag: true,
        });
        return false;
      }
    } catch (err) {
      console.log("getTheCommunityInfo: err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      return false;
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  getBaandaDnaNow = async () => {
    let url = baandaServer + getBaandaDna;
    let baandaDna = null;
    try {
      let bdna = await axios.get(url);

      if (bdna.data.status === "success") {
        baandaDna = bdna.data.Msg;
        await this.setState({
          baandaDna: bdna.data.Msg,
        });
      }
    } catch (err) {
      console.log("getBaandaDna Err:", err.message);
    }

    return baandaDna;
  };

  getMembershipFee = async () => {
    let url = baandaServer + getSubscriptionPrice;
    let membershipPrices = [];
    try {
      let retmem = await axios.get(url);
      if (retmem.data.status === "success") {
        membershipPrices = retmem.data.Msg;
        await this.setState({
          membershipPrices: retmem.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          errMsg: retmem.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      console.log("getMembershipFee err:", err.message);
    }

    return membershipPrices;
  };

  handleAgreementConfirm = async () => {
    await this.setState((prevstate) => ({
      iAgree: !prevstate.iAgree,
      errMsg: ""
    }));
  };

  createInput = async () => {
    // Get baanda community
    let bc = await this.getTheCommunityInfo(baandaCommunityId);

    let thiscomm = await this.getTheCommunityInfo(this.props.communityId);

    let couponStripeId = "";
    let couponStripeObj = null;
    if (this.state.selectedDiscount) {
      couponStripeId = this.state.selectedDiscount.couponStripeId;
      couponStripeObj = this.state.selectedDiscount.couponStripeObj;
    }

    let price = null;
    let couponName = "";
    if (this.state.selectedDiscount) {
      price = {
        registrationFee: this.state.joinFee,
        monthlyFee: this.state.monthlyFee,
        regiCouponPercent: this.state.selectedDiscount.initiatePercent,
        monthlyCouponPercent: this.state.selectedDiscount.monthlyPercent,
        forMonths: this.state.selectedDiscount.forMonths,
        couponName: this.state.selectedDiscount.couponName,
        salesRep:
          this.state.selectedDiscount.salesPersonName +
          " (" +
          this.state.selectedDiscount.salesPersonEmail +
          " | Ph: " +
          this.state.selectedDiscount.salesPersonCell +
          ")",
        couponCreatedOn: this.state.selectedDiscount.createdOn.substring(0, 10),
      };
      couponName = this.state.selectedDiscount.couponName;
    } else {
      price = {
        registrationFee: this.state.joinFee,
        monthlyFee: this.state.monthlyFee,
        regiCouponPercent: 0,
        monthlyCouponPercent: 0,
        forMonths: 0,
        couponName: "",
        salesRep: "",
        couponCreatedOn: "",
      };
    }
    let input = {
      customerCommunityId: this.props.communityId,
      customerCommunityName: this.props.community.commName,
      customerBaandaId: this.props.auth.user.baandaId,
      customerEmail: this.props.auth.user.email,
      customerName: this.props.auth.user.name,
      customerCell: this.props.auth.user.cell.number,
      couponStripeId,
      couponStripeObj,
      couponName,
      itemName:
        this.props.community.commName +
        " (community) is subscribing for Baanda Platform (rent). ",
      price,
      productName,
      productDescription,
      finYYYY: bc.finYYYY,
      finMMDD: bc.finMMDD,
      sellerCommunityId: parseFloat(baandaCommunityId),
      sellerCommunityName: bc.commName,
      customerFinYYYY: thiscomm.finYYYY,
      customerFinMMDD: thiscomm.finMMDD,
      paymentMedium: "creditcard",
      description:
        "Community " +
        this.props.communityName +
        "(id: " +
        this.props.communityId +
        ") is subscribing to Baanda membership.",
      clientProgram: "SubscriptionSetup.js",
      clientFunction: "handleSetup",
      baandaStripeId: this.state.baandaStripeId,
      discountPercent: this.state.discountPercent,
      discountCreatedOn: this.state.discountCreatedOn,
      discountForMonths: this.state.discountForMonths,
    };

    return input;
  };

  handleDiscountSelected = async () => {
    let postregi =
      parseFloat(this.state.joinFee) -
      parseFloat(this.state.joinFee) *
        (this.state.selectedDiscount.monthlyPercent / 100);

    let postmonth =
      parseFloat(this.state.monthlyFee) -
      parseFloat(this.state.monthlyFee) *
        (this.state.selectedDiscount.monthlyPercent / 100);

    await this.setState({
      postDiscRegiFee: postregi,
      postDiscMonthlyFee: postmonth,
    });
  };

  dateLessThanToday = (dt) => {
    let today = new Date().getTime();
    let compDt = new Date(dt).getTime();

    if (compDt > today) return true;
    else return false;
  };

  checkCoupon = async () => {
    let isValid = false;
    let selectedDiscount = null;
    let discountPercent = 0;
    let discountCreatedOn = null;
    let discountForMonths = 0;
    let msg = "Invalid coupon.";
    for (var i = 0; i < this.state.baandaDna.discounts.length; i++) {
      if (
        this.state.baandaDna.discounts[i].couponName === this.state.couponCode
      ) {
        isValid = true;
        selectedDiscount = this.state.baandaDna.discounts[i];
        discountPercent = this.state.baandaDna.discounts[i].monthlyPercent;
        discountCreatedOn = this.state.baandaDna.discounts[i].createdOn;
        discountForMonths = this.state.baandaDna.discounts[i].forMonths;
      }
    }
    if (isValid) {
      await this.setState({
        selectedDiscount,
        errMsg: "Please check and click to agree.",
        errFlag: false,
        iAgree: false,
        discountPercent,
        discountCreatedOn,
        discountForMonths,
      });
      await this.handleDiscountSelected();
    } else {
      this.setState({
        errMsg: msg,
        errFlag: true,
        selectedDiscount: null,
      });
    }
  };

  handleSetup = async () => {
    this.setState({
      loadingFlag: true,
      errMsg: "",
      errFlag: false,
    });
    if (parseFloat(this.props.communityId) !== parseFloat(baandaCommunityId)) {
      let url = baandaServer + setupMeterSubscription;
      let input = await this.createInput();

      try {
        let retsub = await axios.post(url, input);
        if (retsub.data.status === "success") {
          // Enable the acceptance of cc payment.
          this.setState({
            getCCPaymentFlag: true,
            showDisplayFlag: false,
            ccInfo: retsub.data.Msg,
            inputData: {
              clientSecret:
                retsub.data.Msg.ccInfo.subscription.latest_invoice
                  .payment_intent.client_secret,
              input,
              ccInfo: retsub.data.Msg.ccInfo,
              transactionId: retsub.data.Msg.transactionId,
              postDiscMonthlyFee:
                Math.round(this.state.postDiscMonthlyFee * 100) / 100,
              postDiscRegiFee:
                Math.round(this.state.postDiscRegiFee * 100) / 100,
            },
          });
        } else {
          this.setState({
            getCCPaymentFlag: false,
            showDisplayFlag: true,
            errMsg: retsub.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handleSetup err:", err.message);
        this.setState({
          getCCPaymentFlag: false,
          showDisplayFlag: true,
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      let url = baandaServer + saveBaandaSubscription;
      let input = { communityId: parseFloat(baandaCommunityId) };
      try {
        let bret = await axios.post(url, input);
        if (bret.data.status === "success") {
          this.setState({
            errMsg: "Updated subscription flag",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: bret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    }

    await this.setState({ loadingFlag: false });
  };

  handleBackFromPayment = async (data) => {
    // console.log("handleBackFromPayment data:", data);
    // we flip back here.
    let msg = "";
    let errFlag = false;
    let subscriptionErrFlag = false;
    let subscriptionDoneFlag = false;
    if (data === "success") {
      msg = "Payment received and receipt in mail. Published successfully.";
      subscriptionDoneFlag = true;
    } else if (data === "error") {
      msg =
        "Payment Error. Plese send a mail to support@baanda.com with communityId = " +
        this.props.communityId;
      subscriptionErrFlag = true;
      errFlag = true;
    } else {
      msg = "Payment is canceled. Community is not ready for business.";
      errFlag = true;
    }
    await this.setState({
      getCCPaymentFlag: false,
      showDisplayFlag: true,
      publishMsg: msg,
      subscriptionErrFlag,
      subscriptionDoneFlag,
      errMsg: msg,
      errFlag,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("SubscriptionSetup...");

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div className="subscription-spinner-pos text-center">
          <ReactLoading
            type={"spokes"}
            color={"#1f3d6b"}
            height={30}
            width={30}
          />
        </div>
      );
    }

    let subModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let checkCouponButton = (
      <button
        className="btn_reg_140"
        type="button"
        onClick={this.checkCoupon}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-check" /> Check Coupon
      </button>
    );

    let buttonPanel;

    buttonPanel = (
      <div className="subscription-button-row">
        {this.state.iAgree &&
        (this.state.selectedDiscount || this.state.couponCode === "") ? (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleSetup}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Setup
          </button>
        ) : null}
        {subModalButton}
      </div>
    );

    let rulesPanel;

    rulesPanel = (
      <div className="fixedsize_rules_box">
        <div className="text-center rules-of-eng">Rules of Membership</div>
        <ul>
          {this.state.agreements.map((obj, i) => {
            return (
              <div key={i}>
                <div className="text-left">
                  <li>
                    <p align="justify" className="admin-rules-present">
                      {obj.text}
                    </p>
                  </li>
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    );

    // let discountlist;
    // if (this.state.baandaDna) {
    //   discountlist = this.state.baandaDna.discounts.map((obj, i) => {
    //     return (
    //       <option key={i} value={JSON.stringify(obj)}>
    //         Registration {obj.initiatePercent}% & Monthly {obj.monthlyPercent}%
    //         for {obj.forMonths} months
    //       </option>
    //     );
    //   });
    // }

    let discountPanel;
    discountPanel = (
      <div>
        <div className="row subscription-coupon-row">
          <div className="col-5 text-right search-lbl">Coupon Name</div>
          <div className="col-7 text-left">
            <input
              name="couponCode"
              type="text"
              value={this.state.couponCode}
              onChange={this.onChange}
              // size="14"
              maxLength="20"
              className="coupon-code-txt"
              placeholder=""
            />
          </div>
        </div>
        <div className="coupon-message-row text-center">
          Enter coupon code for discounts
        </div>
        <div className="text-center">{checkCouponButton} </div>
      </div>
    );
   

    let dispNOpsPanel;
    if (parseFloat(this.props.communityId) !== parseFloat(baandaCommunityId)) {
      dispNOpsPanel = (
        <div>
          <div className=" text-center rent-pricing-head">
            <u>Price, Discount, & Ops</u>
          </div>
          <div className="row">
            <div className="col-5 text-right rent-msg-lbl">
              Registration Fee
            </div>
            <div className="col-7 text-left rent-msg-text">
              ${this.commaFormattedCurrency(this.state.joinFee)}
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-right rent-msg-lbl">Monthly Fee</div>
            <div className="col-7 text-left rent-msg-text">
              ${this.commaFormattedCurrency(this.state.monthlyFee)}
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-right rent-msg-lbl">Storage Cost</div>
            <div className="col-7 text-left rent-msg-text">
              Free up to {baseStorageAllocation}MB,&nbsp;$
              {this.commaFormattedCurrency(this.state.storageFeePerBlock)}/every
              additional {this.state.storageBlockSize}MB monthly
            </div>
          </div>
          {discountPanel}
          <div className="row">
            <div className="col  text-right rent-msg-lbl">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-5 text-right rent-msg-lbl">Coupon Used</div>
            <div className="col-7 text-left rent-msg-text">
              {this.state.selectedDiscount
                ? this.state.selectedDiscount.couponName
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-right rent-msg-lbl">Reg. To Pay</div>
            <div className="col-7 text-left rent-msg-text">
              ${this.commaFormattedCurrency(this.state.postDiscRegiFee)}
            </div>
          </div>
          {this.state.selectedDiscount ? (
            <div className="row">
              <div className="col-5 text-right rent-msg-lbl">Monthly Fee</div>
              <div className="col-7 text-left rent-msg-text">
                ${this.commaFormattedCurrency(this.state.postDiscMonthlyFee)}
                &nbsp;for first {this.state.selectedDiscount.forMonths} months.
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-5 text-right rent-msg-lbl">
                Monthly To Pay
              </div>
              <div className="col-7 text-left rent-msg-text">
                ${this.commaFormattedCurrency(this.state.postDiscMonthlyFee)}{" "}
                per month
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-6 text-right rent-agreement-checkbox">
              <Checkbox
                checked={this.state.iAgree}
                onChange={this.handleAgreementConfirm}
              />{" "}
              I Agree to abide
            </div>
            <div className="col-6 text-left">{buttonPanel}</div>
          </div>
          <div className="text-center">{uploadingSpin}</div>
          <div
            className={
              this.state.errFlag
                ? "text-center sub-meter-msg-err"
                : "text-center sub-meter-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    } else {
      dispNOpsPanel = (
        <div>
          Please click Setup. Baanda Office do not pay rent.
          <div className="row">
            <div className="col-6 text-right rent-agreement-checkbox">
              <Checkbox
                checked={this.state.iAgree}
                onChange={this.handleAgreementConfirm}
              />{" "}
              I Agree to abide
            </div>
            <div className="col-6 text-left">{buttonPanel}</div>
          </div>
          <div className="text-center">{uploadingSpin}</div>
          <div
            className={
              this.state.errFlag
                ? "text-center sub-meter-msg-err"
                : "text-center sub-meter-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    let displayPanel;
    if (!this.state.subscriptionDoneFlag) {
      displayPanel = (
        <div className="">
          <div className="row">
            <div className="col-md-6">{rulesPanel}</div>
            <div className="col-md-6">{dispNOpsPanel}</div>
          </div>
        </div>
      );
    } else {
      displayPanel = (
        <div className="">
          <div className="row">
            <div className="col text-center payment-success">
              Payment has been received and subscription has been set up. Please
              check your email for your receipt. You can also find your receipt
              in the report center (under the Operate button). You are ready for
              the next step of the business/community setup. If not sure,
              contact Baanda support.
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showDisplayFlag) {
      outputPanel = (
        <div className="business-subscription-box">
          <div className="text-center subscription-header">
            Monthly Rent/Subscription Setup
          </div>
          {displayPanel}
        </div>
      );
    }

    if (this.state.getCCPaymentFlag) {
      outputPanel = (
        <div>
          <ReceiveCCForMeterSub
            inputData={this.state.inputData}
            deviceSize={this.state.deviceSize}
            handleExit={this.handleBackFromPayment}
            caller="initsubscription"
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SubscriptionSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionSetup));
