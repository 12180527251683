import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
// import ReactLoading from "react-loading";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import LoadFileToS3 from "../../../../../utils/components/fileUpload/LoadFileToS3";

import noImageAvailable from "../../image/notavailable.png";

import "./CatalogAdmin.css";

import { arraySortNum } from "../../../../../utils/sortArrayOfObjectsNum";

import SubscriptionMgmt from "./SubscriptionMgmt";
import ManageDiscount from "../discount/ManageDiscount";
import TaxSetup from "./TaxSetup";
import ShippingMgmt from "./ShippingMgmt";
import ServicesMgmt from "./ServicesMgmt";
import PackageDimension from "../goods/PackageDimension";
import CustomServiceTagging from "../services/CustomServiceTagging";
import TeamGameSetup from "../teamGame/TeamGameSetup";
import SubscriptionPkgPlan from "../pkgPlan/SubscriptionPkgPlan";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnItemFromIM = "/routes/dashboard/getAnItem?";
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";
const getACatalogItem = "/routes/dashboard/getACatalogItem?";

const picTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const videoTypes = ["mp4", "webm"];

const maxPicSize = 52428800; // 50MB
const maxVideoSize = 524288000; // 500mb

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1112510000";

class CatalogAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      adminsterFlag: true,

      errMsg: "",
      errFlag: false,
      lockErrFlag: false,
      item: null,
      catalog: null,

      videoS3FileData: null,
      tempVideoS3FileData: null,
      picS3FileData: null,
      tempPicS3FileData: null,
      currImageSeqno: 1,
      subDirName: "",
      imageS3FileData: [],

      showImageButton: false,
      showVideoButton: true,
      showImageFlag: true,
      showVideoFlag: false,
      noDGAsset: false,
      showGeneralFlag: true, // To show the general catalog panel
      digitalS3FileData: [],

      leftSlideFlag: false,
      rightSlideFlag: false,
      newImageOk: true,
      editImageOk: false,

      uploadOpsMode: "",

      doneMsg: "",
      doneMsgFlag: false,
      countImage: 0,

      itemDescription: "",
      itemPrice: 0.0,

      // Subscription
      manageSubscription: false,
      manageDiscounts: false,
      showSubscriptionFlag: false,
      showDiscountFlag: false,
      // ------------------
      manageTax: false,
      showTaxFlag: false,
      manageShipping: false,

      showShippingFlag: false,
      packageDimension: false,
      showDimensionFlag: false,
      manageSubItems: false,
      showSubItemFlag: false,
      manageServices: false,
      willBeGrouped: false,
      showServicesFlag: false,
      manageGameSetup: false,
      showGameSetupFlag: false,
      managePkgPlan: false,
      showPkgPlanFlag: false,

      manageCustomTagging: false,
      showCustomTagFlag: false,

      isPublished: false,
      isUnpublished: false,
      itemExposure: "public",
      searchWords: "",

      servicePricingType: "now",
      adjustOfDASize: 0,
      totalCatalogQty: 0,
      totalIMQty: 0,

      numberOfPkgPlanned: 0,
      numberOfPkgSubscribed: 0,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let errMsg = "";
    let lockErrFlag = false;
    let adminsterFlag = true;
    let picS3FileData = null;
    let videoS3FileData = null;
    let rightSlideFlag = false;
    let countImage = 0;
    let showImageFlag = false;
    let showVideoFlag = false;
    let noDGAsset = false;
    let imageS3FileData = [];
    let willBeGrouped = false;

    if (this.props.catalogItem.catobj.locked) {
      errMsg =
        "This item is locked by another manager. Please plan within your team for only one person can manage an item at a time.";
      lockErrFlag = true;
      adminsterFlag = false;
      this.setState({
        lockErrFlag,
        adminsterFlag,
        errMsg,
        errFlag: true,
      });
    } else {
      await this.getAnItemFromItemMaster(this.props.catalogItem.catobj.itemId);
      let firstImageLoaded = false;
      // let propCatFileUploads = [...this.props.catalogItem.catobj.fileUploads];
      // console.log("&&& propCatFileUploads:", propCatFileUploads);
      if (this.props.catalogItem.catobj.fileUploads.length > 0) {
        this.props.catalogItem.catobj.fileUploads.forEach((obj) => {
          // if (obj.type === "image" && obj.seqno === 1) {
          //   picS3FileData = obj;
          // }
          if (obj.type === "image") {
            if (!firstImageLoaded) {
              picS3FileData = obj;
              firstImageLoaded = true;
            }
            imageS3FileData.push(JSON.parse(JSON.stringify(obj)));
            countImage++;
            showImageFlag = true;
          }
          if (obj.type === "video") {
            videoS3FileData = obj;
          }
        });
      }
      if (countImage > 1) rightSlideFlag = true;
      if (!showImageFlag && videoS3FileData) showVideoFlag = true;
      if (!showImageFlag && !showVideoFlag) noDGAsset = true;
      // }

      if (this.props.catalogItem.catobj.autoGroupingRequired)
        willBeGrouped = this.props.catalogItem.catobj.autoGroupingRequired;

      if (countImage > 0) {
        let seqNo = 1;
        imageS3FileData.forEach((isd) => {
          isd.seqno = seqNo;
          seqNo++;
        });
      }

      let editImageOk = false;
      if (countImage >= 1) editImageOk = true;

      let itemDescription = "";

      if (this.props.catalogItem.catobj.itemDescription !== null)
        itemDescription = this.props.catalogItem.catobj.itemDescription;

      let totalCatalogQty = 0;
      this.props.catalogItem.catobj.itemVariance.forEach((elm) => {
        totalCatalogQty = totalCatalogQty + parseFloat(elm.quantity);
      });

      let totalIMQty = 0;
      if (this.state.item) {
        this.state.item.itemVariance.forEach((elm) => {
          totalIMQty = totalIMQty + parseFloat(elm.quantity);
        });
      }

      let servicePricingType = "now";
      if (this.props.catalogItem.catobj.service)
        servicePricingType =
          this.props.catalogItem.catobj.service.servicePricingType;

      await this.getAnItemFromCatalog();

      // Calculate planned pkgs / subscribed
      if (this.state.catalog && this.state.catalog.pkgComposedFlag)
        await this.calculatePkgPlanSub();

      this.setState({
        deviceSize,
        errMsg,
        lockErrFlag,
        adminsterFlag,
        subDirName: this.props.catalogItem.catobj.s3subBucket,
        picS3FileData,
        imageS3FileData,
        tempPicS3FileData: picS3FileData,
        videoS3FileData,
        tempVideoS3FileData: videoS3FileData,
        digitalS3FileData: this.props.catalogItem.catobj.fileUploads,
        rightSlideFlag,
        countImage,
        editImageOk,
        showImageFlag,
        showVideoFlag,
        noDGAsset,
        itemDescription,
        itemPrice: this.props.catalogItem.catobj.itemPrice,
        searchWords: this.props.catalogItem.catobj.searchWords,
        itemExposure: this.props.catalogItem.catobj.itemExposure,
        isPublished: this.props.catalogItem.catobj.isPublished,
        totalCatalogQty,
        totalIMQty,
        servicePricingType,
        willBeGrouped,
      });
    }
  };

  calculatePkgPlanSub = async () => {
    let numberOfPkgPlanned = 0;
    let numberOfPkgSubscribed = 0;
    // this.state.catalog.pkgSubscriptions.forEach((pln) => {
    //   numberOfPkgPlanned++;
    // });
    // this.state.catalog.pkgPlan.forEach((sbr) => {
    //   numberOfPkgSubscribed++;
    // });
    if (this.state.catalog && this.state.catalog.pkgInventoryCtrl) {
      if (
        this.state.catalog.pkgComposedFlag &&
        this.state.catalog.pkgInventoryCtrl.length > 0
      ) {
        numberOfPkgPlanned = this.state.catalog.pkgInventoryCtrl[0].pkgsOnRack;
        numberOfPkgSubscribed =
          this.state.catalog.pkgInventoryCtrl[0].subscribed;
      }
    }

    this.setState({
      numberOfPkgPlanned,
      numberOfPkgSubscribed,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getAnItemFromCatalog = async () => {
    try {
      let param = "itemId=" + this.props.catalogItem.catobj.itemId;
      let url = baandaServer + getACatalogItem + param;
      let catret = await axios.get(url);
      if (catret.data.status === "success") {
        this.setState({
          catalog: catret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: "Error: (retrieving catalog) " + catret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getAnItemFromItemMaster = async (itemId) => {
    // let coopStoreId = 0;
    // if ( this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "requestBy=itemId&itemId=" +
      itemId +
      "&clientProgram=AnItemsInventory&clientFunction=getAnItemFromItemMaster";
    try {
      let url = baandaServer + getAnItemFromIM + param;
      // console.log("getAnItemFromItemMaster url:", url);
      let itret = await axios.get(url);
      // console.log("itret:", itret);
      if (itret.data.status === "success") {
        await this.setState({
          errorPanelFlag: false,
          errorMsg: "",
          item: itret.data.Msg[0],
        });
      } else {
        await this.setState({
          errorPanelFlag: true,
          errorMsg: itret.Msg,
        });
      }
    } catch (err) {
      console.log("getAnItemFromItemMaster err:", err.message);
      await this.setState({
        errorPanelFlag: true,
        errorMsg: err.message,
      });
    }
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
      doneMsg: "",
      doneMsgFlag: false,
    });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        doneMsg: "",
        doneMsgFlag: false,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        doneMsg: "",
        doneMsgFlag: false,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  // Special Ops Section ---------
  handleSubscription = async () => {
    let showSubscriptionFlag = false;
    let showGeneralFlag = false;
    if (!this.state.manageSubscription) showSubscriptionFlag = true;
    if (this.state.manageSubscription) showGeneralFlag = true;
    this.setState((prevstate) => ({
      manageSubscription: !prevstate.manageSubscription,
      showSubscriptionFlag,
      showDiscountFlag: false,
      showDimensionFlag: false,
      showGeneralFlag,
      doneMsg: "",
      doneMsgFlag: false,
    }));
  };

  returnFromSubscription = async (retcat) => {
    // console.log("returnFromSubscription retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      showSubscriptionFlag: false,
      showGeneralFlag: true,
      showDimensionFlag: false,
      manageSubscription: false,
      // catalog: retcat,
    });
  };

  returnFromDiscount = async (retcat) => {
    // console.log("returnFromDiscount retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      showDiscountFlag: false,
      showGeneralFlag: true,
      showDimensionFlag: false,
      manageDiscounts: false,
      // catalog: retcat,
    });
  };

  handleDiscounts = async () => {
    let showDiscountFlag = false;
    let showGeneralFlag = false;
    if (!this.state.manageDiscounts) showDiscountFlag = true;
    if (this.state.manageDiscounts) showGeneralFlag = true;
    this.setState((prevstate) => ({
      manageDiscounts: !prevstate.manageDiscounts,
      showDiscountFlag,
      showSubscriptionFlag: false,
      showTaxFlag: false,
      showShippingFlag: false,
      showDimensionFlag: false,
      showGeneralFlag,
      doneMsg: "",
      doneMsgFlag: false,
    }));
  };

  handleSalesTax = async () => {
    let showGeneralFlag = false;
    let showTaxFlag = false;
    if (!this.state.manageTax) showTaxFlag = true;
    if (this.state.manageTax) showGeneralFlag = true;
    this.setState((prevstate) => ({
      manageTax: !prevstate.manageTax,
      showTaxFlag,
      showDiscountFlag: false,
      showSubscriptionFlag: false,
      showShippingFlag: false,
      showDimensionFlag: false,
      showGeneralFlag,
      doneMsg: "",
      doneMsgFlag: false,
    }));
  };

  returnFromTaxSetup = async (retcat) => {
    // console.log("returnFromTaxSetup retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      showDiscountFlag: false,
      showSubscriptionFlag: false,
      showTaxFlag: false,
      showShippingFlag: false,
      showDimensionFlag: false,
      showGeneralFlag: true,
      // catalog: retcat,
      manageTax: false,
    });
  };

  handleShipping = async () => {
    // console.log("in shipping");
    let showGeneralFlag = false;
    let showShippingFlag = false;
    if (!this.state.manageShipping) showShippingFlag = true;
    if (this.state.manageShipping) showGeneralFlag = true;
    this.setState((prevstate) => ({
      manageShipping: !prevstate.manageShipping,
      showTaxFlag: false,
      showDiscountFlag: false,
      showSubscriptionFlag: false,
      showDimensionFlag: false,

      showShippingFlag,
      showGeneralFlag,
      doneMsg: "",
      doneMsgFlag: false,
    }));
  };

  handlePkgPlan = async () => {
    // console.log("in pkgplan");
    let showGeneralFlag = false;
    let showPkgPlanFlag = false;
    if (!this.state.managePkgPlan) showPkgPlanFlag = true;
    if (this.state.managePkgPlan) showGeneralFlag = true;
    this.setState((prevstate) => ({
      managePkgPlan: !prevstate.managePkgPlan,
      showPkgPlanFlag,
      showGeneralFlag,
      doneMsg: "",
      doneMsgFlag: false,
    }));
  };

  returnFromPkgPlan = async (retcat) => {
    // console.log("returnFromPkgPlan retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      managePkgPlan: false,
      showPkgPlanFlag: false,
      showShippingFlag: false,
      showGeneralFlag: true,
      // catalog: retcat,
    });
  };

  returnFromShippingSetup = async (retcat) => {
    // console.log("returnFromShippingSetup retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      manageShipping: false,
      showShippingFlag: false,
      showGeneralFlag: true,
      // catalog: retcat,
    });
  };

  handleGameSetup = async () => {
    let showGameSetupFlag = false;
    let showGeneralFlag = false;
    if (!this.state.manageGameSetup) showGameSetupFlag = true;
    if (this.state.manageGameSetup) showGeneralFlag = true;
    this.setState((prevstate) => ({
      manageGameSetup: !prevstate.manageGameSetup,
      showGameSetupFlag,
      showGeneralFlag,
    }));
  };

  returnFromGameSetup = async (retcat) => {
    // console.log("returing from team game setup ...");
    await this.getAnItemFromCatalog();
    this.setState({
      manageGameSetup: false,
      showGameSetupFlag: false,
      showGeneralFlag: true,
      // catalog: retcat,
    });
  };

  handleDimension = async () => {
    // console.log("in shipping");
    let showGeneralFlag = false;
    let showDimensionFlag = false;
    if (!this.state.packageDimension) showDimensionFlag = true;
    if (this.state.packageDimension) showGeneralFlag = true;
    await this.setState((prevstate) => ({
      packageDimension: !prevstate.packageDimension,
      showTaxFlag: false,
      showDiscountFlag: false,
      showSubscriptionFlag: false,
      showDimensionFlag,
      showGeneralFlag,
      doneMsg: "",
      doneMsgFlag: false,
    }));
  };

  returnFromDimensionSetup = async (retcat) => {
    // console.log("returnFromDimensionSetup retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      packageDimension: false,
      showDimensionFlag: false,
      showGeneralFlag: true,
      // catalog: retcat,
    });
  };

  handleServices = async () => {
    // console.log("in Services");
    let showGeneralFlag = false;
    let showServicesFlag = false;
    if (!this.state.manageServices) showServicesFlag = true;
    if (this.state.manageServices) showGeneralFlag = true;
    this.setState((prevstate) => ({
      manageServices: !prevstate.manageServices,
      showTaxFlag: false,
      showDiscountFlag: false,
      showSubscriptionFlag: false,
      showShippingFlag: false,
      // showSubItemFlag: false,
      showServicesFlag,
      showGeneralFlag,
    }));
  };

  handleGrouping = async () => {
    this.setState((prevstate) => ({
      willBeGrouped: !prevstate.willBeGrouped,
    }));
  };

  returnFromServicesSetup = async (retcat) => {
    // console.log("returnFromShippingSetup retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      showServicesFlag: false,
      showGeneralFlag: true,
      manageServices: false,
      // catalog: retcat,
    });
  };

  handleCustomTagging = async () => {
    // console.log("in Services");
    let showGeneralFlag = false;
    let showCustomTagFlag = false;
    if (!this.state.manageCustomTagging) showCustomTagFlag = true;
    if (this.state.manageCustomTagging) showGeneralFlag = true;
    await this.setState((prevstate) => ({
      manageCustomTagging: !prevstate.manageCustomTagging,
      showTaxFlag: false,
      showDiscountFlag: false,
      showSubscriptionFlag: false,
      showShippingFlag: false,
      showCustomTagFlag,
      showGeneralFlag,
    }));
  };

  returnFromServiceTagging = async (retcat) => {
    // console.log("returnFromServiceTagging retcat:", retcat);
    await this.getAnItemFromCatalog();
    this.setState({
      showCustomTagFlag: false,
      showGeneralFlag: true,
      // catalog: retcat,
      manageCustomTagging: false,
    });
  };

  // ------------------------------

  catalogSubDirectory = async () => {
    // console.log("catalogSubDirectory");
    let randomct, commrn, subBucket;
    if (this.state.subDirName === "" || this.state.subDirName === null) {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "catalog-cid" +
        this.props.communityId +
        "-itemId" +
        this.state.catalog.itemId +
        "-image-" +
        commrn;
    } else subBucket = this.state.subDirName;

    // console.log("subBucket:", subBucket);
    await this.setState({
      subDirName: subBucket,
    });
    // console.log("this.state.subDirName");
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  exitCatalogAdmin = () => {
    this.props.returnToCaller();
  };

  handleShowVideoFlag = async () => {
    // console.log("handleShowVideoFlag");
    // Get the right object from fileUploads to picS3FileData

    await this.setState({
      uploadOpsMode: "",
      adminsterFlag: false,
      attachPicFlag: false,
      attachVideoFlag: true,
      showVideoFlag: true,
      handleDigitalAsset: true,
      showImageButton: true,
      showVideoButton: false,
      showImageFlag: false,
      // showVideoFlag: false,
      leftSlideFlag: false,
      rightSlideFlag: false,
      newImageOk: false,
      editImageOk: false,
    });
  };

  handleShowImageFlag = async () => {
    let rightSlideFlag = false;
    let editImageOk = false;
    let count = 0;
    let picS3FileData = null;
    let currImageSeqno = 1;
    this.state.digitalS3FileData.forEach((obj) => {
      if (obj.type === "image") count++;
      if (obj.seqno === 1) {
        picS3FileData = obj;
      }
    });
    if (count >= 1) {
      editImageOk = true;
    }
    if (count >= 2) {
      rightSlideFlag = true;
    }
    await this.setState({
      picS3FileData,
      currImageSeqno,
      tempPicS3FileData: picS3FileData,
      uploadOpsMode: "",
      adminsterFlag: true,
      attachPicFlag: false,
      attachVideoFlag: false,
      handleDigitalAsset: false,
      showImageButton: false,
      showVideoButton: true,
      showImageFlag: true,
      showVideoFlag: false,
      leftSlideFlag: false,
      rightSlideFlag,
      newImageOk: true,
      editImageOk,
    });
  };

  handleImageEdit = async () => {
    // console.log("handleImageEdit currImageSeqno:", this.state.currImageSeqno);
    // Get the right object from fileUploads to picS3FileData
    await this.setState({
      uploadOpsMode: "edit",
      adminsterFlag: false,
      attachPicFlag: true,
      attachVideoFlag: false,
      handleDigitalAsset: true,
      showImageButton: false,
      showVideoButton: true,
    });
  };

  returnToCatalogForVideo = async (fileData) => {
    // console.log("Check S3 fileData: ", fileData);
    // console.log("this.state.digitalS3FileData:", this.state.digitalS3FileData);

    let videoS3FileData = null;
    let imageFile = [];
    let vobj = null;
    let prevDASize = 0;
    let deletedDASize = 0;
    if (fileData.ifDeletedFlag) {
      // console.log("The file has been deleted ...");
      this.state.digitalS3FileData.forEach((obj) => {
        if (this.state.tempVideoS3FileData.key !== obj.key) {
          imageFile.push(obj);
        } else {
          if (obj.size) deletedDASize = obj.size;
        }
        // Calculate the total DA size prior to new/edit
        if (obj.size) prevDASize = prevDASize + obj.size;
      });
    } else {
      imageFile = [...this.state.digitalS3FileData];
    }

    if (fileData.s3FileData) {
      let aVideoExists = false;
      imageFile.forEach((obj) => {
        if (obj.type === "video") {
          aVideoExists = true;
          // console.log("Video Exists");
        }
      });
      if (!aVideoExists) {
        // console.log("New Video");
        vobj = {
          seqno: 0,
          key: fileData.s3FileData.key,
          type: "video",
          location: fileData.s3FileData.location,
        };
        imageFile.push(vobj);
        videoS3FileData = vobj;
      } else {
        videoS3FileData = fileData.s3FileData;
      }
    }

    let sortednew = await arraySortNum(imageFile, "seqno", "dsc");
    // console.log("video sortednew:", sortednew);

    let adjustOfDASize = 0; // This will be increment amount of DA size
    if (fileData.s3FileData) {
      adjustOfDASize = prevDASize - deletedDASize + fileData.s3FileData.size;
    } else {
      adjustOfDASize = prevDASize - deletedDASize;
    }

    await this.setState({
      videoS3FileData,
      tempVideoS3FileData: videoS3FileData,
      digitalS3FileData: sortednew,
      adminsterFlag: true,
      attachPicFlag: false,
      attachVideoFlag: false,
      handleDigitalAsset: false,
      showImageFlag: false,
      showVideoFlag: true,
      rightSlideFlag: false,
      leftSlideFlag: false,
      newImageOk: false,
      editImageOk: false,
      adjustOfDASize,
    });

    await this.handleCatalogSave("digidatasave", "video");
  };

  handleNewImageLoad = async () => {
    // console.log("handleNewImageLoad");
    // Create subDirectory if it does not exist
    if (this.state.subDirName === "" || this.state.subDirName === null) {
      // console.log("will call catalogSubDirectory");
      await this.catalogSubDirectory();
    }
    // console.log("Error: subdirectory should be specified ... not working");
    // Get the right object from fileUploads to picS3FileData
    else
      await this.setState({
        uploadOpsMode: "new",
        adminsterFlag: false,
        attachPicFlag: true,
        handleDigitalAsset: true,
        picS3FileData: null,
      });
  };

  packageDigitalCatalogData = async () => {
    let catdata;
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    catdata = {
      communityId: this.props.communityId,
      coopStoreId,
      itemId: this.state.catalog.itemId,
      digitalFiles: this.state.digitalS3FileData,
      subDirName: this.state.subDirName,
      updtmode: "digidata",
      clientProgram: "Catalog.js",
      clientFunction: "packageDigitalCatalogData",
      adjustOfDASize: this.state.adjustOfDASize,
    };

    return catdata;
  };

  packageCatalogData = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let catdata;
    catdata = {
      communityId: this.props.communityId,
      coopStoreId,
      commName: this.props.catalogItem.catobj.commName,
      itemId: this.props.catalogItem.catobj.itemId,

      itemDescription: this.state.itemDescription,
      itemPrice: parseFloat(this.state.itemPrice),
      isPublished: this.state.isPublished,
      isUnpublished: this.state.isUnpublished,
      baandaId: this.props.auth.user.baandaId,
      itemExposure: this.state.itemExposure,
      autoGroupingRequired: this.state.willBeGrouped,

      searchWords: this.state.searchWords,
      servicePricingType: this.state.servicePricingType,
      itemType: this.props.catalogItem.catobj.itemType,

      updtmode: "textdata",
      clientProgram: "Catalog.js",
      clientFunction: "packageCatalogData",
    };

    return catdata;
  };

  validateBeforeSave = async () => {
    let msg = "";
    let state = "success";
    if (this.state.item.itemType === "goods" && this.state.isPublished) {
      if (parseFloat(this.state.itemPrice) === 0) {
        msg = "Cannot publish goods with no price.";
        state = "error";
      }
    }

    return { status: state, Msg: msg };
  };

  handleCatalogSave = async (callby, medium) => {
    // console.log("handleCatalogSave callby:", callby, " medium:", medium);
    let val = await this.validateBeforeSave();
    if (val.status === "success") {
      try {
        let url = baandaServer + updateCatalogItem;
        let catalogData;
        if (callby === "textdatasave") {
          catalogData = await this.packageCatalogData();
        }
        if (callby === "digidatasave") {
          catalogData = await this.packageDigitalCatalogData();
        }
        // console.log("catalogData: ", catalogData, " url:", url);
        let retcat = await axios.post(url, catalogData);
        // console.log("retcat ::: ", retcat);
        if (retcat.data.status === "success") {
          this.setState({
            doneMsg: "Successfully Updated",
            doneMsgFlag: false,
            catalog: retcat.data.Msg,
            isPublished: retcat.data.Msg.isPublished,
          });
        } else {
          this.setState({
            doneMsgFlag: true,
            doneMsg: retcat.data.Msg,
          });
        }
      } catch (err) {
        this.setState({
          doneMsgFlag: true,
          doneMsg: err.message,
        });
      }
    } else {
      this.setState({
        doneMsgFlag: true,
        doneMsg: val.Msg,
      });
    }
  };

  returnToCatalogForImage = async (fileData) => {
    let imageFile = []; // Create the new digitalS3FileData array with new delete / upload pic.
    let picS3FileData = null;
    let newImageFile = [];
    let showImageFlag = false;
    // Remove the deleted file from existing digital file array
    let editedseqno = 0;
    if (this.state.tempPicS3FileData)
      editedseqno = this.state.tempPicS3FileData.seqno;
    let prevDASize = 0;
    let deletedDASize = 0;
    let newFileSize = 0;
    let adjustOfDASize = 0;
    let currImageSeqno = this.state.currImageSeqno;
    let countImage = 0;
    let rightSlideFlag = this.state.rightSlideFlag;

    if (fileData.ops !== "cancel") {
      // Calculate the total size of digital assets prior to modification.
      this.state.digitalS3FileData.forEach((obj) => {
        if (obj.size) prevDASize = prevDASize + obj.size;
      });

      if (fileData.ifDeletedFlag) {
        this.state.digitalS3FileData.forEach((obj) => {
          if (this.state.tempPicS3FileData.seqno !== obj.seqno) {
            imageFile.push(obj);
          } else {
            if (obj.size) deletedDASize = obj.size; // This is the size of deleted file;
          }
        });
      } else {
        imageFile = [...this.state.digitalS3FileData];
      }

      let seqno = 1;
      if (fileData.s3FileData) {
        // console.log("There is a new image file ...");
        newFileSize = parseFloat(fileData.s3FileData.size);
        showImageFlag = true;

        // has sorted digitalS3FileData by seqno
        // let sorted = await arraySortNum(imageFile, "seqno", "dsc");

        if (this.state.uploadOpsMode === "edit") {
          imageFile.forEach((pic) => {
            if (seqno === editedseqno) {
              seqno++; // Skip the seqno as an opening for the edited pic
            }
            // console.log("2 seqno:", seqno, "  editedseqno:", editedseqno);
            picS3FileData = {
              seqno: parseFloat(seqno), // Get the proper seqno
              key: pic.key,
              type: pic.type,
              location: pic.location,
              size: pic.size,
            };

            newImageFile.push(picS3FileData);

            seqno++;
          });

          picS3FileData = {
            seqno: parseFloat(editedseqno), // Get the proper seqno
            key: fileData.s3FileData.key,
            type: "image",
            location: fileData.s3FileData.location,
            size: fileData.s3FileData.size,
          };

          newImageFile.push(picS3FileData);
        }
        if (this.state.uploadOpsMode === "new") {
          newImageFile = [...imageFile];
          picS3FileData = {
            seqno: parseFloat(imageFile.length + 1), // Get the proper seqno
            key: fileData.s3FileData.key,
            type: "image",
            location: fileData.s3FileData.location,
            size: fileData.s3FileData.size,
          };

          newImageFile.push(picS3FileData);
        }
      } else {
        newImageFile = [...imageFile];
      }
      // console.log("2 newImageFile:", newImageFile);
      // If negative, decrement else encrement by the differene.
      adjustOfDASize = prevDASize - deletedDASize + newFileSize;

      seqno = 1;
      let adjNewImageFile = [];
      newImageFile.forEach((ndj) => {
        let lobj = JSON.parse(JSON.stringify(ndj));
        lobj.seqno = seqno;
        seqno++;
        adjNewImageFile.push(lobj);
      });

      // let sortednew = await arraySortNum(adjNewImageFile, "seqno", "dsc");
      let noDGAsset = true;
      let editImageOk = false;
      let imageOnlyFiles = [];
      let newseqno = 1;
      newImageFile.forEach((pics) => {
        if (pics.type === "image") {
          pics.seqno = newseqno;
          newseqno++;
          imageOnlyFiles.push(JSON.parse(JSON.stringify(pics)));
          countImage++;
          editImageOk = true;
        }
      });

      let leftSlideFlag = false;
      if (countImage > 0) {
        showImageFlag = true;
        if (this.state.uploadOpsMode === "new") {
          picS3FileData = imageOnlyFiles[imageOnlyFiles.length - 1];
          currImageSeqno = imageOnlyFiles.length;
          rightSlideFlag = false;
          if (currImageSeqno > 1) leftSlideFlag = true;
        }
        if (this.state.uploadOpsMode === "edit") {
          if (fileData.ifDeletedFlag && !fileData.s3FileData) {
            picS3FileData = imageOnlyFiles[0];
            currImageSeqno = 1;
            if (imageOnlyFiles.length > 1) rightSlideFlag = true;
          } else {
            picS3FileData = imageOnlyFiles[editedseqno];
            currImageSeqno = editedseqno;
            if (editedseqno < imageOnlyFiles.length) {
              rightSlideFlag = true;
            }
          }
        }
      }

      if (adjNewImageFile.length > 0) noDGAsset = false;

      this.setState({
        picS3FileData,
        showImageFlag,
        tempPicS3FileData: picS3FileData,
        digitalS3FileData: adjNewImageFile, //sortednew,
        imageS3FileData: imageOnlyFiles,
        adminsterFlag: true,
        attachPicFlag: false,
        handleDigitalAsset: false,
        currImageSeqno,
        leftSlideFlag,
        rightSlideFlag,
        countImage,
        noDGAsset,
        editImageOk,
        adjustOfDASize,
      });

      // If there is a pic or video loaded and needs to be in db

      await this.handleCatalogSave("digidatasave", "image");
    } else {
      this.setState({
        adminsterFlag: true,
        attachPicFlag: false,
        handleDigitalAsset: false,
      });
    }
  };

  handleSlideImageRight = async () => {
    // console.log("handleSlideImageRight");
    let currImageSeqno = parseFloat(this.state.currImageSeqno) + 1;
    if (currImageSeqno > this.state.imageS3FileData.length)
      currImageSeqno = this.state.imageS3FileData.length;
    let picS3FileData;
 
    this.state.imageS3FileData.forEach((obj) => {
      if (obj.seqno === currImageSeqno) picS3FileData = obj;
     
    });

    let tempPicS3FileData = picS3FileData;
    let rightSlideFlag = true;
    let leftSlideFlag = false;
  
    if (currImageSeqno === this.state.imageS3FileData.length) {
      rightSlideFlag = false;
    }

    if (currImageSeqno > 1) leftSlideFlag = true;
    // console.log("right currImageSeqno:", currImageSeqno);
    await this.setState({
      currImageSeqno,
      picS3FileData,
      tempPicS3FileData,
      rightSlideFlag,
      leftSlideFlag,
      doneMsg: "",
    });
  };

  handleSlideImageLeft = async () => {
    // console.log("handleSlideImageLeft");
    let currImageSeqno = parseFloat(this.state.currImageSeqno) - 1;
    if (currImageSeqno === 0) currImageSeqno = 1;
    let picS3FileData;
    this.state.imageS3FileData.forEach((obj) => {
      if (obj.seqno === currImageSeqno) picS3FileData = obj;
    });

    let tempPicS3FileData = picS3FileData;
    let rightSlideFlag = false;
    let leftSlideFlag = true;
    if (currImageSeqno < this.state.imageS3FileData.length) {
      rightSlideFlag = true;
    }
    if (currImageSeqno === 1) leftSlideFlag = false;
    // console.log("left currImageSeqno:", currImageSeqno);
    await this.setState({
      currImageSeqno,
      picS3FileData,
      tempPicS3FileData,
      rightSlideFlag,
      leftSlideFlag,
      doneMsg: "",
    });
  };

  handleIsPublish = async () => {
    await this.setState((prevstate) => ({
      isPublished: !prevstate.isPublished,
      doneMsg: "",
    }));
  };

  handleIsUnpublish = async () => {
    await this.setState((prevstate) => ({
      isUnpublished: !prevstate.isUnpublished,
      doneMsg: "",
    }));
  };

  handleExposure = async (e) => {
    await this.setState({
      itemExposure: e.target.value,
    });
  };

  handleServicePricingType = async (e) => {
    await this.setState({
      servicePricingType: e.target.value,
    });
  };

  render() {
    // console.log("> this.props:", this.props);
    // console.log("> this.state:", this.state);
    console.log("CatalogAdmin...");

    let modalButtonButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel = (
      <div className="catalog-present-spaceing">
        <span>
          <button
            className="btn_back_main"
            type="button"
            onClick={this.exitCatalogAdmin}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-step-backward" />
          </button>
          &nbsp;&nbsp;
          <button
            className="btn_reg_70"
            type="button"
            onClick={() => this.handleCatalogSave("textdatasave", "na")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
          {modalButtonButton}
        </span>
        <div
          className={
            this.state.doneMsgFlag
              ? "text-left cat-done-msg-err"
              : "text-left cat-done-msg"
          }
        >
          {this.state.doneMsg}
        </div>
      </div>
    );

    // Digital Asset Management =======================
    let noImgItemSelPanel;
    noImgItemSelPanel = (
      <div>
        <img
          src={noImageAvailable}
          alt="pic"
          className="catalog-img-sel-view"
        />
      </div>
    );

    let imagePanel;
    if (this.state.picS3FileData) {
      // console.log("In image panel def...");
      imagePanel = (
        <div>
          <img
            src={this.state.picS3FileData.location}
            alt="pic"
            className="catalog-img-sel-view"
          />
        </div>
      );
    }

    let videoPanel;
    if (this.state.videoS3FileData) {
      videoPanel = (
        <div>
          <video className="catalog-video-size-for-card" controls>
            <source
              src={this.state.videoS3FileData.location}
              type={this.state.videoFileType}
            />
          </video>
        </div>
      );
    }

    let digitalButtons;
    digitalButtons = (
      <div>
        <div>
          {this.state.leftSlideFlag ? (
            <button
              onClick={this.handleSlideImageLeft}
              className="btn-image-slide"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              <i className="fa fa-chevron-left" />
            </button>
          ) : (
            <button className="btn-image-slide-disabled">
              <i className="fa fa-chevron-left" />
            </button>
          )}
          {this.state.newImageOk ? (
            <button
              onClick={this.handleNewImageLoad}
              className="btn-image"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              New
            </button>
          ) : (
            <button
              className="btn-image-disabled"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              New
            </button>
          )}
          {this.state.editImageOk ? (
            <button
              onClick={this.handleImageEdit}
              className="btn-image"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              Edit
            </button>
          ) : (
            <button className="btn-image-disabled">Edit</button>
          )}
          {this.state.rightSlideFlag ? (
            <button
              onClick={this.handleSlideImageRight}
              className="btn-image-slide"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              <i className="fa fa-chevron-right" />
            </button>
          ) : (
            <button className="btn-image-slide-disabled">
              <i className="fa fa-chevron-right" />
            </button>
          )}

          {this.state.showVideoButton ? (
            <button
              onClick={this.handleShowVideoFlag}
              className="btn-video"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              Video
            </button>
          ) : null}
          {this.state.showImageButton ? (
            <button
              onClick={this.handleShowImageFlag}
              className="btn-video"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              Image
            </button>
          ) : null}
        </div>
        <div>
          <button className="btn-image-set">
            Image: Load(new)-Edit(current): max:5
          </button>
        </div>
      </div>
    );

    let digitalAssetPanel;
    if (this.state.showImageFlag) {
      digitalAssetPanel = (
        <div>
          {this.state.picS3FileData ? imagePanel : noImgItemSelPanel}

          {digitalButtons}
        </div>
      );
    }
    if (this.state.showVideoFlag) {
      digitalAssetPanel = (
        <div>
          {this.state.videoS3FileData ? videoPanel : "No Video Uploaded"}
          {digitalButtons}
        </div>
      );
    }

    if (this.state.noDGAsset) {
      digitalAssetPanel = (
        <div>
          {noImgItemSelPanel}
          {digitalButtons}
        </div>
      );
    }
    // =============================================DAM

    let baselinePanel;
    baselinePanel = (
      <div className="row baseline-panel-row">
        <div className="col-6">
          <div className="row">
            <div className="col-3 text-right catalog-label">Exposure</div>
            <div className="col-9 text-left catalog-data">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="public"
                    checked={this.state.itemExposure === "public"}
                    onChange={this.handleExposure}
                  />{" "}
                  Public
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="community"
                    checked={this.state.itemExposure === "community"}
                    onChange={this.handleExposure}
                  />{" "}
                  Community
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="private"
                    checked={this.state.itemExposure === "private"}
                    onChange={this.handleExposure}
                  />{" "}
                  Private
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="row search-word-row">
            <div className="col-3 text-right catalog-label">Search Words</div>
            <div className="col-9 text-left catalog-data">
              <input
                name="searchWords"
                type="text"
                value={this.state.searchWords}
                onChange={this.onChange}
                size="200"
                maxLength="200"
                className="catalog-search-words-x"
                placeholder="Enter words, comma delimited, "
              />
            </div>
          </div>
        </div>
      </div>
    );

    let endPanel;
    if (this.state.catalog) {
      if (this.state.catalog.isPublished) {
        endPanel = (
          <div className="row baseline-panel-row">
            <div className="col-6 text-right cat-confirm-text">
              <Checkbox
                checked={this.state.isUnpublished}
                onChange={this.handleIsUnpublish}
              />
              &nbsp;&nbsp;<font color="red">Un-publish</font>
            </div>
            <div className="col-6 text-left">{buttonPanel}</div>
          </div>
        );
      } else {
        endPanel = (
          <div className="row baseline-panel-row">
            <div className="col-6 text-right cat-confirm-text">
              <Checkbox
                checked={this.state.isPublished}
                onChange={this.handleIsPublish}
              />
              &nbsp;&nbsp;Ok to Publish
            </div>
            <div className="col-6 text-left">{buttonPanel}</div>
          </div>
        );
      }
    }

    // Checkbox Section ==========================
    let checkboxPanel;

    if (this.state.catalog && this.state.catalog.itemType === "goods") {
      // console.log(
      //   "this.state.catalog.pkgComposedFlag:",
      //   this.state.catalog.pkgComposedFlag
      // );

      checkboxPanel = (
        <div className="row cat-stup-row">
          <div className="col text-center cat-setup-functions">
            {this.state.catalog &&
            this.state.catalog.subscription &&
            this.state.catalog.subscription.allowSubscription ? null : (
              <Checkbox
                checked={this.state.manageDiscounts}
                onChange={this.handleDiscounts}
              />
            )}
            {/* <Checkbox
              checked={this.state.manageDiscounts}
              onChange={this.handleDiscounts}
            /> */}
            {this.state.catalog &&
            this.state.catalog.subscription &&
            this.state.catalog.subscription.allowSubscription
              ? null
              : " Discount "}
            {/* &nbsp;Discount&nbsp;&nbsp;&nbsp;&nbsp; */}
            <Checkbox
              checked={this.state.manageTax}
              onChange={this.handleSalesTax}
            />
            &nbsp;Sales Tax&nbsp;&nbsp;&nbsp;&nbsp;
            <Checkbox
              checked={this.state.manageShipping}
              onChange={this.handleShipping}
            />
            &nbsp;Delivery&nbsp;&nbsp;&nbsp;&nbsp;
            <Checkbox
              checked={this.state.packageDimension}
              onChange={this.handleDimension}
            />
            &nbsp;Size&nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.catalog.pkgComposedFlag && !this.props.store ? (
              <Checkbox
                checked={this.state.managePkgPlan}
                onChange={this.handlePkgPlan}
              />
            ) : null}
            {this.state.catalog.pkgComposedFlag && !this.props.store
              ? " Pkg-Plan"
              : null}
            {!this.state.catalog.pkgComposedFlag && !this.props.store ? (
              <Checkbox
                checked={this.state.manageSubscription}
                onChange={this.handleSubscription}
              />
            ) : (
              ""
            )}
            {!this.state.catalog.pkgComposedFlag && !this.props.store
              ? " Subscription"
              : ""}
          </div>
        </div>
      );
    }

    if (this.state.catalog && this.state.catalog.itemType === "service") {
      if (!this.props.store) {
        checkboxPanel = (
          <div className="row cat-stup-row">
            <div className="col text-center cat-setup-functions">
              {this.state.catalog.service.type !== "custom" ? (
                <Checkbox
                  checked={this.state.manageSubscription}
                  onChange={this.handleSubscription}
                />
              ) : null}
              {this.state.catalog.service.type !== "custom"
                ? " Subscription "
                : null}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Checkbox
                checked={this.state.manageDiscounts}
                onChange={this.handleDiscounts}
              />
              &nbsp;Discount/Sales&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Checkbox
                checked={this.state.manageServices}
                onChange={this.handleServices}
              />
              &nbsp;Service Mgmt.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* <Checkbox
                checked={this.state.willBeGrouped}
                onChange={this.handleGrouping}
              />
              &nbsp;To group&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              {this.state.catalog.service.type === "custom" ? (
                <Checkbox
                  checked={this.state.manageCustomTagging}
                  onChange={this.handleCustomTagging}
                />
              ) : null}
              {this.state.catalog.service.type === "custom"
                ? " Custom Tagging"
                : null}
              &nbsp;
            </div>
          </div>
        );
      } else {
        checkboxPanel = (
          <div className="row cat-stup-row">
            <div className="col text-center cat-setup-functions">
              <Checkbox
                checked={this.state.manageDiscounts}
                onChange={this.handleDiscounts}
              />
              &nbsp;Discount/Sales&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Checkbox
                checked={this.state.manageServices}
                onChange={this.handleServices}
              />
              &nbsp;Service Mgmt.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.catalog.service.type === "custom" ? (
                <Checkbox
                  checked={this.state.manageCustomTagging}
                  onChange={this.handleCustomTagging}
                />
              ) : null}
              {this.state.catalog.service.type === "custom"
                ? " Custom Tagging"
                : null}
              &nbsp;
            </div>
          </div>
        );
      }
    }

    // ===========================================

    let ctgry = "Category";
    let itemname = "Item Name";
    let perucost = "Cost";
    let mavgcost = "Average Cost";
    let istate = "Item State";
    let uname = "Unit Name";

    let sri = this.state.catalog;
    // console.log("---------sri:", sri);

    let servicePricingPanel;
    if (
      this.state.catalog &&
      sri.itemType === "service" &&
      sri.service.type === "custom"
    ) {
      servicePricingPanel = (
        <div>
          <div className="row">
            <div className="col text-center service-price-type">
              Service Pricing Type&nbsp;&nbsp;
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="now"
                    checked={this.state.servicePricingType === "now"}
                    onChange={this.handleServicePricingType}
                  />{" "}
                  Price Now
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="quote"
                    checked={this.state.servicePricingType === "quote"}
                    onChange={this.handleServicePricingType}
                  />{" "}
                  Via Quotation
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }
    let catalogGeneralPanel;
    if (sri && this.state.showGeneralFlag) {
      catalogGeneralPanel = (
        <div>
          <div className="row cat-desc-row">
            <div className="col-8 catalog-description-lbl text-center">
              Describe the item for your customer (max 1000 chars - line break
              to paragraph)
            </div>
            <div className="col-4 catalog-description-wc text-center">
              (Char Count {this.state.itemDescription.length})
            </div>
          </div>
          <div className="row">
            <div className="col catalog-description text-center">
              <textarea
                name="itemDescription"
                maxLength="1000"
                placeholder="Entice your customers ..."
                rows="5"
                wrap="hard"
                spellCheck="true"
                className="catalog_desc_textarea"
                onChange={this.onChange}
                value={this.state.itemDescription}
                required
              />
            </div>
          </div>
          {servicePricingPanel}
          <div className="row price-row">
            {this.state.servicePricingType === "now" ? (
              <div className="col-4 text-center cat-price">
                Price the Item&nbsp;$
                <input
                  name="itemPrice"
                  type="number"
                  value={this.state.itemPrice}
                  onChange={this.onChangePrice}
                  className="catalog-price-field"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                  spellCheck="false"
                />
              </div>
            ) : (
              <div className="col-4 text-center cat-price">
                Priced via quotation
              </div>
            )}

            {this.state.catalog &&
            this.state.catalog.itemType === "goods" &&
            this.state.catalog.pkgComposedFlag ? (
              <div className="col-8 text-center catalog-item-qty">
                Future Planned Qty:&nbsp;{this.state.numberOfPkgPlanned}
                &nbsp;&nbsp; Subscribed Qty:&nbsp;
                {this.state.numberOfPkgSubscribed}
                &nbsp;
              </div>
            ) : null}
            {this.state.catalog &&
            this.state.catalog.itemType === "goods" &&
            !this.state.catalog.pkgComposedFlag ? (
              <div className="col-8 text-center catalog-item-qty">
                Catalog Qty:&nbsp;{this.state.totalCatalogQty}
                &nbsp;&nbsp; Warehouse Qty:&nbsp;{this.state.totalIMQty}
                &nbsp;
              </div>
            ) : null}
            {this.state.catalog && this.state.catalog.itemType === "service" ? (
              <div className="col-8 text-center catalog-item-qty">
                Service Type: {this.state.catalog.service.type.toUpperCase()}
              </div>
            ) : null}
          </div>
          {checkboxPanel}
          {baselinePanel}
        </div>
      );
    }

    let showItemDetailPanel;
    if (this.state.catalog) {
      showItemDetailPanel = (
        <div>
          <div className="row">
            <div className="col-md-6">{digitalAssetPanel}</div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-3 text-right catalog-label">{itemname}</div>
                <div className="col-9 text-left catalog-data-name">
                  {sri.itemName}
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-right catalog-label">Item Type</div>
                <div className="col-9 text-left catalog-data">
                  {sri.itemType}&nbsp; (Item Id:{" "}
                  {this.props.catalogItem.catobj.itemId})
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-right catalog-label">{ctgry}</div>
                <div className="col-9 text-left catalog-data">
                  {sri.itemCategory}
                </div>
              </div>
              {sri.itemType === "service" ? null : (
                <div className="row">
                  <div className="col-3 text-right catalog-label">
                    {perucost}
                  </div>
                  <div className="col-9 text-left catalog-data">
                    $
                    {this.state.item &&
                    parseFloat(this.state.item.lastPerUnitCost) > 0
                      ? this.commaFormattedCurrency(
                          parseFloat(this.state.item.lastPerUnitCost)
                        )
                      : "N/A"}{" "}
                    per unit
                  </div>
                </div>
              )}
              {sri.itemType === "service" ? null : (
                <div className="row">
                  <div className="col-3 text-right catalog-label">
                    {mavgcost}
                  </div>
                  <div className="col-9 text-left catalog-data">
                    $
                    {this.state.item &&
                    parseFloat(this.state.item.movingAvgCostPerUnit) > 0
                      ? this.commaFormattedCurrency(
                          parseFloat(this.state.item.movingAvgCostPerUnit)
                        )
                      : "N/A"}
                  </div>
                </div>
              )}
              {sri.itemType === "service" ? null : (
                <div className="row">
                  <div className="col-3 text-right catalog-label">{uname}</div>
                  <div className="col-8 text-left catalog-data">
                    {this.state.item && this.state.item.unitName}
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-3 text-right catalog-label">{istate}</div>
                <div className="col-8 text-left catalog-data">
                  {this.state.catalog
                    ? "Edit - the Definition"
                    : "New - to be Defined"}
                </div>
              </div>
              <div className="row">
                <div className="col text-left catalog-wh-label">
                  Warehouse Item Description
                </div>
              </div>
              <div className="row">
                <div className="col text-left warehouse-description">
                  {this.state.item ? this.state.item.itemDescription : null}
                </div>
              </div>
            </div>
          </div>
          {catalogGeneralPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.adminsterFlag) {
      outputPanel = (
        <div className="catalog-display-box">
          {showItemDetailPanel}
          {endPanel}
        </div>
      );
    }

    if (this.state.lockErrFlag) {
      outputPanel = (
        <div className="catalog-admin-error-box">
          <div className="cat-admin-err-head">Catalog Admin Message</div>
          <div>
            <p align="justify" className="lock-error-msg">
              {this.state.errMsg}
            </p>
          </div>
          <div className="cat-admin-lock-msg">
            Locked by: {this.props.catalogItem.catobj.lockedByName}
          </div>
          <div className="cat-admin-lock-msg">
            The email: {this.props.catalogItem.catobj.lockedByEmail}
          </div>
          <button onClick={this.exitCatalogAdmin}>R</button>
        </div>
      );
    }

    let digitalUploadPanel;
    if (this.state.attachPicFlag) {
      digitalUploadPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToCatalogForImage}
            communityId={this.props.communityId}
            fileType={picTypes}
            maxSize={maxPicSize}
            s3subdir={this.state.subDirName}
            requestor="Catalog Item Image"
            s3FileData={this.state.picS3FileData}
            type="image"
          />
        </div>
      );
    }

    if (this.state.attachVideoFlag) {
      digitalUploadPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToCatalogForVideo}
            communityId={this.props.communityId}
            fileType={videoTypes}
            maxSize={maxVideoSize}
            s3subdir={this.state.subDirName}
            requestor="Catalog Item Video"
            s3FileData={this.state.videoS3FileData}
            type="video"
          />
        </div>
      );
    }

    if (this.state.handleDigitalAsset) {
      outputPanel = <div>{digitalUploadPanel}</div>;
    }

    if (this.state.showSubscriptionFlag) {
      outputPanel = (
        <div>
          <SubscriptionMgmt
            returnToCaller={this.returnFromSubscription}
            catalog={this.state.catalog}
          />
        </div>
      );
    }

    if (this.state.showDiscountFlag) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      outputPanel = (
        <div>
          <ManageDiscount
            catalog={this.state.catalog}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            returnToCaller={this.returnFromDiscount}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showTaxFlag) {
      let store = null;
      if (this.props.store) {
        store = this.props.store;
      }
      outputPanel = (
        <div>
          <TaxSetup
            returnToCaller={this.returnFromTaxSetup}
            catalog={this.state.catalog}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showShippingFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <ShippingMgmt
            returnToCaller={this.returnFromShippingSetup}
            communityId={this.props.communityId}
            catalog={this.state.catalog}
            store={store}
            community={this.props.community}
          />
        </div>
      );
    }

    if (this.state.showPkgPlanFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <SubscriptionPkgPlan
            returnToCaller={this.returnFromPkgPlan}
            communityId={this.props.communityId}
            catalog={this.state.catalog}
            store={store}
            community={this.props.community}
          />
        </div>
      );
    }

    if (this.state.showDimensionFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <PackageDimension
            returnToCaller={this.returnFromDimensionSetup}
            communityId={this.props.communityId}
            catalog={this.state.catalog}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showServicesFlag) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      outputPanel = (
        <div>
          <ServicesMgmt
            returnToCaller={this.returnFromServicesSetup}
            catalog={this.state.catalog}
            communityId={this.props.communityId}
            community={this.props.community}
            coopStoreId={coopStoreId}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showCustomTagFlag) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      outputPanel = (
        <div>
          <CustomServiceTagging
            returnToCaller={(catalog) => this.returnFromServiceTagging(catalog)}
            catalog={this.state.catalog}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showGameSetupFlag) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      outputPanel = (
        <div>
          <TeamGameSetup
            returnToCaller={(catalog) => this.returnFromGameSetup(catalog)}
            catalog={this.state.catalog}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CatalogAdmin.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CatalogAdmin));
