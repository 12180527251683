import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from "react-loading";

import "./ReceiveCCForSubscription.css";

import {
  Elements,
  CardElement,
  useStripe,
  useElements, 
} from "@stripe/react-stripe-js";

import axios from "axios";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const handleInvoiceOfSales = "/routes/dashboard/handleInvoiceOfSales";

const CCPayoutForm = (props) => {
  // console.log("2. props:", props);
  const stripe = useStripe();
  const elements = useElements();

  let amount = ( props.inputData.ccInfo.price2.unit_amount + props.inputData.ccInfo.price1.unit_amount) /100  ;
  // let amount = (props.inputData.ccInfo.price1.unit_amount + props.inputData.ccInfo.price2.unit_amount) / 100;

  const [valueof, setValueof] = useState(true);
  const [message, setMessage] = useState(0);
  const [errmsg, setErrmsg] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [errflag, setErrflag] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isShownPay, setIsShownPay] = useState(false);
  const [isShownCancel, setIsShownCancel] = useState(false);
  const [isShownFinito, setIsShownFinito] = useState(false);

  const handleSubmit = async (event) => {
    // console.log("stripeaccId props:", props.stripeaccId);
    event.preventDefault();
    setMessage(1);
    setDisabled(true);
    setSpinner(true);
    let clientSecret = props.inputData.clientSecret;
  
    // console.log("clientSecret:", clientSecret);

    const response  = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    setSpinner(false);
    // console.log("1. response:", response);
   
    // console.log('2. ##### id:', response.paymentIntent.id);
    // console.log('2. ##### method:', response.paymentIntent.payment_method);

    let error = false;
    let errMsg = "";
    if (response.error) {
      error = true;
      // errMsg = "Error: " + response.error.code + ". " + response.error.message;
      // console.log("ErrMsg:", errMsg);
    } 
    // else { 
    //   console.log("I am here ok,,, error:", error);
    // }

    if (!error) {
      // console.log("here 4455");
      // console.log("response.paymentIntent: ", response.paymentIntent);
      setSpinner(true);
      setErrflag(false);
      setErrmsg("");
      if ( props.paymentFor === 'invoice-subscription'){
        let paydone = await handleSalesCompletion(response);
        // console.log('paydone:', paydone);
        if ( paydone.status === "success") setValueof(false);
        else {
          setMessage(0);
          setErrflag(true);
          setErrmsg(paydone.Msg);
        }
      }
      setSpinner(false);
    } else {
      console.log("confirmCardPayment error:", error);
      setMessage(0);
      setErrflag(true);
      setErrmsg(errMsg);
    }
  };

  const handleSalesCompletion = async (response) => {
    // console.log('in receiveInitMemberhip');
    try {
      let url = baandaServer + handleInvoiceOfSales;
      let input = {
        paymentFor: props.paymentFor,
        inputData: props.inputData.invoiceData,
        ccInfo: props.inputData.ccInfo,
        subTranId: props.inputData.transactionId,
        paymentIntent: response,
        intentId: response.paymentIntent.id,
        paymentMethod: response.paymentIntent.payment_method,
        customerId: props.inputData.ccInfo.customer.id,
        subTransactionId: props.transactionId,
        stripeAccount: props.StripeAccId,
      }
      let payret = await axios.post(url, input)
      // console.log('payret:', payret);
      // console.log('payret.data:', payret.data);
      return payret.data;
    } catch (err) {
      console.log("ReceiveCForMeterSub Error:", err.message);
      setErrflag(true);
      setErrmsg(err.message);
      return { status: 'error', Msg: err.message};
    }
  }
  

  // console.log(">>>> valueof:", valueof);

  const doFinito = async () => {
    // console.log("doFinito errmsg:", errmsg);
    let retval;
    // console.log(">>>> in dofinito valueof:", valueof);
    if (!valueof) retval = "success";
    else retval = "error";
    // console.log("dofinito retval:", retval);
    props.handleExit(retval);
  };
  const doCancel = async () => {
    // console.log("inside doCancel");
    props.handleExit("cancel");
  };

  let buttonPanel;
  if (message === 0) {
    buttonPanel = (
      <div className="test-pay-button-place">
        <button
          style={{ cursor: disabled ? "default" : "pointer" }}
          type="submit"
          className={
            isShownPay ? "btn_reg_60" : "btn_reg_50"
          }
          disabled={!stripe}
          onMouseEnter={() => setIsShownPay(true)}
          onMouseLeave={() => setIsShownPay(false)}
        >
          Pay
        </button>
 
      </div>
    );
  }

  let cancelBtn = (
    <div>
      <button
        onClick={doCancel}
        style={{ cursor: disabled ? "default" : "pointer" }}
        className={
          isShownCancel
            ? "btn_reg_80"
            : "btn_reg_70"
        }
        onMouseEnter={() => setIsShownCancel(true)}
        onMouseLeave={() => setIsShownCancel(false)}
      >
        Cancel
      </button>
      <div className="platform-cc-payment-description">{props.description}</div>
      <div className="platform-cc-payment-wait">
        Note: Processing of payment will take a few moments.
      </div>
      <div className="platform-cc-payment-description">
        <font color="red">{errmsg}</font>
      </div>
    </div>
  );

  let finishBtn = (
    <div>
      <button
        onClick={doFinito}
        className={
          isShownFinito ? "btn_reg_80" : "btn_reg_70"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
        onMouseEnter={() => setIsShownFinito(true)}
        onMouseLeave={() => setIsShownFinito(false)}
      >
        Finito
      </button>
      {errmsg === "" ? null : (
        <div className="platform-cc-payment-description">
          <font color="red">
            Error: {errmsg}.&nbsp;If CC info it right, contact baanda support at
            support@baanda.com
          </font>
        </div>
      )}
    </div>
  );

  let btnbelow;
  if (valueof) btnbelow = cancelBtn;
  else btnbelow = finishBtn;

  let uploadingSpin;
  if (spinner) {
    uploadingSpin = (
      <div>
        <ReactLoading
          type={"spokes"}
          color={"#ffffff"}
          height={30}
          width={30}
        />
      </div>
    );
  }

  let creditCardPanel;
  if (valueof) {
    creditCardPanel = ( 
      <div className="text-center">
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "300px", margin: "0 auto" }}
        >
          <div className="card-subscription-msg">Total amount is ${amount.toFixed(2)} </div>
          <CardElement className="card-stripe-cc-subscription" />
          {buttonPanel}
        </form>
        <div className="text-center spin-subscription-position">{uploadingSpin}</div>
      </div>
    );
  } else if (!errflag) {
    creditCardPanel = (
      <div className="cc_thanks_for_biz text-center">
        <h3>Thank you for the business</h3>
      </div>
    );
  } else {
    creditCardPanel = (
      <div className="cc_thanks_for_biz text-center">
        <h3>
          <font color="red">Error ... contact Baanda Support</font>
        </h3>
      </div>
    );
  }

  let cconcard = (
    <div className="sub-meter-creditcard">{creditCardPanel}</div>
  );

  let outputPanel;
  if (props.deviceSize === "small") {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col-1 text-center">&nbsp;</div>
          <div className="col-11">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center deliver-to-text">{btnbelow}</div>
        </div>
      </div>
    );
  } else {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col text-center cc-place">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center btn-below-place">{btnbelow}</div>
        </div>
      </div>
    );
  }

  return <div>{outputPanel}</div>;
};

//=====================================================================
// Calls the function, invoked from other cmomponent, to expose CC form.
const PayUsingCC = (props) => {
  // console.log("ReceiveCCForSubscription ... ");
  const stripePromise = loadStripe(stripePublicKey, {
    stripeAccount: props.StripeAccId,
  });


  return (
    <div>
      <Elements stripe={stripePromise}>
        <CCPayoutForm
          handleExit={(retdata) => props.handleExit(retdata)}
          inputData={props.inputData}
          paymentFor={props.paymentFor}
          StripeAccId={props.StripeAccId}
        />
      </Elements>
    </div>
  );
};

export default PayUsingCC;
