import React, { Component } from "react";

import "./ShowEventPurchase.css";

import ViewEvent from "../../calendar/event/view/ViewEvent";

import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";
const saveMsgRead = "/routes/interact/saveMsgRead";

class ShowEventPurchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theEvent: null,
      showEventFlag: false,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.markMsgRead();
    await this.getTheEvent();

    await this.setState({
      deviceSize,
      showEventFlag: true,
    });
  };

  markMsgRead = async () => {
    let input = { synapseId: this.props.showPost.synapseId };
    let url = baandaServer + saveMsgRead;
 
    try {
      let readret = await axios.post(url, input);
 
      if (readret.data.status === "error") {
 
        this.setState({
          errMsg: readret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      // console.log(
      //   "markMsgRead for synapseId " +
      //     this.props.showPost.synapseId +
      //     " got error = " +
      //     err.message
      // );
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  getTheEvent = async () => {
    let param = "eventId=" + this.props.showPost.actions.refId;
    try {
      let url = baandaServer + getAnEvent + param;
      // console.log("url:", url);
      let evtret = await axios.get(url);
      // console.log("evtret:", evtret);
      if (evtret.data.status === "success") {
        await this.setState({
          theEvent: evtret.data.Msg,
        });
      } else {
        this.setState({
          errMsg: evtret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  returnToBmail = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ShowEventPurchase...");

    let presentPanel;
    if (this.state.showEventFlag) {
      presentPanel = (
        <div className="event-display-frame-box">
          <div>
            <ViewEvent
              communityId={this.props.showPost.communityId}
              communityName={
                this.props.showPost.actions.parameters.taggedCatalog.commName
              }
              authorizedFuncs={this.props.authorizedFuncs}
              hostBaandaId={this.props.baandaId}
              theEvent={this.state.theEvent}
              returnToCaller={this.returnFromPublish}
              caller="bmail"
            />
          </div>
          <div className="text-center">
            <button
              className="btn-return-to-bmail"
              type="button"
              onClick={this.returnToBmail}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              To Bmail
            </button>
          </div>
        </div>
      );
    }

    return <div>{presentPanel}</div>;
  }
}

export default ShowEventPurchase;
