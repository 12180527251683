import React, { Component } from "react";
import axios from "axios";

import Editor from "./Editor";

import moment from "moment";

import "./BoardHub.css";

const editorList = ["editor"];

const noItemImage = process.env.REACT_APP_IMAGE_NOT_AVAILABLE;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getToday = "/routes/interact/getToday?";

class BoardHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtonPanel: true,
      todayFlag: true,

      editor: true,
      showEditor: false,

      deviceSize: "",

      headline: "",
      theNews: "", 
      theImage: "", 
      theVideo: "",

      showImage: false,
      showVideo: false,
      videoFileType: "",

      today: null,
      news: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    // let editor = false;
    // if (this.props.authorizedFuncs.includes("editor")) editor = true;

    await this.setAuthorizedButtons();

    await this.getToday();

    let news = [];
    if (this.state.today) {
      this.state.today.news.forEach((nss) => {
        if (nss.isPublished) news.push(nss);
      });
    }

    await this.setState({
      deviceSize,
      news,
    });

    // if ( news.length > 0 ) await this.processToday();
    await this.processToday();
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.state.authorizedFuncs);

    let editor = false;

    editorList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) editor = true;
    });

    this.setState({
      editor,
    });
  };

  processToday = async () => {
    // console.log("inside processToday !!!");
    let headline = "No News";
    let theNews = "";
    let theImage = "";
    let theVideo = "";
    let showImage = false;
    let showVideo = false;
    let videoFileType = "";

    // console.log("this.state.today:", this.state.today);
    let news = [];
    let topseqno = 0;
    // console.log("this.props.isMember:", this.props.isMember);
    // console.log('this.state.today.news.length:', this.state.today.news.length);

    if (
      this.state.today &&
      this.state.today.news &&
      this.state.today.news.length > 0
    ) {
      this.state.today.news.forEach((seq) => {
        // console.log("seq:", seq);
        // console.log("topseqno:", topseqno);
        if (topseqno === 0) {
          if (seq.isPublished) {
            if (seq.openTo === "member" && this.props.isMember)
              topseqno = seq.seqNo;
            if (seq.openTo === "public") topseqno = seq.seqNo;
          }
        }
        if (this.props.isMember && seq.isPublished) {
          news.push(seq);
        }
        if (!this.props.isMember) {
          if (seq.openTo === "public" && seq.isPublished) news.push(seq);
        }
      });
    }
    // console.log("topseqno:", topseqno);

    if (
      this.state.today &&
      this.state.today.news &&
      this.state.today.news.length > 0
    ) {
      // let i = 1;
      this.state.today.news.forEach((obj) => {
        // Just read the top news to show as default.
        // console.log("processToday obj:", obj);
        if (obj.seqNo === topseqno) {
          headline = obj.newsTitle;
          theNews = obj.news;
          if (obj.fileUploads.length > 0) {
            obj.fileUploads.forEach((elm) => {
              if (elm.type === "image") {
                theImage = elm.location;
                showImage = true;
              }
              if (elm.type === "video") {
                theVideo = elm.location;
                showVideo = true;
                videoFileType = "video/" + elm.key.split(".").pop();
              }
            });
          }
        }
        // i++;
      });
    }

    if (showImage) showVideo = false;

    await this.setState({
      news,
      headline,
      theNews,
      theImage,
      theVideo,
      showImage,
      showVideo,
      videoFileType,
    });
  };

  getToday = async () => {
    // let coopStoreId = 0;
    // if ( this.props.store ) coopStoreId = this.props.store.coopStoreId;
    try {
      let params = "communityId=" + this.props.communityId + "&coopStoreId=0";
      let url = baandaServer + getToday + params;
      // console.log("getToday url :", url);
      let news = await axios.get(url);
      // console.log(">>>>> news:", news);
      if (news.data.status === "success") {
        // console.log("news.data.Msg:", news.data.Msg);
        await this.setState({
          today: news.data.Msg,
        });
      } else {
        await this.setState({
          getNewsErr: news.data.Msg,
        });
      }
    } catch (err) {
      console.log("getToday error:", err.message);
      await this.setState({
        getNewsErr: err.message,
      });
    }
  };

  handleSelect = async (sel) => {
    // console.log("sel:", sel);
    if (sel === "editor") {
      await this.setState({
        todayFlag: false,
        editor: false,
        showEditor: true,
      });
    }
  };

  returnedFromEditor = async () => {
    // console.log("Returned from editor ...");
    await this.getToday();
    // console.log(
    //   "will go to processToday ... this.state.today:",
    //   this.state.today
    // );
    await this.processToday();
    // console.log("done process processToday ...");
    await this.setState({
      todayFlag: true,
      editor: true,
      showEditor: false,
    });
  };

  handleShow = async (inp) => {
    // console.log("handleShow inp:", inp);
    let headline = "No News";
    let theNews = "";
    let theImage = "";
    let theVideo = "";
    let showImage = false;
    let showVideo = false;
    let videoFileType = "";

    if (this.state.news.length > 0) {
      this.state.news.forEach((obj) => {
        if (obj.seqNo === inp.seqNo) {
          headline = obj.newsTitle;
          theNews = obj.news;
          if (obj.fileUploads.length > 0) {
            obj.fileUploads.forEach((elm) => {
              if (elm.type === "image") {
                theImage = elm.location;
                showImage = true;
              }
              if (elm.type === "video") {
                theVideo = elm.location;
                showVideo = true;
                videoFileType = "video/" + elm.key.split(".").pop();
              }
            });
          }
        }
      });
    }

    if (showImage) showVideo = false;

    await this.setState({
      headline,
      theNews,
      theImage,
      theVideo,
      showImage,
      showVideo,
      videoFileType,
    });
  };

  handleAudioVideo = async (inp) => {
    // console.log("handleAudioVideo inp:", inp);
    if (inp === "video") {
      await this.setState({
        showVideo: true,
        showImage: false,
      });
    }
    if (inp === "image") {
      await this.setState({
        showVideo: false,
        showImage: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("BoardHub...");

    let mainHeadlinePanel;
    mainHeadlinePanel = (
      <div className="hub-news-headline">{this.state.headline}</div>
    );

    // let showbig = false;
    // if (!this.state.showImage && !this.state.showVideo) showbig = true;

    let mainNewsPanel;
    mainNewsPanel = (
      <div>
        <p align="justify" className="hub-news-body">
          {this.state.theNews}
        </p>
      </div>
    );

    let imagePanel;
    if (this.state.showImage) {
      imagePanel = (
        <div>
          <img
            src={this.state.theImage}
            alt="pic"
            className="hub-image-viewx"
          />
        </div>
      );
    } else {
      imagePanel = (
        <div>
          <img src={noItemImage} alt="pic" className="hub-image-viewx" />
        </div>
      );
    }

    let videoPanel;
    videoPanel = (
      <div className="hub-video-disp-card">
        <video className="hub-video-size" controls poster="">
          <source src={this.state.theVideo} type={this.state.videoFileType} />
        </video>
      </div>
    );

    let bool = true;
    let theNewsListPanel;
    // console.log("this.state.news.length:", this.state.news.length);
    if (this.state.news.length > 0) {
      theNewsListPanel = (
        <div className="post-list-pos">
          <div className="text-center hub-list-head">Post List</div>
          <div className="fixedsize_news_list">
            {this.state.news.map((obj, i) => {
              // console.log(i, ". obj:", obj);
              return (
                <div key={i}>
                  <div
                    className={`${
                      bool ? "row-color-hub-drk" : "row-color-hub-lit"
                    }`}
                  >
                    <div className="row">
                      <div className="col-10 text-left news-title-list">
                        {obj.newsTitle}
                      </div>
                      <div className="col-2 text-left">
                        <button
                          className="btn-hub-showlist"
                          onClick={() => this.handleShow(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <b>O</b>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left news-message-list">
                        {obj.news.length > 35
                          ? obj.news.substring(0, 35) + "..."
                          : obj.news}
                      </div>
                    </div>
                  </div>
                  <div className="hub-gap" />
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let showVideoButton;
    if (this.state.theVideo !== "") {
      showVideoButton = (
        <button
          className="btn_reg_40 board-video-btn-pos"
          onClick={() => this.handleAudioVideo("video")}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          V
        </button>
      );
    }

    let showImageButton;
    if (this.state.theImage !== "") {
      showImageButton = (
        <button
          className="btn_reg_40 board-video-btn-pos"
          onClick={() => this.handleAudioVideo("image")}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          I
        </button>
      );
    }

    let todayPanel;
    // if (this.state.doEditFlag) {
    let todayDate = "";
    if (this.state.deviceSize === "big")
      todayDate = moment().format("MM-DD-YYYY");

    todayPanel = (
      <div className="text-center">
        <div className="row">
          <div className="col-10 text-center header-today">
            <b>{this.props.communityName}&nbsp;- Today</b>
          </div>
          <div className="col-2 text-center hub-date">
            {this.state.editor && this.state.deviceSize === "big" ? (
              <button
                className="btn_reg_70"
                onClick={() => this.handleSelect("editor")}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                Editor
              </button>
            ) : (
              todayDate
            )}
          </div>
        </div>
        <hr className="hr-header" />
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col text-center">{mainHeadlinePanel}</div>
            </div>

            {this.state.showImage ? (
              <div className="row">
                <div className="col-1 text-center">&nbsp;</div>
                <div className="col-10 text-center">{imagePanel}</div>
                <div className="col-1 text-center">{showVideoButton}</div>
              </div>
            ) : null}

            {this.state.showVideo ? (
              <div className="row">
                <div className="col-1 text-center">&nbsp;</div>
                <div className="col-10">{videoPanel}</div>
                <div className="col-1 text-center">{showImageButton}</div>
              </div>
            ) : null}

            <div className="row">
              <div className="fixedsize-newspart-only">{mainNewsPanel}</div>
            </div>
          </div>
          <div className="col-md-4">{theNewsListPanel}</div>
        </div>
      </div>
    ); 
    // }

    let outputPanel;
    if (this.state.todayFlag) {
      outputPanel = (
        <div>
          <div className="billboard-display-box">{todayPanel}</div>
        </div>
      );
    }

    if (this.state.showEditor) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <Editor
            returnToCaller={this.returnedFromEditor}
            communityId={this.props.communityId}
            commName={this.props.communityName}
            today={this.state.today}
            editor={this.props.baandaId}
            authorizedFuncs={this.props.authorizedFuncs}
            baandaId={this.props.baandaId}
            store={store}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default BoardHub;
