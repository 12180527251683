import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import axios from "axios";

import { emailValidation } from "../../../../../../../utils/emailValidation";
import { sortArrayOfObjects } from "../../../../../../../utils/sortArrayOfObjects";

import "./ViewATarget.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getTargetListObj = "/routes/interact/getTargetListObj?";
const updateTarget = "/routes/interact/updateTarget";

class ViewATarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showList: true,
      listDetails: [],
      addContact: false,
      targetListName: "",
      targetList: null,
      newName: "",
      newEmail: "",
      newCell: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
 
    await this.getTheTargetList();

    this.setState({
      targetListName: this.props.selectedTargetName,
    });
  };

  getTheTargetList = async () => {
 
    let param =
      "communityId=" +
      this.props.communityId +
      "&targetListName=" +
      this.props.selectedTargetName;
    let url = baandaServer + getTargetListObj + param;
    // console.log("url:", url);
    try {
      let tlret = await axios.get(url);
      if (tlret.data.status === "success") {
        // console.log("tlret.data.Msg.listDetails:", tlret.data.Msg.listDetails);
        let sorted = await sortArrayOfObjects(
          tlret.data.Msg.listDetails,
          "email",
          "dsc"
        );
        // console.log("sorted:", sorted);
        this.setState({
          // listDetails: tlret.data.Msg.listDetails,
          listDetails: sorted,
          targetList: tlret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          listDetails: [],
          errMsg: tlret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        listDetails: [],
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  validate = () => {
    let msg = "";
    let status = "success";
    if (this.state.newName === "") {
      msg = "Name cannot be blank. ";
      status = "error";
    }
    if (this.state.newEmail === "") {
      msg += "Email cannot be empty. ";
      status = "error";
    } else {
      let pret = emailValidation(this.state.newEmail);
      if (!pret) {
        msg += "Invalid email syntax. ";
        status = "error";
      }
    }

    return { status, Msg: msg };
  };

  addNewTarget = async () => {
    let val = this.validate();
    // console.log("val:", val);
    if (val.status === "success") {
      let exists = false;
      this.state.listDetails.forEach((ls) => {
        if (this.state.newEmail === ls.email) exists = true;
      });
      if (!exists) {
        let newList = [...this.state.listDetails];
        let obj = {
          name: this.state.newName,
          email: this.state.newEmail,
          cell: this.state.newCell,
        };

        newList.push(obj);

        let sorted = await sortArrayOfObjects(newList, "email", "dsc");

        this.setState({
          listDetails: sorted,
          newName: "",
          newEmail: "",
          newCell: "",
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg:
            "Email exists. To change name/phone delete the line and re-enter. ",
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
      });
    }
  };

  deleteThis = async (obj) => {
    // console.log("obj:", obj);

    let newList = [];
    this.state.listDetails.forEach((ls) => {
      if (ls.email !== obj.email) {
        newList.push(ls);
      }
    });

    let sorted = await sortArrayOfObjects(newList, "email", "dsc");

    this.setState({
      listDetails: sorted,
    });
  };

  exitTargetView = () => {
    this.props.returnToCaller();
  };

  updateTarget = async () => {
    try {
      let url = baandaServer + updateTarget;
      let input = {
        communityId: this.props.communityId,
        targetListName: this.props.selectedTargetName,
        listDetails: this.state.listDetails,
      };

      let uret = await axios.post(url, input);
      // console.log('uret.data:', uret.data);
      if (uret.data.status === "success") {
        let sorted = await sortArrayOfObjects(uret.data.Msg.listDetails, "email", "dsc");
        this.setState({
          listDetails: sorted,
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: uret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message ,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewATarget...');

    let saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.updateTarget}
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        Save
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitTargetView}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let addPanel;
    addPanel = (
      <div className="row new-entry-pos-tgt-view">
        <div className="col-4 text-left">
          <b>Name</b>&nbsp;
          <input
            name="newName"
            type="text"
            value={this.state.newName}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="tgt-view-new-name"
            placeholder=""
          />
        </div>
        <div className="col-4 text-left">
          <b>Email</b>&nbsp;
          <input
            name="newEmail"
            type="text"
            value={this.state.newEmail}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="tgt-view-new-name"
            placeholder=""
          />
        </div>
        <div className="col-3 text-left">
          <PhoneInput
            placeholder="Enter Cell number"
            value={this.state.newCell}
            onChange={(newCell) => this.setState({ newCell })}
            defaultCountry="US"
            country="US"
            className="brd-view-new-cell"
          />
        </div>
        <div className="col-1 text-left">
          <button
            className="btn_reg_30_small"
            type="button"
            onClick={this.addNewTarget}
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            +
          </button>
        </div>
      </div>
    );

    let bool = true;
    let listTargetPanel;
    if (this.state.listDetails.length > 0) {
      listTargetPanel = (
        <div className="text-center">
          <div className="brd-trg-view-box">
            <div>
              {this.state.listDetails.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={`${
                        bool
                          ? "row-existing-campaign-dr"
                          : "row-existing-campaign-lt"
                      }`}
                    >
                      <div className="row brd-list-view-hight">
                        <div className="col-4 text-left broadcast-filt-name-lbl">
                          {obj.name}
                        </div>
                        <div className="col-4 text-left broadcast-filt-name-lbl">
                          {obj.email}
                        </div>
                        <div className="col-2 text-left broadcast-filt-name-lbl">
                          {obj.cell}
                        </div>
                        {obj.additionalInfo ? (
                          <div className="col-1 text-left">
                            <button
                              className="btn_reg_30_small"
                              type="button"
                              onClick={() => this.showMore(obj)}
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              <i className="fa fa-folder-open" />
                            </button>
                          </div>
                        ) : (
                          <div className="col-1 text-left">&nbsp;</div>
                        )}

                        <div className="col-1 text-left">
                          <button
                            className="btn_exit_del_30"
                            type="button"
                            onClick={() => this.deleteThis(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fas fa-trash" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="row">
          <div className="col-8 test-right tg-view-header">
            Target = {this.props.selectedTargetName}
          </div>
          <div className="text-left">
            {backButton} &nbsp; {saveButton}
          </div>
        </div>

        {listTargetPanel}
        {addPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center brd-tg-view-msg-err"
              : "text-center brd-tg-view-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

ViewATarget.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(mapStateToProps, null)(withRouter(ViewATarget));
