import React, { Component } from "react";

import axios from "axios";

import { sortArrayOfObjects } from "../../../../../../utils/sortArrayOfObjects"
// import { sortArrayOfDate} from "../../../../../../utils/sortArrayOfDates";

import "./ShowBroadcastIntel.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getABroadcastLog = "/routes/interact/getABroadcastLog?";

class ShowBroadcastIntel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aBroadcast: null,
      broadcastList: []
    };
  }

  componentDidMount = async () => {
    await this.getABroadcast(); 
  };

  getABroadcast = async () => {
    let param =
      "communityId=" +
      this.props.communityId +
      "&broadcastId=" +
      this.props.theBroadcast.broadcastId;
    try {
      let url = baandaServer + getABroadcastLog + param;
      // console.log("url:", url);
      let bret = await axios.get(url);
      // console.log("bret.data:", bret.data);

      if (bret.data.status === "success") {
        // let sorted = await sortArrayOfObjects(bret.data.Msg, "broadcastDate", "dsc" );
        let sorted = await sortArrayOfObjects(bret.data.Msg.broadcasts, "broadcastDate", "asc" );
        // console.log('sorted:', sorted);
        this.setState({
          aBroadcast: bret.data.Msg,
          broadcastList : sorted,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: bret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  showResponses = async () => {};

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShowBroadcastIntel...');

    let header = (
      <div className="text-center brd-intel-header">
        Intel of: {this.props.theBroadcast.broadcastName}{" "}
      </div>
    );

    let listPanel;
    let bool = true;
    if (this.state.aBroadcast) {
      listPanel = (
        <div>
          <div className="row brd-header-pos">
            <div className="col-2 brd-hdr-lbl-name text-center">
              Campaign Name
            </div>
            <div className="col-8 brd-hdr-lbl-name text-left">
              Date | Targets | #of Clicks | Target Type | Cpn-Name | Coupon | Cpn-Type | %
            </div>
            {/* <div className="col-2 brd-hdr-lbl-name text-center">Resp-detail</div> */}
            <div className="col-2 brd-hdr-lbl-name text-center">&nbsp;</div>
          </div>
          <div className="fixedsize-brodcastlog-view">
            {this.state.broadcastList.map((post, i) => {
              return (
                <div key={i}>
                  <div
                    className={`${
                      bool ? "row-cat-item-color-dr" : "row-cat-item-color-lt"
                    }`}
                  >
                    <div className="row">
                      {post.campaignName ? (
                        <div className="col-2 text-left ">
                          {post.campaignName}
                        </div>
                      ) : (
                        <div className="col-2 text-left ">N/A</div>
                      )}

                      <div className="col-8 text-left">
                        {post.broadcastDate.substring(0, 10)}&nbsp;|&nbsp;
                        {post.numberOfTarget} | {post.numberOfHits} |{" "}
                        {post.targetFrom} | {post.targetListName} | {post.couponName ? post.couponName : "X"} | {post.couponType ? post.couponType : "X"} | {post.selectedCoupon ? post.selectedCoupon.percentDiscount : "0"}
                      </div>
                      <div className="col-2 text-center ">&nbsp;</div>
                      {/* {post.responses && post.responses.length > 0 ? (
                        <div className="col-2 text-center ">
                          <button
                            onClick={() => this.showResponses(post)}
                            className="btn_reg_50_open"
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            ...
                          </button>
                        </div>
                      ) : (
                        <div className="col-2 text-center ">
                          <button
                            // onClick={() => this.showResponses(post)}
                            className="btn_reg_50_open"
                            // style={{
                            //   cursor: this.state.disabled ? "default" : "pointer",
                            // }}
                          >
                            N/A
                          </button>
                        </div>
                      )} */}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="text-center">
        {header}
        {listPanel}
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default ShowBroadcastIntel;
