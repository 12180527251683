import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
// import ReactLoading from "react-loading";

import moment from "moment";

import noImageAvailable from "../../image/notavailable.png";
// import showNoImage from "../../image/blackback2.jpg";

import { arraySortNum } from "../../../../../utils/sortArrayOfObjectsNum";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import ScheduleToAttend from "../../../connect/calendar/event/buySell/ScheduleToAttend";

// import ViewEvent from "../../../connect/calendar/event/view/ViewEvent";

import "./CatalogServiceDetails.css";

const guestLoginId = process.env.REACT_APP_BAANDA_GUEST;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveItemInCart = "/routes/dashboard/saveItemInCart";
// const getAnEvent = "/routes/inetract/getAnEvent?";
const doesCartHaveSubscriptionItem =
  "/routes/dashboard/doesCartHaveSubscriptionItem?";

const readMeCode = "1112210000";

class CatalogServiceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      catalog: null,
      fileUploads: null,

      picS3FileData: null,
      videoS3FileData: null,
      showCustomFlag: false,
      showScheduleFlag: false,
      showMembershipflag: false,
      leftSlideFlag: false,
      rightSlideFlag: false,
      subscriptionFlag: false,

      showCustomServiceFlag: false,
      noSlideShowtest: false,
      currImageSeqno: 0,

      onSale: false,
      onCoupon: false,
      couponCode: "",
      salesPercent: 0,
      couponPercent: 0,
      discountType: "sales",

      serviceContext: "",
      theEvent: null,
      showAnEventFlag: false,

      cartOf: null,
      canCart: true,
      cartMsg: "",
      errFlag: false,

      showTheItemFlag: true,
      requestToBeInvited: false,
      showScheduleButtonFlag: false,

      showSchedulingPanel: false,
      showSellingPanel: true,
      schedulesToCart: [],
      imageFiles: null,
      qtyToBuy: 0,
      discountCouponCode: "",
      isValidCoupon: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let item = JSON.parse(
      JSON.stringify(this.props.sendCatalogItemDetails.anItem)
    );

    // console.log('>>> item:', item);

    let cartOf;
    if (this.props.cartOf) cartOf = this.props.cartOf;

    let picS3FileData = null;
    let videoS3FileData = null;
    let picExists = false;
    let countImage = 0;
    let showImageFlag = false;
    let showVideoFlag = false;
    let showImageButton = false;
    let rightSlideFlag = false;
    let showVideoButton = false;
    let noDGAsset = false;
    let currImageSeqno = 0;
    let discountType = "sales";

    let imageFiles = [];

    item.fileUploads.forEach((obj) => {
      if (obj.type === "image" && !picExists) {
        picExists = true;
        picS3FileData = obj;
        showImageFlag = true;
        showImageButton = true;
        currImageSeqno = 1;
      }
      if (obj.type === "image") {
        countImage++;
        imageFiles.push(obj);
      }
      if (obj.type === "video") {
        videoS3FileData = obj;
        showVideoFlag = true;
        showVideoButton = true;
      }
    });

    let noSlideShowtest = false;
    if (countImage > 1) noSlideShowtest = true;

    let sorted = await arraySortNum(imageFiles, "seqno", "dsc");

    if (showImageFlag) showVideoFlag = false;
    if (countImage > 1) rightSlideFlag = true;
    if (countImage === 1) showImageButton = false;
    if (!showImageFlag && !showVideoFlag) noDGAsset = true;
    if (showVideoButton) showImageButton = false;

    let availableQty = 1;

    let onSale = false,
      onCoupon = false;
    let salesPercent = 0,
      couponPercent = 0;
    let discountCouponCode = "";

    item.discounts.forEach((itm) => {
      if (
        moment().isSameOrAfter(moment(itm.startDate)) &&
        moment().isSameOrBefore(moment(itm.endDate))
      ) {
        if (itm.type === "sales") {
          onSale = true;
          salesPercent = itm.percentage;
        }
        if (itm.type === "coupons") {
          onCoupon = true;
          couponPercent = itm.percentage;
          discountCouponCode = itm.code;
        }
      }
    });
    // if (!onSale && !onCoupon) discountType = "";
    // if (!onSale && onCoupon) discountType = "coupon";
    if (!onSale && !onCoupon) discountType = "";
    if (!onSale && onCoupon) discountType = "coupon";

    let showCustomFlag = false;
    if (item.service.type === "custom") showCustomFlag = true;
    let showScheduleFlag = false;
    if (item.service.type === "scheduled") showScheduleFlag = true;
    let showMembershipflag = false;
    if (item.service.type === "membership") showMembershipflag = true;

    let subscriptionFlag = false;
    if (item.subscription && item.subscription.allowSubscription)
      subscriptionFlag = true;

    // let serviceEventScheduled = false;
    let requestToBeInvited = false;
    let canCart = true;
    let showScheduleButtonFlag = false;

    let cartMsg = "";
    // console.log(">>> item:", item);
    // console.log(">>> item.service.type:", item.service.type);
    if (item.service.type === "scheduled") {
      // console.log(">>> in here 11111 item.itemExposure:", item.itemExposure);
      canCart = false;
      if (item.itemExposure === "public") {
        // console.log(">>> item.eventTaggingOnly:", item.eventTaggingOnly);
        if (item.eventTaggingOnly) {
          // Can attend only via invitation
          // If someone is here  means they have not been invited.
          if (item.service.reqInvitation === "yes") {
            requestToBeInvited = true; // Show the Cart-It button if user want to request an invite.
          } else {
            cartMsg = "Private event; participation with invitation only.";
          }
          // } else {
          // One can buy a spot without being invited if there is a future event scheduled and opening available.
          if (item.taggedByEvent && item.taggedByEvent.length > 0) {
            let anyEventInFuture = false;
            item.taggedByEvent.forEach((obj) => {
              // console.log('moment(obj.eventEndDate).isSameOrAfter(moment()):', moment(obj.eventEndDate).isSameOrAfter(moment()));
              if (moment(obj.eventEndDate).isSameOrAfter(moment())) {
                anyEventInFuture = true;
              }
            });
            if (anyEventInFuture) {
              showScheduleButtonFlag = true;
              cartMsg = "Please select your schedule to attend.";
            } else {
              cartMsg = "No event for this service has been scheduled yet.";
              if (item.service.reqInvitation === "yes") {
                requestToBeInvited = true; // Show the Cart-It button if user want to request an invite.
              }
            }
          } else {
            cartMsg = "No event for this service has been scheduled yet.";
            if (item.service.reqInvitation === "yes") {
              requestToBeInvited = true; // Show the Cart-It button if user want to request an invite.
            }
          }
        }
      } else if (item.itemExposure === "community") {
        if (await this.isThisACommunityMember()) {
          if (item.eventTaggingOnly) {
            // Can attend only via invitation
            // If someone is here means they have not been invited.
            if (item.service.reqInvitation === "yes") {
              requestToBeInvited = true; // Show the Cart-It button if user want to request an invite.
            } else {
              cartMsg = "Private event; participation with invitation only.";
            }
          } else {
            // One can buy a spot without being invited if there is a future event scheduled and opening available.
            if (
              this.state.taggedByEvent &&
              this.state.taggedByEvent.length > 0
            ) {
              let anyEventInFuture = false;
              this.state.taggedByEvent.forEach((obj) => {
                if (moment(obj.eventEndDate).isSameOrAfter(moment())) {
                  anyEventInFuture = true;
                }
              });
              if (anyEventInFuture) {
                showScheduleButtonFlag = true;
                cartMsg = "Please select your schedule to attend.";
              } else {
                cartMsg = "No event for this service has been scheduled yet.";
                if (item.service.reqInvitation === "yes") {
                  requestToBeInvited = true; // Show the Cart-It button if user want to request an invite.
                }
              }
            } else {
              canCart = false;
              cartMsg = "No event for this service has been scheduled yet.";
              if (item.service.reqInvitation === "yes") {
                requestToBeInvited = true; // Show the Cart-It button if user want to request an invite.
                canCart = false;
              }
            }
          }
        } else {
          canCart = false;
          cartMsg = "This service is opend to community members only.";
        }
      } else {
        cartMsg = "Private event; participation with invitation only.";
        canCart = false;
      }
    } else {
    }

    await this.setState({
      deviceSize,
      serviceContext: item.service.type,
      cartOf,
      catalog: item,
      fileUploads: item.fileUploads,
      picS3FileData,
      videoS3FileData,
      showImageFlag,
      showVideoFlag,
      countImage,
      showImageButton,
      showVideoButton,
      rightSlideFlag,
      noSlideShowtest,
      noDGAsset,
      picExists,
      imageFiles: sorted,
      currImageSeqno,
      availableQty,
      onSale,
      onCoupon,
      discountType,
      salesPercent,
      couponPercent,
      showCustomFlag,
      showScheduleFlag,
      showMembershipflag,
      subscriptionFlag,
      canCart,
      cartMsg,
      requestToBeInvited,
      showScheduleButtonFlag,
      discountCouponCode,
    });
  };

  isThisACommunityMember = async () => {
    let isMember = false;
    this.props.community.members.forEach((obj) => {
      if (obj.baandaId === this.props.auth.user.baandaId) isMember = true;
    });

    return isMember;
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleSlideImageRight = async () => {
    // console.log("handleSlideImageRight");
    let currImageSeqno = parseFloat(this.state.currImageSeqno) + 1;
    if (currImageSeqno > this.state.imageFiles.length)
      currImageSeqno = this.state.digitalS3FileData.length;
    let picS3FileData;
    this.state.imageFiles.forEach((obj) => {
      if (obj.seqno === currImageSeqno) picS3FileData = obj;
    });

    let rightSlideFlag = true;
    let leftSlideFlag = false;
    if (currImageSeqno === this.state.imageFiles.length) {
      rightSlideFlag = false;
    }
    if (currImageSeqno > 1) leftSlideFlag = true;

    // console.log("right currImageSeqno:", currImageSeqno);
    await this.setState({
      currImageSeqno,
      picS3FileData,
      rightSlideFlag,
      leftSlideFlag,
    });
  };

  handleSlideImageLeft = async () => {
    // console.log("handleSlideImageLeft");
    let currImageSeqno = parseFloat(this.state.currImageSeqno) - 1;
    if (currImageSeqno === 0) currImageSeqno = 1;
    let picS3FileData;
    this.state.imageFiles.forEach((obj) => {
      if (obj.seqno === currImageSeqno) picS3FileData = obj;
    });

    let rightSlideFlag = false;
    let leftSlideFlag = true;
    if (currImageSeqno < this.state.imageFiles.length) {
      rightSlideFlag = true;
    }
    if (currImageSeqno === 1) leftSlideFlag = false;
    // console.log("left currImageSeqno:", currImageSeqno);
    await this.setState({
      currImageSeqno,
      picS3FileData,
      rightSlideFlag,
      leftSlideFlag,
    });
  };

  handleShowVideoFlag = async () => {
    // console.log("handleShowVideoFlag");
    // Get the right object from fileUploads to picS3FileData

    let availableQty = this.state.availableQty;
    if (this.state.catalog.itemVariance.length > 0) availableQty = 0;

    await this.setState({
      leftSlideFlag: false,
      picExists: false,
      rightSlideFlag: false,
      showImageButton: true,
      showVideoButton: false,
      showVideoFlag: true,
      showImageflag: false,
      noSlideShowtest: false,
      showVarianceFlag: false,
      availableQty,
    });
  };

  handleShowImageFlag = async () => {
    let availableQty = this.state.availableQty;
    if (this.state.catalog.itemVariance.length > 0) availableQty = 0;

    let currImageSeqno = 0;
    let rightSlideFlag = false;
    let picS3FileData = null;
    let picExists = true;
    let showImageFlag = true;
    let noDGAsset = false;
    if (this.state.imageFiles.length > 0) {
      currImageSeqno = 1;
      picS3FileData = this.state.imageFiles[0];
      if (this.state.imageFiles.length > 1) rightSlideFlag = true;
    } else {
      picExists = false;
      showImageFlag = false;
    }

    let showVideoFlag = true;
    let showVideoButton = true;
    if (!this.state.videoS3FileData) {
      showVideoButton = false;
      showVideoFlag = false;
    }

    if (!showVideoFlag && !showImageFlag) noDGAsset = true;

    await this.setState({
      picS3FileData,
      currImageSeqno,
      leftSlideFlag: false,
      picExists,
      rightSlideFlag,
      showImageButton: false,
      showVideoButton,
      showVideoFlag: false,
      showImageflag: false,
      noDGAsset,
      noSlideShowtest: true,
      showVarianceFlag: false,
      availableQty,
    });
  };

  returnToCatalog = () => {
    this.props.returnToCaller();
  };

  returnToPagi = () => {
    this.props.returnToDetails();
  };

  handleDiscountType = async (e) => {
    // let value = e.target.value;
    await this.setState({
      discountType: e.target.value,
    });
  };

  handleInTheCartSave = async () => {
    // console.log("inhandleInthecartsave...");
    let coopStoreId = this.props.sendCatalogItemDetails.anItem.coopStoreId;

    if (this.props.sendCatalogItemDetails.anItem.subscription) {
      // console.log("in subscription...");
      let baandaId = this.props.auth.user.baandaId;
      if (this.props.auth.user.email === guestLoginId) {
        baandaId = this.props.guestCartId;
      }
      let params =
        "baandaId=" +
        baandaId +
        "&communityId=" +
        this.props.community.communityId +
        "&coopStoreId=" +
        coopStoreId +
        "&itemId=" +
        this.props.sendCatalogItemDetails.anItem.itemId;
      let url = baandaServer + doesCartHaveSubscriptionItem + params;
      // console.log("url:", url);
      try {
        let resp = await axios.get(url);
        // console.log("resp:", resp);
        if (resp.data.status === "success") {
          if (
            resp.data.Msg.hasSub === "no" &&
            resp.data.Msg.hasTheSameCustomService === "no"
          ) {
            await this.putItInTheCart();
          } else {
            let msg = "";
            let errFlag = false;
            if (resp.data.Msg.hasSub === "yes") {
              msg =
                "A cart cannot have multiple subscription item. Buy it separately.";
              errFlag = true;
            }
            if (resp.data.Msg.hasTheSameCustomService === "yes") {
              msg = msg + "The cart already has this custom service.";
              errFlag = true;
            }
            await this.setState({
              cartMsg: msg,
              errFlag,
            });
          }
        } else {
          await this.setState({
            cartMsg: resp.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handleInTheCartSave err: ", err.message);
        await this.setState({
          cartMsg: err.message,
        });
      }
    } else {
      // console.log("Just putting in the cart - no subscription...");
      await this.putItInTheCart();
    }
  };

  putItInTheCart = async () => {
    // console.log("putitemincart...");
    let price = this.state.catalog.itemPrice;
    let quantity = 1;
    let taggedByEvent = null;
    // console.log(
    //   "this.props.sendCatalogItemDetails.anItem.taggedByEvent:",
    //   this.props.sendCatalogItemDetails.anItem.taggedByEvent
    // );
    if (this.state.catalog.service.type === "scheduled") {
      if (this.state.catalog.eventTaggingOnly) {
        price = this.state.catalog.itemPrice;
        quantity = this.state.schedulesToCart.length;
        if (this.props.sendCatalogItemDetails.anItem.taggedByEvent) {
          taggedByEvent =
            this.props.sendCatalogItemDetails.anItem.taggedByEvent;
        }
      }
    }

    // console.log("@@@ taggedByEvent:", taggedByEvent);

    if (this.state.catalog.service.type === "custom") {
      if (this.state.catalog.service.customQuotingFee === 0) price = 0;
      else price = this.state.catalog.service.customQuotingFee;
    }

    let discount = [];

    this.state.catalog.discounts.forEach((obj) => {
      if (
        moment().isSameOrBefore(moment(obj.endDate)) &&
        moment().isSameOrAfter(moment(obj.startDate))
      ) {
        let discobj = {
          inDb: true,
          type: obj.type,
          name: obj.name,
          startDate: moment(obj.startDate),
          endDate: moment(obj.endDate),
          percentage: obj.percentage,
          code: obj.code,
        };
        discount.push(discobj);
      }
    });

    // console.log("this.state.discountType:", this.state.discountType);
    // console.log("this.state.couponPercent:", this.state.couponPercent);

    let discountPercent = 0;
    if (this.state.discountType === "sales")
      discountPercent = this.state.salesPercent;
    if (this.state.discountType === "coupon" && this.state.isValidCoupon) {
      discountPercent = this.state.couponPercent;
    }
    // console.log("discountPercent:", discountPercent);

    let cartMsg = "";
    if (this.state.catalog.service.type === "custom") {
      if (this.state.catalog.service.bookingType === "negotiated") {
        cartMsg =
          "On checkout, check your tasklist to specify your requirement to start the quote/price process.";
      }
    }

    let subscription = null;

    // if (this.props.sendCatalogItemDetails.anItem.subscription.allowSubscription)
    subscription = this.props.sendCatalogItemDetails.anItem.subscription;

    // let isValid = true;
    // if (isValid) {
    let cartOfA = "baanda";
    let baandaId = this.props.cartOf.baandaId;
    if (this.props.auth.user.email === guestLoginId) {
      cartOfA = "guest";
      baandaId = Number(this.props.guestCartId);
    }
    try {
      let url = baandaServer + saveItemInCart;
      let data = {
        cartOfA,
        baandaId,
        customerName: this.props.cartOf.name,
        customerEmail: this.props.cartOf.email,
        communityId: this.props.community.communityId,
        coopStoreId: this.props.sendCatalogItemDetails.anItem.coopStoreId,
        itemId: this.state.catalog.itemId,
        itemName: this.state.catalog.itemName,
        price,
        quantity,
        subItem: null,
        subItems: null,
        service: this.state.catalog.service,
        eventTaggingOnly: this.state.catalog.eventTaggingOnly,
        discountEnabled: this.state.catalog.discountEnabled,
        discount,
        discountType: this.state.discountType,
        couponCode: this.state.couponCode,
        discountPercent,
        taxInfo: this.state.catalog.taxInfo,
        itemType: this.state.catalog.itemType,
        unitType: this.state.catalog.unitName,
        cartOfType: this.props.caller,
        schedulesToCart: this.state.schedulesToCart,
        subscription,
        taggedByEvent,
        clientProgram: "CatalogServiceDetails",
        clientFunction: "handleInTheCartSave",
      };
      // console.log("handleInTheCartSave ... url:", url, " data:", data);
      let ret = await axios.post(url, data);
      // console.log("ret.data:", ret.data);
      let errMsg = "";
      let errFlag = false;
      let catalog = this.state.catalog;
      let qtyToBuy = this.state.qtyToBuy;
      let couponCode = this.state.couponCode;
      if (ret.data.status === "success") {
        errMsg = "Carted. Click Back to shop/checkout.";
        errFlag = false;
        catalog = ret.data.Msg;
        qtyToBuy = 0;
        couponCode = "";
      } else {
        errMsg = ret.data.Msg;
        errFlag = true;
      }

      await this.setState({
        errMsg,
        errFlag,
        catalog,
        qtyToBuy,
        couponCode,
        cartMsg,
      });
      // document.getElementById("inTheCart").checked = false;
    } catch (err) {
      console.log("handleInTheCartSave error:", err.message);
    }
    // }
  };

  handleScheduling = async () => {
    await this.setState({
      showSchedulingPanel: true,
      showSellingPanel: false,
    });
  };

  returnToShowSellPanel = async (schedule) => {
    // console.log("schedule:", schedule);
    let canCart = false;
    if (schedule && schedule.length > 0) canCart = true;

    await this.setState({
      showSchedulingPanel: false,
      showSellingPanel: true,
      canCart,
      schedulesToCart: schedule,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    // console.log("name:", name);
    let isValidCoupon = false;
    if (name === "couponCode") {
      if (value === this.state.discountCouponCode) {
        isValidCoupon = true;
      }
    }
    this.setState({
      [e.target.name]: e.target.value,
      isValidCoupon,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CatalogServiceDetails...");

    let isSaleOn = false;
    let discountOn = false;
    let plainSalesOn = false;
    let couponCode = "";
    let couponper = 0;
    let salesper = 0;
    if (this.state.catalog) {
      this.state.catalog.discounts.forEach((obj) => {
        // console.log("707 obj: ", obj);
        if (
          moment().isSameOrAfter(moment(obj.startDate)) &&
          moment().isSameOrBefore(obj.endDate)
        ) {
          isSaleOn = true;
          if (obj.type === "coupons") {
            couponCode = obj.code;
            discountOn = true;
            couponper = obj.percentage;
          }
          if (obj.type === "sales") {
            plainSalesOn = true;
            salesper = obj.percentage;
          }
        }
      });
    }

    let selectDiscountPanel;
    if (this.state.onCoupon && this.state.onSale) {
      selectDiscountPanel = (
        <div>
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Discount Type&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="sales"
                    checked={this.state.discountType === "sales"}
                    onChange={this.handleDiscountType}
                  />{" "}
                  Sales
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="coupons"
                    checked={this.state.discountType === "coupons"}
                    onChange={this.handleDiscountType}
                  />{" "}
                  Coupon
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let salesPanel;
    if (isSaleOn) {
      salesPanel = (
        <div>
          {selectDiscountPanel}
          <div className="row">
            <div className="col-3 text-right cat-serv-show-lbl">Sales</div>
            <div className="col-9 text-left cat-show-value">
              {plainSalesOn ? salesper + "%;  " : null}
              {discountOn ? "Discount with coupon " + couponper + "%" : null}
            </div>
          </div>

          {discountOn ? (
            <div className="row">
              <div className="col-3 text-right cat-serv-show-lbl">Coupon</div>
              <div className="col-9 text-left cat-show-value">
                <input
                  name="couponCode"
                  type="text"
                  value={this.state.couponCode}
                  onChange={this.onChange}
                  size="10"
                  maxLength="10"
                  className="cat-coupon-code"
                  placeholder="Code "
                />{" "}
                &nbsp;&nbsp;
                {this.state.couponCode === couponCode
                  ? "Approved"
                  : "Enter a valid code"}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let noImgItemSelPanel;
    noImgItemSelPanel = (
      <div>
        <img
          src={noImageAvailable}
          alt="pic"
          className="catalog-img-sel-catview"
        />
      </div>
    );

    let imagePanel;
    if (this.state.picS3FileData) {
      imagePanel = (
        <div>
          <img
            src={this.state.picS3FileData.location}
            alt="pic"
            className="cat-show-image-view"
          />
        </div>
      );
    }

    let videoPanel;
    if (this.state.videoS3FileData) {
      videoPanel = (
        <div>
          <video className="catshow-video-card" controls autoPlay>
            <source
              src={this.state.videoS3FileData.location}
              type={this.state.videoFileType}
            />
          </video>
        </div>
      );
    }

    let digitalButtons;
    digitalButtons = (
      <div className="catalog-da-position">
        {this.state.leftSlideFlag ? (
          <button
            onClick={this.handleSlideImageLeft}
            className="btn_reg_40"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fa fa-chevron-left" />
          </button>
        ) : this.state.noSlideShowtest ? (
          <button className="btn_reg_40_disabled">
            <i className="fa fa-chevron-left" />
          </button>
        ) : null}
        {/* {this.state.picExists && this.state.noSlideShowtest ? (
          <button className="btn-pic-exists">Slide Image</button>
        ) : null} */}
        {this.state.rightSlideFlag ? (
          <button
            onClick={this.handleSlideImageRight}
            className="btn_reg_40"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fa fa-chevron-right" />
          </button>
        ) : this.state.noSlideShowtest ? (
          <button className="btn_reg_40_disable">
            <i className="fa fa-chevron-right" />
          </button>
        ) : null}

        {this.state.showVideoButton ? (
          <button
            onClick={this.handleShowVideoFlag}
            className="btn_reg_60"
            // className="btn-dgasset-showcat"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              "Video"
            ) : (
              <i className="fas fa-video" />
            )}
          </button>
        ) : null}
        {this.state.showImageButton ? (
          <button
            onClick={this.handleShowImageFlag}
            className="btn_reg_60"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              "Image"
            ) : (
              <i className="fas fa-image" />
            )}
          </button>
        ) : null}
      </div>
    );

    let catShowOpsButtons;
    catShowOpsButtons = (
      <div>
        {/* <button
          onClick={this.handleReviews}
          className="btn-basecat-review"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Reviews
        </button> */}
        {this.state.canCart ? (
          <button
            onClick={this.handleInTheCartSave}
            className="btn_reg_80"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              "Cart-It"
            ) : (
              <i className="fas fa-shopping-cart" />
            )}
          </button>
        ) : null}
        {this.state.showScheduleButtonFlag ? (
          <button
            onClick={this.handleScheduling}
            className="btn_reg_80"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            Schedule
          </button>
        ) : null}
        <button
          onClick={this.returnToPagi}
          className="btn_back_main"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          <i className="fas fa-step-backward" />
        </button>
        <button
          onClick={this.openAlertModal}
          className="btn_info_main"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let digitalAssetPanel;
    if (this.state.showImageFlag) {
      digitalAssetPanel = (
        <div>
          {this.state.picS3FileData ? imagePanel : noImgItemSelPanel}
          {digitalButtons}
        </div>
      );
    }
    if (this.state.showVideoFlag) {
      digitalAssetPanel = (
        <div>
          {this.state.videoS3FileData ? videoPanel : "No Video Uploaded"}
          {digitalButtons}
        </div>
      );
    }

    if (this.state.noDGAsset) {
      digitalAssetPanel = (
        <div>
          {noImgItemSelPanel}
          {/* {digitalButtons} */}
        </div>
      );
    }

    let descPanel;
    descPanel = (
      <div className="text-center">
        {this.state.catalog ? (
          <p align="justify" className="fixedsize-catshow-desc">
            {this.props.sendCatalogItemDetails.anItem.itemDescription}
          </p>
        ) : null}
      </div>
    );

    let showErrPanel;
    if (this.state.errMsg !== "") {
      showErrPanel = (
        <div
          className={
            this.state.errFlag
              ? "text-center cat-show-msg-err"
              : "text-center cat-show-msg"
          }
        >
          {this.state.errMsg}
        </div>
      );
    }

    let ren;
    if (this.state.deviceSize === "small") {
      ren = "Rendered";
    } else {
      ren = "Rendered Via";
    }

    let customServicePanel;
    if (this.state.catalog && this.state.showCustomFlag) {
      let csrv = this.state.catalog.service;
      customServicePanel = (
        <div className="text-center ">
          <div className="text-center custom-service-header">
            Customized Service
          </div>
          <div className="row">
            <div className="col-4 text-right custom-lbl">Booking Type</div>
            <div className="col-8 text-left custom-val">
              {csrv.bookingType.toUpperCase()}
            </div>
          </div>
          {csrv.customQuotingFee > 0 ? (
            <div className="row">
              <div className="col-4 text-right custom-lbl">
                Quote Request Fee
              </div>
              <div className="col-8 text-left custom-val">
                {this.commaFormattedCurrency(csrv.customQuotingFee)}
              </div>
            </div>
          ) : (
            <div>
              {" "}
              {this.state.catalog &&
              this.state.catalog.service.type === "custom" ? (
                <div className="row">
                  <div className="col-4 text-right custom-lbl">
                    Quote Request Fee
                  </div>
                  <div className="col-8 text-left custom-val">Free</div>
                </div>
              ) : null}
            </div>
          )}
          <div className="row">
            <div className="col-4 text-right custom-lbl">{ren}</div>
            <div className="col-8 text-left custom-val">
              {csrv.renderedVia.toUpperCase()}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right custom-lbl">Process</div>
            <div className="col-8 text-left custom-service-msg">
              After you complete the checkout process, click on the 'Operate'
              button in the community landing page. Open the task associated
              with this order to initiate the quotation process and open a line
              of communication.
            </div>
          </div>
        </div>
      );
    }

    let schedulePanel;
    if (this.state.catalog && this.state.showScheduleFlag) {
      let csrv = this.state.catalog.service;
      schedulePanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right cat-serv-show-lbl">
              Rendered Via
            </div>
            <div className="col-9 text-left cat-show-value">
              {csrv.renderedVia.toUpperCase()}
            </div>
          </div>
        </div>
      );
    }

    let subscription;
    if (this.state.deviceSize === "small") {
      subscription = "SubScr";
    } else {
      subscription = "Subscription";
    }

    let subscriptionPanel;
    if (this.state.subscriptionFlag) {
      subscriptionPanel = (
        <div className="text-center">
          <div className="row">
            <div className="col-3 text-right cat-serv-show-lbl">
              {subscription}
            </div>
            <div className="col-9 text-left cat-serv-subscription-text">
              APR: <b>{this.state.catalog.subscription.interestRate}%</b>
              &nbsp;&nbsp;Interval:{" "}
              <b>{this.state.catalog.subscription.intervalType}</b>&nbsp;&nbsp;
              Number of Payment:
              <b>
                &nbsp;
                {this.state.catalog.subscription.subscriptionType ===
                "continuous"
                  ? "Periodic, until canceled"
                  : this.state.catalog.subscription.numberOfInstallment}
              </b>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right cat-serv-show-lbl">Note</div>
            <div className="col-9 text-left cat-serv-subscription-text">
              {this.state.catalog.subscription.subscriptionNote}
            </div>
          </div>
        </div>
      );
    }

    let topDisplayPanel;
    if (this.state.showSellingPanel) {
      topDisplayPanel = (
        <div>
          <div className="row catalog-top-image-bottom-buffer">
            <div className="col-md-6 cat-view-top-left text-center">
              {digitalAssetPanel}
            </div>
            <div className="col-md-6 cat-view-top-right text-center">
              {descPanel}
            </div>
          </div>
          {this.state.showCustomFlag ? null : (
            <div className="row">
              <div className="col-3 text-right cat-serv-show-lbl">Price</div>
              <div className="col-9 text-left cat-show-value">
                $&nbsp;
                {this.state.catalog
                  ? this.commaFormattedCurrency(this.state.catalog.itemPrice) +
                    " " +
                    this.state.catalog.unitName
                  : null}
                {this.state.catalog &&
                this.state.catalog.subscription &&
                this.state.catalog.subscription.registrationFee > 0
                  ? " (Registration: $" +
                    this.commaFormattedCurrency(
                      this.state.catalog.subscription.registrationFee
                    ) +
                    ")"
                  : ""}
              </div>
            </div>
          )}
          {customServicePanel}
          {salesPanel}
          {schedulePanel}
          {subscriptionPanel}
          <div className="catalog-service-ops-button-pos">
            {catShowOpsButtons}
          </div>
          {this.state.cartMsg === "" ? null : (
            <div
              className={
                this.state.errFlag
                  ? "text-center service-cart-msg-err"
                  : "text-center service-cart-msg"
              }
            >
              {this.state.cartMsg}
            </div>
          )}
          {/* {this.state.cartMsg === "" ? null : (
            <div className="text-center service-cart-msg">
              {this.state.cartMsg}
            </div>
          )} */}
          {showErrPanel}
        </div>
      );
    }

    if (this.state.showSchedulingPanel) {
      let store = null;
      if (this.props.store) store = this.props.store;
      topDisplayPanel = (
        <div>
          <ScheduleToAttend
            taggedByEvent={this.state.catalog.taggedByEvent}
            communityId={this.state.catalog.communityId}
            returnToCaller={(schedule) => this.returnToShowSellPanel(schedule)}
            store={store}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.showTheItemFlag) {
      outputPanel = (
        <div className="">
          {/* <div className="cat-show-display-box"></div> */}
          <div className="text-center show-cat-name-head">
            {this.state.catalog ? this.state.catalog.itemName : null}
          </div>
          {topDisplayPanel}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CatalogServiceDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CatalogServiceDetails));
