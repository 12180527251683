import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./TaskMain.css";

import TaskDetails from "./TaskDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getActiveTasks = "/routes/task/getActiveTasks?";

const readMeCode = "1130100000";

class TaskMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taskStatus: "new",

      errMsg: "",
      errFlag: false,

      showTaskList: true,
      showTaskDetails: false,
 
      tasks: [],
      selectedTask: null,
    };
  }

  componentDidMount = async () => {
    await this.getTasks();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTasks = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&responderBaandaId=" +
      this.props.auth.user.baandaId +
      "&taskStatus=" +
      this.state.taskStatus +
      "&type=merchant&purpose=responder";
    let url = baandaServer + getActiveTasks + params;
    // console.log("url:", url);
    try {
      let tskret = await axios.get(url);
      // console.log("tskret.data:", tskret.data);
      if (tskret.data.status === "success") {
        if (tskret.data.Msg.length > 0) {
          this.setState({
            tasks: tskret.data.Msg,
            errMsg: "",
            errFlag: false,
          });
        } else {
          this.setState({
            tasks: tskret.data.Msg,
            errMsg: "No tasks found for the status = " + this.state.taskStatus,
            errFlag: false,
          });
        }
      } else {
        this.setState({
          tasks: [],
          errMsg: tskret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        tasks: [],
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleTaskStatusSelect = async (e) => {
    await this.setState({
      taskStatus: e.target.value,
    });
    await this.getTasks();
  };

  handleTaskDetails = async (tsk) => {
    // console.log("tsk:", tsk);
    this.setState({
      showTaskList: false,
      showTaskDetails: true,
      selectedTask: tsk,
    });
  };

  returnFromTaskDetails = async (ret) => {
    // console.log("ret:", ret);
    await this.getTasks();
    this.setState({
      showTaskList: true,
      showTaskDetails: false,
      selectedTask: null,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('TaskMain...');

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let selectStatusPanel;
    selectStatusPanel = (
      <div className="row comm-status-select-row">
        <div className="col text-center radio-font-style">
          <strong>Task Status&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.taskStatus === "new"}
                onChange={this.handleTaskStatusSelect}
              />{" "}
              New
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="wip"
                checked={this.state.taskStatus === "wip"}
                onChange={this.handleTaskStatusSelect}
              />{" "}
              Work In progress
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="done"
                checked={this.state.taskStatus === "done"}
                onChange={this.handleTaskStatusSelect}
              />{" "}
              Done
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="canceled"
                checked={this.state.taskStatus === "canceled"}
                onChange={this.handleTaskStatusSelect}
              />{" "}
              Canceled
            </label>
          </div>
        </div>
      </div>
    );

    let taskListPanel;
    let bool = true;
    taskListPanel = (
      <div>
        {this.state.tasks.map((obj, i) => {
          return (
            <div key={i}>
              <div
                className={
                  bool ? "row row-col-task-list-dr" : "row row-col-task-list-lt"
                }
              >
                <div className="col-11 text-left comm-task-list-lbl">
                  {obj.taskId} | {obj.itemName} |{" "}
                  {obj.taskInitiatedAt.substring(0, 10)} | {obj.taskName} |{" "}
                  {obj.taskStatus} | {obj.targetContact.name}
                </div>
                <div className="col-1 text-left comm-task-list-btn-pos">
                  <button
                    onClick={() => this.handleTaskDetails(obj)}
                    className="btn_reg_50"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    Open
                  </button>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          );
        })}
      </div>
    );

    let outputPanel;
    if (this.state.showTaskList) {
      outputPanel = (
        <div>
          {/* <div className="text-center comm-task-select">{selectStatusPanel}</div> */}
          <div className="biz-task-main-box">
            <div className="text-center comm-task-select">
              {selectStatusPanel}
            </div>
            <div className="comm-task-header">
              Tasks &nbsp;&nbsp;{helpModalButton}
            </div>
            <div className="row comm-tasklist-head-pos">
              <div className="col-11 text-left comm-tasklist-head-lbl-l">
                id | Item Name | Initiated On | Task Name | Status | Customer
              </div>
              <div className="col-1 text-left comm-tasklist-head-lbl-r">
                Ops
              </div>
            </div>
            <div className="fixedsize-comm-task-list-box">{taskListPanel}</div>
            <div
              className={
                this.state.errFlag
                  ? "text-center comm-task-msg-err"
                  : "text-center comm-task-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.showTaskDetails) {
      outputPanel = (
        <div>
          <div>
            <TaskDetails
              communityId={this.props.communityId}
              community={this.props.community}
              theTask={this.state.selectedTask}
              returnToCaller={this.returnFromTaskDetails}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

TaskMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TaskMain)
);
