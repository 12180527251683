import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";
import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { emailValidation } from "../../../../utils/emailValidation";

import "./UpdateBizFinalStep.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateStripe = "/routes/stripe/updateStripe";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const readMeCode = "1151141000";

class UpdateBizFinalStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pfirst_name: "",
      plast_name: "",
      pday: 0,
      pmonth: 0,
      pyear: 0,
      prdir: "no",
      prexec: "no",
      prowner: "no",
      prpercent: 0,
      prtitle: "",
      prssnlast4: "",

      deviceSize: "",
      loadingFlag: false,

      updateBeginFlag: false,
      updateDoneFlag: false,

      errBizMsg: "",
      errCompMsg: "",
      errEAMsg: "",
      errPersonMsg: "",
      errIdMsg: "",

      errBizFlag: false,
      errCompFlag: false,
      errEAFlag: false,
      errPersonFlag: false,
      errIdFlag: false,

      errFlg: false,

      ccdataForUpdate: {},
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleBackFromFinal = async () => {
    this.props.handleBack();
  };

  handleCancel = async () => {
    this.props.handleCancel();
  };

  handleDirector = async (e) => {
    this.setState({
      prdir: e.target.value,
    });
  };

  handleExecutive = async (e) => {
    this.setState({
      prexec: e.target.value,
    });
  };

  handleOwner = async (e) => {
    this.setState({
      prowner: e.target.value,
    });
  };

  repackageCCdata = async () => {
    let updtccdata = "nothing";
    let ccd = this.props.ccdata;

    let ccp = ccd.person;

    var dobdd = String(ccp.dob.getDate()).padStart(2, "0");
    var dobmonth = String(ccp.dob.getMonth() + 1);

    let dobmm = dobmonth < 10 ? "0" + dobmonth.toString() : dobmonth.toString();

    var dobyyyy = ccp.dob.getFullYear().toString();

    let mperson = {
      object: ccp.object,
      account: ccp.account,
      personId: ccp.personId,
      first_name: ccp.first_name,
      last_name: ccp.last_name,
      dob: ccp.dob,
      dobdd,
      dobmm,
      dobyyyy,
      relationship: ccp.relationship,
      address: ccp.address,
      email: ccp.email,
      phone: ccp.phone,
    };

    updtccdata = {
      stripeAccId: ccd.stripeAccId,
      businessType: ccd.businessType,
      business_profile: ccd.business_profile,
      company: ccd.company,
      external_account: ccd.external_account,
      person: mperson,
      businessProfileSkip: ccd.businessProfileSkip,
      companySkip: ccd.companySkip,
      externalAccountSkip: ccd.externalAccountSkip,
      personSkip: ccd.personSkip,
      IdpictureSkip: ccd.IdpictureSkip,
      communityId: ccd.communityId,
      verification: ccd.id_files,
    };

    return updtccdata;
  };

  handlefinalUpdate = async () => {
    await this.setState({
      updateBeginFlag: true,
      loadingFlag: true,
    });
    let isValid = true;
    isValid = await this.validateCCData();

    if (isValid) {
      let ccdataForUpdate;
      if (!this.props.ccdata.IdpictureSkip) {
        ccdataForUpdate = await this.repackageCCdata();
      } else {
        ccdataForUpdate = this.props.ccdata;
      }

      try {
        let url = baandaServer + updateStripe;

        let ret = await axios.post(url, ccdataForUpdate);

        await this.processOutputError(ret.data);
      } catch (err) {
        console.log("finalupdate err:", err.message);
      }
    }

    await this.setState({
      updateBeginFlag: true,
      updateDoneFlag: true,
      loadingFlag: false,
    });
  };

  processOutputError = async (out) => {
    // console.log('processOutputError out:', out);
    let bflg = false,
      cflg = false,
      eaflg = false,
      pflg = false,
      // iflg = false,
      idflg = false,
      erflg = false;
    let bmsg = "",
      cmsg = "",
      eamsg = "",
      pmsg = "",
      // imsg = "",
      idmsg = "";
    if (out.bret && out.bret.status === "error") {
      bflg = true;
      erflg = true;
      bmsg = out.bret.msg;
    }
    if (out.cret && out.cret.status === "error") {
      cflg = true;
      erflg = true;
      cmsg = out.cret.msg;
    }
    if (out.earet && out.earet.status === "error") {
      eaflg = true;
      erflg = true;
      eamsg = out.earet.msg;
    }
    if (out.pret && out.pret.status === "error") {
      pflg = true;
      erflg = true;
      pmsg = out.pret.msg;
    }

    if (out.ipdret && out.ipdret.status === "error") {
      idflg = true;
      erflg = true;
      idmsg = "Domain:" + out.ipdret.domain + " Msg: " + out.ipdret.msg;
    }

    await this.setState({
      errBizMsg: bmsg,
      errCompMsg: cmsg,
      errEAMsg: eamsg,
      errPersonMsg: pmsg,
      errIdMsg: idmsg,
      errBizFlag: bflg,
      errCompFlag: cflg,
      errEAFlag: eaflg,
      errPersonFlag: pflg,
      errIdFlag: idflg,
      errFlg: erflg,
    });
  };

  handleClose = async () => {
    this.props.handleCancel();
  };

  validateCCData = async () => {
    let isValid = true;
    let bizvalid;

    if (!this.props.ccdata.businessProfileSkip) {
      bizvalid = await this.doBizProfileValidation();
      if (!bizvalid.status) {
        isValid = false;
        await this.setState({
          errBizMsg: bizvalid.msg,
          errBizFlag: true,
          errFlg: true, // Got errors
        });
      }
    }
    let compValid;
    if (!this.props.ccdata.companySkip) {
      compValid = await this.doCompanyValidation();
      if (!compValid.status) {
        isValid = false;
        await this.setState({
          errCompMsg: compValid.msg,
          errCompFlag: true,
          errFlg: true,
        });
      }
    }

    let eaValid;
    if (!this.props.ccdata.externalAccountSkip) {
      eaValid = await this.doEAValidation();
      if (!eaValid.status) {
        isValid = false;
        await this.setState({
          errEAMsg: eaValid.msg,
          errEAFlag: true,
          errFlg: true,
        });
      }
    }

    let perValid;

    if (!this.props.ccdata.personSkip) {
      perValid = await this.doPersonValidation();

      if (!perValid.status) {
        isValid = false;
        await this.setState({
          errPersonMsg: perValid.msg,
          errPersonFlag: true,
          errFlg: true,
        });
      }
    }

    let idValid;
    if (!this.props.ccdata.IdpictureSkip) {
      idValid = await this.doPersonIdValidation();

      if (!idValid.status) {
        isValid = false;
        await this.setState({
          errIdMsg: idValid.msg,
          errIdFlag: true,
          errFlg: true,
        });
      }
    }

    return isValid;
  };

  doBizProfileValidation = async () => {
    let bvalid = true;
    let bd = this.props.ccdata.business_profile;
    let bmsg = "";

    if (!bd.mcc) {
      bvalid = false;
      bmsg = "MCC (Merchant Category Code) is missing. ";
    } else if (bd.mcc < 1000 && bd.mcc > 9999) {
      bvalid = false;
      bmsg = 'Enter 4 digits valid MCC. Check info "i" for direction. ';
    }

    if (!bd.name) {
      bvalid = false;
      bmsg = bmsg + "Business name is mandatory. ";
    }

    if (bmsg !== "") {
      bmsg = "Error (Business Profile) : " + bmsg;
    }

    return { status: bvalid, msg: bmsg };
  };

  doCompanyValidation = async () => {
    let compValid = true;
    let cmsg = "";
    let co = this.props.ccdata.company;

    if (
      co.address.line1 === "" ||
      co.address.city === "" ||
      co.address.state === "" ||
      co.address.postal_code === ""
    ) {
      cmsg = "A company address is required. ";
      compValid = false;
    } else if (co.address.line1 !== "address_full_match") {
      let validateAddr = await this.checkAddress(
        co.address.line1,
        co.address.city,
        co.address.state,
        co.address.postal_code,
        "USA"
      );
      if (validateAddr.status === "error") {
        compValid = false;
        cmsg = "Invalid Company Address. ";
      }
    }

    if (!co.name || co.name === "") {
      compValid = false;
      cmsg = cmsg + "Company name is required. ";
    }

    if (!co.phone) {
      compValid = false;
      cmsg = cmsg + "Company phone number is required. ";
    }

    if (!co.tax_id) {
      compValid = false;
      cmsg = cmsg + "Company tax individual is required. ";
    }

    if (cmsg !== "") {
      cmsg = "Error (Company) : " + cmsg;
    }

    return { status: compValid, msg: cmsg };
  };

  checkAddress = async (street, city, state, zip, country) => {
    let status = "success";
    let msg = "";

    if (
      street === "" ||
      city === "" ||
      zip === "" ||
      state === "" ||
      country === ""
    ) {
      status = "error";
      msg = "Must provide valid & complete address.";
    } else {
      let param =
        "street1=" +
        street +
        "&city=" +
        city +
        "&zip=" +
        zip +
        "&state=" +
        state +
        "&country=" +
        country +
        "&company=" +
        this.props.communityName;
      let url = baandaServer + easypostAddressCheck + param;
      try {
        let check = await axios.get(url);

        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  doEAValidation = async () => {
    let eaValid = true;
    let emsg = "";
    let ea = this.props.ccdata.external_account;
    if (!ea.account_holder_name || ea.account_holder_name === "") {
      eaValid = false;
      emsg = "Account holder name is required. ";
    }
    if (!ea.bank_name || ea.bank_name === "") {
      eaValid = false;
      emsg = emsg + "Bank name is required. ";
    }
    if (!ea.routing_number || ea.routing_number === "") {
      eaValid = false;
      emsg = emsg + "Routing number is required. ";
    }
    if (!ea.account_number || ea.account_number === "") {
      eaValid = false;
      emsg = emsg + "Account number is required. ";
    }

    if (emsg !== "") {
      emsg = "Error (Bank Info.) : " + emsg;
    }

    return { status: eaValid, msg: emsg };
  };

  doPersonValidation = async () => {
    let perValid = true;
    let pmsg = "";
    let per = this.props.ccdata.person;
    // console.log("doPersonValidation per:", per);

    if (!per.first_name || per.first_name === "") {
      perValid = false;
      pmsg = "First Name is required. ";
    }
    if (!per.last_name || per.last_name === "") {
      perValid = false;
      pmsg = pmsg + "Last Name is required. ";
    }
    // console.log("here 1 pmsg:", pmsg);

    if (per.ssn_last_4.length !== 9) {
      perValid = false;
      pmsg = pmsg + "Invalid ssn. ";
    } else {
      let last = parseFloat(per.ssn_last_4);
      if (isNaN(last)) {
        // Checks if value enterd is NaN
        perValid = false;
        pmsg = pmsg + "Invalid ssn. ";
      }
    }
    // console.log("here 2 pmsg:", pmsg);

    if (
      per.relationship.owner === "no" &&
      parseFloat(per.relationship.percent_ownership) > 0
    ) {
      pmsg =
        pmsg + "You cannot have percentage ownership without being an owner. ";
      perValid = false;
    }
    // console.log("here 3 pmsg:", pmsg);

    if (
      parseFloat(per.relationship.percent_ownership) === 0 &&
      per.relationship.owner === "yes"
    ) {
      pmsg =
        pmsg + "You are an owner you must have some ownership percentage. ";
      perValid = false;
    }

    if (
      per.relationship.owner === "yes" &&
      parseFloat(per.relationship.percent_ownership) < 25
    ) {
      pmsg =
        pmsg +
        "You need to be at least 25% or more ownership to be a setup representative. ";
      perValid = false;
    }

    if (per.address.line1 !== "address_full_match") {
      if (
        per.address.line1 === "" ||
        per.address.city === "" ||
        per.address.state === "" ||
        per.address.postal_code === ""
      ) {
        pmsg = pmsg + "A valid address is required. ";
        perValid = false;
      } else {
        if (per.address.line1 !== "address_full_match") {
          let validateAddr = await this.checkAddress(
            per.address.line1,
            per.address.city,
            per.address.state,
            per.address.postal_code,
            "USA"
          );
          if (validateAddr.status === "error") {
            perValid = false;
            pmsg += " Invalid Company Address. ";
          }
        }
      }
    }

    if (per.email === "") {
      perValid = false;
      pmsg = pmsg + "A valid email is required. ";
    } else {
      let emv = emailValidation(per.email);

      if (!emv) {
        perValid = false;
        pmsg = pmsg + "Invalid email format. ";
      }
    }

    if (this.props.ccdata.numberOfPerson < 2) {
      if (per.relationship.account_opener === "no") {
        pmsg = pmsg + "You must be an Account Opener to initiate the account. ";
        perValid = false;
      }
    }

    if (per.ssn_last_4.length !== 9) {
      perValid = false;
      pmsg = pmsg + "Invalid SSN. ";
    } else {
      let last = parseFloat(per.ssn_last_4);
      if (isNaN(last)) {
        // Checks if value enterd is NaN
        perValid = false;
        pmsg = pmsg + "Invalid SSN (must be numeric). ";
      }
    }

    return { status: perValid, msg: pmsg };
  };

  doPersonIdValidation = async () => {
    let pi = this.props.ccdata.id_files;

    let msg = "",
      isValid = true;
    if (!pi.person) {
      // return { status: false, msg: "You have not uploaded person ID files to be verified. " };
      isValid = false;
      msg = "No person ID has been uploaded to be verified. ";
    } else {
      if (!pi.person.faceup && !pi.person.faceup.verificationFieldId) {
        isValid = false;
        msg = "Face up of Id file has not been verified.";
      }
      if (!pi.person.facedown && !pi.person.facedown.verificationFieldId) {
        isValid = false;
        msg += " Back of Id file has not been verified.";
      }
    }

    return { status: isValid, msg: msg };
  };

  // For checking to see if a year is leapyear for validation.
  quater = async (no) => {
    return no % 4;
  };

  render() {
    // console.log("Final this.state:", this.state);
    // console.log("Final this.props:", this.props);
    console.log('UpdateBizFinalStep');

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    let commButtonCompany = (
      <div>
        <button
          onClick={this.handleBackFromFinal}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleCancel}
          className="btn_reg_70"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Cancel
        </button>
        &nbsp;
        {!this.props.noUpdateFlag ? (
          <button
            onClick={this.handlefinalUpdate}
            className="btn_reg_70"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Update
          </button>
        ) : null}
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let inpName;
    if (this.state.deviceSize === "small") inpName = "Company";
    else inpName = "Type: Company";

    let headerPanel;
    // console.log('this.state.updateBeginFlag:', this.state.updateBeginFlag);
    if (!this.state.updateBeginFlag || this.state.errFlg) {
      // console.log('in here 731')
      // if (!this.state.updateBeginFlag || this.state.errFlg) {
      headerPanel = (
        <div className="row">
          <div className="col-4 header_comp_text">{inpName}</div>
          <div className="col-8 ">{commButtonCompany}</div>
        </div>
      );
    }

    let successPanel;
    if (!this.state.errFlg) {
      successPanel = (
        <div className="success_msg">
          <p align="justify">Successfully updated. Click 'Next' to complete.</p>
          <button onClick={this.handleClose} className="btn_reg_60">
            Next
          </button>
        </div>
      );
    }

    let errorPanel;
    if (this.state.errFlg) {
      errorPanel = (
        <div>
          <div>
            <div className="err_msg_header text-center">
              <u>Errors</u>
            </div>
            {this.state.errBizFlag ? (
              <p align="justify" className="err_msg">
                <b>Business Profile:&nbsp;</b>
                {this.state.errBizMsg}
              </p>
            ) : null}
            {this.state.errCompFlag ? (
              <p align="justify" className="err_msg">
                <b>Company:&nbsp;</b>
                {this.state.errCompMsg}
              </p>
            ) : null}
            {this.state.errEAFlag ? (
              <p align="justify" className="err_msg">
                <b>Banking:&nbsp;</b>
                {this.state.errEAMsg}
              </p>
            ) : null}
            {this.state.errPersonFlag ? (
              <p align="justify" className="err_msg">
                <b>Person</b>:&nbsp;
                {this.state.errPersonMsg}
              </p>
            ) : null}
            {this.state.errIdFlag ? (
              <p align="justify" className="err_msg">
                <b>ID Card:&nbsp;</b>
                {this.state.errIdMsg}
              </p>
            ) : null}
          </div>
          <div className="space-below-errors" />
        </div>
      );
    }

    let finalPanel;
    if (!this.state.updateBeginFlag) {
      if (!this.props.noUpdateFlag) {
        finalPanel = (
          <div>
            <div className="up-height" />
            <div className="row">
              <div className="col final_msg text-right">
                <p align="justify">
                  • When you click ‘Update’, all the data you have entered will
                  be validated to meet minimum requirements; then verified
                  extensively by our credit card payment processor. It may take
                  few minutes to few days to complete the validation process.
                </p>
                <p align="justify">
                  {" "}
                  • If you checked ‘Skip for now’ in any section, that section
                  will not be updated.
                </p>
                <p align="justify">
                  • If there is an error, you will be directed to navigate back
                  to the section to fix it.
                </p>
                <p align="justify">• Click ‘Cancel’ to abandon your work.</p>
                <p align="justify">
                  • Click ‘Back’ to review and fix data in any section.
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        finalPanel = (
          <div>
            <div className="up-height" />
            <div className="row">
              <div className="col final_msg">
                <font color="red">
                  <p>You have skipped all. Nothing to update.</p>
                </font>
              </div>
            </div>
          </div>
        );
      }
    } else if (!this.state.updateDoneFlag) {
      finalPanel = (
        <div>
          <div className="text-center spin-position" />
          <h6>Processing ... Please do not switch. </h6>
          <div className="row">
            <div className="col-5">&nbsp;</div>
            <div className="col-2">{uploadingSpin}</div>
            <div className="col-5">&nbsp;</div>
          </div>
        </div>
      );
    } else {
      finalPanel = <div>{this.state.errFlg ? errorPanel : successPanel}</div>;
    }

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="text-center">{finalPanel}</div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

UpdateBizFinalStep.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateBizFinalStep));
