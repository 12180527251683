import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./DefineShift.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const searchItemToEdit = "/routes/dashboard/searchItemToEdit?";
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

const readMeCode = "1111211000";

class DefineShift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      serviceMedium: "online",
      location: "",

      serviceName: "",
      filetrType: "name",
      itemType: "service",
      isPublished: true,
      serviceErrMsg: "",
      recipiantType: "individual",
      serverType: "individual",

      serviceFeeType: "free",
      showSelectPanel: false,
      showTaggedCatalogItem: false,
      catalogedServices: [],
      selheight: 1,

      groups: [],
      selGrpHeight: 1,
      groupName: "",
      groupSelected: null,
      showGroupSelectPanel: false,
      theEvent: null,

      selectedCatalogItem: null,
      maxRecipantAllowed: 1,

      errMsg: "",
      errFlag: false,
      eventId: 0,

      directions: "",
      attendeeBye: "",
      attendeeHello: "",

      notifyVia: "bmail",
      smsText: "",
      smsHello: "",
      smsBye: "",

      getEventMsgFlag: false,
      getMMSMsgFlag: false,

      serviceType: "scheduled",
      searchServiceName: "",
      itemfetchFlag: false,
      taggedItem: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let taggedCatalog = this.state.taggedCatalog;
    if (this.props.theEvent.services.taggedCatalog)
      taggedCatalog = this.props.theEvent.services.taggedCatalog;
    let serviceMedium = "online";
    if (this.props.theEvent.services.serviceMedium !== "")
      serviceMedium = this.props.theEvent.services.serviceMedium;
    let serviceFeeType = "free";
    let showTaggedCatalogItem = false;
    let selectedCatalogItem = this.state.selectedCatalogItem;
    // if (this.props.theEvent.services.serviceFeeType === "catalog") {
    //   serviceFeeType = this.props.theEvent.services.serviceFeeType;
    //   showTaggedCatalogItem = true;
    //   selectedCatalogItem = taggedCatalog;
    // }
    if (
      this.props.theEvent.services.serviceFeeType === "catalog" &&
      this.props.theEvent.services.taggedCatalog
    ) {
      serviceFeeType = "catalog";
      showTaggedCatalogItem = true;
      selectedCatalogItem = taggedCatalog;
    }

    let recipiantType = "individual";
    if (this.props.theEvent.services.recipiantType !== "")
      recipiantType = this.props.theEvent.services.recipiantType;
    let serverType = "individual";
    if (this.props.theEvent.services.serverType !== "")
      serverType = this.props.theEvent.services.serverType;
    let maxRecipantAllowed = 1;
    if (this.props.theEvent.services.maxRecipantAllowed !== 1)
      maxRecipantAllowed = this.props.theEvent.services.maxRecipantAllowed;
    let groupId = 0,
      groupName = "";
    if (this.props.theEvent.services.groupId !== 0) {
      groupId = this.props.theEvent.services.groupId;
      groupName = this.props.theEvent.services.groupName;
    }

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
      eventId: this.props.theEvent.eventId,
      recipiantType,
      serverType,
      location: this.props.theEvent.services.location,
      serviceFeeType,
      serviceMedium,
      taggedCatalog: taggedCatalog,
      maxRecipantAllowed,
      groupId,
      groupName,
      showTaggedCatalogItem,
      selectedCatalogItem,
      notifyVia: this.props.theEvent.inviteMedium,
      attendeeBye: this.props.theEvent.attendeeBye,
      attendeeHello: this.props.theEvent.attendeeHello,
      directions: this.props.theEvent.attendeeDirection,
      smsBye: this.props.theEvent.smsBye,
      smsHello: this.props.theEvent.smsHello,
      smsText: this.props.theEvent.smsText,
    });

    // console.log("onmount this.state:", this.state);
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleMedium = async (e) => {
    await this.setState({
      serviceMedium: e.target.value,
    });
  };

  handleRecipiantType = async (e) => {
    // console.log("handleRecipiantType");
    let value = e.target.value;
    let maxRecipantAllowed = this.state.maxRecipantAllowed;
    if (value === "individual") maxRecipantAllowed = 1;
    await this.setState({
      recipiantType: e.target.value,
      maxRecipantAllowed,
    });
  };

  handleServiceType = async (e) => {
    await this.setState({
      serviceType: e.target.value,
    });
  };

  handleServerType = async (e) => {
    let value = e.target.value;
    // console.log("handleServerType value:", value);
    let showGroupSelectPanel = false;
    if (value === "group") showGroupSelectPanel = true;
    await this.setState({
      serverType: e.target.value,
      showGroupSelectPanel,
    });
  };

  handleServiceFee = async (e) => {
    let value = e.target.value;
    // console.log("handleServiceFee value:", value);
    let showSelectPanel = false;
    let showTaggedCatalogItem = false;
    let serviceType = "scheduled";
    let catalogedServices = this.state.catalogedServices;
    if (value === "catalog") {
      showSelectPanel = true;
      serviceType = "catalog";
    }
    if (value === "free") {
      showSelectPanel = false;
      showTaggedCatalogItem = false;
      catalogedServices = [];
    }
    await this.setState({
      serviceFeeType: e.target.value,
      showSelectPanel,
      showTaggedCatalogItem,
      catalogedServices,
      taggedItem: null,
      serviceType,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onfocus = async () => {
    let selht = 4;
    if (this.state.catalogedServices.length < 4)
      selht = this.state.catalogedServices.length + 1;
    await this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  onfocusGroup = async () => {
    let selht = 4;
    if (this.state.groups.length < 4) selht = this.state.groups.length + 1;
    await this.setState({
      selGrpHeight: selht,
    });
  };

  onblurGroup = async () => {
    await this.setState({
      selGrpHeight: 1,
    });
  };

  getServicesInCatelog = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&fileterByName=" +
      this.state.searchServiceName +
      "&itemType=" +
      this.state.itemType +
      "&isPublished=" +
      this.state.isPublished +
      "&filterType=service";
    // +       this.state.filetrType;
    let url = baandaServer + searchItemToEdit + params;
    // console.log("url:", url);
    try {
      let ret = await axios.get(url);
      // console.log("catalog >>>>> ret:", ret);
      if (ret.data.status === "error") {
        await this.setState({
          serviceErrMsg: "Need to catalog services to be tagged.",
          itemfetchFlag: true,
        });
      } else {
        this.setState({
          catalogedServices: ret.data.Msg.items,
          serviceErrMsg: "",
          itemfetchFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }

    if (document.getElementById("toReferService"))
      document.getElementById("toReferService").value = "DEFAULT";
  };

  getGroups = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&groupName=" +
      this.state.groupName;
    let url = baandaServer + getGroupsOfCommunity + params;
    try {
      let ret = await axios.get(url);
      // console.log("group >>>>> ret:", ret);
      if (ret.data.status === "Error") {
        await this.setState({
          serviceErrMsg: "Need to setup catalog service to hook to.",
        });
      } else {
        await this.setState({
          groups: ret.data.Msg,
          errMsg: "",
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }

    if (document.getElementById("groupSelectService"))
      document.getElementById("groupSelectService").value = "DEFAULT";
  };

  selectServiceToRefer = async () => {
    let value = document.getElementById("toReferService").value;
    let obj = JSON.parse(value);
    // console.log("selectServiceToRefer obj:", obj);
    let taggedCat = {
      itemId: obj.itemId,
      itemName: obj.itemName,
      itemPrice: obj.itemPrice,
      serviceRendereUnit: obj.serviceRendereUnit,
    };
    await this.setState({
      showSelectPanel: false,
      showTaggedCatalogItem: true,
      selectedCatalogItem: taggedCat,
      errMsg: "",
    });
  };

  selectAGroup = async () => {
    let value = document.getElementById("groupSelectService").value;
    let obj = JSON.parse(value);
    // console.log("selectAGroup obj:", obj);
    await this.setState({
      groupSelected: obj,
      showGroupSelectPanel: false,
      errMsg: "",
    });
  };

  validateData = async () => {
    let isValid = true;
    let msg = "";

    if (this.state.location === "") {
      isValid = false;
      msg = "Location description is needed to save. || ";
    }

    // console.log('this.state.notifyVia:', this.state.notifyVia);
    // console.log("this.state.directions:", this.state.directions);

    if (this.state.notifyVia === "bmail") {
      if (this.state.directions === "") {
        isValid = false;
        msg = msg + "Must provide message to bmail/email. || ";
      }
    }
    if (this.state.notifyVia === "text") {
      if (this.state.smsText === "") {
        isValid = false;
        msg = msg + "Must provide SMS message to text. || ";
      }
    }
    if (this.state.notifyVia === "bmailNtext") {
      if (this.state.smsText === "" || this.state.directions === "") {
        isValid = false;
        msg =
          msg +
          "Must provide both SMS message & bmail message to bmail ( email) & text. || ";
      }
    }

    return { status: isValid, Msg: msg };
  };

  packDataForDB = async () => {
    let groupId = 0;
    let groupName = "";
    if (this.state.serverType === "group") {
      if (this.state.groupSelected && this.state.groupSelected.groupId > 0) {
        groupId = this.state.groupSelected.groupId;
        groupName = this.state.groupSelected.groupName;
      }
    }

    let theWouldBeTaggedCatalog = this.state.taggedItem;

    if (!this.state.taggedItem && this.state.taggedCatalog) {
      theWouldBeTaggedCatalog = this.state.taggedCatalog;
    }

    let schedulingType = "periodic";
    if (this.state.theWouldBeTaggedCatalog)
      schedulingType = theWouldBeTaggedCatalog.service.type;

    let exposureType = "private";
    if (this.state.taggedCatalog)
      exposureType = this.state.taggedCatalog.itemExposure;
    // console.log("exposureType:", exposureType);

    let audienceType = "";
    if (this.state.serviceFeeType === "catalog") audienceType = "none";
    else if (this.props.theEvent.audienceType !== "")
      audienceType = this.props.theEvent.audienceType;

    if (this.state.serviceFeeType === "free") theWouldBeTaggedCatalog = null;

    // let coopStoreId = 0;
    // if ( this.props.store) coopStoreId = this.props.store.coopackDataForDBStoreId;

    let input = {
      eventId: this.props.theEvent.eventId,
      serviceMedium: this.state.serviceMedium,
      recipiantType: this.state.recipiantType,
      maxRecipantAllowed: this.state.maxRecipantAllowed,
      serverType: this.state.serverType,
      groupId,
      groupName,
      audienceType,
      location: this.state.location,
      serviceFeeType: this.state.serviceFeeType,
      // taggedCatalog: this.state.selectedCatalogItem,
      taggedCatalog: theWouldBeTaggedCatalog,
      attendeeDirection: this.state.directions,
      attendeeBye: this.state.attendeeBye,
      attendeeHello: this.state.attendeeHello,
      schedulingType,
      // notifyVia: this.state.notifyVia,
      smsText: this.state.smsText,
      smsHello: this.state.smsHello,
      smsBye: this.state.smsBye,
      exposureType,
      module: "servicedefine",
      clientProgram: "DefineShift.js",
      clientFunction: "saveUpdateEvent",
    };

    return input;
  };

  saveUpdateEvent = async () => {
    let url = baandaServer + saveUpdtEvent;
    let isValid = await this.validateData();
    // console.log("isValid:", isValid);
    if (isValid.status) {
      let inp = await this.packDataForDB();
      // console.log(" url:", url, " inp:", inp);
      try {
        // console.log('Before the execution ... ')
        let resp = await axios.post(url, inp);
        // console.log("resp.data:", resp.data);
        if (resp.data.status === "success") {
          this.setState({
            errMsg: "Successfully Defined.",
            errFlag: false,
            eventId: resp.data.Msg.eventId,
            theEvent: resp.data.Msg.theEvent,
          });
          this.returnToCreateShift();
        } else {
          this.setState({
            errMsg: resp.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  returnToCreateShift = async () => {
    this.props.returnToCaller(this.state.theEvent, this.state.taggedCatalog);
  };

  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChangeMaxRecipiant = async (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (this.countDecimals(value) === 0 && value > 0) {
      await this.setState({
        [e.target.name]: e.target.value,
        errMsg: "",
      });
    }
  };

  handleNotifyMedium = async (e) => {
    await this.setState({
      notifyVia: e.target.value,
    });
  };

  showMessageBox = async (type) => {
    // console.log("showMessageBox type:", type);
    if (type === "eventmessage") {
      await this.setState({
        getEventMsgFlag: true,
        getMMSMsgFlag: false,
      });
    }
    if (type === "smsmessage") {
      await this.setState({
        getMMSMsgFlag: true,
        getEventMsgFlag: false,
      });
    }
  };

  doneMessageBox = async () => {
    await this.setState({
      getEventMsgFlag: false,
      getMMSMsgFlag: false,
    });
  };

  selectThisItemToTag = async (obj) => {
    // console.log(">> obj:", obj);
    await this.setState({
      serviceType: "custom",
      showSelectPanel: false,
      taggedItem: obj,
      taggedCatalog: obj,
    });
  };

  exitTagCatalog = async () => {
    await this.setState({
      serviceType: "free",
      showSelectPanel: false,
      serviceFeeType: "free",
    });
  };

  exitGroupBox = () => {
    this.setState({
      showGroupSelectPanel: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("DefineShift...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let groupList = this.state.groups.map((obj, i) => {
      return (
        <option key={i + 2} value={JSON.stringify(obj)}>
          {obj.groupName}
        </option>
      );
    });

    let groupListDropdown;
    groupListDropdown = (
      <div>
        <div className="row">
          <div className="col">
            <select
              size={this.state.selGrpheight}
              onFocus={this.onfocusGroup}
              onBlur={this.onblurGroup}
              id="groupSelectService"
              name="groupSelectService"
              onChange={this.selectAGroup}
              className="event-to-copy"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select a group
              </option>
              {groupList}
            </select>
          </div>
        </div>
      </div>
    );

    let groupSelectPanel;
    if (this.state.serverType === "group" && this.state.showGroupSelectPanel) {
      groupSelectPanel = (
        <div className="define-shift-popup-box">
          <div className="text-center copy-event-header">
            Select the Recipiant Group
          </div>
          <div className="row">
            <div className="col text-center">
              <input
                name="groupName"
                type="text"
                value={this.state.groupName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="search-event-name"
                placeholder="Filter by group name (part/full) ..."
              />
              &nbsp;&nbsp;
              <button onClick={this.getGroups} className="btn_reg_60">
                Filter
              </button>
              &nbsp;
              <button onClick={this.exitGroupBox} className="btn_back_main">
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">{groupListDropdown}</div>
          </div>
        </div>
      );
    }

    let serviceListPanel;

    if (this.state.serviceFeeType === "catalog") {
      if (this.state.catalogedServices.length > 0) {
        let bool = true;
        serviceListPanel = (
          <div className="fixedsize-catalog-item-list">
            {this.state.catalogedServices.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left item-category-label">
                      {obj.itemName}&nbsp;|&nbsp;<b>Type</b>:&nbsp;
                      {obj.service.type}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        className="btn-alloc-schedule-more"
                        type="button"
                        onClick={() => this.selectThisItemToTag(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        );
      } else {
        if (this.state.itemfetchFlag) {
          serviceListPanel = (
            <div className="text-center no-items-to-edit">
              {this.state.serviceErrMsg}
            </div>
          );
        }
      }
    }

    let showTaggedCatalogItemPanel;
    if (this.state.showTaggedCatalogItem) {
      showTaggedCatalogItemPanel = (
        <div className="show-tagged-catalog-item-box">
          <div className="text-center catalog-show-header">
            Tagged Catalog Service
          </div>
          <div className="text-center catalog-item-tagged">
            <b>Service Name</b>&nbsp;{this.state.selectedCatalogItem.itemName}
            &nbsp;(itemId: {this.state.selectedCatalogItem.itemId})
          </div>

          <div className="text-center catalog-item-tagged">
            <b>Price</b>&nbsp;$
            {this.commaFormattedCurrency(
              this.state.selectedCatalogItem.itemPrice
            )}
            &nbsp;(Render Unit:{" "}
            {this.state.selectedCatalogItem.serviceRendereUnit})
          </div>
        </div>
      );
    }

    let serviceCatalogPanel;
    if (this.state.serviceFeeType === "catalog" && this.state.showSelectPanel) {
      serviceCatalogPanel = (
        <div className="define-catalog-box">
          <div className="text-center copy-event-header">
            Select a catalog'd service
          </div>
          <div className="row">
            <div className="col text-center">
              <input
                name="searchServiceName"
                type="text"
                value={this.state.searchServiceName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="search-event-name"
                placeholder="Filter by service name (part/full) ..."
              />
              &nbsp;&nbsp;
              <button
                onClick={this.getServicesInCatelog}
                className="btn_reg_60"
              >
                Fetch
              </button>
              <button onClick={this.exitTagCatalog} className="btn_back_main">
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col text-center">{serviceListPanel}</div>
          </div>
        </div>
      );
    }

    let serverTypePanel;
    serverTypePanel = (
      <div>
        <div className="row row-medium">
          <div className="col-3 text-right search-lbl">Server is </div>
          <div className="col-9 text-left search-lbl">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="individual"
                  checked={this.state.serverType === "individual"}
                  onChange={this.handleServerType}
                />{" "}
                An Individual
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="group"
                  checked={this.state.serverType === "group"}
                  onChange={this.handleServerType}
                  disabled={this.props.store ? true : false}
                />{" "}
                A Group
              </label>
            </div>
            &nbsp;&nbsp;
            <font color="blue">
              {this.state.groupSelected
                ? "(Selected: " + this.state.groupSelected.groupName + ")"
                : null}
            </font>
          </div>
        </div>
        {groupSelectPanel}
      </div>
    );

    let maxRecipantPanel;
    if (this.state.recipiantType === "group") {
      maxRecipantPanel = (
        <div>
          Max Recipant:{" "}
          <input
            name="maxRecipantAllowed"
            type="number"
            value={this.state.maxRecipantAllowed}
            onChange={this.onChangeMaxRecipiant}
            className="max-recipant"
            step="1"
          />
        </div>
      );
    }

    let recipiantTypePanel;
    recipiantTypePanel = (
      <div>
        <div className="row row-medium">
          <div className="col-3 text-right search-lbl">Recipiant is </div>
          <div className="col-5 text-left search-lbl">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="individual"
                  checked={this.state.recipiantType === "individual"}
                  onChange={this.handleRecipiantType}
                />{" "}
                An Individual
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="group"
                  checked={this.state.recipiantType === "group"}
                  onChange={this.handleRecipiantType}
                />{" "}
                A Group
              </label>
            </div>
          </div>
          {this.state.recipiantType === "group" ? (
            <div className="col-4 text-left search-lbl">{maxRecipantPanel}</div>
          ) : (
            <div className="col-4 text-left search-lbl">&nbsp;</div>
          )}
        </div>
      </div>
    );

    let showMessageInputPanel;
    if (this.state.getEventMsgFlag) {
      showMessageInputPanel = (
        <div className="get-message-box-shift">
          <div className="shift-message-popup-header">Enter Event Message</div>
          <div className="row">
            <div className="col-4 text-right sms-hello-bye-shift">Hail</div>
            <div className="col-4 text-left">
              <input
                name="attendeeHello"
                type="text"
                value={this.state.attendeeHello}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-8 event-directions text-center">
              Additional Messages (with 1000 chars)
            </div>
            <div className="col-4 event-directions text-left">
              Used:&nbsp;{this.state.directions.length}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="directions"
                maxLength="1000"
                placeholder="Provide event location, other direction, url for e-event etc."
                rows="3"
                wrap="hard"
                spellCheck="true"
                className="event-direction-textarea"
                onChange={this.onChange}
                value={this.state.directions}
                required
              />
            </div>
          </div>
          <div className="row sms-signoff-row">
            <div className="col-4 text-right sms-hello-bye-shift">SignOff</div>
            <div className="col-4 text-left">
              <input
                name="attendeeBye"
                type="text"
                value={this.state.attendeeBye}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="text-center">
            <button
              className="btn-msg-done"
              type="button"
              onClick={this.doneMessageBox}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    if (this.state.getMMSMsgFlag) {
      showMessageInputPanel = (
        <div className="get-message-box-shift">
          <div className="shift-message-popup-header">
            MMS Notification Message
          </div>
          <div className="row">
            <div className="col-4 text-right sms-hello-bye-shift">Hail</div>
            <div className="col-4 text-left">
              <input
                name="smsHello"
                type="text"
                value={this.state.smsHello}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-8 event-directions-mms text-center">
              Messages (within 200 chars)
            </div>
            <div className="col-4 event-directions-mms text-left">
              Used:&nbsp;{this.state.smsText.length}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="smsText"
                type="text"
                value={this.state.smsText}
                onChange={this.onChange}
                rows="3"
                wrap="hard"
                size="200"
                maxLength="200"
                className="notify-sms-text"
                placeholder="SMS in 150 chars or less"
              />
            </div>
          </div>
          <div className="row sms-signoff-row">
            <div className="col-4 text-right sms-hello-bye-shift">SignOff</div>
            <div className="col-4 text-left">
              <input
                name="smsBye"
                type="text"
                value={this.state.smsBye}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="mms-hail-input"
                placeholder="Personalize - blank otherwise"
              />
            </div>
            <div className="col-4 text-right">&nbsp;</div>
          </div>
          <div className="text-center">
            <button
              className="btn-msg-done"
              type="button"
              onClick={this.doneMessageBox}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    let showServerRecipientFlag = false;
    if (
      (this.state.taggedCatalog &&
        this.state.taggedCatalog.service.type === "scheduled") ||
      this.state.serviceFeeType === "free"
    ) {
      showServerRecipientFlag = true;
    }

    let serviceSetupPanel;
    serviceSetupPanel = (
      <div>
        <div className="row row-medium">
          <div className="col">
            <strong>Render Medium&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="online"
                  checked={this.state.serviceMedium === "online"}
                  onChange={this.handleMedium}
                />{" "}
                On-Line
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="clientlocation"
                  checked={this.state.serviceMedium === "clientlocation"}
                  onChange={this.handleMedium}
                />{" "}
                Recipiant's Location
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="serverlocation"
                  checked={this.state.serviceMedium === "serverlocation"}
                  onChange={this.handleMedium}
                />{" "}
                Server Location
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-center textarea-event-def">
            Maximum 1000 characters allowed. Click 'Enter' to paraagraph.
          </div>
          <div className="col-4 text-center textarea-event-def">
            Characters Used = {this.state.location.length}
          </div>
        </div>
        <div className="row search-other-place">
          <div className="col-3 text-right search-lbl">Location</div>
          <div className="col-9 text-left search-lbl">
            <textarea
              name="location"
              maxLength="1000"
              placeholder="Enter service location and other directions..."
              rows="4"
              wrap="hard"
              spellCheck="true"
              className="service_location"
              onChange={this.onChange}
              value={this.state.location}
              required
            />
          </div>
        </div>
        {/* {serviceTypePanel} */}
        <div className="row row-medium">
          <div className="col-3 text-right search-lbl">Service Type</div>
          <div className="col-9 text-left search-lbl">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="free"
                  checked={this.state.serviceFeeType === "free"}
                  onChange={this.handleServiceFee}
                />{" "}
                Scheduled(Free)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="catalog"
                  checked={this.state.serviceFeeType === "catalog"}
                  onChange={this.handleServiceFee}
                />{" "}
                Cataloged
              </label>
            </div>
            &nbsp;&nbsp;
            {this.state.taggedItem
              ? "Event tagged to: " + this.state.taggedItem.itemName
              : ""}
          </div>
        </div>
        {showServerRecipientFlag ? serverTypePanel : null}
        {showServerRecipientFlag ? recipiantTypePanel : null}

        {serviceCatalogPanel}
        {showTaggedCatalogItemPanel}
        {showMessageInputPanel}
      </div>
    );

    let buttonPanel = (
      <div className="shift-setup-button-position">
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.saveUpdateEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
        {infoModalButton}
      </div>
    );

    let outputPanel = (
      <div>
        <div className="text-center service-def-header">Service Setup</div>
        <div className="text-center service-def-header">
          <b>Event</b>:&nbsp;{this.props.theEvent.eventName}
        </div>
        {serviceSetupPanel}
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center shift-definition-msg-err"
              : "text-center shift-definition-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

DefineShift.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefineShift));
