import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from "react-loading";

import "./ReceiveCCForMeterSub.css";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import axios from "axios";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const customerSwapUpdate = "/routes/payctrl/customerSwapUpdate";

const CCPayoutForm = (props) => {
  // console.log("CCPayoutForm ...");
  const stripe = useStripe();
  const elements = useElements();

  const [valueof, setValueof] = useState(true);
  const [message, setMessage] = useState(0);
  const [errmsg, setErrmsg] = useState(""); 
  const [spinner, setSpinner] = useState(false);
  const [errflag, setErrflag] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [isShownPay, setIsShownPay] = useState(false);
  const [isShownCancel, setIsShownCancel] = useState(false);
  const [isShownFinito, setIsShownFinito] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage(1);

    let clientSecret = props.inputData.client_secret;

    // console.log("clientSecret:", clientSecret);
    setSpinner(true);
    setDisabled(true);

    const response = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    }); 

    setSpinner(false);

    // console.log("1. response:", response);

    let error = false;
    let errMsg = "";
    if (response.error) {
      error = true;
      errMsg = "Error: " + response.error.code + ". " + response.error.message;
      // console.log("ErrMsg:", errMsg);
      setErrmsg(errMsg);
    }

    if (!error) {
      // console.log("response.paymentIntent: ", response.paymentIntent);
      setSpinner(true);
      setErrflag(false);
      setErrmsg("");

      let swapdone = await updateSubscriptionCustomer(response);
      // console.log("swapdone:", swapdone);
      if (swapdone.status === "success") setValueof(false);
      else {
        setMessage(0);
        setErrflag(true);
        setErrmsg(swapdone.Msg);
      }
      setSpinner(false);
    } else {
      console.log("SwapCCOfSubscription error:", error);
      setMessage(0);
      setErrflag(true);
      setErrmsg(errMsg);
    }
  };

  const updateSubscriptionCustomer = async (response) => {
    try {
      let url = baandaServer + customerSwapUpdate;
      let input = {
        payment_method: response.setupIntent.payment_method,
        customerId: props.inputData.customerId,
        connectedAccId: props.inputData.connectedAccId,
        subscriptionId: props.inputData.subscriptionId
      };
      // console.log("url:", url, " input:", input);

      let swapret = await axios.post(url, input);
      return swapret.data;
    } catch (err) {
      console.log("err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  // console.log(">>>> valueof:", valueof);

  const doFinito = async () => {
    // console.log("doFinito errmsg:", errmsg);
    let retval;
    // console.log(">>>> in dofinito valueof:", valueof);
    if (!valueof) retval = "success";
    else retval = "error";
    // console.log("dofinito retval:", retval);
    props.handleExit(retval);
  };
  const doCancel = async () => {
    // console.log("inside doCancel");
    props.handleExit("cancel");
  };

  let buttonPanel;
  if (message === 0) {
    // console.log('props.deviceSize:', props.deviceSize);
    let swap;
    if ( props.deviceSize === 'small') swap = "Swp";
    else swap = "Swap";
    buttonPanel = (
      <div className="test-pay-button-place">
        <button
          style={{ cursor: disabled ? "default" : "pointer" }}
          type="submit"
          className={
            isShownPay ? "btn_reg_60" : "btn_reg_50"
          }
          disabled={!stripe}
          onMouseEnter={() => setIsShownPay(true)}
          onMouseLeave={() => setIsShownPay(false)}
        >
          {swap}
        </button>
      </div>
    );
  }

  let cancel = "Cancel";
  if ( props.deviceSize === 'small') cancel = "Cncl";
  let cancelBtn = (
    <div>
      <button
        onClick={doCancel}
        style={{ cursor: disabled ? "default" : "pointer" }}
        className={
          isShownCancel
            ? "btn_reg_80"
            : "btn_reg_70"
        }
        onMouseEnter={() => setIsShownCancel(true)}
        onMouseLeave={() => setIsShownCancel(false)}
      >
        {cancel}
      </button>
      <div className="platform-cc-payment-description">{props.description}</div>
      <div className="platform-cc-payment-wait">
        Note: Swapping process may take a few moments. Please hold your wagons
        ...
      </div>
      <div className="platform-cc-payment-description">
        <font color="red">{errmsg}</font>
      </div>
    </div>
  );

  let finishBtn = (
    <div>
      <button
        onClick={doFinito}
        className={
          isShownFinito ? "btn_reg_80" : "btn_reg_70"
        }
        style={{ cursor: disabled ? "default" : "pointer" }}
        onMouseEnter={() => setIsShownFinito(true)}
        onMouseLeave={() => setIsShownFinito(false)}
      >
        Finito
      </button>
      {errmsg === "" ? null : (
        <div className="platform-cc-payment-description">
          <font color="red">
            Error: {errmsg}.&nbsp;If CC info it right, contact baanda support at
            support@baanda.com
          </font>
        </div>
      )}
    </div>
  );

  let btnbelow;
  if (valueof) btnbelow = cancelBtn;
  else btnbelow = finishBtn;

  let uploadingSpin;
  if (spinner) {
    uploadingSpin = (
      <div>
        <ReactLoading
          type={"spokes"}
          color={"#ffffff"}
          height={30}
          width={30}
        />
      </div>
    );
  }

  let creditCardPanel;
  if (valueof) {
    creditCardPanel = (
      <div className="text-center">
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "300px", margin: "0 auto" }}
        >
          <div className="cc-meter-msg">
            Last 4 of CC# to swap: ... {props.inputData.last4}
          </div>
          <CardElement className="card-strip-cc-meter" />
          {buttonPanel}
        </form>
        <div className="text-center spin_meter_position">{uploadingSpin}</div>
      </div>
    );
  } else if (!errflag) {
    creditCardPanel = (
      <div className="cc-thanks-meter text-center">
        <h3>Card swap successful</h3>
      </div>
    );
  } else {
    creditCardPanel = (
      <div className="cc-thanks-meter text-center">
        <h3>
          <font color="red">Error ... contact Baanda Support</font>
        </h3>
      </div>
    );
  }

  let cconcard = <div className="sub-meter-creditcard">{creditCardPanel}</div>;

  let outputPanel;
  if (props.deviceSize === "small") {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col-1 text-center">&nbsp;</div>
          <div className="col-11">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center deliver-to-text">{btnbelow}</div>
        </div>
      </div>
    );
  } else {
    outputPanel = (
      <div>
        <div className="row">
          <div className="col text-center cc-place">{cconcard}</div>
        </div>
        <div className="row">
          <div className="col text-center btn-below-place">{btnbelow}</div>
        </div>
      </div>
    );
  }

  return <div>{outputPanel}</div>;
};

//=====================================================================
// Calls the function, invoked from other cmomponent, to expose CC form.
const PayUsingCC = (props) => {
  console.log("SwapCCOfSubscription...");

  const stripePromise = loadStripe(stripePublicKey, {
    stripeAccount: props.inputData.connectedAccId,
  });

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CCPayoutForm
          handleExit={(retdata) => props.handleExit(retdata)}
          inputData={props.inputData}
          caller={props.caller}
          deviceSize={props.deviceSize}
        />
      </Elements>
    </div>
  );
};

export default PayUsingCC;
