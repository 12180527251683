import React, { Component } from "react";
import axios from "axios";

import ReactLoading from "react-loading";

import "./ViewItemList.css";
import notAvailable from "../../../images/notavailable4.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getItemsSearch = "/routes/search/getItemsSearch?";

class ViewItemList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      itemsList: [],

      loading: true,

      showListFlag: false,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.loadListData();

    this.setState({
      showListFlag: true,
      deviceSize,
      loading: false,
    });
  };

  loadListData = async () => {
    let params =
      "type=" +
      this.props.searchInput.type +
      "&itemsTexts=" +
      this.props.searchInput.itemTexts +
      "&distance=" +
      this.props.searchInput.distance +
      "&itemType=" +
      this.props.searchInput.itemType;

    // console.log("params:", params);
    let url = baandaServer + getItemsSearch + params;
    // console.log('Item LoadListData URL:', url);
    try {
      let bizret = await axios.get(url);
      if (bizret.data.status === "success") {
        this.setState({
          itemsList: bizret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: bizret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleItem = (item) => {
    // console.log("item:", item);
    let retObj = {
      type: "item",
      item: item,
    };
    // console.log("retObj:", retObj);
    this.props.returnToCaller(retObj);
  };

  returnToSearch = () => {
    let retObj = {
      type: "search",
    };
    this.props.returnToCaller(retObj);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewItemList...');

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let summaryPanel;
    if (this.state.itemsList.length > 0) {
      summaryPanel = (
        <div className="login-search-res">
          Results&nbsp;
          <span className="boxes">{this.state.itemsList.length}</span>&nbsp;
        </div>
      );
    }

    let itemsPanel;
    if (this.state.itemsList.length > 0) {
      let bool = true;
      itemsPanel = (
        <div>
          <div className="row show-pickups-header">
            <div className="col text-center header-text-catalog">
              {summaryPanel}
            </div>
          </div>

          <div className="fixedsize_search-biz-view">
            {this.state.itemsList.map((post, i) => {
              let pic = "";
              if (post.location === "") pic = notAvailable;
              else pic = post.location;

              let cati = post.centerOfActivityLocation.postalAddress;

              return (
                <div key={i}>
                  <div
                    className={`${
                      bool ? "row-cat-item-color-dr" : "row-cat-item-color-lt"
                    }`}
                  >
                    <div className="row">
                      <div className="col-2 text-center pic-placement">
                        <img src={pic} alt="pic" className="pic-cat-browse" />
                      </div>
                      <div className="col-8 text-left login-search-biz-lines">
                        <div className="row login-search-biz-lines-row1">
                          <div className="col-10 text-left login-search-item-name">
                            {post.itemName} ({post.itemCategory})
                          </div>

                          {this.props.searchInput.itemTexts === "" ? (
                            <div className="col-2 text-left login-search-biz-lines-row2">
                              &nbsp;
                            </div>
                          ) : (
                            <div className="col-2 text-left login-search-biz-lines-row2">
                              Score:{post.score.toFixed(1)}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col text-left login-search-biz-lines-row3">
                            Comm: {post.commName} @ {cati.city},{" "}{cati.state.toUpperCase()}
                          </div>
                        </div>
                      </div>
                      <div className="col-2 text-center item-open-button-pos">
                        <button
                          onClick={() => this.handleItem(post)}
                          className="btn_reg_50_open "
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          {/* <div className="text-center cat-rec-count-txt">
            Listed {this.state.items.length} record of {this.state.noOfDoc}{" "}
            available.{" "}
            {this.state.items.length < this.state.noOfDoc
              ? "Please refine your search."
              : null}{" "}
            [max items: 40]
          </div> */}
        </div>
      );
    } else {
      if (this.state.loading) {
        itemsPanel = (
          <div className="text-center comm-biz-search-result-none">
            Searching --- Work in progress
            <div className="text-center react-load-login-pos">
              <ReactLoading
                type={"spokes"}
                color={"#dfe9f0"}
                height={50}
                width={50}
              />
            </div>
          </div>
        );
      } else {
        itemsPanel = (
          <div className="text-center comm-biz-search-result-none">
            Nothing found for your search
          </div>
        );
      }
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center comm-search-result-header">
          Communities&nbsp;&nbsp; {backButton}
          {itemsPanel}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default ViewItemList;
