import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./DefaultPkgSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveDefaultShippingPkgs = "/routes/dashboard/saveDefaultShippingPkgs";

// const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115400000";

class DefaultPkgSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pkgName: "",
      scaleType: "fps",
      pkgLength: 0,
      pkgWidth: 0,
      pkgHeight: 0,
      pkgWeight: 0, 
      pkgWeightOzs: 0,

      packages: [],
    };
  }

  componentDidMount = async () => {
    this.setState({
      packages: this.props.community.shippingMeta.defaultPackages,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onChangeCurrency = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleScaleType = async (e) => {
    await this.setState({
      scaleType: e.target.value,
    });
  };

  validatePkg = async () => {
    let msg = "";
    let state = "success";
    if (this.state.pkgName !== "") {
      this.state.packages.forEach((pkg) => {
        if (pkg.pkgName === this.state.pkgName) {
          state = "error";
          msg = "Package name muct be unique; ";
        }
      });
    } else {
      state = "error";
      msg += "A package must have a unique name; ";
    }

    if (this.state.pkgHeight === 0) {
      state = "error";
      msg += "Must have non-zero height; ";
    }
    if (this.state.pkgLength === 0) {
      state = "error";
      msg += "Must have non-zero length; ";
    }
    if (this.state.pkgWidth === 0) {
      state = "error";
      msg += "Must have non-zero width; ";
    }
    let weig = parseFloat(this.state.pkgWeight) * 16 + parseFloat(this.state.pkgWeightOzs);
    if (weig === 0) {
      state = "error";
      msg += "Must have non-zero weight; ";
    }

    return { status: state, Msg: msg };
  };

  addAPackage = async () => {
    let val = await this.validatePkg();

    if (val.status === "success") {
      let wippkgs = [...this.state.packages];
      let pkgObj = {
        pkgName: this.state.pkgName,
        scaleType: this.state.scaleType,
        pkgLength: parseFloat(this.state.pkgLength),
        pkgWidth: parseFloat(this.state.pkgWidth),
        pkgHeight: parseFloat(this.state.pkgHeight),
        pkgWeight: parseFloat(this.state.pkgWeight),
        pkgWeightOzs: parseFloat(this.state.pkgWeightOzs),
      };
      wippkgs.push(pkgObj);
      this.setState({
        packages: wippkgs,
        pkgName: "",
        pkgLength: 0,
        pkgWidth: 0,
        pkgHeight: 0,
        pkgWeight: 0,
        pkgWeightOzs: 0,
        errMsg: "",
        errFlag: false,
      });
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
    return res;
  };

  handleShippingSave = async () => {
    let url = baandaServer + saveDefaultShippingPkgs;
    let input = {
      communityId: this.props.communityId,
      packages: this.state.packages,
      clientProgram: "DefaultPkgSetup",
      clientFunction: "handleShippingSave",
    };

    try {
      let pkgret = await axios.post(url, input);
      if (pkgret.data.status === "success") {
        this.setState({
          errMsg: "Successfully updated",
          errFlag: false,
          packages: pkgret.data.Msg,
        });
      } else {
        this.setState({
          errMsg: pkgret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  selectThispkgToDelete = async (pkg) => {
    let newPkgs = [];
    this.state.packages.forEach((obj) => {
      if (pkg.pkgName !== obj.pkgName) {
        newPkgs.push(obj);
      }
    });

    this.setState({
      packages: newPkgs,
      errMsg: '',
      errFlag: false
    });
  }; 

  render() {
    // console.log("this.state:", this.state);
    // console.log("this.props:", this.props);
    console.log("DefaultPkgSetup...");

    let savePkgButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.handleShippingSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save&nbsp;
        <i className="fa fa-inbox" />
      </button>
    );

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addLineButton = (
      <button
        className="btn_reg_70 def-adm-add-button-pos"
        type="button"
        onClick={this.addAPackage}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add <i className="fa fa-plus" />
      </button>
    );

    let mksFpsPanel;
    mksFpsPanel = (
      <div className="row ship-handler-type-row">
        <div className="col text-center radio-font-style">
          <strong>Scale Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="fps"
                checked={this.state.scaleType === "fps"}
                onChange={this.handleScaleType}
              />{" "}
              FPS (Foot-Ozs)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="mks"
                checked={this.state.scaleType === "mks"}
                onChange={this.handleScaleType}
                disabled
              />{" "}
              MKS (Meter-Kg)
            </label>
          </div>
        </div>
      </div>
    );

    let dim;
    if (this.state.scaleType === "fps") {
      dim = "inchs";
    } else {
      dim = "cm";
    }

    let aPkgPanel;
    aPkgPanel = (
      <div className="adm-pkg-input-box">
        <div className="row adm-pkg-inp-row1">
          <div className="col-2 text-right adm-pkg-lbl">Name</div>
          <div className="col-4 text-left inv-refid-lbl">
            <input
              name="pkgName"
              type="text"
              value={this.state.pkgName}
              onChange={this.onChange}
              size="15"
              maxLength="15"
              className="adm-pkg-name"
              placeholder=""
            />
          </div>
          <div className="col-6 text-center inv-refid-lbl">{mksFpsPanel}</div>
        </div>

        <div className="row adm-pkg-inp-row">
          <div className="col-2 text-right adm-pkg-lbl">Length</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgLength"
              type="number"
              value={this.state.pkgLength}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {dim}
          </div>
          <div className="col-2 text-right adm-pkg-lbl">Width</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgWidth"
              type="number"
              value={this.state.pkgWidth}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {dim}
          </div>
        </div>
        <div className="row adm-pkg-inp-row">
          <div className="col-2 text-right adm-pkg-lbl">Height</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgHeight"
              type="number"
              value={this.state.pkgHeight}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {dim}
          </div>

          <div className="col-2 text-right adm-pkg-lbl">Weight</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgWeight"
              type="number"
              value={this.state.pkgWeight}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight-lboz"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            <font size="2">Lbs</font> &nbsp;&nbsp;
            <input
              name="pkgWeightOzs"
              type="number"
              value={this.state.pkgWeightOzs}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight-lboz"
              step=".1"
              placeholder="0.0"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            <font size="2">Ozs</font>
          </div>
        </div>
        <div className="row adm-pkg-inp-row">
          <div className="col-10 text-left adm-pkg-lbl">&nbsp;</div>
          <div className="col-2 text-left adm-pkg-lbl">{addLineButton}</div>
        </div>
      </div>
    );

    let pkgLineBody;

    let bool = true;
    if (this.state.packages) {
      if (this.state.packages.length > 0) {
        pkgLineBody = (
          <div className="">
            <div className="row am-pkg-lines-row">
              <div className="col-1 text-center adm-pkg-line-header">Ops</div>
              <div className="col-3 text-left adm-pkg-line-header">Name</div>
              <div className="col-2 text-left adm-pkg-line-header">Length</div>
              <div className="col-2 text-left adm-pkg-line-header">Width</div>
              <div className="col-2 text-left adm-pkg-line-header">Height</div>
              <div className="col-2 text-left adm-pkg-line-header">Lbs/Ozs</div>
            </div>
            <div className="adm-pkg-lines-box">
              {this.state.packages.map((obj, i) => {
                let scale = "";

                if (obj.scaleType === "fps") {
                  scale = "in";
                } else {
                  scale = "cm";
                }
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-invline-dr"
                          : "row row-col-invline-lt"
                      }
                    >
                      <div className="col-1 text-center">
                        <button
                          className="btn-sub-line-del"
                          type="button"
                          onClick={() => this.selectThispkgToDelete(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                      <div className="col-3 text-left adm-pkg-line-text">
                        {obj.pkgName}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgLength)} {scale}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgWidth)} {scale}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgHeight)} {scale}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgWeight)} /{" "}
                        {obj.pkgWeightOzs
                          ? this.commaFormattedCurrency(obj.pkgWeightOzs)
                          : 0}
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        pkgLineBody = (
          <div className="">
            <div className="row am-pkg-lines-row">
              <div className="col-1 text-center adm-pkg-line-header">Ops</div>
              <div className="col-3 text-left adm-pkg-line-header">Name</div>
              <div className="col-2 text-left adm-pkg-line-header">Length</div>
              <div className="col-2 text-left adm-pkg-line-header">Width</div>
              <div className="col-2 text-left adm-pkg-line-header">Height</div>
              <div className="col-2 text-left adm-pkg-line-header">Weight</div>
            </div>
            <div className="adm-pkg-lines-box">
              <div className="text-center">
                No package has been defined yet...
              </div>
            </div>
          </div>
        );
      }
    }

    let outputPanel;

    outputPanel = (
      <div className="adm-ship-pkg-box">
        <div className="text-center ship-pkg-head">
          Default Package Size {savePkgButton} {infoButton}
        </div>
        {aPkgPanel}
        {pkgLineBody}
        <div
          className={
            this.state.errFlag
              ? "text-center adm-pkg-msg-err"
              : "text-center adm-pkg-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

DefaultPkgSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefaultPkgSetup));
