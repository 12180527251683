import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./SwapSubscriptionCC.css";

import SwapCCOfSubscription from "../../../../../../account/finance/communities/SwapCCOfSubscription";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const setSwapIntent = "/routes/payctrl/setSwapIntent";

const readMeCode = "0000000000";

class SwapSubscriptionCC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      errMsg: "",
      errFlag: false,

      intent: null,
      swapCCFlag: false,
      stripeConnectedId: "",
      inputData: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setTheIntent();

    await this.setState({
      deviceSize,
    });
  };

  setTheIntent = async () => {
    let input = {
      customerId: this.props.subSelected.subscriptionSetupObj.customer.id,
      communityId: this.props.communityId,
    };
    try {
      let url = baandaServer + setSwapIntent; 
      let inret = await axios.post(url, input);
      // console.log("inret:", inret);
      if (inret.data.status === "success") {
        let inputData = {
          connectedAccId: inret.data.Msg.stripeConnectedId,
          client_secret: inret.data.Msg.setupIntent.client_secret,
          last4: this.props.last4,
          customerId: this.props.customerId,
          subscriptionId: this.props.subSelected.subscriptionId
        };
        this.setState({
          intent: inret.data.Msg.setupIntent,
          stripeConnectedId: inret.data.Msg.stripeConnectedId,
          inputData,
          errMsg: "",
          errFlag: false,
          swapCCFlag: true,
        });
      } else {
        this.setState({
          errMsg: inret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) { 
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToDetailsPause = () => {
    this.props.returnToCaller();
  };

  handleBackFromSwap = async () => {
    // console.log("handleBackFromSwap");
    this.returnToDetailsPause();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('SwapSubscriptionCC...');

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    // let exitButton = (
    //     <button
    //       className="btn-swap-cc-exit"
    //       type="button"
    //       onClick={this.returnToDetailsPause}
    //       style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //     >
    //       X
    //     </button>
    //   );

    let outputPanel;

    // outputPanel = (
    //   <div>
    //     Swap <button onClick={this.returnToDetailsPause}>R</button> {infoButton}
    //   </div>
    // );

    if (this.state.swapCCFlag) {
      console.log("this.prope.last4:", this.props.last4);
      outputPanel = (
        <div>
          <div className="text-center swap-sub-cc-head">
            Swap Subscription Credit Card {infoButton}
          </div>
          <div className="text-center swap-sub-cc-head-lbl">
            Enter the new CC Information
          </div>
          <div>
            <SwapCCOfSubscription
              inputData={this.state.inputData}
              deviceSize={this.state.deviceSize}
              handleExit={this.handleBackFromSwap}
              caller="swapccintent"
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SwapSubscriptionCC.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SwapSubscriptionCC)
);

// export default SwapSubscriptionCC;
