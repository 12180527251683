import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import LoadFileToS3 from "../../../../utils/components/fileUpload/LoadFileToS3";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { emailValidation } from "../../../../utils/emailValidation";

import TalkLog from "../coop/TalkLog";
import StoreSetup from "../coop/StoreSetup";
import Closeopen from "../coop/CloseOpen";
import ShippingKeepAdm from "../coop/delivery/ShippingKeepAdm";
import DeliveryDefaultSetup from "../coop/delivery/DeliveryDefaultSetup";
import ReturnPolicySetup from "../coop/return/ReturnPolicySetup";
// import ManageMembers from "./utils/ManageMembers";

import "./StoreKeepHub.css";
 

const imageFileTypes = ["jpeg", "jpg", "bmp", "tiff", "png", "gif", "ebp"];
const maxImageSize = 52428800; // 500KB

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getStoresForAkeeper = "/routes/coop/getStoresForAkeeper?";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
const sendASMSMessage = "/routes/myaccount/sendASMSMessage?";
const saveWipKeeperInit = "/routes/coop/saveWipKeeperInit";
const savePicId = "/routes/coop/savePicId";
const getAFreshStoreInfo = "/routes/coop/getAFreshStoreInfo?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1112110000";

class StoreKeepHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [],

      selheight: 1,
      selectedStore: null,
      selOpsListHeight: 1,

      freeQnA: [],
      agreement: [],

      answer: "",
      showSeqno: 0,
      showQuestion: "",
      selectedQnA: null,

      storeName: "",
      storeShortName: "",
      street: "",
      city: "",
      postalCode: "",
      state: "CA",
      country: "USA",
      description: "",
      deviceSize: "",

      showNextButtonFlag: false,
      showPreviousButtonFlag: false,

      contactCell: "",
      contactEmail: "",
      contactName: "",
      contactBaandaId: 0,
      cellCode: "",
      sentCode: "",
      sentTime: null,
      cellConfirmMsg: "",
      cellConfirmed: false,

      opsType: "basic",
      confirmForApproval: false,

      initiateFlag: true,
      talkLogFlag: false,
      storeSetupFlag: false,
      closeopenFlag: false,
      shippingAdmFlag: false,
      deliveryDefaultFlag: false,
      returnPolicyFlag: false,

      picidS3File: null,
      fileUploads: [],
      s3CoopSWbucket: "",

      discountAmt: 0,
      discountForMonths: 0,
      fixedRent: 0,
      meterValue: 0,
      subscriptionType: "",
      graceInDays: 0,
      aprPercent: 0,

      errMsg: "",
      errFlag: false,

      showErrMsgPanel: false,
      showVerifyCellFlag: true,
      paymentCycle: "monthly",
      agreementNote: "",

      // manageMembersFlag : false
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let contactCell = this.props.auth.user.cell.number;
    let contactEmail = this.props.auth.user.email;
    let contactName = this.props.auth.user.name;
    let contactBaandaId = this.props.auth.user.baandaId;

    let showErrMsgPanel = false;
    let errMsg = "";
    let agreement = [];

    agreement = this.props.community.storeAgreementMaster.agreements;

    await this.getStores();

    let initiateFlag = true;
    if (deviceSize === "small") initiateFlag = false;

    this.setState({
      deviceSize,
      contactCell,
      contactEmail,
      contactName,
      contactBaandaId,
      showErrMsgPanel,
      initiateFlag,
      errMsg,
      agreement,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  getStores = async () => {
    let params;
    // console.log("this.props.role:", this.props.role);
    if (this.props.role === "storeowner") {
      // For regular storeowners
      params =
        "communityId=" +
        this.props.communityId +
        "&baandaId=" +
        this.props.auth.user.baandaId;
    } else {
      // If someone else has been appointed to be a keeper for the main community stores.
      let creatorBaandaId;
      this.props.community.members.forEach((obj) => {
        if (obj.role === "creator") creatorBaandaId = obj.baandaId;
      });
      params =
        "communityId=" +
        this.props.communityId +
        "&baandaId=" +
        creatorBaandaId;
    }

    let url = baandaServer + getStoresForAkeeper + params;
    // console.log("url:", url);
    try {
      let stret = await axios.get(url);
      // console.log("stret.data.Msg:", stret.data.Msg);
      if (stret.data.status === "success") {
        if (stret.data.Msg.length === 0) {
          this.setState({
            errMsg:
              "You do not have any stores to work with. Request store initiation.",
            errFlag: true,
          });
        } else {
          this.setState({
            stores: stret.data.Msg,
            showNextButtonFlag: true,
            errMsg: "",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          errMsg: stret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleStoreSelect = async () => {
    // console.log("handleStoreSelect ....");
    let value = document.getElementById("coopStoreListxx").value;
    let data = JSON.parse(value);
    let showVerifyCellFlag = false;
    if (data.status === "wip") showVerifyCellFlag = true;

    await this.setState({
      selectedStore: data,
      selheight: 1,
      showVerifyCellFlag,
    });

    await this.perpForStoreInit(data);

    // if (document.getElementById("coopStoreListxx")) {
    //   document.getElementById("coopStoreListxx").value = "DEFAULT";
    // }
  };

  perpForStoreInit = async (store) => {
    // console.log("in perpForStoreInit... store:", store);
    let freeQnA = [];
    if (store.freefromQnA.length > 0) {
      freeQnA = [...store.freefromQnA];
    } else {
      this.props.community.storeQuestionsMaster.questions.forEach((obj) => {
        let qnaObj = {
          seqno: obj.seqno,
          question: obj.question,
          answer: "",
        };
        freeQnA.push(qnaObj);
      });
    }

    let showSeqno = freeQnA[0].seqno;
    let showQuestion = freeQnA[0].question;
    let answer = freeQnA[0].answer;
    let selectedQnA = freeQnA[0];
    let errMsg = "";
    let errFlag = false;

    let agreement = null;
    let discountAmt = 0;
    let discountForMonths = 0;
    let fixedRent = 0;
    let meterValue = 0;
    let subscriptionType = "";
    let graceInDays = 0;
    let aprPercent = 0;

    if (store.agreementMaster) {
      agreement = store.agreementMaster.agreement;
      discountAmt = store.agreementMaster.discountAmt;
      discountForMonths = store.agreementMaster.discountForMonths;
      fixedRent = store.agreementMaster.fixedRent;
      meterValue = store.agreementMaster.meterValue;
      subscriptionType = store.agreementMaster.subscriptionType;
      // graceInDays = store.agreementMaster.graceInDays;
      // aprPercent = store.agreementMaster.aprPercent;
      graceInDays = 5;
      aprPercent = 4;
    } else {
      if (this.props.community.storeAgreementMaster) {
        // console.log(
        //   "this.props.community.storeAgreementMaster:",
        //   this.props.community.storeAgreementMaster
        // );
        agreement = this.props.community.storeAgreementMaster.agreements;
        discountAmt = this.props.community.storeAgreementMaster.discountAmt;
        discountForMonths =
          this.props.community.storeAgreementMaster.discountForMonths;
        fixedRent = this.props.community.storeAgreementMaster.fixedRent;
        meterValue = this.props.community.storeAgreementMaster.meterValue;
        subscriptionType =
          this.props.community.storeAgreementMaster.subscriptionType;
        graceInDays = this.props.community.storeAgreementMaster.graceInDays;
        aprPercent = this.props.community.storeAgreementMaster.aprPercent;
      } else {
        agreement = null;
        errMsg =
          "Coop adminstrator has not stated agreement. Contact co-op admin/owner.";
        errFlag = true;
      }
    }

    let description = store.description;
    let street = store.storeLocation.street;
    let city = store.storeLocation.city;
    let state = store.storeLocation.state;
    let postalCode = store.storeLocation.postal_code;
    let country = store.storeLocation.country;

    let contactName = this.state.contactName;
    let contactCell = this.state.contactCell;
    let contactEmail = this.state.contactEmail;
    let contactBaandaId = this.state.contactBaandaId;
    let cellConfirmed = this.state.cellConfirmed;
    if (store.contact.name !== "") contactName = store.contact.name;
    if (store.contact.cell !== "") contactCell = store.contact.cell;
    if (store.contact.email !== "") contactEmail = store.contact.email;
    if (store.contact.cellConfirmed)
      cellConfirmed = store.contact.cellConfirmed;

    let fileUploads = [...store.fileUploads];
    let picidS3File = null;
    if (store.fileUploads.length > 0) {
      store.fileUploads.forEach((obj) => {
        if (obj.type === "picid") {
          picidS3File = obj;
        }
      });
    }

    let storeName = store.storeName;
    let storeShortName = store.storeShortName;

    let s3CoopSWbucket = store.s3CoopSWbucket;

    let paymentCycle = store.paymentCycle;
    let agreementNote = store.agreementKeeperComment;

    this.setState({
      freeQnA,
      showSeqno,
      showQuestion,
      answer,
      selectedQnA,
      agreement,
      description,
      street,
      city,
      state,
      postalCode,
      country,
      contactName,
      contactCell,
      contactEmail,
      contactBaandaId,
      picidS3File,
      fileUploads,
      s3CoopSWbucket,
      storeName,
      storeShortName,
      cellConfirmed,
      discountAmt,
      discountForMonths,
      fixedRent,
      meterValue,
      subscriptionType,
      graceInDays,
      aprPercent,
      errMsg,
      errFlag,
      paymentCycle,
      agreementNote,
    });
  };

  onfocus = async () => {
    this.setState({
      selheight: 4,
    });
  };

  onblur = async () => {
    this.setState({
      selheight: 1,
    });
  };

  onOpsonfocus = async () => {
    this.setState({
      selOpsListHeight: 5,
    });
  };

  onOpsonblur = async () => {
    this.setState({
      selOpsListHeight: 1,
    });
  };

  handleConfirmForApproval = async () => {
    this.setState((prevstate) => ({
      confirmForApproval: !prevstate.confirmForApproval,
    }));
  };

  checkAddress = async () => {
    let param =
      "street1=" +
      this.state.street +
      "&city=" +
      this.state.city +
      "&zip=" +
      this.state.postalCode +
      "&state=" +
      this.state.state +
      "&country=" +
      this.state.country;
    let url = baandaServer + easypostAddressCheck + param;
    let status = "success";
    let msg = "";
    try {
      let check = await axios.get(url);
      // console.log("check:", check);
      if (check.data.status === "success") {
        await this.setState({
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: check.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    return { status, Msg: msg };
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  verifyCell = async () => {
    try {
      let vcode = this.randfn(100001, 999999);
      // console.log("vcode:", vcode);
      let params =
        "type=sms&cellno=" +
        this.state.contactCell +
        "&message=Baanda ... enter this code  <" +
        vcode +
        "> to verify your cell number.";
      let url = baandaServer + sendASMSMessage + params;
      let smsret = await axios.get(url);
      // console.log("smsret.data:", smsret.data);
      if (smsret.data.status === "success") {
        let nowt = new Date();
        // console.log("nowt:", nowt);
        await this.setState({
          sentCode: vcode.toString(),
          sentTime: nowt,
          cellCode: "",
          cellConfirmMsg: "",
          errMsg: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          errMsg: smsret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("verifyCell err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChangeSMS = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    // console.log("111  this.state.cellCode:", this.state.cellCode);
    if (this.state.cellCode.length === 6) {
      // console.log(
      //   "this.state.cellCode:",
      //   this.state.cellCode,
      //   " this.state.sentCode:",
      //   this.state.sentCode
      // );
      await this.verifyThisCell();
    }
  };

  verifyThisCell = async () => {
    let nowt = new Date();
    let difinms = Math.abs(nowt - this.state.sentTime);
    // console.log("difinms:", difinms);
    let secLapse = difinms / 1000;
    // console.log("secLapse:", secLapse);
    if (secLapse < 300) {
      if (this.state.sentCode !== this.state.cellCode) {
        await this.setState({
          errMsg: "Invalid Code",
          errFlag: true,
        });
      } else {
        await this.setState({
          // errMsg: "Cell number confirmed",
          cellConfirmMsg: "Cell number confirmed",
          // errFlag: false,
          cellConfirmed: true,
        });
      }
    } else {
      await this.setState({
        errMsg: "Time Expired ... Verify Cell again.",
        errFlag: true,
      });
    }
  };

  handleNextQnA = () => {
    let tempqa = [];
    this.state.freeQnA.forEach((obj) => {
      if (obj.seqno === this.state.showSeqno) {
        obj.answer = this.state.answer;
      }
      tempqa.push(obj);
    });

    this.setState({
      freeQnA: tempqa,
    });

    tempqa = [];

    let newseqno = this.state.showSeqno + 1;
    if (newseqno > this.state.freeQnA.length) newseqno = this.state.showSeqno;
    // console.log("this.state.showSeqno:", this.state.showSeqno);
    // console.log("newseqno:", newseqno);
    // console.log("this.state.answer:", this.state.answer);

    let showQuestion = this.state.showQuestion;
    let answer = "";
    let selectedQnA = this.state.selectedQnA;
    this.state.freeQnA.forEach((obj) => {
      if (obj.seqno === newseqno) {
        answer = obj.answer;
        showQuestion = obj.question;
        selectedQnA = obj;
      }
      tempqa.push(obj);
    });

    let showNextButtonFlag = this.state.showNextButtonFlag;
    let showPreviousButtonFlag = this.state.showPreviousButtonFlag;
    if (this.state.showSeqno + 1 > this.state.freeQnA.length)
      showNextButtonFlag = false;
    if (newseqno > 1 && this.state.freeQnA.length > 0)
      showPreviousButtonFlag = true;

    this.setState({
      freeQnA: tempqa,
      showSeqno: newseqno,
      answer,
      showQuestion,
      selectedQnA,
      showNextButtonFlag,
      showPreviousButtonFlag,
    });
  };

  handlePrevQnA = () => {
    let tempseqno = this.state.showSeqno;
    if (this.state.showSeqno > 1) tempseqno = this.state.showSeqno - 1;

    let answer = this.state.answer;
    let showQuestion = this.state.showQuestion;
    let tempqa = [];
    let selectedQnA = this.state.selectedQnA;

    this.state.freeQnA.forEach((obj) => {
      if (obj.seqno === tempseqno) {
        answer = obj.answer;
        showQuestion = obj.question;
        selectedQnA = obj;
      }
      tempqa.push(obj);
    });

    let showNextButtonFlag = this.state.showNextButtonFlag;
    let showPreviousButtonFlag = this.state.showPreviousButtonFlag;

    if (tempseqno === 1) showPreviousButtonFlag = false;
    if (tempseqno < this.state.freeQnA.length) showNextButtonFlag = true;

    this.setState({
      freeQnA: tempqa,
      showSeqno: tempseqno,
      answer,
      showQuestion,
      showPreviousButtonFlag,
      showNextButtonFlag,
      selectedQnA,
    });
  };

  returnFromOutside = async (obj) => {
    // console.log("obj:", obj);
    // console.log("obj.from:", obj.from);
    let opsType = "basic";
    let selectedStore = this.state.selectedStore;
    if (obj.from === "talklog") {
      await this.getStores();
      // console.log("stores:", this.state.stores);
      this.state.stores.forEach((stor) => {
        if (stor.coopStoreId === selectedStore.coopStoreId)
          selectedStore = stor;
      });
    }
    this.setState({
      initiateFlag: true,
      talkLogFlag: false,
      storeSetupFlag: false,
      closeopenFlag: false,
      shippingAdmFlag: false,
      deliveryDefaultFlag: false,
      opsType,
      selectedStore,
    });
  };

  sets3CoopSWbucket = async () => {
    let subBucket = this.state.s3CoopSWbucket;
    if (subBucket === "") {
      subBucket =
        "cid-" +
        this.props.communityId +
        "-coop-" +
        this.state.selectedStore.coopStoreId;
    }
    await this.setState({
      s3CoopSWbucket: subBucket,
    });
  };

  uploadLicense = async () => {
    if (this.state.s3CoopSWbucket === "") await this.sets3CoopSWbucket();
    this.setState({
      talkLogFlag: false,
      uploadIdFlag: true,
    });
  };

  callerOfImageUpload = async (s3loadret) => {
    let picidS3File = this.state.picidS3File;
    // console.log("s3loadret:", s3loadret);
    let deletedFileSize = 0;
    let newFileSize = 0;
    let newFileuploads = [];

    if (s3loadret.ops === "cancel") {
      newFileuploads = [...this.state.fileUploads];
    } else {
      this.state.fileUploads.forEach((obj) => {
        // console.log("obj:", obj);
        if (obj.type === "picid") {
          if (s3loadret.ifDeletedFlag) {
            if (obj.size) {
              deletedFileSize = obj.size;
              // console.log("1. deletedFileSize:", deletedFileSize);
            }
            picidS3File = null;
          }
        } else newFileuploads.push(obj);
      });
      if (s3loadret.s3FileData) {
        // console.log("inside new DA");
        newFileuploads.push(s3loadret.s3FileData);
        if (s3loadret.s3FileData.size) newFileSize = s3loadret.s3FileData.size;
        picidS3File = s3loadret.s3FileData;
      }
    }

    if (s3loadret.ops === "done") {
      // console.log("Will update DA in db ...");
      let adjustOfDASize = newFileSize - deletedFileSize;
      let inputData = {
        adjustOfDASize,
        fileUploads: newFileuploads,
        communityId: this.props.communityId,
        coopStoreId: this.state.selectedStore.coopStoreId,
      };
      await this.updateDAInDB(inputData);
    } else {
      console.log("Was canceled ... did not update");
    }

    this.setState({
      talkLogFlag: false,
      uploadIdFlag: false,
      picidS3File,
      fileUploads: newFileuploads,
      opsType: "basic",
    });
  };

  updateDAInDB = async (input) => {
    // console.log("updateDAInDB input:", input);
    let url = baandaServer + savePicId;
    try {
      let picret = await axios.post(url, input);
      // console.log("picret:", picret);
      if (picret.data.status === "success") {
        this.setState({
          errFlag: false,
          errMsg: "Successfully updated",
        });
      } else {
        this.setState({
          errMsg: picret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  packInputForDB = async () => {
    let agreementMaster = {
      agreement: this.state.agreement,
      discountAmt: this.state.discountAmt,
      discountForMonths: this.state.discountForMonths,
      fixedRent: this.state.fixedRent,
      meterValue: this.state.meterValue,
      subscriptionType: this.state.subscriptionType,
      graceInDays: this.state.graceInDays,
      aprPercent: this.state.aprPercent,
    };

    let input = {
      communityId: this.props.communityId,
      coopStoreId: this.state.selectedStore.coopStoreId,
      contactName: this.state.contactName,
      contactEmail: this.state.contactEmail,
      contactCell: this.state.contactCell,
      contactBaandaId: this.state.contactBaandaId,
      street: this.state.street,
      city: this.state.city,
      state: this.state.state,
      postalCode: this.state.postalCode,
      country: this.state.country,
      storeName: this.state.storeName,
      storeShortName: this.state.storeShortName,
      description: this.state.description,
      agreementMaster,
      freeQnA: this.state.freeQnA,
      cellConfirmed: this.state.cellConfirmed,
      calledFor: "wip",
      paymentCycle: this.state.paymentCycle,
      keeperComment: this.state.agreementNote,
    };

    return input;
  };

  saveWIPInDB = async () => {
    let url = baandaServer + saveWipKeeperInit;
    let input = await this.packInputForDB();
    // console.log("input:", input);
    try {
      let wipret = await axios.post(url, input);
      // console.log("wipret.data:", wipret.data);
      if (wipret.data.status === "success") {
        this.setState({
          errMsg: "Successfully saved (WIP)",
          errFlag: false,
          selectedStore: wipret.data.Msg,
        });
      }
    } catch (err) {}
  };

  processForApproval = async () => {
    let url = baandaServer + saveWipKeeperInit;
    let input = await this.packInputForDB();
    // console.log("input:", input);
    let isValid = await this.validateForApproval(input);
    // console.log("isValid:", isValid);
    if (isValid.status === "success") {
      input.calledFor = "approval";
      let aprret = await axios.post(url, input);
      if (aprret.data.status === "success") {
        this.setState({
          errMsg: "Request sent for approval.",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: aprret.data.Msg,
          errFlag: false,
        });
      }
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  validateForApproval = async () => {
    let msg = "";
    let status = "success";

    if (this.state.storeName === "") {
      msg = "Store must have a name; ";
      status = "error";
    }
    if (this.state.storeShortName === "") {
      msg += "Must have a name short name; ";
      status = "error";
    }
    if (this.state.contactName === "") {
      msg += "Must have a contact name; ";
      status = "error";
    }

    if (
      this.state.street === "" ||
      this.state.city === "" ||
      this.state.state === "" ||
      this.state.postalCode === ""
    ) {
      msg += "Must enter a complete & valid address; ";
      status = "error";
    } else {
      let adrchk = await this.checkAddress();
      if (adrchk.status !== "success") {
        msg += "Invalid store address; ";
        status = "error";
      }
    }

    let testemail = emailValidation(this.state.contactEmail);
    if (!testemail) {
      msg += "Invalid email syntax; ";
      status = "error";
    }

    if (!this.state.selectedStore.contact.cellConfirmed) {
      msg += "Cell number has not been verified; ";
      status = "error";
    }

    if (this.state.freeQnA.length > 0) {
      let complete = true;
      this.state.freeQnA.forEach((qa) => {
        if (qa.answer === "") complete = false;
      });
      if (!complete) {
        msg += "Must complete answering all questions; ";
        status = "error";
      }
    }

    if (this.state.fileUploads.length === 0) {
      msg += "Must load your ID to process. ";
      status = "error";
    }

    return { status, Msg: msg };
  };

  getLatestStoreInfo = async (coopStoreId) => {
    let param = "coopStoreId=" + coopStoreId;
    let url = baandaServer + getAFreshStoreInfo + param;

    try {
      let fresh = await axios(url);
      if (fresh.data.status === "success") {
        this.setState({
          selectedStore: fresh.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: fresh.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleSelectThisStore = async () => {
    await this.getLatestStoreInfo(this.state.selectedStore.coopStoreId);
    this.props.returnToMain(this.state.selectedStore);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleAdminOpsSelected = async () => {
    let value = document.getElementById("storeKeepAdminOpt").value;
    // console.log("value:", value);
    let initiateFlag = true;
    let talkLogFlag = false;
    let storeSetupFlag = false;
    let shippingAdmFlag = false;
    let deliveryDefaultFlag = false;
    let returnPolicyFlag = false;
    let closeopenFlag = false;
    // let manageMembersFlag = false;
    if (value === "basic" || value === "QandA" || value === "agreement") {
      initiateFlag = true;
    }
    if (value === "talklog") talkLogFlag = true;
    if (value === "storesetup") storeSetupFlag = true;
    if (value === "closeopen") closeopenFlag = true;
    if (value === "shipping") shippingAdmFlag = true;
    if (value === "deliveryDefault") deliveryDefaultFlag = true;
    if (value === "returnpolicy") returnPolicyFlag = true;
    // if (value === "member")  manageMembersFlag = true;
    this.setState({
      selOpsListHeight: 1,
      opsType: value,
      initiateFlag,
      talkLogFlag,
      storeSetupFlag,
      closeopenFlag,
      shippingAdmFlag,
      deliveryDefaultFlag,
      returnPolicyFlag,
      // manageMembersFlag,
      errMsg: "",
      errFlag: false,
    });

    // if (document.getElementById("storeKeepAdminOpt")) {
    //   document.getElementById("storeKeepAdminOpt").value = "DEFAULT";
    // }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('StoreKeepHub...');

    let infoButton;
    infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let nextButton;
    if (this.state.showNextButtonFlag) {
      nextButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleNextQnA}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
      );
    }

    let prevButton;
    if (this.state.showPreviousButtonFlag) {
      prevButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handlePrevQnA}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Prev
        </button>
      );
    }

    let cellVerifyButton;
    cellVerifyButton = (
      <button
        className="btn_reg_90"
        type="button"
        onClick={this.verifyCell}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Verify Cell
        <i className="fa fa-picture-o" />
      </button>
    );

    let wipButton;
    wipButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.saveWIPInDB}
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        WIP
      </button>
    );

    let selectButton;
    selectButton = (
      <button
        // className="btn_reg_60"
        className={
          this.state.deviceSize === "big" ? "btn_reg_60" : "btn_reg_80"
        }
        type="button"
        onClick={this.handleSelectThisStore}
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        Select
      </button>
    );

    let confirmToProcess;
    confirmToProcess = (
      <div>
        <Checkbox
          checked={this.state.confirmForApproval}
          onChange={this.handleConfirmForApproval}
        />
        &nbsp;Confirm to process for approval&nbsp;&nbsp;&nbsp;
        {this.state.confirmForApproval ? (
          <button
            className="btn_reg_75"
            type="button"
            onClick={this.processForApproval}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Process
          </button>
        ) : null}
      </div>
    );

    let addressPanel;
    addressPanel = (
      <div className="coop-address-section">
        <div className="text-center coop-basic-store-addr-head">
          Store Address
        </div>
        <div className="row">
          <div className="col-8 keeper-street-addr-pos">
            <b>Street</b>&nbsp;{" "}
            <input
              name="street"
              type="text"
              value={this.state.street}
              onChange={this.onChange}
              size="45"
              maxLength="60"
              className="keeper-addr-street"
              placeholder="Your street address"
            />
          </div>
          <div className="col-4 keeper-city-addr-pos">
            <b>City</b>&nbsp;
            <input
              name="city"
              type="text"
              value={this.state.city}
              onChange={this.onChange}
              size="45"
              maxLength="60"
              className="keeper-addr-city"
              placeholder="City"
            />
          </div>
        </div>
        <div className="row coop-store-addr-row2">
          <div className="col-3 address-text">
            <b>Zip</b>&nbsp;
            <input
              name="postalCode"
              type="number"
              value={this.state.postalCode}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="keeper-setup-addr-zip"
              placeholder="ZIP"
            />
          </div>
          {/* <div className="col-1">&nbsp;</div> */}
          <div className="col-3 address-text">
            <b>State</b>&nbsp;
            <input
              name="state"
              type="text"
              value={this.state.state}
              onChange={this.onChange}
              className="keeper-addr-state"
              size="2"
              maxLength="2"
            />
          </div>
          {/* <div className="col-1">&nbsp;</div> */}
          <div className="col-5 text-left">
            <b>Country</b>&nbsp;
            <input
              name="country"
              type="text"
              value={this.state.country}
              onChange={this.onChange}
              className="coop-store-addr-country"
              size="45"
              maxLength="60"
            />
          </div>
        </div>
      </div>
    );

    let smscode = "Texted Code";

    let contactPanel;
    contactPanel = (
      <div className="coop-contact-section">
        <div className="text-center coop-basic-contact-head">Contact Info</div>
        <div className="row coop-basic-row">
          <div className="col-2 text-right coop-basic-adm-lbl">Name</div>
          <div className="col-10 text-left">
            {/* <input
              name="contactName"
              type="text"
              value={this.state.contactName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="coop-contact-name-email"
              placeholder=""
            /> */}
            {this.state.contactName}
          </div>
        </div>
        <div className="row coop-basic-row">
          <div className="col-2 text-right coop-basic-adm-lbl">Email</div>
          <div className="col-10 text-left">
            {/* <input
              name="contactEmail"
              type="text"
              value={this.state.contactEmail}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="coop-contact-name-email"
              placeholder=""
            /> */}
            {this.state.contactEmail}
          </div>
        </div>
        <div className="row coop-basic-row">
          <div className="col-2 text-right coop-basic-adm-lbl">Cell</div>
          <div className="col-5 text-left">
            <PhoneInput
              placeholder="Contact cell#"
              value={this.state.contactCell}
              onChange={(contactCell) => this.setState({ contactCell })}
              defaultCountry={"US"}
              countries={["US", "CA", "IN"]}
              addInternationalOption={false}
              className="coop-adm-contact-cell"
            />
          </div>
          <div className="col-5 text-left">
            {this.state.showVerifyCellFlag ? cellVerifyButton : null}
            {this.state.showVerifyCellFlag ? (
              <div className="text-left coop-verify-cell-msg">
                Click once & wait few secs for the code.
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-4 text-right profile-cell-code">{smscode}</div>
          <div className="col-8 text-left ">
            <input
              name="cellCode"
              type="text"
              value={this.state.cellCode}
              onChange={this.onChangeSMS}
              size="10"
              className=""
            />
            &nbsp;&nbsp;
            <font color="green" size="3">
              <b>{this.state.cellConfirmMsg}</b>
            </font>
            <small>
              <p>The code texted is valid for 5 mins</p>
            </small>
          </div>
        </div>
        <div className="row coop-describe-row">
          <div className="col-2 text-right coop-basic-adm-lbl">Describe</div>
          <div className="col-10 text-left">
            <textarea
              name="description"
              maxLength="2000"
              placeholder=""
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="coop-basic-description"
              onChange={this.onChange}
              value={this.state.description}
              required
            />
          </div>
        </div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Monthly Payment Cycle</strong>
          </div>
        </div>
      </div>
    );

    let savePrepPanel;
    if (this.state.selectedStore && this.state.selectedStore.status === "wip" && this.state.deviceSize === 'big') {
      if (this.state.opsType === "basic" && !this.state.uploadIdFlag) {
        savePrepPanel = (
          <div>
            <div className="row">
              <div className="col-8 text-center">{confirmToProcess}</div>
              <div className="col-4 text-center">
                <button
                  className="btn_reg_70"
                  type="button"
                  onClick={this.uploadLicense}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Pic-ID
                </button>
                {wipButton}
              </div>
            </div>
          </div>
        );
      }

      if (this.state.opsType === "QandA" && this.state.deviceSize === 'big') {
        savePrepPanel = (
          <div>
            <div className="row">
              <div className="col-8 text-center">{confirmToProcess}</div>
              <div className="col-4 text-center">{wipButton}</div>
            </div>
          </div>
        );
      }

      if (this.state.opsType === "agreement" && this.state.deviceSize === 'big') {
        savePrepPanel = (
          <div>
            <div className="row">
              <div className="col-8 text-center">{confirmToProcess}</div>
              <div className="col-4 text-center">{wipButton}</div>
            </div>
          </div>
        );
      }
    }

    let opsTypeSelect;
    if (this.state.selectedStore) {
      if (this.state.deviceSize === "big") {
        opsTypeSelect = (
          <div className="row">
            <div className="col-2">&nbsp;</div>
            <div className="col-8">
              <select
                size={this.state.selOpsListHeight}
                onFocus={this.onOpsonfocus}
                onBlur={this.onOpsonblur}
                id="storeKeepAdminOpt"
                name="storeKeepAdminOpt"
                onChange={this.handleAdminOpsSelected}
                onSelect={this.handleAdminOpsSelected}
                className="store-keep-admin-ops"
                defaultValue={"DEFAULT"}
              >
                <option
                  className="admin-option-row-sel"
                  value="DEFAULT"
                  disabled
                >
                  Select an Admin Task
                </option>
                <option className="store-keep-admin-dd" value="basic">
                  &nbsp;Basic Information (Entry / View)
                </option>
                <option className="store-keep-admin-dd" value="QandA">
                  &nbsp;Coop questions & store-owner's answers
                </option>
                <option className="store-keep-admin-dd" value="agreement">
                  &nbsp;Agreement between coop & store
                </option>
                <option className="store-keep-admin-dd" value="talklog">
                  &nbsp;Log talk between coop & store
                </option>
                {this.state.selectedStore.status === "active" ? (
                  <option className="store-keep-admin-dd" value="storesetup">
                    &nbsp;Store setup and branding
                  </option>
                ) : null}
                {this.state.selectedStore.status === "active" ? (
                  <option className="store-keep-admin-dd" value="shipping">
                    &nbsp;Shipping management (standard providers)
                  </option>
                ) : null}
                {this.state.selectedStore.status === "active" ? (
                  <option
                    className="store-keep-admin-dd"
                    value="deliveryDefault"
                  >
                    &nbsp;Pickup, check defaults setup.
                  </option>
                ) : null}
                {this.state.selectedStore.status === "active" ? (
                  <option className="store-keep-admin-dd" value="returnpolicy">
                    &nbsp;Setup return policy.
                  </option>
                ) : null}
                {/* {this.state.selectedStore.status === "active" ? (
                  <option
                    className="store-keep-admin-dd"
                    value="member"
                  >
                    &nbsp;Manage workforce of the store
                  </option>
                ) : null} */}
                {/* {this.state.selectedStore.status === "active" ? (
                  <option
                    className="store-keep-admin-dd"
                    value="delivery"
                    disabled={true}
                  >
                    &nbsp;Delivery management (localized - disabled)
                  </option>
                ) : null} */}
                {this.state.selectedStore.status === "active" ? (
                  <option className="store-keep-admin-dd" value="closeopen">
                    &nbsp;Open Your Store & Set Store Hours
                  </option>
                ) : null}
              </select>
            </div>
            <div className="col-2">
              {this.state.selectedStore.status === "active"
                ? selectButton
                : null}
            </div>
          </div>
        );
      } else {
        opsTypeSelect = (
          <div>
            <div className="text-center">
              {this.state.selectedStore.status === "active"
                ? selectButton
                : null}
            </div>

            <div className="catalog-error-box">
              <div className="cat-error-header">Message</div>
              <div>
                <p align="justify" className="device-error-msg">
                  Plese use a larger display (laptop, PC, etc.) to perform adminstrative tasks.
                </p>
              </div>
            </div>
          </div>
        );
      }
    }

    let storelist;
    if (this.state.stores && this.state.stores.length > 0) {
      storelist = this.state.stores.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.storeShortName === ""
              ? "Init phase - Unnamed "
              : obj.storeShortName}
            &nbsp;({obj.ownerEmail}) status: {obj.status} { obj.openClose && obj.openClose.currentState === "open" ? "(Open)" : "(Close)"}
          </option>
        );
      });
    }

    let storeListPanel;
    storeListPanel = (
      <div className="row">
        <div className="col-2">&nbsp;</div>
        <div className="col-8">
          <select
            size={this.state.selheight}
            id="coopStoreListxx"
            name="coopStoreListxx"
            onChange={this.handleStoreSelect}
            className="coop-stores-sel-dropdown"
            onFocus={this.onfocus}
            onBlur={this.onblur}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a store
            </option>
            {storelist}
          </select>
        </div>
        <div className="col-2">&nbsp;</div>
      </div>
    );

    let activePanel;
    if (
      this.state.selectedStore &&
      this.state.initiateFlag &&
      this.state.opsType === "QandA"
    ) {
      let bool = true;
      activePanel = (
        <div>
          <div className="keeper-admin-qna-inp-box">
            <div className="row">
              <div className="col-1 coop-admin-qna-lbl text-right">
                {this.state.showSeqno}.
              </div>
              <div className="col-11 coop-admin-qna-lbl text-left">
                {this.state.showQuestion}.
              </div>
            </div>
            <div className="row">
              <div className="col coop-admin-qna-text">
                <textarea
                  name="answer"
                  maxLength="2000"
                  placeholder=""
                  rows="2"
                  wrap="hard"
                  spellCheck="true"
                  className="coop-qna-text"
                  onChange={this.onChange}
                  value={this.state.answer}
                  required
                />
              </div>
            </div>
            <div className="text-center">
              {prevButton}&nbsp;&nbsp;{nextButton}
            </div>
          </div>

          <div className="keeper-admin-qna-show-box">
            {this.state.freeQnA.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool
                        ? "row row-col-coop-admin-dr"
                        : "row row-col-coop-admin-lt"
                    }
                  >
                    <div className="col">
                      <div className="row">
                        <div className="col-1 text-right coop-admin-qna-showq">
                          {obj.seqno}.
                        </div>
                        <div className="col-11 text-left coop-admin-qna-showq">
                          {obj.question}
                        </div>
                      </div>
                      <div className="text-left coop-admin-qna-showa">
                        {obj.answer}
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (
      this.state.selectedStore &&
      this.state.initiateFlag &&
      this.state.opsType === "basic"
    ) {
      activePanel = (
        <div className="keeper-admin-basic-q-box">
          <div className="row coop-basic-row">
            <div className="col-2 text-right coop-basic-adm-lbl">
              Short Name
            </div>
            <div className="col-10 text-left">
              <input
                name="storeShortName"
                type="text"
                value={this.state.storeShortName}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="coop-store-short-name"
                placeholder="Name in 20 chr"
              />{" "}
              &nbsp;&nbsp; Store name, post approval, cannot be changed.
            </div>
          </div>
          <div className="row coop-basic-row">
            <div className="col-2 text-right coop-basic-adm-lbl">
              Store Name
            </div>
            <div className="col-10 text-left">
              <input
                name="storeName"
                type="text"
                value={this.state.storeName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="coop-store-name"
                placeholder="Name in 50 chr"
              />
            </div>
          </div>
          {addressPanel}
          {contactPanel}
        </div>
      );
    }

    if (
      this.state.selectedStore &&
      this.state.initiateFlag &&
      this.state.opsType === "agreement"
    ) {
      let bool1 = true;
      activePanel = (
        <div>
          <div className="keeper-admin-agreement-show-box">
            {this.state.agreement.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-coop-admin-dr"
                        : "row row-col-coop-admin-lt"
                    }
                  >
                    <div className="col">
                      <div className="row">
                        <div className="col-1 text-right coop-admin-qna-showq">
                          {obj.seqno}.
                        </div>
                        <div className="col-11 text-left coop-admin-qna-showq">
                          {obj.anAgreementClause}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col text-center keeper-subscription-col">
              {this.state.subscriptionType === "combo"
                ? "Subscription is $" +
                  this.commaFormattedCurrency(this.state.fixedRent) +
                  " per month + " +
                  this.commaFormattedCurrency(this.state.meterValue) +
                  " % of sales. "
                : null}
              {this.state.subscriptionType === "rent"
                ? "Subscription is $" +
                  this.commaFormattedCurrency(this.state.fixedRent) +
                  " per month. "
                : null}
              {this.state.subscriptionType === "metered"
                ? "Subscription is " +
                  this.commaFormattedCurrency(this.state.meterValue) +
                  " % of sales. "
                : null}
              {parseFloat(this.state.discountAmt) > 0
                ? "You get a discount of " +
                  this.commaFormattedCurrency(this.state.discountAmt) +
                  " % for " +
                  this.state.discountForMonths +
                  " months from the start date."
                : null}
            </div>
          </div>
          {this.state.aprPercent > 0 ? (
            <div className="row">
              <div className="col text-center keeper-subscription-col-fine">
                A late pay will acru at a rate of{" "}
                {this.commaFormattedCurrency(this.state.aprPercent)}% APR fine
                after {this.state.graceInDays} days from the monthly
                pay-due-date.
              </div>
            </div>
          ) : null}

          <div className="row comment-coop-row">
            <div className="col-2 text-right coop-agr-comment-lbl">Comment</div>
            <div className="col-10 text-left">
              <input
                name="agreementNote"
                type="text"
                value={this.state.agreementNote}
                onChange={this.onChange}
                size="250"
                maxLength="250"
                className="coop-agreement-note"
                placeholder="Comment for the approver about the agreement."
              />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.talkLogFlag) {
      activePanel = (
        <div>
          <TalkLog
            store={this.state.selectedStore}
            returnToCaller={(store) => this.returnFromOutside(store)}
          />
        </div>
      );
    }

    if (this.state.closeopenFlag) {
      activePanel = (
        <div>
          <Closeopen store={this.state.selectedStore} />
        </div>
      );
    }

    if (this.state.storeSetupFlag) {
      // console.log("this.state.selectedStore:", this.state.selectedStore);
      activePanel = (
        <div>
          <StoreSetup
            store={this.state.selectedStore}
            communityId={this.props.communityId}
            community={this.props.community}
          />
        </div>
      );
    }

    let imgRequestorMsg =
      "Image (png, jpg, bmp, gif, tiff, webp, ebp) MaxSize: " +
      maxImageSize / 1000000 +
      "MB";

    if (this.state.uploadIdFlag) {
      activePanel = (
        <div className="text-center">
          <LoadFileToS3
            returnToCaller={this.callerOfImageUpload}
            communityId={this.state.activeCommunityId}
            fileType={imageFileTypes}
            maxSize={maxImageSize}
            s3subdir={this.state.s3CoopSWbucket}
            requestor={imgRequestorMsg}
            s3FileData={this.state.picidS3File}
            type="picid"
          />
        </div>
      );
    }

    if (this.state.shippingAdmFlag) {
      activePanel = (
        <div>
          <ShippingKeepAdm
            store={this.state.selectedStore}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    if (this.state.deliveryDefaultFlag) {
      activePanel = (
        <div>
          <DeliveryDefaultSetup
            store={this.state.selectedStore}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    if (this.state.returnPolicyFlag) {
      activePanel = (
        <div>
          <ReturnPolicySetup
            store={this.state.selectedStore}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    // if (this.state.manageMembersFlag) {
    //   activePanel = (
    //     <div>
    //       <ManageMembers
    //         store={this.state.selectedStore}
    //         communityId={this.props.communityId}
    //       />
    //     </div>
    //   );
    // }

    let outputPanel;
    outputPanel = (
      <div className="keeper-manage-store-box">
        <div className="text-center keeper-header">
          Store Keeper Admin Center &nbsp;{infoButton}
        </div>
        <div className="row store-list-pos">
          <div className="col">{storeListPanel}</div>
        </div>
        {opsTypeSelect}
        {activePanel}
        {savePrepPanel}
        <div
          className={
            this.state.errFlag
              ? "tet-center coop-str-msg-err"
              : "tet-center coop-str-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    // }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

StoreKeepHub.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StoreKeepHub));
