import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./RoleDeligation.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getDomainAccessMap = "/routes/architecture/getDomainAccessMap?";
const getCommunityFuncRole = "/routes/architecture/getCommunityFuncRole?";
const updateFunctionRoleMap = "/routes/architecture/updateFunctionRoleMap";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const mapMemberToRole = "/routes/architecture/mapMemberToRole";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1158000000";

class RoleDeligation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opsType: "deligate", // assign role

      domainAccess: [],
      constructOpsType: "new",
      newSelectedDomains: [],
      funcRole: [],

      newRoleEntryFlag: false,
      domainAccessLibFlag: false,
      newRoleIncludeFlag: false,

      funcRoleToEditFlag: false,
      showEditRoleNameFlag: false,
      showEditRoleToAccessFlag: false,
      selectedRoleToEdit: null,

      roleName: "",

      message: "",
      errFlag: false,
      community: null,
      selectedMember: null,
      deligateThisRole: null,

      roleType: "constructed",
      okToDeleteRole: false,
      storeowner: false,

      terminationReason: "",
    };
  }

  componentDidMount = async () => {
    await this.getThisCommunity();
    if (this.state.community) {
 
      await this.getDomainAccess();
      await this.getFuncRoles();
    }
  };

  getThisCommunity = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      if (retData.data.status === "success") {
        await this.setState({
          community: retData.data.Msg,
          message: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getDomainAccess = async () => {
    let param = "communityType=" + this.state.community.communityType;
    let url = baandaServer + getDomainAccessMap + param;
    try {
      let ret = await axios.get(url);
      if (ret.data.status === "success") {
        let domainAccess = [];
        for (var i = 0; i < ret.data.Msg.length; i++) {
          let af = ret.data.Msg[i].authorization;

          let afString = af.toString();
          afString = af.toString().replace("[", "");
          afString = afString.replace("]", "");

          let arr = afString.replace(/ /g, "").split(",");
          let outarr = [];
          arr.forEach((obj) => {
            let x = obj.replace("'", "");
            let x1 = x.replace("'", "");
            outarr.push(x1);
          });
          let daObj = {
            domain: ret.data.Msg[i].domain,
            description: ret.data.Msg[i].description,
            authorization: outarr,
          };
          domainAccess.push(daObj);
        }
        await this.setState({
          domainAccess,
        });
      } else {
        await this.setState({
          message: ret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getDomainAccess err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  getFuncRoles = async () => {
    let params = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityFuncRole + params;
    try {
      let fret = await axios.get(url);
      if (fret.data.status === "success") {
        await this.setState({
          funcRole: fret.data.Msg,
          message: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: fret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getFuncRole err: ", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      storeowner: false,
    });
  };

  handleOperationType = async (e) => {
    let value = e.target.value;

    let newRoleEntryFlag = false;
    let domainAccessLibFlag = false;
    let newRoleIncludeFlag = false;
    let constructOpsType = "new";
    if (value === "construct") {
      newRoleEntryFlag = true;
      domainAccessLibFlag = true;
      newRoleIncludeFlag = true;
    } else {
      await this.getFuncRoles();
    }
    this.setState({
      opsType: e.target.value,
      newRoleEntryFlag,
      domainAccessLibFlag,
      newRoleIncludeFlag,
      constructOpsType,
      message: "",
      errFlag: false,
    });
  };

  handleRoleType = async (e) => {
    this.setState({
      roleType: e.target.value,
    });
  };

  handleConstructType = async (e) => {
    let value = e.target.value;
    if (this.state.constructOpsType === "edit") {
      await this.getFuncRoles();
    }
    let newRoleEntryFlag = false;
    let domainAccessLibFlag = false;
    let newRoleIncludeFlag = false;
    let funcRoleToEditFlag = false;
    if (value === "new") {
      newRoleEntryFlag = true;
      domainAccessLibFlag = true;
      newRoleIncludeFlag = true;
      funcRoleToEditFlag = false;
    } else {
      newRoleEntryFlag = false;
      domainAccessLibFlag = false;
      newRoleIncludeFlag = false;
      funcRoleToEditFlag = true;
    }
    await this.setState({
      constructOpsType: e.target.value,
      newRoleEntryFlag,
      domainAccessLibFlag,
      newRoleIncludeFlag,
      funcRoleToEditFlag,
      newSelectedDomains: [],
      message: "",
      errFlag: false,
      storeowner: false,
    });
  };

  selectThisRoleToAdd = async (obj) => {
    let newRows = [];
    let itExists = false;
    let message = "";
    let errFlag = false;
    this.state.newSelectedDomains.forEach((elm) => {
      newRows.push(elm);

      if (elm.domain === obj.domain) {
        itExists = true;
        message = "Duplicate access names not allowed.";
        errFlag = true;
      }

      if (elm.domain === "Store Owner") {
        itExists = true;
        message = "Store Owner cannot be associated with any other role.";
        errFlag = true;
      }
    });

    if (!itExists) newRows.push(obj);

    this.setState({
      newSelectedDomains: newRows,
      message,
      errFlag,
    });
  };

  selectThisRoleToDelete = async (obj) => {
    let newRows = [];
    this.state.newSelectedDomains.forEach((elm) => {
      if (elm.domain !== obj.domain) newRows.push(elm);
    });
    this.setState({
      newSelectedDomains: newRows,
      message: "",
      errFlag: false,
    });
  };

  updatedEditedRole = async () => {
    let funcAllowed = [];
    this.state.newSelectedDomains.forEach((dom) => {
      dom.authorization.forEach((ath) => {
        funcAllowed.push(ath);
      });
    });
    let desc = "";
    this.state.newSelectedDomains.forEach((des) => {
      desc += des.description + " ";
    });
    let funcRoleMap = {
      communityId: this.props.communityId,
      intent: "business",
      focus: "catalog",
      role: this.state.selectedRoleToEdit.role,
      functionsAllowed: funcAllowed,
      cumulativeDomains: this.state.newSelectedDomains,
      description: desc,
    };

    await this.saveFunctionRoleMap(funcRoleMap, "update");
  };

  saveNewRole = async () => {

    let duplicate;
    this.state.funcRole.forEach((obj) => {
      if (obj.role.toLowerCase() === this.state.roleName.toLowerCase())
        duplicate = true;
    });

    let error = false;

    if (
      this.state.roleName === "user" ||
      this.state.roleName === "customer" ||
      this.state.roleName === "prospect" ||
      this.state.roleName === "creator"
    ) {
      error = true;
      this.setState({
        message:
          "Role name user, customer, prospect and creator are reserved roles.",
        errFlag: true,
      });
    }
    if (!duplicate && !error) {
      let funcAllowed = [];
      this.state.newSelectedDomains.forEach((dom) => {
        dom.authorization.forEach((ath) => {
          funcAllowed.push(ath);
        });
      });

      let desc = "";
      this.state.newSelectedDomains.forEach((des) => {
        desc += des.description + " ";
      });
      let funcRoleMap = {
        communityId: this.props.communityId,
        intent: "business",
        focus: "catalog",
        role: this.state.roleName,
        functionsAllowed: funcAllowed,
        cumulativeDomains: this.state.newSelectedDomains,
        description: desc,
        deleted: false,
      };

      await this.saveFunctionRoleMap(funcRoleMap, "save");
    } else {
      if (duplicate) {
        await this.setState({
          message: "The role name exists. Role name must be unique.",
          errFlag: true,
        });
      }
    }
  };

  saveFunctionRoleMap = async (funcRole, opsType) => {

    let url = baandaServer + updateFunctionRoleMap;
    let input = {
      clientProgram: "RoleDeligation",
      clientFunction: "saveFunctionRoleMap",
      FunctionRoleMap: funcRole,
      members: this.state.community.members,
      opsType,
    };

    try {
      let fret = await axios.post(url, input);

      if (fret.data.status === "success") {
        await this.setState({
          message: "Successfully Updated.",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: fret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("saveFunctionRoleMap err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  selectThisToEdit = async (obj) => {

    let newSelectedDomains = [];
    obj.cumulativeDomains.forEach((elm) => {
      newSelectedDomains.push(elm);
    });
    await this.setState({
      selectedRoleToEdit: obj,
      showEditRoleNameFlag: true,
      showEditRoleToAccessFlag: true,
      funcRoleToEditFlag: false,
      domainAccessLibFlag: true,
      newSelectedDomains,
      newRoleIncludeFlag: true,
    });
  };

  selectThisMember = async (member) => {

    await this.setState({
      selectedMember: member,
    });
  };

  selectThisRoleToMap = async (role) => {

    this.setState({
      deligateThisRole: role,
    });
  };

  deligateRoleToMember = async () => {
    let currentRole = "";
    this.state.community.members.forEach((mem) => {
      if (mem.baandaId === this.state.selectedMember.baandaId)
        currentRole = mem.role;
    });

    let deligator = {
      baandaId: this.props.auth.user.baandaId,
      name: this.props.auth.user.name,
      cell: this.props.auth.user.cell.number,
      email: this.props.auth.user.email,
    };

    if (currentRole !== "storeowner") {
      let url = baandaServer + mapMemberToRole;

      let role = "";
      if (this.state.deligateThisRole) role = this.state.deligateThisRole.role;

      let input;
      if (this.state.storeowner) {
        input = {
          communityId: this.props.communityId,
          role: "storeowner",
          roleType: this.state.roleType,
          email: this.state.selectedMember.email,
          baandaId: this.state.selectedMember.baandaId,
          deligator,
          terminationReason: this.state.terminationReason,
          clientFunction: "deligateRoleToMember",
          clientProgram: "RoleDeligation",
        };
      } else {
        input = {
          communityId: this.props.communityId,
          role,
          roleType: this.state.roleType,
          email: this.state.selectedMember.email,
          baandaId: this.state.selectedMember.baandaId,
          deligator,
          terminationReason: this.state.terminationReason,
          clientFunction: "deligateRoleToMember",
          clientProgram: "RoleDeligation",
        };
      }

      try {
        let retrole = await axios.post(url, input);
        if (retrole.data.status === "success") {
          this.setState({
            message: "Successfully deligated",
            errFlag: false,
            deligateThisRole: null,
            selectedMember: null,
            community: retrole.data.Msg,
          });
        } else {
          this.setState({
            message: retrole.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("err:", err.message);
      }
    } else {
      if (this.state.storeowner) {
        this.setState({
          message:
            this.state.selectedMember.name +
            " is already a store owner. No need to re-deligate",
          errFlag: true,
        });
      } else {
        this.setState({
          message:
            this.state.selectedMember.name +
            " is a storeowner; cannot deligate any other role. Check info for more ...",
          errFlag: true,
        });
      }
    }
  };

  handleDeleteConfirm = async () => {
    this.setState((prevstate) => ({
      okToDeleteRole: !prevstate.okToDeleteRole,
    }));
  };

  handleStoreKeep = async () => {
    this.setState((prevstate) => ({
      storeowner: !prevstate.storeowner,
      roleName: "storeowner",
    }));
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('RoleDelegation...');

    let helpButton = (
      <button
        className="btn_info_main"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let saveNewRoleButton = (
      <button
        className="btn_reg_70"
        onClick={this.saveNewRole}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let updateRoleButton = (
      <button
        className="btn_reg_70"
        onClick={this.updatedEditedRole}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Update
      </button>
    );

    let deligateRoleButton;
    if (
      (this.state.roleType === "constructed" &&
        this.state.deligateThisRole &&
        this.state.selectedMember) ||
      (this.state.roleType === "norole" && this.state.selectedMember) ||
      (this.state.roleType === "terminate" && this.state.selectedMember) ||
      (this.state.selectedMember && this.state.storeowner)
    ) {
      deligateRoleButton = (
        <button
          className="btn_reg_75"
          onClick={this.deligateRoleToMember}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Delegate
        </button>
      );
    }

    let opsSelectionPanel;
    opsSelectionPanel = (
      <div className="row role-ops-select-row">
        <div className="col text-center radio-font-style">
          <strong>Operation Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="deligate"
                checked={this.state.opsType === "deligate"}
                onChange={this.handleOperationType}
              />{" "}
              Delegate Role
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="construct"
                checked={this.state.opsType === "construct"}
                onChange={this.handleOperationType}
              />{" "}
              Construct Role
            </label>
          </div>
        </div>
      </div>
    );

    let consOpsPanel;
    consOpsPanel = (
      <div>
        <div className="row role-ops-select-row">
          <div className="col text-center radio-font-style">
            <strong>Role Construct&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="new"
                  checked={this.state.constructOpsType === "new"}
                  onChange={this.handleConstructType}
                />{" "}
                New Role
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="edit"
                  checked={this.state.constructOpsType === "edit"}
                  onChange={this.handleConstructType}
                />{" "}
                Edit Role
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let newRoleInputPanel;
    // if (this.state.constructOpsType === "new") {
    if (this.state.newRoleEntryFlag) {
      newRoleInputPanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right adm-role-lbl">Role Name</div>
            <div className="col-5 text-left">
              <input
                name="roleName"
                type="text"
                value={this.state.roleName}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="role-name-width"
                placeholder="Name in 20 char"
              />
            </div>
            <div className="col-4">&nbsp;</div>
            {/* {this.props.communityType === "coop" ? (
              <div className="col-4 text-left">
                <b>OR</b> &nbsp;&nbsp;
                <Checkbox
                  checked={this.state.storeowner}
                  onChange={this.handleStoreKeep}
                />{" "}
                Store Owner
              </div>
            ) : (
              <div className="col-4">&nbsp;</div>
            )} */}
          </div>
        </div>
      );
    }

    let domainAccessPanel;
    if (this.state.domainAccessLibFlag) {
      let bool = true;
      domainAccessPanel = (
        <div>
          <div className="row domain-row-pos">
            <div className="col-1 text-right role-name-lbl">Ops</div>
            <div className="col-4 text-left role-name-lbl">Name</div>
            <div className="col-7 text-left role-name-lbl">
              Description 
            </div>
          </div>
          <div className="fixedsize-domain-list">
            {this.state.domainAccess.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-right">
                      <button
                        className="btn-select-domain"
                        type="button"
                        onClick={() => this.selectThisRoleToAdd(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </div>
                    <div className="col-4 text-left role-name-lbl">
                      {obj.domain}
                    </div>
                    <div className="col-7 text-left role-name-lbl-desc">
                      {obj.description}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let newConstructPanel;
    if (this.state.newRoleIncludeFlag) {
      let bool1 = true;
      newConstructPanel = (
        <div>
          <div className="row domain-row-pos">
            <div className="col-1 text-right role-name-lbl-gr">Ops</div>
            <div className="col-4 text-left role-name-lbl-gr">Name</div>
            <div className="col-7 text-left role-name-lbl-gr">
              Description 
            </div>
          </div>
          <div className="fixedsize-domain-list">
            {this.state.newSelectedDomains.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-right">
                      <button
                        className="btn-delete-domain-role "
                        type="button"
                        onClick={() => this.selectThisRoleToDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-4 text-left role-name-lbl">
                      {obj.domain}
                    </div>
                    <div className="col-7 text-left role-name-lbl-desc">
                      {obj.description}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let selRoleToEditpanel;
    if (this.state.funcRoleToEditFlag) {
      let bool2 = true;
      selRoleToEditpanel = (
        <div>
          <div>
            <div className="row domain-row-pos">
              <div className="col-1 text-right role-name-lbl-gr">Ops</div>
              <div className="col-2 text-left role-name-lbl-gr">Role</div>
              <div className="col-9 text-left role-name-lbl-gr">
                Description (access types)
              </div>
            </div>
            <div className="fixedsize-domain-list">
              {this.state.funcRole.map((obj, i) => {
                let accType = "";
                obj.functionsAllowed.forEach((elm) => {
                  accType += elm + ", ";
                });
                return (
                  <div key={i}>
                    <div
                      className={
                        bool2
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-1 text-right">
                        <button
                          className="btn-select-domain"
                          type="button"
                          onClick={() => this.selectThisToEdit(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      </div>
                      {/* <div className="col-9 text-left item-category-label"> */}
                      <div className="col-2 text-left role-name-lbl">
                        {obj.role}
                      </div>
                      <div className="col-9 text-left role-name-lbl-desc">
                        {accType}
                      </div>
                    </div>
                    {(bool2 = !bool2)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    let selectMemberPanel;
    if (this.state.opsType === "deligate" && this.state.community) {
      let bool3 = true;

      let canSelectCreator = false;
      let noOfCreator = 0;
      this.state.funcRole.forEach((elm) => {
        if (elm.role === "creator") noOfCreator++;
      });
      if (noOfCreator > 1) canSelectCreator = true;

      selectMemberPanel = (
        <div>
          <div className="text-left select-member-lbl">Select a member</div>
          <div className="row domain-row-pos">
            <div className="col-1 text-right role-name-lbl-gr">Ops</div>
            <div className="col-3 text-left role-name-lbl-gr">Role</div>
            <div className="col-4 text-left role-name-lbl-gr">Name</div>
            <div className="col-4 text-left role-name-lbl-gr">Email</div>
          </div>
          <div className="fixedsize-domain-list">
            {this.state.community.members.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool3
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-right">
                      {obj.role === "creator" && canSelectCreator ? (
                        <button
                          className="btn-select-domain"
                          type="button"
                          onClick={() => this.selectThisMember(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      ) : null}
                      {obj.role === "creator" && !canSelectCreator ? (
                        <button
                          className="btn-select-domain-disabled"
                          type="button"
                          // onClick={() => this.selectThisMember(obj)}
                          // style={{
                          //   cursor: this.state.disabled ? "default" : "pointer",
                          // }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      ) : null}
                      {obj.role !== "creator" ? (
                        <button
                          className="btn-select-domain"
                          type="button"
                          onClick={() => this.selectThisMember(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      ) : null}
                    </div>
                    {/* <div className="col-9 text-left item-category-label"> */}
                    <div className="col-3 text-left role-name-lbl">
                      {obj.role}
                    </div>
                    <div className="col-4 text-left role-name-lbl-desc">
                      {obj.name}
                    </div>
                    <div className="col-4 text-left role-name-lbl-desc">
                      {obj.email}
                    </div>
                  </div>
                  {(bool3 = !bool3)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let roleTypePanel;
    roleTypePanel = (
      <div className="row role-ops-select-row">
        <div className="col text-center radio-font-style">
          <strong>Role Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="constructed"
                checked={this.state.roleType === "constructed"}
                onChange={this.handleRoleType}
              />{" "}
              Constructed
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="norole"
                checked={this.state.roleType === "norole"}
                onChange={this.handleRoleType}
              />{" "}
              No Role (user)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="terminate"
                checked={this.state.roleType === "terminate"}
                onChange={this.handleRoleType}
              />{" "}
              Terminate
            </label>
          </div>
        </div>
      </div>
    );

    let rolesListPanel;
    if (this.state.roleType === "constructed") {
      let bool4 = true;
      rolesListPanel = (
        <div>
          <div className="fixedsize-domain-list">
            {this.state.funcRole.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool4
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-right">
                      {}
                      <button
                        className="btn-select-domain"
                        type="button"
                        onClick={() => this.selectThisRoleToMap(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                    {/* <div className="col-9 text-left item-category-label"> */}
                    <div className="col-3 text-left role-name-lbl">
                      {obj.role}
                    </div>
                    <div className="col-8 text-left role-name-lbl-desc">
                      {obj.description}
                    </div>
                  </div>
                  {(bool4 = !bool4)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let roleToDeligatePanel;
    if (
      this.state.opsType === "deligate" &&
      this.state.roleType === "constructed" &&
      !this.state.storeowner
    ) {
      roleToDeligatePanel = (
        <div>
          <div className="text-left select-role-lbl">Select a Role</div>
          <div className="row domain-row-pos">
            <div className="col-1 text-right role-name-lbl-gr">Ops</div>
            <div className="col-3 text-left role-name-lbl-gr">Role</div>

            <div className="col-8 text-left role-name-lbl-gr">
              Description (access to)
            </div>
          </div>
          {rolesListPanel}
        </div>
      );
    }

    let showRoleNameToEditPanel;
    if (this.state.showEditRoleNameFlag) {
      showRoleNameToEditPanel = (
        <div className="text-center role-name-to-edit">
          <b>Role Name (to Edit)</b>:&nbsp;{this.state.selectedRoleToEdit.role}
        </div>
      );
    }

    let showMemberRoleMapPanel;
    if (
      this.state.opsType === "deligate" &&
      this.state.roleType === "constructed" &&
      !this.state.storeowner
    ) {
      showMemberRoleMapPanel = (
        <div>
          <div className="row">
            <div className="col text-center selected-member-lbl">
              Member{" "}
              <b>
                {this.state.selectedMember
                  ? this.state.selectedMember.name
                  : "X"}
              </b>
              &nbsp;will have the Role{" "}
              <b>
                {this.state.deligateThisRole
                  ? this.state.deligateThisRole.role
                  : "Y"}
              </b>
            </div>
          </div>
          <div className="text-center">{deligateRoleButton}</div>
        </div>
      );
    }
    if (this.state.opsType === "deligate" && this.state.storeowner) {
      showMemberRoleMapPanel = (
        <div>
          <div className="row">
            <div className="col text-center selected-member-lbl">
              Member{" "}
              <b>
                {this.state.selectedMember
                  ? this.state.selectedMember.name
                  : "X"}
              </b>
              &nbsp;will have the Role <b>Storeowner</b>
            </div>
          </div>
          <div className="text-center">{deligateRoleButton}</div>
        </div>
      );
    }

    if (
      this.state.opsType === "deligate" &&
      this.state.roleType === "norole" &&
      this.state.selectedMember
    ) {
      showMemberRoleMapPanel = (
        <div>
          <div className="row">
            <div className="col text-center selected-member-lbl-orange">
              Warning: Member{" "}
              <b>
                {this.state.selectedMember.name} (
                {this.state.selectedMember.email})
              </b>
              &nbsp;will have no role (user) once deligated.{" "}
            </div>
          </div>
          <div className="text-center rol-alloc-refresh-msg">Must exit the community and return to take effect.</div>
          <div className="text-center">{deligateRoleButton}</div>
        </div>
      );
    }

    if (
      this.state.opsType === "deligate" &&
      this.state.roleType === "terminate" &&
      this.state.selectedMember
    ) {
      showMemberRoleMapPanel = (
        <div>
          <div className="row">
            <div className="col text-center selected-member-lbl-red">
              Warning: Member{" "}
              <b>
                {this.state.selectedMember.name} (
                {this.state.selectedMember.email})
              </b>
              &nbsp;will be terminated (cease to be a member) once deligated. If{" "}
              {this.state.selectedMember.name} is currenlty handling tasks, it
              will be relegated to you as the current handler.
            </div>
          </div>
          <div className="text-center rol-alloc-refresh-msg">Must exit the community and return to take effect.</div>
          <div className="row">
            <div className="col-2 text-right tr-reason-lbl">Reason</div>
            <div className="col-10 text-left ">
              <textarea
                name="terminationReason"
                maxLength="250"
                placeholder="Reason for termination in 250 words or less."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="tr-reason-text"
                onChange={this.onChange}
                value={this.state.terminationReason}
              />
            </div>
          </div>
          <div className="text-center term-reason-counter">
            Characters used: {this.state.terminationReason.length}
          </div>
          <div className="text-center">{deligateRoleButton}</div>
        </div>
      );
    }

    let updateButtonPanel;
    if (this.state.constructOpsType === "edit") {
      if (this.state.newSelectedDomains.length > 0) {
        updateButtonPanel = (
          <div className="row">
            <div className="col-8">&nbsp;</div>
            <div className="col-4 text-left role-update-btn-pos">
              {updateRoleButton}
            </div>
          </div>
        );
      } else {
        if (this.state.selectedRoleToEdit) {
          updateButtonPanel = (
            <div className="row">
              <div className="col-8 role-deligation-del-pos">
                <Checkbox
                  checked={this.state.okToDeleteRole}
                  onChange={this.handleDeleteConfirm}
                />
                &nbsp;
                <b>
                  The role '{this.state.selectedRoleToEdit.role} will be
                  deleted. Confirm.
                </b>
              </div>
              <div className="col-4 text-left role-update-btn-pos">
                {this.state.okToDeleteRole ? updateRoleButton : null}
              </div>
            </div>
          );
        }
      }
    }

    let constructRolePanel;
    if (this.state.opsType === "construct") {
      constructRolePanel = (
        <div>
          <div className="text-center adm-role-head">Construct Roles</div>
          {consOpsPanel}
          {newRoleInputPanel}
          {domainAccessPanel}
          {showRoleNameToEditPanel}
          {newConstructPanel}
          {selRoleToEditpanel}
          {/* {showRoleNameToEditPanel} */}
          {this.state.newSelectedDomains.length > 0 &&
          this.state.roleName.length > 0 &&
          this.state.constructOpsType === "new"
            ? saveNewRoleButton
            : null}
          {updateButtonPanel}

          <div
            className={
              this.state.errFlag
                ? "text-center adm-role-setup-msg-err"
                : "text-center adm-role-setup-msg"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    let deligateRolePanel;
    if (this.state.opsType === "deligate") {
      deligateRolePanel = (
        <div>
          {selectMemberPanel}
          <div className="text-center adm-role-types">{roleTypePanel}</div>
          {roleToDeligatePanel}
          {showMemberRoleMapPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center adm-role-setup-msg-err"
                : "text-center adm-role-setup-msg"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="role-deligation-box">
        <div className="text-center role-deiligate-header">
          Role Construction & Delegation&nbsp;{helpButton}
        </div>
        {opsSelectionPanel}
        {constructRolePanel}
        {deligateRolePanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

RoleDeligation.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RoleDeligation)
);
