import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
// import ReactLoading from "react-loading";

import moment from "moment";

import noImageAvailable from "../../image/notavailable.png";
import showNoImage from "../../image/blackback2.jpg";

import { arraySortNum } from "../../../../../utils/sortArrayOfObjectsNum";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import Review from "../reviews/Review";

import "./CatalogGoodsDetails.css";

// const guestLoginId = process.env.REACT_APP_BAANDA_GUEST;
const noItemImage = process.env.REACT_APP_IMAGE_NOT_AVAILABLE;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveItemInCart = "/routes/dashboard/saveItemInCart";
const doesCartHaveSubscriptionItem =
  "/routes/dashboard/doesCartHaveSubscriptionItem?";
const getCatalogPkgForDelivery = "/routes/dashboard/getCatalogPkgForDelivery?";
const setWillHaveItemVariance = "/routes/inventory/setWillHaveItemVariance";

const readMeCode = "1112011000";

class CatalogGoodsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      leftSlideFlag: false,
      rightSlideFlag: false,
      showVideoButton: false,
      showImageButton: false,
      showImageFlag: true,
      showVideoFlag: false,
      noDGAsset: false,
      showReviewFlag: false,
      showTheItemFlag: true,

      picS3FileData: null,
      videoS3FileData: null,

      catalog: null,
      fileUploads: null,
      countImage: 0,

      onSale: false,
      onCoupon: false,
      // salesName: "",
      couponCode: "",
      salesPercent: 0,
      couponPercent: 0,
      discountType: "",

      numberOfSubItems: 0,
      subItem: null,
      imageFiles: [],
      currImageSeqno: 0,
      noSlideShowtest: true,
      subExists: false,
      subExistedSelected: false,
      leftSlideFlagSub: false,
      rightSlideFlagSub: false,
      showVarianceFlag: false,
      currSubItemSeqno: 1, // was 1/0 ... how to fix this????
      deliveryDuration: "open",

      qtyToBuy: 0,
      availableQty: 0,
      fixedNoOfDelivery: 2,
      onLoadPkgAvailability: 0,

      errMsg: "",
      errFlag: false,

      packAvailQty: 0,

      payShipType: "paynow",
      selectedScheduledItem: null,
      selheightad: 1,
      itemVariance: [],
      currSubItemImageSeqno: 1,

      thisItemWillHaveSubItems: false,
      selectClicked: false,
      onMountProcessDone: false,

      showDiscountPanelFlag: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let itemOnLoad = JSON.parse(
      JSON.stringify(this.props.sendCatalogItemDetails.anItem)
    );

    // Copy the catalog
    let temVar = [];
    let itemVariance = [];
    let currImageSeqno = 0;
    let currSubItemImageSeqno = 1;

    let thisItemWillHaveSubItems = false;
    // console.log(
    //   "itemOnLoad.willHaveItemVariance:",
    //   itemOnLoad.willHaveItemVariance
    // );
    // console.log("itemOnLoad.itemVariance:", itemOnLoad.itemVariance);
    if (itemOnLoad.willHaveItemVariance) {
      thisItemWillHaveSubItems = true;
    } else if (itemOnLoad.itemVariance.length > 1) {
      // Legacy items without 'willHaveItemVAriance
      // console.log("Came from legacy ...");
      thisItemWillHaveSubItems = true;
      await this.fixWillHaveItemVariance();
    }

    await this.setState({
      thisItemWillHaveSubItems,
    });

    // console.log("itemOnLoad:", itemOnLoad);
    let newseqno = 1;
    if (itemOnLoad.willHaveItemVariance) {
      itemOnLoad.itemVariance.forEach((iv) => {
        if (parseFloat(iv.seqno) > 1 && !iv.deleted) {
          iv.newseqno = newseqno;
          itemVariance.push(iv);
          temVar.push(iv);
          newseqno++;
        }
      });
    } else {
      itemOnLoad.itemVariance.forEach((iv) => {
        if (parseFloat(iv.seqno) > 0 && !iv.deleted) {
          iv.newseqno = newseqno;
          itemVariance.push(iv);
          temVar.push(iv);
          newseqno++;
        }
      });
    }

    // console.log("itemVariance:", itemVariance);
    // itemOnLoad.itemVariance = temVar;

    let item = itemOnLoad;

    let cartOf;
    if (this.props.cartOf) cartOf = this.props.cartOf;

    let picS3FileData = null;
    let videoS3FileData = null;
    let picExists = false;
    let countImage = 0;
    let showImageFlag = false;
    let showVideoFlag = false;
    let showImageButton = false;
    let rightSlideFlag = false;
    let showVideoButton = false;
    let noDGAsset = false;

    let imageFiles = [];

    // console.log(">> item:", item);

    item.fileUploads.forEach((obj) => {
      if (obj.type === "image" && !picExists) {
        picExists = true;
        picS3FileData = obj;
        showImageFlag = true;
        // showImageButton = true;
        currImageSeqno = 1;
      }
      if (obj.type === "image") {
        countImage++;
        imageFiles.push(obj);
      }
      if (obj.type === "video") {
        videoS3FileData = obj;
        showVideoFlag = true;
        showVideoButton = true;
      }
    });

    if (showImageFlag) showVideoFlag = false;
    if (countImage > 1) rightSlideFlag = true;
    if (countImage === 1) showImageButton = false;
    if (!showImageFlag && !showVideoFlag) noDGAsset = true;
    if (showVideoButton) showImageButton = false;

    let numberOfSubItems = item.itemVariance.length;

    let subItem = [];
    if (thisItemWillHaveSubItems) {
      subItem = itemVariance[0];
    } else {
      // This is to handle legacy items. If they had mot been defined as
      // havinf sub items at store item creation.
      subItem = itemOnLoad.itemVariance[0];
    }

    let subExists = false;
    let rightSlideFlagSub = false;
    let availableQty = 0;
    let onLoadPkgAvailability = 0;

    // console.log(">> itemOnLoad:", itemOnLoad);

    if (itemOnLoad.subscription.allowPeriodicDelivery) {
      if (!itemOnLoad.pkgComposedFlag) {
        availableQty =
          itemOnLoad.itemVariance[0].quantity -
          itemOnLoad.itemVariance[0].pkgQtyLocked;
        onLoadPkgAvailability = availableQty;
      } else {
        availableQty = item.itemVariance[0].quantity;
        onLoadPkgAvailability = availableQty;
      }
    } else {
      if (thisItemWillHaveSubItems) {
        if (itemVariance.length > 1) {
          rightSlideFlagSub = true;
        }
        subExists = true;
      } else {
        availableQty = item.itemVariance[0].quantity;
        onLoadPkgAvailability = availableQty;
      }
    }

    let selectedScheduledItem = null;
    if (
      itemOnLoad.subscription &&
      itemOnLoad.subscription.allowPeriodicDelivery
    ) {
      if (itemOnLoad.subscription.priodicDeliveryDetails.length === 1)
        selectedScheduledItem =
          itemOnLoad.subscription.priodicDeliveryDetails[0];
    }

    // console.log("196 availableQty:", availableQty);

    let sorted = await arraySortNum(imageFiles, "seqno", "dsc");

    let onSale = false,
      onCoupon = false;
    let salesPercent = 0,
      couponPercent = 0;
    let discountType = "sales";
    item.discounts.forEach((itm) => {
      if (
        moment().isSameOrAfter(moment(itm.startDate)) &&
        moment().isSameOrBefore(moment(itm.endDate))
      ) {
        if (itm.type === "sales") {
          onSale = true;
          salesPercent = itm.percentage;
        }
        if (itm.type === "coupons") {
          onCoupon = true;
          couponPercent = itm.percentage;
        }
      }
    });
    if (!onSale && !onCoupon) discountType = "";
    if (!onSale && onCoupon) discountType = "coupon";

    // console.log('2. subItem:', subItem);
    // console.log("itemOnLoad.pkgComposedFlag:", itemOnLoad.pkgComposedFlag);
    if (itemOnLoad.pkgComposedFlag) {
      let ret = await this.getPkgsPlanned();
      // console.log("ret:", ret);
      availableQty = ret.availableQty;
      onLoadPkgAvailability = ret.onLoadPkgAvailability;
    }

    // console.log("232 availableQty:", availableQty);
    let showDiscountPanelFlag = true;
    if (item && item.subscription && item.subscription.allowSubscription)
      showDiscountPanelFlag = false;

    this.setState({
      deviceSize,
      catalog: item,
      fileUploads: item.fileUploads,
      picS3FileData,
      videoS3FileData,
      showImageFlag,
      showVideoFlag,
      countImage,
      showImageButton,
      showVideoButton,
      rightSlideFlag,
      noDGAsset,
      picExists,
      numberOfSubItems,
      subItem,
      imageFiles: sorted,
      currImageSeqno,
      subExists,
      rightSlideFlagSub,
      availableQty,
      onLoadPkgAvailability,
      onSale,
      onCoupon,
      discountType,
      cartOf,
      salesPercent,
      couponPercent,
      selectedScheduledItem,
      itemVariance,
      currSubItemImageSeqno,
      onMountProcessDone: true,
      showDiscountPanelFlag,
    });

    if (document.getElementById("schSubscriptionItemToShip")) {
      document.getElementById("schSubscriptionItemToShip").value = "DEFAULT";
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  // This will fix the legacy catalog and items that
  // did not have willHaveItemVariance defined.
  // In future, this would be redundant.
  fixWillHaveItemVariance = async () => {
    // console.log("in fixWillHaveItemVariance");
    try {
      let input = {
        communityId: this.props.sendCatalogItemDetails.anItem.communityId,
        coopStoreId: this.props.sendCatalogItemDetails.anItem.coopStoreId,
        itemId: this.props.sendCatalogItemDetails.anItem.itemId,
      };
      let url = baandaServer + setWillHaveItemVariance;
      // console.log("url:", url, " input:", input);
      let ret = await axios.post(url, input);
      if (ret.data.status === "success") {
        this.setState({
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg:
            "Could not reset legacy willHaveItemVariance because " +
            ret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  onChangeNumber = async (e) => {
    let name = e.target.name;
    // console.log('name:', name);
    let value = e.target.value;

    let isValid = true;
    if (name === "fixedNoOfDelivery" && value < 2) isValid = false;
    if (isValid) {
      if (this.countDecimals(value) > 0) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          [e.target.name]: value,
          errMsg: "",
          errFlag: false,
        });
      }
    } else {
      this.setState({
        errMsg: "No of delivery must be 2 or more.",
        errFlag: true,
      });
    }
  };

  getPkgsPlanned = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let today = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let availableQty = 0;

    let communityId = this.props.sendCatalogItemDetails.anItem.communityId;

    let params =
      "communityId=" +
      communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&itemId=" +
      this.props.sendCatalogItemDetails.anItem.itemId +
      "&year=" +
      year +
      "&month=" +
      month;
    let url = baandaServer + getCatalogPkgForDelivery + params;
    // console.log("url:", url);
    try {
      let plnret = await axios.get(url);
      // console.log("plnret.data:", plnret.data);
      if (plnret.data.status === "success") {
        let planned = 0;
        if (plnret.data.Msg) {
          planned = plnret.data.Msg.batch.length;
        }

        let errMsg = "";
        let errFlag = false;
        let qty = 0;
        if (planned > 0) {
          plnret.data.Msg.batch.forEach((pk) => {
            let sold = 0;
            let locked = 0;
            if (pk.batchQtySubscribed) sold = pk.batchQtySubscribed; // new field. batchQtySubscribed
            if (pk.qtyLockedInCart) locked = pk.qtyLockedInCart;
            qty += pk.batchQty - (sold + locked);
          });
        }
        // console.log("qty:", qty);
        availableQty = qty;

        this.setState({
          errMsg,
          errFlag,
        });
      } else {
        this.setState({
          errMsg: plnret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    // availableQty =
    //   availableQty -
    //   this.props.sendCatalogItemDetails.anItem.pkgNoOfSubscriptionReqs;

    let ret = {
      availableQty,
      onLoadPkgAvailability: availableQty,
    };

    // console.log(">>> ret:", ret);

    return ret;
  };

  handleSlideImageRight = async () => {
    // console.log("handleSlideImageRight");
    let currImageSeqno = parseFloat(this.state.currImageSeqno) + 1;
    // let currSubItemImageSeqno = parseFloat(this.state.currSubItemImageSeqno) + 1;;   // FIX THIS ... @@@@@@@@@@@
    if (currImageSeqno > this.state.fileUploads.length)
      currImageSeqno = this.state.fileUploads.length;

    let picS3FileData;
    this.state.imageFiles.forEach((obj) => {
      if (obj.seqno === currImageSeqno) picS3FileData = obj;
    });

    let rightSlideFlag = true;
    let leftSlideFlag = false;
    if (currImageSeqno === this.state.imageFiles.length) {
      rightSlideFlag = false;
    }
    if (currImageSeqno > 1) leftSlideFlag = true;

    // console.log("right currImageSeqno:", currImageSeqno);
    this.setState({
      currImageSeqno,
      picS3FileData,
      rightSlideFlag,
      leftSlideFlag,
    });
  };

  handleSlideImageLeft = async () => {
    // console.log("handleSlideImageLeft");
    let currImageSeqno = parseFloat(this.state.currImageSeqno) - 1;
    if (currImageSeqno === 0) currImageSeqno = 1;
    let picS3FileData;
    this.state.imageFiles.forEach((obj) => {
      if (obj.seqno === currImageSeqno) picS3FileData = obj;
    });

    let rightSlideFlag = false;
    let leftSlideFlag = true;
    if (currImageSeqno < this.state.imageFiles.length) {
      rightSlideFlag = true;
    }
    if (currImageSeqno === 1) leftSlideFlag = false;
    // console.log("left currImageSeqno:", currImageSeqno);
    await this.setState({
      currImageSeqno,
      picS3FileData,
      rightSlideFlag,
      leftSlideFlag,
    });
  };

  handleShowVideoFlag = async () => {
    // console.log("handleShowVideoFlag");
    // Get the right object from fileUploads to picS3FileData

    let availableQty = this.state.availableQty;
    if (this.state.catalog.itemVariance.length > 0) availableQty = 0;

    let showImageButton = false;
    if (this.state.fileUploads.length > 0) showImageButton = true;

    await this.setState({
      leftSlideFlag: false,
      picExists: false,
      rightSlideFlag: false,
      showVideoButton: false,
      showVideoFlag: true,
      showImageflag: false,
      noSlideShowtest: false,
      showVarianceFlag: false,
      availableQty,
      showImageButton,
    });
  };

  handleShowImageFlag = async () => {
    let availableQty = this.state.availableQty;
    if (this.state.catalog.itemVariance.length > 0) availableQty = 0;

    let currImageSeqno = 0;
    let rightSlideFlag = false;
    let picS3FileData = null;
    let picExists = true;
    let showImageFlag = true;
    let noDGAsset = false;
    if (this.state.imageFiles.length > 0) {
      currImageSeqno = 1;
      picS3FileData = this.state.imageFiles[0];
      if (this.state.imageFiles.length > 1) rightSlideFlag = true;
    } else {
      picExists = false;
      showImageFlag = false;
    }

    let showVideoFlag = true;
    let showVideoButton = true;
    if (!this.state.videoS3FileData) {
      showVideoButton = false;
      showVideoFlag = false;
    }

    if (!showVideoFlag && !showImageFlag) noDGAsset = true;

    await this.setState({
      picS3FileData,
      currImageSeqno,
      leftSlideFlag: false,
      picExists,
      rightSlideFlag,
      showImageButton: false,
      showVideoButton,
      showVideoFlag: false,
      showImageflag: false,
      noDGAsset,
      noSlideShowtest: true,
      showVarianceFlag: false,
      availableQty,
    });
  };

  handleVarianceShow = async () => {
    // console.log(
    //   "this.state.currSubItemImageSeqno:",
    //   this.state.currSubItemImageSeqno
    // );

    let showImageButton = false;
    let showVideoButton = false;
    if (this.state.picS3FileData) showImageButton = true;
    if (this.state.videoS3FileData) showVideoButton = true;

    let rightSlideFlagSub = false;
    let availableQty = 0;
    // console.log(
    //   "this.state.itemVariance.length:",
    //   this.state.itemVariance.length
    // );

    if (this.state.itemVariance.length > 0) {
      rightSlideFlagSub = true;
      this.state.itemVariance.forEach((av) => {
        availableQty = parseFloat(av.quantity);
      });
    }

    let onLoadPkgAvailability = 0;
    if (this.state.subExists && this.state.subItem) {
      onLoadPkgAvailability = this.state.subItem.quantity;
    }

    await this.setState({
      showImageButton,
      showVideoButton,
      noSlideShowtest: false,
      leftSlideFlag: false,
      rightSlideFlag: false,
      rightSlideFlagSub,
      showVarianceFlag: true,
      availableQty,
      currSubItemSeqno: 1,
      subExistedSelected: true,
      onLoadPkgAvailability,
      selectClicked: true,
    });
  };

  handleSlideImageRightSub = async () => {
    let subExistedSelected = true;
    let onLoadPkgAvailability = 0;
    let subItem = null;
    this.state.itemVariance.forEach((sci) => {
      if (
        parseFloat(sci.newseqno) ===
        parseFloat(this.state.currSubItemImageSeqno) + 1
      ) {
        subItem = sci;
        onLoadPkgAvailability = parseFloat(subItem.quantity);
      }
    });

    if (subItem) {
      let currSubItemSeqno = parseFloat(this.state.currSubItemSeqno) + 1;
      let currSubItemImageSeqno =
        parseFloat(this.state.currSubItemImageSeqno) + 1;
      let rightSlideFlagSub = true;
      // console.log('currSubItemSeqno:', currSubItemSeqno, ' this.state.catalog.itemVariance.length:', this.state.catalog.itemVariance.length);

      if (currSubItemSeqno >= this.state.itemVariance.length) {
        rightSlideFlagSub = false;
      }
      let leftSlideFlagSub = false;
      if (currSubItemSeqno > 1) leftSlideFlagSub = true;
      let availableQty = subItem.quantity;
      // console.log("availableQty:", availableQty);
      // onLoadPkgAvailability = subItem.quantity;
      // console.log("onLoadPkgAvailability:", onLoadPkgAvailability);

      this.setState({
        showImageButton: this.state.showImageFlag,
        showVideoButton: this.state.showVideoFlag,
        noSlideShowtest: false,
        leftSlideFlag: false,
        rightSlideFlag: false,
        showVarianceFlag: true,
        rightSlideFlagSub,
        leftSlideFlagSub,
        picExists: false,
        subItem,
        currSubItemSeqno,
        currSubItemImageSeqno,
        availableQty,
        subExistedSelected,
        onLoadPkgAvailability,
        qtyToBuy: 0,
        errMsg: "",
        errFlag: false,
      });
    } else {
      this.setState({
        errMsg: "Request the keeper to set inventory properly for trading.",
      });
    }
  };

  handleSlideImageLeftSub = async () => {
    let subExistedSelected = true;
    let onLoadPkgAvailability = 0;
    let subItem = null;
    this.state.itemVariance.forEach((sci) => {
      if (
        parseFloat(sci.newseqno) ===
        parseFloat(this.state.currSubItemSeqno) - 1
      ) {
        subItem = sci;
        onLoadPkgAvailability = subItem.quantity;
      }
    });

    let currSubItemSeqno = parseFloat(this.state.currSubItemSeqno) - 1;
    let currSubItemImageSeqno =
      parseFloat(this.state.currSubItemImageSeqno) - 1;

    let rightSlideFlagSub = false;
    if (currSubItemSeqno < this.state.itemVariance.length) {
      rightSlideFlagSub = true;
    }
    let leftSlideFlagSub = false;
    if (currSubItemSeqno > 1) leftSlideFlagSub = true;
    let availableQty = subItem.quantity;

    await this.setState({
      showImageButton: this.state.showImageFlag,
      showVideoButton: this.state.showVideoFlag,
      noSlideShowtest: false,
      leftSlideFlag: false,
      rightSlideFlag: false,
      showVarianceFlag: true,
      rightSlideFlagSub,
      leftSlideFlagSub,
      picExists: false,
      subItem,
      currSubItemSeqno,
      currSubItemImageSeqno,
      availableQty,
      subExistedSelected,
      onLoadPkgAvailability,
      qtyToBuy: 0,
      errMsg: "",
      errFlag: false,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  onItemToBuy = async (e) => {
    let value = e.target.value;
    // console.log("value:", value, " availableQty:", this.state.availableQty);
    if (value >= 0) {
      let regular = true;
      if (this.state.catalog.subscription.allowPeriodicDelivery) {
        regular = false;
      }
      // console.log("regular:", regular);
      if (regular) {
        // if (value > this.state.availableQty) {
        if (value > this.state.onLoadPkgAvailability) {
          this.setState({
            errMsg: "Quantity cannot exceed the inventory.",
            errFlag: true,
          });
        } else {
          // && value <= this.state.onLoadPkgAvailability
          let seqno = this.state.subItem.seqno;
          // console.log("seqno:", seqno);
          let subItem = null;
          this.state.catalog.itemVariance.forEach((obj) => {
            if (obj.seqno === seqno) {
              subItem = JSON.parse(JSON.stringify(obj));
            }
          });
          // console.log("subItem:", subItem);
          let qty = subItem.quantity - value;
          subItem.quantity = qty;
          if (this.state.catalog.canSellFraction) {
            if (this.countDecimals(value) > 2) {
              let tmp = parseFloat(value).toFixed(2);
              let val = parseFloat(tmp);
              this.setState({
                [e.target.name]: val,
                errMsg: "",
                errFlag: false,
                subItem,
                availableQty: qty,
              });
            } else {
              this.setState({
                [e.target.name]: value,
                errMsg: "",
                errFlag: false,
                subItem,
                availableQty: qty,
              });
            }
          } else {
            if (this.countDecimals(value) === 0) {
              this.setState({
                [e.target.name]: value,
                errMsg: "",
                errFlag: false,
                subItem,
                availableQty: qty,
              });
            }
          }
        }
      } else {
        if (!this.state.catalog.pkgComposedFlag) {
          // console.log("In here 692");
          let avlQty =
            this.state.catalog.itemVariance[0].quantity -
            this.state.catalog.itemVariance[0].pkgQtyLocked;
          if (this.state.selectedScheduledItem) {
            avlQty =
              avlQty - value * this.state.selectedScheduledItem.scheduleQty;
          }
          if (this.countDecimals(value) === 0) {
            if (avlQty >= 0) {
              this.setState({
                [e.target.name]: value,
                errMsg: "",
                errFlag: false,
                // subItem,
                availableQty: avlQty,
              });
            } else {
              this.setState({
                errMsg: "Quantity cannot exceed the inventory.",
                errFlag: true,
              });
            }
          }
        } else {
          let avlQty = this.state.onLoadPkgAvailability - value;
          // console.log("avlQty:", avlQty);
          if (this.countDecimals(value) === 0) {
            if (avlQty >= 0) {
              this.setState({
                [e.target.name]: value,
                errMsg: "",
                errFlag: false,
                availableQty: avlQty,
              });
            } else {
              this.setState({
                errMsg: "Quantity cannot exceed the inventory.",
                errFlag: true,
              });
            }
          }
        }
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  returnToPagi = () => {
    this.props.returnToDetails();
  };

  handleInTheCartSave = async () => {
    // console.log("in handleInTheCartSave");
    let coopStoreId = this.props.sendCatalogItemDetails.anItem.coopStoreId;

    if (
      this.props.sendCatalogItemDetails.anItem.subscription &&
      this.props.sendCatalogItemDetails.anItem.subscription.allowSubscription
    ) {
      let params =
        "baandaId=" +
        this.props.auth.user.baandaId +
        "&communityId=" +
        this.props.community.communityId +
        "&coopStoreId=" +
        coopStoreId +
        "&itemId=" +
        this.props.sendCatalogItemDetails.anItem.itemId;
      let url = baandaServer + doesCartHaveSubscriptionItem + params;
      try {
        let resp = await axios.get(url);
        // console.log("resp.data:", resp.data);
        if (resp.data.status === "success") {
          if (
            resp.data.Msg.hasSub === "no" &&
            resp.data.Msg.hasTheSameCustomService === "no"
          ) {
            await this.putItInTheCart();
          } else {
            let msg = "";
            let errFlag = false;
            if (resp.data.Msg.hasSub === "yes") {
              msg =
                "A cart cannot have multiple subscription item. Buy it separately.";
              errFlag = true;
            }
            if (resp.data.Msg.hasTheSameCustomService === "yes") {
              msg = msg + "The cart already has this custom service.";
              errFlag = true;
            }
            this.setState({
              errMsg: msg,
              errFlag,
            });
          }
        } else {
          this.setState({
            errMsg: resp.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handleInTheCartSave err: ", err.message);
        this.setState({
          errMsg: err.message,
        });
      }
    } else {
      await this.putItInTheCart();
    }
  };

  putItInTheCart = async () => {
    let discount = [];
    // console.log("putItInTheCart 1");
    this.state.catalog.discounts.forEach((obj) => {
      if (
        moment().isSameOrBefore(moment(obj.endDate)) &&
        moment().isSameOrAfter(moment(obj.startDate))
      ) {
        // console.log("in discount ...");
        let discobj = {
          inDb: true,
          type: obj.type,
          name: obj.name,
          startDate: moment(obj.startDate),
          endDate: moment(obj.endDate),
          percentage: obj.percentage,
          code: obj.code,
        };
        discount.push(discobj);
      }
    });

    let discountPercent = 0;
    if (this.state.discountType !== "") {
      if (this.state.discountType === "sales")
        discountPercent = this.state.salesPercent;
      if (this.state.discountType === "coupons")
        discountPercent = this.state.couponPercent;
    }

    let adjQtyForBoxFlag = false;
    if (
      this.state.catalog &&
      this.state.catalog.subscription.allowPeriodicDelivery &&
      !this.state.catalog.pkgComposedFlag
    ) {
      adjQtyForBoxFlag = true;
    }

    let subItems = [];
    if (this.props.sendCatalogItemDetails.anItem.willHaveItemVariance) {
      let newseqno = this.state.subItem.newseqno;
      this.state.itemVariance.forEach((obj) => {
        if (obj.newseqno === newseqno) {
          let sItemObj = JSON.parse(JSON.stringify(this.state.subItem));
          if (adjQtyForBoxFlag) {
            sItemObj.quantity =
              obj.quantity -
              parseFloat(this.state.qtyToBuy) *
                parseFloat(this.state.selectedScheduledItem.scheduleQty);
          } else {
            sItemObj.quantity = obj.quantity - parseFloat(this.state.qtyToBuy);
          }

          subItems.push(sItemObj);
        } else subItems.push(obj);
      });
    } else {
      console.log("This is when no subitem ... ");
      let sItemObj = JSON.parse(JSON.stringify(this.state.subItem));
      subItems.push(sItemObj);
    }

    let sItem = JSON.parse(JSON.stringify(this.state.subItem));
    if (adjQtyForBoxFlag) {
      sItem.quantity =
        parseFloat(this.state.qtyToBuy) *
        parseFloat(this.state.selectedScheduledItem.scheduleQty);
    } else {
      sItem.quantity = parseFloat(this.state.qtyToBuy);
    }
    // sItem.quantity = parseFloat(this.state.qtyToBuy);

    let subscription = null;

    subscription = this.props.sendCatalogItemDetails.anItem.subscription;

    let quantity = parseFloat(this.state.qtyToBuy);
    let itemName = "";
    let boxedPrice = 0;
    let periodicShipData = null;
    if (this.state.catalog.subscription.allowSubscription) {
      if (this.state.catalog.subscription.allowPeriodicDelivery) {
        if (this.state.catalog.pkgComposedFlag) {
          // to do
          // console.log("here 1");
          itemName = this.state.catalog.itemName;

          let today = new Date();
          let month = today.getMonth() + 1;
          let year = today.getFullYear();

          periodicShipData = {
            enabled: true,
            type: "planned",
            plannedPack: {
              year,
              month,
              plannedRef: [],
            },
            boxedPack: null,
            boxedPrice: 0,
            deliveryDuration: this.state.deliveryDuration,
            numberOfDelivery: parseFloat(this.state.fixedNoOfDelivery),
            pkgComposedShippingCost: parseFloat(
              this.state.catalog.pkgComposedShippingCost
            ),
            plannedPkgDimension: this.state.catalog.packageDimension,
          };
        } else {
          itemName =
            this.state.catalog.itemName +
            " (" +
            this.state.selectedScheduledItem.friendlyName +
            " | Qt:" +
            this.state.selectedScheduledItem.scheduleQty +
            "; Dc:" +
            this.state.selectedScheduledItem.schDiscount +
            ")";
          boxedPrice =
            (this.state.catalog.itemPrice *
              this.state.selectedScheduledItem.scheduleQty *
              parseFloat(this.state.qtyToBuy) *
              (1 - this.state.selectedScheduledItem.schDiscount / 100) +
              this.state.selectedScheduledItem.shipPrice) *
            parseFloat(this.state.qtyToBuy);
          periodicShipData = {
            enabled: true,
            type: "boxed",
            plannedPack: {
              year: 0,
              month: 0,
              plannedRef: [],
            },
            boxedPack: this.state.selectedScheduledItem,
            boxedPrice,
            deliveryDuration: this.state.deliveryDuration,
            numberOfDelivery: parseFloat(this.state.fixedNoOfDelivery),
            pkgComposedShippingCost: parseFloat(
              this.state.catalog.pkgComposedShippingCost
            ),
            plannedPkgDimension: this.state.catalog.packageDimension,
          };
        }
      } else {
        itemName =
          this.state.catalog.itemName +
          " (" +
          this.state.subItem.name +
          " - " +
          this.state.subItem.options +
          ")";
      }
    } else {
      itemName =
        this.state.catalog.itemName +
        " (" +
        this.state.subItem.name +
        " - " +
        this.state.subItem.options +
        ")";
    }

    let specialShipping = this.state.catalog.specialShipping;
    if (periodicShipData) specialShipping = "periodic";

    let imgs;
    if (this.state.catalog.fileUploads.length > 0)
      imgs = this.state.catalog.fileUploads[0].location;
    else imgs = noItemImage;

    let isValid = true;
    // console.log("isValid:", isValid);
    let coopStoreId = 0;
    if (this.props.sendCatalogItemDetails.anItem.coopStoreId)
      coopStoreId = this.props.sendCatalogItemDetails.anItem.coopStoreId;
    if (isValid) {
      try {
        let url = baandaServer + saveItemInCart;
        let cartOfA = "baanda";
        let baandaId = this.props.cartOf.baandaId;
        // if (this.props.auth.user.email === guestLoginId) {
        //   cartOfA = "guest";
        //   baandaId = Number(this.props.guestCartId);
        // }
        let data = {
          cartOfA,
          baandaId,
          customerName: this.props.cartOf.name,
          customerEmail: this.props.cartOf.email,
          communityId: this.props.community.communityId,
          coopStoreId,
          itemId: this.state.catalog.itemId,
          itemName,
          itemImage: imgs,
          price: this.state.catalog.itemPrice,
          quantity,
          subItem: sItem,
          subItems,
          discountEnabled: this.state.catalog.discountEnabled,
          discount,
          discountType: this.state.discountType,
          couponCode: this.state.couponCode,
          discountPercent,
          taxInfo: this.state.catalog.taxInfo,
          itemType: this.state.catalog.itemType,
          unitType: this.state.catalog.unitName,
          taxable: this.state.catalog.taxable,
          cartOfType: this.props.caller,
          clientProgram: "CatalogGoodsDetails",
          clientFunction: "handleInTheCartSave",
          shipping: this.state.catalog.shipping,
          pickupAllowed: this.state.catalog.pickupAllowed,
          subscription,
          pickupLocations: this.state.catalog.pickupLocations,
          specialShipping,
          dimensions: this.props.sendCatalogItemDetails.anItem.packageDimension,
          periodicShipData,
          pkgComposedFlag: this.state.catalog.pkgComposedFlag,
          willHaveItemVariance:
            this.props.sendCatalogItemDetails.anItem.willHaveItemVariance,
        };
     
        let ret = await axios.post(url, data);
        let errMsg = "";
        let errFlag = false;
        let catalog = this.state.catalog;
        let qtyToBuy = this.state.qtyToBuy;
        let couponCode = this.state.couponCode;
        if (ret.data.status === "success") {
          errMsg = "Carted. Click Back to shop/checkout.";
          errFlag = false;
          catalog = ret.data.Msg;
          qtyToBuy = 0;
          couponCode = "";
        } else {
          errMsg = ret.data.Msg;
          errFlag = true;
        }

        await this.setState({
          errMsg,
          errFlag,
          catalog,
          qtyToBuy,
          couponCode,
        });
      } catch (err) {
        console.log("handleInTheCartSave error:", err.message);
        this.setState({
          errMsg: err.message,
          errFlag: false,
        });
      }
    }
  };

  handleReviews = async () => {
    this.setState({
      showReviewFlag: true,
      showTheItemFlag: false,
    });
  };

  returnFromReview = async () => {
    this.setState({
      showReviewFlag: false,
      showTheItemFlag: true,
    });
  };

  handleDiscountType = async (e) => {
    // let value = e.target.value;
    this.setState({
      discountType: e.target.value,
    });
  };

  handledeliveryDuration = async (e) => {
    // let value = e.target.value;
    this.setState({
      deliveryDuration: e.target.value,
    });
  };

  handlepayShipType = async (e) => {
    this.setState({
      payShipType: e.target.value,
    });
  };

  handleSchItemSelect = async () => {
    let value = document.getElementById("schSubscriptionItemToShip").value;
    // console.log("handleSchItemSelect value:", value);
    let data = JSON.parse(value);
    // console.log("valobj:", data);
    // let fromAddress = data.address;
    this.setState({
      selectedScheduledItem: data,
      selheightad: 1,
    });
  };

  onfocusa = async () => {
    let len = 5;
    if (this.state.catalog.subscription.priodicDeliveryDetails.length < 5)
      len = this.state.catalog.subscription.priodicDeliveryDetails.length;
    // console.log("len:", len);
    this.setState({
      selheightad: len + 1,
    });
  };

  onblura = async () => {
    this.setState({
      selheightad: 1,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CatalogGoodsDetails...");

    let isSaleOn = false;
    let discountOn = false;
    let plainSalesOn = false;
    let couponCode = "";
    let couponper = 0;
    let salesper = 0;
    if (this.state.catalog && this.state.catalog.discountEnabled) {
      this.state.catalog.discounts.forEach((obj) => {
        let startDate = new Date(obj.startDate);
        let endDate = new Date(obj.endDate);
        let today = new Date();
        // if (
        //   moment().isSameOrAfter(moment(obj.startDate)) &&
        //   moment().isSameOrBefore(obj.endDate)
        // )
        if (startDate <= today && endDate >= today) {
          isSaleOn = true;
          if (obj.type === "coupons") {
            couponCode = obj.code;
            discountOn = true;
            couponper = obj.percentage;
          }
          if (obj.type === "sales") {
            plainSalesOn = true;
            salesper = obj.percentage;
          }
        }
      });
    }

    let selectDiscountPanel;
    if (this.state.showDiscountPanelFlag) {
      if (this.state.onCoupon || this.state.onSale) {
        selectDiscountPanel = (
          <div>
            <div className="row name-panel-row">
              <div className="col text-center radio-font-style">
                <strong>Discount Type&nbsp;&nbsp;</strong>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="sales"
                      checked={this.state.discountType === "sales"}
                      onChange={this.handleDiscountType}
                    />{" "}
                    Sales
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="coupons"
                      checked={this.state.discountType === "coupons"}
                      onChange={this.handleDiscountType}
                    />{" "}
                    Coupon
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    let salesPanel;
    if (this.state.showDiscountPanelFlag) {
      if (isSaleOn) {
 
        salesPanel = (
          <div>
            {selectDiscountPanel}
            <div className="row">
              <div className="col-3 text-right cat-show-lbl">Sales</div>
              <div className="col-9 text-left cat-show-value">
                {plainSalesOn ? salesper + "%;  " : null}
                {discountOn ? "Discount with coupon " + couponper + "%" : null}
              </div>
            </div>
            {discountOn && this.state.discountType === "coupons" ? (
              <div className="row">
                <div className="col-3 text-right cat-show-lbl">Coupon</div>
                <div className="col-9 text-left cat-show-value">
                  <input
                    name="couponCode"
                    type="text"
                    value={this.state.couponCode}
                    onChange={this.onChange}
                    size="40"
                    maxLength="40"
                    className="cat-coupon-code"
                    placeholder="Code "
                  />{" "}
                  &nbsp;&nbsp;
                  {this.state.couponCode === couponCode
                    ? "Approved"
                    : "Enter a valid code"}
                </div>
              </div>
            ) : null}
          </div>
        );
      }
    }

    let noImgItemSelPanel;
    noImgItemSelPanel = (
      <div>
        <img
          src={noImageAvailable}
          alt="pic"
          className="catalog-img-sel-catview"
        />
      </div>
    );

    let showSubNoImagePanel;
    showSubNoImagePanel = (
      <div>
        <img src={showNoImage} alt="pic" className="catalog-sub-no-image" />
        <div className="text-center sub-no-image-msg">
          No picture to display
        </div>
      </div>
    );

    let imagePanel;
    if (this.state.picS3FileData) {
      imagePanel = (
        <div>
          <img
            src={this.state.picS3FileData.location}
            alt="pic"
            className="cat-show-image-view"
          />
        </div>
      );
    }

    let videoPanel;
    if (this.state.videoS3FileData) {
      videoPanel = (
        <div>
          <video className="catshow-video-card" controls>
            <source
              src={this.state.videoS3FileData.location}
              type={this.state.videoFileType}
            />
          </video>
        </div>
      );
    }

    let image, video;
    if (this.state.deviceSize === "big") {
      image = "Image";
      video = "Video";
    } else {
      image = "Img";
      video = "Vdo";
    }

    let digitalButtons;
    digitalButtons = (
      <div>
        {this.state.leftSlideFlag ? (
          <button
            onClick={this.handleSlideImageLeft}
            className="btn_reg_40"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fa fa-chevron-left" />
          </button>
        ) : this.state.noSlideShowtest ? (
          <button className="btn_reg_40_disabled">
            <i className="fa fa-chevron-left" />
          </button>
        ) : null}

        {this.state.rightSlideFlag ? (
          <button
            onClick={this.handleSlideImageRight}
            // className="btn-image-catshow-slide"
            className="btn_reg_40"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fa fa-chevron-right" />
          </button>
        ) : this.state.noSlideShowtest ? (
          <button className="btn_reg_40_disabled">
            <i className="fa fa-chevron-right" />
          </button>
        ) : null}

        {this.state.showVideoButton ? (
          <button
            onClick={this.handleShowVideoFlag}
            // className="btn-dgasset-showcat"
            className={
              this.state.deviceSize === "big" ? "btn_reg_60" : "btn_reg_75"
            }
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              video
            ) : (
              <i className="fas fa-video" />
            )}
          </button>
        ) : null}

        {this.state.showImageButton ? (
          <button
            onClick={this.handleShowImageFlag}
            className={
              this.state.deviceSize === "big" ? "btn_reg_60" : "btn_reg_75"
            }
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              image
            ) : (
              <i className="fas fa-image" />
            )}
          </button>
        ) : null}
      </div>
    );

    let cartit;
    if (this.state.deviceSize === "big") {
      cartit = "Cart-It";
    } else {
      cartit = "Crt";
    }

    let cartItButton;
    if (this.state.catalog) {
      if (this.state.catalog.isPublished) {
        cartItButton = (
          <button
            onClick={this.handleInTheCartSave}
            className={
              this.state.deviceSize === "big" ? "btn_reg_80" : "btn_reg_80"
            }
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {this.state.deviceSize === "big" ? (
              cartit
            ) : (
              <i className="fas fa-shopping-cart" />
            )}
          </button>
        );
      } else {
        cartItButton = <button className="btn_reg_70_disabled">Cart-It</button>;
      }
    }

    let catShowOpsButtons;
    if (
      this.state.catalog &&
      this.state.catalog.subscription.allowPeriodicDelivery &&
      !this.state.catalog.pkgComposedFlag
    ) {
      catShowOpsButtons = (
        <div>
          {this.state.qtyToBuy > 0 && this.state.selectedScheduledItem
            ? cartItButton
            : null}
          <button
            onClick={this.returnToPagi}
            className="btn_back_main"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fas fa-step-backward" />
          </button>
          <button
            onClick={this.openAlertModal}
            className="btn_info_main"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fas fa-info-circle" />
          </button>
        </div>
      );
    } else {
      catShowOpsButtons = (
        <div>
          {this.state.qtyToBuy > 0 ? cartItButton : null}
          <button
            onClick={this.returnToPagi}
            className="btn_back_main"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fas fa-step-backward" />
          </button>
          <button
            onClick={this.openAlertModal}
            className="btn_info_main"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            <i className="fas fa-info-circle" />
          </button>
        </div>
      );
    }

    let digitalAssetPanel;
    if (this.state.showImageFlag) {
      digitalAssetPanel = (
        <div>
          {this.state.picS3FileData ? imagePanel : noImgItemSelPanel}
          <div className="text-center cat-sel-dabtn-pos">{digitalButtons}</div>
        </div>
      );
    }
    if (this.state.showVideoFlag) {
      digitalAssetPanel = (
        <div>
          {this.state.videoS3FileData ? videoPanel : "No Video Uploaded"}
          <div className="text-center cat-sel-dabtn-pos">{digitalButtons}</div>
        </div>
      );
    }

    if (this.state.noDGAsset) {
      digitalAssetPanel = <div>{noImgItemSelPanel}</div>;
    }

    // SubItem / variance of items display.

    if (this.state.showVarianceFlag) {
      let subDispPanel = (
        <div className="text-center">
          {this.state.subItem.image ? (
            <div className="text-center">
              {this.state.subItem.image.location === "" ? (
                showSubNoImagePanel
              ) : (
                <img
                  src={this.state.subItem.image.location}
                  alt="pic"
                  className="cat-sub-image-view"
                />
              )}
            </div>
          ) : (
            showSubNoImagePanel
          )}

          <div className="row sub-item-row-top">
            <div className="col-3 text-right sub-item-lbl">Option</div>
            <div className="col-9 text-left sub-item-value">
              {this.state.subItem.options}
            </div>
          </div>
          <div className="row sub-item-row">
            <div className="col-3 text-right sub-item-lbl">Stock</div>
            <div className="col-9 text-left sub-item-value">
              {this.state.subItem.quantity}
            </div>
          </div>
        </div>
      );
      digitalAssetPanel = (
        <div>
          <div>
            {/* <div className="variance-disp-box"> */}
            <div className="text-center sub-item-name-x">
              {this.state.subItem.name}
            </div>
            {subDispPanel}
          </div>
          {digitalButtons}
        </div>
      );
    }
    //==============================================

    let descPanel;
    descPanel = (
      <div className="text-center">
        {this.state.catalog ? (
          <p align="justify" className="fixedsize-catshow-desc">
            {this.state.catalog.itemDescription}
          </p>
        ) : null}
      </div>
    );

    let available;
    if (this.state.deviceSize === "big") {
      available = "Available Qty";
    } else {
      available = "Avalble";
    }

    let subItemPanel;
    // if (!this.props.sendCatalogItemDetails.anItem.willHaveItemVariance && !this.state.thisItemWillHaveSubItems) {
    if (this.state.onMountProcessDone) {
      if (!this.state.thisItemWillHaveSubItems) {
        if (this.state.subItem) {
          subItemPanel = (
            <div className="">
              <div className="row">
                <div className="col-3 text-right cat-show-lbl">{available}</div>
                <div className="col-9 text-left cat-show-value">
                  {this.state.subItem.quantity}{" "}
                </div>
              </div>
            </div>
          );
        }
      } else {
        subItemPanel = (
          <div className="">
            <div className="row">
              <div className="col-3 text-right cat-show-variance-lbl">
                Types
              </div>
              <div className="col-9 text-left cat-show-value">
                {this.state.leftSlideFlagSub ? (
                  <button
                    onClick={this.handleSlideImageLeftSub}
                    className="btn_reg_40"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </button>
                ) : (
                  <button className="btn_reg_40_disabled">
                    <i className="fa fa-chevron-left" />
                  </button>
                )}
                {this.state.subExists && !this.state.selectClicked ? (
                  <button
                    onClick={this.handleVarianceShow}
                    // className="btn-sub-exists"
                    className="btn_reg_70"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    {this.state.deviceSize === "big" ? (
                      "Select"
                    ) : (
                      <i className="fas fa-hand-pointer" />
                    )}
                  </button>
                ) : (
                  <button
                    // onClick={this.handleVarianceShow}
                    // className="btn-sub-exists"
                    className="btn_reg_70_disabled"
                    // style={{
                    //   cursor: this.state.disabled ? "default" : "pointer",
                    // }}
                  >
                    Select
                  </button>
                )}
                {this.state.rightSlideFlagSub && this.state.selectClicked ? (
                  <button
                    onClick={this.handleSlideImageRightSub}
                    className="btn_reg_40"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </button>
                ) : (
                  <button className="btn_reg_40_disabled">
                    <i className="fa fa-chevron-right" />
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      }
    }

    let qtyToBuy, aqtbm;
    if (this.state.deviceSize === "small") {
      qtyToBuy = "Qty";
      aqtbm = " to Buy";
    } else {
      qtyToBuy = "Qty to Buy";
      aqtbm = "";
    }

    let showQtyPanelFlag = false;

    let getQuantityPanel;
    if (
      (!this.state.subExists || this.state.subExistedSelected) &&
      this.state.catalog &&
      this.state.catalog.subscription &&
      !this.state.catalog.subscription.allowPeriodicDelivery
    ) {
      showQtyPanelFlag = true;
    }

    if (
      this.state.catalog &&
      this.state.catalog.subscription.allowPeriodicDelivery
    ) {
      if (!this.state.catalog.pkgComposedFlag) {
        if (this.state.selectedScheduledItem) {
          showQtyPanelFlag = true;
        } else {
          showQtyPanelFlag = true;
        }
      } else {
        showQtyPanelFlag = true;
      }
    }

    if (showQtyPanelFlag) {
      getQuantityPanel = (
        <div>
          <div className="row cat-buy-row-pos">
            <div className="col-3 text-right sub-item-lbl">{qtyToBuy} </div>
            <div className="col-9 text-left sub-item-lbl">
              <input
                name="qtyToBuy"
                type="number"
                value={this.state.qtyToBuy}
                onChange={this.onItemToBuy}
                className="cat-item-qty-tobuy"
                step={
                  this.state.catalog && this.state.catalog.canSellFraction
                    ? ".01"
                    : "1"
                }
              />
              &nbsp;&nbsp;{aqtbm}
              {this.state.catalog &&
              this.state.catalog.subscription.allowSubscription &&
              this.state.catalog.subscription.allowPeriodicDelivery &&
              !this.state.catalog.pkgComposedFlag &&
              this.state.selectedScheduledItem
                ? this.state.selectedScheduledItem.scheduleQty + " in this pack"
                : ""}
            </div>
          </div>
          {this.state.catalog && this.state.catalog.pkgComposedFlag ? (
            <div className="text-center pkgcomp-shiping-cost">
              Shipping cost is ${" "}
              {this.commaFormattedCurrency(
                this.state.catalog.pkgComposedShippingCost
              )}{" "}
              per month
            </div>
          ) : null}
        </div>
      );
    }

    let showErrPanel;
    if (this.state.errMsg !== "") {
      showErrPanel = (
        <div
          className={
            this.state.errFlag
              ? "text-center cat-show-msg-err"
              : "text-center cat-show-msg"
          }
        >
          {this.state.errMsg}
        </div>
      );
    }

    let paynow, installment; //, subdelivery;
    if (this.state.deviceSize === "small") {
      paynow = "Pay";
      installment = "Instlmnt";
      // subdelivery = "Sub-Ship";
    } else {
      paynow = "Pay Now";
      installment = "Installment";
      // subdelivery = "Subscription Delivery";
    }

    let saleTypePanel;
    if (
      this.state.catalog &&
      this.state.catalog.subscription &&
      this.state.catalog.subscription.allowSubscription &&
      this.state.catalog.subscription.deliveryFrequency === "onetime" &&
      !this.state.catalog.pkgComposedFlag
    ) {
      saleTypePanel = (
        <div>
          <div className="row name-panel-row">
            <div className="col-3 text-right radio-font-style">
              <strong>Type&nbsp;&nbsp;</strong>
            </div>
            <div className="col-9 text-left ">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="paynow"
                    checked={this.state.payShipType === "paynow"}
                    onChange={this.handlepayShipType}
                  />{" "}
                  {paynow}xx
                </label>
              </div>

              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="installment"
                    checked={this.state.payShipType === "installment"}
                    onChange={this.handlepayShipType}
                  />{" "}
                  {installment}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let pkgAvailabilityPanel;
    if (
      this.state.catalog &&
      this.state.catalog.subscription &&
      this.state.catalog.subscription.allowPeriodicDelivery &&
      this.state.catalog.pkgComposedFlag
    ) {
      pkgAvailabilityPanel = (
        <div>
          <div className="">
            <div className="row">
              <div className="col-3 text-right cat-show-lbl">{available}</div>
              <div className="col-9 text-left cat-show-value">
                {this.state.availableQty}{" "}
              </div>
            </div>
          </div>
        </div>
      );
    }

    let disc, includesShip;
    if (this.state.deviceSize === "small") {
      disc = "Dc";
      includesShip = "Inc. Ship";
    } else {
      disc = "Dc";
      includesShip = "Includes Ship";
    }
    let periodicDropDown;
    let itemList;
    if (
      this.state.catalog &&
      this.state.catalog.subscription &&
      this.state.catalog.subscription.allowSubscription &&
      this.state.catalog.subscription.priodicDeliveryDetails.length > 1 &&
      !this.state.catalog.pkgComposedFlag
    ) {
      itemList = this.state.catalog.subscription.priodicDeliveryDetails.map(
        (obj, i) => {
          return (
            <option key={i} value={JSON.stringify(obj)}>
              {obj.friendlyName}&nbsp;({disc}: {obj.schDiscount}% Qt:
              {obj.scheduleQty})
            </option>
          );
        }
      );
    }

    if (
      this.state.catalog &&
      this.state.catalog.subscription &&
      this.state.catalog.subscription.allowSubscription &&
      this.state.catalog.subscription.deliveryFrequency === "periodic" &&
      this.state.catalog.subscription.priodicDeliveryDetails.length > 1 &&
      !this.state.catalog.pkgComposedFlag
    ) {
      periodicDropDown = (
        <div className="ship-addr-from-pos">
          <div className="row ship-addr-dropdown">
            <div className="col text-center ">
              <select
                size={this.state.selheightad}
                id="schSubscriptionItemToShip"
                name="schSubscriptionItemToShip"
                onChange={this.handleSchItemSelect}
                className="sch-subsciption-tem-dd"
                onFocus={this.onfocusa}
                onBlur={this.onblura}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select an Item
                </option>
                {itemList}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let subDeliveryDurationPanel;
    if (
      this.state.catalog &&
      this.state.catalog.subscription &&
      this.state.catalog.subscription.deliveryFrequency === "periodic"
    ) {
      if (this.state.deviceSize === "big") {
        subDeliveryDurationPanel = (
          <div className="cat-goods-pos">
            <div className="row">
              <div className="col-md-6 cat-show-lbl">
                Period Type&nbsp;&nbsp;
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="open"
                      checked={this.state.deliveryDuration === "open"}
                      onChange={this.handledeliveryDuration}
                    />{" "}
                    Open
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="fixed"
                      checked={this.state.deliveryDuration === "fixed"}
                      onChange={this.handledeliveryDuration}
                    />{" "}
                    Fixed end
                  </label>
                </div>
              </div>
              {this.state.deliveryDuration === "fixed" ? (
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-4 text-right cat-show-lbl">
                      No of delivery
                    </div>
                    <div className="col-8 text-left cat-show-value">
                      <input
                        name="fixedNoOfDelivery"
                        type="number"
                        value={this.state.fixedNoOfDelivery}
                        onChange={this.onChangeNumber}
                        className="cat-fixed-no-of-del"
                        min={2}
                        step="1"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-6">&nbsp;</div>
              )}
            </div>
          </div>
        );
      } else {
        subDeliveryDurationPanel = (
          <div className="cat-goods-pos">
            <div className="row">
              <div className="col-3 text-right cat-show-lbl">Periods</div>
              <div className="col-9 text-left">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="open"
                      checked={this.state.deliveryDuration === "open"}
                      onChange={this.handledeliveryDuration}
                    />{" "}
                    Open
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="fixed"
                      checked={this.state.deliveryDuration === "fixed"}
                      onChange={this.handledeliveryDuration}
                    />{" "}
                    Fixed end
                  </label>
                </div>
              </div>
            </div>

            {this.state.deliveryDuration === "fixed" ? (
              <div className="row">
                <div className="col-3 text-right cat-show-lbl">No of</div>
                <div className="col-9 text-left cat-show-value">
                  <input
                    name="fixedNoOfDelivery"
                    type="number"
                    value={this.state.fixedNoOfDelivery}
                    onChange={this.onChangeNumber}
                    className="cat-fixed-no-of-del"
                    min={2}
                    step="1"
                  />{" "}
                  &nbsp;deliveries.
                </div>
              </div>
            ) : null}
          </div>
        );
      }
    }

    let periodicOptionPanel;
    if (
      this.state.catalog &&
      this.state.catalog.subscription &&
      this.state.catalog.subscription.deliveryFrequency === "periodic"
    ) {
      if (this.state.deviceSize === "big") {
        if (!this.state.catalog.pkgComposedFlag) {
          let pdd = [...this.state.catalog.subscription.priodicDeliveryDetails];
          if (pdd.length === 1) {
            periodicOptionPanel = (
              <div>
                <div className="row cat-packs-avail-pos">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4 text-right cat-show-lbl">
                        Available
                      </div>
                      <div className="col-8 text-left cat-show-value">
                        {this.state.availableQty}
                        &nbsp;{" "}
                        <font size="1">Sell qty based on selection below</font>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col text-right cat-show-lbl">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4 text-right cat-show-lbl">Item</div>
                      <div className="col-8 text-left cat-show-value">
                        {pdd[0].friendlyName} [Qty: {pdd[0].scheduleQty} Disc:
                        {pdd[0].schDiscount}% Ship: $
                        {this.commaFormattedCurrency(pdd[0].shipPrice)}]
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4 text-right cat-show-lbl">Price</div>
                      <div className="col-8  text-left cat-show-value">
                        ${" "}
                        {this.commaFormattedCurrency(
                          this.state.catalog.itemPrice *
                            pdd[0].scheduleQty *
                            (1 - pdd[0].schDiscount / 100) +
                            pdd[0].shipPrice
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            periodicOptionPanel = (
              <div>
                <div>
                  <div className="row cat-packs-avail-pos">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-4 text-right cat-show-lbl">
                          Available
                        </div>
                        <div className="col-8 text-left cat-show-value">
                          {this.state.availableQty}
                          &nbsp;{" "}
                          <font size="1">
                            Sell qty based on selection below
                          </font>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col text-right cat-show-lbl">
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4 text-right cat-show-lbl">Item</div>
                      <div className="col-8 text-left cat-show-value">
                        {periodicDropDown}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {this.state.selectedScheduledItem ? (
                      <div className="row">
                        <div className="col-4 text-right cat-show-lbl">
                          Price
                        </div>
                        <div className="col-8  text-left cat-show-value-sch">
                          ${" "}
                          {this.commaFormattedCurrency(
                            this.state.catalog.itemPrice *
                              this.state.selectedScheduledItem.scheduleQty *
                              (1 -
                                this.state.selectedScheduledItem.schDiscount /
                                  100) +
                              this.state.selectedScheduledItem.shipPrice
                          )}
                          /Mo | ({includesShip}: $
                          {this.commaFormattedCurrency(
                            this.state.selectedScheduledItem.shipPrice
                          )}
                          )
                        </div>
                      </div>
                    ) : null}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            );
          }
        }
      } else {
        // When small
        if (!this.state.catalog.pkgComposedFlag) {
          let pdd = [...this.state.catalog.subscription.priodicDeliveryDetails];
          if (pdd.length === 1) {
            periodicOptionPanel = (
              <div>
                <div className="row cat-packs-avail-pos">
                  <div className="col-3 text-right cat-show-lbl">Avlble</div>
                  <div className="col-9 text-left cat-show-value">
                    {this.state.availableQty}
                    &nbsp;{" "}
                    <font size="1">Sell qty based on selection below</font>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4 text-right cat-show-lbl">Item</div>
                      <div className="col-8 text-left cat-show-value">
                        {pdd[0].friendlyName} [Qty: {pdd[0].scheduleQty} Disc:
                        {pdd[0].schDiscount}% Ship: $
                        {this.commaFormattedCurrency(pdd[0].shipPrice)}]
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-4 text-right cat-show-lbl">Price</div>
                      <div className="col-8  text-left cat-show-value">
                        ${" "}
                        {this.commaFormattedCurrency(
                          this.state.catalog.itemPrice *
                            pdd[0].scheduleQty *
                            (1 - pdd[0].schDiscount / 100) +
                            pdd[0].shipPrice
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            periodicOptionPanel = (
              <div>
                <div>
                  <div className="row cat-packs-avail-pos">
                    <div className="col-3 text-right cat-show-lbl">Avlble</div>
                    <div className="col-9 text-left cat-show-value">
                      {this.state.availableQty}
                      &nbsp;{" "}
                      <font size="1">Sell qty based on selection below</font>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 text-right cat-show-lbl">Item</div>
                  <div className="col-9 text-left cat-show-value">
                    {periodicDropDown}
                  </div>
                </div>

                {this.state.selectedScheduledItem ? (
                  <div className="row">
                    <div className="col-3 text-right cat-show-lbl">Price</div>
                    <div className="col-9  text-left cat-show-value-sch">
                      ${" "}
                      {this.commaFormattedCurrency(
                        this.state.catalog.itemPrice *
                          this.state.selectedScheduledItem.scheduleQty *
                          (1 -
                            this.state.selectedScheduledItem.schDiscount /
                              100) +
                          this.state.selectedScheduledItem.shipPrice
                      )}
                      /Mo | ({includesShip}: $
                      {this.commaFormattedCurrency(
                        this.state.selectedScheduledItem.shipPrice
                      )}
                      )
                    </div>
                  </div>
                ) : null}
              </div>
            );
          }
        }
      }
    }

    // let couponPanel;
    // let showCouponPanel = false;
    // if ( this.state.catalog && this.state.catalog.discountEnabled) {
    //   this.state.catalog.discounts.forEach(dobj => {
    //     let fromdt = new Date(dobj.startDate);
    //     let todate = new Date(dobj.endDate);
    //     let today = new Date();
    //     if (  fromdt <= today && todate >= today) {
    //       showCouponPanel = true;
    //     }
    //   });
    // }

    let topDisplayPanel;
    topDisplayPanel = (
      <div>
        <div className="row">
          <div className="col-md-6 cat-view-top-left text-center">
            {digitalAssetPanel}
          </div>
          <div className="col-md-6 cat-view-top-right text-center">
            {descPanel}
          </div>
        </div>
        <div className="row cat-price-row-pos">
          <div className="col-3 text-right cat-show-lbl">Price</div>
          <div className="col-9 text-left cat-show-value">
            $&nbsp;
            {this.state.catalog
              ? this.commaFormattedCurrency(this.state.catalog.itemPrice) +
                " " +
                this.state.catalog.unitName
              : null}
          </div>
        </div>

        {saleTypePanel}

        {pkgAvailabilityPanel}

        {this.state.catalog &&
        this.state.catalog.subscription &&
        this.state.catalog.subscription.deliveryFrequency === "onetime"
          ? subItemPanel
          : null}
        {this.state.catalog &&
        this.state.catalog.subscription &&
        this.state.catalog.subscription.deliveryFrequency === "periodic"
          ? periodicOptionPanel
          : null}
        {subDeliveryDurationPanel}
        {this.state.numberOfSubItems > 1 ? (
          <div className="sub-select-msg">Use side arrows to select & buy.</div>
        ) : null}
        {parseFloat(this.state.onLoadPkgAvailability) !== 0
          ? getQuantityPanel
          : null}
        {this.state.qtyToBuy > 0 ? salesPanel : null}
        <br />
        {catShowOpsButtons}
        {showErrPanel}
      </div>
    );

    let outputPanel;
    if (this.state.showTheItemFlag) {
      outputPanel = (
        // <div className="cat-show-display-box">
        <div>
          <div className="text-center show-cat-name-head">
            {this.state.catalog ? this.state.catalog.itemName : null}
          </div>
          {topDisplayPanel}
        </div>
      );
    }

    if (this.state.showReviewFlag) {
      outputPanel = (
        <div>
          <Review
            communityId={this.props.community.communityId}
            item={this.state.catalog}
            returnToCaller={this.returnFromReview}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CatalogGoodsDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CatalogGoodsDetails));
