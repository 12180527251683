import React, { Component } from "react";

import axios from "axios";

import "./BasicCompTestSend.css";

import { emailValidation } from "../../../../../../../../utils/emailValidation";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const emailCampaignDistributor = "/routes/interact/emailCampaignDistributor";

class BasicCompTestSend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetName: "",
      targetEmail: "",

      errMsg: "",
      errFlag: false,
    };
  }
 
  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }; 

  validateSetInput = async () => {
    let state = "success";
    let msg = "";
    if (this.state.targetEmail === "" || this.state.targetName === "") {
      state = "error";
      msg =
        "Must provide target name / email. Please check the email properly for test to succeed.";
    } else {
      let emailval = await emailValidation(this.state.targetEmail);
      // console.log('emailval:', emailval);  
      if (!emailval) {
        state = "error";
        msg = "Invalid email. Must provide a valid email to test.";
      } else {
        let coopStoreId = 0;
        if (this.props.store) coopStoreId = this.props.store.coopStoreId;

        msg = {
          broadcastId: this.props.broadcastId,
          communityId: this.props.communityId,
          compositionComponent: this.props.compositionComponent,
          selectedTemplate: this.props.selectedTemplate,
          target: [
            { name: this.state.targetName, email: this.state.targetEmail },
          ],
          recipiant: "individual",
          groupId: 0,
          coopStoreId,
          broadcastType: "email",
          clientProgram: "BasicCompTestSend",
          clientFunction: "handleTestSend",
        };
      }
    }

    return { status: state, Msg: msg };
  };

  handleTestSend = async () => {
    let isVal = await this.validateSetInput();
    // console.log("isVal:", isVal);
    if ( isVal.status === 'success') {
      try {
        let input = isVal.Msg;
        let url = baandaServer + emailCampaignDistributor;
        // console.log('url:', url, ' input:', input);
        let etret = await axios.post(url, input);
        // console.log('etret.data:', etret.data)
        if ( etret.data.status === 'success') {
          this.setState({
            errMsg: 'Successfully sent. Please check email.',
            errFlag: false
          });
        } else {
          this.setState({
            errMsg: etret.data.Msg,
            errFlag: true
          })
        }

      } catch(err) {
        this.setState({
          errMsg: err.message,
          errFlag: true
        })
      }
    } else {
      this.setState({
        errMsg: isVal.Msg,
        errFlag: true
      })
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('BasicComTestSend...');

    let saveBtn = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleTestSend}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Send
      </button>
    );

    let targetPanel;
    targetPanel = (
      <div className="basic-ts-entry-pos">
        <div className="row">
          <div className="col-4 text-right basic-ts-lbl">To Name</div>
          <div className="col-8 text-left">
            <input
              name="targetName"
              type="text"
              value={this.state.targetName}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="basic-ts-name"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 text-right basic-ts-lbl">To Email</div>
          <div className="col-8 text-left">
            <input
              name="targetEmail"
              type="text"
              value={this.state.targetEmail}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="basic-ts-name"
              placeholder=""
            />
          </div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="">
        <div className="text-center basic-test-send-header">
          Test Send Email
        </div>
        {targetPanel}
        <div className="text-center">{saveBtn}</div>
        <div
          className={
            this.state.errFlag
              ? "text-center basic-ts-msg-err"
              : "text-center basic-ts-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default BasicCompTestSend;
