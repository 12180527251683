import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./CoopStoreMgmt.css";

import ViewQnAAgreement from "./ViewQnAAgreement";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCoopStores = "/routes/dashboard/getCoopStores?";
const saveCoopMgmtDirective = "/routes/coop/saveCoopMgmtDirective";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1164000000";

class CoopStoreMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opsType: "forapproval",
      storeName: "",

      coops: [],
      idLocation: "",
      confirmForResponse: false,
      processDirection: "approve",
      newTalk: "",

      searchPanelFlag: false,
      storeDetailFlag: false,
    };
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOpsType = (e) => {
    this.setState({
      opsType: e.target.value,
      searchPanelFlag: false,
      errMsg: "",
      errFlag: false,
    });
  };

  handleProcessDirection = (e) => {
    this.setState({
      processDirection: e.target.value,
      errMsg: "",
      errFlag: false,
      confirmForResponse: false,
    });
  };

  handleSearch = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&filterType=" +
      this.state.opsType +
      "&storeName=" +
      this.state.storeName;
    let url = baandaServer + getCoopStores + params;
    // console.log("url:", url);
    try {
      let coret = await axios.get(url);
      // console.log("coret.data:", coret.data);
      if (coret.data.status === "success") {
        if (coret.data.Msg.length === 0) {
          this.setState({
            searchPanelFlag: false,
            errMsg: "No stores found with the search condition.",
            errFlag: true,
          });
        } else {
          this.setState({
            coops: coret.data.Msg,
            searchPanelFlag: true,
            errMsg: "",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          searchPanelFlag: false,
          errMsg: coret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleAStore = (store) => {
    // console.log("store:", store);

    let idLocation = "";
    store.fileUploads.forEach((elm) => {
      if (elm.type === "picid") idLocation = elm.location;
    });

    this.setState({
      searchPanelFlag: false,
      storeDetailFlag: true,
      selStore: store,
      idLocation,
    });
  };

  handleConfirmForResponse = async () => {
    await this.setState((prevstate) => ({
      confirmForResponse: !prevstate.confirmForResponse,
    }));
  };

  handleDismiss = () => {
    this.setState({
      searchPanelFlag: false,
      storeDetailFlag: false,
      selStore: null,
      idLocation: "",
      opsType: "forapproval",
    });
  };

  validateProcessDirective = async () => {
    let msg = "";
    let status = "success";

    if (
      this.state.processDirection === "decline" ||
      this.state.processDirection === "deactivate"
    ) {
      if (this.state.newTalk.length === 0) {
        msg =
          "Approval decline or deactivation of store must have some comment.";
        status = "error";
      }
    }

    if (!this.state.selStore.agreementMaster) {
      msg += "Must create an agreement";
      status = "error";
    } else {
      if (
        this.state.selStore.agreementMaster.agreement &&
        this.state.selStore.agreementMaster.agreement === 0
      ) {
        msg += "Construct your agreements properly. ";
        status = "error";
      }
    }

    if ( this.state.selStore.freefromQnA ) {
      if (this.state.selStore.freefromQnA.length === 0) {
        msg += "Must ask at least one question (QnA).";
        status = "error";
      }
    } else {
      msg += "Must ask at least one question (QnA).";
        status = "error";
    }

     

    return { status, Msg: msg };
  };

  calProrateRent = async () => {
    let today = new Date();
    let days = today.getDate();

    let currmonth = today.getMonth();
    let currentYear = today.getFullYear();
    let daysInCurrMo = new Date(currentYear, currmonth + 1, 0).getDate();
    let noOfDaysLeft = daysInCurrMo - days;

    let proratedRent = Math.ceil(
      noOfDaysLeft *
        (parseFloat(this.state.selStore.agreementMaster.fixedRent) /
          daysInCurrMo)
    );

    return proratedRent;
  };

  handleProcessDirective = async () => {

    let isValid = await this.validateProcessDirective();

    if (isValid.status === "success") {
      let proratedRent = await this.calProrateRent();
      let input = {
        // processDirection: this.state.processDirection,
        processDirection: "approve",
        approvalRequest: this.state.selStore.approvalRequest,
        newTalk: this.state.newTalk,
        communityId: this.props.communityId,
        coopStoreId: this.state.selStore.coopStoreId,
        storeName: this.state.selStore.storeName,
        storeOwner: this.state.selStore.ownerName,
        ownerEmail: this.state.selStore.ownerEmail,
        membershipStartDate: this.state.selStore.membershipStartDate,
        fixedRent: proratedRent,
        clientProgram: "CoopStoreMgmt.js",
        clientFunction: "handleProcessDirective",
        baandaId: this.props.auth.user.baandaId,
        baandaName: this.props.auth.user.name,
        paymentCycle: this.state.selStore.paymentCycle,
      };
      let url = baandaServer + saveCoopMgmtDirective;

      try {
        let coret = await axios.post(url, input);
        if (coret.data.status === "success") {
          this.setState({
            errMsg: "",
            errFlag: true,
            newTalk: "",
            confirmForResponse: false,
          });
          this.handleDismiss();
        } else {
          this.setState({
            errMsg: coret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  returnFromView = () => {
    this.setState({
      processDirection: "talk",
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CoopStoreMgmt ...');

    let infoButton;
    infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let searchButton;
    searchButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Search
      </button>
    );

    let processButton;
    processButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleProcessDirective}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Process
      </button>
    );

    let dismissButton;
    dismissButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleDismiss}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let opsTypePanel;
    opsTypePanel = (
      <div className="row">
        <div className="col text-center radio-font-style">
          <strong>Ops Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="forapproval"
                checked={this.state.opsType === "forapproval"}
                onChange={this.handleOpsType}
              />{" "}
              Pending Approval
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="searchbyname"
                checked={this.state.opsType === "searchbyname"}
                onChange={this.handleOpsType}
              />{" "}
              Search by name
            </label>
          </div>
        </div>
      </div>
    );

    let searchByNamePanel;
    if (
      this.state.opsType === "searchbyname" &&
      !this.state.searchPanelFlag &&
      !this.state.storeDetailFlag
    ) {
      searchByNamePanel = (
        <div className="row coop-mgmt-storename-pos">
          <div className="col-3 text-right coop-mgmt-storename-lbl">
            Store Name
          </div>
          <div className="col-9 text-left">
            <input
              name="storeName"
              type="text"
              value={this.state.storeName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="coop-store-mgmt-name"
              placeholder="Part / full store name"
            />
          </div>
        </div>
      );
    }

    let searchOutPanel;
    if (this.state.searchPanelFlag && !this.state.storeDetailFlag) {
      let bool = true;
      searchOutPanel = (
        <div className="coop-show-stores-box">
          {this.state.coops.map((store, i) => {
            return (
              <div key={i}>
                <div className={`${bool ? "row-color-dr" : "row-color-lt"}`}>
                  <div className="row">
                    <div className="col-10 text-left coop-store-list-desc">
                      {store.storeName} ({store.ownerName} - {store.ownerEmail})
                      - {store.approvalRequest ? "Req. Approval" : null}-{" "}
                      {store.status}
                    </div>
                    <div className="col-2 text-left">
                      <button
                        onClick={() => this.handleAStore(store)}
                        className="btn_reg_60"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      );
    }

    let talkShowPanel;
    if (!this.state.searchPanelFlag && this.state.storeDetailFlag) {
      let bool1 = true;
      talkShowPanel = (
        <div className="coop-adm-talk-log-box">
          {this.state.selStore.talkLog.map((tl, i) => {
            return (
              <div key={i}>
                <div
                  className={`${
                    bool1 ? "row-color-coop-mgmt-dr" : "row-color-coop-mgmt-lt"
                  }`}
                >
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col  text-left coop-talk-log-show">
                          {tl.byName} - {tl.Date.substring(0, 10)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col  text-left coop-talk-log-show">
                          {tl.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(bool1 = !bool1)}
              </div>
            );
          })}
        </div>
      );
    }

    let newCommentPanel;
    if (!this.state.searchPanelFlag && this.state.storeDetailFlag) {
      newCommentPanel = (
        <div>
          <div className="row coop-mgmt-talk-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">Comment</div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              <textarea
                name="newTalk"
                maxLength="2000"
                placeholder="..."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="coop-adm-talk-area-x"
                onChange={this.onChange}
                value={this.state.newTalk}
                required
              />
            </div>
          </div>
        </div>
      );
    }

    let approvePart;
    let declinePart;
    let deactivatePart;
    let activatePart;
    let justTalkPart;
    let viewQAnAgreement;

    if (this.state.selStore && this.state.selStore.status === "wip") {
      approvePart = (
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              value="approve"
              checked={this.state.processDirection === "approve"}
              onChange={this.handleProcessDirection}
            />{" "}
            Approve
          </label>
        </div>
      );
      declinePart = (
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              value="decline"
              checked={this.state.processDirection === "decline"}
              onChange={this.handleProcessDirection}
            />{" "}
            Decline
          </label>
        </div>
      );
    }

    if (this.state.selStore && this.state.selStore.status !== "wip") {
      if (this.state.selStore.status === "active") {
        deactivatePart = (
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="deactivate"
                checked={this.state.processDirection === "deactivate"}
                onChange={this.handleProcessDirection}
              />{" "}
              Deactivate
            </label>
          </div>
        );
      }

      if (this.state.selStore.status === "inactive") {
        activatePart = (
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="activate"
                checked={this.state.processDirection === "activate"}
                onChange={this.handleProcessDirection}
              />{" "}
              Activate
            </label>
          </div>
        );
      }
    }

    justTalkPart = (
      <div className="form-check form-check-inline">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="radio"
            value="talk"
            checked={this.state.processDirection === "talk"}
            onChange={this.handleProcessDirection}
          />{" "}
          Just Talk
        </label>
      </div>
    );

    viewQAnAgreement = (
      <div className="form-check form-check-inline">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="radio"
            value="view"
            checked={this.state.processDirection === "view"}
            onChange={this.handleProcessDirection}
          />{" "}
          QnA-Agreement
        </label>
      </div>
    );

    let idCtrlPanel;
    if (!this.state.searchPanelFlag && this.state.storeDetailFlag) {
      idCtrlPanel = (
        <div>
          <div className="row">
            <div className="col-4 text-center">
              <img
                src={this.state.idLocation}
                className="coop-id-img"
                alt="ID"
              />
            </div>
            <div className="col-8">
              <div className="row coop-resp-opts-pos">
                <div className="col text-center radio-font-style">
                  <strong>Direction&nbsp;&nbsp;</strong>
                  {approvePart}
                  {declinePart}
                  {deactivatePart}
                  {activatePart}
                  {justTalkPart}
                  {viewQAnAgreement}
                </div>
              </div>
              <div className="row coop-resp-opts-pos">
                <div className="col coop-mgmt-status">
                  Current Status: {this.state.selStore.status}
                </div>{" "}
              </div>
              <div className="row">
                <div className="col text-center coop-mgmt-status">
                  <Checkbox
                    checked={this.state.confirmForResponse}
                    onChange={this.handleConfirmForResponse}
                  />
                  &nbsp;Confirm to Process
                </div>{" "}
              </div>
              <div className="row">
                <div className="col-6 text-right coop-process-but-pos">
                  {this.state.confirmForResponse ? processButton : null}
                </div>{" "}
                <div className="col-6 text-left coop-dismiss-but-pos">
                  {dismissButton}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      );
    }

    let detailPanel;
    if (
      !this.state.searchPanelFlag &&
      this.state.storeDetailFlag &&
      this.state.selStore
    ) {
      let dt = this.state.selStore;
      detailPanel = (
        <div className="coop-details-stores-box">
          <div className="text-center coop-mgmt-detail-header">
            Store Details
          </div>
          <div className="row coop-mgmt-detail-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">Name</div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              {dt.storeName}
            </div>
          </div>
          <div className="row coop-mgmt-detail-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">
              Short Name
            </div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              {dt.storeShortName}
            </div>
          </div>
          <div className="row coop-mgmt-detail-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">Contact</div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              {dt.contact.name} ({dt.contact.email} || {dt.contact.cell})
            </div>
          </div>
          <div className="row coop-mgmt-detail-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">
              Store Location
            </div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              {dt.storeLocation.street}, {dt.storeLocation.city},{" "}
              {dt.storeLocation.state} {dt.storeLocation.postal_code},{" "}
              {dt.storeLocation.country}
            </div>
          </div>
          <div className="row coop-mgmt-detail-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">Owner</div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              {dt.ownerName}, ({dt.ownerEmail} - Id: {dt.ownerBaandaId})
            </div>
          </div>
          <div className="row coop-mgmt-detail-rows">
            <div className="col-3 coop-mgmt-detail-lbl text-right">
              Description
            </div>
            <div className="col-9 coop-mgmt-detail-txt text-left">
              {dt.description}
            </div>
          </div>
          {newCommentPanel}
          {talkShowPanel}
          {idCtrlPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.processDirection !== "view") {
      outputPanel = (
        <div className="coop-store-mgmt-box">
          <div className="coop-mgmt-header">
            Coop Store Management&nbsp;&nbsp;{infoButton}
          </div>
          <div className="row coop-mgmt-opstype-row">
            <div className="col-8 text-right coop-store-mgmt-opspanel">
              {opsTypePanel}
            </div>
            <div className="col-4 text-left coop-store-mgmt-search">
              {searchButton}
            </div>
          </div>
          {searchByNamePanel}
          {searchOutPanel}
          {detailPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center coop-adm-msg-err"
                : "text-center coop-adm-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    } else {
      outputPanel = (
        <div>
          <ViewQnAAgreement
            returnToCaller={this.returnFromView}
            agreement={this.state.selStore.agreementMaster.agreement}
            freeformQnA={this.state.selStore.freefromQnA}
            agreementKeeperComment={this.state.selStore.agreementKeeperComment}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CoopStoreMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CoopStoreMgmt));
