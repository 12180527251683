import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./GroupSetup.css";

import axios from "axios";

import { sortArrayOfObjects } from "../../../../utils/sortArrayOfObjects";

import notavailable from "../../../../images/notavailable4.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const saveGroup = "/routes/dashboard/saveGroup";
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";

const genListForGroupCreation = "/routes/dashboard/genListForGroupCreation";

const readMeCode = "1154000000";

class GroupSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devicesize: "",
      isSubGroupOf: "none",
      groupType: "newgroup",
      searchGroupName: "",
      communityGroups: [],
      originType: "workforce",

      itemsForGrouping: [],
      selectedItems: [],
      selectedGroup: null,

      memberListSrc: [],
      selectedMembers: [],
      showFromMemlistFlag: false,

      groupName: "",
      groupId: 0,
      description: "",

      memberName: "",
      lastName: "",
      email: "",
      cell: "",

      members: [],

      newFlag: true,
      editFlag: false,

      showItemSelectFlag: false,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.createMemberList();
    this.setState({
      deviceSize,
    });
  };

  initState = () => {
    this.setState({
      isSubGroupOf: "none",
      groupType: "newgroup",
      searchGroupName: "",
      communityGroups: [],
      originType: "items",
      selectedGroup: null,

      // memberListSrc: [],
      selectedMembers: [],
      showFromMemlistFlag: false,

      groupName: "",
      groupId: 0,
      description: "",

      memberName: "",
      lastName: "",
      email: "",
      cell: "",

      members: [],

      newFlag: true,
      editFlag: false,
    });
  };

  getMembersOfCommunity = async () => {
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&memberName=" +
        "&coopStoreId=0";
      let url = baandaServer + getMembersOfCommunity + params;

      let memdata = await axios.get(url);

      if (memdata.data.status === "success") {
        let members = [];
        memdata.data.Msg.forEach((obj) => {
          let memobj = {
            baandaId: obj.baandaId,
            name: obj.name,
            cell: obj.cell,
            email: obj.email,
          };
          members.push(memobj);
        });
        await this.setState({
          members,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errFlagMsg: memdata.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getMembersOfCommunity err:", err.message);
      this.setState({
        errMsg: err.messages,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
    });
  };

  handleGroupType = async (e) => {
    let value = e.target.value;
    let showFromMemlistFlag = false;
    if (value === "newgroup") {
      showFromMemlistFlag = true;
    }
    let editFlag = false;
    let newFlag = true;
    if (value === "existinggroup") {
      editFlag = true;
      newFlag = false;
    }

    await this.handleGetGroups();

    let errMsg = "";
    let errFlag = false;
    if (this.state.communityGroups.length === 0 && value === "existinggroup") {
      errMsg = "No groups exist for the search; setup new group.";
      errFlag = true;
    }

    this.setState({
      groupType: value,
      selectedMembers: [],
      groupName: "",
      newFlag,
      editFlag,
      errMsg,
      errFlag,
      showGroupList: false,
      showFromMemlistFlag,
      // communityGroups: []
    });
  };

  handleGetExistingGroups = () => {
    this.setState({
      newFlag: true,
      editFlag: false,
      groupType: "newgroup",
    });
  };

  getCommunityInfo = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);

      this.setState({
        community: retData.data.joiningProcess,
      });
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
    }
  };

  handleOnFileLoad = async (data) => {
    await this.setState({
      csvData: data,
      dataLoadedFlag: true,
    });
  };

  handleSelectGroupName = async () => {
    let value = document.getElementById("grp-mgmt-groups").value;
    let data = JSON.parse(value);
    let memberType = "nonmember";
    if (data.memberType) memberType = data.memberType;
    await this.setState({
      searchGroupName: data.groupName,
      groupName: data.groupName,
      description: data.description,
      groupId: data.groupId,
      memberData: data.members,
      memberType,
      selectedGroup: data,
    });
    await this.onblur();
  };

  handleGetGroups = async () => {
    this.setState({
      showFromMemlistFlag: false,
      selectedGroup: null,
      selectedMembers: [],
      showGroupList: false,
    });
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&groupName=" +
        this.state.searchGroupName +
        "&originType=adm";
      let url = baandaServer + getGroupsOfCommunity + params;

      let grpret = await axios.get(url);

      if (grpret.data.status === "success") {
        if (grpret.data.Msg.length > 0) {
          await this.setState({
            communityGroups: grpret.data.Msg,
            noGroupsFlag: false,
            showGroupList: true,
          });
        } else {
          await this.setState({
            communityGroups: [],
            noGroupsFlag: true,
            showGroupList: false,
          });
        }
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handleRecipiantAdd = async () => {
    await this.setState({
      showRecipiantAddFlag: true,
      memberName: "",
      email: "",
      cell: "",
    });
  };

  handleAddRecipiantExit = async () => {
    await this.setState({
      showRecipiantAddFlag: false,
    });
  };

  //showManualFlag
  handleManualGroupingDone = async () => {
    this.setState({
      showMemberManualFlag: false,
      // showManualFlag: false,
      showRecipiantAddFlag: false,
      email: "",
      cell: "",
      memberName: "",
    });
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  handleFilterByName = async () => {
    let memberData = [...this.state.totMemberData];
    let output = [];
    if (this.state.memberName === "") {
      output = memberData;
    } else {
      memberData.forEach((obj) => {
        if (
          obj.name
            .toLocaleLowerCase()
            .includes(this.state.memberName.toLocaleLowerCase())
        ) {
          output.push(obj);
        }
      });
    }

    await this.setState({
      memberData: output,
    });
  };

  handleSelectThisItem = async (inpobj) => {
    let newSelectedItems = [];
    let itExists = false;
    this.state.selectedItems.forEach((it) => {
      if (it.itemId === inpobj.itemId) itExists = true;
      newSelectedItems.push(it);
    });

    if (!itExists) {
      newSelectedItems.push(inpobj);
    }

    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  handleDeleteThisItem = (inp) => {
    let newSelectedItems = [];
    this.state.selectedItems.forEach((it) => {
      if (it.itemId !== inp.itemId) newSelectedItems.push(it);
    });

    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  handleSelectThisMember = async (inp) => {
    if (this.state.groupType === "existinggroup" && !this.state.selectedGroup) {
      this.setState({
        errMsg: "Must select a group to edit an existing group.",
        errFlag: true,
      });
    } else {
      let selectedMem = [];
      let itExists = false;
      this.state.selectedMembers.forEach((mm) => {
        if (parseFloat(mm.baandaId) === parseFloat(inp.baandaId))
          itExists = true;
        selectedMem.push(mm);
      });

      if (!itExists) {
        selectedMem.push(inp);
      }

      let sorted = await sortArrayOfObjects(selectedMem, "name", "dsc");

      this.setState({
        selectedMembers: sorted,
        errMsg: "",
        errFlag: false,
      });
    }
  };

  handleDeleteThisMember = (inp) => {
    let selectedMem = [];
    this.state.selectedMembers.forEach((it) => {
      if (parseFloat(it.baandaId) !== parseFloat(inp.baandaId))
        selectedMem.push(it);
    });

    this.setState({
      selectedMembers: selectedMem,
    });
  };

  handleSelectThisGroup = async (inp) => {
    this.setState({
      selectedGroup: inp,
      showGroupList: false,
      selectedMembers: [...inp.members],
      groupName: inp.groupName,
      groupId: inp.groupId,
      description: inp.description,
    });

    await this.createMemberList();
  };

  closeGroupManagement = () => {
    this.props.returnToCaller();
  };

  onFilesAdded = async (evt) => {
    const file = evt.target.files[0];

    let reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => this.storeResults(reader.result);
  };

  storeResults = async (result) => {
    let cat = result;

    let output = cat.split("\r\n");

    let out = [];
    output.forEach((cc) => {
      let zz = cc.split(",");
      if (zz.length > 0) out.push(cc);
    });

    this.setState({
      loadedFile: out,
    });
  };

  createMemberList = async () => {
    let input = {
      communityId: this.props.communityId,
      baandaId: this.props.auth.user.baandaId,
    };

    try {
      let url = baandaServer + genListForGroupCreation;

      let memret = await axios.post(url, input);

      if (memret.data.status === "success") {
        let msg = "";
        let memlist = [];
        if (memret.data.Msg.length === 0) {
          msg = "No members found for the ask.";
        } else {
          memlist = await sortArrayOfObjects(memret.data.Msg, "name", "dsc");
        }
        this.setState({
          memberListSrc: memlist,
          showFromMemlistFlag: true,
          errMsg: msg,
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: memret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    // }
  };

  /*
    We have to decide if the current group manager is not a member 
    of the parent group, woule he/she be allowed to make subgroups
    of the parent group? 
  */
  setGroupMemberSrc = () => {
    let members = [];
    this.state.selectedGroup.members.forEach((mm) => {
      let role = "member";
      if (mm.baandaId === this.props.auth.user.baandaId) role = "leader";
      let nmobj = mm;
      nmobj.role = role;
      members.push(nmobj);
    });
    this.setState({
      memberListSrc: members,
      showFromMemlistFlag: true,
      showItemSelectFlag: false,
      showGroupList: false,
    });
  };

  checkIfGroupNameUnique = async () => {
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&groupName=" +
        this.state.groupName;
      let url = baandaServer + getGroupsOfCommunity + params;
      let grpret = await axios.get(url);
      if (grpret.data.status === "success") {
        if (grpret.data.Msg.length > 0) {
          return { status: "error", Msg: "The group Name exists." };
        } else {
          return { status: "success", Msg: "" };
        }
      } else {
        return { status: "error", Msg: grpret.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  validateToSave = async () => {
    let state = "success";
    let msg = "";
    if (this.state.groupName === "") {
      state = "error";
      msg = "A group must have a unique name.";
    } else {
      let valret = await this.checkIfGroupNameUnique();
      if (this.state.groupType === "newgroup" && valret.status === "error") {
        state = "error";
        msg = valret.Msg;
      }
    }
    if (
      this.state.groupType === "newgroup" &&
      this.state.description.length < 10
    ) {
      state = "error";
      msg = "Must have a description of 10 characters or more.";
    }
    return { status: state, Msg: msg };
  };

  saveTheGroup = async () => {
    let val = await this.validateToSave();

    if (val.status === "success") {
      let coopStoreId = 0;
      let groupLevel = 0;
      let groupParentName = "";
      let groupParentId = 0;
      if (this.props.coopStoreId) coopStoreId = this.props.coopStoreId;

      let memlist = [];
      this.state.selectedMembers.forEach((mem) => {
        let dassets = [];
        if (mem.selfiLocation) dassets.push(mem.selfiLocation);
        else if (mem.fileUploads) dassets = [...mem.fileUploads];

        let newobj = {
          baandaId: mem.baandaId,
          active: mem.active,
          cell: mem.cell,
          deactivationReason: mem.deactivationReason,
          email: mem.email,
          fileUploads: dassets,
          memberComment: mem.memberComment,
          name: mem.name,
          role: mem.role,
        };
        memlist.push(newobj);
      });

      let input = {
        communityId: this.props.communityId,
        coopStoreId,
        groupName: this.state.groupName,
        groupImage: null,
        groupParentName,
        groupParentId,
        groupLevel,
        description: this.state.description,
        memberType: "member",
        subBucket: "",
        members: memlist,
        isSubGroupOf: this.state.isSubGroupOf,
        status: true,
        createdAt: new Date(),
        updatedAt: new Date(),
        baandaId: this.props.auth.user.baandaId,
        groupId: this.state.groupId,
        groupType: this.state.groupType,
        originType: "adm",
      };

      let url = baandaServer + saveGroup;

      try {
        let savret = await axios.post(url, input);
        if (savret.data.status === "success") {
          this.setState({
            errMsg: "Successfully Saved.",
            errFlag: false,
          });
          this.initState();
        } else {
          this.setState({
            errMsg: savret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("GroupSetup...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let groupOriginPanel;
    groupOriginPanel = (
      <div>
        <div className="row adm-grp-type-pos">
          <div className="col text-center radio-font-style">
            <strong>Group Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="newgroup"
                  checked={this.state.groupType === "newgroup"}
                  onChange={this.handleGroupType}
                />{" "}
                New Group
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="existinggroup"
                  checked={this.state.groupType === "existinggroup"}
                  onChange={this.handleGroupType}
                />{" "}
                Existing Group
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let selectItemsPanel;
    let selectedItemsPanel;

    let groupListPanel;
    console.log("this.state.showGroupList:", this.state.showGroupList);
    if (this.state.showGroupList) {
      let bool = true;
      groupListPanel = (
        <div>
          <div className="adm-grp-item-sel-box-head">
            <div className="row">
              <div className="col-1 adm-grp-grp-lines-ops text-left">Ops</div>
              <div className="col-10 adm-grp-grp-lines-name text-left">
                Group Name (id) Description (50 chars)
              </div>
              <div className="col-1 adm-grp-grp-lines-lvl text-left">Level</div>
            </div>
          </div>
          <div className="adm-grp-group-sel-box">
            {this.state.communityGroups.map((obj, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool
                      ? "adm-grp-item-row-color-lt"
                      : "adm-grp-item-row-color-dr"
                  }`}
                >
                  <div className="row">
                    <div className="col-1 adm-grp-grp-lines text-left">
                      <button
                        className="btn_reg_40"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.handleSelectThisGroup(obj)}
                      >
                        <i className="fas fa-check-square" />
                      </button>
                    </div>
                    <div className="col-11 adm-grp-grp-ind-lines text-left">
                      &nbsp;&nbsp;
                      {obj.groupName};&nbsp; ({obj.groupId})&nbsp;&nbsp;
                      {/* { obj.groupParentName === '' ? null : "(Of: " + obj.groupParentName +")"} */}
                      {this.state.selectedGroup &&
                      this.state.selectedGroup.groupId === obj.groupId ? (
                        <i className="fas fa-check-square" />
                      ) : (
                        ""
                      )}
                      {obj.description.length > 50
                        ? obj.description.substring(0, 50) + "..."
                        : obj.description}
                    </div>
                    {/* <div className="col-1 adm-grp-grp-ind-lines text-left">
                      {obj.groupLevel}
                    </div> */}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let fromMemlistPanel;
    if (this.state.showFromMemlistFlag) {
      let bool = true;
      fromMemlistPanel = (
        <div>
          <div className="adm-grp-memlist-head">Source Member List</div>
          <div className="adm-grp-from-mem-box">
            {this.state.memberListSrc.map((obj, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool
                      ? "adm-grp-item-row-color-lt"
                      : "adm-grp-item-row-color-dr"
                  }`}
                >
                  <div className="row">
                    <div className="col-2 text-left">
                      {obj.fileUploads.length > 0 ? (
                        <img
                          src={obj.fileUploads[0].location}
                          className="adm-mem-src-pic"
                          alt="Show AnImage"
                        />
                      ) : (
                        <img
                          src={notavailable}
                          className="adm-mem-src-pic"
                          alt="Noimage found"
                        />
                      )}
                    </div>
                    <div className="col-8 adm-grp-grp-ind-lines text-left">
                      {obj.name}&nbsp;|&nbsp;{obj.email}
                    </div>
                    <div className="col-2 adm-grp-from-ops text-left">
                      <button
                        className="btn_reg_40"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.handleSelectThisMember(obj)}
                      >
                        <i className="fas fa-check-square" />
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let toMemlistPanel;
    let bool1 = true;
    toMemlistPanel = (
      <div>
        <div className="adm-grp-memlist-head">
          {this.state.editFlag && this.state.selectedGroup
            ? this.state.selectedGroup.groupName
            : "New Group Members"}
        </div>
        <div className="adm-grp-from-mem-box">
          {this.state.selectedMembers.map((obj, i) => (
            <div key={i}>
              <div
                className={`${
                  bool1
                    ? "adm-grp-item-row-color-lt"
                    : "adm-grp-item-row-color-dr"
                }`}
              >
                <div className="row">
                  <div className="col-2 text-left">
                    {obj.fileUploads.length > 0 ? (
                      <img
                        src={obj.fileUploads[0].location}
                        className="adm-mem-src-pic"
                        alt="Show AnImage"
                      />
                    ) : (
                      <img
                        src={notavailable}
                        className="adm-mem-src-pic"
                        alt="Noimage found"
                      />
                    )}
                  </div>
                  <div className="col-8 adm-grp-grp-ind-lines text-left">
                    {obj.name}&nbsp;|&nbsp;{obj.email}
                  </div>
                  <div className="col-2 adm-grp-from-ops text-left">
                    <button
                      className="btn_exit_del"
                      type="button"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      onClick={() => this.handleDeleteThisMember(obj)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </div>
                </div>
              </div>
              {(bool1 = !bool1)}
            </div>
          ))}
        </div>
      </div>
    );

    let manageMemlistPanel;
    if (this.state.showFromMemlistFlag) {
      manageMemlistPanel = (
        <div>
          <div className="row">
            <div className="col-md-6">{fromMemlistPanel}</div>
            <div className="col-md-6">{toMemlistPanel}</div>
          </div>
          <div>
            {this.state.selectedMembers.length > 0 &&
            this.state.groupName.length > 0 ? (
              <button
                className="btn_reg_60 adm-grp-save-pos"
                type="button"
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
                onClick={this.saveTheGroup}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
      );
    }

    let newNamePanel;
    newNamePanel = (
      <div>
        <div className="row adm-grp-name-row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-2 text-right adm-grp-text-lbl">Name</div>
              <div className="col-10 text-left">
                <input
                  name="groupName"
                  type="text"
                  value={this.state.groupName}
                  onChange={this.onChange}
                  size="50"
                  maxLength="50"
                  className="adm-grp-name-entry"
                  placeholder="Enter a new Group Name"
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {/* <div className="row adm-grp-is-sub-pos">
              <div className="col text-center">{isSubgroupOfPanel}</div>
            </div> */}
            &nbsp;
          </div>
        </div>
        <div className="row adm-grp-name-row">
          <div className="col-1 text-right adm-grp-text-lbl">Description</div>
          <div className="col-10 text-left">
            <textarea
              name="description"
              maxLength="400"
              placeholder="A description for reference."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="adm-grp-desc-entry"
              onChange={this.onChange}
              value={this.state.description}
              required
            />
          </div>
          <div className="col-1 text-right adm-grp-text-lbl">&nbsp;</div>
        </div>

        {/* <div>{createButton}</div> */}
        {selectItemsPanel}
        {this.state.showItemSelectFlag ? (
          <div className="text-center">Selected Items</div>
        ) : null}
        {selectedItemsPanel}
        {groupListPanel}
        {this.state.showFromMemlistFlag ? "" : null}
        {manageMemlistPanel}
      </div>
    );

    let backButton;
    if (this.props.caller !== "admin") {
      backButton = (
        <button
          className="btn_back_main"
          type="button"
          onClick={this.closeGroupManagement}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      );
    }

    let searchButtonPanel;
    searchButtonPanel = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.handleGetGroups}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let exitSearchPanel = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.handleGetExistingGroups}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let findExistingPanel;
    findExistingPanel = (
      <div className="grps-search-box">
        <div className="grp-wip-search-header">View Existing Groups</div>
        <div className="row grp-name-row">
          <div className="col-2 text-right brd-rec-search-lbl">Group</div>
          <div className="col-10 text-left">
            <input
              name="searchGroupName"
              type="text"
              value={this.state.searchGroupName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="brd-grp-search-name"
              placeholder="Enter a Group Name to Search"
            />
            &nbsp;{searchButtonPanel}
            {exitSearchPanel}
          </div>
        </div>
        <div>{groupListPanel}</div>
        {this.state.selectedGroup ? (
          <div>
            <div className="row adm-grp-name-row">
              <div className="col-2 text-right adm-grp-text-lbl">
                Description
              </div>
              <div className="col-10 text-left">
                <textarea
                  name="description"
                  maxLength="400"
                  placeholder="A description for reference."
                  rows="2"
                  wrap="hard"
                  spellCheck="true"
                  className="adm-grp-desc-entry-edit"
                  onChange={this.onChange}
                  value={this.state.description}
                  required
                />
              </div>
            </div>
            <div>{manageMemlistPanel}</div>
          </div>
        ) : null}
      </div>
    );

    let activePanel;
    if (this.state.newFlag) {
      activePanel = <div>{newNamePanel}</div>;
    }

    if (this.state.editFlag) {
      activePanel = <div>{findExistingPanel}</div>;
    }

    let outputPanel;
    outputPanel = (
      <div className="manual-grp-mgmt-box">
        <div className="text-center recipiant-mgmt-header">
          Groups Management&nbsp;&nbsp;{infoModalButton}&nbsp;{backButton}
        </div>
        {groupOriginPanel}
        {activePanel}

        <div
          className={
            this.state.errFlag
              ? "text-center grp-save-msg-err"
              : "text-center grp-save-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

GroupSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupSetup));
