import React, { Component } from "react";

import axios from "axios";

import "./BasicCompReview.css";

import fbicon from "../../../../../../../../images/icons8-blog-48.png";
import insticon from "../../../../../../../../images/instagram.png";
import twiticon from "../../../../../../../../images/icons8-twitter-48.png";
import youicon from "../../../../../../../../images/youtube.png";
import storeicon from "../../../../../../../../images/store.jpg";
import websiteicon from "../../../../../../../../images/website.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCampaignFooter = "/routes/interact/getCampaignFooter?";

class BasicCompReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReviewFlag: true,

      bannerLocation: "",
      imageLocation: "",

      introbgColor: "",
      isPersonal: false,
      salute: "",
      signoff: "",

      footer: null,

      bodyForm: null,
      basicReviewFrameColor: "#fff",
      basicReviewFontColor: "#000",
    };
  }

  componentDidMount = async () => {
    // console.log("here 93809820492");
    let bannerLocation = "";
    let imageLocation = "";
    let introbgColor = "#fff";
    let isPersonal = false;
    let salute = "";
    let bodyForm = null;
    let basicReviewFrameColor = "#fff";
    let basicReviewFontColor = "#000";
    if (this.props.theBroadcast.presentationFrameColor)
      basicReviewFrameColor = this.props.theBroadcast.presentationFrameColor;

    if (
      basicReviewFrameColor === "#000" ||
      basicReviewFrameColor === "#000000"
    ) {
      basicReviewFontColor = "#fff";
    }

    let bmc = this.props.theBroadcast.mailComposition;
    // console.log(">>>>>>>>> bmc:", bmc);
    if (bmc) {
      if (bmc.fileUploads.length > 0) {
        bmc.fileUploads.forEach((obj) => {
          // console.log("53 obj:", obj);
          if (obj.compositionComponent === "banner") {
            bannerLocation = obj.location;
          }
          if (obj.compositionComponent === "basicCompImage") {
            imageLocation = obj.location;
          }
        });
      }

      if (bmc.introBodyFormat) introbgColor = bmc.introBodyFormat.bgColor;
      if (bmc.interactionType === "personal") {
        isPersonal = true;
        salute = bmc.salute + " " + this.props.yourName + ", ";
      }

      bodyForm = bmc.introBodyFormat;
    }

    await this.getFooter();

    this.setState({
      bannerLocation,
      imageLocation,
      introbgColor,
      isPersonal,
      salute,
      bodyForm,
      basicReviewFrameColor,
      basicReviewFontColor,
    });
  };

  getFooter = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCampaignFooter + param;
    try {
      // console.log("url:", url);
      let fret = await axios.get(url);
      // console.log("fret.data:", fret.data);
      if (fret.data.status === "success") {
        this.setState({
          footer: fret.data.Msg.campaignFooterObj,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: fret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  exitFromPopup = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log(">>this.props:", this.props);
    // console.log(">>this.state:", this.state);
    // console.log('BasicCompReview...');

    let backFromTemplatePop = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let bannerPanel;
    bannerPanel = (
      <div className="text-center">
        <img
          src={this.state.bannerLocation}
          alt="banner"
          hight="50"
          width="460"
          className="basic-review-banner-pos"
        />
      </div>
    );

    let bcb = this.props.theBroadcast.mailComposition;

    let introBodyFontSize,
      introBodyColor,
      introBodybgColor,
      introBodyIsBold,
      introBodyFontFamily,
      introBodyAlign;
    if (bcb.introBodyFormat) {
      introBodyFontSize = bcb.introBodyFormat.fontSize;
      introBodyColor = bcb.introBodyFormat.color;
      introBodybgColor = bcb.introBodyFormat.bgColor;
      introBodyIsBold = bcb.introBodyFormat.isBold;
      introBodyFontFamily = bcb.introBodyFormat.fontFamily;
      introBodyAlign = bcb.introBodyFormat.align;
    }

    let titleFontSize,
      titleColor,
      titlebgColor,
      titleIsBold,
      titleFontFamily,
      titleAlign;
    if (bcb.introTitleFormat) {
      titleFontSize = bcb.introTitleFormat.fontSize;
      titleColor = bcb.introTitleFormat.color;
      titlebgColor = bcb.introTitleFormat.bgColor;
      titleIsBold = bcb.introTitleFormat.isBold;
      titleFontFamily = bcb.introTitleFormat.fontFamily;
      titleAlign = bcb.introTitleFormat.align;
    }

    let introTitlePanel;
    if (bcb.introTitle !== "") {
      introTitlePanel = (
        <div
          style={{
            backgroundColor: titlebgColor,
            width: "460px",
            fontSize: titleFontSize,
            fontFamily: titleFontFamily,
            color: titleColor,
            textAlign: titleAlign,
          }}
          className="comp-title-adj"
        >
          {titleIsBold ? <b>{bcb.introTitle}</b> : bcb.introTitle}
        </div>
      );
    }

    let introPersonalizePanel;
    if (this.state.isPersonal) {
      introPersonalizePanel = (
        <div
          style={{
            backgroundColor: introBodybgColor,
            width: "460px",
            fontSize: introBodyFontSize,
            fontFamily: introBodyFontFamily,
            color: introBodyColor,
            // textAlign: introBodyAlign,
            textAlign: "left",
          }}
          className="basic-linebreak-text"
        >
          {introBodyIsBold ? <b>{this.state.salute}</b> : this.state.salute}
          {/* <div>{this.state.salute}</div> */}
          <div>&nbsp;</div>
        </div>
      );
    }
 

    let introPanel;
    introPanel = (
      <div
        style={{
          backgroundColor: introBodybgColor,
          width: "460px",
          fontSize: introBodyFontSize,
          fontFamily: introBodyFontFamily,
          color: introBodyColor,
          textAlign: introBodyAlign,
        }}
        className="basic-linebreak-intro-body-text"
      >
        {introBodyIsBold ? <b>{bcb.introBody}</b> : bcb.introBody}

        <div>&nbsp;</div>
      </div>
    );

    let topPanel;
    topPanel = (
      <div
        style={{
          backgroundcolor: this.state.introbgColor,
          width: "360px",
          marginleft: "3px",
          paddingleft: "3px",
        }}
      >
        {introTitlePanel}
        {introPersonalizePanel}
        {introPanel}
      </div>
    );

    let imagePanel;

    if (this.state.imageLocation !== "") {
      imagePanel = (
        <div className="image-panel-back">
          <img
            src={this.state.imageLocation}
            alt="banner"
            hight="auto"
            width="460px"
            marginleft="3px"
          />
        </div>
      );
    }

    let signoffPanel;
    if (
      this.props.theBroadcast.mailComposition.interactionType === "personal"
    ) {
      signoffPanel = (
        <div className="basic-signoff">
          {this.props.theBroadcast.mailComposition.signoff !== "" ? (
            <div className="row">
              <div className="col text-left basic-comp-signoff-rev">
                {this.props.theBroadcast.mailComposition.signoff}
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col text-left basic-comp-signoff-name">
              {this.props.theBroadcast.mailComposition.senderName}
            </div>
          </div>
          {this.props.theBroadcast.mailComposition.senderPosition !== "" ? (
            <div className="row">
              <div className="col text-left basic-comp-signoff-position">
                ( {this.props.theBroadcast.mailComposition.senderPosition} )
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let listPanel;
    if (this.props.theBroadcast.mailComposition.basicCompBody.length > 0) {
      let arr = this.props.theBroadcast.mailComposition.basicCompBody;
      let cmplen =
        this.props.theBroadcast.mailComposition.basicCompBody.length - 1;
      let brbg =
        this.props.theBroadcast.mailComposition.basicCompBody[cmplen]
          .writeupFormat.bgColor;
      console.log(">>> arr:", arr);
      listPanel = (
        <div className="">
          {arr.map((obj, i) => (
            <div key={i}>
              <div className="row basic-writeup-title">
                <div
                  className="col"
                  style={{
                    backgroundColor: obj.titleFormat.bgColor,
                    textAlign: obj.titleFormat.align,
                    color: obj.titleFormat.color,
                    fontFamily: obj.titleFormat.fontFamily,
                    fontSize: obj.titleFormat.fontSize,
                  }}
                >
                  &nbsp;
                </div>
              </div>
              {obj.title !== "" ? (
                <div className="row basic-writeup-title">
                  <div
                    className="col"
                    style={{
                      backgroundColor: obj.titleFormat.bgColor,
                      textAlign: obj.titleFormat.align,
                      color: obj.titleFormat.color,
                      fontFamily: obj.titleFormat.fontFamily,
                      fontSize: obj.titleFormat.fontSize,
                    }}
                  >
                    {obj.titleFormat.isBold ? <b>{obj.title}</b> : obj.title}
                  </div>
                </div>
              ) : null}

              <div className="row">
                <div
                  className="col"
                  style={{
                    backgroundColor: obj.writeupFormat.bgColor,
                    textAlign: obj.writeupFormat.align,
                    color: obj.writeupFormat.color,
                    fontFamily: obj.writeupFormat.fontFamily,
                    fontSize: obj.writeupFormat.fontSize,
                  }}
                >
                  {obj.writeupFormat.isBold ? (
                    <b>{obj.writeup}</b>
                  ) : (
                    obj.writeup
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div
              className="col"
              style={{
                backgroundColor: brbg,
              }}
            >
              &nbsp;
            </div>
          </div>
          {signoffPanel}
        </div>
      );
    } else {
      listPanel = (
        <div className="">
          <div className="row">
            <div
              className="col"
              style={{
                backgroundColor: "#ffffff",
              }}
            >
              &nbsp;
            </div>
          </div>
          {signoffPanel}
        </div>
      );
    }

    let footerPanel;
    if (this.state.footer) {
      footerPanel = (
        <div
          className="footer-box"
          style={{
            backgroundColor: "#fff",
            marginLeft: "-3px",
            paddingleft: "3px",
            paddingright: "15px",
            width: "462px",
          }}
        >
          <div className="text-center">
            {this.state.footer.activeFacebook ? (
              <a
                href={this.state.footer.facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbicon} alt="fbicon" hight="auto" width="30px" />
              </a>
            ) : null}
            &nbsp;
            {this.state.footer.activeInstagram ? (
              <a
                href={this.state.footer.instagramLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={insticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeTwitter ? (
              <a
                href={this.state.footer.twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={twiticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeYoutube ? (
              <a
                href={this.state.footer.youtubeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={youicon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeStore ? (
              <a
                href={this.state.footer.storeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={storeicon} alt="insticon" hight="auto" width="35px" />
              </a>
            ) : null}
            {this.state.footer.activeWebsite ? (
              <a
                href={this.state.footer.websiteLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={websiteicon}
                  alt="insticon"
                  hight="auto"
                  width="35px"
                />
              </a>
            ) : null}
          </div>
          <div className="text-center comp-footer-msg">
            <p className="comp-footer-padding" align="justify">
              {this.state.footer.footerMessage}
            </p>
          </div>
          <div className="text-center comp-unsubscribe-msg">
            <u>Unsubscribe</u>
          </div>
        </div>
      );
    }

    let showTemplatePanel;
    showTemplatePanel = (
      <div
        className="basic-email-review"
        style={{
          backgroundColor: this.state.basicReviewFrameColor,
          color: this.state.basicReviewFontColor,
        }}
      >
        <div className="template-review-header text-center">
          Email Review &nbsp;{backFromTemplatePop}
        </div>
        {bannerPanel}
        {topPanel}
        {imagePanel}

        {listPanel}
        {footerPanel}
      </div>
    );

    let outputPanel;
    outputPanel = <div>{showTemplatePanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default BasicCompReview;
